import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { useDialog } from "hooks/useDialog";
import { useInterval } from "hooks/useInterval";
import { ContentLayout } from "Containers";
import { Confirmation } from "Components/Confirmation/Confirmation";
import { Button, flash, Icon, Icons, Input, TextareaAutoSizable } from "Uikit";
import { Loader } from "Uikit/Loader/Loader";
import { TestingHeaderLinear } from "./TestingHeaderLinear";
import { TestingHeaderLoose } from "./TestingHeaderLoose";
import { TestingFooter } from "./TestingFooter";
import {
    TestUserStartedResponse,
    TestUserStartedResponseQuestion,
    TestUserStartedResponseQuestionAnswer,
    TestUserValidateResponse,
} from "Api/Responses/TestResponse";
import Api from "Api";
import { TestUserValidateRequest } from "Api/Requests/TestRequest";
import { useNavigateToSource } from "hooks/useNavigateToSouce";
import { setIsBack, setIsFavorite, setIsHidden, setTitle } from "slices/headerSlice";
import { useDispatch } from "react-redux";
import { numberRegexp, testingRegexp } from "Uikit/Forms/Input";
import { GlobalContext } from "App";
import { BadRequestResponse, ErrorCode } from "Api/BaseResponse";

export const Testing = () => {
    // const backURL = useSelector(getSourcePage);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { setIsInterfaceElementsHidden, setTestingURL } = useContext(GlobalContext);

    const { dialogState, openDialog, closeDialog } = useDialog();

    const [isShowError, setIsShowError] = useState<boolean>(false);

    const [test, setTest] = useState<TestUserStartedResponse | null>(null);
    const [question, setQuestion] = useState<TestUserStartedResponseQuestion | null>(null);

    const [testTime, setTestTime] = useState<number | null>(null);
    const [questionTime, setQuestionTime] = useState<number | null>(null);

    const [isChanged, setIsChanged] = useState(false);
    const { goSourcePage, navigateWithCurrentSource } = useNavigateToSource("/tests");

    const onClose = () => {
        openDialog({
            title: "Завершить тестирование",
            content:
                "У вас остались вопросы без ответов. Вы уверены, что хотите завершить прохождение теста? Все вопросы без ответов будут помечены неправильными.",
            closeBtnText: "Отмена",
            submitBtnText: "Завершить",
            submitBtnColor: "danger",
            onRequestClose: () => closeDialog(),
            onRequestSubmit: () => {
                closeDialog();
                // const courseId = localStorage.getItem("testingCourseId");
                localStorage.setItem("test", JSON.stringify(test));
                setTestingURL(null);

                // if (courseId) {
                //     localStorage.removeItem("testingCourseId");
                //     navigate(backURL);
                // } else {
                //     navigateWithCurrentSource("/testing/finish");
                // }
                navigateWithCurrentSource("/testing/finish");
            },
        });
    };
    const onQuestionClick = (index: number) => {
        if (test === null || question === null) {
            return;
        }

        setTest({ ...test, currentQuestionId: test.questions[index].id });
        setQuestion(test.questions[index]);

        setIsShowError(test.showMistakesDuringQuiz && test.questions[index].hasAnswer);
    };

    const onAnswer = (answer: string) => {
        if (question?.type === "TEXT" || question?.type === "NUMBER") {
            setQuestion({ ...question, customAnswer: answer });
        } else if (question?.type === "ONE_OPTION") {
            const answers = Object.assign(question.answers).map((p: TestUserStartedResponseQuestionAnswer) => {
                p.isSelected = p.id === answer;
                return p;
            });

            setQuestion({ ...question, answers });
        } else if (question?.type === "MULTIPLE_OPTIONS") {
            const answers = Object.assign(question.answers).map((p: TestUserStartedResponseQuestionAnswer) => {
                if (p.id === answer) {
                    p.isSelected = !p.isSelected;
                }

                return p;
            });

            setQuestion({ ...question, answers });
        }
    };

    const isTimeout = (): boolean => {
        if (test === null) {
            return false;
        }

        if (test.quizType === "LINEAR" && test.questionTimeLimit && questionTime !== null) {
            return (questionTime * 100) / test.questionTimeLimit <= 10 || questionTime <= 10;
        } else if (test.quizTimeLimit && testTime !== null) {
            return (testTime * 100) / test.quizTimeLimit <= 10 || testTime <= 10;
        }

        return false;
    };

    const onPrevious = () => {
        if (test === null || question === null) {
            return;
        }

        const prevQuestionIndex = test.questions.findIndex((p) => p.id === question.id) - 1;

        setTest({ ...test, currentQuestionId: test.questions[prevQuestionIndex].id });
        setQuestion(test.questions[prevQuestionIndex]);

        setIsShowError(test.showMistakesDuringQuiz && test.questions[prevQuestionIndex].hasAnswer);
    };
    const onSkip = () => {
        if (test === null || question === null) {
            return;
        }

        let nextQuestionIndex = test.questions.findIndex((p) => p.id === question.id) + 1;

        if (nextQuestionIndex === test.questions.length || test.questions[nextQuestionIndex].hasAnswer) {
            const nextQuestions = test.questions.filter((p) => !p.hasAnswer);

            if (
                test.questions.slice(nextQuestionIndex, test.questions.length).filter((p) => !p.hasAnswer).length !== 0
            ) {
                nextQuestionIndex = test.questions.indexOf(
                    test.questions.slice(nextQuestionIndex, test.questions.length).filter((p) => !p.hasAnswer)[0],
                );
            } else {
                nextQuestionIndex = test.questions.indexOf(nextQuestions[0]);
            }
        }

        setTest({ ...test, currentQuestionId: test.questions[nextQuestionIndex].id });
        setQuestion(test.questions[nextQuestionIndex]);

        setIsShowError(test.showMistakesDuringQuiz && test.questions[nextQuestionIndex].hasAnswer);
    };

    const onNext = () => {
        if (test === null || question === null) {
            return;
        }

        let nextQuestionIndex = test.questions.findIndex((p) => p.id === question.id) + 1;

        if (test.questions.length === nextQuestionIndex && test.questions.filter((p) => !p.hasAnswer).length !== 0) {
            nextQuestionIndex = test.questions.indexOf(test.questions.filter((p) => !p.hasAnswer)[0]);
        }

        if (
            (nextQuestionIndex === test.questions.length || test.questions[nextQuestionIndex].hasAnswer) &&
            test.questions.filter((p) => !p.hasAnswer).length !== 0
        ) {
            const nextQuestions = test.questions.filter((p) => !p.hasAnswer);

            if (
                test.questions.slice(nextQuestionIndex, test.questions.length).filter((p) => !p.hasAnswer).length !== 0
            ) {
                nextQuestionIndex = test.questions.indexOf(
                    test.questions.slice(nextQuestionIndex, test.questions.length).filter((p) => !p.hasAnswer)[0],
                );
            } else {
                nextQuestionIndex = test.questions.indexOf(nextQuestions[0]);
            }
        }

        if (test.questions.length === nextQuestionIndex || test.questions.filter((p) => !p.hasAnswer).length === 0) {
            localStorage.setItem("test", JSON.stringify(test));
            navigateWithCurrentSource("/testing/finish");

            return;
        }

        setTest({ ...test, currentQuestionId: test.questions[nextQuestionIndex].id });
        setQuestion(test.questions[nextQuestionIndex]);

        if (test?.questionTimeLimit) {
            setQuestionTime(test.questionTimeLimit);
        }

        setIsShowError(test.showMistakesDuringQuiz && test.questions[nextQuestionIndex].hasAnswer);
    };
    const onSubmit = async () => {
        if (test === null || question === null) {
            return;
        }

        const validateRequest = new TestUserValidateRequest();
        validateRequest.questionId = question.id;

        if (question.type === "NUMBER" || question.type === "TEXT") {
            validateRequest.customAnswer = question.customAnswer;
        } else {
            validateRequest.answerIds = question.answers.filter((p) => p.isSelected).map((p) => p.id);
        }

        let validation = new TestUserValidateResponse();

        try {
            validation = await Api.Test.UserValidate(validateRequest);
        } catch (e) {
            if ((e as BadRequestResponse).errorCode === ErrorCode.QUIZ_WAS_CHANGED) {
                setIsChanged(true);
            } else if ((e as BadRequestResponse).errorCode !== ErrorCode.QUIZ_TIME_EXCEEDED) {
                flash.error("Произошла ошибка при отправке теста на проверку");
            }

            return;
        }

        let nextQuestionIndex = test.questions.findIndex((p) => p.id === question.id) + 1;

        test.questions[nextQuestionIndex - 1].hasAnswer = true;

        if (question.type !== "TEXT" && test.showMistakesDuringQuiz && !validation.isCorrect) {
            test.questions[nextQuestionIndex - 1].approved = validation.isCorrect;
            test.questions[nextQuestionIndex - 1].correctAnswer = validation.correctAnswer.customAnswer;

            setTest({ ...test });
            setQuestion({
                ...question,
                hasAnswer: true,
                approved: validation.isCorrect,
                correctAnswer: validation.correctAnswer.customAnswer,
                answers: question.answers.map((p) => {
                    if (validation.correctAnswer.answerIds && validation.correctAnswer.answerIds.indexOf(p.id) !== -1) {
                        p.isCorrect = true;
                    }

                    return p;
                }),
            });

            setIsShowError(true);
            setQuestionTime(null);

            return;
        }

        if (nextQuestionIndex === test.questions.length || test.questions[nextQuestionIndex].hasAnswer) {
            const nextQuestions = test.questions.filter((p) => !p.hasAnswer);

            if (
                test.questions.slice(nextQuestionIndex, test.questions.length).filter((p) => !p.hasAnswer).length !== 0
            ) {
                nextQuestionIndex = test.questions.indexOf(
                    test.questions.slice(nextQuestionIndex, test.questions.length).filter((p) => !p.hasAnswer)[0],
                );
            } else {
                nextQuestionIndex = test.questions.indexOf(nextQuestions[0]);
            }
        }

        if (test.questions.length === nextQuestionIndex || test.questions.filter((p) => !p.hasAnswer).length === 0) {
            localStorage.setItem("test", JSON.stringify(test));
            const courseId = localStorage.getItem("testingCourseId");

            if (courseId) {
                navigate("/training/coursing/finish", { replace: true });
            } else {
                navigateWithCurrentSource("/testing/finish");
            }
            return;
        }

        setTest({ ...test, currentQuestionId: test.questions[nextQuestionIndex].id });
        setQuestion(test.questions[nextQuestionIndex]);

        if (test?.questionTimeLimit) {
            setQuestionTime(test.questionTimeLimit);
        }
    };

    useInterval(async () => {
        if (test?.quizType === "LINEAR") {
            if (questionTime === 0) {
                await onSubmit();
            } else if (questionTime !== null) {
                setQuestionTime(questionTime - 1);
            }
        }

        if (testTime === 0) {
            try {
                await onSubmit();
            } catch {
                // Should be empty;
            }

            openDialog({
                title: "Время на тестирование вышло",
                content: "Все вопросы без ответов будут помечены неправильными.",
                closeBtnText: "",
                submitBtnText: "Закрыть",
                onRequestClose: () => {
                    closeDialog();
                    localStorage.setItem("test", JSON.stringify(test));

                    navigateWithCurrentSource("/testing/finish");
                },
                onRequestSubmit: () => {
                    closeDialog();
                    localStorage.setItem("test", JSON.stringify(test));

                    navigateWithCurrentSource("/testing/finish");
                },
            });

            setTestTime(null);
            setQuestionTime(null);
        } else if (testTime !== null) {
            setTestTime(testTime - 1);
        }
    }, 1000);
    // Скрытие элментов интерфейса
    useEffect(() => {
        setIsInterfaceElementsHidden(true);

        return () => {
            setIsInterfaceElementsHidden(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        const fetch = async () => {
            try {
                const response = await Api.Test.UserStarted();
                let currentQuestion: TestUserStartedResponseQuestion | undefined = response.questions[0];

                if (response.currentQuestionId) {
                    currentQuestion = response.questions.find((p) => p.id === response.currentQuestionId);
                } else {
                    response.currentQuestionId = currentQuestion.id;
                }

                if (!currentQuestion) {
                    goSourcePage();
                    return;
                }

                response.questions = response.questions.map((p) => {
                    if (p.customAnswer || p.answers.filter((p1) => p1.isSelected).length !== 0) {
                        p.hasAnswer = true;

                        if (p.correctAnswer && p.customAnswer !== p.correctAnswer) {
                            p.approved = false;
                        } else {
                            const correct = p.answers.filter((p1) => p1.isCorrect).length;
                            const selectedAndCorrect = p.answers.filter((p1) => p1.isSelected && p1.isCorrect).length;

                            p.approved = correct === selectedAndCorrect;
                        }
                    }

                    return p;
                });

                setTest(response);
                setQuestion(currentQuestion);

                // Check and create test timers;
                if (response.quizTimeLimit) {
                    setTestTime(response.quizTimeLimit);
                }

                if (response.questionTimeLimit) {
                    setQuestionTime(response.questionTimeLimit);
                }
            } catch {
                goSourcePage();
            }
        };
        fetch().then();
    }, [navigate, goSourcePage]);
    useEffect(() => {
        dispatch(setIsHidden(true));
        dispatch(setIsBack(true));
        dispatch(setTitle("Тест"));
        dispatch(setIsFavorite(false));
    }, [dispatch]);

    let time = null;

    if (test) {
        time = test.quizType === "LINEAR" ? questionTime ?? testTime : testTime;
    }

    useEffect(() => {
        if (!test || window.screen.width > 568) {
            return;
        }

        const questionIndex = test.questions.findIndex((p) => p.id === test.currentQuestionId);
        const nextQuestionItem: HTMLElement = document.querySelector(
            "#testingHeaderLooseQuestions div:nth-child(" + (questionIndex + 1) + ")",
        )!;

        const container = document.getElementById("testingHeaderLooseQuestionsContainer")!;

        if (container && nextQuestionItem) {
            container.scrollTo({ left: nextQuestionItem.offsetLeft - container.clientWidth / 2, behavior: "smooth" });
        }
    }, [test]);

    return (
        <ContentLayout className="!fixed top-0 left-0 flex flex-col items-center !pb-0 z-[100] w-full h-full bg-white">
            {isChanged && (
                <div className="flex flex-col w-full h-full">
                    <div className="flex-center flex-col pt-6 flex-grow">
                        <h1 className="mb-4">Тест был изменен администратором</h1>
                        <p className="text-blue-drk">
                            {localStorage.getItem("testingCourseId")
                                ? `Только что тест, который вы прроходите, был изменен администратором, чтобы 
                                продолжить обучение необходимо перейти на главную страницу курса`
                                : `Только что тест, который вы проходите, был изменен администратором. Все актуальные 
                                тесты находятся в разделе «Тесты»`}
                        </p>
                        <Button
                            color="primary"
                            className="mt-7.5"
                            onClick={() => {
                                const courseId = localStorage.getItem("testingCourseId");
                                navigate(courseId ? `/training/course/${courseId}` : "/tests");
                            }}
                        >
                            Ну спасибо...
                        </Button>
                    </div>
                </div>
            )}
            {!isChanged && (test === null || question === null) && (
                <div className="w-full h-full flex-center mt-25 px-10">
                    <Loader />
                </div>
            )}
            {!isChanged && test !== null && question !== null && (
                <>
                    <Confirmation {...dialogState} />
                    <div className="fixed top-0 sm:top-15 2xl:top-[75px] right-0 sm:right-15 2xl:right-[75px] flex justify-end py-1.5 sm:py-0 bg-white z-20">
                        <div onClick={onClose} className="cursor-pointer" id="userTestingCloseBtn">
                            <Icon
                                className="hidden sm:block 2xl:!w-6.25 2xl:!h-6.25"
                                icon={Icons.Close}
                                width={20}
                                height={20}
                                color="fill-blue-drk"
                            />
                            <Icon
                                className="block sm:hidden"
                                icon={Icons.Close}
                                width={32}
                                height={32}
                                color="fill-blue-drk"
                            />
                        </div>
                    </div>
                    {time !== null && (
                        <div className="fixed top-15 right-3 flex sm:hidden justify-end items-center z-20">
                            <Icon
                                icon={Icons.Time}
                                width={16}
                                height={16}
                                color={isTimeout() ? "fill-red" : "fill-gray-dark"}
                            />
                            <p className={isTimeout() ? "ml-1 p2-accent text-red" : "ml-1 p2-accent text-gray-dark"}>
                                {new Date(time * 1000).toISOString().slice(11, 19)}
                            </p>
                        </div>
                    )}
                    <div
                        className="mt-11 sm:mt-0 relative w-full sm:max-w-175 2xl:max-w-[875px] z-10 bg-white"
                        id="userTestingHeaderContainer"
                    >
                        {test.quizType === "LINEAR" && (
                            <TestingHeaderLinear
                                count={test.questions.length}
                                current={test.questions.findIndex((p) => p.id === test.currentQuestionId)}
                            />
                        )}
                        {test.quizType === "LOOSE" && (
                            <TestingHeaderLoose
                                questions={test.questions}
                                current={test.questions.findIndex((p) => p.id === test.currentQuestionId)}
                                onQuestionClick={onQuestionClick}
                            />
                        )}
                    </div>
                    <div
                        id="userTestingContentContainer"
                        className="p-4 sm:p-6 2xl:p-7.5 w-full sm:max-w-175 2xl:max-w-[875px] h-full sm:h-[calc(100vh-260px)] 2xl:h-auto 2xl:max-h-[calc(100vh-260px)] bg-background rounded-xl 2xl:rounded-2xl overflow-auto custom-scrollbar"
                    >
                        <pre className="font-sans p1-accent text-black 2xl:text-md">{question.text}</pre>
                        {question.logo?.id && (
                            <img
                                className="mt-2 sm:mt-3.5 2xl:mt-4.5 w-82 2xl:w-[437px] h-50 2xl:h-62.5 rounded-xl 2xl:rounded-2xl object-cover"
                                src={`/service/lms-upload/api/file/download/${question.logo.id}`}
                                alt={question.text}
                            />
                        )}
                        <div className="mt-5.5 2xl:mt-7">
                            {question.type === "TEXT" && (
                                <TextareaAutoSizable
                                    id="testingTextInput"
                                    name="testingTextInput"
                                    className="border-0 resize-none !bg-white"
                                    value={question.customAnswer || ""}
                                    placeholder="Введите ответ"
                                    cutRegExp={testingRegexp}
                                    maxLength={1024}
                                    onChange={(e) => onAnswer(e.target.value)}
                                />
                            )}
                            {question.type === "NUMBER" && (
                                <div className="relative">
                                    <Input
                                        className={clsx(
                                            "!bg-white border-0",
                                            isShowError && !question.approved ? "text-red" : "",
                                        )}
                                        value={question.customAnswer || ""}
                                        onChange={(e) => (!isShowError ? onAnswer(e.target.value) : null)}
                                        placeholder="Введите число"
                                        maxLength={20}
                                        cutRegExp={numberRegexp}
                                        rootClassName="!bg-transparent"
                                    />
                                    {isShowError && (
                                        <span className="absolute top-2 2xl:top-3 right-4.5 2xl:right-6 text-blue">
                                            Правильно: {question.correctAnswer}
                                        </span>
                                    )}
                                </div>
                            )}
                            {(question.type === "ONE_OPTION" || question.type === "MULTIPLE_OPTIONS") && (
                                <div className="flex flex-wrap gap-2 sm:gap-4 2xl:gap-5">
                                    {question.answers.map((answer) => {
                                        return (
                                            <div
                                                key={answer.id}
                                                className={clsx(
                                                    "flex cursor-pointer",
                                                    answer.pictureId ? "flex-col" : "items-center w-full",
                                                )}
                                                onClick={() => (!isShowError ? onAnswer(answer.id) : null)}
                                            >
                                                <div className={answer.pictureId ? "flex items-center" : ""}>
                                                    {question.type !== "MULTIPLE_OPTIONS" &&
                                                        isShowError &&
                                                        answer.isSelected &&
                                                        !answer.isCorrect && (
                                                            <div className="mr-2 2xl:mr-2.5 w-6 2xl:w-7.5 h-6 2xl:h-7.5 bg-red rounded-full">
                                                                <Icon
                                                                    icon={Icons.Close}
                                                                    width={24}
                                                                    height={24}
                                                                    color="fill-white"
                                                                    className="2xl:!w-7.5 2xl:!h-7.5"
                                                                />
                                                            </div>
                                                        )}
                                                    {question.type !== "MULTIPLE_OPTIONS" &&
                                                        isShowError &&
                                                        !answer.isSelected &&
                                                        answer.isCorrect && (
                                                            <div className="flex justify-center items-center mr-2 2xl:mr-2.5 w-6 2xl:w-7.5 h-6 2xl:h-7.5 bg-white border border-blue rounded-full">
                                                                <Icon
                                                                    icon={Icons.Minus}
                                                                    width={18}
                                                                    height={18}
                                                                    color="fill-blue"
                                                                    className="2xl:!w-6 2xl:!h-6"
                                                                />
                                                            </div>
                                                        )}
                                                    {question.type !== "MULTIPLE_OPTIONS" &&
                                                        isShowError &&
                                                        answer.isSelected &&
                                                        answer.isCorrect && (
                                                            <div className="flex justify-center items-center mr-2 2xl:mr-2.5 w-6 2xl:w-7.5 h-6 2xl:h-7.5 bg-blue rounded-full">
                                                                <Icon
                                                                    icon={Icons.Check}
                                                                    width={20}
                                                                    height={20}
                                                                    color="fill-white"
                                                                    className="2xl:!w-6.25 2xl:!h-6.25"
                                                                />
                                                            </div>
                                                        )}
                                                    {question.type !== "MULTIPLE_OPTIONS" &&
                                                        isShowError &&
                                                        !answer.isSelected &&
                                                        !answer.isCorrect && (
                                                            <div className="mr-2 2xl:mr-2.5 w-6 2xl:w-7.5 h-6 2xl:h-7.5 bg-white border border-gray-stroke rounded-full"></div>
                                                        )}
                                                    {question.type === "MULTIPLE_OPTIONS" &&
                                                        isShowError &&
                                                        answer.isSelected &&
                                                        !answer.isCorrect && (
                                                            <div className="mr-2 2xl:mr-2.5 w-6 2xl:w-7.5 h-6 2xl:h-7.5 bg-red rounded-md">
                                                                <Icon
                                                                    icon={Icons.Close}
                                                                    width={24}
                                                                    height={24}
                                                                    color="fill-white"
                                                                    className="2xl:!w-7.5 2xl:!h-7.5"
                                                                />
                                                            </div>
                                                        )}
                                                    {question.type === "MULTIPLE_OPTIONS" &&
                                                        isShowError &&
                                                        !answer.isSelected &&
                                                        answer.isCorrect && (
                                                            <div className="flex justify-center items-center mr-2 2xl:mr-2.5 w-6 2xl:w-7.5 h-6 2xl:h-7.5 bg-white border border-blue rounded-md">
                                                                <Icon
                                                                    icon={Icons.Minus}
                                                                    width={18}
                                                                    height={18}
                                                                    color="fill-blue"
                                                                    className="2xl:!w-6 2xl:!h-6"
                                                                />
                                                            </div>
                                                        )}
                                                    {question.type === "MULTIPLE_OPTIONS" &&
                                                        isShowError &&
                                                        answer.isSelected &&
                                                        answer.isCorrect && (
                                                            <div className="flex justify-center items-center mr-2 2xl:mr-2.5 w-6 2xl:w-7.5 h-6 2xl:h-7.5 bg-blue rounded-md">
                                                                <Icon
                                                                    icon={Icons.Check}
                                                                    width={20}
                                                                    height={20}
                                                                    color="fill-white"
                                                                    className="2xl:!w-6.25 2xl:!h-6.25"
                                                                />
                                                            </div>
                                                        )}
                                                    {question.type === "MULTIPLE_OPTIONS" &&
                                                        isShowError &&
                                                        !answer.isSelected &&
                                                        !answer.isCorrect && (
                                                            <div className="mr-2 2xl:mr-2.5 w-6 2xl:w-7.5 h-6 2xl:h-7.5 bg-white border border-gray-stroke rounded-md"></div>
                                                        )}
                                                    {!isShowError &&
                                                        question.type === "MULTIPLE_OPTIONS" &&
                                                        !answer.isSelected && (
                                                            <div className="mr-2 2xl:mr-2.5 w-6 2xl:w-7.5 h-6 2xl:h-7.5 bg-white border border-gray-stroke rounded-md"></div>
                                                        )}
                                                    {!isShowError &&
                                                        question.type === "MULTIPLE_OPTIONS" &&
                                                        answer.isSelected && (
                                                            <div className="flex justify-center items-center mr-2 2xl:mr-2.5 w-6 2xl:w-7.5 h-6 2xl:h-7.5 bg-blue rounded-md">
                                                                <Icon
                                                                    icon={Icons.Check}
                                                                    width={20}
                                                                    height={20}
                                                                    color="fill-white"
                                                                    className="2xl:!w-6.25 2xl:!h-6.25"
                                                                />
                                                            </div>
                                                        )}
                                                    {!isShowError && question.type !== "MULTIPLE_OPTIONS" && (
                                                        <input
                                                            className={clsx(
                                                                "mr-2 2xl:mr-2.5 cursor-pointer radio w-6 2xl:w-7.5 h-6 2xl:h-7.5 border-gray-background ring-1 ring-input-stroke",
                                                                "focus:ring-offset-0 focus:ring-1 ring-offset-0 focus:ring-blue",
                                                                answer.isSelected
                                                                    ? "bg-blue text-blue border-white border-2 2xl:border-4 ring-1 !ring-blue"
                                                                    : "",
                                                                isShowError && answer.isCorrect
                                                                    ? "bg-blue text-blue border-white border-2 ring-1 !ring-blue"
                                                                    : "",
                                                                isShowError && answer.isSelected && !answer.isCorrect
                                                                    ? "bg-red text-red border-white border-2 ring-1 !ring-red"
                                                                    : "",
                                                            )}
                                                            type="radio"
                                                            readOnly
                                                            checked={!!answer.isSelected}
                                                        />
                                                    )}
                                                    {!isShowError && answer.pictureId && answer.isSelected && (
                                                        <span className="p1-accent text-blue 2xl:text-md">
                                                            Ваш ответ
                                                        </span>
                                                    )}
                                                    {isShowError && answer.pictureId && answer.isCorrect && (
                                                        <span className="p1-accent text-blue 2xl:text-md">
                                                            Верный ответ
                                                        </span>
                                                    )}
                                                    {isShowError &&
                                                        answer.pictureId &&
                                                        answer.isSelected &&
                                                        !answer.isCorrect && (
                                                            <span className="p1-accent text-red 2xl:text-md">
                                                                Неверно
                                                            </span>
                                                        )}
                                                </div>
                                                {answer.text && (
                                                    <span className="text-gray-dark 2xl:text-md">{answer.text}</span>
                                                )}
                                                {answer.pictureId && (
                                                    <img
                                                        className={clsx(
                                                            "mt-1 2xl:mt-1.25 w-32 sm:w-51 2xl:w-[255px] h-32 sm:h-51 2xl:h-[255px] rounded-2lg object-cover",
                                                            answer.isSelected ? "border-2 border-blue" : "",
                                                            isShowError && answer.isCorrect
                                                                ? "border-2 border-blue"
                                                                : "",
                                                            isShowError && answer.isSelected && !answer.isCorrect
                                                                ? "border-2 border-red"
                                                                : "",
                                                        )}
                                                        src={`/service/lms-upload/api/file/download/${answer.pictureId}`}
                                                        alt={answer.id}
                                                    />
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                    <TestingFooter
                        type={test.quizType}
                        questionsCount={test.questions.length}
                        answeredQuestionsCount={test.questions.filter((p) => p.hasAnswer).length}
                        current={test.questions.findIndex((p) => p.id === test.currentQuestionId)}
                        hasAnswer={question.hasAnswer}
                        isShowError={isShowError}
                        isTimeout={isTimeout()}
                        time={test.quizType === "LINEAR" ? questionTime ?? testTime : testTime}
                        canSubmit={
                            (question.customAnswer && question.customAnswer.length !== 0) ||
                            (question.answers && question.answers.filter((p) => p.isSelected).length !== 0)
                        }
                        onPrevious={onPrevious}
                        onSkip={onSkip}
                        onNext={onNext}
                        onSubmit={onSubmit}
                    />
                </>
            )}
        </ContentLayout>
    );
};
