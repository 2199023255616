import AuthApi from "./Services/AuthApi";
import UserApi from "./Services/UserApi";
import MaterialApi from "./Services/MaterialApi";
import MaterialCategoryApi from "./Services/MaterialCategoryApi";
import ProgramApi from "./Services/ProgramApi";
import CourseApi from "./Services/CourseApi";
import SearchApi from "./Services/SearchApi";
import OfficeApi from "./Services/OfficeApi";
import AchievementApi from "./Services/AchievementApi";
import VerificationApi from "./Services/VerificationApi";
import InterviewApi from "./Services/InterviewApi";
import CommentApi from "./Services/CommentApi";
import AccessApi from "./Services/AccessApi";
import TestApi from "./Services/TestApi";
import TaskApi from "./Services/TaskApi";
import TeamApi from "./Services/TeamApi";
import ArchiveApi from "./Services/ArchiveApi";
import RatingApi from "./Services/RatingApi";
import RoleApi from "./Services/RoleApi";
import UploadApi from "./Services/UploadApi";
import FileApi from "./Services/FileApi";
import JobsApi from "./Services/JobsApi";
import TaskCategoryApi from "./Services/TaskCategoryApi";
import PreviewApi from "./Services/PreviewApi";
import LMSRolesApi from "Api/Services/LMSRolesApi";
import TaskPublicApi from "./Services/TaskPublicApi";
import CourseProgressApi from "./Services/CourseProgressApi";
import CommonsApi from "Api/Services/CommonsApi";
import StatisticApi from "Api/Services/StatisticApi";
import ScormApi from "Api/Services/ScormApi";
import CompanyApi from "./Services/CompanyApi";
import ScormProgressApi from "Api/Services/ScormProgressApi";
import SkyTrainerApi from "Api/Services/SkyTrainer";
import FavoriteApi from "./Services/FavoriteApi";
import NotificationApi from "Api/Services/NotificationApi";
import UtilsApi from "./Services/UtilsApi";
import ProgramProgressApi from "./Services/ProgramProgressApi";
import FeedbackApi from "./Services/FeedbackApi";
import InvitesApi from "./Services/InvitesApi";

function Api() {
    return {
        Auth: new AuthApi(""),
        Access: new AccessApi("/api/access/"),
        User: new UserApi("/service/ums/api/v1"),
        Material: new MaterialApi("/service/lms-materials/api"),
        MaterialCategory: new MaterialCategoryApi("/service/lms-materials/api/material-category"),
        Program: new ProgramApi("/service/lms-course/api"),
        ProgramProgress: new ProgramProgressApi("/service/lms-course/api/program-progress"),
        Course: new CourseApi("/service/lms-course/api"),
        CourseProgress: new CourseProgressApi("/service/lms-course/api/course-user"),
        Search: new SearchApi("/service/lms-search/api"),
        Office: new OfficeApi("/service/ums/api/v1"),
        Achievement: new AchievementApi("/service/ums/api/v1"),
        Verification: new VerificationApi("api/verification/"),
        Interview: new InterviewApi("api/interview/"),
        Comment: new CommentApi("api/comment/"),
        Test: new TestApi("/service/lms-quiz/api"),
        Task: new TaskApi("/service/lms-quiz/api/v1/content/exercise"),
        TaskPublic: new TaskPublicApi("/service/lms-quiz/api/v1/content"),
        TaskCategoryApi: new TaskCategoryApi("/service/lms-quiz/api/content/exercise-category"),
        Teams: new TeamApi("/service/ums/api/v1"),
        Archive: new ArchiveApi("api/archive/"),
        Rating: new RatingApi("/service/ums/api/v1"),
        Role: new RoleApi("/service/lms-roles"),
        Upload: new UploadApi("/service/lms-upload/api"),
        File: new FileApi("/service/lms-upload/api/file"),
        ScormFile: new ScormApi("/service/lms-upload/api/scorm"),
        ScormProgress: new ScormProgressApi("/service/lms-course/api/scorm-progress"),
        Preview: new PreviewApi("/service/lms-upload/api/preview"),
        Job: new JobsApi("/service/ums/api/v1"),
        LMSRoles: new LMSRolesApi("/service/lms-roles"),
        Commons: new CommonsApi("/service/lms-commons/api/v1"),
        Statistic: new StatisticApi("/service/lms-statistic/api/v1"),
        CompanyApi: new CompanyApi("/service/lms-company/api/v1"),
        SkyTrainerApi: new SkyTrainerApi(""),
        Favorite: new FavoriteApi("/service/lms-commons/api/v1/"),
        NotificationApi: new NotificationApi("/service/lms-notification/api"),
        UtilsApi: new UtilsApi("/service/lms-quiz/api"),
        Feedback: new FeedbackApi("/service/lms-course/api"),
        Invites: new InvitesApi("/service/ums/api"),
    };
}

export default Api();
