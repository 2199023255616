import React from "react";

export const FileIcons = {
    FileZip: (
        <svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4_38231)">
                <path d="M4 0V24H18L24 18V0H4Z" fill="url(#paint0_linear_4_38231)" />
                <path d="M18 18V24L24 18H18Z" fill="#ABABAB" />
                <path d="M12 24H18V18L12 24Z" fill="#DEDEDD" />
                <path d="M0 6V13H16V6H0Z" fill="#402D7A" />
                <path
                    d="M3.72302 8.07397H2.25202V7.36597H4.71802V8.01997L3.11602 10.942H4.74202V11.65H2.14502V11.02L3.72302 8.07397Z"
                    fill="white"
                />
                <path d="M5.82764 7.36597H6.69164V11.65H5.82764V7.36597Z" fill="white" />
                <path
                    d="M9.44608 7.36499C9.66608 7.36499 9.85008 7.40299 10.0011 7.47699C10.1521 7.55099 10.2721 7.64799 10.3641 7.76799C10.4561 7.88799 10.5221 8.02499 10.5621 8.17899C10.6011 8.33199 10.6221 8.48999 10.6221 8.64999C10.6221 8.86999 10.5891 9.06199 10.5231 9.22599C10.4571 9.38999 10.3651 9.52399 10.2471 9.62699C10.1291 9.73099 9.98608 9.80999 9.81808 9.86099C9.65008 9.91199 9.46408 9.93899 9.25908 9.93899H8.79708V11.649H7.93408V7.36499H9.44608ZM9.18208 9.30599C9.35408 9.30599 9.49408 9.25299 9.59908 9.14899C9.70508 9.04499 9.75808 8.88299 9.75808 8.66299C9.75808 8.44699 9.71208 8.28299 9.62008 8.17099C9.52808 8.05999 9.37408 8.00299 9.15808 8.00299H8.79808V9.30599H9.18208Z"
                    fill="white"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_4_38231"
                    x1="6.83325"
                    y1="4.83375"
                    x2="33.2568"
                    y2="31.2552"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EFEEEE" />
                    <stop offset="1" stopColor="#DEDEDD" />
                </linearGradient>
                <clipPath id="clip0_4_38231">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    ),
    FileXml: (
        <svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4_38240)">
                <path d="M4 0V24H18L24 18V0H4Z" fill="#EFEEEE" />
                <path d="M18 18V24L24 18H18Z" fill="#ABABAB" />
                <path d="M12 24H18V18L12 24Z" fill="#DEDEDD" />
                <path d="M0 6V13H16V6H0Z" fill="#CB5641" />
                <path
                    d="M3.07413 7.36597L3.62613 8.75197L4.17214 7.36597H5.11414L4.11814 9.48397L5.18614 11.65H4.21414L3.59713 10.198L2.98413 11.65H2.03613L3.10413 9.48397L2.12013 7.36597H3.07413Z"
                    fill="white"
                />
                <path
                    d="M7.42414 7.36597L8.03614 10.39H8.04814L8.66614 7.36597H9.94414V11.65H9.15214V8.22997H9.14014L8.37814 11.65H7.70614L6.94414 8.22997H6.93214V11.65H6.14014V7.36597H7.42414Z"
                    fill="white"
                />
                <path d="M11.1611 7.36597H12.0261V10.942H13.5551V11.65H11.1611V7.36597Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_4_38240">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    ),
    FileXls: (
        <svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4_38249)">
                <path d="M4 0V24H18L24 18V0H4Z" fill="#EFEEEE" />
                <path d="M18 18V24L24 18H18Z" fill="#ABABAB" />
                <path d="M12 24H18V18L12 24Z" fill="#DEDEDD" />
                <path d="M0 6V13H16V6H0Z" fill="#61B565" />
                <path
                    d="M3.07413 7.36597L3.62613 8.75197L4.17214 7.36597H5.11414L4.11814 9.48397L5.18614 11.65H4.21414L3.59713 10.198L2.98413 11.65H2.03613L3.10413 9.48397L2.12013 7.36597H3.07413Z"
                    fill="white"
                />
                <path d="M6.12207 7.36597H6.98607V10.942H8.51506V11.65H6.12207V7.36597Z" fill="white" />
                <path
                    d="M10.196 10.438C10.196 10.538 10.204 10.629 10.219 10.71C10.236 10.792 10.265 10.861 10.309 10.917C10.353 10.974 10.413 11.018 10.487 11.05C10.56 11.081 10.654 11.098 10.765 11.098C10.898 11.098 11.016 11.055 11.122 10.969C11.227 10.883 11.281 10.749 11.281 10.57C11.281 10.474 11.267 10.391 11.242 10.321C11.216 10.251 11.173 10.188 11.113 10.133C11.052 10.076 10.973 10.026 10.876 9.97999C10.779 9.93399 10.657 9.88699 10.513 9.83899C10.322 9.77499 10.156 9.70399 10.015 9.62799C9.87503 9.55299 9.75803 9.46399 9.66403 9.36099C9.57003 9.25899 9.50103 9.14099 9.45703 9.00699C9.41303 8.87198 9.39103 8.71799 9.39103 8.54098C9.39103 8.11698 9.50903 7.80198 9.74503 7.59398C9.98003 7.38598 10.305 7.28198 10.717 7.28198C10.909 7.28198 11.086 7.30298 11.248 7.34498C11.41 7.38698 11.55 7.45398 11.668 7.54898C11.786 7.64298 11.878 7.76298 11.944 7.90798C12.009 8.05398 12.043 8.22898 12.043 8.43298V8.55298H11.215C11.215 8.34898 11.179 8.19198 11.108 8.08098C11.036 7.97198 10.916 7.91598 10.748 7.91598C10.652 7.91598 10.572 7.93098 10.508 7.95798C10.445 7.98598 10.393 8.02298 10.355 8.06898C10.317 8.11498 10.292 8.16798 10.278 8.22798C10.263 8.28798 10.257 8.34998 10.257 8.41398C10.257 8.54698 10.285 8.65698 10.341 8.74698C10.397 8.83798 10.517 8.92099 10.701 8.99599L11.367 9.28399C11.531 9.35599 11.665 9.43099 11.768 9.50999C11.872 9.58799 11.956 9.67199 12.017 9.76199C12.079 9.85199 12.122 9.94999 12.146 10.059C12.17 10.166 12.182 10.287 12.182 10.418C12.182 10.87 12.051 11.199 11.789 11.405C11.527 11.611 11.162 11.715 10.694 11.715C10.206 11.715 9.85703 11.609 9.64603 11.397C9.43603 11.185 9.33203 10.881 9.33203 10.485V10.311H10.196V10.438Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_4_38249">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    ),
    FileXlsx: (
        <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_31541_79196)">
                <path d="M4 0V24H18L24 18V0H4Z" fill="#EFEEEE" />
                <path d="M18 18V24L24 18H18Z" fill="#ABABAB" />
                <path d="M12 24H18V18L12 24Z" fill="#DEDEDD" />
                <path d="M0 6V13H16V6H0Z" fill="#6ED373" />
                <path
                    d="M2.60099 7.28369L3.15299 8.66969L3.69899 7.28369H4.64099L3.64499 9.40169L4.71299 11.5677H3.74099L3.12399 10.1157L2.51099 11.5677H1.56299L2.63099 9.40169L1.64699 7.28369H2.60099Z"
                    fill="white"
                />
                <path d="M5.4917 7.28369H6.3557V10.8597H7.88469V11.5677H5.4917V7.28369Z" fill="white" />
                <path
                    d="M9.15795 10.4397C9.15795 10.5397 9.16595 10.6307 9.18095 10.7117C9.19795 10.7937 9.22695 10.8627 9.27095 10.9187C9.31495 10.9757 9.37495 11.0197 9.44895 11.0517C9.52195 11.0827 9.61595 11.0997 9.72695 11.0997C9.85995 11.0997 9.97795 11.0567 10.0839 10.9707C10.1889 10.8847 10.2429 10.7507 10.2429 10.5717C10.2429 10.4757 10.2289 10.3927 10.2039 10.3227C10.1779 10.2527 10.1349 10.1897 10.0749 10.1347C10.0139 10.0777 9.93495 10.0277 9.83795 9.9817C9.74095 9.9357 9.61895 9.8887 9.47495 9.8407C9.28395 9.7767 9.11795 9.7057 8.97695 9.6297C8.83695 9.5547 8.71995 9.4657 8.62595 9.36269C8.53195 9.26069 8.46295 9.14269 8.41895 9.00869C8.37495 8.87369 8.35295 8.71969 8.35295 8.54269C8.35295 8.11869 8.47095 7.80369 8.70695 7.59569C8.94195 7.38769 9.26695 7.28369 9.67895 7.28369C9.87095 7.28369 10.0479 7.30469 10.2099 7.34669C10.3719 7.38869 10.5119 7.45569 10.6299 7.55069C10.7479 7.64469 10.8399 7.76469 10.9059 7.90969C10.9709 8.05569 11.0049 8.23069 11.0049 8.43469V8.55469H10.1769C10.1769 8.35069 10.1409 8.19369 10.0699 8.08269C9.99795 7.97369 9.87795 7.91769 9.70995 7.91769C9.61395 7.91769 9.53395 7.93269 9.46995 7.95969C9.40695 7.98769 9.35495 8.02469 9.31695 8.07069C9.27895 8.11669 9.25395 8.16969 9.23995 8.22969C9.22495 8.28969 9.21895 8.35169 9.21895 8.41569C9.21895 8.54869 9.24695 8.65869 9.30295 8.74869C9.35895 8.83969 9.47895 8.92269 9.66295 8.99769L10.3289 9.2857C10.4929 9.3577 10.6269 9.4327 10.7299 9.5117C10.8339 9.5897 10.9179 9.6737 10.9789 9.7637C11.0409 9.8537 11.0839 9.9517 11.1079 10.0607C11.1319 10.1677 11.1439 10.2887 11.1439 10.4197C11.1439 10.8717 11.0129 11.2007 10.7509 11.4067C10.4889 11.6127 10.1239 11.7167 9.65595 11.7167C9.16795 11.7167 8.81895 11.6107 8.60795 11.3987C8.39795 11.1867 8.29395 10.8827 8.29395 10.4867V10.3127H9.15795V10.4397Z"
                    fill="white"
                />
                <path
                    d="M12.5912 7.28369L13.1432 8.66969L13.6892 7.28369H14.6312L13.6352 9.40169L14.7032 11.5677H13.7312L13.1142 10.1157L12.5012 11.5677H11.5532L12.6212 9.40169L11.6372 7.28369H12.5912Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_31541_79196">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    ),
    FileTxt: (
        <svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4_38267)">
                <path d="M4 0V24H18L24 18V0H4Z" fill="#EFEEEE" />
                <path d="M18 18V24L24 18H18Z" fill="#ABABAB" />
                <path d="M12 24H18V18L12 24Z" fill="#DEDEDD" />
                <path d="M0 6V13H16V6H0Z" fill="#15498A" />
                <path
                    d="M4.80778 8.07397H3.87178V11.65H3.00778V8.07397H2.07178V7.36597H4.80778V8.07397Z"
                    fill="white"
                />
                <path
                    d="M6.5546 7.36597L7.1066 8.75197L7.6526 7.36597H8.5936L7.5986 9.48397L8.6656 11.65H7.6936L7.0756 10.198L6.4636 11.65H5.5166L6.5836 9.48397L5.6006 7.36597H6.5546Z"
                    fill="white"
                />
                <path
                    d="M12.1095 8.07397H11.1725V11.65H10.3095V8.07397H9.37354V7.36597H12.1095V8.07397Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_4_38267">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    ),
    FileRar: (
        <svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4_38276)">
                <path d="M4 0V24H18L24 18V0H4Z" fill="url(#paint0_linear_4_38276)" />
                <path d="M18 18V24L24 18H18Z" fill="#ABABAB" />
                <path d="M12 24H18V18L12 24Z" fill="#DEDEDD" />
                <path d="M0 6V13H16V6H0Z" fill="#682767" />
                <path
                    d="M3.89581 7.36597C4.25481 7.36597 4.53581 7.45797 4.73581 7.63997C4.93581 7.82197 5.03581 8.09897 5.03581 8.47097C5.03581 8.75897 4.97781 8.99297 4.86181 9.17597C4.74581 9.35797 4.56581 9.47497 4.32181 9.52697V9.53897C4.53781 9.57097 4.69581 9.64197 4.79581 9.74897C4.89681 9.85697 4.95781 10.031 4.98281 10.271C4.98981 10.351 4.99581 10.438 4.99981 10.532C5.00381 10.626 5.00781 10.731 5.01281 10.847C5.02081 11.075 5.03281 11.248 5.04781 11.368C5.07181 11.488 5.12381 11.57 5.20381 11.614V11.65H4.26881C4.22481 11.59 4.19681 11.521 4.18481 11.443C4.17181 11.365 4.16381 11.284 4.16081 11.2L4.13781 10.378C4.12881 10.21 4.08681 10.078 4.01081 9.98197C3.93581 9.88597 3.80781 9.83797 3.62681 9.83797H3.16381V11.65H2.2998V7.36597H3.89581ZM3.52381 9.23897C3.73181 9.23897 3.89181 9.18997 4.00281 9.09297C4.11481 8.99397 4.17081 8.82897 4.17081 8.59797C4.17081 8.20197 3.97181 8.00297 3.57081 8.00297H3.16381V9.23897H3.52381Z"
                    fill="white"
                />
                <path
                    d="M7.8978 7.36694L9.0318 11.651H8.1318L7.9338 10.7449H6.7938L6.5958 11.651H5.6958L6.8298 7.36694H7.8978ZM7.7838 10.0369L7.3698 8.11094H7.3578L6.9438 10.0369H7.7838Z"
                    fill="white"
                />
                <path
                    d="M11.2879 7.36597C11.6479 7.36597 11.9289 7.45797 12.1279 7.63997C12.3279 7.82197 12.4289 8.09897 12.4289 8.47097C12.4289 8.75897 12.3699 8.99297 12.2539 9.17597C12.1389 9.35797 11.9579 9.47497 11.7139 9.52697V9.53897C11.9309 9.57097 12.0879 9.64197 12.1889 9.74897C12.2889 9.85697 12.3509 10.031 12.3749 10.271C12.3829 10.351 12.3899 10.438 12.3939 10.532C12.3979 10.626 12.4019 10.731 12.4059 10.847C12.4139 11.075 12.4259 11.248 12.4419 11.368C12.4649 11.488 12.5179 11.57 12.5979 11.614V11.65H11.6599C11.6159 11.59 11.5879 11.521 11.5759 11.443C11.5639 11.365 11.5559 11.284 11.5529 11.2L11.5289 10.378C11.5209 10.21 11.4789 10.078 11.4029 9.98197C11.3269 9.88597 11.1989 9.83797 11.0189 9.83797H10.5569V11.65H9.69287V7.36597H11.2879ZM10.9159 9.23897C11.1239 9.23897 11.2839 9.18997 11.3959 9.09297C11.5069 8.99397 11.5639 8.82897 11.5639 8.59797C11.5639 8.20197 11.3639 8.00297 10.9629 8.00297H10.5559V9.23897H10.9159Z"
                    fill="white"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_4_38276"
                    x1="6.8329"
                    y1="4.8336"
                    x2="33.2564"
                    y2="31.2546"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EFEEEE" />
                    <stop offset="1" stopColor="#DEDEDD" />
                </linearGradient>
                <clipPath id="clip0_4_38276">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    ),
    FilePpt: (
        <svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4_38285)">
                <path d="M4 0V24H18L24 18V0H4Z" fill="#EFEEEE" />
                <path d="M18 18V24L24 18H18Z" fill="#ABABAB" />
                <path d="M12 24H18V18L12 24Z" fill="#DEDEDD" />
                <path d="M0 6V13H16V6H0Z" fill="#CB7B29" />
                <path
                    d="M3.81181 7.36597C4.03181 7.36597 4.21581 7.40297 4.36681 7.47697C4.51781 7.55097 4.63781 7.64797 4.72981 7.76797C4.82181 7.88797 4.88781 8.02497 4.92781 8.17897C4.96681 8.33197 4.98781 8.48997 4.98781 8.64997C4.98781 8.86997 4.95481 9.06197 4.88881 9.22597C4.82281 9.38997 4.73081 9.52397 4.61281 9.62697C4.49481 9.73097 4.35181 9.80997 4.18381 9.86097C4.01581 9.91197 3.82981 9.93997 3.62581 9.93997H3.16381V11.65H2.2998V7.36597H3.81181ZM3.54781 9.30597C3.71981 9.30597 3.85981 9.25297 3.96481 9.14897C4.07081 9.04497 4.12381 8.88297 4.12381 8.66297C4.12381 8.44697 4.07781 8.28297 3.98581 8.17097C3.89381 8.05997 3.73981 8.00297 3.52381 8.00297H3.16381V9.30597H3.54781Z"
                    fill="white"
                />
                <path
                    d="M7.52567 7.36597C7.74567 7.36597 7.92967 7.40297 8.08067 7.47697C8.23167 7.55097 8.35167 7.64797 8.44367 7.76797C8.53567 7.88797 8.60167 8.02497 8.64167 8.17897C8.68067 8.33197 8.70167 8.48997 8.70167 8.64997C8.70167 8.86997 8.66867 9.06197 8.60267 9.22597C8.53667 9.38997 8.44467 9.52397 8.32667 9.62697C8.20867 9.73097 8.06567 9.80997 7.89767 9.86097C7.72967 9.91197 7.54367 9.93997 7.33967 9.93997H6.87767V11.65H6.01367V7.36597H7.52567ZM7.26167 9.30597C7.43367 9.30597 7.57367 9.25297 7.67867 9.14897C7.78467 9.04497 7.83767 8.88297 7.83767 8.66297C7.83767 8.44697 7.79167 8.28297 7.69967 8.17097C7.60767 8.05997 7.45367 8.00297 7.23767 8.00297H6.87767V9.30597H7.26167Z"
                    fill="white"
                />
                <path d="M12.236 8.07397H11.3V11.65H10.436V8.07397H9.5V7.36597H12.236V8.07397Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_4_38285">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    ),
    FilePptx: (
        <svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4_38294)">
                <path d="M4 0V24H18L24 18V0H4Z" fill="#EFEEEE" />
                <path d="M18 18V24L24 18H18Z" fill="#ABABAB" />
                <path d="M12 24H18V18L12 24Z" fill="#DEDEDD" />
                <path d="M0 6V13H18V6H0Z" fill="#A96C2D" />
                <path
                    d="M13.7743 7.36597L14.3263 8.75197L14.8723 7.36597H15.8143L14.8183 9.48397L15.8863 11.65H14.9143L14.2973 10.198L13.6843 11.65H12.7363L13.8043 9.48397L12.8203 7.36597H13.7743Z"
                    fill="white"
                />
                <path
                    d="M3.512 7.36597C3.732 7.36597 3.916 7.40297 4.067 7.47697C4.218 7.55097 4.338 7.64797 4.43 7.76797C4.522 7.88797 4.588 8.02497 4.628 8.17897C4.667 8.33197 4.688 8.48997 4.688 8.64997C4.688 8.86997 4.655 9.06197 4.589 9.22597C4.523 9.38997 4.431 9.52397 4.313 9.62697C4.195 9.73097 4.052 9.80997 3.884 9.86097C3.716 9.91197 3.53 9.93997 3.326 9.93997H2.864V11.65H2V7.36597H3.512ZM3.248 9.30597C3.42 9.30597 3.56 9.25297 3.665 9.14897C3.771 9.04497 3.824 8.88297 3.824 8.66297C3.824 8.44697 3.778 8.28297 3.686 8.17097C3.594 8.05997 3.44 8.00297 3.224 8.00297H2.864V9.30597H3.248Z"
                    fill="white"
                />
                <path
                    d="M7.22587 7.36597C7.44587 7.36597 7.62987 7.40297 7.78087 7.47697C7.93187 7.55097 8.05187 7.64797 8.14387 7.76797C8.23587 7.88797 8.30187 8.02497 8.34187 8.17897C8.38087 8.33197 8.40187 8.48997 8.40187 8.64997C8.40187 8.86997 8.36887 9.06197 8.30287 9.22597C8.23687 9.38997 8.14487 9.52397 8.02687 9.62697C7.90887 9.73097 7.76587 9.80997 7.59787 9.86097C7.42987 9.91197 7.24387 9.93997 7.03987 9.93997H6.57787V11.65H5.71387V7.36597H7.22587ZM6.96187 9.30597C7.13387 9.30597 7.27387 9.25297 7.37887 9.14897C7.48487 9.04497 7.53787 8.88297 7.53787 8.66297C7.53787 8.44697 7.49187 8.28297 7.39987 8.17097C7.30787 8.05997 7.15387 8.00297 6.93787 8.00297H6.57787V9.30597H6.96187Z"
                    fill="white"
                />
                <path d="M11.9362 8.07397H11.0002V11.65H10.1362V8.07397H9.2002V7.36597H11.9362V8.07397Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_4_38294">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    ),
    FilePng: (
        <svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4_38304)">
                <path d="M4 0V24H18L24 18V0H4Z" fill="url(#paint0_linear_4_38304)" />
                <path d="M18 18V24L24 18H18Z" fill="#ABABAB" />
                <path d="M12 24H18V18L12 24Z" fill="#DEDEDD" />
                <path d="M0 6V13H16V6H0Z" fill="#CB5641" />
                <path
                    d="M3.81181 7.36597C4.03181 7.36597 4.21581 7.40297 4.36681 7.47697C4.51781 7.55097 4.63781 7.64797 4.72981 7.76797C4.82181 7.88797 4.88781 8.02497 4.92781 8.17897C4.96681 8.33197 4.98781 8.48997 4.98781 8.64997C4.98781 8.86997 4.95481 9.06197 4.88881 9.22597C4.82281 9.38997 4.73081 9.52397 4.61281 9.62697C4.49481 9.73097 4.35181 9.80997 4.18381 9.86097C4.01581 9.91197 3.82981 9.93997 3.62581 9.93997H3.16381V11.65H2.2998V7.36597H3.81181ZM3.54781 9.30597C3.71981 9.30597 3.85981 9.25297 3.96481 9.14897C4.07081 9.04497 4.12381 8.88297 4.12381 8.66297C4.12381 8.44697 4.07781 8.28297 3.98581 8.17097C3.89381 8.05997 3.73981 8.00297 3.52381 8.00297H3.16381V9.30597H3.54781Z"
                    fill="white"
                />
                <path
                    d="M8.05367 10.301H8.06667V7.36597H8.85867V11.65H7.87367L6.81767 8.64997H6.80567V11.65H6.01367V7.36597H7.00967L8.05367 10.301Z"
                    fill="white"
                />
                <path
                    d="M11.9326 8.39301C11.9146 8.30901 11.8856 8.23201 11.8456 8.16201C11.8056 8.09301 11.7546 8.03401 11.6936 7.98801C11.6316 7.94201 11.5556 7.91901 11.4676 7.91901C11.2596 7.91901 11.1106 8.03501 11.0176 8.26801C10.9256 8.49901 10.8796 8.88401 10.8796 9.41901C10.8796 9.67601 10.8876 9.90701 10.9036 10.115C10.9196 10.323 10.9486 10.5 10.9906 10.646C11.0326 10.792 11.0926 10.904 11.1706 10.982C11.2486 11.06 11.3496 11.099 11.4736 11.099C11.5266 11.099 11.5826 11.084 11.6456 11.057C11.7076 11.029 11.7646 10.987 11.8196 10.931C11.8736 10.874 11.9186 10.804 11.9546 10.718C11.9906 10.632 12.0086 10.53 12.0086 10.414V9.97601H11.4396V9.34001H12.8366V11.65H12.1996V11.254H12.1876C12.0836 11.423 11.9586 11.541 11.8126 11.611C11.6666 11.681 11.4916 11.716 11.2876 11.716C11.0236 11.716 10.8086 11.669 10.6416 11.577C10.4746 11.485 10.3456 11.344 10.2516 11.151C10.1576 10.958 10.0946 10.722 10.0636 10.44C10.0316 10.158 10.0146 9.83101 10.0146 9.45901C10.0146 9.10001 10.0376 8.78301 10.0836 8.51201C10.1296 8.24001 10.2086 8.01301 10.3206 7.83001C10.4316 7.64801 10.5806 7.51201 10.7636 7.41901C10.9486 7.32701 11.1776 7.28101 11.4536 7.28101C11.9256 7.28101 12.2656 7.39801 12.4736 7.63301C12.6816 7.86601 12.7856 8.20101 12.7856 8.63801H11.9596C11.9596 8.55901 11.9506 8.47701 11.9326 8.39301Z"
                    fill="white"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_4_38304"
                    x1="6.8331"
                    y1="4.83353"
                    x2="33.2581"
                    y2="31.257"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EFEEEE" />
                    <stop offset="1" stopColor="#DEDEDD" />
                </linearGradient>
                <clipPath id="clip0_4_38304">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    ),
    FilePdf: (
        <svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4_38313)">
                <path d="M4 0V24H18L24 18V0H4Z" fill="#EFEEEE" />
                <path d="M18 18V24L24 18H18Z" fill="#ABABAB" />
                <path d="M12 24H18V18L12 24Z" fill="#DEDEDD" />
                <path d="M0 6V13H16V6H0Z" fill="#CB5641" />
                <path
                    d="M3.81181 7.36597C4.03181 7.36597 4.21581 7.40297 4.36681 7.47697C4.51781 7.55097 4.63781 7.64797 4.72981 7.76797C4.82181 7.88797 4.88781 8.02497 4.92781 8.17897C4.96681 8.33197 4.98781 8.48997 4.98781 8.64997C4.98781 8.86997 4.95481 9.06197 4.88881 9.22597C4.82281 9.38997 4.73081 9.52397 4.61281 9.62697C4.49481 9.73097 4.35181 9.80997 4.18381 9.86097C4.01581 9.91197 3.82981 9.93997 3.62581 9.93997H3.16381V11.65H2.2998V7.36597H3.81181ZM3.54781 9.30597C3.71981 9.30597 3.85981 9.25297 3.96481 9.14897C4.07081 9.04497 4.12381 8.88297 4.12381 8.66297C4.12381 8.44697 4.07781 8.28297 3.98581 8.17097C3.89381 8.05997 3.73981 8.00297 3.52381 8.00297H3.16381V9.30597H3.54781Z"
                    fill="white"
                />
                <path
                    d="M7.47166 7.36499C7.77266 7.36499 8.01366 7.41499 8.19866 7.51299C8.38266 7.61099 8.52566 7.74999 8.62766 7.93299C8.73066 8.11499 8.79866 8.33299 8.83466 8.58999C8.87066 8.84599 8.88866 9.13199 8.88866 9.44699C8.88866 9.82299 8.86666 10.15 8.82066 10.428C8.77466 10.705 8.69566 10.935 8.58366 11.115C8.47166 11.296 8.32166 11.429 8.13366 11.517C7.94566 11.605 7.70766 11.649 7.41966 11.649H6.05566V7.36499H7.47166ZM7.30366 11.015C7.45566 11.015 7.57766 10.989 7.66966 10.937C7.76166 10.885 7.83466 10.799 7.88866 10.679C7.94366 10.559 7.97966 10.401 7.99666 10.204C8.01466 10.009 8.02366 9.76499 8.02366 9.47299C8.02366 9.22899 8.01466 9.01499 7.99966 8.82999C7.98366 8.64599 7.95066 8.49299 7.90066 8.37099C7.84966 8.24899 7.77666 8.15699 7.67766 8.09599C7.57966 8.03399 7.44866 8.00199 7.28466 8.00199H6.91966V11.015H7.30366Z"
                    fill="white"
                />
                <path
                    d="M12.4516 7.36597V8.07397H10.9216V9.08197H12.3616V9.78997H10.9216V11.65H10.0576V7.36597H12.4516Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_4_38313">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    ),
    FileMp3: (
        <svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4_38322)">
                <path d="M4 0V24H18L24 18V0H4Z" fill="url(#paint0_linear_4_38322)" />
                <path d="M18 18V24L24 18H18Z" fill="#ABABAB" />
                <path d="M12 24H18V18L12 24Z" fill="#DEDEDD" />
                <path d="M0 6V13H16V6H0Z" fill="#527CA3" />
                <path
                    d="M3.60287 7.36597L4.21387 10.39H4.22687L4.84487 7.36597H6.12187V11.65H5.32987V8.22997H5.31787L4.55587 11.65H3.88387L3.12187 8.22997H3.10987V11.65H2.31787V7.36597H3.60287Z"
                    fill="white"
                />
                <path
                    d="M8.85284 7.36499C9.07284 7.36499 9.25684 7.40299 9.40784 7.47699C9.55884 7.55099 9.67884 7.64799 9.77084 7.76799C9.86284 7.88799 9.92884 8.02499 9.96884 8.17899C10.0078 8.33199 10.0288 8.48999 10.0288 8.64999C10.0288 8.86999 9.99584 9.06199 9.92984 9.22599C9.86384 9.38999 9.77184 9.52399 9.65384 9.62699C9.53584 9.73099 9.39284 9.80999 9.22484 9.86099C9.05684 9.91199 8.87084 9.93899 8.66584 9.93899H8.20384V11.649H7.33984V7.36499H8.85284ZM8.58784 9.30599C8.75984 9.30599 8.89984 9.25299 9.00484 9.14899C9.11084 9.04499 9.16384 8.88299 9.16384 8.66299C9.16384 8.44699 9.11784 8.28299 9.02584 8.17099C8.93384 8.05999 8.77984 8.00299 8.56384 8.00299H8.20384V9.30599H8.58784Z"
                    fill="white"
                />
                <path
                    d="M11.8011 10.9761C11.8631 11.1061 11.9891 11.1701 12.1761 11.1701C12.2801 11.1701 12.3641 11.1521 12.4251 11.1161C12.4871 11.0801 12.5351 11.0311 12.5701 10.9701C12.6041 10.9081 12.6261 10.8341 12.6351 10.7501C12.6461 10.6661 12.6511 10.5781 12.6511 10.4861C12.6511 10.3901 12.6441 10.2991 12.6301 10.2141C12.6161 10.1271 12.5891 10.0511 12.5491 9.98308C12.5091 9.91508 12.4531 9.86108 12.3811 9.82108C12.3101 9.78108 12.2131 9.76108 12.0931 9.76108H11.8051V9.19708H12.0871C12.1791 9.19708 12.2561 9.17808 12.3181 9.14008C12.3801 9.10208 12.4291 9.05208 12.4671 8.99008C12.5051 8.92808 12.5331 8.85708 12.5511 8.77708C12.5701 8.69708 12.5781 8.61308 12.5781 8.52508C12.5781 8.32108 12.5441 8.17708 12.4761 8.09308C12.4081 8.00908 12.3031 7.96708 12.1641 7.96708C12.0721 7.96708 11.9971 7.98408 11.9391 8.01808C11.8801 8.05208 11.8351 8.10008 11.8031 8.16208C11.7721 8.22408 11.7501 8.29808 11.7411 8.38208C11.7321 8.46608 11.7261 8.55808 11.7261 8.65708H10.9341C10.9341 8.23308 11.0391 7.91808 11.2511 7.71208C11.4631 7.50708 11.7731 7.40308 12.1821 7.40308C12.5701 7.40308 12.8701 7.49108 13.0841 7.66708C13.2981 7.84408 13.4051 8.11908 13.4051 8.49508C13.4051 8.75108 13.3481 8.96008 13.2341 9.12208C13.1201 9.28408 12.9611 9.38808 12.7561 9.43208V9.44408C13.0321 9.48808 13.2281 9.59908 13.3411 9.77708C13.4551 9.95508 13.5121 10.1781 13.5121 10.4461C13.5121 10.5911 13.4941 10.7381 13.4581 10.8901C13.4221 11.0411 13.3531 11.1801 13.2481 11.3041C13.1441 11.4281 13.0011 11.5281 12.8191 11.6041C12.6361 11.6801 12.3981 11.7181 12.1021 11.7181C11.6901 11.7181 11.3811 11.6061 11.1771 11.3821C10.9741 11.1571 10.8711 10.8401 10.8711 10.4281V10.4091H11.6991C11.7061 10.6561 11.7391 10.8461 11.8011 10.9761Z"
                    fill="white"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_4_38322"
                    x1="6.83345"
                    y1="4.83361"
                    x2="33.2565"
                    y2="31.2561"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EFEEEE" />
                    <stop offset="1" stopColor="#DEDEDD" />
                </linearGradient>
                <clipPath id="clip0_4_38322">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    ),
    FileJson: (
        <svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4_38331)">
                <path d="M4 0V24H18L24 18V0H4Z" fill="#EFEEEE" />
                <path d="M18 18V24L24 18H18Z" fill="#ABABAB" />
                <path d="M12 24H18V18L12 24Z" fill="#DEDEDD" />
                <path d="M0 6V13H16V6H0Z" fill="#CB5641" />
                <path
                    d="M3.42988 10.45C3.42988 10.906 3.32488 11.231 3.11188 11.426C2.90088 11.619 2.57688 11.717 2.14088 11.717C1.91188 11.717 1.72488 11.686 1.57888 11.623C1.43288 11.561 1.31588 11.474 1.22988 11.359C1.14388 11.244 1.08488 11.112 1.05388 10.96C1.02088 10.809 1.00488 10.648 1.00488 10.481V10.294H1.79688V10.427C1.79688 10.655 1.82088 10.823 1.87188 10.934C1.92188 11.043 2.02388 11.099 2.18088 11.099C2.33788 11.099 2.44088 11.043 2.49088 10.934C2.54088 10.824 2.56588 10.655 2.56588 10.427V7.36597H3.42988C3.42988 7.36597 3.42988 10.45 3.42988 10.45Z"
                    fill="white"
                />
                <path
                    d="M5.05006 10.438C5.05006 10.538 5.05906 10.629 5.07406 10.71C5.09006 10.792 5.12006 10.861 5.16406 10.917C5.20806 10.974 5.26806 11.018 5.34206 11.05C5.41506 11.081 5.50806 11.098 5.62006 11.098C5.75206 11.098 5.87106 11.055 5.97606 10.969C6.08206 10.883 6.13606 10.749 6.13606 10.57C6.13606 10.474 6.12206 10.391 6.09706 10.321C6.07106 10.251 6.02806 10.188 5.96806 10.133C5.90706 10.076 5.82806 10.026 5.73006 9.97999C5.63206 9.93399 5.51106 9.88699 5.36806 9.83899C5.17606 9.77499 5.01006 9.70399 4.87006 9.62799C4.73006 9.55299 4.61306 9.46399 4.51906 9.36099C4.42506 9.25899 4.35506 9.14099 4.31206 9.00699C4.26706 8.87198 4.24606 8.71799 4.24606 8.54098C4.24606 8.11698 4.36406 7.80198 4.60006 7.59398C4.83606 7.38598 5.16106 7.28198 5.57306 7.28198C5.76406 7.28198 5.94206 7.30298 6.10406 7.34498C6.26606 7.38698 6.40606 7.45398 6.52406 7.54898C6.64106 7.64298 6.73406 7.76298 6.79906 7.90798C6.86506 8.05398 6.89906 8.22898 6.89906 8.43298V8.55298H6.07006C6.07006 8.34898 6.03406 8.19198 5.96306 8.08098C5.89106 7.97198 5.77006 7.91598 5.60206 7.91598C5.50606 7.91598 5.42606 7.93098 5.36306 7.95798C5.30006 7.98598 5.24806 8.02298 5.21006 8.06898C5.17206 8.11498 5.14506 8.16998 5.13106 8.22998C5.11706 8.28998 5.11006 8.35198 5.11006 8.41598C5.11006 8.54898 5.13806 8.65898 5.19406 8.74898C5.25006 8.83998 5.37006 8.92299 5.55406 8.99799L6.22006 9.28599C6.38406 9.35799 6.51706 9.43299 6.62106 9.51199C6.72506 9.59099 6.80806 9.67399 6.87006 9.76399C6.93206 9.85399 6.97706 9.95199 7.00006 10.061C7.02406 10.168 7.03606 10.289 7.03606 10.42C7.03606 10.872 6.90506 11.201 6.64206 11.407C6.38006 11.613 6.01506 11.717 5.54806 11.717C5.06006 11.717 4.71006 11.611 4.50006 11.399C4.29006 11.187 4.18506 10.883 4.18506 10.487V10.313H5.05006V10.438Z"
                    fill="white"
                />
                <path
                    d="M7.78279 8.61098C7.81179 8.33798 7.87879 8.10198 7.97979 7.90598C8.08279 7.71098 8.22979 7.55698 8.42079 7.44698C8.61179 7.33798 8.87079 7.28198 9.19379 7.28198C9.51779 7.28198 9.77579 7.33798 9.96779 7.44698C10.1598 7.55698 10.3068 7.71098 10.4078 7.90598C10.5108 8.10198 10.5758 8.33798 10.6068 8.61098C10.6358 8.88499 10.6518 9.18399 10.6518 9.50799C10.6518 9.83499 10.6358 10.136 10.6068 10.407C10.5758 10.679 10.5098 10.913 10.4078 11.109C10.3058 11.305 10.1598 11.455 9.96779 11.559C9.77579 11.663 9.51779 11.715 9.19379 11.715C8.87079 11.715 8.61179 11.662 8.42079 11.559C8.22979 11.455 8.08179 11.305 7.97979 11.109C7.87779 10.913 7.81179 10.679 7.78279 10.407C7.75279 10.136 7.73779 9.83499 7.73779 9.50799C7.73779 9.18499 7.75279 8.88599 7.78279 8.61098ZM8.62279 10.243C8.63679 10.445 8.66479 10.609 8.70679 10.735C8.74879 10.861 8.80879 10.953 8.88679 11.011C8.96479 11.069 9.06879 11.098 9.19579 11.098C9.32479 11.098 9.42679 11.069 9.50579 11.011C9.58379 10.952 9.64379 10.861 9.68679 10.735C9.72779 10.609 9.75579 10.445 9.77079 10.243C9.78479 10.041 9.79179 9.79699 9.79179 9.50899C9.79179 9.22099 9.78479 8.97699 9.77079 8.77699C9.75579 8.57699 9.72779 8.41298 9.68679 8.28498C9.64379 8.15698 9.58379 8.06398 9.50579 8.00698C9.42779 7.94798 9.32479 7.91898 9.19579 7.91898C9.06879 7.91898 8.96579 7.94798 8.88679 8.00698C8.80879 8.06498 8.74879 8.15698 8.70679 8.28498C8.66479 8.41298 8.63679 8.57699 8.62279 8.77699C8.60879 8.97699 8.60179 9.22099 8.60179 9.50899C8.60179 9.79699 8.60879 10.041 8.62279 10.243Z"
                    fill="white"
                />
                <path
                    d="M13.5639 10.301H13.5759V7.36597H14.3679V11.65H13.3849L12.3279 8.64997H12.3159V11.65H11.5229V7.36597H12.5189L13.5639 10.301Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_4_38331">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    ),
    FileJpg: (
        <svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4_38341)">
                <path d="M4 0V24H18L24 18V0H4Z" fill="url(#paint0_linear_4_38341)" />
                <path d="M18 18V24L24 18H18Z" fill="#ABABAB" />
                <path d="M12 24H18V18L12 24Z" fill="#DEDEDD" />
                <path d="M0 6V13H16V6H0Z" fill="#71A742" />
                <path
                    d="M4.51484 10.45C4.51484 10.906 4.40884 11.231 4.19684 11.426C3.98484 11.619 3.66084 11.717 3.22484 11.717C2.99584 11.717 2.80984 11.686 2.66284 11.623C2.51684 11.561 2.40084 11.474 2.31484 11.359C2.22884 11.244 2.16984 11.112 2.13884 10.96C2.10584 10.809 2.08984 10.648 2.08984 10.48V10.293H2.88184V10.426C2.88184 10.654 2.90684 10.822 2.95684 10.933C3.00684 11.042 3.10984 11.098 3.26684 11.098C3.42184 11.098 3.52584 11.042 3.57584 10.933C3.62584 10.823 3.65084 10.654 3.65084 10.426V7.36499H4.51484V10.45Z"
                    fill="white"
                />
                <path
                    d="M7.18973 7.36499C7.40973 7.36499 7.59373 7.40299 7.74473 7.47699C7.89573 7.55099 8.01573 7.64799 8.10773 7.76799C8.19973 7.88799 8.26573 8.02499 8.30573 8.17899C8.34473 8.33199 8.36573 8.48999 8.36573 8.64999C8.36573 8.86999 8.33273 9.06199 8.26673 9.22599C8.20073 9.38999 8.10873 9.52399 7.99073 9.62699C7.87273 9.73099 7.72973 9.80999 7.56173 9.86099C7.39373 9.91299 7.20773 9.93899 7.00273 9.93899H6.54173V11.649H5.67773V7.36499H7.18973ZM6.92573 9.30599C7.09773 9.30599 7.23773 9.25299 7.34273 9.14899C7.44873 9.04499 7.50173 8.88299 7.50173 8.66299C7.50173 8.44699 7.45573 8.28299 7.36373 8.17099C7.27173 8.05999 7.11773 8.00299 6.90173 8.00299H6.54173V9.30599H6.92573Z"
                    fill="white"
                />
                <path
                    d="M11.2666 8.39301C11.2486 8.30901 11.2196 8.23201 11.1796 8.16201C11.1396 8.09301 11.0886 8.03401 11.0276 7.98801C10.9656 7.94201 10.8896 7.91901 10.8016 7.91901C10.5936 7.91901 10.4446 8.03501 10.3516 8.26801C10.2596 8.49901 10.2136 8.88401 10.2136 9.41901C10.2136 9.67601 10.2216 9.90701 10.2376 10.115C10.2536 10.323 10.2826 10.5 10.3246 10.646C10.3666 10.792 10.4266 10.904 10.5046 10.982C10.5826 11.06 10.6836 11.099 10.8076 11.099C10.8606 11.099 10.9166 11.084 10.9796 11.057C11.0416 11.029 11.0986 10.987 11.1536 10.931C11.2076 10.874 11.2526 10.804 11.2886 10.718C11.3246 10.632 11.3426 10.53 11.3426 10.414V9.97601H10.7736V9.34001H12.1706V11.65H11.5346V11.254H11.5226C11.4186 11.423 11.2936 11.541 11.1476 11.611C11.0016 11.681 10.8266 11.716 10.6226 11.716C10.3586 11.716 10.1436 11.669 9.97661 11.577C9.80961 11.485 9.68061 11.344 9.58661 11.151C9.49261 10.958 9.42961 10.722 9.39861 10.44C9.36661 10.158 9.34961 9.83101 9.34961 9.45901C9.34961 9.10001 9.37261 8.78301 9.41861 8.51201C9.46461 8.24001 9.54361 8.01301 9.65561 7.83001C9.76661 7.64801 9.91561 7.51201 10.0986 7.41901C10.2836 7.32701 10.5126 7.28101 10.7886 7.28101C11.2606 7.28101 11.6006 7.39801 11.8086 7.63301C12.0166 7.86601 12.1206 8.20101 12.1206 8.63801H11.2926C11.2936 8.55901 11.2846 8.47701 11.2666 8.39301Z"
                    fill="white"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_4_38341"
                    x1="6.83245"
                    y1="4.83325"
                    x2="33.2564"
                    y2="31.2543"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EFEEEE" />
                    <stop offset="1" stopColor="#DEDEDD" />
                </linearGradient>
                <clipPath id="clip0_4_38341">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    ),
    FileHtml: (
        <svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4_38350)">
                <path d="M4.00049 0V24H18L24 18V0H4.00049Z" fill="#EFEEEE" />
                <path d="M17.9995 18V24L23.9995 18H17.9995Z" fill="#ABABAB" />
                <path d="M11.9995 24H17.9995V18L11.9995 24Z" fill="#DEDEDD" />
                <path d="M0.000488281 6V13H16.0005V6H0.000488281Z" fill="#2B84C3" />
                <path
                    d="M1.9348 7.36597V9.00397H2.9428V7.36597H3.8068V11.65H2.9428V9.74797H1.9348V11.65H1.0708V7.36597H1.9348Z"
                    fill="white"
                />
                <path
                    d="M7.29948 8.07397H6.36348V11.65H5.49848V8.07397H4.56348V7.36597H7.29948V8.07397Z"
                    fill="white"
                />
                <path
                    d="M9.40414 7.36597L10.0161 10.39H10.0281L10.6461 7.36597H11.9241V11.65H11.1321V8.22997H11.1191L10.3571 11.65H9.68514L8.92314 8.22997H8.91114V11.65H8.11914V7.36597H9.40414Z"
                    fill="white"
                />
                <path d="M12.7603 7.36597H13.6253V10.942H15.1553V11.65H12.7603V7.36597Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_4_38350">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    ),
    FileExe: (
        <svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4_38360)">
                <path d="M4 0V24H18L24 18V0H4Z" fill="#EFEEEE" />
                <path d="M18 18V24L24 18H18Z" fill="#ABABAB" />
                <path d="M12 24H18V18L12 24Z" fill="#DEDEDD" />
                <path d="M0 6V13H16V6H0Z" fill="#DFB02C" />
                <path
                    d="M4.6948 8.07397H3.1638V9.08197H4.6038V9.78997H3.1638V10.942H4.7538V11.65H2.2998V7.36597H4.6948V8.07397Z"
                    fill="white"
                />
                <path
                    d="M6.56098 7.36597L7.11298 8.75197L7.65898 7.36597H8.59998L7.60498 9.48397L8.67198 11.65H7.69998L7.08198 10.198L6.46997 11.65H5.52197L6.58898 9.48397L5.60597 7.36597H6.56098Z"
                    fill="white"
                />
                <path
                    d="M12.0029 8.07397H10.4719V9.08197H11.9119V9.78997H10.4719V10.942H12.0619V11.65H9.60791V7.36597H12.0029V8.07397Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_4_38360">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    ),
    FileDoc: (
        <svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4_38369)">
                <path d="M4 0V24H18L24 18V0H4Z" fill="#EFEEEE" />
                <path d="M18 18V24L24 18H18Z" fill="#ABABAB" />
                <path d="M12 24H18V18L12 24Z" fill="#DEDEDD" />
                <path d="M0 6V13H16V6H0Z" fill="#4E95D0" />
                <path
                    d="M3.75829 7.36597C4.05929 7.36597 4.30029 7.41497 4.48529 7.51297C4.66929 7.61097 4.81229 7.74997 4.91429 7.93297C5.01729 8.11497 5.08528 8.33297 5.12128 8.58997C5.15728 8.84597 5.17529 9.13197 5.17529 9.44697C5.17529 9.82297 5.15329 10.15 5.10729 10.428C5.06129 10.705 4.98229 10.935 4.87029 11.115C4.75829 11.296 4.60829 11.429 4.42029 11.517C4.23029 11.606 3.99229 11.65 3.70429 11.65H2.34229V7.36597H3.75829ZM3.59029 11.015C3.74229 11.015 3.86429 10.989 3.95629 10.937C4.04829 10.885 4.12129 10.799 4.17529 10.679C4.23029 10.559 4.26629 10.401 4.28329 10.204C4.30129 10.009 4.31029 9.76497 4.31029 9.47297C4.31029 9.22897 4.30128 9.01497 4.28628 8.82997C4.27028 8.64597 4.23728 8.49297 4.18728 8.37097C4.13628 8.24897 4.06329 8.15697 3.96429 8.09597C3.86629 8.03397 3.73529 8.00197 3.57129 8.00197H3.20629V11.015H3.59029Z"
                    fill="white"
                />
                <path
                    d="M6.29939 8.61098C6.32939 8.33798 6.39639 8.10198 6.4974 7.90598C6.6004 7.71098 6.7464 7.55698 6.9384 7.44698C7.1294 7.33798 7.3884 7.28198 7.7114 7.28198C8.0354 7.28198 8.2934 7.33798 8.4854 7.44698C8.6764 7.55698 8.8244 7.71098 8.9254 7.90598C9.0284 8.10198 9.0934 8.33798 9.1234 8.61098C9.1534 8.88498 9.1684 9.18398 9.1684 9.50798C9.1684 9.83498 9.1534 10.136 9.1234 10.407C9.0934 10.679 9.0274 10.913 8.9254 11.109C8.8234 11.305 8.6764 11.455 8.4854 11.559C8.2934 11.663 8.0354 11.715 7.7114 11.715C7.3884 11.715 7.1294 11.662 6.9384 11.559C6.7464 11.455 6.5994 11.305 6.4974 11.109C6.39539 10.913 6.32939 10.679 6.29939 10.407C6.27039 10.136 6.25439 9.83498 6.25439 9.50798C6.25439 9.18498 6.27039 8.88598 6.29939 8.61098ZM7.1404 10.243C7.1544 10.445 7.1824 10.609 7.2244 10.735C7.2664 10.861 7.3264 10.953 7.4044 11.011C7.4824 11.069 7.5854 11.098 7.7134 11.098C7.8414 11.098 7.9444 11.069 8.0234 11.011C8.1014 10.952 8.1614 10.861 8.2034 10.735C8.2454 10.609 8.2734 10.445 8.2874 10.243C8.3014 10.041 8.3084 9.79698 8.3084 9.50898C8.3084 9.22098 8.3004 8.97698 8.2874 8.77698C8.2744 8.57698 8.2454 8.41298 8.2034 8.28498C8.1614 8.15698 8.1014 8.06398 8.0234 8.00698C7.9454 7.94798 7.8414 7.91898 7.7134 7.91898C7.5854 7.91898 7.4834 7.94798 7.4044 8.00698C7.3264 8.06498 7.2664 8.15698 7.2244 8.28498C7.1824 8.41298 7.1544 8.57698 7.1404 8.77698C7.1254 8.97698 7.1194 9.22098 7.1194 9.50898C7.1194 9.79698 7.1254 10.041 7.1404 10.243Z"
                    fill="white"
                />
                <path
                    d="M11.9994 8.13191C11.9254 7.98891 11.7904 7.91791 11.5944 7.91791C11.4874 7.91791 11.3984 7.94691 11.3274 8.00591C11.2574 8.06391 11.2024 8.15691 11.1624 8.28691C11.1224 8.41691 11.0944 8.58691 11.0784 8.79791C11.0624 9.00791 11.0554 9.26491 11.0554 9.56891C11.0554 9.89291 11.0664 10.1549 11.0874 10.3549C11.1094 10.5549 11.1444 10.7089 11.1904 10.8199C11.2364 10.9309 11.2944 11.0049 11.3644 11.0419C11.4344 11.0799 11.5154 11.0999 11.6074 11.0999C11.6834 11.0999 11.7534 11.0869 11.8204 11.0609C11.8854 11.0349 11.9434 10.9829 11.9914 10.9049C12.0394 10.8269 12.0774 10.7169 12.1054 10.5749C12.1324 10.4329 12.1474 10.2459 12.1474 10.0139H13.0104C13.0104 10.2449 12.9924 10.4659 12.9564 10.6739C12.9204 10.8819 12.8534 11.0639 12.7554 11.2199C12.6574 11.3749 12.5204 11.4969 12.3414 11.5849C12.1634 11.6729 11.9334 11.7179 11.6494 11.7179C11.3254 11.7179 11.0674 11.6649 10.8754 11.5619C10.6844 11.4579 10.5364 11.3079 10.4354 11.1119C10.3324 10.9159 10.2674 10.6819 10.2374 10.4099C10.2074 10.1389 10.1924 9.83791 10.1924 9.51091C10.1924 9.18691 10.2074 8.88791 10.2374 8.61391C10.2674 8.34091 10.3334 8.10491 10.4354 7.90891C10.5374 7.71391 10.6844 7.55991 10.8754 7.44991C11.0674 7.34091 11.3254 7.28491 11.6494 7.28491C11.9574 7.28491 12.2004 7.33491 12.3784 7.43491C12.5564 7.53491 12.6904 7.66191 12.7774 7.81591C12.8644 7.96991 12.9204 8.13591 12.9424 8.31391C12.9634 8.49191 12.9754 8.65891 12.9754 8.81491H12.1104C12.1104 8.49991 12.0734 8.27291 11.9994 8.13191Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_4_38369">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    ),
    FileOdt: (
        <svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4_38378)">
                <path d="M4 0V24H18L24 18V0H4Z" fill="#EFEEEE" />
                <path d="M18 18V24L24 18H18Z" fill="#ABABAB" />
                <path d="M12 24H18V18L12 24Z" fill="#DEDEDD" />
                <path d="M0 6V13H16V6H0Z" fill="#4E72D0" />
                <path
                    d="M7.76952 7.36597C8.07052 7.36597 8.31152 7.41497 8.49652 7.51297C8.68052 7.61097 8.82352 7.74997 8.92552 7.93297C9.02852 8.11497 9.09652 8.33297 9.13252 8.58997C9.16852 8.84597 9.18652 9.13197 9.18652 9.44697C9.18652 9.82297 9.16452 10.15 9.11852 10.428C9.07252 10.705 8.99352 10.935 8.88152 11.115C8.76952 11.296 8.61952 11.429 8.43152 11.517C8.24152 11.606 8.00352 11.65 7.71552 11.65H6.35352V7.36597H7.76952ZM7.60152 11.015C7.75352 11.015 7.87552 10.989 7.96752 10.937C8.05952 10.885 8.13252 10.799 8.18652 10.679C8.24152 10.559 8.27752 10.401 8.29452 10.204C8.31252 10.009 8.32152 9.76497 8.32152 9.47297C8.32152 9.22897 8.31252 9.01497 8.29752 8.82997C8.28152 8.64597 8.24852 8.49297 8.19852 8.37097C8.14752 8.24897 8.07452 8.15697 7.97552 8.09597C7.87752 8.03397 7.74652 8.00197 7.58252 8.00197H7.21752V11.015H7.60152Z"
                    fill="white"
                />
                <path
                    d="M12.7214 8.07421H11.7854V11.6502H10.9214V8.07421H9.98535V7.36621H12.7214V8.07421Z"
                    fill="white"
                />
                <path
                    d="M2.39852 8.61098C2.42852 8.33798 2.49552 8.10198 2.59652 7.90598C2.69952 7.71098 2.84552 7.55698 3.03752 7.44698C3.22852 7.33798 3.48752 7.28198 3.81052 7.28198C4.13452 7.28198 4.39252 7.33798 4.58452 7.44698C4.77552 7.55698 4.92352 7.71098 5.02452 7.90598C5.12752 8.10198 5.19252 8.33798 5.22252 8.61098C5.25252 8.88498 5.26752 9.18398 5.26752 9.50798C5.26752 9.83498 5.25252 10.136 5.22252 10.407C5.19252 10.679 5.12652 10.913 5.02452 11.109C4.92252 11.305 4.77552 11.455 4.58452 11.559C4.39252 11.663 4.13452 11.715 3.81052 11.715C3.48752 11.715 3.22852 11.662 3.03752 11.559C2.84552 11.455 2.69852 11.305 2.59652 11.109C2.49452 10.913 2.42852 10.679 2.39852 10.407C2.36952 10.136 2.35352 9.83498 2.35352 9.50798C2.35352 9.18498 2.36952 8.88598 2.39852 8.61098ZM3.23952 10.243C3.25352 10.445 3.28152 10.609 3.32352 10.735C3.36552 10.861 3.42552 10.953 3.50352 11.011C3.58152 11.069 3.68452 11.098 3.81252 11.098C3.94052 11.098 4.04352 11.069 4.12252 11.011C4.20052 10.952 4.26052 10.861 4.30252 10.735C4.34452 10.609 4.37252 10.445 4.38652 10.243C4.40052 10.041 4.40752 9.79698 4.40752 9.50898C4.40752 9.22098 4.39952 8.97698 4.38652 8.77698C4.37352 8.57698 4.34452 8.41298 4.30252 8.28498C4.26052 8.15698 4.20052 8.06398 4.12252 8.00698C4.04452 7.94798 3.94052 7.91898 3.81252 7.91898C3.68452 7.91898 3.58252 7.94798 3.50352 8.00698C3.42552 8.06498 3.36552 8.15698 3.32352 8.28498C3.28152 8.41298 3.25352 8.57698 3.23952 8.77698C3.22452 8.97698 3.21852 9.22098 3.21852 9.50898C3.21852 9.79698 3.22452 10.041 3.23952 10.243Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_4_38378">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    ),
    FileOdf: (
        <svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4_38387)">
                <path d="M4 0V24H18L24 18V0H4Z" fill="#EFEEEE" />
                <path d="M18 18V24L24 18H18Z" fill="#ABABAB" />
                <path d="M12 24H18V18L12 24Z" fill="#DEDEDD" />
                <path d="M0 6V13H16V6H0Z" fill="#6787DA" />
                <path
                    d="M7.76952 7.36597C8.07052 7.36597 8.31152 7.41497 8.49652 7.51297C8.68052 7.61097 8.82352 7.74997 8.92552 7.93297C9.02852 8.11497 9.09652 8.33297 9.13252 8.58997C9.16852 8.84597 9.18652 9.13197 9.18652 9.44697C9.18652 9.82297 9.16452 10.15 9.11852 10.428C9.07252 10.705 8.99352 10.935 8.88152 11.115C8.76952 11.296 8.61952 11.429 8.43152 11.517C8.24152 11.606 8.00352 11.65 7.71552 11.65H6.35352V7.36597H7.76952ZM7.60152 11.015C7.75352 11.015 7.87552 10.989 7.96752 10.937C8.05952 10.885 8.13252 10.799 8.18652 10.679C8.24152 10.559 8.27752 10.401 8.29452 10.204C8.31252 10.009 8.32152 9.76497 8.32152 9.47297C8.32152 9.22897 8.31252 9.01497 8.29752 8.82997C8.28152 8.64597 8.24852 8.49297 8.19852 8.37097C8.14752 8.24897 8.07452 8.15697 7.97552 8.09597C7.87752 8.03397 7.74652 8.00197 7.58252 8.00197H7.21752V11.015H7.60152Z"
                    fill="white"
                />
                <path
                    d="M12.6665 7.36621V8.07421H11.1365V9.08221H12.5765V9.79021H11.1365V11.6502H10.2725V7.36621H12.6665Z"
                    fill="white"
                />
                <path
                    d="M2.39852 8.61098C2.42852 8.33798 2.49552 8.10198 2.59652 7.90598C2.69952 7.71098 2.84552 7.55698 3.03752 7.44698C3.22852 7.33798 3.48752 7.28198 3.81052 7.28198C4.13452 7.28198 4.39252 7.33798 4.58452 7.44698C4.77552 7.55698 4.92352 7.71098 5.02452 7.90598C5.12752 8.10198 5.19252 8.33798 5.22252 8.61098C5.25252 8.88498 5.26752 9.18398 5.26752 9.50798C5.26752 9.83498 5.25252 10.136 5.22252 10.407C5.19252 10.679 5.12652 10.913 5.02452 11.109C4.92252 11.305 4.77552 11.455 4.58452 11.559C4.39252 11.663 4.13452 11.715 3.81052 11.715C3.48752 11.715 3.22852 11.662 3.03752 11.559C2.84552 11.455 2.69852 11.305 2.59652 11.109C2.49452 10.913 2.42852 10.679 2.39852 10.407C2.36952 10.136 2.35352 9.83498 2.35352 9.50798C2.35352 9.18498 2.36952 8.88598 2.39852 8.61098ZM3.23952 10.243C3.25352 10.445 3.28152 10.609 3.32352 10.735C3.36552 10.861 3.42552 10.953 3.50352 11.011C3.58152 11.069 3.68452 11.098 3.81252 11.098C3.94052 11.098 4.04352 11.069 4.12252 11.011C4.20052 10.952 4.26052 10.861 4.30252 10.735C4.34452 10.609 4.37252 10.445 4.38652 10.243C4.40052 10.041 4.40752 9.79698 4.40752 9.50898C4.40752 9.22098 4.39952 8.97698 4.38652 8.77698C4.37352 8.57698 4.34452 8.41298 4.30252 8.28498C4.26052 8.15698 4.20052 8.06398 4.12252 8.00698C4.04452 7.94798 3.94052 7.91898 3.81252 7.91898C3.68452 7.91898 3.58252 7.94798 3.50352 8.00698C3.42552 8.06498 3.36552 8.15698 3.32352 8.28498C3.28152 8.41298 3.25352 8.57698 3.23952 8.77698C3.22452 8.97698 3.21852 9.22098 3.21852 9.50898C3.21852 9.79698 3.22452 10.041 3.23952 10.243Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_4_38387">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    ),
    FileOds: (
        <svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4_38258)">
                <path d="M4 0V24H18L24 18V0H4Z" fill="#EFEEEE" />
                <path d="M18 18V24L24 18H18Z" fill="#ABABAB" />
                <path d="M12 24H18V18L12 24Z" fill="#DEDEDD" />
                <path d="M0 6V13H16V6H0Z" fill="#33AB39" />
                <path
                    d="M7.79442 7.36597C8.09542 7.36597 8.33642 7.41497 8.52142 7.51297C8.70542 7.61097 8.84842 7.74997 8.95042 7.93297C9.05342 8.11497 9.12142 8.33297 9.15742 8.58997C9.19342 8.84597 9.21142 9.13197 9.21142 9.44697C9.21142 9.82297 9.18942 10.15 9.14342 10.428C9.09742 10.705 9.01842 10.935 8.90642 11.115C8.79442 11.296 8.64442 11.429 8.45642 11.517C8.26642 11.606 8.02842 11.65 7.74042 11.65H6.37842V7.36597H7.79442ZM7.62642 11.015C7.77842 11.015 7.90042 10.989 7.99242 10.937C8.08442 10.885 8.15742 10.799 8.21142 10.679C8.26642 10.559 8.30242 10.401 8.31942 10.204C8.33742 10.009 8.34642 9.76497 8.34642 9.47297C8.34642 9.22897 8.33742 9.01497 8.32242 8.82997C8.30642 8.64597 8.27342 8.49297 8.22342 8.37097C8.17242 8.24897 8.09942 8.15697 8.00042 8.09597C7.90242 8.03397 7.77142 8.00197 7.60742 8.00197H7.24242V11.015H7.62642Z"
                    fill="white"
                />
                <path
                    d="M11.1634 10.438C11.1634 10.538 11.1714 10.629 11.1874 10.71C11.2034 10.792 11.2334 10.861 11.2774 10.917C11.3214 10.974 11.3804 11.018 11.4544 11.05C11.5284 11.081 11.6214 11.098 11.7334 11.098C11.8654 11.098 11.9844 11.055 12.0894 10.969C12.1954 10.883 12.2484 10.749 12.2484 10.57C12.2484 10.474 12.2354 10.391 12.2094 10.321C12.1844 10.251 12.1414 10.188 12.0804 10.133C12.0204 10.076 11.9414 10.026 11.8434 9.97999C11.7454 9.93399 11.6244 9.88699 11.4814 9.83899C11.2894 9.77499 11.1234 9.70399 10.9834 9.62799C10.8424 9.55299 10.7264 9.46399 10.6314 9.36099C10.5374 9.25899 10.4684 9.14099 10.4244 9.00699C10.3804 8.87198 10.3594 8.71799 10.3594 8.54098C10.3594 8.11698 10.4774 7.80198 10.7134 7.59398C10.9494 7.38598 11.2744 7.28198 11.6864 7.28198C11.8774 7.28198 12.0554 7.30298 12.2164 7.34498C12.3784 7.38698 12.5194 7.45398 12.6374 7.54898C12.7544 7.64298 12.8474 7.76298 12.9124 7.90798C12.9784 8.05398 13.0124 8.22898 13.0124 8.43298V8.55298H12.1834C12.1834 8.34898 12.1474 8.19198 12.0754 8.08098C12.0034 7.97198 11.8834 7.91598 11.7154 7.91598C11.6194 7.91598 11.5394 7.93098 11.4764 7.95798C11.4124 7.98598 11.3614 8.02298 11.3234 8.06898C11.2854 8.11498 11.2584 8.16998 11.2444 8.22998C11.2304 8.28998 11.2234 8.35198 11.2234 8.41598C11.2234 8.54898 11.2504 8.65898 11.3074 8.74898C11.3634 8.83998 11.4834 8.92299 11.6664 8.99799L12.3324 9.28599C12.4964 9.35799 12.6304 9.43299 12.7344 9.51199C12.8384 9.58999 12.9214 9.67399 12.9834 9.76399C13.0454 9.85399 13.0874 9.95199 13.1124 10.061C13.1364 10.168 13.1484 10.289 13.1484 10.42C13.1484 10.872 13.0174 11.201 12.7544 11.407C12.4924 11.613 12.1274 11.717 11.6594 11.717C11.1714 11.717 10.8224 11.611 10.6124 11.399C10.4024 11.187 10.2974 10.883 10.2974 10.487V10.313H11.1634V10.438Z"
                    fill="white"
                />
                <path
                    d="M2.42342 8.61098C2.45342 8.33798 2.52042 8.10198 2.62142 7.90598C2.72442 7.71098 2.87042 7.55698 3.06242 7.44698C3.25342 7.33798 3.51242 7.28198 3.83542 7.28198C4.15942 7.28198 4.41742 7.33798 4.60942 7.44698C4.80042 7.55698 4.94842 7.71098 5.04942 7.90598C5.15242 8.10198 5.21743 8.33798 5.24743 8.61098C5.27743 8.88498 5.29243 9.18398 5.29243 9.50798C5.29243 9.83498 5.27743 10.136 5.24743 10.407C5.21743 10.679 5.15142 10.913 5.04942 11.109C4.94742 11.305 4.80042 11.455 4.60942 11.559C4.41742 11.663 4.15942 11.715 3.83542 11.715C3.51242 11.715 3.25342 11.662 3.06242 11.559C2.87042 11.455 2.72342 11.305 2.62142 11.109C2.51942 10.913 2.45342 10.679 2.42342 10.407C2.39442 10.136 2.37842 9.83498 2.37842 9.50798C2.37842 9.18498 2.39442 8.88598 2.42342 8.61098ZM3.26442 10.243C3.27842 10.445 3.30642 10.609 3.34842 10.735C3.39042 10.861 3.45042 10.953 3.52842 11.011C3.60642 11.069 3.70942 11.098 3.83742 11.098C3.96542 11.098 4.06842 11.069 4.14742 11.011C4.22542 10.952 4.28542 10.861 4.32742 10.735C4.36942 10.609 4.39742 10.445 4.41142 10.243C4.42542 10.041 4.43242 9.79698 4.43242 9.50898C4.43242 9.22098 4.42442 8.97698 4.41142 8.77698C4.39842 8.57698 4.36942 8.41298 4.32742 8.28498C4.28542 8.15698 4.22542 8.06398 4.14742 8.00698C4.06942 7.94798 3.96542 7.91898 3.83742 7.91898C3.70942 7.91898 3.60742 7.94798 3.52842 8.00698C3.45042 8.06498 3.39042 8.15698 3.34842 8.28498C3.30642 8.41298 3.27842 8.57698 3.26442 8.77698C3.24942 8.97698 3.24342 9.22098 3.24342 9.50898C3.24342 9.79698 3.24942 10.041 3.26442 10.243Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_4_38258">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    ),
    FileCsv: (
        <svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4_38396)">
                <path d="M4 0V24H18L24 18V0H4Z" fill="#EFEEEE" />
                <path d="M18 18V24L24 18H18Z" fill="#ABABAB" />
                <path d="M12 24H18V18L12 24Z" fill="#DEDEDD" />
                <path d="M0 6V13H16V6H0Z" fill="#448E47" />
                <path
                    d="M4.01994 8.13191C3.94594 7.98891 3.81094 7.91791 3.61494 7.91791C3.50694 7.91791 3.41794 7.94691 3.34794 8.00591C3.27794 8.06391 3.22294 8.15691 3.18294 8.28691C3.14294 8.41691 3.11494 8.58691 3.09894 8.79791C3.08194 9.00791 3.07494 9.26491 3.07494 9.56891C3.07494 9.89291 3.08594 10.1549 3.10794 10.3549C3.12894 10.5549 3.16394 10.7089 3.20994 10.8199C3.25594 10.9309 3.31394 11.0049 3.38394 11.0419C3.45394 11.0799 3.53494 11.0999 3.62694 11.0999C3.70294 11.0999 3.77394 11.0869 3.83994 11.0609C3.90594 11.0349 3.96294 10.9829 4.01094 10.9049C4.05994 10.8269 4.09794 10.7169 4.12494 10.5749C4.15294 10.4329 4.16694 10.2459 4.16694 10.0139H5.02994C5.02994 10.2449 5.01194 10.4659 4.97594 10.6739C4.93994 10.8819 4.87194 11.0639 4.77494 11.2199C4.67594 11.3749 4.53894 11.4969 4.36094 11.5849C4.18294 11.6729 3.95194 11.7179 3.66794 11.7179C3.34494 11.7179 3.08594 11.6649 2.89494 11.5619C2.70294 11.4579 2.55594 11.3079 2.45394 11.1119C2.35194 10.9159 2.28594 10.6819 2.25594 10.4099C2.22694 10.1389 2.21094 9.83791 2.21094 9.51091C2.21094 9.18691 2.22694 8.88791 2.25594 8.61391C2.28594 8.34091 2.35294 8.10491 2.45394 7.90891C2.55694 7.71391 2.70294 7.55991 2.89494 7.44991C3.08594 7.34091 3.34494 7.28491 3.66794 7.28491C3.97694 7.28491 4.21894 7.33491 4.39694 7.43491C4.57494 7.53491 4.70794 7.66191 4.79494 7.81591C4.88194 7.96991 4.93894 8.13591 4.95994 8.31391C4.98194 8.49191 4.99294 8.65891 4.99294 8.81491H4.12994C4.12994 8.49991 4.09294 8.27291 4.01994 8.13191Z"
                    fill="white"
                />
                <path
                    d="M6.81664 10.438C6.81664 10.538 6.82564 10.629 6.84064 10.71C6.85764 10.792 6.88664 10.861 6.93064 10.917C6.97464 10.974 7.03464 11.018 7.10864 11.05C7.18164 11.081 7.27564 11.098 7.38664 11.098C7.51964 11.098 7.63764 11.055 7.74364 10.969C7.84864 10.883 7.90264 10.749 7.90264 10.57C7.90264 10.474 7.88864 10.391 7.86364 10.321C7.83764 10.251 7.79464 10.188 7.73464 10.133C7.67364 10.076 7.59464 10.026 7.49764 9.97999C7.40064 9.93399 7.27864 9.88699 7.13464 9.83899C6.94364 9.77499 6.77764 9.70399 6.63664 9.62799C6.49664 9.55299 6.37964 9.46399 6.28564 9.36099C6.19164 9.25899 6.12264 9.14099 6.07864 9.00699C6.03464 8.87198 6.01264 8.71799 6.01264 8.54098C6.01264 8.11698 6.13064 7.80198 6.36664 7.59398C6.60164 7.38598 6.92664 7.28198 7.33864 7.28198C7.53064 7.28198 7.70764 7.30298 7.86964 7.34498C8.03164 7.38698 8.17164 7.45398 8.28964 7.54898C8.40764 7.64298 8.49964 7.76298 8.56564 7.90798C8.63064 8.05398 8.66464 8.22898 8.66464 8.43298V8.55298H7.83764C7.83764 8.34898 7.80164 8.19198 7.73064 8.08098C7.65864 7.97198 7.53864 7.91598 7.37064 7.91598C7.27464 7.91598 7.19464 7.93098 7.13064 7.95798C7.06764 7.98598 7.01564 8.02298 6.97764 8.06898C6.93964 8.11498 6.91264 8.16998 6.89864 8.22998C6.88364 8.28998 6.87764 8.35198 6.87764 8.41598C6.87764 8.54898 6.90564 8.65898 6.96164 8.74898C7.01764 8.83998 7.13764 8.92299 7.32164 8.99799L7.98764 9.28599C8.15164 9.35799 8.28564 9.43299 8.38864 9.51199C8.49264 9.58999 8.57664 9.67399 8.63764 9.76399C8.69964 9.85399 8.74264 9.95199 8.76664 10.061C8.79064 10.168 8.80264 10.289 8.80264 10.42C8.80264 10.872 8.67164 11.201 8.40964 11.407C8.14764 11.613 7.78264 11.717 7.31464 11.717C6.82664 11.717 6.47764 11.611 6.26664 11.399C6.05664 11.187 5.95264 10.883 5.95264 10.487V10.313H6.81664V10.438Z"
                    fill="white"
                />
                <path
                    d="M11.0841 10.534H11.1031L11.7391 7.36597H12.6341L11.6501 11.65H10.5401L9.55713 7.36597H10.4811L11.0841 10.534Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_4_38396">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    ),
    FileBmp: (
        <svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4_38405)">
                <path d="M4 0V24H18L24 18V0H4Z" fill="url(#paint0_linear_4_38405)" />
                <path d="M18 18V24L24 18H18Z" fill="#ABABAB" />
                <path d="M12 24H18V18L12 24Z" fill="#DEDEDD" />
                <path d="M0 6V13H16V6H0Z" fill="#D9AD2B" />
                <path
                    d="M3.74581 7.36597C3.92181 7.36597 4.08481 7.38497 4.23481 7.41997C4.38381 7.45597 4.51381 7.51697 4.62181 7.60097C4.72981 7.68497 4.81381 7.79697 4.87381 7.93697C4.93481 8.07697 4.96381 8.24897 4.96381 8.45297C4.96381 8.68497 4.90981 8.88497 4.80181 9.05097C4.69481 9.21697 4.53181 9.32897 4.31681 9.38997V9.39997C4.55681 9.43197 4.74681 9.53397 4.88681 9.70597C5.02681 9.87897 5.09681 10.108 5.09681 10.396C5.09681 10.564 5.07481 10.724 5.03081 10.875C4.98681 11.027 4.91181 11.16 4.80581 11.274C4.69981 11.388 4.56181 11.479 4.39181 11.547C4.22081 11.616 4.00981 11.65 3.75781 11.65H2.2998V7.36597H3.74581ZM3.40981 9.12297C3.64981 9.12297 3.82481 9.08097 3.93481 8.99497C4.04381 8.90897 4.09981 8.76497 4.09981 8.55997C4.09981 8.35497 4.04881 8.21097 3.94981 8.12697C3.85081 8.04297 3.69181 8.00097 3.47581 8.00097H3.16381V9.12297H3.40981ZM3.49981 11.015C3.70781 11.015 3.87581 10.969 4.00381 10.876C4.13181 10.784 4.19581 10.614 4.19581 10.366C4.19581 10.242 4.17781 10.139 4.14481 10.057C4.11081 9.97497 4.06481 9.90897 4.00581 9.85897C3.94881 9.80997 3.87981 9.77397 3.79981 9.75397C3.71981 9.73397 3.63181 9.72497 3.53581 9.72497H3.16381V11.015H3.49981Z"
                    fill="#010101"
                />
                <path
                    d="M7.29767 7.36597L7.90967 10.39H7.92167L8.53967 7.36597H9.81767V11.65H9.02567V8.22997H9.01267L8.25067 11.65H7.57967L6.81767 8.22997H6.80567V11.65H6.01367V7.36597H7.29767Z"
                    fill="#010101"
                />
                <path
                    d="M12.3089 7.36499C12.5289 7.36499 12.7129 7.40299 12.8639 7.47699C13.0149 7.55099 13.1349 7.64799 13.2269 7.76799C13.3189 7.88799 13.3849 8.02499 13.4249 8.17899C13.4639 8.33199 13.4849 8.48999 13.4849 8.64999C13.4849 8.86999 13.4519 9.06199 13.3859 9.22599C13.3199 9.38999 13.2279 9.52399 13.1099 9.62699C12.9919 9.73099 12.8489 9.80999 12.6809 9.86099C12.5129 9.91199 12.3269 9.93899 12.1219 9.93899H11.6599V11.649H10.7959V7.36499H12.3089ZM12.0439 9.30599C12.2159 9.30599 12.3559 9.25299 12.4609 9.14899C12.5669 9.04499 12.6199 8.88299 12.6199 8.66299C12.6199 8.44699 12.5739 8.28299 12.4819 8.17099C12.3899 8.05999 12.2359 8.00299 12.0199 8.00299H11.6599V9.30599H12.0439Z"
                    fill="#010101"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_4_38405"
                    x1="6.8344"
                    y1="4.83394"
                    x2="33.2559"
                    y2="31.2569"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EFEEEE" />
                    <stop offset="1" stopColor="#DEDEDD" />
                </linearGradient>
                <clipPath id="clip0_4_38405">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    ),
    FileWmv: (
        <svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4_38414)">
                <path d="M4 0V24H18L24 18V0H4Z" fill="url(#paint0_linear_4_38414)" />
                <path d="M18 18V24L24 18H18Z" fill="#ABABAB" />
                <path d="M12 24H18V18L12 24Z" fill="#DEDEDD" />
                <path d="M0 6V13H16V6H0Z" fill="#CB5641" />
                <path
                    d="M2.20886 7.36597L2.68386 10.534H2.69586L3.19986 7.36597H4.02786L4.53186 10.534H4.54386L5.01886 7.36597H5.84686L5.06686 11.65H4.09986L3.61986 8.54197H3.60686L3.12686 11.65H2.16186L1.38086 7.36597H2.20886Z"
                    fill="white"
                />
                <path
                    d="M8.09601 7.36597L8.70702 10.39H8.72002L9.33802 7.36597H10.615V11.65H9.82402V8.22997H9.81102L9.04902 11.65H8.37602L7.61401 8.22997H7.60401V11.65H6.81201V7.36597H8.09601Z"
                    fill="white"
                />
                <path
                    d="M13.0807 10.534H13.0997L13.7357 7.36597H14.6297L13.6457 11.65H12.5367L11.5527 7.36597H12.4767L13.0807 10.534Z"
                    fill="white"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_4_38414"
                    x1="6.83369"
                    y1="4.83259"
                    x2="33.2547"
                    y2="31.2576"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EFEEEE" />
                    <stop offset="1" stopColor="#DEDEDD" />
                </linearGradient>
                <clipPath id="clip0_4_38414">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    ),
    FileWav: (
        <svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4_38423)">
                <path d="M4 0V24H18L24 18V0H4Z" fill="url(#paint0_linear_4_38423)" />
                <path d="M18 18V24L24 18H18Z" fill="#ABABAB" />
                <path d="M12 24H18V18L12 24Z" fill="#DEDEDD" />
                <path d="M0 6V13H16V6H0Z" fill="#663694" />
                <path
                    d="M2.49402 7.36597L2.96902 10.534H2.98002L3.48402 7.36597H4.31202L4.81602 10.534H4.82802L5.30302 7.36597H6.13102L5.35002 11.65H4.38502L3.90502 8.54197H3.89302L3.41302 11.65H2.44602L1.66602 7.36597H2.49402Z"
                    fill="white"
                />
                <path
                    d="M8.75179 7.36597L9.88679 11.65H8.98679L8.78879 10.744H7.64878L7.44978 11.65H6.55078L7.68378 7.36597H8.75179ZM8.63879 10.036L8.22479 8.10997H8.21279L7.79878 10.036H8.63879Z"
                    fill="white"
                />
                <path
                    d="M11.7941 10.534H11.8131L12.4491 7.36597H13.3431L12.3591 11.65H11.2501L10.2661 7.36597H11.1901L11.7941 10.534Z"
                    fill="white"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_4_38423"
                    x1="6.83435"
                    y1="4.83299"
                    x2="33.2544"
                    y2="31.258"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EFEEEE" />
                    <stop offset="1" stopColor="#DEDEDD" />
                </linearGradient>
                <clipPath id="clip0_4_38423">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    ),
    FileTiff: (
        <svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4_38432)">
                <path d="M4 0V24H18L24 18V0H4Z" fill="url(#paint0_linear_4_38432)" />
                <path d="M18 18V24L24 18H18Z" fill="#ABABAB" />
                <path d="M12 24H18V18L12 24Z" fill="#DEDEDD" />
                <path d="M0 6V13H16V6H0Z" fill="#2B5529" />
                <path
                    d="M4.80827 8.07397H3.87227V11.65H3.00827V8.07397H2.07227V7.36597H4.80827V8.07397Z"
                    fill="white"
                />
                <path d="M5.58203 7.36597H6.44603V11.65H5.58203V7.36597Z" fill="white" />
                <path
                    d="M9.84224 7.36597V8.07397H8.31324V9.08197H9.75224V9.78997H8.31324V11.65H7.44824V7.36597H9.84224Z"
                    fill="white"
                />
                <path
                    d="M12.9804 7.36597V8.07397H11.4504V9.08197H12.8904V9.78997H11.4504V11.65H10.5864V7.36597H12.9804Z"
                    fill="white"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_4_38432"
                    x1="6.83325"
                    y1="4.83324"
                    x2="33.2563"
                    y2="31.2562"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EFEEEE" />
                    <stop offset="1" stopColor="#DEDEDD" />
                </linearGradient>
                <clipPath id="clip0_4_38432">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    ),
    FilePsd: (
        <svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4_38451)">
                <path d="M4 0V24H18L24 18V0H4Z" fill="url(#paint0_linear_4_38451)" />
                <path d="M18 18V24L24 18H18Z" fill="#ABABAB" />
                <path d="M12 24H18V18L12 24Z" fill="#DEDEDD" />
                <path d="M0 6V13H16V6H0Z" fill="#1F60A4" />
                <path
                    d="M3.81181 7.36597C4.03181 7.36597 4.21581 7.40297 4.36681 7.47697C4.51781 7.55097 4.63781 7.64797 4.72981 7.76797C4.82181 7.88797 4.88781 8.02497 4.92781 8.17897C4.96681 8.33197 4.98781 8.48997 4.98781 8.64997C4.98781 8.86997 4.95481 9.06197 4.88881 9.22597C4.82281 9.38997 4.73081 9.52397 4.61281 9.62697C4.49481 9.73097 4.35181 9.80997 4.18381 9.86097C4.01581 9.91197 3.82981 9.93997 3.62581 9.93997H3.16381V11.65H2.2998V7.36597H3.81181ZM3.54781 9.30597C3.71981 9.30597 3.85981 9.25297 3.96481 9.14897C4.07081 9.04497 4.12381 8.88297 4.12381 8.66297C4.12381 8.44697 4.07781 8.28297 3.98581 8.17097C3.89381 8.05997 3.73981 8.00297 3.52381 8.00297H3.16381V9.30597H3.54781Z"
                    fill="white"
                />
                <path
                    d="M6.70975 10.438C6.70975 10.538 6.71775 10.629 6.73375 10.71C6.74975 10.792 6.77975 10.861 6.82375 10.917C6.86775 10.974 6.92675 11.018 7.00075 11.05C7.07475 11.081 7.16775 11.098 7.27975 11.098C7.41175 11.098 7.53075 11.055 7.63575 10.969C7.74175 10.883 7.79475 10.749 7.79475 10.57C7.79475 10.474 7.78175 10.391 7.75575 10.321C7.73075 10.251 7.68775 10.188 7.62675 10.133C7.56675 10.076 7.48775 10.026 7.38975 9.97999C7.29175 9.93399 7.17075 9.88699 7.02775 9.83899C6.83575 9.77499 6.66975 9.70399 6.52975 9.62799C6.38875 9.55299 6.27275 9.46399 6.17775 9.36099C6.08375 9.25899 6.01475 9.14099 5.97075 9.00699C5.92675 8.87198 5.90575 8.71799 5.90575 8.54098C5.90575 8.11698 6.02375 7.80198 6.25975 7.59398C6.49575 7.38598 6.82075 7.28198 7.23275 7.28198C7.42375 7.28198 7.60175 7.30298 7.76275 7.34498C7.92475 7.38698 8.06575 7.45398 8.18375 7.54898C8.30075 7.64298 8.39375 7.76298 8.45875 7.90798C8.52475 8.05398 8.55875 8.22898 8.55875 8.43298V8.55298H7.72975C7.72975 8.34898 7.69375 8.19198 7.62175 8.08098C7.54975 7.97198 7.42975 7.91598 7.26175 7.91598C7.16575 7.91598 7.08575 7.93098 7.02275 7.95798C6.95875 7.98598 6.90775 8.02298 6.86975 8.06898C6.83175 8.11498 6.80475 8.16998 6.79075 8.22998C6.77675 8.28998 6.76975 8.35198 6.76975 8.41598C6.76975 8.54898 6.79675 8.65898 6.85375 8.74898C6.90975 8.83998 7.02975 8.92299 7.21275 8.99799L7.87875 9.28599C8.04275 9.35799 8.17675 9.43299 8.28075 9.51199C8.38475 9.58999 8.46775 9.67399 8.52975 9.76399C8.59175 9.85399 8.63375 9.95199 8.65875 10.061C8.68275 10.168 8.69475 10.289 8.69475 10.42C8.69475 10.872 8.56375 11.201 8.30075 11.407C8.03875 11.613 7.67375 11.717 7.20575 11.717C6.71775 11.717 6.36875 11.611 6.15875 11.399C5.94875 11.187 5.84375 10.883 5.84375 10.487V10.313H6.70975V10.438Z"
                    fill="white"
                />
                <path
                    d="M11.186 7.36499C11.487 7.36499 11.728 7.41499 11.913 7.51299C12.097 7.61099 12.24 7.74999 12.342 7.93299C12.445 8.11499 12.513 8.33299 12.549 8.58999C12.585 8.84599 12.603 9.13199 12.603 9.44699C12.603 9.82299 12.581 10.15 12.535 10.428C12.489 10.705 12.41 10.935 12.298 11.115C12.186 11.296 12.036 11.429 11.848 11.517C11.66 11.605 11.422 11.649 11.134 11.649H9.77002V7.36499H11.186ZM11.018 11.015C11.17 11.015 11.292 10.989 11.384 10.937C11.476 10.885 11.549 10.799 11.603 10.679C11.658 10.559 11.694 10.401 11.711 10.204C11.729 10.009 11.738 9.76499 11.738 9.47299C11.738 9.22899 11.729 9.01499 11.714 8.82999C11.698 8.64599 11.665 8.49299 11.615 8.37099C11.564 8.24899 11.491 8.15699 11.392 8.09599C11.294 8.03399 11.163 8.00199 10.999 8.00199H10.633V11.015H11.018Z"
                    fill="white"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_4_38451"
                    x1="6.8331"
                    y1="4.83349"
                    x2="33.2581"
                    y2="31.257"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EFEEEE" />
                    <stop offset="1" stopColor="#DEDEDD" />
                </linearGradient>
                <clipPath id="clip0_4_38451">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    ),
    FileMpg: (
        <svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4_38460)">
                <path d="M4 0V24H18L24 18V0H4Z" fill="url(#paint0_linear_4_38460)" />
                <path d="M18 18V24L24 18H18Z" fill="#ABABAB" />
                <path d="M12 24H18V18L12 24Z" fill="#DEDEDD" />
                <path d="M0 6V13H16V6H0Z" fill="#2F2E7C" />
                <path
                    d="M3.60287 7.36597L4.21387 10.39H4.22687L4.84487 7.36597H6.12187V11.65H5.32987V8.22997H5.31787L4.55587 11.65H3.88387L3.12187 8.22997H3.10987V11.65H2.31787V7.36597H3.60287Z"
                    fill="white"
                />
                <path
                    d="M8.6121 7.36499C8.8321 7.36499 9.0161 7.40299 9.1671 7.47699C9.3181 7.55099 9.4371 7.64799 9.5301 7.76799C9.6221 7.88799 9.6881 8.02499 9.7281 8.17899C9.7671 8.33199 9.78811 8.48999 9.78811 8.64999C9.78811 8.86999 9.7551 9.06199 9.6891 9.22599C9.6231 9.38999 9.5311 9.52399 9.4131 9.62699C9.2951 9.73099 9.1521 9.80999 8.9841 9.86099C8.8161 9.91199 8.6301 9.93899 8.4251 9.93899H7.9631V11.649H7.1001V7.36499H8.6121ZM8.3481 9.30599C8.5201 9.30599 8.6601 9.25299 8.7651 9.14899C8.8711 9.04499 8.9241 8.88299 8.9241 8.66299C8.9241 8.44699 8.8781 8.28299 8.7861 8.17099C8.6941 8.05999 8.5401 8.00299 8.3241 8.00299H7.9641V9.30599H8.3481Z"
                    fill="white"
                />
                <path
                    d="M12.4487 8.39301C12.4307 8.30901 12.4017 8.23201 12.3617 8.16201C12.3217 8.09301 12.2707 8.03401 12.2087 7.98801C12.1467 7.94201 12.0717 7.91901 11.9837 7.91901C11.7757 7.91901 11.6257 8.03501 11.5337 8.26801C11.4417 8.49901 11.3957 8.88401 11.3957 9.41901C11.3957 9.67601 11.4037 9.90701 11.4187 10.115C11.4357 10.323 11.4637 10.5 11.5057 10.646C11.5477 10.792 11.6087 10.904 11.6867 10.982C11.7647 11.06 11.8657 11.099 11.9897 11.099C12.0417 11.099 12.0977 11.084 12.1607 11.057C12.2227 11.029 12.2807 10.987 12.3347 10.931C12.3897 10.874 12.4347 10.804 12.4697 10.718C12.5057 10.632 12.5247 10.53 12.5247 10.414V9.97601H11.9547V9.34001H13.3527V11.65H12.7157V11.254H12.7037C12.5997 11.423 12.4747 11.541 12.3287 11.611C12.1827 11.681 12.0077 11.716 11.8037 11.716C11.5397 11.716 11.3247 11.669 11.1587 11.577C10.9927 11.485 10.8627 11.344 10.7687 11.151C10.6737 10.959 10.6117 10.722 10.5797 10.44C10.5477 10.158 10.5317 9.83101 10.5317 9.45901C10.5317 9.10001 10.5537 8.78301 10.6007 8.51201C10.6467 8.24001 10.7257 8.01301 10.8367 7.83001C10.9487 7.64801 11.0967 7.51201 11.2807 7.41901C11.4647 7.32701 11.6947 7.28101 11.9707 7.28101C12.4427 7.28101 12.7827 7.39801 12.9907 7.63301C13.1987 7.86601 13.3027 8.20101 13.3027 8.63801H12.4757C12.4767 8.55901 12.4667 8.47701 12.4487 8.39301Z"
                    fill="white"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_4_38460"
                    x1="6.8338"
                    y1="4.8327"
                    x2="33.2548"
                    y2="31.2577"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EFEEEE" />
                    <stop offset="1" stopColor="#DEDEDD" />
                </linearGradient>
                <clipPath id="clip0_4_38460">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    ),
    FileMp4: (
        <svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4_38469)">
                <path d="M4 0V24H18L24 18V0H4Z" fill="url(#paint0_linear_4_38469)" />
                <path d="M18 18V24L24 18H18Z" fill="#ABABAB" />
                <path d="M12 24H18V18L12 24Z" fill="#DEDEDD" />
                <path d="M0 6V13H16V6H0Z" fill="#3A3A39" />
                <path
                    d="M3.60287 7.36597L4.21387 10.39H4.22687L4.84487 7.36597H6.12187V11.65H5.32987V8.22997H5.31787L4.55587 11.65H3.88387L3.12187 8.22997H3.10987V11.65H2.31787V7.36597H3.60287Z"
                    fill="white"
                />
                <path
                    d="M8.6121 7.36499C8.8321 7.36499 9.0161 7.40299 9.1671 7.47699C9.3181 7.55099 9.4371 7.64799 9.5301 7.76799C9.6221 7.88799 9.6881 8.02499 9.7281 8.17899C9.7671 8.33199 9.78811 8.48999 9.78811 8.64999C9.78811 8.86999 9.7551 9.06199 9.6891 9.22599C9.6231 9.38999 9.5311 9.52399 9.4131 9.62699C9.2951 9.73099 9.1521 9.80999 8.9841 9.86099C8.8161 9.91199 8.6301 9.93899 8.4251 9.93899H7.9641V11.649H7.1001V7.36499H8.6121ZM8.3481 9.30499C8.5201 9.30499 8.6601 9.25199 8.7651 9.14799C8.8711 9.04399 8.9241 8.88199 8.9241 8.66199C8.9241 8.44599 8.8781 8.28199 8.7861 8.16999C8.6941 8.05899 8.5401 8.00199 8.3241 8.00199H7.9641V9.30499H8.3481Z"
                    fill="white"
                />
                <path
                    d="M11.7689 7.40186H12.6629V10.0959H13.0709V10.7678H12.6629V11.6498H11.8699V10.7678H10.3579V10.0599L11.7689 7.40186ZM11.8579 8.48185L11.0299 10.0959H11.8699V8.48185H11.8579Z"
                    fill="white"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_4_38469"
                    x1="6.83345"
                    y1="4.8325"
                    x2="33.255"
                    y2="31.2575"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EFEEEE" />
                    <stop offset="1" stopColor="#DEDEDD" />
                </linearGradient>
                <clipPath id="clip0_4_38469">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    ),
    FileMov: (
        <svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4_38478)">
                <path d="M4 0V24H18L24 18V0H4Z" fill="url(#paint0_linear_4_38478)" />
                <path d="M18 18V24L24 18H18Z" fill="#ABABAB" />
                <path d="M12 24H18V18L12 24Z" fill="#DEDEDD" />
                <path d="M0 6V13H16V6H0Z" fill="#50B6E7" />
                <path
                    d="M3.60287 7.36597L4.21487 10.39H4.22687L4.84487 7.36597H6.12287V11.65H5.32987V8.22997H5.31787L4.55587 11.65H3.88487L3.12287 8.22997H3.10987V11.65H2.31787V7.36597H3.60287Z"
                    fill="white"
                />
                <path
                    d="M6.93611 8.61098C6.96611 8.33798 7.03211 8.10198 7.13411 7.90598C7.23711 7.71098 7.38311 7.55698 7.57411 7.44698C7.76611 7.33798 8.02511 7.28198 8.34811 7.28198C8.67211 7.28198 8.93011 7.33798 9.12211 7.44698C9.31311 7.55698 9.46011 7.71098 9.56211 7.90598C9.66511 8.10198 9.73011 8.33798 9.76011 8.61098C9.78911 8.88498 9.80511 9.18398 9.80511 9.50798C9.80511 9.83498 9.78911 10.136 9.76011 10.407C9.73011 10.679 9.66411 10.913 9.56211 11.109C9.46011 11.305 9.31311 11.455 9.12211 11.559C8.93011 11.663 8.67211 11.715 8.34811 11.715C8.02511 11.715 7.76611 11.662 7.57411 11.559C7.38311 11.455 7.23611 11.305 7.13411 11.109C7.03211 10.913 6.96611 10.679 6.93611 10.407C6.90711 10.136 6.89111 9.83498 6.89111 9.50798C6.89111 9.18498 6.90611 8.88598 6.93611 8.61098ZM7.77511 10.243C7.78911 10.445 7.81811 10.609 7.85911 10.735C7.90211 10.861 7.96111 10.953 8.03911 11.011C8.11711 11.069 8.22111 11.098 8.34911 11.098C8.47711 11.098 8.58011 11.069 8.65911 11.011C8.73711 10.952 8.79711 10.861 8.83911 10.735C8.88111 10.609 8.90911 10.445 8.92311 10.243C8.93711 10.041 8.94411 9.79698 8.94411 9.50898C8.94411 9.22098 8.93611 8.97698 8.92311 8.77698C8.91011 8.57698 8.88111 8.41298 8.83911 8.28498C8.79711 8.15698 8.73711 8.06398 8.65911 8.00698C8.58111 7.94798 8.47711 7.91898 8.34911 7.91898C8.22111 7.91898 8.11811 7.94798 8.03911 8.00698C7.96111 8.06498 7.90211 8.15698 7.85911 8.28498C7.81811 8.41298 7.78911 8.57698 7.77511 8.77698C7.76111 8.97698 7.75411 9.22098 7.75411 9.50898C7.75411 9.79698 7.76211 10.041 7.77511 10.243Z"
                    fill="white"
                />
                <path
                    d="M11.8049 10.534H11.8229L12.4599 7.36597H13.3539L12.3699 11.65H11.2599L10.2769 7.36597H11.2009L11.8049 10.534Z"
                    fill="white"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_4_38478"
                    x1="6.83335"
                    y1="4.83336"
                    x2="33.2573"
                    y2="31.2574"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EFEEEE" />
                    <stop offset="1" stopColor="#DEDEDD" />
                </linearGradient>
                <clipPath id="clip0_4_38478">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    ),
    FileGif: (
        <svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4_38496)">
                <path d="M4 0V24H18L24 18V0H4Z" fill="url(#paint0_linear_4_38496)" />
                <path d="M18 18V24L24 18H18Z" fill="#ABABAB" />
                <path d="M12 24H18V18L12 24Z" fill="#DEDEDD" />
                <path d="M0 6V13H16V6H0Z" fill="#010101" />
                <path
                    d="M4.17481 8.39301C4.15681 8.30901 4.12781 8.23201 4.08781 8.16201C4.04781 8.09301 3.99681 8.03401 3.93581 7.98801C3.87381 7.94201 3.79781 7.91901 3.70981 7.91901C3.50181 7.91901 3.35281 8.03501 3.25981 8.26801C3.16781 8.49901 3.12181 8.88401 3.12181 9.41901C3.12181 9.67601 3.12981 9.90701 3.14581 10.115C3.16181 10.323 3.19081 10.5 3.23281 10.646C3.27481 10.792 3.33481 10.904 3.41281 10.982C3.49081 11.06 3.59181 11.099 3.71581 11.099C3.76881 11.099 3.82481 11.084 3.88781 11.057C3.94981 11.029 4.00681 10.987 4.06181 10.931C4.11581 10.874 4.16081 10.804 4.19681 10.718C4.23281 10.632 4.24981 10.53 4.24981 10.414V9.97601H3.68081V9.34001H5.07781V11.65H4.44181V11.254H4.43081C4.32681 11.423 4.20181 11.541 4.05581 11.611C3.90981 11.681 3.73481 11.716 3.53081 11.716C3.26681 11.716 3.05181 11.669 2.88481 11.577C2.71781 11.485 2.58881 11.344 2.49481 11.151C2.40081 10.958 2.33781 10.722 2.30681 10.44C2.27481 10.158 2.25781 9.83101 2.25781 9.45901C2.25781 9.10001 2.28081 8.78301 2.32681 8.51201C2.37281 8.24001 2.45181 8.01301 2.56381 7.83001C2.67481 7.64801 2.82381 7.51201 3.00681 7.41901C3.19181 7.32701 3.42081 7.28101 3.69681 7.28101C4.16881 7.28101 4.50881 7.39801 4.71681 7.63301C4.92481 7.86601 5.02881 8.20101 5.02881 8.63801H4.20181C4.20181 8.55901 4.19281 8.47701 4.17481 8.39301Z"
                    fill="white"
                />
                <path d="M6.27783 7.36597H7.14183V11.65H6.27783V7.36597Z" fill="white" />
                <path
                    d="M10.7778 7.36597V8.07397H9.24779V9.08197H10.6878V9.78997H9.24779V11.65H8.38379V7.36597H10.7778Z"
                    fill="white"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_4_38496"
                    x1="6.83345"
                    y1="4.8348"
                    x2="33.2585"
                    y2="31.2548"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EFEEEE" />
                    <stop offset="1" stopColor="#DEDEDD" />
                </linearGradient>
                <clipPath id="clip0_4_38496">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    ),
    FileEps: (
        <svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4_38505)">
                <path d="M4 0V24H18L24 18V0H4Z" fill="url(#paint0_linear_4_38505)" />
                <path d="M18 18V24L24 18H18Z" fill="#ABABAB" />
                <path d="M12 24H18V18L12 24Z" fill="#DEDEDD" />
                <path d="M0 6V13H16V6H0Z" fill="#F47821" />
                <path
                    d="M4.6948 8.07397H3.1638V9.08197H4.6038V9.78997H3.1638V10.942H4.7538V11.65H2.2998V7.36597H4.6948V8.07397Z"
                    fill="white"
                />
                <path
                    d="M7.29764 7.36597C7.51764 7.36597 7.70264 7.40297 7.85364 7.47697C8.00264 7.55097 8.12464 7.64797 8.21564 7.76797C8.30664 7.88797 8.37364 8.02497 8.41364 8.17897C8.45364 8.33197 8.47364 8.48997 8.47364 8.64997C8.47364 8.86997 8.44164 9.06197 8.37464 9.22597C8.30964 9.38997 8.21664 9.52397 8.09864 9.62697C7.98164 9.73097 7.83764 9.80997 7.66964 9.86097C7.50164 9.91197 7.31564 9.93997 7.11164 9.93997H6.64964V11.65H5.78564V7.36597H7.29764ZM7.03364 9.30597C7.20564 9.30597 7.34465 9.25297 7.45064 9.14897C7.55564 9.04497 7.60964 8.88297 7.60964 8.66297C7.60964 8.44697 7.56365 8.28297 7.47064 8.17097C7.37864 8.05997 7.22564 8.00297 7.00864 8.00297H6.64964V9.30597H7.03364Z"
                    fill="white"
                />
                <path
                    d="M10.196 10.438C10.196 10.538 10.204 10.629 10.219 10.71C10.236 10.792 10.265 10.861 10.309 10.917C10.353 10.974 10.413 11.018 10.487 11.05C10.56 11.081 10.654 11.098 10.765 11.098C10.898 11.098 11.016 11.055 11.122 10.969C11.227 10.883 11.281 10.749 11.281 10.57C11.281 10.474 11.267 10.391 11.242 10.321C11.216 10.251 11.173 10.188 11.113 10.133C11.052 10.076 10.973 10.026 10.876 9.97998C10.779 9.93398 10.657 9.88698 10.513 9.83898C10.322 9.77498 10.156 9.70398 10.015 9.62798C9.87503 9.55298 9.75803 9.46398 9.66403 9.36098C9.57003 9.25898 9.50103 9.14098 9.45703 9.00698C9.41303 8.87198 9.39103 8.71798 9.39103 8.54098C9.39103 8.11698 9.50903 7.80198 9.74503 7.59398C9.98003 7.38598 10.305 7.28198 10.717 7.28198C10.909 7.28198 11.086 7.30298 11.248 7.34498C11.41 7.38698 11.55 7.45398 11.668 7.54898C11.786 7.64298 11.878 7.76298 11.944 7.90798C12.009 8.05398 12.043 8.22898 12.043 8.43298V8.55298H11.215C11.215 8.34898 11.179 8.19198 11.108 8.08098C11.036 7.97198 10.916 7.91598 10.748 7.91598C10.652 7.91598 10.572 7.93098 10.508 7.95798C10.445 7.98598 10.393 8.02298 10.355 8.06898C10.317 8.11498 10.292 8.16798 10.278 8.22798C10.263 8.28798 10.257 8.34998 10.257 8.41398C10.257 8.54698 10.285 8.65698 10.341 8.74698C10.397 8.83798 10.517 8.92098 10.701 8.99598L11.367 9.28398C11.531 9.35598 11.665 9.43098 11.768 9.50998C11.872 9.58798 11.956 9.67198 12.017 9.76198C12.079 9.85198 12.122 9.94998 12.146 10.059C12.17 10.166 12.182 10.287 12.182 10.418C12.182 10.87 12.051 11.199 11.789 11.405C11.527 11.611 11.162 11.715 10.694 11.715C10.206 11.715 9.85703 11.609 9.64603 11.397C9.43603 11.185 9.33203 10.881 9.33203 10.485V10.311H10.196V10.438Z"
                    fill="white"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_4_38505"
                    x1="6.83465"
                    y1="4.83465"
                    x2="33.2547"
                    y2="31.2546"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EFEEEE" />
                    <stop offset="1" stopColor="#DEDEDD" />
                </linearGradient>
                <clipPath id="clip0_4_38505">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    ),
    FileAvi: (
        <svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4_38514)">
                <path d="M4 0V24H18L24 18V0H4Z" fill="url(#paint0_linear_4_38514)" />
                <path d="M18 18V24L24 18H18Z" fill="#ABABAB" />
                <path d="M12 24H18V18L12 24Z" fill="#DEDEDD" />
                <path d="M0 6V13H16V6H0Z" fill="#387639" />
                <path
                    d="M4.202 7.36597L5.336 11.65H4.437L4.239 10.744H3.099L2.9 11.65H2L3.134 7.36597H4.202ZM4.088 10.036L3.674 8.10997H3.662L3.248 10.036H4.088Z"
                    fill="white"
                />
                <path
                    d="M7.36398 10.534H7.38198L8.01898 7.36597H8.91298L7.92798 11.65H6.81798L5.83398 7.36597H6.75798L7.36398 10.534Z"
                    fill="white"
                />
                <path d="M9.87207 7.36597H10.7361V11.65H9.87207V7.36597Z" fill="white" />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_4_38514"
                    x1="6.8331"
                    y1="4.83374"
                    x2="33.2586"
                    y2="31.2567"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EFEEEE" />
                    <stop offset="1" stopColor="#DEDEDD" />
                </linearGradient>
                <clipPath id="clip0_4_38514">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    ),
    FileOther: (
        <svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 0V24H18L24 18V0H4Z" fill="url(#paint0_linear_4_38523)" />
            <path d="M18 18V24L24 18H18Z" fill="#ABABAB" />
            <path d="M12 24H18V18L12 24Z" fill="#DEDEDD" />
            <defs>
                <linearGradient
                    id="paint0_linear_4_38523"
                    x1="6.8331"
                    y1="4.83374"
                    x2="33.2586"
                    y2="31.2567"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EFEEEE" />
                    <stop offset="1" stopColor="#DEDEDD" />
                </linearGradient>
            </defs>
        </svg>
    ),
};
