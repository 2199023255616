import React, { ReactNode } from "react";
import { useLocation } from "react-router-dom";
import { Header } from "Components/Header/Header";

interface IMenuProps {
    isInterfaceElementsHidden?: boolean;
    children: ReactNode;
}

export const Wrapper = ({ children, isInterfaceElementsHidden = false }: IMenuProps) => {
    const location = useLocation();
    const adminSettings = "/admin/settings";
    const isSideMenu =
        (location.pathname.includes(adminSettings) &&
            ["achievements"].some((item) => {
                return location.pathname.includes(`${adminSettings}/${item}`);
            })) ||
        location.pathname.includes("/admin/statistics");

    return (
        <div className="relative flex flex-col justify-start w-full h-full overflow-hidden">
            {!isInterfaceElementsHidden && <Header sideMenu={isSideMenu} />}
            <div className={`grow max-h-full min-h-0 ${!isSideMenu ? "overflow-y-scroll" : ""} custom-scrollbar`}>
                {children}
            </div>
            <div className="block sm:hidden min-h-[56px] w-full"></div>
        </div>
    );
};
