import React, { useState } from "react";
import { Modal } from "Uikit/Modal/Modal";
import { Except } from "type-fest";
import { SecondaryButton } from "Uikit/Button/SecondaryButton";
import { SubmitButton } from "Uikit/Button/SubmitButton";
import { FormGroup } from "Uikit/Forms/FormGroup";
import { Label } from "Uikit/Forms/Label";
import { Input } from "Uikit/Forms/Input";
import { AchievementResponse } from "Api/Responses/AchievementResponse";
import { useMutation } from "react-query";
import Api from "Api/index";
import { ID, TVoidFunction } from "types";
import { useInvalidate } from "hooks/useInvalidate";
import { flash } from "Uikit/Notification/flash";
import { BadRequestResponse, ErrorCode } from "Api/BaseResponse";
import { numCapEnd } from "helpers/numCapEnd";
import { LogoSize } from "Api/Services/UploadApi";
import { UIErrorMessages } from "Enums";

interface AchievementApplyModalProps {
    userId: ID;
    achievement: AchievementResponse | null;
    isOpen: boolean;
    onClose: TVoidFunction;
    onBack: TVoidFunction;
}

export const AchievementApplyModal = ({ isOpen, onClose, ...rest }: AchievementApplyModalProps) => {
    return (
        <Modal title="Выдать ачивку" isOpen={isOpen} onClose={onClose}>
            <ModalContent onClose={onClose} {...rest} />
        </Modal>
    );
};

const ModalContent = ({ userId, onBack, achievement, onClose }: Except<AchievementApplyModalProps, "isOpen">) => {
    const invalidate = useInvalidate();
    const [comment, setComment] = useState("");

    const { mutateAsync: apply } = useMutation((comment: string) => {
        return Api.Achievement.UserCreate({ achievementId: achievement!.id, userIds: [userId], comment });
    });

    if (!achievement) {
        return null;
    }

    const handleSubmit = async () => {
        try {
            await apply(comment);
            invalidate("users", "current");
            onClose();
            flash.success("Ачивка успешно выдана!");
        } catch (error) {
            if (error instanceof BadRequestResponse) {
                if (error.errorCode === ErrorCode.ACHIEVEMENT_LIMIT_EXCEEDED) {
                    flash.error("Превышен лимит ачивки.");
                } else {
                    flash.error("Ачивка не может быть выдана!");
                }
            } else {
                flash.error(UIErrorMessages.UNKNOWN_ERROR);
            }
        }
    };

    return (
        <>
            <Modal.Body>
                <div className="mt-6">
                    <div className="flex justify-center">
                        <div className="flex flex-grow space-x-4">
                            {achievement.logoId && (
                                <img
                                    className="rounded-full w-25 h-25 object-cover"
                                    src={Api.Upload.GetLogo(achievement.logoId, LogoSize.THUMB_MICRO)}
                                    alt={achievement.name}
                                />
                            )}
                            {!achievement.logoId && <div className="rounded-full w-25 h-25 bg-gray-blue" />}
                            <span className="my-auto p1 text-black">{achievement?.name}</span>
                        </div>
                        <span className="flex my-auto p1 text-primary">
                            {achievement?.rating}{" "}
                            {numCapEnd({ one: "балл", two: "балла", few: "баллов" }, Number(achievement?.rating))}
                        </span>
                    </div>
                    <FormGroup className="mt-8">
                        <Label>Комментарий</Label>
                        <Input type="text" value={comment} onChange={(e) => setComment(e.target.value)} />
                    </FormGroup>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <SecondaryButton onClick={onBack}>Назад</SecondaryButton>
                <SubmitButton onClick={handleSubmit}>Выдать</SubmitButton>
            </Modal.Footer>
        </>
    );
};
