import React, { useMemo, useState } from "react";
import { ColumnDef, ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { StatisticTaskRequest } from "Api/Requests/StatisticRequest";
import { useQuery } from "react-query";
import Api from "Api";
import { Button, Table } from "Uikit";
import { Loader } from "Uikit/Loader/Loader";
import { StatisticTaskResponseData } from "Api/Responses/StatisticResponse";
import { UserAvatar } from "Uikit/UserAvatar/UserAvatar";
import clsx from "clsx";
import { TVoidFunction } from "types";
import { LogoSize } from "Api/Services/UploadApi";

interface IReportTableProps {
    onClickLoadReportFile: TVoidFunction;
    queryPayload: StatisticTaskRequest;
}

export const ReportTable = ({ onClickLoadReportFile, queryPayload }: IReportTableProps) => {
    const [sorting, setSorting] = useState<SortingState>([{ id: "userLastName,userFirstName", desc: false }]);
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 15 });

    const [selectedRows, setSelectedRows] = useState<any>();
    const [rowSelection, setRowSelection] = useState({});

    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
    const [search, setSearch] = useState<string>("");

    console.log(selectedRows);

    const pagination = useMemo(() => ({ pageIndex, pageSize }), [pageIndex, pageSize]);
    const dataQuery = useQuery(
        ["reportUserTasks", "collection", pagination, sorting, search, queryPayload],
        async () => {
            const payload: StatisticTaskRequest = { ...queryPayload, searchQuery: search };
            return await Api.Statistic.CreateTaskReport(payload, pageIndex, pageSize, sorting);
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );

    const columns = React.useMemo<ColumnDef<StatisticTaskResponseData>[]>(
        () => [
            {
                header: "участник",
                enableResizing: true,
                accessorKey: "userLastName,userFirstName",
                cell: ({
                    row: {
                        original: { user },
                    },
                }) => {
                    return (
                        <a
                            className="group inline-flex items-center space-x-3 max-w-75 cursor-pointer text-black hover:no-underline"
                            href={`/admin/member/${user.id}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <UserAvatar
                                avatarId={user.avatarId}
                                color={user.defaultAvatarColor}
                                userInitials={`${user.firstName?.slice(0, 1)}${user.lastName?.slice(0, 1)}`}
                                size={36}
                            />
                            <div className={clsx("line-clamp-1", "group-hover:text-blue")}>
                                {user.lastName} {user.firstName}
                            </div>
                        </a>
                    );
                },
                footer: (props) => props.column.id,
            },
            {
                header: "ЗАДАНИЕ",
                accessorKey: "resourceTitle",
                enableResizing: true,
                footer: (props) => props.column.id,
                cell: ({
                    row: {
                        original: { exercise },
                    },
                }) => {
                    return (
                        <a
                            className="group inline-flex items-center space-x-3 cursor-pointer text-black hover:no-underline"
                            href={`/admin/task/${exercise.id}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {exercise.logoId && (
                                <img
                                    className="rounded-md w-[54px] h-9 object-cover"
                                    src={Api.Upload.GetLogo(exercise.logoId, LogoSize.THUMB_MICRO)}
                                    alt={exercise.name}
                                />
                            )}
                            {!exercise.logoId && <div className="rounded-md w-[54px] h-9 bg-gray-blue" />}
                            <span className="group-hover:text-blue">{exercise.name}</span>
                        </a>
                    );
                },
            },
            {
                header: "Прохождение",
                accessorKey: "passingNumber",
                enableResizing: true,
                footer: (props) => props.column.id,
                cell: ({
                    row: {
                        original: { passingNumber, passingLimit },
                    },
                }) => {
                    return (
                        <>
                            {passingNumber === null && "—"}
                            {passingNumber !== null && passingLimit
                                ? `${passingNumber} из ${passingLimit}`
                                : passingNumber}
                        </>
                    );
                },
            },
            {
                header: "Начисленные баллы",
                accessorKey: "rating",
                enableResizing: true,
                footer: (props) => props.column.id,
                cell: ({
                    row: {
                        original: { rating },
                    },
                }) => {
                    return <>{rating ?? "—"}</>;
                },
            },
        ],

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const controlButtons = (
        <div className="flex justify-end items-center col-start-1 row-start-1">
            <Button
                className="rounded-lg whitespace-nowrap font-medium w-full"
                size="medium"
                onClick={() => {
                    onClickLoadReportFile();
                }}
            >
                Выгрузить отчет в файл
            </Button>
        </div>
    );

    const isEmpty = dataQuery.data?.data.Content.length === 0 && search === "";

    return (
        <>
            {dataQuery.isFetching && !dataQuery.data?.data.Content && (
                <div className="w-full flex justify-center">
                    <Loader />
                </div>
            )}
            {dataQuery.data?.data.Content && (
                <div>
                    <Table
                        columns={columns}
                        controlButtons={controlButtons}
                        columnFilters={columnFilters}
                        pageCount={dataQuery.data.data.TotalPages}
                        pagination={pagination}
                        emptyTitle="Нет данных"
                        emptyMessage={
                            isEmpty
                                ? "По заданным параметрам данных для выгрузки нет"
                                : "По заданным параметрам результатов нет"
                        }
                        searchTitle="Поиск по участнику"
                        data={dataQuery.data.data.Content}
                        isFetching={dataQuery.isFetching}
                        rowSelection={rowSelection}
                        onPaginationChange={setPagination}
                        onRowSelectionChange={setRowSelection}
                        onColumnFiltersChange={setColumnFilters}
                        setSelectedRows={setSelectedRows}
                        sorting={sorting}
                        onSortingChange={setSorting}
                        onSearch={setSearch}
                        searchRowClassName={`gap-6.5 !grid grid-cols-2 ${isEmpty && "!grid-cols-1"}`}
                        searchClassName="!w-full"
                        searchBlockClassName={`col-start-2 col-end-2 ${isEmpty && "hidden"}`}
                        defaultSortOrder={{
                            "userLastName,userFirstName": "asc",
                            resourceTitle: "asc",
                        }}
                    />
                </div>
            )}
        </>
    );
};
