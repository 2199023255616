import React, { useRef, useId } from "react";
import { Button, ComboBox, flash, FormGroup, Input, Label, TextareaAutoSizable } from "Uikit";
import { TestRequestQuestion } from "Api/Requests/TestRequest";
import { SingleValue } from "react-select";
import { numberRegexp, testingRegexp } from "Uikit/Forms/Input";
import { IOption } from "types";
import { getFileSizeErrorMessage } from "helpers/file";
import { UIErrorMessages } from "Enums";

interface ITestContentItemNumber {
    errors: any;
    complexity: IOption[];
    question: TestRequestQuestion;
    onEdit: (question: TestRequestQuestion) => void;
    expanded?: boolean;
}
export const TestContentItemNumber = ({
    errors,
    complexity,
    question,
    onEdit,
    expanded = false,
}: ITestContentItemNumber) => {
    const inputRef = useRef(null);
    const id = useId();

    const onLogoChange = (event: any) => {
        const files = (event?.target as HTMLInputElement)?.files;

        if (!files || files.length === 0) {
            return;
        }

        if (!RegExp(/^image\/(jpg|jpeg|png)/).exec(files[0].type)) {
            flash.error(UIErrorMessages.FILE_EXTENSION_ERROR);

            return;
        }

        if (files[0].size > 2097152) {
            flash.error(getFileSizeErrorMessage("2 Мб"));
            return;
        }

        const reader = new FileReader();

        reader.readAsDataURL(files[0]);
        reader.onload = () => onEdit({ ...question, logoId: { file: files[0], base64: reader.result!.toString() } });
    };

    return (
        <>
            {!expanded ? (
                <FormGroup>
                    <Label className="break-all">{question.questionText}</Label>
                    {question.logoId && (
                        <img
                            className="mb-4 w-40 h-22.5 rounded-lg object-cover"
                            src={
                                typeof question.logoId === "object"
                                    ? question.logoId.base64
                                    : "/service/lms-upload/api/file/download/" + question.logoId
                            }
                            alt={question.questionText}
                        />
                    )}
                    <Input
                        type="number"
                        value={question.correctAnswer}
                        onClick={(e) => e.stopPropagation()}
                        id={id + "adminNewTestContentTabInputUtil"}
                        className="!pointer-events-none"
                    />
                </FormGroup>
            ) : (
                <>
                    <FormGroup className="flex items-center">
                        {question.logoId && (
                            <img
                                className="mr-6.5 w-40 h-22.5 rounded-lg object-cover"
                                src={
                                    typeof question.logoId === "object"
                                        ? question.logoId.base64
                                        : "/service/lms-upload/api/file/download/" + question.logoId
                                }
                                alt={question.questionText}
                            />
                        )}
                        <input
                            ref={inputRef}
                            id={id + "logoInput"}
                            className="hidden"
                            type="file"
                            accept="image/png, image/jpeg"
                            onChange={onLogoChange}
                        />
                        <Button
                            variant="outline"
                            color="secondary"
                            className="mr-4"
                            onClick={() => (inputRef.current as unknown as HTMLInputElement).click()}
                            id={id + "adminNewTestContentTabBtnloadImg"}
                        >
                            Загрузить картинку
                        </Button>
                        {question.logoId && (
                            <Button
                                variant="outline"
                                color="secondary"
                                className="mr-4"
                                onClick={() => onEdit({ ...question, logoId: "" })}
                                id={id + "adminNewTestContentTabbtnDeleteimg"}
                            >
                                Удалить
                            </Button>
                        )}
                        {!question.logoId && (
                            <div className="text-sm text-gray-text">
                                <p>Загрузите картинку вопроса</p>
                                <p>Поддерживаются файлы форматов JPG, PNG до 2Мб</p>
                            </div>
                        )}
                    </FormGroup>
                    <FormGroup>
                        <Label isRequired={true}>Текст вопроса</Label>
                        <TextareaAutoSizable
                            id={id + "adminNewTestContentTabInputTextQuestion"}
                            name="adminNewTestContentTabInputTextQuestion"
                            className="resize-none"
                            value={question.questionText}
                            placeholder="Введите текст вопроса"
                            cutRegExp={testingRegexp}
                            maxLength={1024}
                            error={errors["questionText"]}
                            onChange={(e) => onEdit({ ...question, questionText: e.target.value })}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label isRequired={true}>Правильный ответ</Label>
                        <Input
                            isRequired={true}
                            value={question.correctAnswer}
                            maxLength={20}
                            cutRegExp={numberRegexp}
                            placeholder="Введите правильный ответ"
                            error={errors["correctAnswer"]}
                            onChange={(e) =>
                                onEdit({
                                    ...question,
                                    correctAnswer: e.target.value,
                                })
                            }
                            id={id + "adminNewTestContentTabInputRightAnswer"}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label isRequired={true}>Сложность вопроса</Label>
                        <ComboBox
                            value={complexity[complexity.findIndex((p) => p.value === question.complexity)]}
                            options={complexity}
                            onChange={(option: SingleValue<any>) => onEdit({ ...question, complexity: option.value })}
                            id={id + "adminNewTestContentTabComboBoxComplexity"}
                            isSearchable={false}
                        />
                    </FormGroup>
                    {/*<FormGroup className="mt-1.5 pt-5.5 border-t border-gray-blue">*/}
                    {/*    <Label>Предложить материалы при ошибке:</Label>*/}
                    {/*    <Button*/}
                    {/*        className="mt-1 !p-0 h-auto border-0 !text-blue focus:!ring-0"*/}
                    {/*        variant="outline"*/}
                    {/*        color="common"*/}
                    {/*        iconPlacement="center"*/}
                    {/*        icon={<Icon className="mr-2" icon={Icons.PlusFilled} color="fill-blue" />}*/}
                    {/*    >*/}
                    {/*        Добавить материал*/}
                    {/*    </Button>*/}
                    {/*</FormGroup>*/}
                </>
            )}
        </>
    );
};
