import { Client } from "@stomp/stompjs";
import { getCookie } from "typescript-cookie";
import { WssMessage } from "types/WssMessage";
import { TVoidFunction } from "types/TVoidFunction";

let stompClient: any = null;
let subscribers: any[] = [];
let subscribtions: any[] = [];
let onWSCloseCallbacks: TVoidFunction[] = [];
let isAuthParam = true;

type INotificationsProps = {
    isAuth?: boolean;
    onWSCloseCallback?: TVoidFunction;
};

const NotificationConnect = (options: INotificationsProps = { isAuth: true }) => {
    const activate = (isAuth = true) => {
        isAuthParam = isAuth;
        stompClient.activate();
    };

    if (stompClient) {
        return { activate, stompClient };
    }

    isAuthParam = !!options?.isAuth;

    if (options?.onWSCloseCallback) {
        onWSCloseCallbacks.push(options.onWSCloseCallback);
    }

    stompClient = new Client({
        brokerURL:
            (process.env.NODE_ENV && process.env.NODE_ENV === "production" ? "wss://" : "ws://") +
            window.location.host +
            "/service/lms-notification/websocket/notifications",
    });

    stompClient.onConnect = () => {
        if (isAuthParam) {
            ["/user/topic/notifications", "/user/topic/system-events"].forEach((item) => {
                const subscription = stompClient.subscribe(item, (message: any) => {
                    subscribers.forEach((callback) => callback(message));
                });
                subscribtions.push(subscription);
            });
            stompClient.publish({
                destination: "/websocket/notifications",
                body: `{"messageType":"SESSION","body":{"sessionId":"${getCookie("USER_SESSION")}"}}`,
            });
        } else {
            const subscription = stompClient.subscribe("/topic/system-events", (message: any) => {
                subscribers.forEach((callback) => callback(message));
            });
            subscribtions.push(subscription);
        }
    };

    stompClient.onDisconnect = () => {
        subscribtions.forEach((subscribtion) => {
            subscribtion.unsubscribe();
        });
        subscribtions = [];
        subscribers = [];
        onWSCloseCallbacks = [];
    };

    stompClient.onWebSocketClose = () => {
        onWSCloseCallbacks.forEach((callback) => callback?.());
    };

    stompClient.activate();

    return { activate, stompClient };
};

export const NotificationSubscribe = (onMessage: (message: WssMessage) => Promise<void>) => {
    subscribers.push((message: any) => {
        let parsedMessage;

        if (message.isBinaryBody) {
            const stringFromBinary = new TextDecoder().decode(message._binaryBody);
            parsedMessage = JSON.parse(stringFromBinary);
        } else {
            parsedMessage = JSON.parse(message._body);
        }

        onMessage(parsedMessage).then();
    });
};

export default NotificationConnect;
