import { Expose, Type } from "class-transformer";
import { UserListResponse } from "Api/Responses/UserResponse";
import { JobResponse } from "Api/Responses/JobResponse";
// import { ResourceState, ProgressStatus, ResourceType } from "Enums";

export class Inviteteam {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public name: string;
}

export class InviteResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => Number)
    public createdAt: number;

    @Expose()
    @Type(() => String)
    public state: string;

    @Expose()
    @Type(() => String)
    public title: string;

    @Expose()
    @Type(() => Inviteteam)
    public teams: Inviteteam[];
}

export class InviteListResponse extends InviteResponse {
    @Expose()
    @Type(() => Boolean)
    public requiresAction: boolean;

    @Expose()
    @Type(() => Number)
    public registeredUserCount: number;
}

export class InviteJobsListResponse extends JobResponse {}

export class InvitedUsersListResponse extends UserListResponse {}

export class InviteOfficeResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public name: string;
}
