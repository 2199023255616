import React, { useState, useRef, useEffect, SyntheticEvent, useContext } from "react";
import { Icon, Icons, flash, FileViewDialog } from "Uikit";
import { FileReadResponse } from "Api/Responses/FileResponse";
import Dropzone from "react-dropzone";
import clsx from "clsx";
import { AttachmentType, FileUploadType } from "Enums";
import classNames from "classnames";
import Api from "Api";
import { humanFileSize } from "helpers/humanFileSize";
import { GlobalContext } from "App";
import { getFileSizeErrorMessage } from "helpers/file";

interface AttachmentsProps {
    className?: string;
    files: FileReadResponse[];
    type?: AttachmentType;
    sizeLimit?: number;
    onChange?: (files: any) => void;
    filesView?: "tiles" | "list";
}

const Attachments = ({
    className,
    files,
    onChange,
    type = AttachmentType.File,
    sizeLimit = 10000, // kb
    filesView = "list",
}: AttachmentsProps) => {
    const onChangeRef = useRef(onChange);
    const filesRef = useRef(files);
    const [selectedFile, setSelectedFile] = useState<FileReadResponse | null>(null);
    const [isViewFileModal, setIsViewFileModal] = useState(false);
    const { setIsFullScreenViewEnabled } = useContext(GlobalContext);

    useEffect(() => {
        filesRef.current = files;
    }, [files]);
    //
    useEffect(() => {
        onChangeRef.current = onChange;
    }, [onChange]);

    const onDrop = async (files: File[]) => {
        if (
            files.some(({ size }: File) => {
                return size / 1000 > sizeLimit;
            })
        ) {
            flash.error(getFileSizeErrorMessage());
            return;
        }

        const uploadFilePromises = files.map((file: File) => {
            return Api.File.UploadFile(file, null, null, true, FileUploadType.RESOURCE_ATTACHMENT);
        });

        const filesIds = await Promise.allSettled(uploadFilePromises).then((results) => {
            return results.reduce<any[]>((acc, result): any => {
                if (result.status === "fulfilled") {
                    acc.push(result.value.id);
                }
                return acc;
            }, []);
        });

        const generatPreviewResponse = await Api.Preview.generateFilePreview({ FileIds: filesIds });

        const getPreviewResponse = await Api.Preview.getFilePreview(generatPreviewResponse.taskId);

        console.log(getPreviewResponse);
    };

    // const _onChange = (e: any) => {
    //     // const _onUpdate = async (file: any) => {
    //     //     const i = filesRef.current.findIndex((f: any) => f.id === file.id);
    //     //     onChangeRef.current?.([...filesRef.current.slice(0, i), file, ...filesRef.current.slice(i + 1)]);
    //     // };

    const fileSelectHandler = (e: SyntheticEvent, file: FileReadResponse | null) => {
        e.stopPropagation();
        setSelectedFile(file);
        setIsViewFileModal(true);
        setIsFullScreenViewEnabled(true);
    };

    return (
        <>
            <div className={className}>
                <div className={classNames("flex flex-wrap", { "gap-4": filesView === "tiles" })}>
                    {files
                        // .filter((file) => file?.status !== "deleted")
                        .map((file) => {
                            // const fileType = file?.Type as keyof typeof FILES_TYPE_ICONS;
                            return (
                                <>
                                    {filesView === "list" ? (
                                        <div
                                            className="w-full flex min-h-20 p-[1rem] rounded-md pointer border border-[#EAEDF3]"
                                            key={file.id}
                                            // onClick={(e) => fileSelectHandler(e, file)}
                                        >
                                            {/* <Icon
                                                    className="mr-6"
                                                    width={24}
                                                    height={24}
                                                    icon={
                                                        <Icon
                                                            icon={FILES_TYPE_ICONS[fileType] || Icons.FileOther}
                                                            width="40px"
                                                            height="40px"
                                                        />
                                                    }
                                                /> */}
                                            <div className="!border-red flex-grow">
                                                <div className="!border-black flex justify-between mb-2">
                                                    <div className="break-all">
                                                        {/*{file.status === loading && (*/}
                                                        {/*    <img src={/icons/loading.svg} width={16} height={16} alt="" />*/}
                                                        {/*)}*/}
                                                        {file?.name}
                                                    </div>
                                                    {/*{file.status === done &&*/}
                                                    <div className="flex ml-auto">
                                                        {/*{!noDelete && (*/}
                                                        {/*    <Icon*/}
                                                        {/*        id={attachmentsDeleteIcon + i}*/}
                                                        {/*        className={cs.icon}*/}
                                                        {/*        width={20}*/}
                                                        {/*        height={20}*/}
                                                        {/*        icon={Icons.Delete}*/}
                                                        {/*        // tooltip={"Удалить"}*/}
                                                        {/*        // onClick={(e) => throwAway(e, file)}*/}
                                                        {/*    />*/}
                                                        {/*)}*/}
                                                        {/*{!noDownload && (*/}
                                                        <div className="relative">
                                                            {/*{loadingFileId === file.fileId ? (*/}
                                                            {/*    // <Loading size={20} withOverlay={false} withRelativeOverlay={false} />*/}
                                                            {/*) : (*/}
                                                            <Icon
                                                                className={"cursor-pointer ml-4"}
                                                                width={20}
                                                                height={20}
                                                                icon={
                                                                    <Icon
                                                                        icon={Icons.Download}
                                                                        width="22px"
                                                                        height="22px"
                                                                        color={"fill-[#7B8F9C]"}
                                                                    />
                                                                }
                                                                // onClick={(e) => download(e, file)}
                                                            />
                                                            {/*)}*/}
                                                        </div>
                                                        {/*)}*/}
                                                    </div>
                                                    {/*}*/}
                                                </div>
                                                <div className="opacity-50">
                                                    {/* {file.Extension.toUpperCase()} — {humanFileSize(file?.size)} */}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className="cursor-pointer relative"
                                            key={file.id}
                                            onClick={(e) => {
                                                fileSelectHandler(e, file);
                                            }}
                                        >
                                            {/* <img className={"rounded-[10px]"} src={file.TumbUrl} /> */}
                                            <div className="absolute bottom-3 right-3 bg-[rgba(38,38,38,0.5)] px-2 py-0.5 text-white rounded-[6px] text-[12px]">
                                                {/* <span>{file.Extension}</span> */}
                                                <span> · </span>
                                                <span>{humanFileSize(file?.size, true)}</span>
                                            </div>
                                        </div>
                                    )}
                                </>
                            );
                        })}

                    {files.length === 0 && (
                        <Dropzone onDrop={onDrop}>
                            {({ getRootProps, getInputProps }) => (
                                <div
                                    {...getRootProps()}
                                    className="border border-[#C9C9C9] border-dashed border-1 rounded-md w-full"
                                >
                                    <input id="imageInput" {...getInputProps()} accept="image/png, image/jpeg" />
                                    <label className={clsx("relative cursor-pointer h-16")}>
                                        {
                                            <div className="flex flex-col items-center py-4.5">
                                                <div className="mb-1.5">
                                                    <Icon icon={Icons.FileLoad} width={60} height={60} />
                                                </div>
                                                <p className="mb-1 text-gray-dark">
                                                    {type === AttachmentType.File
                                                        ? "Выберите файл или перетащите мышью"
                                                        : "Выберите изображение или перетащите мышью"}
                                                </p>
                                                <p className="text-gray p4">
                                                    {type === AttachmentType.File
                                                        ? "pdf, txt, xls, xlsx, doc, docx, ppt, pptx, pps, ppsx, avi, mp4, wmv, mov, mp3, wav, jpg, jpeg, png, gif до 200 МБ"
                                                        : "jpg, jpeg, png до 10 МБ"}
                                                </p>
                                            </div>
                                        }
                                    </label>
                                </div>
                            )}
                        </Dropzone>
                    )}
                </div>
            </div>
            {/* <UploadModal
                isOpen={dialog}
                onCloseHandler={toggleUploadModal}
                onSubmitHandler={_onChange}
                onAttachHandler={attachFiles}
                selectedFiles={files}
            /> */}

            {isViewFileModal && (
                <FileViewDialog
                    file={selectedFile as FileReadResponse & { type: string; data: string }}
                    handleClose={() => {
                        setIsViewFileModal(false);
                        setIsFullScreenViewEnabled(false);
                    }}
                />
            )}
        </>
    );
};

export default Attachments;
