import { useCallback, useState } from "react";
import { TVoidFunction } from "types";

type TBtnColor = "common" | "primary" | "secondary" | "success" | "warning" | "caution" | "danger";
type TBtnVariant = "standard" | "outline" | "link";

interface IDialogState {
    isOpen: boolean;
    title: string;
    description: string;
    content: string;
    closeBtnText?: string;
    closeBtnColor?: TBtnColor;
    closeBtnVariant?: TBtnVariant;
    submitBtnText: string;
    submitBtnColor?: TBtnColor;
    cancelBtnText?: string;
    onRequestClose: TVoidFunction;
    onRequestSubmit?: TVoidFunction;
    onRequestCancel?: TVoidFunction;
    onCloseModal?: TVoidFunction;
    requestSubmitDisabled?: boolean;
    onRequestSubmitAdd?: TVoidFunction;
    submitBtnAddText?: string;
    submitBtnAddColor?: TBtnColor;
    requestSubmitAddDisabled?: boolean;
}

interface IOpenDialogProps {
    title: string;
    description?: string;
    content: any;
    closeBtnText?: string;
    closeBtnColor?: TBtnColor;
    closeBtnVariant?: TBtnVariant;
    submitBtnText: string;
    submitBtnColor?: TBtnColor;
    cancelBtnText?: string;
    onRequestClose: TVoidFunction;
    onRequestSubmit?: TVoidFunction;
    onRequestCancel?: TVoidFunction;
    onCloseModal?: TVoidFunction;
    requestSubmitDisabled?: boolean;
    onRequestSubmitAdd?: TVoidFunction;
    submitBtnAddText?: string;
    submitBtnAddColor?: TBtnColor;
    requestSubmitAddDisabled?: boolean;
}

const defaultDialogState: IDialogState = {
    isOpen: false,
    title: "",
    description: "",
    content: "",
    closeBtnText: "Отмена",
    closeBtnColor: "secondary",
    closeBtnVariant: "outline",
    submitBtnText: "Подтвердить",
    submitBtnColor: "primary",
    onRequestClose: () => undefined,
    onRequestSubmit: () => undefined,
    onRequestSubmitAdd: () => undefined,
    onCloseModal: () => undefined,
    submitBtnAddText: "Подтвердить",
    submitBtnAddColor: "primary",
};

export const useDialog = (): {
    dialogState: IDialogState;
    openDialog: (state: IOpenDialogProps) => void;
    closeDialog: TVoidFunction;
} => {
    const [dialogState, setDialogState] = useState<IDialogState>(defaultDialogState);

    const openDialog = useCallback(
        ({
            title,
            description = "",
            content,
            onRequestSubmit,
            onRequestClose,
            onRequestCancel,
            onCloseModal,
            closeBtnText,
            closeBtnColor,
            closeBtnVariant,
            submitBtnText,
            submitBtnColor,
            cancelBtnText,
            requestSubmitDisabled,
            onRequestSubmitAdd,
            submitBtnAddText,
            submitBtnAddColor,
            requestSubmitAddDisabled,
        }: IOpenDialogProps) => {
            setDialogState({
                isOpen: true,
                title,
                description,
                content,
                closeBtnText,
                closeBtnColor,
                closeBtnVariant,
                submitBtnText,
                submitBtnColor,
                cancelBtnText,
                onRequestSubmit,
                onRequestClose,
                onRequestCancel,
                onCloseModal,
                requestSubmitDisabled,
                onRequestSubmitAdd,
                submitBtnAddText,
                submitBtnAddColor,
                requestSubmitAddDisabled,
            });
        },
        [],
    );

    const closeDialog = useCallback(() => {
        setDialogState((prevState) => {
            return {
                ...prevState,
                isOpen: false,
            };
        });
    }, []);

    return { dialogState, openDialog, closeDialog };
};
