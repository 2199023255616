import BaseApi from "../BaseApi";
import { BasePaginationRequest, BaseRequest } from "../BaseRequest";
import { BaseArrayResponse, BasePaginationResponse, BaseResponse } from "../BaseResponse";
import {
    UserRequest,
    UserPhoneValidationRequest,
    EditUserRequest,
    UserLoginValidationRequest,
    UserEmailValidationRequest,
    UserPublicPhoneValidationRequest,
    UserPublicEmailValidationRequest,
} from "../Requests/UserRequest";

import {
    CurrentUserResponse,
    UserAccessResponse,
    UserActiveListResponse,
    UserListResponse,
    AccountableUser,
    UserStatisticListResponse,
    MembersListResponse,
    CertificatesListResponse,
    UsersImportFileValidationResponse,
    UsersImportStatusResponse,
} from "../Responses/UserResponse";
import { fetchActiveUserData, fetchUserMemberAccessData } from "Uikit/Table/fetchTestData";
import { ID } from "types/ID";
import { SortingState } from "@tanstack/react-table";
import { LMSFilterParams } from "models/LMSFilterParams";
import { UserImportTypes } from "Enums";

export default class UserApi extends BaseApi {
    GetList = (
        page: number,
        size: number,
        sort: SortingState,
        filter: { [id: string]: string },
    ): Promise<BasePaginationResponse<UserListResponse>> => {
        const params = LMSFilterParams({ filter, sort, page, size });
        return this.Get(new BasePaginationResponse(UserListResponse), `/users?${params.toString()}`);
    };

    GetActiveList = (): Promise<BasePaginationResponse<UserListResponse>> => {
        return this.Get(new BasePaginationResponse(UserListResponse), "/users?state.equal=ACTIVE");
    };

    GetActiveListOptions = (inputValue: string, page?: number): Promise<BasePaginationResponse<UserListResponse>> => {
        return this.Get(
            new BasePaginationResponse(UserListResponse),
            `/users?page=${page}&size=15&sort=firstName,lastName,asc&searchQuery.contains=${inputValue}`,
        );
    };

    ActiveList = async (data: BasePaginationRequest): Promise<BasePaginationResponse<UserActiveListResponse>> => {
        const result = await fetchActiveUserData({ pageIndex: data.page, pageSize: data.size, sort: data.sort });

        const response = new BasePaginationResponse(UserActiveListResponse);
        response.Total = result.pageCount;
        response.Content = result.rows;

        return response;
    };

    GetCurrentUser = (): Promise<CurrentUserResponse> => {
        return this.Get(new CurrentUserResponse(), "/users/current");
    };

    Read = (id: ID): Promise<CurrentUserResponse> => {
        return this.Get(CurrentUserResponse, `/users/${id}`);
    };

    Create = async (data: UserRequest): Promise<UserListResponse> => {
        return await this.Post(UserListResponse, "/users", data);
    };

    Edit = async (data: UserRequest): Promise<UserListResponse> => {
        return await this.Put(UserListResponse, "/users", data);
    };

    Block = async (userId: ID): Promise<BaseResponse> => {
        return await this.Put(BaseResponse, `/users/block/${userId}`);
    };

    UnBlock = async (userId: ID, teamIds: string[]): Promise<BaseResponse> => {
        return await this.Put(BaseResponse, "/users/unblock", { id: userId, teamIds: teamIds } as BaseRequest);
    };

    AccessList = async (data: BasePaginationRequest): Promise<BasePaginationResponse<UserAccessResponse>> => {
        const result = await fetchUserMemberAccessData({
            pageIndex: data.size,
            pageSize: data.page,
            sort: data.sort,
        });

        const response = new BasePaginationResponse(UserAccessResponse);
        response.Total = result.pageCount;
        response.Content = result.rows;

        return response;
    };

    GetAdministratorsList = async (): Promise<BaseArrayResponse<AccountableUser>> => {
        return this.Get(new BaseArrayResponse(AccountableUser), "/users/administrators");
    };

    editCurrentUser = (request: EditUserRequest): Promise<BaseResponse> => {
        return this.Put(BaseResponse, "/users/current", request);
    };

    GetTeamUsers = (
        id: string,
        page: number,
        size: number,
        filter: { [id: string]: string },
        sort: string,
    ): Promise<BasePaginationResponse<UserStatisticListResponse>> => {
        const params = LMSFilterParams({ filter, page, size }, false);
        params.append("sort", sort ?? "");
        return this.Get(
            new BasePaginationResponse(UserStatisticListResponse),
            `/teams/user-space/users/${id}?${params.toString()}`,
            {},
        );
    };

    UserPhoneValidation = async (data: UserPhoneValidationRequest): Promise<string> => {
        return await this.Post("", "/admin/validate/phone", data as BaseRequest);
    };

    UserPublicPhoneValidation = async (data: UserPublicPhoneValidationRequest): Promise<string> => {
        return await this.Post("", "/user/validate/phone", data as BaseRequest);
    };

    UserLoginValidation = async (data: UserLoginValidationRequest): Promise<string> => {
        return await this.Post("", "/admin/validate/login", data as BaseRequest);
    };

    UserEmailValidation = async (data: UserEmailValidationRequest): Promise<string> => {
        return await this.Post("", "/admin/validate/email", data as BaseRequest);
    };

    UserPublicEmailValidation = async (data: UserPublicEmailValidationRequest): Promise<string> => {
        return await this.Post("", "/user/validate/phone", data as BaseRequest);
    };

    setAdminCompany = (companyId: string): Promise<string> => {
        return this.Put("", "/users/set/admin/company", { companyId } as BaseRequest);
    };

    GetMembersList = (
        page: number,
        size: number,
        sort: SortingState,
        filter: { [id: string]: string },
    ): Promise<BasePaginationResponse<MembersListResponse>> => {
        const params = LMSFilterParams({ filter, sort, page, size });
        return this.Get(
            new BasePaginationResponse(MembersListResponse),
            `/users/admin-space/user-list?${params.toString()}`,
        );
    };

    GetCertificatesList = (page: number, size: number): Promise<BasePaginationResponse<CertificatesListResponse>> => {
        return this.Get(
            new BasePaginationResponse(CertificatesListResponse),
            `/certificates/list?page=${page}&size=${size}`,
        );
    };

    DownloadCertificateFile = (certificateId: string): Promise<string> => {
        return this.Get("", `/certificates/download/${certificateId}`, new BaseRequest(), "blob");
    };

    UsersImportFileValidation = async (
        fileId: string,
        type: UserImportTypes,
    ): Promise<UsersImportFileValidationResponse> => {
        return await this.Post(
            new UsersImportFileValidationResponse(),
            `/users/import/validation?fileId=${fileId}&type=${type}`,
        );
    };

    UsersImport = async (fileId: string, type: UserImportTypes): Promise<string> => {
        return await this.Post("", `/users/import?fileId=${fileId}&type=${type}`);
    };

    UsersImportStatus = async (): Promise<UsersImportStatusResponse> => {
        return await this.Get(new UsersImportStatusResponse(), `/users/import/status`);
    };

    UsersExport = (filter: { [id: string]: string }, cancelToken: any): Promise<string> => {
        const filters = Object.keys(filter)
            .map((p) => `&${p}=${filter[p]}`)
            .join("");

        return this.Post(
            "",
            `/users/export?${filters.substring(1)}`,
            new BaseRequest(),
            "arraybuffer",
            {},
            cancelToken,
        );
    };
}
