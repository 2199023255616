import React, { useEffect, useState } from "react";

import { Breadcrumbs, ComboBox, Search } from "Uikit";
import { TabsWrapper } from "Uikit/Tabs/TabsWrapper";
import { Tabs } from "Uikit/Tabs/Tabs";
import { Content } from "Uikit/Tabs/Content";
import { RatingTable } from "./RatingTable";
import { RatingTableMobile } from "./RatingTableMobile";
import { useDebounceValue } from "hooks/useDebounceValue";
import { useGetUIRatingUserTeams } from "./Rating.hooks";
import "./Rating.scss";
import { setIsBack, setIsFavorite, setIsHidden, setTitle } from "slices/headerSlice";
import { useDispatch } from "react-redux";
import { useMediaQuery, MEDIA_QUERY_SM } from "hooks/useMediaQuery";
import { useScreenSize } from "hooks/useMediaQuery";

const periodOptions = [
    { label: "Последний месяц", value: "LAST_MONTH" },
    { label: "Последние 3 месяца", value: "LAST_THREE_MONTHS" },
    { label: "Последний год", value: "LAST_YEAR" },
    { label: "За всё время", value: "" },
];
const mobilePeriodOptions = [
    { label: "Месяц", value: "LAST_MONTH" },
    { label: "3 месяца", value: "LAST_THREE_MONTHS" },
    { label: "Год", value: "LAST_YEAR" },
    { label: "Все", value: "" },
];

export const Rating = () => {
    const dispatch = useDispatch();
    const [period, setPeriod] = useState(periodOptions[2]);

    const [currentTab, setCurrentTab] = useState(0);

    const [query, setQuery] = useState("");
    const [debounceQuery] = useDebounceValue(query, 500);

    const { data: currentUserTeams } = useGetUIRatingUserTeams();
    const matchesSM = useMediaQuery(MEDIA_QUERY_SM);

    useEffect(() => {
        dispatch(setIsHidden(false));
        dispatch(setIsBack(false));
        dispatch(setTitle("Рейтинг"));
        dispatch(setIsFavorite(false));
    }, [dispatch]);

    useEffect(() => {
        setQuery("");
        setPeriod(periodOptions[2]);
    }, [currentTab]);

    const { size } = useScreenSize();
    const isSmall = size === "small";

    return (
        <div className="px-3 sm:px-0 flex flex-wrap justify-center">
            <TabsWrapper
                className="sm:pl-4 sm:pr-6.5 sm:max-w-[1216px] 2xl:max-w-[1506px] w-full"
                selectedIndex={currentTab}
                onChange={setCurrentTab}
            >
                <Breadcrumbs id="userRatingBreadcrumbs" className="hidden sm:block">
                    <Breadcrumbs.Link title="Рейтинг" url="" />
                </Breadcrumbs>

                <TabsWrapper.Tabs id="userRatingTabsBlock" classname="w-full">
                    <Tabs.Tab title="Общий" />
                    {currentUserTeams?.teams?.map((team) => {
                        return <Tabs.Tab key={`${team.id}_tab`} title={team.name} />;
                    })}
                </TabsWrapper.Tabs>

                <TabsWrapper.Content>
                    <Content.Body className="mt-4 2xl:mt-6.25">
                        <div className="space-y-5">
                            <div className="flex justify-between space-x-2 sm:space-x-0">
                                <div className="w-full sm:w-auto">
                                    <div className="hidden sm:block">
                                        <Search
                                            id="userRatingSearchInput"
                                            placeholder="Поиск по участнику"
                                            value={query}
                                            onChange={(event) => setQuery(event.target.value)}
                                        />
                                    </div>
                                    <div className="block sm:hidden">
                                        <Search
                                            id="userRatingSearchInput"
                                            className="min-w-min"
                                            placeholder="Поиск участника"
                                            value={query}
                                            onChange={(event) => setQuery(event.target.value)}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <ComboBox
                                        isMulti={false}
                                        options={mobilePeriodOptions}
                                        className="block sm:hidden min-w-25 w-25"
                                        value={period}
                                        onChange={(value) => setPeriod(value!)}
                                        isSearchable={false}
                                    />
                                    <ComboBox
                                        isMulti={false}
                                        options={periodOptions}
                                        className="hidden sm:block w-55 2xl:w-62.5"
                                        value={period}
                                        onChange={(value) => setPeriod(value!)}
                                        isSearchable={false}
                                    />
                                </div>
                            </div>
                        </div>
                        {matchesSM ? (
                            <RatingTable search={debounceQuery} period={period} />
                        ) : (
                            <RatingTableMobile search={debounceQuery} period={period} />
                        )}
                    </Content.Body>
                    {currentUserTeams?.teams?.map((team) => {
                        return (
                            <Content.Body key={team.id} className="mt-4 2xl:mt-6.25">
                                <div className="space-y-5">
                                    <div className="flex justify-between space-x-2 sm:space-x-0">
                                        <div className="w-full sm:w-auto">
                                            <div className="hidden sm:block">
                                                <Search
                                                    id="userRatingSearchInput"
                                                    placeholder="Поиск по участнику"
                                                    value={query}
                                                    onChange={(event) => setQuery(event.target.value)}
                                                />
                                            </div>
                                            <div className="block sm:hidden">
                                                <Search
                                                    id="userRatingSearchInput"
                                                    className="min-w-min"
                                                    placeholder="Поиск участника"
                                                    value={query}
                                                    onChange={(event) => setQuery(event.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <ComboBox
                                            isMulti={false}
                                            options={mobilePeriodOptions}
                                            className="block sm:hidden min-w-25 w-25"
                                            value={period}
                                            onChange={(value) => setPeriod(value!)}
                                            isSearchable={false}
                                        />
                                        <ComboBox
                                            isMulti={false}
                                            options={periodOptions}
                                            className="hidden sm:block w-55 2xl:w-62.5"
                                            value={period}
                                            onChange={(value) => setPeriod(value!)}
                                            isSearchable={false}
                                        />
                                    </div>
                                </div>
                                <div className="hidden sm:block">
                                    <RatingTable search={debounceQuery} period={period} teamId={team.id} />
                                </div>
                                {isSmall && (
                                    <div className="block sm:hidden">
                                        <RatingTableMobile search={debounceQuery} period={period} teamId={team.id} />
                                    </div>
                                )}
                            </Content.Body>
                        );
                    })}
                </TabsWrapper.Content>
            </TabsWrapper>
        </div>
    );
};
