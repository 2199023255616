import React, { useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";

import { Filter } from "Components/Filter/Filter";
import { Empty } from "Uikit/Page/Empty";
import { Icon, Icons, Button } from "Uikit";
import { TestUserListResponse } from "Api/Responses/TestResponse";
import { ProgressStatus, ResourceType, NotificationActionType } from "Enums";
import { isAfter } from "date-fns";
import { Preloader } from "Components/Preloader/Preloader";
import NotificationConnect, { NotificationSubscribe } from "Api/Wss/Notifications";
import { Search } from "./Tests";
import { TestsCardList } from "./TestsCardList";
import { TestsCardListMobile } from "./TestsCardListMobile";
import { testsKeys, useAvailableTests, useChangeTestFavoriteStatus } from "./tests.hooks";
import { useScreenSize } from "hooks/useMediaQuery";
import { WssMessage } from "types/WssMessage";

export const TestsAll = () => {
    const [isFilterShow, setIsFilterShow] = useState<boolean>(false);
    const [filtersConfig, setFiltersConfig] = useState<any>([]);
    const [filters, setFilters] = useState<{ [id: string]: any }>({});

    const { data: tests, isFetched, isFetching, refetch: testsRefetch } = useAvailableTests(filters);
    const { mutate: mutateFavoriteStatus } = useChangeTestFavoriteStatus();

    const replayTests = useMemo(
        () => tests?.filter((p) => [ProgressStatus.FAILED, ProgressStatus.RE_WORK].includes(p.progressStatus)) ?? [],
        [tests],
    );

    const actualTests = useMemo(
        () =>
            tests
                ?.filter(
                    (p) =>
                        p.progressStatus === ProgressStatus.IN_PROGRESS ||
                        (!!p.deadlineTime && p.progressStatus !== ProgressStatus.RE_WORK),
                )
                .sort((p1: TestUserListResponse, p2: TestUserListResponse) => {
                    if (p1.deadlineTime && p2.deadlineTime) {
                        if (isAfter(new Date(p1.deadlineTime), new Date(p2.deadlineTime))) {
                            return 1;
                        }

                        if (isAfter(new Date(p2.deadlineTime), new Date(p1.deadlineTime))) {
                            return -1;
                        }
                    } else {
                        if (!p1.deadlineTime && p2.deadlineTime) {
                            return 1;
                        }

                        if (p1.deadlineTime && !p2.deadlineTime) {
                            return -1;
                        }

                        if (!p1.deadlineTime && !p2.deadlineTime) {
                            if (isAfter(new Date(p1.statusChangeTime), new Date(p2.statusChangeTime))) {
                                return 1;
                            }

                            if (isAfter(new Date(p1.statusChangeTime), new Date(p2.statusChangeTime))) {
                                return -1;
                            }
                        }
                    }

                    return 0;
                }) ?? [],
        [tests],
    );

    const categoryTests = useMemo(
        () =>
            tests
                ?.filter(
                    (p) =>
                        ![ProgressStatus.IN_PROGRESS, ProgressStatus.RE_WORK, ProgressStatus.FAILED].includes(
                            p.progressStatus,
                        ) && !p.deadlineTime,
                )
                .reduce<{ categoryId: string; categoryTitle: string; tests: TestUserListResponse[] }[]>((acc, p) => {
                    const category = acc.find((p1) => p1.categoryId === p.category.id);

                    if (category) {
                        category.tests.push(p);
                    } else {
                        acc.push({
                            categoryId: p.category.id,
                            categoryTitle: p.category.title,
                            tests: [p],
                        });
                    }

                    return acc;
                }, []) ?? [],
        [tests],
    );

    const onFavoriteChange = (resource: TestUserListResponse, isFavorite: boolean) => {
        mutateFavoriteStatus({ resource, isFavorite, queryKeys: testsKeys.listAvailable() });
    };

    const onSearch = (searchQuery: string) => {
        setFilters((prevState) => ({ ...prevState, searchQuery: searchQuery }));
    };

    useEffect(() => {
        if (filtersConfig.length !== 0 || !tests || tests.length === 0) {
            return;
        }

        setFiltersConfig([
            {
                label: "Награда, баллы",
                fields: [
                    {
                        accessor: "complexity",
                        type: "range",
                        min:
                            tests?.length !== 0
                                ? tests?.reduce((prev, curr) => (prev.ratingPoints < curr.ratingPoints ? prev : curr))
                                      .ratingPoints
                                : 0,
                        max:
                            tests?.length !== 0
                                ? tests?.reduce((prev, curr) => (prev.ratingPoints > curr.ratingPoints ? prev : curr))
                                      .ratingPoints
                                : 0,
                    },
                ],
            },
            {
                label: "Дополнительно",
                fields: [
                    {
                        accessor: "withDeadline",
                        label: "С дедлайном",
                        type: "checkbox",
                        default: true,
                    },
                ],
            },
        ]);
    }, [tests, filtersConfig]);

    // Подписка на выдачу допуска к тесту и обновление счётчика
    useEffect(() => {
        NotificationConnect();
        NotificationSubscribe(async (message: WssMessage) => {
            if (
                message.body.objectType === ResourceType.QUIZ &&
                message.body.actionType === NotificationActionType.ASSIGN
            ) {
                testsRefetch();
            }
        });
    }, [testsRefetch]);

    const { size } = useScreenSize();
    const isSmall = size === "small";

    return (
        <>
            {isSmall && (
                <div
                    className="absolute top-3.5 right-10 block sm:hidden z-[100]"
                    onClick={() => setIsFilterShow(true)}
                >
                    <Icon icon={Icons.Filter} width={18} height={18} color="stroke-blue-drk" />
                </div>
            )}
            <Filter
                isActive={isFilterShow}
                setIsActive={setIsFilterShow}
                configuration={filtersConfig}
                filters={filters}
                onChange={setFilters}
            />
            {!isFetching && (tests?.length !== 0 || Object.keys(filters).length !== 0) && (
                <div className="hidden sm:flex justify-between mb-5 2xl:mb-6.25">
                    <Search value={filters["searchQuery"]} onChange={(e) => onSearch(e ? e.target.value : "")} />
                    <Button
                        className="border-[#E6E9ED] rounded-lg font-medium"
                        variant="outline"
                        color="secondary"
                        size="medium"
                        icon={
                            <Icon
                                icon={Icons.Filter}
                                width={20}
                                height={20}
                                color="stroke-blue"
                                className="2xl:!w-6.25 2xl:!h-6.25"
                            />
                        }
                        iconPlacement="left"
                        onClick={() => setIsFilterShow(true)}
                        id="userTestsBtnFilter"
                    >
                        Фильтры
                    </Button>
                </div>
            )}
            <div className="relative">
                <Preloader
                    className="sm:grid sm:grid-cols-[repeat(4,274px)] 2xl:grid-cols-[repeat(4,342px)] gap-y-5 sm:gap-x-6.5 2xl:gap-x-8 sm:gap-y-9"
                    isShow={!isFetched}
                >
                    {Array.from(Array(16).keys()).map((p) => {
                        return (
                            <div key={p}>
                                <div className="w-60 h-36 sm:w-[274px] sm:h-41 2xl:w-[342px] 2xl:h-51 rounded-2xl overflow-hidden leading-0">
                                    <Skeleton className="rounded-2xl" width="100%" height="100%" />
                                </div>
                                <div className="leading-5 line-clamp-2 pt-3">
                                    <Skeleton className="rounded-2xl" width="100%" height="100%" />
                                </div>
                            </div>
                        );
                    })}
                </Preloader>
            </div>
            {!isFetching && tests?.length === 0 && Object.keys(filters).length === 0 && (
                <div className="h-full flex justify-center">
                    <Empty
                        title="Все тесты пройдены"
                        description="Вы прошли все тесты, скоро тут появятся новые"
                        topElement={
                            <div className="flex-center mb-4 2xl:mb-5">
                                <div className="flex-center w-16.5 h-16.5 2xl:w-20.5 2xl:h-20.5 rounded-full bg-blue-10">
                                    <Icon
                                        icon={Icons.Check}
                                        width={48}
                                        height={48}
                                        color="fill-primary"
                                        className="2xl:!w-15 2xl:!h-15"
                                    />
                                </div>
                            </div>
                        }
                    />
                </div>
            )}
            {!isFetching && tests?.length === 0 && Object.keys(filters).length !== 0 && (
                <div className="h-full flex justify-center">
                    <Empty
                        title="Ничего не найдено"
                        description="По заданным параметрам результатов нет"
                        topElement={
                            <div className="flex-center mb-4 2xl:mb-5">
                                <div className="flex-center w-16.5 h-16.5 2xl:w-20.5 2xl:h-20.5 rounded-full bg-blue-10">
                                    <Icon
                                        icon={Icons.EmojiSad}
                                        width={"36px"}
                                        height={"36px"}
                                        color={"fill-primary"}
                                        className="2xl:!w-11.25 2xl:!h-11.25"
                                    />
                                </div>
                            </div>
                        }
                    />
                </div>
            )}
            {replayTests.length > 0 && (
                <TestsCardList
                    isFinished={false}
                    isReview={false}
                    controlDisplayedItemsLength
                    title="Требуют перепрохождения"
                    data={replayTests}
                    id="userTestsReplay"
                    onFavoriteChange={onFavoriteChange}
                />
            )}
            {actualTests.length > 0 && (
                <TestsCardList
                    isFinished={false}
                    isReview={false}
                    controlDisplayedItemsLength
                    title="Актуальное"
                    data={actualTests}
                    id="userTestsActual"
                    onFavoriteChange={onFavoriteChange}
                />
            )}
            {[replayTests, actualTests, categoryTests].every((item) => item.length > 0) && (
                <div className="border-b-[1px] border-solid border-blue-gray mb-8 2xl:mb-9"></div>
            )}
            {categoryTests.length > 0 && (
                <>
                    <div className="hidden sm:block">
                        {categoryTests.map(({ categoryId, categoryTitle, tests }) => {
                            return (
                                <TestsCardList
                                    key={categoryId}
                                    id={`userTests${categoryId}`}
                                    isFinished={false}
                                    isReview={false}
                                    controlDisplayedItemsLength
                                    title={categoryTitle}
                                    data={tests}
                                    onFavoriteChange={onFavoriteChange}
                                />
                            );
                        })}
                    </div>
                    {isSmall && (
                        <div className="block sm:hidden">
                            {categoryTests.map(({ categoryId, categoryTitle, tests }) => {
                                return (
                                    <TestsCardListMobile
                                        key={categoryId}
                                        id={`userTests${categoryId}`}
                                        isFinished={false}
                                        isReview={false}
                                        title={categoryTitle}
                                        data={tests}
                                    />
                                );
                            })}
                        </div>
                    )}
                </>
            )}
        </>
    );
};
