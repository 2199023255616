import { BaseRequest } from "../BaseRequest";
import { ResourceState } from "Enums";

export class CourseCategoryRequest extends BaseRequest {
    public id?: string;
    public title: string;
}

export class CourseRequestSectionItem {
    public resourceId: string;
    public type: string;
    public approxCompletionMinutes: number;
    public materialType?: string;
    public progressStatus?: string;
    public title?: string;
    public status?: string;
    public ratingPoints?: number;
    public logoId?: string;
    public id?: string;
    public deadlineTimestamp?: number;
    public state?: ResourceState;
}

export class CourseRequestSection {
    public id: string;
    public title: string;
    public components: CourseRequestSectionItem[];
}

export class CourseRequest extends BaseRequest {
    public id?: string;
    public categoryId?: string;
    public logoId?: string;
    public managerUserId: string;
    public state: ResourceState;
    public publicAccess: boolean | null;
    public title: string;
    public description: string;
    public approxCompletionMinutes?: number;
    public isApproxCompletionMinutesManuallyChanged?: boolean;
    public publicationDelayTimestamp: number | null;
    public expirationTimestamp: number | null;
    public deadlineTimestamp: number | null;
    public isRequired: boolean;
    public issueCertificate: boolean;
    public hideAvgReviewScore: boolean;
    public hideUserReviews: boolean;
    public sections: CourseRequestSection[];
    public modifyTimestamp: number | null;
    public ratingPoints: number;
    public averageReviewRating?: number;
}

export class CourseUnArchiveRequest extends BaseRequest {
    public categoryId: string;
    public ids: string[];
}

export class CourseCategoryReorderRequest extends BaseRequest {
    public categoryIds: string[];
}
