import { Expose, Type } from "class-transformer";
import { BaseResponse } from "../BaseResponse";
import { ResourceState, ProgressStatus } from "Enums";

export class ProgramCategoryResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public title: string;
}
export class ProgramListResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public title: string;

    @Expose()
    @Type(() => String)
    public description: string;

    @Expose()
    @Type(() => String)
    public logoId: string;

    @Expose()
    @Type(() => String)
    public categoryId: string;

    @Expose()
    @Type(() => Boolean)
    public publicAccess: boolean;

    @Expose()
    @Type(() => Boolean)
    public isRequired: boolean;

    @Expose()
    @Type(() => Boolean)
    public issueCertificate: boolean;

    @Expose()
    @Type(() => Boolean)
    public hideAvgReviewScore: boolean;

    @Expose()
    @Type(() => Boolean)
    public hideUserReviews: boolean;

    @Expose()
    @Type(() => String)
    public state: ResourceState;

    @Expose()
    @Type(() => Number)
    public createTime: number;

    @Expose()
    @Type(() => Number)
    public modifyTime: number;

    @Expose()
    @Type(() => Number)
    public publicationTime?: number | null;

    @Expose()
    @Type(() => Number)
    public expirationTime?: number | null;

    @Expose()
    @Type(() => Number)
    public deadlineTime?: number | null;

    @Expose()
    @Type(() => Number)
    public complexity: number;

    @Expose()
    @Type(() => Number)
    public deadlineDate: number;

    @Expose()
    @Type(() => String)
    public managerUserId: string;

    @Expose()
    @Type(() => Number)
    public ratingPoints?: number;

    @Expose()
    @Type(() => Number)
    public approxCompletionMinutes?: number;

    @Expose()
    @Type(() => Number)
    public averageReviewRating: number;
}

export class ProgramSectionItem {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public title: string;

    @Expose()
    @Type(() => String)
    public description: string;

    @Expose()
    @Type(() => String)
    public logoId: string;

    @Expose()
    @Type(() => Number)
    public createTime: number;

    @Expose()
    @Type(() => Number)
    public modifyTime: number;

    @Expose()
    @Type(() => Number)
    public deadlineTime: number;

    @Expose()
    @Type(() => String)
    public state: ResourceState;

    @Expose()
    @Type(() => Number)
    public ratingPoints: number;

    @Expose()
    @Type(() => Number)
    public avgReviewRating: number;

    @Expose()
    @Type(() => String)
    public type?: string;

    @Expose()
    @Type(() => Number)
    public componentCount?: number;

    @Expose()
    @Type(() => String)
    public progressStatus?: ProgressStatus;

    @Expose()
    @Type(() => Number)
    public progressPercent?: number;

    @Expose()
    @Type(() => Number)
    public approxCompletionMinutes: number;
}

export class ProgramReadSection {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public title: string;

    @Expose()
    @Type(() => ProgramSectionItem)
    public courses: ProgramSectionItem[];
}

export class ProgramBaseResponse extends BaseResponse {
    // @Expose()
    // @Type(() => String)
    // public id: string;

    @Expose()
    @Type(() => String)
    public title: string;

    @Expose()
    @Type(() => String)
    public description: string;

    @Expose()
    @Type(() => String)
    public logoId?: string | null;

    @Expose()
    @Type(() => String)
    public categoryId: string;

    @Expose()
    @Type(() => Boolean)
    public publicAccess: boolean | null;

    @Expose()
    @Type(() => Boolean)
    public isRequired: boolean;

    @Expose()
    @Type(() => Boolean)
    public issueCertificate: boolean;

    @Expose()
    @Type(() => Boolean)
    public hideAvgReviewScore: boolean;

    @Expose()
    @Type(() => Boolean)
    public hideUserReviews: boolean;

    @Expose()
    @Type(() => String)
    public state: ResourceState;

    @Expose()
    @Type(() => Number)
    public createTime?: number;

    @Expose()
    @Type(() => Number)
    public modifyTime?: number;

    @Expose()
    @Type(() => Number)
    public publicationTime?: number | null;

    @Expose()
    @Type(() => Number)
    public expirationTime?: number | null;

    @Expose()
    @Type(() => Number)
    public deadlineTime?: number | null;

    @Expose()
    @Type(() => String)
    public managerUserId: string;

    @Expose()
    @Type(() => Number)
    public ratingPoints?: number;

    @Expose()
    @Type(() => Number)
    public approxCompletionMinutes?: number;
}

export class ProgramResponse extends ProgramBaseResponse {
    @Expose()
    @Type(() => String)
    public id: string;
}

export class ProgramSectionsResponse extends ProgramBaseResponse {
    @Expose()
    @Type(() => ProgramReadSection)
    public sections: ProgramReadSection[];
}

export class ProgramReadResponse extends ProgramSectionsResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => ProgramReadSection)
    public sections: ProgramReadSection[];

    @Expose()
    @Type(() => Number)
    public averageReviewRating?: number;

    @Expose()
    @Type(() => Boolean)
    public isApproxCompletionMinutesManuallyChanged: boolean;
}

export class ProgramReadUserResponse extends ProgramSectionsResponse {
    @Expose()
    @Type(() => Number)
    public receivedPoints: number;

    @Expose()
    @Type(() => Number)
    public totalCount: number;

    @Expose()
    @Type(() => Number)
    public passedCount: number;

    @Expose()
    @Type(() => Number)
    public startTime: number;

    @Expose()
    @Type(() => Number)
    public completionTime: number;

    @Expose()
    @Type(() => Boolean)
    public isFavorite: boolean;

    @Expose()
    @Type(() => String)
    public progressStatus?: ProgressStatus;

    @Expose()
    @Type(() => Number)
    public progressPercent?: number;

    @Expose()
    @Type(() => String)
    public resourceId: string;

    @Expose()
    @Type(() => String)
    public progressId: string;
}

export class ProgramReadProgressResponse extends BaseResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => Number)
    public passedCount?: number;

    @Expose()
    @Type(() => Number)
    public timeSpent?: number;
}

export class FilterRatingInfo extends BaseResponse {
    @Expose()
    @Type(() => Number)
    public minAverageReviewRating: number;

    @Expose()
    @Type(() => Number)
    public maxAverageReviewRating: number;

    @Expose()
    @Type(() => Number)
    public minRatingPoints: number;

    @Expose()
    @Type(() => Number)
    public maxRatingPoints: number;
}
