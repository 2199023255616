import BaseApi from "Api/BaseApi";
import { BasePaginationRequest } from "Api/BaseRequest";
import { BaseResponse, BasePaginationResponse } from "Api/BaseResponse";
import { FeedbackRequest, FeedbackListRequest, FeedbackCheckRequest } from "Api/Requests/FeedbackRequest";
import { FeedbackListResponse, FeedbackUserResponse } from "Api/Responses/FeedbackResponse";
import { LMSFilterParams } from "models/LMSFilterParams";

export default class FeedbackApi extends BaseApi {
    // Отправка отзыва
    SendFeedback = async (data: FeedbackRequest): Promise<FeedbackUserResponse> => {
        return await this.Post(FeedbackUserResponse, "/feedback", data);
    };

    // Список отзывов в админке - вкладка "Отзывы" у сущностей (таблица отзывов)
    FeedbackList = async ({
        resourceId,
        filters,
        page,
        size,
        sort,
    }: FeedbackListRequest): Promise<BasePaginationResponse<FeedbackListResponse>> => {
        const params = LMSFilterParams({
            filter: filters as { [id: string]: string },
            page,
            size,
        });
        params.append("sort", sort ?? "");

        return this.Get(
            new BasePaginationResponse(FeedbackListResponse),
            `/feedback/${resourceId}?${params.toString()}`,
        );
    };

    // Список отзывов в польз. части
    FeedbackListUser = async ({
        resourceId,
        page,
        size,
        sort,
    }: FeedbackListRequest): Promise<BasePaginationResponse<FeedbackListResponse>> => {
        const params = LMSFilterParams({
            page,
            size,
        });
        params.append("sort", sort ?? "");

        return this.Get(
            new BasePaginationResponse(FeedbackListResponse),
            `/feedback/user/${resourceId}?${params.toString()}`,
        );
    };

    // (Админка) Действия с отзывами в таблице отзывов
    FeedbackCheck = async (data: FeedbackCheckRequest): Promise<BaseResponse> => {
        return await this.Put(BaseResponse, `/feedback/review/check`, data);
    };

    // (Админка) Действия с отзывами в таблице отзывов
    FeedbackReviewList = async ({
        filters,
        page,
        size,
        sort,
    }: BasePaginationRequest): Promise<BasePaginationResponse<FeedbackListResponse>> => {
        const params = LMSFilterParams({
            filter: filters as { [id: string]: string },
            page,
            size,
        });
        params.append("sort", sort ?? "");

        return this.Get(new BasePaginationResponse(FeedbackListResponse), `/feedback/review?${params.toString()}`);
    };
}
