import React, { useState, ChangeEvent } from "react";
import { useMutation } from "react-query";
import clsx from "clsx";
import { Icon, Icons, Button, Textarea } from "Uikit";
import { flash } from "Uikit/Notification/flash";
import Api from "Api";
import { FeedbackRequest } from "Api/Requests/FeedbackRequest";
import { ResourceType } from "Enums";
import { testingRegexp } from "Uikit/Forms/Input";

interface IFeedbackFormProps {
    resourceId: string;
    type: ResourceType;
}

export const FeedbackForm = ({ resourceId, type }: IFeedbackFormProps) => {
    const [rating, setRating] = useState(0);
    const [hoverRating, setHoverRating] = useState(0);
    const [comment, setComment] = useState<null | string>(null);
    const [isCommentSended, setIsCommentSended] = useState(false);

    const { mutateAsync: sendFeedback } = useMutation((payload: FeedbackRequest) => {
        return Api.Feedback.SendFeedback(payload);
    });

    const onCommentChange = (e: ChangeEvent<HTMLInputElement>) => {
        setComment(e.target.value ?? null);
    };

    const onSubmitComment = async () => {
        if (rating) {
            try {
                await sendFeedback({
                    resourceId: resourceId,
                    type: type,
                    rating,
                    comment,
                });
                setIsCommentSended(true);
            } catch (error: any) {
                flash.error("Произошла ошибка при отправке отзыва");
                console.log(error);
            }
        }
    };

    return (
        <div className="flex flex-col gap-5 2xl:gap-6.25 w-full max-w-175 2xl:max-w-[875px] mb-6.25">
            <div className="flex w-full justify-center" id="feedbackRating">
                {Array.from(Array(5).keys()).map((p) => {
                    return (
                        <div
                            key={p}
                            className="pl-1 2xl:pl-1.5 first:pl-0 cursor-pointer"
                            onClick={() => setRating(p + 1)}
                            onMouseEnter={() => setHoverRating(p + 1)}
                            onMouseLeave={() => setHoverRating(0)}
                        >
                            <Icon
                                icon={Icons.RatingStar}
                                width={60}
                                height={60}
                                color={p + 1 <= rating || p + 1 <= hoverRating ? "fill-yellow" : "fill-gray-stroke"}
                                className="transition-all duration-200 2xl:!w-[75px] 2xl:!h-[75px]"
                            />
                        </div>
                    );
                })}
            </div>
            <Textarea
                rows={3}
                value={comment ?? ""}
                onChange={onCommentChange}
                placeholder="Напишите комментарий к отзыву"
                maxLength={512}
                readOnly={isCommentSended}
                className={clsx(
                    "text-black min-h-[40px] 2xl:min-h-[50px]",
                    isCommentSended && "bg-background !border-background !text-gray-dark",
                )}
                cutRegExp={testingRegexp}
                id="feedbackComment"
            />
            <Button
                onClick={onSubmitComment}
                disabled={!rating || isCommentSended}
                className={`w-full ${isCommentSended ? "!text-blue" : ""}`}
                id="feedbackSendbtn"
            >
                {isCommentSended ? "Спасибо за отзыв!" : "Отправить отзыв"}
            </Button>
        </div>
    );
};
