import BaseApi from "../BaseApi";
import { BaseIdRequest, BasePaginationRequest } from "../BaseRequest";
import { BasePaginationResponse } from "../BaseResponse";
import {
    CommentListResponse,
    HistoryListResponse,
    RemarkListResponse,
    ReviewListResponse,
    TaskListTestResponse,
    TestListResponse,
} from "../Responses/VerificationResponse";
import {
    fetchCommentsData,
    fetchHistoryData,
    fetchRemark,
    fetchRemarksData,
    fetchReviewsData,
    fetchValidationTask,
    fetchTest,
} from "Uikit/Table/fetchTestData";
import { ValidationStatus } from "Enums";

export default class VerificationApi extends BaseApi {
    TaskRead = async (data: BaseIdRequest): Promise<TaskListTestResponse | undefined> => {
        return await fetchValidationTask(data.uuid, {
            status: [ValidationStatus.Approved, ValidationStatus.Fixed, ValidationStatus.Declined],
        });
    };

    TestRead = async (data: BaseIdRequest): Promise<TestListResponse | undefined> => {
        return await fetchTest(data.uuid, {
            status: [ValidationStatus.Approved, ValidationStatus.Fixed, ValidationStatus.Declined],
        });
    };

    RemarkList = async (data: BasePaginationRequest): Promise<BasePaginationResponse<RemarkListResponse>> => {
        const result = await fetchRemarksData(
            { pageIndex: data.page, pageSize: data.size },
            { status: ValidationStatus.In_Progress },
            data.sort,
        );

        const response = new BasePaginationResponse(RemarkListResponse);
        response.Total = result.pageCount;
        response.Content = result.rows;
        return response;
    };

    RemarkRead = async (data: BaseIdRequest): Promise<RemarkListResponse | undefined> => {
        return await fetchRemark(data.uuid, {
            status: [ValidationStatus.Approved, ValidationStatus.Fixed, ValidationStatus.Declined],
        });
    };

    HistoryList = async (data: BasePaginationRequest): Promise<BasePaginationResponse<HistoryListResponse>> => {
        const result = await fetchHistoryData({ pageIndex: data.page, pageSize: data.size, sort: data.sort });

        const response = new BasePaginationResponse(HistoryListResponse);
        response.Total = result.pageCount;
        response.Content = result.rows;

        return response;
    };

    ReviewList = async (data: BasePaginationRequest): Promise<BasePaginationResponse<ReviewListResponse>> => {
        const result = await fetchReviewsData({ pageIndex: data.page, pageSize: data.size, sort: data.sort });

        const response = new BasePaginationResponse(ReviewListResponse);
        response.Total = result.pageCount;
        response.Content = result.rows;
        return response;
    };

    CommentList = async (data: BasePaginationRequest): Promise<BasePaginationResponse<CommentListResponse>> => {
        const result = await fetchCommentsData({ pageIndex: data.page, pageSize: data.size, sort: data.sort });

        const response = new BasePaginationResponse(CommentListResponse);
        response.Total = result.pageCount;
        response.Content = result.rows;
        return response;
    };

    // TODO: доработать на действительный эндпоинт принятия текста отзыва с BE
    /* AcceptReviewText = async (reviewsIds: string[]): Promise<string> => {
        return await this.Post("", "/users/login/validation", { reviewsIds } as BaseRequest);
    }; */

    // TODO: доработать на действительный эндпоинт удаления текста отзыва с BE
    /* DeclineReviewText = async (reviewsIds: string[]): Promise<string> => {
        return await this.Post("", "/users/login/validation", { reviewsIds } as BaseRequest);
    }; */
}
