import { BaseArrayResponse } from "Api/BaseResponse";
import { CourseListResponse } from "Api/Responses/CourseResponse";
import { MaterialListResponse } from "Api/Responses/MaterialResponse";
import { ProgramCategoryResponse, ProgramListResponse } from "Api/Responses/ProgramResponse";
import { TaskListResponse } from "Api/Responses/TaskResponse";
import { TestListResponse } from "Api/Responses/TestResponse";
import { ResourceType } from "Enums";

export interface IStatisticValue {
    label: string;
    value: string;
    name: string;
    id: string;
    questionsCount?: string;
}

//формирование дерева тестов
export const getTestTreeNode = (
    { id, logoId, title, state, questionsCount }: TestListResponse,
    checked = false,
    selected = false,
) => {
    return {
        id: id,
        logoId: logoId,
        type: ResourceType.QUIZ,
        name: title,
        status: state,
        nodeType: "SECTION",
        children: null,
        state: {
            checked,
            selected,
        },
        questionsCount,
    };
};

export const structurizeTestTreeData = (
    treeDataItems: TestListResponse[],
    checkedNodes: { id: string; checked: boolean }[] = [],
) => {
    const treeData: any[] = [];
    try {
        treeDataItems.forEach((p) => {
            let categoryIndex = treeData.findIndex((p1) => p1.id === p.category.id);

            if (categoryIndex === -1) {
                treeData.push({
                    id: p.category.id,
                    nodeType: "PROJECT",
                    name: p.category.title,
                    children: [],
                    state: {
                        open: false,
                        checked: false,
                    },
                });

                categoryIndex = treeData.length - 1;
            }

            treeData[categoryIndex].children.push(
                getTestTreeNode(
                    p,
                    checkedNodes.find(({ id }) => id === p.id)?.checked,
                    !!checkedNodes.find(({ id }) => id === p.id),
                ),
            );
        });
    } catch (e) {
        /**/
    }

    if (treeData.length !== 0) {
        treeData[0].state.open = true;
    }

    return treeData;
};

//формирование дерева курсов
export const getCourseTreeNode = (
    { id, logoId, title, state }: CourseListResponse,
    checked = false,
    selected = false,
) => {
    return {
        id: id,
        logoId: logoId,
        type: ResourceType.COURSE,
        name: title,
        status: state,
        nodeType: "SECTION",
        children: null,
        state: {
            checked,
            selected,
        },
    };
};

export const structurizeCourseTreeData = (
    treeDataItems: CourseListResponse[],
    checkedNodes: { id: string; checked: boolean }[] = [],
) => {
    const treeData: any[] = [];
    try {
        treeDataItems.forEach((p) => {
            let categoryIndex = treeData.findIndex((p1) => p1.id === p.category.id);

            if (categoryIndex === -1) {
                treeData.push({
                    id: p.category.id,
                    nodeType: "PROJECT",
                    name: p.category.title,
                    children: [],
                    state: {
                        open: false,
                        checked: false,
                    },
                });

                categoryIndex = treeData.length - 1;
            }

            treeData[categoryIndex].children.push(
                getCourseTreeNode(
                    p,
                    checkedNodes.find(({ id }) => id === p.id)?.checked,
                    !!checkedNodes.find(({ id }) => id === p.id),
                ),
            );
        });
    } catch (e) {
        /**/
    }

    if (treeData.length !== 0) {
        treeData[0].state.open = true;
    }

    return treeData;
};

//формирование дерева заданий
export const getTaskTreeNode = ({ id, logoId, title, state }: TaskListResponse, checked = false) => {
    return {
        id: id,
        logoId: logoId,
        type: ResourceType.EXERCISE,
        name: title,
        status: state,
        nodeType: "SECTION",
        children: null,
        state: {
            checked,
        },
    };
};

export const structurizeTaskTreeData = (
    treeDataItems: TaskListResponse[],
    checkedNodes: { id: string; checked: boolean }[] = [],
) => {
    const treeData: any[] = [];
    try {
        treeDataItems.forEach((p) => {
            let categoryIndex = treeData.findIndex((p1) => p1.id === p.category.id);

            if (categoryIndex === -1) {
                treeData.push({
                    id: p.category.id,
                    nodeType: "PROJECT",
                    name: p.category.title,
                    children: [],
                    state: {
                        open: false,
                        checked: false,
                    },
                });

                categoryIndex = treeData.length - 1;
            }

            treeData[categoryIndex].children.push(
                getTaskTreeNode(p, checkedNodes.find(({ id }) => id === p.id)?.checked /* ?? false */),
            );
        });
    } catch (e) {
        /**/
    }

    if (treeData.length !== 0) {
        treeData[0].state.open = true;
    }

    return treeData;
};

//формирование дерева программ
export const getProgramTreeNode = (
    { id, logoId, title, state }: ProgramListResponse,
    checked = false,
    selected = false,
) => {
    return {
        id: id,
        logoId: logoId,
        type: ResourceType.PROGRAM,
        name: title,
        status: state,
        nodeType: "SECTION",
        children: null,
        state: {
            checked,
            selected,
        },
    };
};

export const structurizeProgramTreeData = (
    treeDataItems: ProgramListResponse[],
    checkedNodes: { id: string; checked: boolean }[] = [],
    categories?: BaseArrayResponse<ProgramCategoryResponse>,
) => {
    const treeData: any[] = [];
    try {
        treeDataItems.forEach((p) => {
            let categoryIndex = treeData.findIndex((p1) => p1.id === p.categoryId);
            const category = categories?.Content.find((category) => category.id === p.categoryId);
            const catgoryTitle = category ? category.title : p.categoryId;

            if (categoryIndex === -1) {
                treeData.push({
                    id: p.categoryId,
                    nodeType: "PROJECT",
                    name: catgoryTitle,
                    children: [],
                    state: {
                        open: false,
                        checked: false,
                    },
                });

                categoryIndex = treeData.length - 1;
            }

            treeData[categoryIndex].children.push(
                getProgramTreeNode(
                    p,
                    checkedNodes.find(({ id }) => id === p.id)?.checked,
                    !!checkedNodes.find(({ id }) => id === p.id),
                ),
            );
        });
    } catch (e) {
        /**/
    }

    if (treeData.length !== 0) {
        treeData[0].state.open = true;
    }

    return treeData;
};

//формирование дерева материалов
export const getMaterialTreeNode = (
    { id, logoId, title, type, state }: MaterialListResponse,
    checked = false,
    selected = false,
) => {
    return {
        id: id,
        logoId: logoId,
        type: type,
        name: title,
        status: state,
        nodeType: "SECTION",
        children: null,
        state: {
            checked,
            selected,
        },
    };
};

export const structurizeMaterialTreeData = (
    treeDataItems: MaterialListResponse[],
    checkedNodes: { id: string; checked: boolean }[] = [],
) => {
    const treeData: any[] = [];
    try {
        treeDataItems.forEach((p) => {
            let categoryIndex = treeData.findIndex((p1) => p1.id === p.category.id);

            if (categoryIndex === -1) {
                treeData.push({
                    id: p.category.id,
                    nodeType: "PROJECT",
                    name: p.category.title,
                    children: [],
                    state: {
                        open: false,
                        checked: false,
                    },
                });

                categoryIndex = treeData.length - 1;
            }

            treeData[categoryIndex].children.push(
                getMaterialTreeNode(
                    p,
                    checkedNodes.find(({ id }) => id === p.id)?.checked,
                    !!checkedNodes.find(({ id }) => id === p.id),
                ),
            );
        });
    } catch (e) {
        /**/
    }

    if (treeData.length !== 0) {
        treeData[0].state.open = true;
    }

    return treeData;
};
