import React, { useMemo, useState } from "react";
import { StatisticTestTeamsRequest } from "Api/Requests/StatisticRequest";
import { TVoidFunction } from "types";
import { Button, Table } from "Uikit";
import { Loader } from "Uikit/Loader/Loader";
import { ColumnDef, ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { useQuery } from "react-query";
import Api from "Api";
import { StatisticTestTeamsResponseData } from "Api/Responses/StatisticResponse";
import { MultiClumpTooltip } from "Components/MultiClumpTooltip/MultiClumpTooltip";

interface IReportTableProps {
    onClickLoadReportFile: TVoidFunction;
    queryPayload: StatisticTestTeamsRequest;
}

export const ReportTable = ({ onClickLoadReportFile, queryPayload }: IReportTableProps) => {
    const [sorting, setSorting] = useState<SortingState>([{ id: "teamName", desc: false }]);
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 15 });

    const [selectedRows, setSelectedRows] = useState<any>();
    const [rowSelection, setRowSelection] = useState({});

    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
    const [search, setSearch] = useState<string>("");

    console.log(selectedRows);

    const pagination = useMemo(() => ({ pageIndex, pageSize }), [pageIndex, pageSize]);
    const dataQuery = useQuery(
        ["reportTeamsTest", "collection", pagination, sorting, search, queryPayload],
        async () => {
            const payload: StatisticTestTeamsRequest = { ...queryPayload, searchQuery: search };
            return await Api.Statistic.CreateTestTeamsReport(payload, pageIndex, pageSize, sorting);
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );

    const columns = React.useMemo<ColumnDef<StatisticTestTeamsResponseData>[]>(
        () => [
            {
                header: "Команда",
                accessorKey: "teamName",
                className: "w-[40%]",
                footer: (props) => props.column.id,
                cell: ({
                    row: {
                        original: { team },
                    },
                }) => {
                    return (
                        <div>
                            <MultiClumpTooltip key={team.id} clamp={1} label={team.name} />
                        </div>
                    );
                },
            },
            {
                header: "Кол-во участников",
                accessorKey: "memberCount",
                enableResizing: true,
                size: 150,
                footer: (props) => props.column.id,
                cell: ({
                    row: {
                        original: { memberCount },
                    },
                }) => {
                    return <>{memberCount}</>;
                },
            },
            {
                header: "Пройдено",
                accessorKey: "passedCount",
                enableResizing: true,
                size: 100,
                footer: (props) => props.column.id,
                cell: ({
                    row: {
                        original: { passedCount },
                    },
                }) => {
                    return <>{passedCount}%</>;
                },
            },
            {
                header: "Провалено",
                accessorKey: "failedCount",
                enableResizing: true,
                size: 100,
                footer: (props) => props.column.id,
                cell: ({
                    row: {
                        original: { failedCount },
                    },
                }) => {
                    return <>{failedCount}%</>;
                },
            },
            {
                header: "Не начат",
                accessorKey: "notStartedCount",
                enableResizing: true,
                size: 100,
                footer: (props) => props.column.id,
                cell: ({
                    row: {
                        original: { notStartedCount },
                    },
                }) => {
                    return <>{notStartedCount}%</>;
                },
            },
        ],

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const controlButtons = (
        <div className="flex justify-end items-center col-start-1 row-start-1">
            <Button
                className="rounded-lg whitespace-nowrap font-medium w-full"
                size="medium"
                onClick={() => {
                    onClickLoadReportFile();
                }}
            >
                Выгрузить отчет в файл
            </Button>
        </div>
    );

    const isEmpty = dataQuery.data?.data.Content.length === 0 && search === "";

    return (
        <>
            {dataQuery.isFetching && !dataQuery.data?.data.Content && (
                <div className="w-full flex justify-center">
                    <Loader />
                </div>
            )}
            {dataQuery.data?.data.Content && (
                <div>
                    <Table
                        columns={columns}
                        controlButtons={controlButtons}
                        columnFilters={columnFilters}
                        pageCount={dataQuery.data.data.TotalPages}
                        pagination={pagination}
                        emptyTitle="Нет данных"
                        emptyMessage={
                            isEmpty
                                ? "По заданным параметрам данных для выгрузки нет"
                                : "По заданным параметрам результатов нет"
                        }
                        searchTitle="Поиск по команде"
                        data={dataQuery.data.data.Content}
                        isFetching={dataQuery.isFetching}
                        rowSelection={rowSelection}
                        onPaginationChange={setPagination}
                        onRowSelectionChange={setRowSelection}
                        onColumnFiltersChange={setColumnFilters}
                        setSelectedRows={setSelectedRows}
                        sorting={sorting}
                        onSortingChange={setSorting}
                        onSearch={setSearch}
                        searchRowClassName={`gap-6.5 !grid grid-cols-2 ${isEmpty && "!grid-cols-1"}`}
                        searchClassName={"!w-full"}
                        searchBlockClassName={`col-start-2 col-end-2 ${isEmpty && "hidden"}`}
                    />
                </div>
            )}
        </>
    );
};
