import React, { useEffect, useState } from "react";
import SelectComponents, { MultiValue, ActionMeta } from "react-select";
import { useQuery } from "react-query";

import Api from "Api";
import { MembersListResponse } from "Api/Responses/UserResponse";
import { ComboBox, OptionsDropdownIndicator, Button, Modal, SubmitButton } from "Uikit";

import { SingleUserContainer } from "../Group/SingleUserContainer";
import { ReportUserTable } from "./ReportUserTable";

interface IReportUserSelect {
    placeholder?: string;
    value?: MembersListResponse[] | MembersListResponse | null;
    components?: SelectComponents;
    onChange?: (newValue: MultiValue<MembersListResponse[]>, actionMeta: ActionMeta<MembersListResponse[]>) => void;
    onSubmit?: (users: MembersListResponse[]) => void;
    selectedUsersDefault: MembersListResponse[];
    className?: string;
    isMulti?: boolean;
    allPlaceholder?: boolean;
    onDataLoaded?: (users: MembersListResponse[]) => void;
    showStatusFilter?: boolean | undefined;
    isSingleSelect?: boolean;
    isDataLoaded?: boolean;
    dataFetchingFilter?: Record<string, string>;
}

export const ReportUserSelect = ({
    placeholder = "Выберите участника",
    value = null,
    onSubmit,
    onChange,
    components,
    selectedUsersDefault,
    className = "",
    allPlaceholder = false,
    isMulti = false,
    onDataLoaded: onUsersLoaded,
    showStatusFilter,
    isSingleSelect = true,
    isDataLoaded = false,
    dataFetchingFilter = {},
}: IReportUserSelect) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState<MembersListResponse[]>(selectedUsersDefault);

    // all active users request
    const { data, isFetching } = useQuery(
        ["activeUsers", "collection"],
        () => Api.User.GetMembersList(0, 2000, [{ id: "firstName,lastName", desc: false }], dataFetchingFilter),
        {
            keepPreviousData: true,
            enabled: !isDataLoaded,
        },
    );

    const onClose = () => {
        setShowModal(false);
    };

    const handleUsersChanged = (selected: MembersListResponse[]) => {
        setSelectedUsers(selected);
    };

    const handleSubmit = async () => {
        onSubmit?.(selectedUsers);
        onClose();
    };

    useEffect(() => {
        if (selectedUsersDefault.length) {
            setSelectedUsers(selectedUsersDefault);
        }
    }, [selectedUsersDefault]);

    useEffect(() => {
        if (data?.Content) {
            onUsersLoaded?.(data.Content);
        }
    }, [onUsersLoaded, data?.Content]);

    return (
        <>
            {/* инпут */}
            <ComboBox
                className={className}
                placeholder={placeholder}
                onChange={(options, action) => {
                    if (isMulti) {
                        onChange?.(
                            options as unknown as MultiValue<MembersListResponse[]>,
                            action as unknown as ActionMeta<MembersListResponse[]>,
                        );
                    }
                }}
                value={value}
                components={{
                    DropdownIndicator: OptionsDropdownIndicator({
                        onClick: (e) => {
                            if (!e.target.closest(".ui-combo-box__multi-value__remove")) {
                                setShowModal((prevIsOpen) => !prevIsOpen);
                            }
                        },
                    }),
                    ValueContainer: SingleUserContainer,
                    ...components,
                }}
                onMenuOpen={() => setShowModal((prevIsOpen) => !prevIsOpen)}
                isSearchable={false}
                isCreatable={false}
                isClearable={false}
                menuIsOpen={false}
                isModalOpen={showModal}
                allPlaceholder={allPlaceholder}
                isMulti={isMulti || undefined}
            />
            {/* модалка */}
            <Modal
                isOpen={showModal}
                onClose={onClose}
                title={`Выбор ${isMulti ? "участников" : "участника"}`}
                className="!w-142 !max-w-142 !overflow-visible"
            >
                <Modal.Body>
                    <ReportUserTable
                        onChange={handleUsersChanged}
                        selectedUsers={selectedUsers}
                        data={data?.Content ?? []}
                        isSingleSelect={isSingleSelect}
                        showStatusFilter={showStatusFilter}
                        isFetching={isFetching}
                    />
                </Modal.Body>

                <Modal.Footer>
                    {!isSingleSelect && (
                        <div className="grow self-center text-gray">Выбрано {selectedUsers.length}</div>
                    )}
                    <Button
                        key={1}
                        onClick={onClose}
                        variant="outline"
                        size={"medium"}
                        color={"secondary"}
                        className="border-[#EAEDF3]"
                    >
                        Отмена
                    </Button>
                    <SubmitButton key={2} onClick={handleSubmit} disabled={selectedUsers.length === 0}>
                        Выбрать
                    </SubmitButton>
                </Modal.Footer>
            </Modal>
        </>
    );
};
