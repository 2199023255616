import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import {
    Breadcrumbs,
    Button,
    flash,
    FormGroup,
    Icon,
    Icons,
    Input,
    Label,
    AvatarEditor,
    DateTimeSelector,
} from "Uikit";
import { TAvatarEditorType } from "Uikit/AvatarEditor/AvatarEditor";
import { FileUploadType, RoleName, UIErrorMessages } from "Enums";
import Api from "Api";
import { CompanyCreateRequest } from "Api/Requests/CompanyRequest";
import { useBlocker, useNavigate } from "react-router-dom";
import { Confirmation } from "Components/Confirmation/Confirmation";
import { useDialog } from "hooks/useDialog";
import { setIsAuth } from "slices/authSlice";
import { InfoCardList } from "./InfoCardList";
import { InfoCardLogoInput } from "./InfoCardLogoInput";
import { useQuery } from "react-query";
import { companyNamePattern } from "types/Company";
import { getFileSizeErrorMessage } from "helpers/file";

interface ICompany {
    name: string;
    contractNumber: string;
    maxUsers: string;
    licenseExpirationDate: number;
    logoSquareId?: string;
    logoRectangleId?: string;
    maxMemoryGb: string;
}

const licensesLimit = 100000;
const memoryLimit = 200000;

export const NewCompany = () => {
    const { data: user } = useQuery(["users", "current"], () => Api.User.GetCurrentUser());
    const [company, setCompany] = useState<ICompany>({
        logoSquareId: "",
        logoRectangleId: "",
        contractNumber: "",
        name: "",
        maxUsers: "1",
        licenseExpirationDate: Date.now() / 1000 + 31536000,
        maxMemoryGb: "0",
    });
    const [avatarEditor, setAvatarEditor] = useState<string | undefined>(undefined);
    const [avatarEditorType, setAvatarEditorType] = useState<TAvatarEditorType>("square");
    const [logoSquare, setLogoSquare] = useState<File | null>(null);
    const [logoRectangle, setLogoRectangle] = useState<File | null>(null);
    const [errors, setErrors] = useState<any>({});
    const navigate = useNavigate();
    const { dialogState, openDialog, closeDialog } = useDialog();
    const dispatch = useDispatch();

    useBlocker((params) => {
        if (params?.historyAction === "REPLACE") {
            return false;
        }

        handleCancel();
        return true;
    });

    const onValidate = async () => {
        const errors: Partial<ICompany> = {};
        const name = (company.name ?? "").trim();

        errors.name = name === "" ? "Поле обязательно для заполнения" : undefined;
        errors.contractNumber = company.contractNumber === "" ? "Поле обязательно для заполнения" : undefined;

        if (name.length > 256) {
            errors.name = "Невозможно ввести символов  больше разрешенной длины";
        }

        if (RegExp(companyNamePattern).exec(name)) {
            errors.name = "В поле нельзя ввести недопустимые символы";
        }

        const isUnique = await Api.CompanyApi.ValidateCompany({
            name: company.name,
            contractNumber: company.contractNumber,
        });
        if (!isUnique.validName) {
            errors.name = "Данное название уже используется";
        }
        if (!isUnique.validContractNumber) {
            errors.contractNumber = "Данный номер договора уже используется";
        }

        if (Object.keys(errors).filter((p) => errors[p as keyof typeof errors]).length !== 0) {
            setErrors(errors);
            return false;
        }

        setErrors({});
        return true;
    };

    const handleSubmit = async () => {
        const validation = await onValidate();
        if (!validation) {
            flash.error("Ошибка, не все поля формы заполнены правильно");
            return;
        }

        try {
            const parsedData: CompanyCreateRequest = {
                name: company.name,
                contractNumber: company.contractNumber,
                maxUsers: +company.maxUsers,
                licenseExpirationDate: company.licenseExpirationDate,
                maxMemoryGb: +company.maxMemoryGb,
            };
            const createdCompany = await Api.CompanyApi.Create(parsedData);

            localStorage.setItem("companyId", createdCompany.id);
            Api.User.setAdminCompany(createdCompany.id).then();

            try {
                if (logoSquare) {
                    const uploadFileResponse = await Api.File.UploadFile(
                        logoSquare,
                        undefined,
                        undefined,
                        false,
                        FileUploadType.COMPANY_LOGO,
                    );
                    company.logoSquareId = uploadFileResponse.id;
                }
            } catch {
                flash.error("Размер логотипа 1:1 слишком большой!");
                return;
            }

            try {
                if (logoRectangle) {
                    const uploadFileResponse = await Api.File.UploadFile(
                        logoRectangle,
                        undefined,
                        undefined,
                        false,
                        FileUploadType.COMPANY_LOGO,
                    );
                    company.logoRectangleId = uploadFileResponse.id;
                }
            } catch {
                flash.error("Размер логотипа 1:3 слишком большой!");
                return;
            }

            await Api.CompanyApi.Edit({
                ...createdCompany,
                logoSquareId: company.logoSquareId,
                logoRectangleId: company.logoRectangleId,
            });

            flash.success("Данные компании успешно сохранены");
            navigate(`/admin/settings/licences`, { replace: true });
        } catch {
            flash.error("Неизвестная ошибка при сохранении данных компании");
            return;
        }
    };

    const handleCancel = useCallback(() => {
        openDialog({
            title: "Завершите создание компании",
            content: "Пожалуйста, завершите создание компании или вернитесь к выбору компании",
            closeBtnText: "Вернуться к выбору",
            submitBtnText: "Завершить создание",
            submitBtnColor: "primary",
            onRequestClose: () => {
                navigate("/", { replace: true });

                dispatch(setIsAuth(false));
                closeDialog();
            },
            onRequestSubmit: () => {
                closeDialog();
            },
            onCloseModal: () => {
                closeDialog();
            },
        });
    }, [dispatch, navigate, openDialog, closeDialog]);

    const onAvatarChange = (event: any) => {
        const files = (event?.target as HTMLInputElement)?.files;

        if (!files || files.length === 0) {
            return;
        }

        if (files.length > 1) {
            flash.error("Выбрано более 1 файла");
            return;
        }

        if (!RegExp(/^image\/(jpg|jpeg|png)/).exec(files[0].type)) {
            flash.error(UIErrorMessages.FILE_EXTENSION_ERROR);
            return;
        }

        if (files[0].size > 2097152) {
            flash.error(getFileSizeErrorMessage("2 МБ"));
            return;
        }

        const reader = new FileReader();

        reader.readAsDataURL(files[0]);
        reader.onload = () => {
            setAvatarEditor(reader.result!.toString());
            event.target.value = "";
        };
    };

    const onChangeAvatar = (data: File | null, imgBase64?: string, type: TAvatarEditorType = "square") => {
        if (type === "square") {
            setLogoSquare(data);
            setCompany({ ...company, logoSquareId: data ? imgBase64 : undefined });
        } else {
            setLogoRectangle(data);
            setCompany({ ...company, logoRectangleId: data ? imgBase64 : undefined });
        }
    };

    const isRoot = user?.role === RoleName.ROOT;

    return (
        <>
            <div className="flex flex-col px-4 pb-4 w-full h-full sm:overflow-y-scroll custom-scrollbar">
                <Breadcrumbs className="mb-2.5" id="adminSettingsBreadcrumbsLicencesCreate">
                    <Breadcrumbs.Link title="Администратор" />
                    <Breadcrumbs.Link title="Настройки" />
                    <Breadcrumbs.Link title="Лицензии" url="/admin/settings/licences" />
                    <Breadcrumbs.Link title="Новая компания" />
                </Breadcrumbs>
                <div className="flex justify-between items-center mb-7.5">
                    <h1 className="text-[#262626]-600" id="adminNewCompanyTitle">
                        Новая компания
                    </h1>
                    <div className="flex items-center">
                        <Button
                            className="mr-4"
                            variant="outline"
                            color="secondary"
                            id="adminNewCompanyBtnCancel"
                            onClick={handleCancel}
                        >
                            Отменить
                        </Button>
                        <Button id="adminNewCompanyBtnOk" onClick={handleSubmit}>
                            Сохранить
                        </Button>
                    </div>
                </div>
                <AvatarEditor
                    isOpen={avatarEditor !== undefined}
                    type={avatarEditorType}
                    title="Загрузка логотипа компании"
                    img={avatarEditor ?? ""}
                    onDismiss={() => {
                        setAvatarEditor(undefined);
                        setAvatarEditorType("square");
                    }}
                    onSubmit={(img: string, blob: Blob) => {
                        onChangeAvatar(new File([blob], "avatar.png"), img, avatarEditorType);
                        setAvatarEditorType("square");
                    }}
                />
                <div className="flex flex-col gap-10 relative">
                    <InfoCardList title="Информация о компании">
                        {/* Логотип 1:1 */}
                        <InfoCardLogoInput
                            value={company.logoSquareId}
                            onClick={() => {
                                setAvatarEditorType("square");
                            }}
                            onAvatarChange={onAvatarChange}
                            onChangeAvatar={() => onChangeAvatar(null)}
                            label="Логотип 1:1"
                            tooltip="Загрузите изображение в формате .png, .jpg, .jpeg весом до 2 МБ, от 120x120 px"
                            isRoot={isRoot}
                        />
                        {/* Логотип 1:3 */}
                        <InfoCardLogoInput
                            value={company.logoRectangleId}
                            onClick={() => {
                                setAvatarEditorType("rectangle");
                            }}
                            onAvatarChange={onAvatarChange}
                            onChangeAvatar={() => onChangeAvatar(null, undefined, "rectangle")}
                            label="Логотип 1:3"
                            tooltip="Загрузите изображение в формате .png, .jpg, .jpeg весом до 2 МБ, от 120x360 px"
                            format="rectangle"
                            isRoot={isRoot}
                        />
                        <FormGroup className="w-full !mb-0">
                            <Label isRequired={true}>Номер договора</Label>
                            <Input
                                isRequired={true}
                                placeholder="Введите номер договора"
                                maxLength={64}
                                cutRegExp={new RegExp(/[^а-яА-ЯёЁa-zA-Z0-9-–—–"',.()«»!@#№$%^&*_ +=?;:/|\\~><{}[\]]/gi)}
                                value={company.contractNumber}
                                onChange={(e) => {
                                    setCompany({ ...company, contractNumber: e.target.value });
                                }}
                                error={errors["contractNumber"]}
                                id="adminSettingsNewCompanyInputAgreement"
                            />
                        </FormGroup>
                        <FormGroup className="w-full !mb-0">
                            <Label isRequired={true}>Название компании</Label>
                            <Input
                                isRequired={true}
                                placeholder="Введите название компании"
                                maxLength={256}
                                cutRegExp={companyNamePattern}
                                value={company.name}
                                onChange={(e) => {
                                    setCompany({ ...company, name: e.target.value });
                                }}
                                error={errors["name"]}
                                id="adminSettingsNewCompanyInputName"
                            />
                        </FormGroup>
                    </InfoCardList>
                    <InfoCardList title="Лицензии">
                        <FormGroup className="!mb-0">
                            <Label isRequired={true}>Количество</Label>
                            <Input
                                className="!w-[calc(8ch+80px)] 2xl:w-[calc(8ch+88px)] px-4.5 2xl:px-[44px] text-center"
                                cutRegExp={new RegExp(/\D/gi)}
                                value={company.maxUsers.replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                                onChange={(e) => {
                                    const val = Number(e.target.value.replace(/\s/g, ""));

                                    setCompany({
                                        ...company,
                                        maxUsers: val.toString(),
                                        maxMemoryGb: (val * 2).toString(),
                                    });
                                }}
                                onBlur={(e) => {
                                    const value = Number(e.target.value.replaceAll(" ", ""));
                                    const fieldValue = value > licensesLimit ? licensesLimit : value;
                                    setCompany({
                                        ...company,
                                        maxUsers: fieldValue.toString(),
                                        maxMemoryGb: (fieldValue * 2).toString(),
                                    });
                                }}
                                id="adminSettingsNewCompanyInputLimit"
                                before={
                                    <div
                                        className={`cursor-pointer ${
                                            company.maxUsers === "0" && " pointer-events-none cursor-default opacity-50"
                                        }`}
                                        onClick={() => {
                                            const newVal =
                                                company.maxUsers && +company.maxUsers > 1
                                                    ? (+company.maxUsers - 1).toString()
                                                    : "0";
                                            setCompany({
                                                ...company,
                                                maxUsers: newVal,
                                                maxMemoryGb: (+newVal * 2).toString(),
                                            });
                                        }}
                                        id="adminNewCompanyBtnDecrementLimit"
                                    >
                                        <Icon icon={Icons.Minus} width={20} height={20} color="fill-[#878E9C]" />
                                    </div>
                                }
                                after={
                                    <div
                                        className={`cursor-pointer ${
                                            company.maxUsers === `${licensesLimit}` &&
                                            " pointer-events-none cursor-default opacity-50"
                                        }`}
                                        onClick={() => {
                                            const newVal = company.maxUsers ? (+company.maxUsers + 1).toString() : "1";
                                            setCompany({
                                                ...company,
                                                maxUsers: newVal,
                                                maxMemoryGb: (+newVal * 2).toString(),
                                            });
                                        }}
                                        id="adminNewCompanyBtnIncrementLimit"
                                    >
                                        <Icon icon={Icons.Plus} width={20} height={20} color="fill-[#878E9C]" />
                                    </div>
                                }
                            />
                        </FormGroup>
                        <FormGroup className="!mb-0">
                            <Label isRequired={true}>Дата окончания</Label>
                            <DateTimeSelector
                                isPastTime={true}
                                dateTime={company.licenseExpirationDate}
                                onChange={(dateTime) => setCompany({ ...company, licenseExpirationDate: dateTime })}
                                error={errors["licenseExpirationDate"]}
                                className="w-28 2xl:w-[140px]"
                                showTimeInput={false}
                            />
                        </FormGroup>
                    </InfoCardList>
                    <InfoCardList title="Память">
                        <FormGroup className="!mb-0">
                            <Label isRequired={true}>Объем памяти, ГБ</Label>
                            <Input
                                className="!w-[calc(8ch+80px)] 2xl:w-[calc(8ch+88px)] px-4.5 2xl:px-[44px] text-center"
                                value={company.maxMemoryGb.replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                                cutRegExp={new RegExp(/\D/gi)}
                                onChange={(e) => {
                                    const val = Number(e.target.value.replace(/\s/g, ""));

                                    setCompany({
                                        ...company,
                                        maxUsers: Math.floor(val / 2).toString(),
                                        maxMemoryGb: val.toString(),
                                    });
                                }}
                                onBlur={(e) => {
                                    const value = Number(e.target.value.replaceAll(" ", ""));
                                    const fieldValue = value > memoryLimit ? memoryLimit : value;
                                    setCompany({
                                        ...company,
                                        maxUsers: Math.floor(fieldValue / 2).toString(),
                                        maxMemoryGb: fieldValue.toString(),
                                    });
                                }}
                                id="adminSettingsNewCompanyInputLimitGB"
                                before={
                                    <div
                                        className={`cursor-pointer ${
                                            company.maxMemoryGb === "0" &&
                                            " pointer-events-none cursor-default opacity-50"
                                        }`}
                                        onClick={() => {
                                            const value = Number(
                                                company.maxMemoryGb && +company.maxMemoryGb > 1
                                                    ? (+company.maxMemoryGb - 1).toString()
                                                    : "0",
                                            );
                                            const fieldValue = value > memoryLimit ? memoryLimit : value;

                                            setCompany({
                                                ...company,
                                                maxUsers: Math.floor(fieldValue / 2).toString(),
                                                maxMemoryGb: fieldValue.toString(),
                                            });
                                        }}
                                        id="adminNewCompanyBtnDecrementLimitGB"
                                    >
                                        <Icon icon={Icons.Minus} width={20} height={20} color="fill-[#878E9C]" />
                                    </div>
                                }
                                after={
                                    <div
                                        className={`cursor-pointer ${
                                            company.maxMemoryGb === String(memoryLimit) &&
                                            " pointer-events-none cursor-default opacity-50"
                                        }`}
                                        onClick={() => {
                                            const value = Number(
                                                company.maxMemoryGb ? (+company.maxMemoryGb + 1).toString() : "1",
                                            );
                                            const fieldValue = value < 0 ? 0 : value;

                                            setCompany({
                                                ...company,
                                                maxUsers: Math.floor(fieldValue / 2).toString(),
                                                maxMemoryGb: fieldValue.toString(),
                                            });
                                        }}
                                        id="adminNewCompanyBtnIncrementLimitGB"
                                    >
                                        <Icon icon={Icons.Plus} width={20} height={20} color="fill-[#878E9C]" />
                                    </div>
                                }
                            />
                        </FormGroup>
                    </InfoCardList>
                </div>
            </div>
            <Confirmation {...dialogState} />
        </>
    );
};
