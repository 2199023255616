import React, { useState, useEffect, useCallback, SyntheticEvent } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { getCookie } from "typescript-cookie";

import { UIErrorMessages, WssMessageType } from "Enums";
import { Breadcrumbs, flash, Icon, Icons, AvatarEditor, Empty } from "Uikit";
import Api from "Api";
import { InviteRegistrationRequest } from "Api/Requests/InviteRequest";
import { BadRequestResponse, ErrorCode } from "Api/BaseResponse";
import { ValidateEmail, ValidateLogin, ValidateName, ValidatePassword } from "helpers/Validator";
import { TVoidFunction } from "types";
import { userAvatarBgColors } from "utils/defaultAvatarColors";
import { IOption } from "types";
import NotificationConnect, { NotificationSubscribe } from "Api/Wss/Notifications";
import { WssMessage } from "types/WssMessage";

import { AuthorizationInviteFormPrivate as PrivateSettings } from "./AuthorizationInviteFormPrivate";
import { AuthorizationInviteFormAccount as AccountSettings } from "./AuthorizationInviteFormAccount";
import { AuthorizationInviteFormJob as JobSettings } from "./AuthorizationInviteFormJob";

export type TBlurEvent<T extends HTMLElement> = SyntheticEvent & {
    target: T;
};

export interface IAuthorizationInviteSettingsFormProps {
    registrationRequest: InviteRegistrationRequest;
    onChange: (request: React.SetStateAction<InviteRegistrationRequest>) => void;
    onSubmit: TVoidFunction;
    isValid: boolean;
    errors?: Record<string, string | undefined>;
}

interface IAuthorizationInviteFormProps {
    inviteId: string;
    setInviteId: (inviteId: string) => void;
    setIsLoading: (loading: boolean) => void;
}

const plugsData = {
    changed: {
        title: "Приглашение изменено",
        description: "Для продолжения регистрации обновите страницу.",
    },
    disabled: {
        title: "Приглашение неактивно",
        description: "Регистрация по этому приглашению приостановлена. Обратитесь к администратору.",
    },
    deleted: {
        title: "Приглашение удалено",
        description: "Администратор запретил регистрацию по этому приглашению. Обратитесь к администратору.",
    },
    notFound: {
        title: "Приглашение не найдено",
        description: "Проверьте правильность ссылки или обратитесь к администратору.",
    },
};

export const AuthorizationInviteForm = ({ inviteId, setInviteId, setIsLoading }: IAuthorizationInviteFormProps) => {
    const [inviteState, setInviteState] = useState<string | null>(null);
    const [jobOptions, setJobOptions] = useState<IOption[]>([]);
    const [officeOptions, setOfficeOptions] = useState<IOption[]>([]);
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [avatarEditor, setAvatarEditor] = useState<string | undefined>(undefined);
    const [avatar, setAvatar] = useState<File | null>(null);
    const [userName, setUserName] = useState<{ firstName: string; lastName: string } | null>(null);
    const [isPrivateDataValid, setIsPrivateDataValid] = useState(false);
    const [isAccountDataValid, setIsAccountDataValid] = useState(false);
    const [isJobsDataValid, setIsJobsDataValid] = useState(false);
    const navigate = useNavigate();
    // Для тестирования вёрстки заглушек:
    const inviteExistData = useQuery(["invite-exist"], async () => await Api.Invites.InviteExists(inviteId ?? ""), {
        keepPreviousData: false,
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (!inviteExistData.data && !inviteExistData.isFetching) {
            setInviteState("notFound");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inviteExistData.data]);

    const [registrationRequest, setRegistrationRequest] = useState<InviteRegistrationRequest>(
        Object.assign(new InviteRegistrationRequest(), {
            avatarId: "",
            firstName: "",
            lastName: "",
            login: "",
            password: "",
            passwordRepeat: "",
            defaultAvatarColor: userAvatarBgColors[Math.floor(Math.random() * userAvatarBgColors.length)],
            inviteId,
        }),
    );

    const [activeFormStep, setActiveFormStep] = useState(0);

    const [errors, setErrors] = useState<any>({});

    const onChangeAvatar = (data: File | null, imgBase64?: string) => {
        setAvatar(data);
        setRegistrationRequest({ ...registrationRequest, avatarId: imgBase64 });
    };

    const onSave = async (loginIndex: number) => {
        try {
            if (avatar) {
                const uploadFileResponse = await Api.Upload.InviteAvatar(avatar, undefined, false, undefined, inviteId);
                registrationRequest.avatarId = uploadFileResponse.id;
            }

            delete registrationRequest.passwordRepeat;

            await Api.Invites.Registration({
                ...registrationRequest,
                login: loginIndex ? registrationRequest.login + "_" + loginIndex : registrationRequest.login,
                email: registrationRequest.email === "" ? null : registrationRequest.email,
            });
            flash.success("Вы зарегистрированы!");
            setInviteId("");

            navigate(`/`);
        } catch (e) {
            let errorMessage: string = UIErrorMessages.UNKNOWN_ERROR;

            if (e instanceof BadRequestResponse) {
                const { errorCode } = e;
                errorMessage = "Не все поля формы заполнены правильно";
                if ([ErrorCode.CORRUPT_FILE_ERROR, ErrorCode.FILE_EXTENSION_ERROR].includes(errorCode)) {
                    errorMessage = UIErrorMessages.FILE_LOADING_ERROR;
                }
                if ([ErrorCode.EMAIL_EXISTS].includes(errorCode)) {
                    setErrors((errors: any) => ({
                        ...errors,
                        email: "Данный адрес электронной почты уже используется",
                    }));
                }
                if ([ErrorCode.PHONE_EXISTS].includes(errorCode)) {
                    setErrors((errors: any) => ({
                        ...errors,
                        phone: "Данный номер телефона уже используется",
                    }));
                }
                if ([ErrorCode.LOGIN_EXISTS].includes(errorCode)) {
                    setErrors((errors: any) => ({
                        ...errors,
                        phone: "Данный логин уже используется",
                    }));
                }
                if ([ErrorCode.INVITE_NOT_FOUND].includes(errorCode)) {
                    errorMessage = "Приглашение не найдено";
                }
                if ([ErrorCode.INVITE_NOT_ACTIVE].includes(errorCode)) {
                    errorMessage = "Приглашение не активно";
                }
                if ([ErrorCode.JOB_TITLE_NOT_FOUND].includes(errorCode)) {
                    setErrors((errors: any) => ({
                        ...errors,
                        jobTitleId: "Должность не найдена",
                    }));
                }
                if ([ErrorCode.OFFICE_NOT_FOUND].includes(errorCode)) {
                    setErrors((errors: any) => ({
                        ...errors,
                        officeId: "Офис не найден",
                    }));
                }
            }

            flash.error(errorMessage);
        }
    };

    const validatePrivate = useCallback(() => {
        const errorsMsgs: any = {};
        errorsMsgs["firstName"] = ValidateName(registrationRequest.firstName, true);
        errorsMsgs["lastName"] = ValidateName(registrationRequest.lastName, true);

        if (Object.keys(errorsMsgs).filter((p) => errorsMsgs[p]).length !== 0) {
            return false;
        }

        return true;
    }, [registrationRequest.firstName, registrationRequest.lastName]);

    const validateJobs = useCallback(() => {
        const errorsMsgs: any = {};
        errorsMsgs["jobTitleId"] = !registrationRequest.jobTitleId ? "Поле обязательно для заполнения" : undefined;

        if (Object.keys(errorsMsgs).filter((p) => errorsMsgs[p]).length !== 0) {
            return false;
        }

        return true;
    }, [registrationRequest.jobTitleId]);

    const onPhoneValidate = useCallback(async () => {
        try {
            await Api.Invites.PhoneValidation({
                phone: String(registrationRequest.phone),
            });
        } catch (e) {
            if (e instanceof BadRequestResponse) {
                const { errorCode } = e;

                if ([ErrorCode.PHONE_EXISTS].includes(errorCode)) {
                    setErrors((errors: any) => ({
                        ...errors,
                        phone: "Данный номер телефона уже используется",
                    }));
                }
            }
        }
    }, [registrationRequest.phone]);

    const validateAccount = useCallback(async () => {
        const errorsMsgs: any = {};

        errorsMsgs["login"] = ValidateLogin(registrationRequest.login);
        errorsMsgs["password"] = ValidatePassword(
            registrationRequest.password,
            /* !registrationRequest.inviteId */ true,
        );
        errorsMsgs["phone"] = (registrationRequest.phone as string)?.includes("_")
            ? "Поле заполнено некорректно"
            : undefined;
        if (registrationRequest.password !== registrationRequest.passwordRepeat) {
            errorsMsgs["password-repeat"] = "Пароли не совпадают";
        }

        if (!errorsMsgs["phone"] && !!registrationRequest.phone?.replace(/^[+7]+/g, "").replace(/[^\d]+/g, "").length) {
            await onPhoneValidate();
        }

        if (registrationRequest.email) {
            errorsMsgs["email"] = ValidateEmail(registrationRequest.email);
        }

        if (Object.keys(errorsMsgs).filter((p) => errorsMsgs[p]).length !== 0) {
            setErrors(errorsMsgs);
            return false;
        }

        setErrors({});
        return true;
    }, [
        // registrationRequest.inviteId,
        registrationRequest.login,
        registrationRequest.phone,
        registrationRequest.email,
        registrationRequest.password,
        registrationRequest.passwordRepeat,
        onPhoneValidate,
    ]);

    // Валидация
    useEffect(() => {
        const validateAsync = async () => {
            const valid = await validateAccount();
            setIsAccountDataValid(valid);
        };
        validateAsync();

        setIsPrivateDataValid(validatePrivate());

        setIsJobsDataValid(validateJobs());
    }, [registrationRequest, validateAccount, validatePrivate, validateJobs]);

    const onLoginValidate = async () => {
        const { login } = registrationRequest;
        if (login.length > 0) {
            const existLogin = await Api.Invites.LoginValidation({ login: login });
            setRegistrationRequest((prev) => {
                const newRequest = Object.assign({}, prev);

                if (prev.login !== login) {
                    return prev;
                }

                newRequest.login = existLogin;
                return newRequest;
            });
        }
    };

    const onEmailValidate = async () => {
        if (registrationRequest.email) {
            try {
                await Api.Invites.EmailValidation({ email: registrationRequest.email, inviteId });
            } catch (e) {
                if (e instanceof BadRequestResponse) {
                    const { errorCode } = e;

                    if ([ErrorCode.EMAIL_EXISTS].includes(errorCode)) {
                        setErrors((errors: any) => ({
                            ...errors,
                            email: "Данная электронная почта уже используется",
                        }));
                    }
                }
            }
        }
    };

    useEffect(() => {
        NotificationConnect({ isAuth: !!getCookie("USER_SESSION") });
        NotificationSubscribe(async (message: WssMessage) => {
            if (message.messageType === WssMessageType.INVITE_DEACTIVATED && message.body.inviteId === inviteId) {
                setInviteState("disabled");
            }
            if (message.messageType === WssMessageType.INVITE_DELETED && message.body.inviteId === inviteId) {
                setInviteState("deleted");
            }
            if (message.messageType === WssMessageType.INVITE_CHANGED && message.body.inviteId === inviteId) {
                setInviteState("changed");
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setIsLoading(inviteExistData.isFetching);
    }, [inviteExistData.isFetching, setIsLoading]);

    return (
        <>
            {inviteState ? (
                <>
                    <Empty
                        className="bg-white !p-8.5 max-w-142 rounded-lg fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-10"
                        topElement={
                            <div className="flex-center mb-4">
                                <div className="flex-center w-16 h-16 rounded-full bg-blue-10">
                                    <Icon icon={Icons.EmojiSad} width={"36px"} height={"36px"} color={"fill-primary"} />
                                </div>
                            </div>
                        }
                        title={plugsData[inviteState as keyof typeof plugsData].title}
                        description={plugsData[inviteState as keyof typeof plugsData].description}
                    />
                </>
            ) : (
                <div
                    className={`transition-opacity duration-300 opacity-0 ${
                        !inviteExistData.isFetching ? "opacity-100" : ""
                    }`}
                >
                    {!inviteExistData.isFetching && (
                        <>
                            <AvatarEditor
                                isOpen={avatarEditor !== undefined}
                                type="circle"
                                title="Загрузка аватара"
                                img={avatarEditor ?? ""}
                                onDismiss={() => setAvatarEditor(undefined)}
                                onSubmit={(img: string, blob: Blob) =>
                                    onChangeAvatar(new File([blob], "avatar.jpg"), img)
                                }
                            />
                            <div className="w-full sm:max-w-125 sm:bg-white sm:rounded-2xl md:mt-0 xl:p-0">
                                <div
                                    className={`sm:pt-10 sm:pb-7.5 sm:px-10 ${
                                        inviteExistData.isFetching
                                            ? "min-h-[600px] flex flex-column items-center justify-center"
                                            : ""
                                    }`}
                                >
                                    <h1
                                        className="mb-4 sm:mb-2.5 sm:text-[32px] text-black"
                                        id="adminAuthorizationInviteFormHeader"
                                    >
                                        Регистрация
                                    </h1>
                                    <p className="mb-8 sm:mb-5 p1 sm:p2 text-blue-drk sm:text-gray">
                                        Пожалуйста, введите ваши данные для регистрации в системе обучения
                                    </p>
                                    <Breadcrumbs
                                        className="mb-2.5"
                                        id="adminTasksBreadcrumbs"
                                        activeItemIndex={activeFormStep}
                                        isLastActive={true}
                                    >
                                        <span
                                            className={`text-blue-drk ${
                                                isAccountDataValid && activeFormStep !== 0 ? "cursor-pointer" : ""
                                            } ${0 === activeFormStep ? "!text-black !cursor-default" : ""}`}
                                            onClick={() => {
                                                if (isAccountDataValid) {
                                                    setActiveFormStep(0);
                                                }
                                            }}
                                        >
                                            Личные данные
                                        </span>
                                        <span
                                            className={`text-blue-drk ${
                                                isPrivateDataValid && activeFormStep !== 1 ? "cursor-pointer" : ""
                                            } ${1 === activeFormStep ? "!text-black !cursor-default" : ""}`}
                                            onClick={() => {
                                                if (isPrivateDataValid) {
                                                    setActiveFormStep(1);
                                                }
                                            }}
                                        >
                                            Данные аккаунта
                                        </span>
                                        <span
                                            className={`text-blue-drk ${
                                                isPrivateDataValid && isAccountDataValid && activeFormStep !== 2
                                                    ? "cursor-pointer"
                                                    : ""
                                            } ${2 === activeFormStep ? "!text-black !cursor-default" : ""}`}
                                            onClick={() => {
                                                if (isPrivateDataValid && isAccountDataValid) {
                                                    setActiveFormStep(2);
                                                }
                                            }}
                                        >
                                            Рабочие данные
                                        </span>
                                    </Breadcrumbs>
                                    <div className="mb-4 sm:mb-7.5 space-y-4 sm:space-y-6">
                                        {activeFormStep === 0 && (
                                            <PrivateSettings
                                                registrationRequest={registrationRequest}
                                                onChange={(request) => {
                                                    setRegistrationRequest(request);

                                                    setUserName({
                                                        firstName: (request as InviteRegistrationRequest).firstName,
                                                        lastName: (request as InviteRegistrationRequest).lastName,
                                                    });
                                                }}
                                                setAvatar={setAvatar}
                                                userName={userName}
                                                onSubmit={() => {
                                                    setActiveFormStep(1);
                                                }}
                                                isValid={isPrivateDataValid}
                                                onLoginValidate={onLoginValidate}
                                            />
                                        )}
                                        {activeFormStep === 1 && (
                                            <AccountSettings
                                                registrationRequest={registrationRequest}
                                                onChange={setRegistrationRequest}
                                                onSubmit={() => {
                                                    setActiveFormStep(2);
                                                }}
                                                isValid={isAccountDataValid}
                                                onLoginValidate={onLoginValidate}
                                                onPhoneValidate={onPhoneValidate}
                                                onEmailValidate={onEmailValidate}
                                                errors={errors}
                                                setIsValid={setIsAccountDataValid}
                                                setErrors={(newError: any) => {
                                                    setErrors({
                                                        ...errors,
                                                        ...newError,
                                                    });
                                                }}
                                            />
                                        )}
                                        {activeFormStep === 2 && (
                                            <JobSettings
                                                registrationRequest={registrationRequest}
                                                onChange={setRegistrationRequest}
                                                onSubmit={() => {
                                                    onSave(0);
                                                }}
                                                errors={errors}
                                                isValid={isJobsDataValid}
                                                inviteId={inviteId}
                                                jobOptions={jobOptions}
                                                setJobOptions={setJobOptions}
                                                officeOptions={officeOptions}
                                                setOfficeOptions={setOfficeOptions}
                                                isFetched={isFetched}
                                                setIsFetched={setIsFetched}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            )}
        </>
    );
};
