import { SortingState } from "@tanstack/react-table";

export interface LMSFilterArgs {
    filter?: { [id: string]: string };
    sort?: SortingState;
    page?: number;
    size?: number;
}

const CREATURE_ENABLED = true;

export const LMSFilterParams = ({ filter, sort, size, page }: LMSFilterArgs = {}, company = true) => {
    const params = new URLSearchParams();

    const companyId = localStorage.getItem("companyId");
    if (CREATURE_ENABLED && company && companyId) {
        params.append("companyId.equal", companyId);
    }

    if (filter) {
        for (const entry of Object.entries(filter)) {
            params.append(...entry);
        }
    }

    if (sort && sort.length > 0) {
        const separator = sort[0].id.includes("&") ? "&" : ",";
        sort[0].id.split(separator).forEach((v) => {
            params.append("sort", `${v},${sort[0].desc ? "desc" : "asc"}`);
        }, "");
    }

    if (Number.isFinite(size)) {
        params.append("size", size!.toString());
    }

    if (Number.isFinite(page)) {
        params.append("page", page!.toString());
    }

    return params;
};
