import React from "react";
import { UserAvatar } from "Uikit/UserAvatar/UserAvatar";
import { TScreenSize } from "types/Screen";

interface ICustomFormatUserOptionsLabelProps {
    label: string;
    value?: {
        avatarId: string;
        defaultAvatarColor: string;
        firstName: string;
        lastName: string;
    };
}

// Кастомный компонент для react-select
// Отображение выбранного значения асинхронном селекте с аватаркой/без аватарки
export const CustomFormatUserOptionsLabel =
    ({ size }: { size: TScreenSize }) =>
    ({ label, value }: ICustomFormatUserOptionsLabelProps): React.ReactElement => {
        return (
            <div className="flex gap-2">
                <UserAvatar
                    avatarId={value?.avatarId}
                    color={value?.defaultAvatarColor}
                    userInitials={`${value?.firstName?.slice(0, 1)}${value?.lastName?.slice(0, 1)}`}
                    size={size === "large" ? 30 : 20}
                />
                <div>{label}</div>
            </div>
        );
    };
