import React, { useEffect, useMemo } from "react";
import { useInfiniteQuery } from "react-query";
import { useDispatch } from "react-redux";
import { setIsBack, setIsFavorite, setIsHidden, setTitle } from "slices/headerSlice";
import Api from "Api";
import { Empty } from "Uikit/Page/Empty";
import { Icon, Icons } from "Uikit";
import { InfiniteList } from "Components/InfiniteList/InfiniteList";
import { CertificateCard } from "./CertificateCard";

export const PersonalCertificatesMobile = () => {
    const dispatch = useDispatch();

    const { data, isLoading, hasNextPage, fetchNextPage } = useInfiniteQuery(
        ["user-certificates", "collection"],
        async ({ pageParam = 0 }) => {
            return await Api.User.GetCertificatesList(pageParam, 16);
        },
        {
            getNextPageParam: (lastPage) => {
                return lastPage.Last ? undefined : lastPage.Page + 1;
            },
        },
    );

    useEffect(() => {
        dispatch(setIsHidden(false));
        dispatch(setIsBack(true));
        dispatch(setTitle("Сертификаты"));
        dispatch(setIsFavorite(false));
    }, [dispatch]);

    const certificatesList = useMemo(() => {
        const list = data?.pages.map((p) => p.Content).flat();

        return list || [];
    }, [data]);

    if (isLoading) {
        return null;
    }

    return (
        <div className="pt-3 px-3">
            {certificatesList.length === 0 && !isLoading && (
                <Empty
                    className="mt-25"
                    topElement={
                        <div className="flex items-center justify-center mb-4 w-16.5 h-16.5 bg-blue-10 rounded-full">
                            <Icon icon={Icons.EmojiSad} width={36} height={36} color="fill-primary" />
                        </div>
                    }
                    title="У вас нет сертификатов"
                    description="К сожалению у вас сейчас нет сертификатов. Завершите прохождение курса для получения."
                />
            )}
            {certificatesList.length > 0 && (
                <InfiniteList isLoading={false} onLoadMore={fetchNextPage} hasMore={hasNextPage}>
                    <div className="flex flex-col gap-5">
                        {certificatesList.map((certificate) => (
                            <CertificateCard key={certificate.id} certificate={certificate} />
                        ))}
                    </div>
                </InfiniteList>
            )}
        </div>
    );
};
