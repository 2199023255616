import React, { ChangeEvent, useEffect, useState } from "react";
import { Input } from "Uikit";
import { wholeNumberRegexp } from "Uikit/Forms/Input";

type TRangeInputValue = string | number;

interface IRangeFieldValue {
    minValue: TRangeInputValue;
    maxValue: TRangeInputValue;
}

interface IRangeFieldProps {
    minLimit?: TRangeInputValue;
    maxLimit?: TRangeInputValue;
    accessor: string;
    value: IRangeFieldValue;
    disabled?: boolean;
    onChange: (accessor: string, value: IRangeFieldValue) => void;
    asyncLoadLimitsFunction?: () => Promise<{ minLimit: number; maxLimit: number }>;
}

export const RangeField = ({
    minLimit: minLimitDefault = 0,
    maxLimit: maxLimitDefault = 0,
    accessor,
    value,
    onChange,
    asyncLoadLimitsFunction,
    disabled: disabledDefault = false,
}: IRangeFieldProps) => {
    const [range, setRange] = useState<IRangeFieldValue>(value || { minValue: "", maxValue: "" });
    const [disabled, setDisabled] = useState(asyncLoadLimitsFunction ? true : disabledDefault);
    const [isLimitsAsyncLoaded, setIsLimitsAsyncLoaded] = useState(false);
    const [minLimit, setMinLimit] = useState(minLimitDefault);
    const [maxLimit, setMaxLimit] = useState(maxLimitDefault);

    const onMinRangeChange = (e: ChangeEvent<HTMLInputElement>) => {
        const minRange = e.target.value;

        if (isNaN(Number(minRange))) {
            return;
        }

        if (minRange || value?.minValue) {
            onChange(accessor, { ...range, minValue: minRange });
            return;
        }

        setRange((prevRange) => ({ ...prevRange, minValue: minRange }));
    };

    const onMaxRangeChange = (e: ChangeEvent<HTMLInputElement>) => {
        const maxRange = e.target.value;

        if (isNaN(Number(maxRange))) {
            return;
        }

        if (maxRange || value?.maxValue) {
            onChange(accessor, { ...range, maxValue: maxRange });
            return;
        }

        setRange((prevRange) => ({ ...prevRange, maxValue: maxRange }));
    };

    const onMinRangeBlur = () => {
        if (value && !range.minValue && range?.minValue !== value?.minValue) {
            onChange(accessor, { ...range });
            return;
        }

        if (!range.minValue) {
            return;
        }

        const newRange = { ...range };

        const isMinValueGreaterThanMaxLimit = maxLimit && Number(range.minValue) > Number(maxLimit);
        const isMinValueLessThanMinLimit = /* minLimit &&  */ Number(range.minValue) < Number(minLimit);
        const isMinValueGreaterThanMaxValue =
            range.minValue && range.maxValue && Number(range.minValue) > Number(range.maxValue);

        if (isMinValueLessThanMinLimit || (!range.maxValue && isMinValueGreaterThanMaxLimit)) {
            newRange.minValue = minLimit || 0;
        } else if (isMinValueGreaterThanMaxValue) {
            newRange.minValue = range.maxValue;
        }

        onChange(accessor, newRange);
    };

    const onMaxRangeBlur = () => {
        if (value && !range.maxValue && range?.maxValue !== value?.maxValue) {
            onChange(accessor, { ...range });

            return;
        }

        if (!range.maxValue) {
            return;
        }

        const newRange = { ...range };

        const isMaxValueGreaterThanMaxLimit = maxLimit && Number(range.maxValue) > Number(maxLimit);
        const isMaxValueLessThanMinLimit = minLimit && Number(range.maxValue) < Number(minLimit);
        const isMaxValueLessThanMinValue =
            range.minValue && range.maxValue && Number(range.maxValue) < Number(range.minValue);

        if (isMaxValueGreaterThanMaxLimit || (!range.minValue && isMaxValueLessThanMinLimit)) {
            newRange.maxValue = maxLimit || "";
        } else if (isMaxValueLessThanMinValue) {
            newRange.maxValue = newRange.minValue;
        }

        onChange(accessor, newRange);
    };

    useEffect(() => {
        setRange(value || { minValue: "", maxValue: "" });
    }, [value]);

    useEffect(() => {
        if (asyncLoadLimitsFunction && !isLimitsAsyncLoaded) {
            setIsLimitsAsyncLoaded(true);
            const fetch = async () => {
                const { minLimit, maxLimit } = await asyncLoadLimitsFunction();
                setMinLimit(minLimit);
                setMaxLimit(maxLimit);
                setDisabled(false);
            };
            fetch();
        }
    }, [asyncLoadLimitsFunction, isLimitsAsyncLoaded]);

    return (
        <div className="flex mt-3 sm:mt-4 space-x-2 2xl:space-x-6">
            <Input
                before="от"
                placeholder={String(minLimit)}
                cutRegExp={wholeNumberRegexp}
                value={range.minValue}
                onChange={onMinRangeChange}
                onBlur={onMinRangeBlur}
                disabled={disabled}
                className="2xl:!px-12"
                type="number"
            />
            <Input
                before="до"
                placeholder={String(maxLimit)}
                cutRegExp={wholeNumberRegexp}
                value={range.maxValue}
                onChange={onMaxRangeChange}
                onBlur={onMaxRangeBlur}
                disabled={disabled}
                className="2xl:!px-12"
                type="number"
            />
        </div>
    );
};
