import React from "react";
import { Modal, Loader } from "Uikit";
import { Except } from "type-fest";

interface TeamActionLoadingModalProps {
    isOpen: boolean;
    operationType: string;
}

export const TeamActionLoadingModal = ({ isOpen, operationType }: TeamActionLoadingModalProps) => {
    return (
        <Modal isOpen={isOpen} title="" className="!max-w-142" hideModalHeader={true}>
            <ModalContent operationType={operationType} />
        </Modal>
    );
};

const ModalContent = ({ operationType }: Except<TeamActionLoadingModalProps, "isOpen">) => {
    const getOperationText = () => {
        if (operationType === "create") {
            return "Команда создается";
        } else if (operationType === "remove") {
            return "Команда удаляется";
        } else if (operationType === "edit") {
            return "Команда редактируется";
        } else {
            return "";
        }
    };

    return (
        <>
            <Modal.Body className="flex flex-col items-center !mb-0 !py-12 !px-8.5">
                <Loader />
                <h2 className="mt-4 mb-2 text-2xl font-semibold">{getOperationText()}</h2>
                <p className="text-blue-dark">Подождите, пожалуйста</p>
            </Modal.Body>
        </>
    );
};
