import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import SelectComponents from "react-select";

import { Button, ComboBox, FormGroup, Label } from "Uikit";
import { StatisticsAttempts } from "Enums";
import Api from "Api";
import { StatisticTestTeamsRequest } from "Api/Requests/StatisticRequest";
import { TeamTreeAllBasicInfoResponse } from "Api/Responses/TeamResponse";
import { TrainingMultiSelect } from "Components/Common/TrainingMultiSelect";
import { TeamMultiSelect } from "Components/Common/TeamMultiSelect";
import { IRequestSettingsItem, ReportSettings } from "Pages/Admin/Statistics/ReportSettings/ReportSettings";
import { numWord } from "helpers/numWord";
import { flattenTeams } from "models/Team";
import { ResourceType } from "Enums";

import { SingleValueContainer } from "../../SingleValueContainer";
import { IStatisticValue } from "../../utils";
import { attemptsOptions } from "../TestWithoutCourse";
import { ReportTable } from "./ReportTable";
import { reportSettings } from "./TestTeamsWithoutCourse";

export const TestTeamsWithinCourse = () => {
    const [coursesValues, setCoursesValues] = useState<IStatisticValue[]>([]);
    const [showReportTable, setShowReportTable] = useState(false);
    const [testsValues, setTestsValues] = useState<IStatisticValue[]>([]);
    const [selectedTeams, setSelectedTeams] = useState<string[]>([]);
    const [attempts, setAttempts] = useState(attemptsOptions[1]);
    const [showReportSettings, setShowReportSettings] = useState(false);

    //запрашиваем список всех команд
    const { data: teams = [] as TeamTreeAllBasicInfoResponse[] } = useQuery(
        ["teams", "tree", "basic-info", "collection"],
        async () => await Api.Teams.TreeAllBasicInfo([]),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            staleTime: 5 * 60 * 1000,
        },
    );

    const onSubmitCourseSection = (selectedItems: any[]) => {
        setCoursesValues(
            selectedItems.map(({ id, name, logoId }) => {
                return {
                    label: name,
                    value: id,
                    name,
                    id,
                    logoId,
                };
            }),
        );

        setShowReportTable(false);
    };

    const onSubmitTestSection = (selectedItems: any[]) => {
        setTestsValues(
            selectedItems.map(({ id, name, logoId }) => {
                return {
                    label: name,
                    value: id,
                    name,
                    id,
                    logoId,
                };
            }),
        );

        setShowReportTable(false);
    };

    const createReport = () => {
        setShowReportTable(true);
    };

    const createPayloadParams = () => {
        const payload: StatisticTestTeamsRequest = {
            courseIds: coursesValues.map(({ id }) => id),
            quizIds: testsValues.map(({ id }) => id),
            teamsIds: selectedTeams,
            attemptNumber: attempts.value,
        };

        return payload;
    };

    const onSubmit = async (settingsChecked: IRequestSettingsItem[]) => {
        const request: any = {};

        request.params = createPayloadParams();

        settingsChecked.forEach(({ key, items }: IRequestSettingsItem) => {
            request[key] = items
                .filter((item) => item.value !== "all")
                .reduce((prev, cur) => {
                    return {
                        ...prev,
                        [cur.value]: cur.checked,
                    };
                }, {});
        });

        setShowReportSettings(false);

        try {
            await Api.Statistic.GenerateTestTeamsStatisticReportFile(request);
        } catch (error: any) {
            console.log(error);
        }
    };

    //записываем id всеx команд в выбранные
    useEffect(() => {
        setSelectedTeams(flattenTeams(teams).map((team) => team.id));
    }, [teams]);

    useEffect(() => {
        setTestsValues([]);
        setSelectedTeams(flattenTeams(teams).map((team) => team.id));
        setAttempts(attemptsOptions[1]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coursesValues]);

    return (
        <>
            <div className="grid items-center gap-6.5 grid-cols-2">
                {/* Выбор курса */}
                <TrainingMultiSelect
                    className="!mt-0"
                    value={coursesValues[0] ?? null}
                    onSubmiTreeSection={onSubmitCourseSection}
                    placeholder="Выберите курс"
                    resourceType={ResourceType.COURSE}
                    components={
                        {
                            ValueContainer: SingleValueContainer,
                        } as unknown as SelectComponents
                    }
                    checkable={false}
                    selectable={true}
                    checkOnNameClick={true}
                    showSelectedCounter={false}
                    isMulti={false}
                />
                {/* Выбор теста */}
                {coursesValues.length > 0 && (
                    <TrainingMultiSelect
                        className="!mt-0"
                        value={testsValues[0] ?? null}
                        onSubmiTreeSection={onSubmitTestSection}
                        placeholder="Выберите тест"
                        resourceType={ResourceType.QUIZ}
                        components={
                            {
                                ValueContainer: SingleValueContainer,
                            } as unknown as SelectComponents
                        }
                        checkable={false}
                        selectable={true}
                        checkOnNameClick={true}
                        showSelectedCounter={false}
                        isMulti={false}
                        fetchDataFilters={{ "courseId.in": [coursesValues.map(({ id }) => id)] }}
                        fetchDataDependencies={coursesValues}
                    />
                )}
            </div>
            {testsValues.length > 0 && (
                <>
                    <div className="mt-7 flex-wrap relative z-[70]">
                        <div className="grid items-center gap-6.5 grid-cols-2">
                            <FormGroup className="!mb-0">
                                <Label>Команды</Label>
                                <TeamMultiSelect
                                    value={selectedTeams}
                                    onChange={(v) => {
                                        setSelectedTeams(v);
                                        setShowReportTable(false);
                                    }}
                                    placeholder="Выберите команды"
                                    checkable
                                    showTeams
                                    stacked={true}
                                    allPlaceholder={flattenTeams(teams).length === selectedTeams.length}
                                />
                            </FormGroup>
                            <FormGroup className="!mb-0">
                                <Label>Собрать статистику</Label>
                                <ComboBox
                                    onChange={(value) => {
                                        setAttempts(value as { value: StatisticsAttempts; label: string });
                                        setShowReportTable(false);
                                    }}
                                    value={attempts}
                                    options={attemptsOptions}
                                    isSearchable={false}
                                    isCreatable={false}
                                    isClearable={false}
                                />
                            </FormGroup>
                        </div>
                    </div>
                    <div className="mt-9">
                        {showReportTable ? (
                            <>
                                <ReportTable
                                    queryPayload={createPayloadParams()}
                                    onClickLoadReportFile={() => setShowReportSettings(true)}
                                />
                                <ReportSettings
                                    isActive={showReportSettings}
                                    setIsActive={setShowReportSettings}
                                    settings={reportSettings}
                                    onSubmit={onSubmit}
                                    isReportFileFetching={false}
                                />
                            </>
                        ) : (
                            <Button
                                key={1}
                                onClick={() => createReport()}
                                variant="standard"
                                size={"medium"}
                                color={"primary"}
                                className="w-full"
                                disabled={selectedTeams.length === 0}
                            >
                                {`Сформировать отчет по ${selectedTeams.length} ${numWord(selectedTeams.length, [
                                    "команде",
                                    "командам",
                                    "командам",
                                ])}`}
                            </Button>
                        )}
                    </div>
                </>
            )}
        </>
    );
};
