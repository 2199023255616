import BaseApi from "../BaseApi";
import { BaseIdRequest1 } from "../BaseRequest";
import { CompanyCreateRequest, CompanyEditRequest, CompanyValidateRequest } from "../Requests/CompanyRequest";
import { CompanyReadResponse, CompanyValidateResponse } from "../Responses/CompanyResponse";
import { BasePaginationResponse } from "Api/BaseResponse";

export default class CompanyApi extends BaseApi {
    Read = (data: BaseIdRequest1): Promise<CompanyReadResponse> => {
        return this.Get(new CompanyReadResponse(), `/company/${data.id}`);
    };

    Edit = async (data: CompanyEditRequest): Promise<any> => {
        return this.Put(CompanyReadResponse, "/company", data);
    };

    Create = async (data: CompanyCreateRequest): Promise<CompanyReadResponse> => {
        return await this.Post(CompanyReadResponse, "/company", data);
    };

    List = async (): Promise<BasePaginationResponse<CompanyReadResponse>> => {
        return await this.Get(
            new BasePaginationResponse(CompanyReadResponse),
            `/company?page=0&size=999&sort=name,asc`,
        );
    };

    ValidateCompany = async (data: CompanyValidateRequest): Promise<CompanyValidateResponse> => {
        return await this.Post(CompanyValidateResponse, "/company/validate", data);
    };

    resetLicense = (login: string) => {
        return this.Post(String, `/user/reset/license/${login}`);
    };

    // TODO: Переделать на эндпоинт отправки копирования сущностей в компанию
    // CopyEntities = (companyId: string, entitiesIds: string[], responsiblesId: string) => {
    //     return this.Post(String, `/user/reset/license/${login}`);
    // };
}
