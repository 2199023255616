import { Complexity } from "Enums";
import { BaseRequest, BaseIdRequest, BasePaginationRequest } from "../BaseRequest";

export class TaskListRequest extends BasePaginationRequest {
    // public "categoryId.equal": string | null;
    // public "categoryId.in": string | null;
}

export class TaskCreateRequest extends BaseRequest {
    public title: string;
    public description: string;
    public categoryId: string;
    public score: Complexity;
    public publicAccess: boolean | null;
    public passingLimit: number | null;
    public publicationTime: Date;
    public archivingTime: Date;
    public deadlineTime: Date;
    public accountableUserId: string;
    public logoId: string;
    public textContent: [];
    public numericContent: [];
    public imageContent: [];
    public fileContent: [];
    public singleChoiceContent: [];
    public multipleChoiceContent: [];
    public AppearDate: Date;
    public HideDate: Date;
    public DeadlineDate: Date;
}

export class TaskEditRequest extends BaseIdRequest {
    public title: string;
    public description: string;
    public categoryId: string;
    public score: Complexity;
    public publicAccess: boolean | null;
    public passingLimit: number | null;
    public publicationTime: Date;
    public archivingTime: Date;
    public deadlineTime: Date;
    public accountableUserId: string;
    public logoId: string;
    public textContent: [];
    public numericContent: [];
    public imageContent: [];
    public fileContent: [];
    public singleChoiceContent: [];
    public multipleChoiceContent: [];
    public AppearDate: Date;
    public HideDate: Date;
    public DeadlineDate: Date;
}

export class TaskPassRequestItem {
    public Id: number;
    public Content: string;
}

export class TaskPassRequest extends BaseIdRequest {
    public Items: TaskPassRequestItem[];
}

export class TaskUnArchiveRequest extends BaseRequest {
    public categoryId: string;
    public ids: string[];
}
