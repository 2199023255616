import React, { useState, useRef, ChangeEvent, useEffect } from "react";

import { UIErrorMessages } from "Enums";
import { Button, Input, flash, FormGroup, Label, Tooltip, AvatarEditor, UserAvatar } from "Uikit";
import { fioCutRegExps } from "Uikit/Forms/Input";
import { getFileSizeErrorMessage } from "helpers/file";
import { generateLogin } from "helpers/Login";
import { TVoidFunction } from "types";

import { IAuthorizationInviteSettingsFormProps, TBlurEvent } from "./AuthorizationInviteForm";

interface IAuthorizationInviteFormPrivateProps extends IAuthorizationInviteSettingsFormProps {
    setAvatar: (file: File | null) => void;
    userName: {
        firstName: string;
        lastName: string;
    } | null;
    onLoginValidate: TVoidFunction;
}

export const AuthorizationInviteFormPrivate = ({
    registrationRequest,
    onChange,
    setAvatar,
    userName,
    onSubmit,
    isValid,
    onLoginValidate,
}: IAuthorizationInviteFormPrivateProps) => {
    const inputRef = useRef(null);

    const [avatarEditor, setAvatarEditor] = useState<string | undefined>(undefined);
    const [userInitials, setUserInitials] = useState<string | undefined>(undefined);

    const checkUserInitials = () => {
        if (userName?.firstName || userName?.lastName) {
            setUserInitials(`${(userName?.firstName.slice(0, 1) ?? "") + (userName?.lastName.slice(0, 1) ?? "")}`);
        } else {
            setUserInitials("АБ");
        }
    };

    useEffect(() => {
        checkUserInitials();
    });

    const onChangeAvatar = (data: File | null, imgBase64?: string) => {
        setAvatar(data);
        onChange({ ...registrationRequest, avatarId: imgBase64 });
    };

    const onAvatarChange = (event: any) => {
        const files = (event?.target as HTMLInputElement)?.files;

        if (!files || files.length === 0) {
            return;
        }

        if (files.length > 1) {
            flash.error("Выбрано более 1 файла");
            return;
        }

        if (!RegExp(/^image\/(jpg|jpeg|png)/).exec(files[0].type)) {
            flash.error(UIErrorMessages.FILE_EXTENSION_ERROR);
            return;
        }

        if (files[0].size > 2097152) {
            flash.error(getFileSizeErrorMessage("2МБ"));
            return;
        }

        const reader = new FileReader();

        reader.readAsDataURL(files[0]);
        reader.onload = () => {
            setAvatarEditor(reader.result!.toString());
            event.target.value = "";
        };
    };

    const onFirstNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        const firstName = e.target.value.toLowerCase().replace(/(\s|^|-|')+\S/g, (x: any) => x.toUpperCase());

        onChange({
            ...registrationRequest,
            firstName,
            login:
                registrationRequest.firstName !== firstName
                    ? generateLogin(firstName, registrationRequest.lastName).toLowerCase()
                    : registrationRequest.login ?? "",
        });
    };

    const onLastNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        const lastName = e.target.value.toLowerCase().replace(/(\s|^|-|')+\S/g, (x) => x.toUpperCase());

        onChange({
            ...registrationRequest,
            lastName,
            login:
                registrationRequest.lastName !== lastName
                    ? generateLogin(registrationRequest.firstName, lastName).toLowerCase()
                    : registrationRequest.login ?? "",
        });
    };

    const handleFioBlur = (event: TBlurEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const newVal = event.target.value
            .toString()
            .replaceAll(/(^[\s`'—–-]+)/gm, "")
            .replaceAll(/([\s`'—–-]+$)/gm, "")
            .replaceAll("`", "'")
            .replaceAll(/[–—]/gm, "-");
        if (registrationRequest.firstName && registrationRequest.lastName) {
            onLoginValidate();
        }

        if (event.target.value.toString() !== newVal) {
            if ((registrationRequest as { [key: string]: any })[name] === newVal) {
                return;
            }

            onChange({ ...registrationRequest, [name]: newVal });
        }

        if (name === "middleName" && !newVal) {
            onChange({ ...registrationRequest, [name]: null });
        }
    };

    return (
        <>
            <AvatarEditor
                isOpen={avatarEditor !== undefined}
                type="circle"
                title="Загрузка аватара"
                img={avatarEditor ?? ""}
                onDismiss={() => setAvatarEditor(undefined)}
                onSubmit={(img: string, blob: Blob) => onChangeAvatar(new File([blob], "avatar.jpg"), img)}
            />
            <div className="group flex space-x-7.5 mb-5.5">
                <UserAvatar
                    avatarId={registrationRequest.avatarId}
                    color={registrationRequest.defaultAvatarColor as string}
                    userInitials={userInitials}
                    size={80}
                />
                <input
                    ref={inputRef}
                    id="logoInput"
                    className="hidden"
                    type="file"
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={onAvatarChange}
                />
                <div className="flex">
                    <Button
                        className="my-auto"
                        variant="outline"
                        color="secondary"
                        onClick={() => (inputRef.current as unknown as HTMLInputElement).click()}
                        id="adminNewMemberBtnLoadImg"
                    >
                        Загрузить
                    </Button>
                    <Button
                        variant="outline"
                        color="common"
                        className="border-[#EAEDF3] ml-4 my-auto font-normal"
                        onClick={() => onChangeAvatar(null)}
                        disabled={!registrationRequest.avatarId}
                        id="adminNewMemberBtnDeleteImg"
                    >
                        Удалить
                    </Button>
                </div>
            </div>
            <FormGroup>
                <Label isRequired={true}>Имя</Label>
                <Input
                    isRequired={true}
                    placeholder="Введите имя"
                    value={registrationRequest.firstName}
                    onChange={onFirstNameChange}
                    name="firstName"
                    maxLength={64}
                    cutRegExp={fioCutRegExps}
                    id="adminNewMemberInputName"
                    onBlur={handleFioBlur}
                />
            </FormGroup>
            <FormGroup>
                <Label isRequired={true}>Фамилия</Label>
                <Input
                    isRequired={true}
                    placeholder="Введите фамилию"
                    value={registrationRequest.lastName}
                    onChange={onLastNameChange}
                    maxLength={64}
                    name="lastName"
                    cutRegExp={fioCutRegExps}
                    id="adminNewMemberInputLastName"
                    onBlur={handleFioBlur}
                />
            </FormGroup>
            <FormGroup>
                <Label>Отчество</Label>
                <Input
                    placeholder="Введите отчество"
                    value={registrationRequest.middleName}
                    onChange={(e) => {
                        onChange({
                            ...registrationRequest,
                            middleName: e.target.value.toLowerCase().replace(/(\s|^|-|')+\S/g, (x) => x.toUpperCase()),
                        });
                    }}
                    maxLength={64}
                    name="middleName"
                    cutRegExp={fioCutRegExps}
                    id="adminNewMemberInputMiddleName"
                    onBlur={handleFioBlur}
                />
            </FormGroup>
            <Tooltip
                className="w-full max-w-[700px] flex rounded-md cursor-pointer"
                content={"Заполните обязательные поля"}
                disabled={isValid}
            >
                <Button
                    className="w-full h-12 sm:!mt-1 sm:!mb-0 sm:h-10"
                    onClick={() => {
                        onSubmit();
                    }}
                    disabled={!isValid}
                >
                    Далее
                </Button>
            </Tooltip>
        </>
    );
};
