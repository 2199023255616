import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { Breadcrumbs, Content, Tabs, TabsWrapper } from "Uikit";
import { Loader } from "Uikit/Loader/Loader";
import { useCurrentUser } from "hooks/useCurrentUser";
import { RoleName } from "Enums";

import { PositionsTab } from "./Tabs/PositionsTab";
import { OfficesTab } from "./Tabs/OfficesTab";
import { InvitesTab } from "./Tabs/InvitesTab";

export const SettingsMembers = () => {
    const [currentTab, setCurrentTab] = useState(0);
    const [isMounted, setIsMounted] = useState(false);
    const { data: user } = useCurrentUser();
    const isSuperAdmin = [RoleName.SUPER_ADMIN, RoleName.ROOT].includes(user?.role as RoleName);

    const [queryParams] = useSearchParams();

    const tabs = [
        {
            title: `Должности`,
            url: "jobs",
            content: <PositionsTab />,
        },
        {
            title: `Офисы`,
            url: "offices",
            content: <OfficesTab />,
        },
    ];

    if (isSuperAdmin) {
        tabs.push({
            title: `Ссылки для регистрации`,
            url: "invites",
            content: <InvitesTab />,
        });
    }

    const addTabUrl = (tabUrl: string) => {
        queryParams.delete("tab");
        queryParams.append("tab", tabUrl);
        window.history.pushState(null, "", `${location.pathname}?${queryParams.toString()}`);
        setCurrentTab(tabs.findIndex(({ url }) => url === tabUrl));
    };

    useEffect(() => {
        if (!queryParams.has("tab")) {
            addTabUrl(tabs[0].url);
        } else {
            const tab = queryParams.get("tab");
            addTabUrl(tabs[tabs.findIndex(({ url }) => url === tab)].url);
        }
        setIsMounted(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            className="flex flex-col w-full h-full px-4 pb-4 sm:overflow-y-scroll custom-scrollbar"
            id="settingsMembers"
        >
            <Breadcrumbs className="mb-2.5" id="adminSettingsBreadcrumbsMembers">
                <Breadcrumbs.Link title="Администратор" />
                <Breadcrumbs.Link title="Настройки" />
                <Breadcrumbs.Link title="Участники" url={"/admin/settings/members"} />
            </Breadcrumbs>

            {!isMounted ? (
                <div className="flex justify-center h-2/4 items-end">
                    <Loader />
                </div>
            ) : (
                <TabsWrapper selectedIndex={currentTab} className="mt-2 flex flex-col grow">
                    <div className="flex">
                        <TabsWrapper.Tabs classname="h-max space-x-4 flex-grow" id="adminSettingsTabsMembers">
                            {tabs.map(({ title, url }) => {
                                return (
                                    <Tabs.Tab
                                        key={title}
                                        title={title}
                                        onClick={() => {
                                            addTabUrl(url);
                                        }}
                                    />
                                );
                            })}
                        </TabsWrapper.Tabs>
                    </div>

                    <TabsWrapper.Content className="flex flex-col grow">
                        {tabs.map(({ title, content }) => {
                            return (
                                <Content.Body key={title} className="w-full h-full">
                                    {content}
                                </Content.Body>
                            );
                        })}
                    </TabsWrapper.Content>
                </TabsWrapper>
            )}
        </div>
    );
};
