import React, { ChangeEvent, useEffect } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useInvalidate } from "hooks/useInvalidate";
import { setIsBack, setIsFavorite, setIsHidden, setTitle } from "slices/headerSlice";
import { TabsWrapper, Tabs, Content, Icon, Icons, Input, Breadcrumbs } from "Uikit";
import Api from "Api";
import NotificationConnect, { NotificationSubscribe } from "Api/Wss/Notifications";
import { ResourceType, NotificationActionType } from "Enums";
import { TestsAll } from "./TestsAll";
import { TestsReview } from "./TestsReview";
import { TestsResults } from "./TestsResults";
import { WssMessage } from "types/WssMessage";

interface ISearch {
    value?: string;
    onChange?: (e: ChangeEvent<HTMLInputElement> | null) => void;
}
export const Search = ({ value = "", onChange }: ISearch) => (
    <div className="flex items-center gap-4">
        <div className="w-full flex justify-between relative grow min-w-[350px]">
            <span className="z-20 absolute inset-y-0 left-1 flex items-center pl-2">
                <button className="z-20 p-1 focus:outline-none focus:shadow-outline">
                    <Icon
                        icon={Icons.Search}
                        width={18}
                        height={18}
                        color="fill-gray-text"
                        className="2xl:!w-5.5 2xl:!h-5.5"
                    />
                </button>
            </span>
            <Input
                className={`z-10 block w-[350px] 2xl:w-105 border border-gray-stroke placeholder:text-gray-text pl-10 2xl:pl-11 ${
                    value ? " !pr-10 2xl:!pr-11" : ""
                }`}
                placeholder="Поиск..."
                value={value}
                onChange={onChange}
                id="userTestsSearchInput"
                maxLength={256}
            />
            {value && (
                <div
                    className="absolute top-1/2 -translate-y-1/2 right-3 w-5 h-5 flex-center cursor-pointer z-[10]"
                    onClick={() => {
                        onChange?.(null);
                    }}
                >
                    <Icon
                        icon={Icons.Close}
                        width={20}
                        height={20}
                        color="fill-gray-text"
                        className="2xl:!w-5.5 2xl:!h-5.5"
                    />
                </div>
            )}
        </div>
    </div>
);

interface ICounter {
    count: number;
}
const Counter = ({ count }: ICounter) => {
    if (!count) {
        return null;
    }

    return (
        <div className="absolute top-1 left-full flex-center py-0.5 px-1 min-w-3.5 2xl:min-w-4.5 h-3.5 2xl:h-4.5 bg-red rounded-full text-xxs text-white">
            {count}
        </div>
    );
};

export const Tests = () => {
    const dispatch = useDispatch();
    const invalidate = useInvalidate();

    const { data: testsCount, refetch: testsCountRefetch } = useQuery(["tests", "count"], () => Api.Test.UserCount());

    useEffect(() => {
        invalidate(["tests", "count"]);
    }, [invalidate]);

    useEffect(() => {
        dispatch(setIsHidden(false));
        dispatch(setIsBack(true));
        dispatch(setTitle("Тестирование"));
        dispatch(setIsFavorite(false));
    }, [dispatch]);

    // Подписка на выдачу допуска к тесту и обновление счётчика
    useEffect(() => {
        NotificationConnect();
        NotificationSubscribe(async (message: WssMessage) => {
            if (
                message.body.objectType === ResourceType.QUIZ &&
                message.body.actionType === NotificationActionType.ASSIGN
            ) {
                testsCountRefetch();
            }
        });
    }, [testsCountRefetch]);

    return (
        <div className="flex flex-wrap justify-center pt-3 sm:pt-0 px-3 sm:px-0 h-full">
            <TabsWrapper className="sm:pl-4 sm:pr-6.5 sm:max-w-[1216px] 2xl:max-w-[1506px] w-full h-full flex flex-col">
                <Breadcrumbs className="hidden sm:block w-full" id="userTestsBreadcrumbs">
                    <Breadcrumbs.Link title="Тестирование" />
                </Breadcrumbs>
                <TabsWrapper.Tabs classname="mb-5 2xl:mb-6.25" id="userTestsTabsBlock">
                    <Tabs.Tab
                        className="relative"
                        title="Все тесты"
                        additionalElement={<Counter count={testsCount ? testsCount.allTab : 0} />}
                    />
                    <Tabs.Tab title="На проверке" />
                    <Tabs.Tab
                        className="relative"
                        title="Результаты"
                        additionalElement={<Counter count={testsCount ? testsCount.resultsTab : 0} />}
                    />
                </TabsWrapper.Tabs>
                <TabsWrapper.Content>
                    <Content.Body className="h-full flex flex-col">
                        <TestsAll />
                    </Content.Body>
                    <Content.Body className="h-full flex flex-col">
                        <TestsReview />
                    </Content.Body>
                    <Content.Body className="h-full flex flex-col">
                        <TestsResults />
                    </Content.Body>
                </TabsWrapper.Content>
            </TabsWrapper>
        </div>
    );
};
