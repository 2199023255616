import "dayjs/locale/ru";
import dayjs, { Dayjs } from "dayjs";
import capitalize from "lodash/capitalize";
import isSameDay from "date-fns/isSameDay";
import differenceInHours from "date-fns/differenceInHours";
import differenceInDays from "date-fns/differenceInDays";
import format from "date-fns/format";
import { numCapEnd } from "helpers/numCapEnd";

export enum DateFormat {
    DATE_LONG = "D MMMM, YYYY",
    DATE_TIME_LONG = "D MMM, YYYY, HH:mm",
    TIME_MINUTE_SECONDS = "mm:ss",
    NOTIFICATION_DATE = "DD.MM.YYYY HH:mm",
    CERTIFICATE_DATE = "D MMM YYYY",
}

const capitalizeEach = (dateString: string) => {
    return dateString.split(" ").map(capitalize).join(" ");
};

const dateStringModifiers = {
    [DateFormat.DATE_LONG]: capitalizeEach,
    [DateFormat.DATE_TIME_LONG]: capitalizeEach,
    [DateFormat.TIME_MINUTE_SECONDS]: capitalizeEach,
    [DateFormat.NOTIFICATION_DATE]: capitalizeEach,
    [DateFormat.CERTIFICATE_DATE]: capitalizeEach,
};

const LOCALE = "ru";
const isApprovedDateFormat = (format: DateFormat) => Object.values(DateFormat).includes(format);
const noop = (dateString: string) => dateString;

export const formatDate = (date: string | number | Date | Dayjs, format: DateFormat = DateFormat.DATE_LONG) => {
    if (!isApprovedDateFormat(format)) {
        let message = `WARNING: The format "${format}" is not amongst the design approved formats\n  The approved formats are:\n`;
        for (const formatName of Object.keys(DateFormat)) {
            const formatString = DateFormat[formatName as keyof typeof DateFormat];
            message += `\tDateFormat.${formatName} = ${dayjs(date).locale(LOCALE).format(formatString)}\n`;
        }

        console.warn(message);
    }

    const modifier = dateStringModifiers[format] ?? noop;

    try {
        if (dayjs.isDayjs(date)) {
            return modifier(date.locale(LOCALE).format(format));
        }

        return modifier(dayjs(date, format).locale(LOCALE).format(format));
    } catch {
        console.log("Error formatting date", date);
    }

    return "";
};

export const durationHours = (x: Dayjs, y: Dayjs) => {
    return Math.abs(dayjs.duration(x.diff(y)).asHours());
};

export const formatLastActivityTime = (lastActivity: number) => {
    const today = Date.now();
    const diff = today - lastActivity;

    const years = Math.floor(diff / (1000 * 60 * 60 * 24 * 30 * 12));
    const months = Math.floor((diff / (1000 * 60 * 60 * 24 * 30)) % 12);
    const days = Math.floor((diff / (1000 * 60 * 60 * 24)) % 30);
    const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((diff / (1000 * 60)) % 60);

    if (years >= 1) {
        return `${years} ${numCapEnd({ one: "год", two: "года", few: "лет" }, years)} назад`;
    }
    if (months >= 1 && months < 12) {
        return `${months} ${numCapEnd({ one: "месяц", two: "месяца", few: "месяцев" }, months)} назад`;
    }
    if (days >= 1 && days < 30) {
        return `${days} ${numCapEnd({ one: "день", two: "дня", few: "дней" }, days)} назад`;
    }
    if (hours >= 1 && hours < 24) {
        return `${hours} ${numCapEnd({ one: "час", two: "часа", few: "часов" }, hours)} назад`;
    }
    if (minutes >= 1 && minutes < 60) {
        return `${minutes} ${numCapEnd({ one: "минуту", two: "минуты", few: "минут" }, minutes)} назад`;
    }
    return "1 минуту назад";
};

const today = new Date();
const localizeMonth = (dateFormatted: string, divider = "__") => {
    return dateFormatted
        .replace(`${divider}01`, " Янв.")
        .replace(`${divider}02`, " Февр.")
        .replace(`${divider}03`, " Март")
        .replace(`${divider}04`, " Апр.")
        .replace(`${divider}05`, " Мая")
        .replace(`${divider}06`, " Июнь")
        .replace(`${divider}07`, " Июль")
        .replace(`${divider}08`, " Авг.")
        .replace(`${divider}09`, " Сент.")
        .replace(`${divider}10`, " Окт.")
        .replace(`${divider}11`, " Нояб.")
        .replace(`${divider}12`, " Дек.");
};

// Форматирование времени изменения статуса
export const formatStatusChangeTime = (
    time: number | null,
    options: {
        showTime?: boolean;
        formatTonight?: boolean;
        showYear?: boolean;
        delimiter?: string;
    } = {
        showTime: false,
        formatTonight: true,
        showYear: false,
        delimiter: "",
    },
) => {
    const { showTime, formatTonight, showYear, delimiter = "" } = options;
    let timeFormatted = "";

    if (!time) {
        return timeFormatted;
    }

    const date = new Date(time * 1000);
    const hoursToDate = Math.abs(differenceInHours(date, today));
    const displayYear = showYear ?? today.getFullYear() !== date.getFullYear();

    if (isSameDay(today, date)) {
        const dateWord = "сегодня";
        let template = "";
        if (!formatTonight) {
            const yearTemplate = displayYear ? `${delimiter} yyyy` : "";
            template = `d__MM${yearTemplate}`;
        }
        if (showTime) {
            template += `${delimiter} kk:mm`;
        }

        const templateDelimiter = template ? delimiter : "";
        timeFormatted = `
            ${formatTonight ? `${dateWord}${templateDelimiter}` : ""}
            ${template ? localizeMonth(format(date, template)) : ""}
        `;
    } else {
        if ([0, 1].indexOf(differenceInDays(date, today)) !== -1) {
            const dateWord = "вчера";
            let template = "";
            if (!formatTonight) {
                const yearTemplate = displayYear ? `${delimiter} yyyy` : "";
                template = `d__MM${yearTemplate}`;
            }
            if (showTime) {
                template += template ? `${delimiter} kk:mm` : template;
            }

            const templateComma = template ? ", " : "";
            const dateFormat = formatTonight ? `${dateWord}${templateComma}` : "";

            timeFormatted = `${dateFormat}${template ? localizeMonth(format(date, template)) : ""}`;
        }

        if (hoursToDate > 24) {
            const yearTemplate = displayYear ? `${delimiter} yyyy` : "";
            let template = `d__MM${yearTemplate}`;

            if (showTime) {
                template += template ? `${delimiter} kk:mm` : template;
            }
            timeFormatted = localizeMonth(format(date, template));
        }
    }

    return timeFormatted.replace("..", "., ");
};

export const formatSendTime = (time: number | null, showTime = false, showYear = false) => {
    let timeFormatted = "";

    if (!time) {
        return timeFormatted;
    }

    const date = new Date(time);
    const hoursToDate = Math.abs(differenceInHours(date, today));

    if (isSameDay(today, date)) {
        if (showTime) {
            timeFormatted = `сегодня, ${date.toLocaleTimeString("ru-ru", {
                hour: "2-digit",
                minute: "2-digit",
            })}`;
        } else {
            timeFormatted = "сегодня";
        }
    } else {
        if ([0, 1].indexOf(differenceInDays(date, today)) !== -1) {
            if (showTime) {
                timeFormatted = `вчера, ${date.toLocaleTimeString("ru-ru", {
                    hour: "2-digit",
                    minute: "2-digit",
                })}`;
            } else {
                timeFormatted = "вчера";
            }
        }

        if (hoursToDate > 24) {
            const dateFormat: Intl.DateTimeFormatOptions = {
                month: "2-digit",
                day: "numeric",
                hour: showTime ? "numeric" : undefined,
                minute: showTime ? "numeric" : undefined,
                year: showYear ? "numeric" : undefined,
            };
            if (today.getFullYear() < date.getFullYear() || showYear) {
                dateFormat.year = "numeric";
            }
            // Янв., Февр., Март, Апр., Май, Июнь, Июль, Авг., Сент., Окт., Нояб., Дек.
            timeFormatted = date
                .toLocaleDateString("ru-ru", dateFormat)
                .replace(`.01${showYear ? "." : ""}`, ` Янв.${showYear ? ", " : ""}`)
                .replace(`.02${showYear ? "." : ""}`, ` Февр.${showYear ? ", " : ""}`)
                .replace(`.03${showYear ? "." : ""}`, ` Март${showYear ? ", " : ""}`)
                .replace(`.04${showYear ? "." : ""}`, ` Апр.${showYear ? ", " : ""}`)
                .replace(`.05${showYear ? "." : ""}`, ` Мая${showYear ? ", " : ""}`)
                .replace(`.06${showYear ? "." : ""}`, ` Июнь${showYear ? ", " : ""}`)
                .replace(`.07${showYear ? "." : ""}`, ` Июль${showYear ? ", " : ""}`)
                .replace(`.08${showYear ? "." : ""}`, ` Авг.${showYear ? ", " : ""}`)
                .replace(`.09${showYear ? "." : ""}`, ` Сент.${showYear ? ", " : ""}`)
                .replace(`.10${showYear ? "." : ""}`, ` Окт.${showYear ? ", " : ""}`)
                .replace(`.11${showYear ? "." : ""}`, ` Нояб.${showYear ? ", " : ""}`)
                .replace(`.12${showYear ? "." : ""}`, ` Дек.${showYear ? ", " : ""}`);
        }
    }

    return timeFormatted;
};

//формат ДД ммм ГГГГ, ЧЧ:ММ
export const formatDateTime = (ms: number) => {
    const monthAbbr = [
        "Янв.",
        "Февр.",
        "Марта",
        "Апр.",
        "Мая",
        "Июня",
        "Июля",
        "Авг.",
        "Сент.",
        "Окт.",
        "Нояб.",
        "Дек.",
    ];
    const date = new Date(ms);

    const year = date.getFullYear();
    const month = monthAbbr[date.getMonth()];
    const day = date.getDate();
    const hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    const minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();

    return `${day} ${month}, ${year}, ${hours}:${minutes}`;
};

export const daysBetween = (from: Dayjs = dayjs(), end: Dayjs = dayjs()) => {
    return from.isBefore(end) ? Math.round(end.diff(from, "days", true)) : 0;
};

export const daysLeftStr = (days: number) => {
    return `${days} ${numCapEnd({ one: "день", two: "дня", few: "дней" }, days)}`;
};

// Форматирование времени проверки (комментария проверяющего)
export const formatCheckTime = (
    time: number | null,
    options: {
        showTime?: boolean;
        delimiter?: string;
    } = {
        showTime: false,
        delimiter: "",
    },
) => {
    let timeFormatted = "";
    const { showTime, delimiter = "" } = options;

    if (!time) {
        return timeFormatted;
    }

    const date = new Date(time * 1000);
    const hoursToDate = Math.abs(differenceInHours(date, today));

    if (isSameDay(today, date)) {
        if (showTime) {
            timeFormatted = `Сегодня${delimiter} ${date.toLocaleTimeString("ru-ru", {
                hour: "2-digit",
                minute: "2-digit",
            })}`;
        } else {
            timeFormatted = "Сегодня";
        }
    } else {
        if ([0, 1].indexOf(differenceInDays(date, today)) !== -1) {
            if (showTime) {
                timeFormatted = `Вчера${delimiter} ${date.toLocaleTimeString("ru-ru", {
                    hour: "2-digit",
                    minute: "2-digit",
                })}`;
            } else {
                timeFormatted = "Вчера";
            }
        }

        if (hoursToDate > 24) {
            const dateFormat: Intl.DateTimeFormatOptions = {
                month: "2-digit",
                day: "numeric",
                hour: showTime ? "numeric" : undefined,
                minute: showTime ? "numeric" : undefined,
            };
            if (today.getFullYear() !== date.getFullYear()) {
                dateFormat.year = "numeric";
            }
            // Янв., Февр., Март, Апр., Май, Июнь, Июль, Авг., Сент., Окт., Нояб., Дек.
            timeFormatted = date
                .toLocaleDateString("ru-ru", dateFormat)
                .replace(".01", " Янв.")
                .replace(".02", " Февр.")
                .replace(".03", " Март")
                .replace(".04", " Апр.")
                .replace(".05", " Май")
                .replace(".06", " Июнь")
                .replace(".07", " Июль")
                .replace(".08", " Авг.")
                .replace(".09", " Сент.")
                .replace(".10", " Окт.")
                .replace(".11", " Нояб.")
                .replace(".12", " Дек.");
        }
    }

    return timeFormatted;
};
