import React, { useState, useCallback, useMemo } from "react";
import { ColumnDef, ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { useQuery } from "react-query";
// import { useLocation } from "react-router-dom";
import { useDialog } from "hooks/useDialog";
import { useInvalidate } from "hooks/useInvalidate";
import {
    Button,
    Checkbox,
    Icon,
    Icons,
    Tooltip,
    CustomFormatUserOptionsLabel,
    SelectMultiValueRemove,
    SelectValueContainer,
    CustomClearIndicator,
} from "Uikit";
import { Empty } from "Uikit/Page/Empty";
import { StackedValueContainer } from "Uikit/Forms/SelectCustomComponents/StackedValueContainer";
import { Table } from "Uikit/Table/Table";
import { StarRating } from "Uikit/Rating/StarRating";
import { UserAvatar } from "Uikit/UserAvatar/UserAvatar";
import { Confirmation } from "Components/Confirmation/Confirmation";
import { MultiClumpTooltip } from "Components/MultiClumpTooltip/MultiClumpTooltip";
import { Filter, IFilterRow } from "Components/Filter/Filter";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useScreenSize } from "hooks/useMediaQuery";
import Api from "Api";
import { BasePaginationRequest } from "Api/BaseRequest";
import { FeedbackListResponse } from "Api/Responses/FeedbackResponse";
import { RoleName, ResourceType } from "Enums";
import { AccountableUser, UserListResponse } from "Api/Responses/UserResponse";
import { useResponsibleList } from "Api/Hooks/useResponsibleList";

const sourceTypeOptions = [
    {
        label: "Все",
        value: "ALL",
    },
    {
        label: "Курс",
        value: ResourceType.COURSE,
    },
    {
        label: "Программа",
        value: ResourceType.PROGRAM,
    },
];

export const ReviewTab = () => {
    const [rowSelection, setRowSelection] = useState({});
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [filters, setFilters] = useState<{ [id: string]: any }>({
        sourceType: { label: "Все", value: "ALL" },
    });
    const currentUser = useCurrentUser();
    const { size } = useScreenSize();
    const invalidate = useInvalidate();

    const { dialogState, openDialog, closeDialog } = useDialog();

    const [sorting, setSorting] = useState<SortingState>([{ id: "publicationDate", desc: false }]);

    const [isFilterShow, setIsFilterShow] = useState(false);

    const isFiltersEmpty = useMemo(() => {
        if (Object.keys(filters).indexOf("searchQuery.contains") !== -1) {
            return false;
        }

        return Object.keys(filters).length === 1 && filters["sourceType"].value === "ALL";
    }, [filters]);

    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 15,
    });

    const pagination = useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize],
    );

    const onSearch = (searchQuery: string) => {
        setFilters((prevState) => ({ ...prevState, "searchQuery.contains": searchQuery }));
    };

    const dataQuery = useQuery(
        ["data", "reviews", pagination, sorting, filters],
        async () => {
            const request = new BasePaginationRequest();
            request.size = pageSize;
            request.page = pageIndex;
            request.sort = sorting.map((s: any) => `${s.id},${s.desc ? "desc" : "asc"}`).join(";") || "desc";

            const filtersKeys = Object.keys(filters);
            const filtersData: any = {};

            for (const element of filtersKeys) {
                // Дата отправки
                if (element === "publicationDate") {
                    filtersData[element + ".greaterThanOrEqual"] = filters[element]["date"]["startDate"].toISOString();
                    filtersData[element + ".lessThanOrEqual"] = filters[element]["date"]["endDate"].toISOString();
                    // Участники
                } else if (["userId.in", "managerUserId.in"].includes(element)) {
                    filtersData[element] = filters[element].map((p: any) => p.value.id).join(",");
                } else if (element === "resourceId.in") {
                    if (filters[element].length !== 0) {
                        filtersData[element] = filters[element].map((p: any) => p.value).join(",");
                    }
                } else {
                    filtersData[element] = filters[element];
                }
            }

            request.filters = filtersData;

            if (request.filters && request.filters.sourceType) {
                delete request.filters.sourceType;
            }

            return await Api.Feedback.FeedbackReviewList(request);
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );

    const reviews = dataQuery.data?.Content;

    // Оставить|удалить текст отзыва
    const onActionTextHandler = useCallback(
        async (action: "accept" | "decline", data: FeedbackListResponse | string[]) => {
            const isGotChecked = Array.isArray(data);
            const isMultiple = isGotChecked && data.length > 1;
            const isAccept = action === "accept";

            const ids: string[] = !isGotChecked ? [String(data.id)] : data;

            const onRequestSubmit = async (ids: string[], isAccept: boolean) => {
                await Api.Feedback.FeedbackCheck({
                    feedbackIds: ids,
                    isApproved: isAccept,
                });

                setRowSelection({});
                dataQuery.refetch();
                invalidate("review");
                closeDialog();
            };

            let text = "";

            if (!isGotChecked) {
                text = data.comment;
            }

            if (isGotChecked && data.length === 1) {
                text = reviews?.find((review) => review.id === ids[0])?.comment ?? "";
            }

            openDialog({
                title: `${isAccept ? "Оставить" : "Удалить"} текст ${isMultiple ? "отзывов" : "отзыва"}`,
                description: isMultiple ? `Выбрано элементов: ${data.length}` : `${text ? `«${text}»` : ""}`,
                content: `Вы уверены, что хотите ${isAccept ? "оставить" : "удалить"} текст ${
                    isMultiple ? "выбранных отзывов" : "выбранного отзыва"
                }?`,
                closeBtnText: "Отмена",
                submitBtnText: isAccept ? "Оставить" : "Удалить",
                submitBtnColor: isAccept ? "primary" : "danger",
                onRequestClose: () => closeDialog(),
                onRequestSubmit: () => {
                    onRequestSubmit(ids, isAccept);
                },
            });
        },
        [closeDialog, openDialog, dataQuery, invalidate, reviews],
    );

    const columns = useMemo<ColumnDef<FeedbackListResponse>[]>(
        () => [
            {
                id: "select",
                enableResizing: true,
                size: 16,
                header: ({ table }) => (
                    <Checkbox
                        checked={table.getIsAllRowsSelected()}
                        indeterminate={table.getIsSomeRowsSelected()}
                        onChange={table.getToggleAllRowsSelectedHandler()}
                    />
                ),
                cell: ({ row }) => (
                    <Checkbox
                        checked={row.getIsSelected()}
                        indeterminate={row.getIsSomeSelected()}
                        onChange={row.getToggleSelectedHandler()}
                    />
                ),
            },
            {
                header: "участник",
                cell: ({
                    row: {
                        original: { user },
                    },
                }) => {
                    return (
                        <div className="group flex items-center space-x-3 w-52">
                            <UserAvatar
                                avatarId={user.avatarId}
                                color={user.defaultAvatarColor}
                                userInitials={`${user.firstName?.slice(0, 1)}${user.lastName?.slice(0, 1)}`}
                                size={36}
                            />
                            <span className="whitespace-nowrap group-hover:text-blue">
                                {user.firstName + " " + user.lastName}
                            </span>
                        </div>
                    );
                },
                // accessorKey: "user",
                accessorKey: "user.firstName,user.lastName",
                footer: (props) => props.column.id,
            },
            {
                header: "текст",
                enableResizing: true,
                size: 850,
                footer: (props) => props.column.id,
                cell: ({
                    row: {
                        original: { comment },
                    },
                }) => {
                    return (
                        <MultiClumpTooltip
                            clamp={2}
                            label={comment}
                            textClassName="!line-clamp-2 break-anywhere"
                            className="!break-normal"
                        />
                    );
                },
                accessorKey: "comment",
            },
            {
                header: "действия",
                enableResizing: true,
                size: 90,
                cell: ({ row: { original } }) => {
                    return (
                        <div className="flex" id={"adminReviewTabTableGroupButton" + original.id}>
                            <Tooltip
                                className="w-full max-w-[700px] flex rounded-md cursor-pointer"
                                content={<span>Оставить текст</span>}
                            >
                                <Button
                                    shape={"round"}
                                    color={"common"}
                                    icon={
                                        <Icon
                                            icon={Icons.Check}
                                            width="18px"
                                            height="18px"
                                            color="fill-blue-drk hover:fill-blue-hover"
                                        />
                                    }
                                    iconPlacement={"center"}
                                    onClick={() => {
                                        onActionTextHandler("accept", original);
                                    }}
                                />
                            </Tooltip>
                            <Tooltip
                                className="w-full max-w-[700px] flex rounded-md cursor-pointer"
                                content={<span>Удалить текст</span>}
                            >
                                <Button
                                    shape={"round"}
                                    color={"common"}
                                    icon={
                                        <Icon
                                            icon={Icons.Delete}
                                            width="18px"
                                            height="18px"
                                            color="fill-blue-drk hover:fill-red"
                                        />
                                    }
                                    iconPlacement={"center"}
                                    onClick={() => {
                                        onActionTextHandler("decline", original);
                                    }}
                                />
                            </Tooltip>
                        </div>
                    );
                },
                footer: (props) => props.column.id,
            },
            {
                header: "оценка",
                cell: ({
                    row: {
                        original: { rating },
                    },
                }) => {
                    return (
                        <div className="flex items-center w-32">
                            <StarRating value={rating} />
                        </div>
                    );
                },
                accessorKey: "rating",
                footer: (props) => props.column.id,
            },
            {
                header: "место",
                enableResizing: true,
                size: 250,
                cell: ({
                    row: {
                        original: { resourceId, resourceTitle },
                    },
                }) => {
                    return (
                        <div>
                            <MultiClumpTooltip
                                key={resourceId}
                                clamp={2}
                                label={resourceTitle ?? "—"}
                                textClassName="!line-clamp-2 break-anywhere"
                                className="!break-normal"
                            />
                        </div>
                    );
                },
                accessorKey: "resourceTitle",
                footer: (props) => props.column.id,
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const responsibleList = useResponsibleList();
    const loadResponsibleListOptions = useCallback(
        async (inputValue: string): Promise<any> => {
            if (inputValue === "") {
                return {
                    options: [],
                    hasMore: false,
                };
            }

            const selectOptions = responsibleList
                .filter((p) => p.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1)
                .map((p) => ({ label: p.label, value: p.payload }));

            return {
                options: selectOptions,
                hasMore: false,
            };
        },
        [responsibleList],
    );

    const loadActiveUserOptions = useCallback(
        async (inputValue: string, prevOptions: unknown, { page }: { page: number }): Promise<any> => {
            if (inputValue === "") {
                return {
                    options: [],
                    hasMore: false,
                };
            }

            const users = await Api.User.GetActiveListOptions(inputValue, page);
            const usersSelectOptions: {
                label: string;
                value: AccountableUser | UserListResponse | undefined | string;
            }[] = users.Content.map((p) => ({ label: p.lastName + " " + p.firstName, value: p }));

            return {
                options: usersSelectOptions,
                hasMore: users.TotalPages > page,
                additional: {
                    page: page + 1,
                },
            };
        },
        [],
    );

    const filtersConfig = useMemo(
        () => [
            {
                label: "Дата добавления",
                fields: [
                    {
                        accessor: "publicationDate",
                        type: "date-range",
                    },
                ],
            },
            {
                label: "Участники",
                fields: [
                    {
                        accessor: "userId.in",
                        type: "async-multi-select",
                        placeholder: "Выберите участника",
                        loadOptions: loadActiveUserOptions,
                        formatOptionLabel: CustomFormatUserOptionsLabel({ size }),
                        components: {
                            MultiValueRemove: SelectMultiValueRemove,
                            ValueContainer: StackedValueContainer,
                        },
                        lazy: true,
                    },
                ],
            },
            {
                label: "Ответственный",
                fields: [
                    {
                        accessor: "managerUserId.in",
                        type: "async-multi-select",
                        placeholder: "Выберите ответственного",
                        loadOptions: loadResponsibleListOptions,
                        formatOptionLabel: CustomFormatUserOptionsLabel({ size }),
                        components: {
                            MultiValueRemove: SelectMultiValueRemove,
                            ValueContainer: SelectValueContainer({}),
                        },
                        lazy: true,
                    },
                ],
            },
            {
                label: "Тип места",
                fields: [
                    {
                        accessor: "sourceType",
                        type: "select",
                        placeholder: "Тип места",
                        options: sourceTypeOptions,
                        components: {
                            ClearIndicator: CustomClearIndicator({}),
                        },
                    },
                ],
            },
            {
                label: "Программа",
                fields: [
                    {
                        accessor: "resourceId.in",
                        type: "training-multi-select",
                        components: {
                            MultiValueRemove: SelectMultiValueRemove,
                        },
                        componentResourceType: ResourceType.PROGRAM,
                        placeholder: "Выберите одну или несколько программ",
                    },
                ],
                condition: (filter: any) => {
                    return filter["sourceType"]?.value === ResourceType.PROGRAM;
                },
            },
            {
                label: "Курс",
                fields: [
                    {
                        accessor: "resourceId.in",
                        type: "training-multi-select",
                        components: {
                            MultiValueRemove: SelectMultiValueRemove,
                        },
                        componentResourceType: ResourceType.COURSE,
                        placeholder: "Выберите один или несколько курсов",
                    },
                ],
                condition: (filter: any) => {
                    return filter["sourceType"]?.value === ResourceType.COURSE;
                },
            },
        ],
        [loadResponsibleListOptions, loadActiveUserOptions, size],
    );

    const ReviewsControlButtons = (
        <div className="flex justify-end items-center space-x-4">
            <Button
                variant="outline"
                color="secondary"
                size="medium"
                className="border-[#E6E9ED] rounded-lg font-medium"
                icon={<Icon icon={Icons.Filter} width={20} height={20} color="stroke-blue" />}
                iconPlacement={"left"}
                onClick={() => setIsFilterShow(!isFilterShow)}
                id="adminValidationsReviewTabBtnFilter"
            >
                Фильтры
            </Button>
        </div>
    );

    const selectedRowButtons = (
        <div className="flex items-center space-x-4">
            <Button
                onClick={() => {
                    onActionTextHandler("accept", Object.keys(rowSelection));
                }}
                color="primary"
                size="medium"
                className="border-[#E6E9ED] rounded-lg font-medium"
                id="adminValidationsReviewTabBtnSelTextOk"
            >
                Оставить текст
            </Button>
            <Button
                onClick={() => {
                    onActionTextHandler("decline", Object.keys(rowSelection));
                }}
                color="danger"
                size="medium"
                className="border-[#E6E9ED] rounded-lg font-medium"
                id="adminValidationsReviewTabBtnRemoveSelText"
            >
                Удалить текст
            </Button>
        </div>
    );

    return (
        <div className="h-full flex flex-col grow" id="validationsReviewTab">
            {isFiltersEmpty && dataQuery.data?.Content && dataQuery.data?.Content.length === 0 && (
                <Empty
                    title="Все отзывы проверены!"
                    description="Любые отзывы, отправленные на проверку, отражаются на данной вкладке"
                    topElement={
                        <div className="flex-center mb-4">
                            <div className="flex-center w-16 h-16 rounded-full bg-blue-10">
                                <Icon icon={Icons.EmojiHappy} width={"36px"} height={"36px"} color={"fill-primary"} />
                            </div>
                        </div>
                    }
                ></Empty>
            )}

            {!isFiltersEmpty || (dataQuery.data?.Content && dataQuery.data?.Content.length > 0) ? (
                <>
                    <Table
                        columns={columns}
                        searchTitle="Поиск..."
                        data={reviews}
                        isFetching={dataQuery?.isFetching}
                        pagination={pagination}
                        onPaginationChange={setPagination}
                        pageCount={dataQuery.data?.TotalPages}
                        rowSelection={rowSelection}
                        onRowSelectionChange={setRowSelection}
                        onColumnFiltersChange={setColumnFilters}
                        columnFilters={columnFilters}
                        controlButtons={ReviewsControlButtons}
                        selectButtons={selectedRowButtons}
                        emptyMessage={"По заданным параметрам результатов нет"}
                        sorting={sorting}
                        onSortingChange={setSorting}
                        id="adminValidationsReviewTab"
                        onSearch={onSearch}
                        defaultSortOrder={{
                            "user_firstName,user.lastName": "asc",
                            comment: "asc",
                            rating: "asc",
                            resourceTitle: "asc",
                        }}
                    />

                    <Confirmation {...dialogState} />

                    <Filter
                        isActive={isFilterShow}
                        setIsActive={setIsFilterShow}
                        configuration={filtersConfig as IFilterRow[]}
                        filters={filters}
                        onChange={setFilters}
                        hideTeamMembers={currentUser?.data?.role === RoleName.ADMIN}
                    />
                </>
            ) : null}
        </div>
    );
};
