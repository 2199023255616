import React, { useCallback, useEffect, useRef } from "react";

import { ID } from "types/ID";
import { Loader } from "Uikit";
import { RatingInfoBlock } from "./RatingInfoBlock";
import { useGetUIMobileRating } from "./Rating.hooks";
import { IOption } from "types";

interface IRatingTableMobile {
    search?: string;
    teamId?: ID;
    period?: IOption;
}
export const RatingTableMobile = ({ search = "", teamId = "", period }: IRatingTableMobile) => {
    const observerElem = useRef<HTMLDivElement>(null);

    const {
        data: userRating,
        isLoading,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
    } = useGetUIMobileRating(search, teamId, period);

    const handleObserver = useCallback(
        (entries: any) => {
            const [target] = entries;

            if (target.isIntersecting && hasNextPage) {
                fetchNextPage().then();
            }
        },
        [fetchNextPage, hasNextPage],
    );
    useEffect(() => {
        const element = observerElem.current;
        const option = { threshold: 0 };

        const observer = new IntersectionObserver(handleObserver, option);

        if (element) {
            observer.observe(element);
            return () => observer.unobserve(element);
        }
    }, [fetchNextPage, hasNextPage, handleObserver]);

    if (!userRating && !isLoading) {
        return null;
    }

    return (
        <>
            {userRating?.pages.map((page, pageIndex) => {
                return (
                    <div key={pageIndex}>
                        {page.Content.map((item, index) => {
                            return (
                                <div key={index} className="mt-4">
                                    <RatingInfoBlock tableMode rating={item} />
                                </div>
                            );
                        })}
                    </div>
                );
            })}
            <div className="flex justify-center w-full" ref={observerElem}>
                {isFetchingNextPage && hasNextPage && (
                    <div className="py-4">
                        <Loader />
                    </div>
                )}
            </div>
        </>
    );
};
