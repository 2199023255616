import React, { useState } from "react";
import SelectComponents from "react-select";

import Api from "Api";
import { MembersListResponse } from "Api/Responses/UserResponse";
import { StatisticProgramRequest } from "Api/Requests/StatisticRequest";
import { Breadcrumbs, Button, ComboBox, FormGroup, Label, SelectAvatarMultiValueLabel } from "Uikit";
import { StackedValueContainer } from "Uikit/Forms/SelectCustomComponents/StackedValueContainer";
import { ProgressStatus, ResourceState, StatisticsReportAccess, StatisticsUsersStatus } from "Enums";
import { numWord } from "helpers/numWord";
import { TrainingMultiSelect } from "Components/Common/TrainingMultiSelect";

import { IRequestSettingsItem, ReportSettings } from "../../ReportSettings/ReportSettings";
import { ReportUserSelect } from "../../ReportUserSelect/ReportUserSelect";
import { IStatisticValue } from "../utils";
import { ReportTable } from "./ReportTable";

const reportSettings = [
    {
        title: "Участник",
        key: "user",
        items: [
            {
                title: "Все",
                value: "all",
                checked: true,
            },
            {
                title: "Фамилия, Имя участника",
                value: "name",
                checked: true,
            },
            {
                title: "Логин",
                value: "login",
                checked: true,
            },
            {
                title: "Электронная почта",
                value: "email",
                checked: true,
            },
            {
                title: "Должность",
                value: "job",
                checked: true,
            },
            {
                title: "Офис",
                value: "office",
                checked: true,
            },
            {
                title: "Статус участника",
                value: "status",
                checked: true,
            },
            {
                title: "Команда",
                value: "command",
                checked: true,
            },
            {
                title: "Путь к команде",
                value: "commandPath",
                checked: true,
            },
            {
                title: "Наставник",
                value: "mentor",
                checked: true,
            },
            {
                title: "Доступ на текущий момент",
                value: "access",
                checked: true,
            },
        ],
    },
    {
        title: "Программа",
        key: "program",
        items: [
            {
                title: "Все",
                value: "all",
                checked: true,
            },
            {
                title: "Название программы",
                value: "name",
                checked: true,
            },
            {
                title: "Дата начала",
                value: "startDate",
                checked: true,
            },
            {
                title: "Дата завершения",
                value: "endDate",
                checked: true,
            },
            {
                title: "Дедлайн",
                value: "deadlineDate",
                checked: true,
            },
            {
                title: "Дата последней активности участника",
                value: "activityDate",
                checked: true,
            },
            {
                title: "Набранные баллы",
                value: "rating",
                checked: true,
            },
            {
                title: "Максимальное количество баллов",
                value: "maxRating",
                checked: true,
            },
            {
                title: "Статус программы",
                value: "status",
                checked: true,
            },
            {
                title: "Категория программы",
                value: "category",
                checked: true,
            },
            {
                title: "Статус видимости программы",
                value: "visibility",
                checked: true,
            },
            {
                title: "Ориентировочное время",
                value: "requiredTime",
                checked: true,
            },
            {
                title: "Затрачено времени",
                value: "spendTime",
                checked: true,
            },
            {
                title: "Прогресс (%)",
                value: "progress",
                checked: true,
            },
        ],
    },
    {
        title: "Курс",
        key: "course",
        items: [
            {
                title: "Все",
                value: "all",
                checked: true,
            },
            {
                title: "Пройдено курсов",
                value: "completedCourses",
                checked: true,
            },
            {
                title: "Всего курсов",
                value: "totalCourses",
                checked: true,
            },
        ],
    },
];

// Доступ на текущий момент
const accessOptions = [
    {
        value: StatisticsReportAccess.ALL,
        label: "Любой",
    },
    {
        value: StatisticsReportAccess.NO_ACCESS,
        label: "Без доступа",
    },
    {
        value: StatisticsReportAccess.HAS_ACCESS,
        label: "С доступом",
    },
];

// Статус прохождения программы
const progressOptions: { value: ProgressStatus; label: string }[] = [
    {
        value: ProgressStatus.ALL,
        label: "Любой",
    },
    {
        value: ProgressStatus.PASSED,
        label: "Пройдена",
    },
    {
        value: ProgressStatus.IN_PROGRESS,
        label: "В процессе",
    },
    {
        value: ProgressStatus.NOT_STARTED,
        label: "Не начата",
    },
];

// Статус участников
const usersStatusOptions = [
    {
        value: StatisticsUsersStatus.ALL,
        label: "Любой",
    },
    {
        value: StatisticsUsersStatus.ACTIVE,
        label: "Активные",
    },
    {
        value: StatisticsUsersStatus.BLOCKED,
        label: "Заблокированные",
    },
];

export type TTaskFilterValue = ResourceState | "ALL";

export const Programs = () => {
    const [showReportTable, setShowReportTable] = useState(false);
    const [programsValues, setProgramValues] = useState<IStatisticValue[]>([]);
    const [users, setUsers] = useState<MembersListResponse[]>([]);
    const [allUsersCount, setAllUsersCount] = useState(0);
    const [allUsers, setAllUsers] = useState<MembersListResponse[]>([]);
    const [access, setAccess] = useState(accessOptions[0]);
    const [progress, setProgress] = useState<{ value: ProgressStatus; label: string }>(progressOptions[0]);
    const [usersStatus, setUsersStatus] = useState(usersStatusOptions[0]);
    const [showReportSettings, setShowReportSettings] = useState(false);
    const [isUsersLoaded, setIsUsersLoaded] = useState(false);

    const createReport = () => {
        setShowReportTable(true);
    };

    const createPayloadParams = () => {
        const payload: StatisticProgramRequest = {
            programIds: programsValues.map(({ id }) => id),
            userIds: users.map(({ id }) => id),
            teamsIds: Array.from(
                new Set(
                    users.reduce((prev, { teams }) => {
                        return [...prev, ...teams.map(({ id }) => id)];
                    }, [] as string[]),
                ),
            ),
            officeIds: users.map(({ officeId }) => officeId).filter((item) => !!item),
            jobIds: users.map(({ jobTitle }) => jobTitle?.id).filter((item) => !!item) as string[],
            access: access.value,
            status: progress.value,
            userStatus: usersStatus.value,
        };

        return payload;
    };

    const onSubmit = async (settingsChecked: IRequestSettingsItem[]) => {
        const request: any = {};

        request.params = createPayloadParams();

        settingsChecked.forEach(({ key, items }: IRequestSettingsItem) => {
            request[key] = items
                .filter((item) => item.value !== "all")
                .reduce((prev, cur) => {
                    return {
                        ...prev,
                        [cur.value]: cur.checked,
                    };
                }, {});
        });

        setShowReportSettings(false);

        try {
            await Api.Statistic.GenerateProgramStatisticReportFile(request);
        } catch (error: any) {
            console.log(error);
        }
    };

    const onSubmitSection = (selectedItems: any[]) => {
        setProgramValues(
            selectedItems.map(({ id, name, logoId, state }) => {
                return {
                    label: name,
                    value: id,
                    name,
                    id,
                    logoId,
                    state,
                };
            }),
        );

        if (selectedItems.length === 0) {
            setUsers(allUsers);
            setAccess(accessOptions[0]);
            setProgress(progressOptions[0]);
            setUsersStatus(usersStatusOptions[0]);
        }

        setShowReportTable(false);
    };

    return (
        <div className="px-6 w-full h-full sm:overflow-y-scroll custom-scrollbar">
            <Breadcrumbs className="mb-1">
                <Breadcrumbs.Link title="Администратор" />
                <Breadcrumbs.Link title="Статистика" />
                <Breadcrumbs.Link title="Программы" />
                <Breadcrumbs.Link title="Отчет по программам" />
            </Breadcrumbs>
            <div>
                <h1>Отчет по программам</h1>
                <div className="w-full h-full mt-5">
                    {/* Выбор программ */}
                    <TrainingMultiSelect
                        onChange={(options, action) => {
                            if (action && action.action === "clear") {
                                setProgramValues([]);
                            }
                            if (action && action.action === "remove-value") {
                                setProgramValues((prevItems) =>
                                    prevItems.filter(({ id }) => id !== action.removedValue.id),
                                );
                            }
                            setShowReportTable(false);
                        }}
                        value={programsValues}
                        onSubmiTreeSection={onSubmitSection}
                        placeholder="Выберите одну или несколько программ"
                    />
                    {programsValues.length > 0 && (
                        <>
                            <div className="mt-7 flex-wrap relative z-[70]">
                                <div className="grid items-center gap-6.5 grid-cols-2">
                                    {/* Выбор участников */}
                                    <FormGroup className="!mb-0">
                                        <Label>Участники</Label>
                                        <ReportUserSelect
                                            value={users}
                                            placeholder="Выберите одного или нескольких участников"
                                            onSubmit={(users) => {
                                                setUsers(
                                                    users.map((user) => {
                                                        const { firstName, lastName, avatarId, defaultAvatarColor } =
                                                            user;
                                                        return {
                                                            ...user,
                                                            value: {
                                                                avatarId,
                                                                firstName,
                                                                lastName,
                                                                defaultAvatarColor,
                                                            },
                                                            label: `${lastName} ${firstName}`,
                                                        };
                                                    }),
                                                );
                                                setShowReportTable(false);
                                            }}
                                            onChange={(options, action) => {
                                                if (action && action.action === "clear") {
                                                    setUsers([]);
                                                }
                                                if (action && action.action === "remove-value") {
                                                    setUsers(options as unknown as MembersListResponse[]);
                                                }
                                                setShowReportTable(false);
                                            }}
                                            components={
                                                {
                                                    ValueContainer: StackedValueContainer,
                                                    MultiValueLabel: SelectAvatarMultiValueLabel({}),
                                                } as unknown as SelectComponents
                                            }
                                            onDataLoaded={(users) => {
                                                if (!allUsers.length && !isUsersLoaded) {
                                                    setUsers(users);
                                                    setAllUsers(users);
                                                    setAllUsersCount(users.length);
                                                    setIsUsersLoaded(true);
                                                }
                                            }}
                                            isDataLoaded={isUsersLoaded}
                                            allPlaceholder={users.length > 0 && allUsersCount === users.length}
                                            selectedUsersDefault={users}
                                            showStatusFilter={true}
                                            isSingleSelect={false}
                                            isMulti={true}
                                        />
                                    </FormGroup>
                                    <FormGroup className="!mb-0">
                                        <Label>Доступ на текущий момент</Label>
                                        <ComboBox
                                            onChange={(value) => {
                                                setAccess(value as { value: StatisticsReportAccess; label: string });
                                                setShowReportTable(false);
                                            }}
                                            value={access}
                                            options={accessOptions}
                                            isOptionDisabled={(option) => {
                                                return (
                                                    [
                                                        StatisticsReportAccess.ALL,
                                                        StatisticsReportAccess.NO_ACCESS,
                                                    ].includes(option.value) &&
                                                    progress.value === ProgressStatus.NOT_STARTED
                                                );
                                            }}
                                            isSearchable={false}
                                            isCreatable={false}
                                            isClearable={false}
                                        />
                                    </FormGroup>
                                    <FormGroup className="!mb-0">
                                        <Label>Статус прохождения программы</Label>
                                        <ComboBox
                                            onChange={(value) => {
                                                setProgress(value as { value: ProgressStatus; label: string });
                                                setShowReportTable(false);
                                            }}
                                            value={progress}
                                            options={progressOptions}
                                            isOptionDisabled={(option) => {
                                                return (
                                                    [
                                                        StatisticsReportAccess.ALL,
                                                        StatisticsReportAccess.NO_ACCESS,
                                                    ].includes(access.value) &&
                                                    option.value === ProgressStatus.NOT_STARTED
                                                );
                                            }}
                                            isSearchable={false}
                                            isCreatable={false}
                                            isClearable={false}
                                        />
                                    </FormGroup>
                                    <FormGroup className="!mb-0">
                                        <Label>Статус участников</Label>
                                        <ComboBox
                                            onChange={(value) => {
                                                setUsersStatus(
                                                    value as { value: StatisticsUsersStatus; label: string },
                                                );
                                                setShowReportTable(false);
                                            }}
                                            value={usersStatus}
                                            options={usersStatusOptions}
                                            isSearchable={false}
                                            isCreatable={false}
                                            isClearable={false}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                            <div className="mt-9">
                                {showReportTable ? (
                                    <>
                                        <ReportTable
                                            singleProgramChoosed={programsValues.length === 1}
                                            onClickLoadReportFile={() => setShowReportSettings(true)}
                                            queryPayload={createPayloadParams()}
                                        />
                                        <ReportSettings
                                            isActive={showReportSettings}
                                            setIsActive={setShowReportSettings}
                                            settings={reportSettings}
                                            onSubmit={onSubmit}
                                            isReportFileFetching={false}
                                        />
                                    </>
                                ) : (
                                    <Button
                                        key={1}
                                        onClick={() => createReport()}
                                        variant="standard"
                                        size={"medium"}
                                        color={"primary"}
                                        className="w-full"
                                        disabled={!users.length}
                                    >
                                        {`Сформировать отчет по ${users.length} ${numWord(users.length, [
                                            "участнику",
                                            "участникам",
                                            "участникам",
                                        ])}`}
                                    </Button>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
