import React, { useEffect, useState } from "react";
import { ru } from "date-fns/locale";
import { Popover } from "@headlessui/react";
import { DayPicker } from "react-day-picker";
import { Input } from "Uikit/Forms/Input";
import { Tooltip } from "Uikit/Tooltip";
import { Icon, Icons } from "Uikit/Icon/Icon";
import clsx from "clsx";

export const DateTimeSelector = ({
    isPastTime,
    dateTime,
    onChange,
    error,
    showTimeInput,
    className,
}: {
    isPastTime: boolean;
    dateTime: number;
    onChange: (dateTime: number) => void;
    error?: string;
    showTimeInput?: boolean;
    className?: string;
}) => {
    const [timeValue, setTimeValue] = useState("");

    useEffect(() => {
        if (!dateTime) {
            return;
        }

        const currentDate = new Date();
        const date = new Date(dateTime * 1000);

        if (!isPastTime && date <= currentDate) {
            setTimeValue(
                (currentDate.getHours() < 10 ? "0" + currentDate.getHours() : currentDate.getHours()) +
                    ":" +
                    (currentDate.getMinutes() + 5 < 10
                        ? "0" + currentDate.getMinutes() + 5
                        : currentDate.getMinutes() + 5),
            );
        } else {
            setTimeValue(
                (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) +
                    ":" +
                    (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()),
            );
        }
    }, [isPastTime, dateTime]);

    return (
        <div className="relative flex space-x-3">
            <div className={clsx("max-w-40", className)}>
                <Popover>
                    {({ open, close }) => {
                        const date = dateTime ? new Date(dateTime * 1000) : new Date();

                        return (
                            <>
                                <Popover.Button className="relative">
                                    <Input
                                        id="adminNewTestInputPulicDeadLineDate"
                                        className={`cursor-pointer ${open ? "border-primary" : ""} ${
                                            error ? "border-red" : ""
                                        }`}
                                        type="text"
                                        value={new Date(
                                            date.getTime() - date.getTimezoneOffset() * 60 * 1000,
                                        ).toLocaleDateString("ru-RU")}
                                    />
                                    {error && (
                                        <div className="absolute top-1/2 -translate-y-1/2 right-3">
                                            <Tooltip content={error}>
                                                <Icon
                                                    icon={Icons.Exclamation}
                                                    width={20}
                                                    height={20}
                                                    color="fill-red"
                                                />
                                            </Tooltip>
                                        </div>
                                    )}
                                </Popover.Button>
                                <Popover.Panel className="absolute bottom-12 left-0 bg-white rounded-lg shadow-calendar">
                                    <DayPicker
                                        mode="single"
                                        locale={ru}
                                        fixedWeeks
                                        showOutsideDays
                                        selected={date}
                                        disabled={[
                                            {
                                                from: new Date(0),
                                                to: (() => {
                                                    const date = new Date();
                                                    date.setDate(date.getDate() - 1);
                                                    return date;
                                                })(),
                                            },
                                        ]}
                                        fromMonth={new Date()}
                                        captionLayout="dropdown-buttons"
                                        fromYear={new Date().getFullYear()}
                                        toYear={new Date().getFullYear() + 5}
                                        onSelect={(date) => {
                                            if (date) {
                                                date.setHours(+timeValue.split(":")[0]);
                                                date.setMinutes(+timeValue.split(":")[1]);
                                            }

                                            onChange(date!.getTime() / 1000);
                                            close();
                                        }}
                                    />
                                </Popover.Panel>
                            </>
                        );
                    }}
                </Popover>
            </div>
            {showTimeInput && (
                <div className="max-w-20 2xl:max-w-25">
                    <Input
                        id="adminNewTestInputPulicDeadLineTime"
                        className={error ? "border-red" : ""}
                        type="time"
                        value={timeValue}
                        onChange={(e) => {
                            const time = e.target.value;

                            if (!time) {
                                return;
                            }

                            if (!dateTime) {
                                setTimeValue(time);
                                return;
                            }

                            setTimeValue(time);
                        }}
                        onBlur={() => {
                            const currentDate = new Date();
                            const selected = new Date(dateTime * 1000);

                            const [hours, minutes] = timeValue.split(":").map((str) => parseInt(str, 10));
                            let newSelectedDate;

                            if (
                                !isPastTime &&
                                selected.getFullYear() === currentDate.getFullYear() &&
                                selected.getMonth() === currentDate.getMonth() &&
                                selected.getDate() === currentDate.getDate() &&
                                (hours < currentDate.getHours() ||
                                    (hours === currentDate.getHours() && minutes < currentDate.getMinutes()))
                            ) {
                                newSelectedDate = new Date(
                                    selected.getFullYear(),
                                    selected.getMonth(),
                                    selected.getDate(),
                                    currentDate.getHours(),
                                    +currentDate.getMinutes() + 5,
                                );

                                setTimeValue(
                                    ("0" + currentDate.getHours()).slice(-2) +
                                        ":" +
                                        ("0" + (+currentDate.getMinutes() + 5).toString()).slice(-2),
                                );
                            } else {
                                newSelectedDate = new Date(
                                    selected.getFullYear(),
                                    selected.getMonth(),
                                    selected.getDate(),
                                    hours,
                                    minutes,
                                );
                            }

                            onChange(newSelectedDate.getTime() / 1000);
                        }}
                    />
                </div>
            )}
        </div>
    );
};
