import React, { useState, useEffect } from "react";
import { getCookie } from "typescript-cookie";
import { Icon, Icons, flash } from "Uikit";
import { Tooltip } from "Uikit/Tooltip";
import { ProgressBar } from "Uikit/ProgressBar";
import { humanFileSize } from "helpers/humanFileSize";
import { FILES_TYPE_ICONS, FILE_INPUT_ACCEPTED_TYPES, FILES_TYPE_SHORT_NAME } from "constants/attachments";
import { useUpload } from "hooks/useUpload";
import { FileUploadType, UIErrorMessages, WSEventVideoActionTypes, WssMessageType } from "Enums";
import { getFileViewType } from "helpers/file";
// import { TVoidFunction } from "types";
import Api from "Api";
import NotificationConnect, { NotificationSubscribe } from "Api/Wss/Notifications";
import { TUserAttachments } from "Api/Requests/TaskPublicRequest";
import { fetchFileInfo } from "helpers/fetchFileInfo";
import { useDialog } from "hooks/useDialog";
import { Confirmation } from "Components/Confirmation/Confirmation";
import { WssMessage } from "types/WssMessage";

const onError = () => {
    flash.error(UIErrorMessages.FILE_LOADING_ERROR);
};

interface ITaskQuestionFileAttachmentProps {
    fileObj: TUserAttachments;
    onDelete: (deletedAttchmentId: string, file: File | null) => void;
    onLoadAttachment: (loadedAttchmentData: TUserAttachments, file: File | null) => void;
    onAttachmentClick: ({
        type,
        id,
        size,
        name,
        extension,
        disposableLink,
    }: {
        type: string;
        id: string;
        size: string;
        name: string;
        extension: string;
        disposableLink: string;
    }) => void;
    isUploaded: boolean;
    onDiscardOptimizationClick: (file: any) => void;
}

const hostname = window.location.hostname.includes("localhost")
    ? "http://localhost:3000"
    : window.location.protocol + "//" + window.location.hostname;

export const TaskQuestionFileAttachment = ({
    fileObj,
    onDelete,
    onAttachmentClick,
    onLoadAttachment,
    isUploaded,
    onDiscardOptimizationClick,
}: ITaskQuestionFileAttachmentProps) => {
    const { upload } = useUpload();
    const [isGotError, setIsGotError] = useState(false);
    const [uploadingState, setUploadingState] = useState<any>({}); // подставляется рез-т вызова onUpdate и onFinished объекта upload
    const { dialogState, openDialog, closeDialog } = useDialog();

    // const [isVideoProcessed, setIsVideoProcessed] = useState(false);
    const [videoProcessingActionType, setVideoProcessingActionType] = useState<WSEventVideoActionTypes | null>(null);
    const [fileLoaded, setFileLoaded] = useState<File | null>(null);
    const isVideo = (fileObj?.type || uploadingState?.file?.type)?.includes("video");

    const showOptimizationModal = (file: any) => {
        openDialog({
            title: "Оптимизация файла",
            content:
                "Файл в процессе оптимизации. Это может занять много времени. Можно оптимизировать файл самостоятельно по инструкции и загрузить заново или продолжить оптимизировать в системе.",
            closeBtnText: "Отмена",
            submitBtnText: "Продолжить",
            onRequestClose: () => {
                onDiscardOptimizationClick(file);
                onDelete(uploadingState.fileId ?? fileObj.id, fileObj.file as File);
                setUploadingState({});
                closeDialog();
            },
            onRequestSubmit: () => {
                closeDialog();
            },
        });
    };

    useEffect(() => {
        if (fileObj.id) {
            fetchFileInfo(String(fileObj.id), (isVideoProcessed) => {
                setVideoProcessingActionType(
                    isVideoProcessed
                        ? WSEventVideoActionTypes.PROCESSING_COMPLETE
                        : WSEventVideoActionTypes.PROCESSING_IN_PROGRESS,
                );
            });
        }

        if (!fileLoaded && isUploaded && (uploadingState.fileId ?? fileObj.id)) {
            Api.File.DownloadFile((uploadingState.fileId ?? fileObj.id) as string).then((blob) => {
                const file = new File([blob], fileObj.name ?? "");
                if (file) {
                    setFileLoaded(file);
                    onLoadAttachment(fileObj, file);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    NotificationConnect({
        onWSCloseCallback: () => {
            if (fileObj.id) {
                Api.Upload.DiscardVideoFile(fileObj.id);
            }
        },
    });
    NotificationSubscribe(async (message: WssMessage) => {
        if (message.messageType === WssMessageType.NOTIFICATION) {
            const { actionType, objectId } = message.body;
            if (actionType === WSEventVideoActionTypes.PROCESSING_IN_PROGRESS) {
                setVideoProcessingActionType(WSEventVideoActionTypes.PROCESSING_IN_PROGRESS);
                showOptimizationModal?.(fileObj);
            }
            if (
                actionType === WSEventVideoActionTypes.PROCESSING_COMPLETE &&
                (uploadingState.fileId || fileObj.id) === objectId
            ) {
                setVideoProcessingActionType(WSEventVideoActionTypes.PROCESSING_COMPLETE);
                closeDialog();
            }
        }
    });

    useEffect(() => {
        if (!fileLoaded && !isUploaded) {
            if (!uploadingState.status) {
                const { file: fileObjFile } = fileObj;
                const u = upload({
                    file: fileObjFile,
                    onUpdate: (file: any) => {
                        setUploadingState(file);
                    },
                    onFinished: (fileData: any) => {
                        const {
                            fileId,
                            file: { name, size, type },
                        } = fileData;
                        setUploadingState(
                            fileId
                                ? {
                                      ...fileData,
                                      fileId,
                                  }
                                : fileData,
                        );
                        onLoadAttachment({ ...fileObj, id: fileId, name, size, type }, fileObjFile as File);
                    },
                    fileUploadType: FileUploadType.EXERCISE_FILE,
                    socketId: getCookie("USER_SESSION"),
                });
                u.start().catch(() => {
                    setIsGotError(true);
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileLoaded]);

    return (
        <>
            <Confirmation {...dialogState} />
            <div
                className="flex p-2.5 sm:p-4 2xl:p-5 border border-gray-blue bg-white rounded-lg 2xl:rounded-2lg group cursor-pointer"
                onClick={() => {
                    if (isVideo && videoProcessingActionType === WSEventVideoActionTypes.PROCESSING_IN_PROGRESS) {
                        showOptimizationModal?.(fileObj);
                        return;
                    }

                    if (
                        (!isVideo && fileObj.size) ||
                        (isVideo && String(videoProcessingActionType) === WSEventVideoActionTypes.PROCESSING_COMPLETE)
                    ) {
                        onAttachmentClick({
                            type: uploadingState.file?.type || fileObj.type,
                            id: uploadingState.fileId ?? fileObj.id,
                            size: uploadingState.file?.size ?? fileObj.size,
                            name: uploadingState.file?.name ?? fileObj.name,
                            extension: FILE_INPUT_ACCEPTED_TYPES[uploadingState.file?.type ?? fileObj.type],
                            disposableLink: `/service/lms-upload/api/file/download/${uploadingState.fileId}`,
                        });
                    }
                }}
            >
                <div className="relative flex items-center w-full">
                    {!isGotError ? (
                        <>
                            <Icon
                                className="mr-6 2xl:mr-7.5 !min-w-6 sm:!min-w-8 !min-h-6 sm:!min-h-8 !w-6 sm:!w-8 !h-6 sm:!h-8 2xl:!w-10 2xl:!h-10 shrink-0"
                                icon={
                                    FILES_TYPE_ICONS[
                                        FILE_INPUT_ACCEPTED_TYPES[
                                            (uploadingState.file?.type ?? fileObj.type)?.replace(/(;(.)*)/g, "")
                                        ] || uploadingState.serverData?.extension
                                    ] || Icons.FileOther
                                }
                            />
                            <div className="w-full">
                                <div className="flex items-center pb-1 sm:pb-2 2xl:pb-2.5 w-full">
                                    {(uploadingState.status === "loading" ||
                                        (isVideo &&
                                            String(videoProcessingActionType) ===
                                                WSEventVideoActionTypes.PROCESSING_IN_PROGRESS)) && (
                                        <img
                                            className="mr-1"
                                            src={"/icons/loading.svg"}
                                            width={16}
                                            height={16}
                                            alt="loading"
                                        />
                                    )}
                                    <span className="w-[35%] sm:w-[80%] h-4 sm:h-5 2xl:h-6 overflow-hidden text-ellipsis group-hover:text-blue-light p3 sm:p1 2xl:text-md">
                                        {uploadingState.file?.name ?? fileObj.name}
                                    </span>
                                </div>

                                <div className="text-gray-text p4 sm:p1 2xl:text-md">
                                    {isVideo &&
                                        fileObj.id &&
                                        String(videoProcessingActionType) ===
                                            WSEventVideoActionTypes.PROCESSING_IN_PROGRESS && (
                                            <Tooltip
                                                className="flex"
                                                content={
                                                    "Файл в очереди на конвертацию, это может занять много времени. Можно конвертировать файл самостоятельно и загрузить заново. Для этого надо отменить текущую загрузку."
                                                }
                                                maxWidth={465}
                                            >
                                                <>Видео обрабатывается...</>
                                            </Tooltip>
                                        )}

                                    {uploadingState.status === "loading" && (
                                        <>
                                            {`${humanFileSize(uploadingState.uploaded, true)} / ${humanFileSize(
                                                uploadingState.size,
                                                true,
                                            )} – осталось ${uploadingState.timeLeft}`}
                                        </>
                                    )}

                                    {((!isVideo && (uploadingState.status === "done" || fileObj.size)) ||
                                        (isVideo &&
                                            String(videoProcessingActionType) ===
                                                WSEventVideoActionTypes.PROCESSING_COMPLETE)) && (
                                        <>{`${
                                            FILES_TYPE_SHORT_NAME[
                                                FILE_INPUT_ACCEPTED_TYPES[
                                                    (uploadingState.file?.type ?? fileObj.type)?.replace(/(;(.)*)/g, "")
                                                ] || uploadingState.serverData?.extension
                                            ]
                                        } – ${humanFileSize(uploadingState.size ?? fileObj.size, true)}`}</>
                                    )}

                                    {uploadingState.status !== "done" && !!uploadingState.uploadedPercent && (
                                        <div className="mt-2">
                                            <ProgressBar value={uploadingState.uploadedPercent || 0} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <Icon
                                className="mr-6 2xl:mr-7.5 2xl:!w-10 2xl:!h-10"
                                icon={Icons.FileOther}
                                width="32px"
                                height="32px"
                            />
                            <div>
                                <span className="text-red 2xl:text-md">Ошибка при загрузке файла</span>
                            </div>
                        </>
                    )}
                    {uploadingState.status === "loading" && (
                        <div
                            className="absolute top-0 right-0 flex items-center gap-5"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                uploadingState.cancel();
                                setUploadingState({});
                                onDelete(uploadingState.fileId ?? fileObj.id, fileObj.file as File);
                            }}
                        >
                            <div className="flex-center cursor-pointer">
                                <Icon
                                    icon={Icons.CloseBig}
                                    width={16}
                                    height={16}
                                    color="fill-[#939393]"
                                    className="2xl:!w-5 2xl:!h-5"
                                />
                            </div>
                        </div>
                    )}
                    {uploadingState.status !== "loading" && (
                        <div className="absolute top-0 right-0 flex items-center gap-4 2xl:gap-5">
                            {fileObj.id && (
                                <a
                                    className="hidden sm:inline-block"
                                    href={`${hostname}/service/lms-upload/api/file/download/${fileObj.id}`}
                                    download={uploadingState.file?.name || fileObj.name}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    <Icon
                                        icon={Icons.Download}
                                        width="20px"
                                        height="20px"
                                        color="fill-blue-drk"
                                        className="2xl:!w-6.25 2xl:!h-6.25"
                                    />
                                </a>
                            )}
                            <div
                                className="cursor-pointer"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    setUploadingState({});
                                    onDelete(uploadingState.fileId ?? fileObj.id, fileObj.file as File);
                                }}
                            >
                                <Icon
                                    icon={Icons.Delete}
                                    width="20px"
                                    height="20px"
                                    color="fill-blue-drk"
                                    className="2xl:!w-6.25 2xl:!h-6.25"
                                />
                            </div>
                            {uploadingState.serverData &&
                                getFileViewType({
                                    type: uploadingState.serverData.contentType,
                                    extension: uploadingState.serverData.extension,
                                }) === "IMAGE" && (
                                    <img
                                        src={`/service/lms-upload/api/file/download/${uploadingState.serverData.id}`}
                                        width="0"
                                        height="0"
                                        className="hidden"
                                        onError={() => {
                                            onError();
                                            setUploadingState({});
                                            onDelete(uploadingState.fileId ?? fileObj.id, fileObj.file as File);
                                        }}
                                    />
                                )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
