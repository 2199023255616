import React, { useMemo, useState } from "react";
import { ColumnDef, ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { useQuery } from "react-query";
import { Empty } from "Uikit/Page/Empty";
import { Table } from "Uikit";
import { Filter } from "Components/Filter/Filter";
import { BasePaginationResponse } from "Api/BaseResponse";
import { AchievementHistoryResponse } from "Api/Responses/AchievementResponse";
import { DateFormat, formatDate } from "helpers/dateHelper";
import { UserAvatar } from "Uikit/UserAvatar/UserAvatar";
import Api from "Api/index";
import { LogoSize } from "Api/Services/UploadApi";

interface IAchievementsHistoryTable {
    usersOptions: { label: string; value: string | undefined }[];
    fetch: (
        page: number,
        size: number,
        sort: SortingState,
        filter: { [id: string]: string },
    ) => Promise<BasePaginationResponse<AchievementHistoryResponse>>;
}
export const AchievementsHistoryTable = ({ usersOptions, fetch }: IAchievementsHistoryTable) => {
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 15 });
    const [sorting, setSorting] = useState<SortingState>([{ id: "timestamp", desc: true }]);

    const [rowSelection, setRowSelection] = useState({});
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

    const [isFilters, setIsFilters] = useState(false);
    const [filters, setFilters] = useState<{ [id: string]: any }>({});

    const pagination = useMemo(() => ({ pageIndex, pageSize }), [pageIndex, pageSize]);
    const dataQuery = useQuery(
        ["achievementsHistory", "collection", pagination, sorting, filters],
        async () => {
            const filtersKeys = Object.keys(filters);
            const filtersData: any = {};

            for (const element of filtersKeys) {
                if (element === "achievementTimestamp") {
                    filtersData[element + ".greaterThanOrEqual"] = Math.round(
                        filters[element]["date"]["startDate"].getTime() / 1000,
                    );
                    filtersData[element + ".lessThanOrEqual"] = Math.round(
                        filters[element]["date"]["endDate"].getTime() / 1000,
                    );
                } else if (element === "rating") {
                    if (filters[element]["minValue"]) {
                        if (isNaN(parseInt(filters[element]["minValue"]))) {
                            continue;
                        }

                        filtersData[element + ".greaterThanOrEqual"] = filters[element]["minValue"];
                    }
                    if (filters[element]["maxValue"]) {
                        if (isNaN(parseInt(filters[element]["maxValue"]))) {
                            continue;
                        }

                        filtersData[element + ".lessThanOrEqual"] = filters[element]["maxValue"];
                    }
                } else if (element === "madeById.in" || element === "userId.in") {
                    filtersData[element] = filters[element].map((p: any) => p.value).join(",");
                } else {
                    filtersData[element] = filters[element];
                }
            }

            return await fetch(pageIndex, pageSize, sorting, filtersData);
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );

    const columns = React.useMemo<ColumnDef<AchievementHistoryResponse>[]>(
        () => [
            {
                header: "Участник",
                accessorKey: "user.lastName,user.firstName",
                cell: (info) => {
                    return (
                        <div className="group flex items-center space-x-3">
                            <UserAvatar
                                avatarId={info.row.original.user.avatarId}
                                color={info.row.original.user.defaultAvatarColor}
                                userInitials={`${info.row.original.user.firstName?.slice(
                                    0,
                                    1,
                                )}${info.row.original.user.lastName?.slice(0, 1)}`}
                                size={36}
                            />
                            <span className="group-hover:text-blue">{`${info.row.original.user.lastName} ${info.row.original.user.firstName}`}</span>
                        </div>
                    );
                },
                footer: (props) => props.column.id,
            },
            {
                header: "Ачивка",
                accessorKey: "achievement.name",
                cell: (info) => {
                    return (
                        <div className="group flex items-center space-x-3">
                            {info.row.original.achievement.logoId && (
                                <img
                                    className="rounded-full w-9 h-9 object-cover"
                                    src={Api.Upload.GetLogo(info.row.original.achievement.logoId, LogoSize.THUMB_MICRO)}
                                    alt={info.row.original.achievement.name}
                                />
                            )}
                            {!info.row.original.achievement.logoId && (
                                <div className="rounded-full w-9 h-9 bg-gray-blue" />
                            )}
                            <span className="group-hover:text-blue">{info.row.original.achievement.name}</span>
                        </div>
                    );
                },
                footer: (props) => props.column.id,
            },
            {
                header: "Кто",
                accessorKey: "madeBy.lastName,madeBy.firstName",
                cell: (info) => {
                    return (
                        <div className="flex items-center space-x-3">
                            <UserAvatar
                                avatarId={info.row.original.madeBy.avatarId}
                                color={info.row.original.madeBy.defaultAvatarColor}
                                userInitials={`${info.row.original.madeBy.firstName?.slice(
                                    0,
                                    1,
                                )}${info.row.original.madeBy.lastName?.slice(0, 1)}`}
                                size={36}
                            />
                            <span>{`${info.row.original.madeBy.lastName} ${info.row.original.madeBy.firstName}`}</span>
                        </div>
                    );
                },
                footer: (props) => props.column.id,
            },
            {
                header: "Действие",
                accessorKey: "action",
                cell: (info) => {
                    return <span>{info.getValue() === "ADD" ? "Выдали" : "Забрали"}</span>;
                },
                footer: (props) => props.column.id,
            },
            {
                size: 200,
                enableResizing: true,
                header: "Дата действия",
                accessorKey: "timestamp",
                cell: (info) => {
                    return formatDate(new Date(Number(info.getValue())), DateFormat.DATE_TIME_LONG);
                },
                footer: (props) => props.column.id,
            },
        ],
        [],
    );
    const filtersConfig = [
        {
            label: "Дата действия",
            fields: [
                {
                    accessor: "achievementTimestamp",
                    type: "date-range",
                },
            ],
        },
        {
            label: "Бонусные баллы ачивки",
            fields: [
                {
                    accessor: "rating",
                    type: "range",
                },
            ],
        },
        {
            label: "Кто выдал/забрал ачивку",
            fields: [
                {
                    accessor: "madeById.in",
                    type: "multi-select",
                    placeholder: "Выберите пользователей",
                    options: usersOptions.filter((p, index) => index !== 0),
                },
            ],
        },
        {
            label: "Участники",
            fields: [
                {
                    accessor: "userId.in",
                    type: "multi-select",
                    placeholder: "Выберите пользователей",
                    options: usersOptions.filter((p, index) => index !== 0),
                },
            ],
        },
        {
            label: "Дополнительно",
            fields: [
                {
                    accessor: "userProfileAssignmentDisabled.equal",
                    label: "Запрещено выдавать в профиле пользователя",
                    type: "checkbox",
                    default: false,
                },
            ],
        },
    ];

    const controlButtons = (
        <div className="flex justify-between items-center w-full">
            <p className="w-75 p3 text-gray-text">
                Здесь собрана вся история действий с ачивками. Кто, когда их выдавал или забирал
            </p>
            {/*<Button*/}
            {/*    variant="outline"*/}
            {/*    color="secondary"*/}
            {/*    size="medium"*/}
            {/*    className="border-[#E6E9ED] rounded-lg font-medium"*/}
            {/*    icon={<Icon icon={Icons.Filter} width={20} height={20} color="stroke-blue" />}*/}
            {/*    iconPlacement="left"*/}
            {/*    onClick={() => setIsFilters(true)}*/}
            {/*    id="adminSettingsBtnFilter"*/}
            {/*>*/}
            {/*    Фильтры*/}
            {/*</Button>*/}
        </div>
    );

    return (
        <div className="h-full">
            <Filter
                isActive={isFilters}
                setIsActive={setIsFilters}
                configuration={filtersConfig}
                filters={filters}
                onChange={setFilters}
            />
            {dataQuery.data?.Total === 0 && (
                <div className="h-full flex items-center">
                    <Empty
                        title="Истории пока нет"
                        description={
                            <span>
                                Здесь будет собрана вся история действий с ачивками. Будет указано, кто
                                <br />и когда их выдавал или забирал
                            </span>
                        }
                    />
                </div>
            )}
            {dataQuery.data?.Total !== 0 && (
                <Table
                    columns={columns}
                    controlButtons={controlButtons}
                    columnFilters={columnFilters}
                    pageCount={dataQuery.data?.TotalPages}
                    pagination={pagination}
                    emptyMessage="По заданным параметрам результатов нет"
                    isFetching={dataQuery.isFetching}
                    data={dataQuery.data?.Content}
                    rowSelection={rowSelection}
                    onPaginationChange={setPagination}
                    onRowSelectionChange={setRowSelection}
                    onColumnFiltersChange={setColumnFilters}
                    sorting={sorting}
                    onSortingChange={setSorting}
                    id="settingsAchievementsHistoryTab"
                    defaultSortOrder={{
                        "user_lastName,user.firstName": "asc",
                        "madeBy_lastName,madeBy.firstName": "asc",
                    }}
                />
            )}
        </div>
    );
};
