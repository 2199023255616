import React, { useEffect, useMemo, useState } from "react";
import { ColumnDef, SortingState } from "@tanstack/react-table";
import { Link } from "react-router-dom";
import { useInfiniteQuery } from "react-query";
import flatten from "lodash/flatten";

import { Table, Icon, Icons } from "Uikit";
import { ID } from "types/ID";
import Api from "Api/index";
import { UserListResponse } from "Api/Responses/UserResponse";
import { TeamMultiSelect } from "Components/Common/TeamMultiSelect";
import { OfficeMultiselect } from "Components/Common/OfficeMultiselect";
import { JobMultiSelect } from "Components/Common/JobMultiSelect";
import { UserAvatar } from "Uikit/UserAvatar/UserAvatar";
import { RoleName, RoleNameTranslate } from "Enums";
import { useCurrentUser } from "hooks/useCurrentUser";

interface InviteRegistrationMembersTableProps {
    hideTeamMembers?: boolean;
    inviteId: string;
}

export const InviteRegistrationMembersTable = ({
    inviteId,
    hideTeamMembers = false,
}: InviteRegistrationMembersTableProps) => {
    const currentUser = useCurrentUser();
    const isRoot = currentUser.data?.role === RoleName.ROOT;

    const [users, setUsers] = useState<UserListResponse[]>([]);
    const [usersFiltered, setUsersFiltered] = useState<UserListResponse[]>([]);
    const [teamsOnly, setTeamsOnly] = useState<ID[]>([]);
    const [jobsOnly, setJobsOnly] = useState<ID[]>([]);
    const [officesOnly, setOfficesOnly] = useState<ID[]>([]);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState<SortingState>([]);

    const {
        data: usersList,
        isLoading: isLazyLoading,
        hasNextPage,
        fetchNextPage,
        // isFetching
    } = useInfiniteQuery(
        ["users", "collection", search, sorting],
        async ({ pageParam = 0 }) => {
            const filtersData: any = {};
            if (search.length > 0) {
                filtersData["searchQuery.contains"] = search;
            }
            filtersData["state.equal"] = "ACTIVE";
            filtersData["inviteId.equal"] = inviteId;

            // TODO: Заменить на эндпоинт получения списка участников, зарег-ых по приглашению
            const result = await Api.Invites.UsersList(pageParam, 15, sorting, filtersData);
            return result.Content;
        },
        {
            getNextPageParam: (lastPage, allPages) => {
                const nextPage = allPages.length;

                return lastPage?.length === 15 ? nextPage : undefined;
            },
            keepPreviousData: true,
        },
    );

    const columns = useMemo<ColumnDef<UserListResponse>[]>(
        () => [
            {
                header: () => <div className="flex h-9 items-center justify-start">Участник</div>,
                footer: (props) => props.column.id,
                accessorKey: "firstName,lastName",
                enableResizing: true,
                cell: ({ row: { original: user } }) => {
                    return (
                        <div
                            className={
                                "group flex items-center relative h-9 after:content-[''] after:hidden after:absolute after:top-0 after:-left-2 after:w-[calc(100%+8px)] after:h-full after:bg-blue-lightest group-hover:after:z-0 after:z-0 group-hover:after:block"
                            }
                        >
                            <div
                                className={`flex items-center space-x-3 relative z-10 group-hover:text-blue ${
                                    !isRoot && user.role === RoleName.SUPER_ADMIN ? "opacity-50" : ""
                                }`}
                            >
                                <UserAvatar
                                    avatarId={user.avatarId}
                                    color={user.defaultAvatarColor}
                                    userInitials={`${user.firstName?.slice(0, 1)}${user.lastName?.slice(0, 1)}`}
                                    size={28}
                                />
                                <div className="line-clamp-1">{String(user.lastName + " " + user.firstName)}</div>
                            </div>
                        </div>
                    );
                },
            },
            {
                header: () => <div className="flex justify-end w-full h-9 items-center">Роль</div>,
                footer: (props) => props.column.id,
                accessorKey: "role",
                size: 160,
                enableResizing: true,
                cell: ({ row: { original: user } }) => {
                    const isVisible = isRoot || (!isRoot && [RoleName.ADMIN, RoleName.USER].includes(user.role));
                    return (
                        <div className="group text-right pr-2 relative after:content-[''] after:hidden after:absolute after:-top-1.5 after:-bottom-1.5 after:-left-2 after:w-[calc(100%+8px)] after:bg-blue-lightest group-hover:after:z-0 after:z-0 group-hover:after:block">
                            {isVisible && (
                                <Link
                                    className="hidden relative z-10 items-center justify-end group-hover:flex cursor-pointer text-right"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    to={`/admin/member/${user.id}`}
                                >
                                    <Icon
                                        className="ml-1 mr-1"
                                        icon={Icons.ShareBox}
                                        width={17}
                                        height={17}
                                        color="fill-primary"
                                    />
                                    Посмотреть
                                </Link>
                            )}
                            <span
                                className={`relative z-10 ${
                                    !isRoot && user.role === RoleName.SUPER_ADMIN ? "opacity-50" : ""
                                } ${isVisible ? "group-hover:hidden" : ""}  text-gray`}
                            >
                                {RoleNameTranslate[user.role]}
                            </span>
                        </div>
                    );
                },
                columnIndividualClassName: "justify-end",
            },
        ],
        [isRoot],
    );

    useEffect(() => {
        const list = flatten(usersList?.pages ?? []);
        setUsersFiltered(list);
        setUsers(list);
    }, [usersList]);

    useEffect(() => {
        setUsersFiltered(() => {
            let usersFiltered = users;
            if (teamsOnly.length > 0) {
                usersFiltered = usersFiltered.filter((test) => test.teams.some((test) => teamsOnly.includes(test.id)));
            }
            if (officesOnly.length > 0) {
                usersFiltered = usersFiltered.filter((test) => officesOnly.includes(test.officeId));
            }
            if (jobsOnly.length > 0) {
                usersFiltered = usersFiltered.filter(({ jobTitle = {} }) => {
                    return jobTitle?.id && jobsOnly.includes(jobTitle?.id);
                });
            }
            return usersFiltered;
        });
    }, [users, teamsOnly, officesOnly, jobsOnly]);

    const controlButtons = (
        <div className="flex w-full justify-start items-center space-x-2">
            <div className="max-w-42 w-full">
                <TeamMultiSelect
                    value={teamsOnly}
                    onChange={(v) => Array.isArray(v) && setTeamsOnly(v.map((v) => v))}
                    showTeams={false}
                    hideMembers={hideTeamMembers}
                />
            </div>
            <div className="max-w-42 w-full">
                <OfficeMultiselect onChange={setOfficesOnly} />
            </div>
            <div className="max-w-42 w-full">
                <JobMultiSelect onChange={setJobsOnly} />
            </div>
        </div>
    );

    return (
        <div className="h-135">
            <Table
                columns={columns}
                searchTitle="Поиск по участникам"
                data={usersFiltered}
                rowSelection={{}}
                controlButtons={controlButtons}
                rowClassName="!p-0 border-none group"
                searchAlwaysLeft={true}
                controlsWrapperClassNames={"mt-6 flex-col gap-4 grow"}
                searchClassName="!w-full"
                tableWrapperClassName="overflow-y-auto overflow-x-hidden pl-2 custom-scrollbar js-scrollbar mt-4"
                columnClassName="sticky top-0 bg-white !opacity-100 first-of-type:pl-2 pl-0 py-0"
                theadClassName="sticky z-40 pb-4"
                searchRowClassName="!mb-0"
                onSearch={(searchQuery: string) => setSearch(searchQuery)}
                searchValue={search}
                sorting={sorting}
                onSortingChange={setSorting}
                pageCount={usersFiltered?.length}
                defaultSortOrder={{
                    "firstName,lastName": "asc",
                }}
                // lazyload params
                lazy
                isLazyLoading={isLazyLoading}
                onLoadMore={fetchNextPage}
                hasMore={hasNextPage}
                isSkeletonLoaderTable={true}
            />
        </div>
    );
};
