import React, { useEffect, useState } from "react";
import SelectComponents from "react-select";

import { Button, ComboBox, FormGroup, Label, SelectAvatarMultiValueLabel } from "Uikit";
import { StackedValueContainer } from "Uikit/Forms/SelectCustomComponents/StackedValueContainer";
import Api from "Api";
import { MembersListResponse } from "Api/Responses/UserResponse";
import { StatisticTestRequest } from "Api/Requests/StatisticRequest";

import {
    ProgressStatus,
    ResourceState,
    StatisticsAttempts,
    StatisticsReportAccess,
    StatisticsUsersStatus,
    ResourceType,
} from "Enums";
import { numWord } from "helpers/numWord";
import { TrainingMultiSelect } from "Components/Common/TrainingMultiSelect";

import { ReportUserSelect } from "../../ReportUserSelect/ReportUserSelect";
import { IRequestSettingsItem, ReportSettings } from "../../ReportSettings/ReportSettings";
import { IStatisticValue } from "../utils";
import { SingleValueContainer } from "../SingleValueContainer";
import {
    accessOptions,
    attemptsOptions,
    progressOptions,
    reportSettings,
    usersStatusOptions,
} from "./TestWithoutCourse";
import { ReportTable } from "./ReportTable";

export type TTaskFilterValue = ResourceState | "ALL";

export const TestWithinCourse = () => {
    const [coursesValues, setCoursesValues] = useState<IStatisticValue[]>([]);
    const [showReportTable, setShowReportTable] = useState(false);
    const [testsValues, setTestsValues] = useState<IStatisticValue[]>([]);
    const [users, setUsers] = useState<MembersListResponse[]>([]);
    const [allUsersCount, setAllUsersCount] = useState(0);
    const [allUsers, setAllUsers] = useState<MembersListResponse[]>([]);
    const [access, setAccess] = useState(accessOptions[0]);
    const [progress, setProgress] = useState<{ value: ProgressStatus; label: string }>(progressOptions[0]);
    const [attempts, setAttempts] = useState(attemptsOptions[1]);
    const [usersStatus, setUsersStatus] = useState(usersStatusOptions[0]);
    const [showReportSettings, setShowReportSettings] = useState(false);
    const [isUsersLoaded, setIsUsersLoaded] = useState(false);

    const onSubmitCourseSection = (selectedItems: any[]) => {
        setCoursesValues(
            selectedItems.map(({ id, name, logoId }) => {
                return {
                    label: name,
                    value: id,
                    name,
                    id,
                    logoId,
                };
            }),
        );

        setShowReportTable(false);
    };

    const onSubmitTestSection = (selectedItems: any[]) => {
        setTestsValues(
            selectedItems.map(({ id, name, logoId }) => {
                return {
                    label: name,
                    value: id,
                    name,
                    id,
                    logoId,
                };
            }),
        );

        setShowReportTable(false);
    };

    const createReport = () => {
        setShowReportTable(true);
    };

    const createPayloadParams = () => {
        const payload: StatisticTestRequest = {
            courseIds: coursesValues.map(({ id }) => id),
            quizIds: testsValues.map(({ id }) => id),
            userIds: users.map(({ id }) => id),
            teamsIds: Array.from(
                new Set(
                    users.reduce((prev, { teams }) => {
                        return [...prev, ...teams.map(({ id }) => id)];
                    }, [] as string[]),
                ),
            ),
            officeIds: users.map(({ officeId }) => officeId).filter((item) => !!item),
            jobIds: users.map(({ jobTitle }) => jobTitle?.id).filter((item) => !!item) as string[],
            access: access.value,
            status: progress.value,
            attemptNumber: attempts.value,
            userStatus: usersStatus.value,
        };

        return payload;
    };

    const onSubmit = async (settingsChecked: IRequestSettingsItem[]) => {
        const request: any = {};

        request.params = createPayloadParams();

        settingsChecked.forEach(({ key, items }: IRequestSettingsItem) => {
            request[key] = items
                .filter((item) => item.value !== "all")
                .reduce((prev, cur) => {
                    return {
                        ...prev,
                        [cur.value]: cur.checked,
                    };
                }, {});
        });

        setShowReportSettings(false);

        try {
            await Api.Statistic.GenerateTestStatisticReportFile(request);
        } catch (error: any) {
            console.log(error);
        }
    };

    useEffect(() => {
        setTestsValues([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coursesValues]);

    useEffect(() => {
        if (testsValues.length === 0) {
            setUsers(allUsers);
            setAccess(accessOptions[0]);
            setProgress(progressOptions[0]);
            setAttempts(attemptsOptions[1]);
            setUsersStatus(usersStatusOptions[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [testsValues]);

    return (
        <>
            <div className="grid items-center gap-6.5 grid-cols-2">
                {/* Выбор курса */}
                <TrainingMultiSelect
                    className="!mt-0"
                    value={coursesValues[0] ?? null}
                    onSubmiTreeSection={onSubmitCourseSection}
                    placeholder="Выберите курс"
                    components={
                        {
                            ValueContainer: SingleValueContainer,
                        } as unknown as SelectComponents
                    }
                    checkable={false}
                    selectable={true}
                    checkOnNameClick={true}
                    showSelectedCounter={false}
                    isMulti={false}
                    resourceType={ResourceType.COURSE}
                />
                {/* Выбор тестов */}
                {coursesValues.length > 0 && (
                    <TrainingMultiSelect
                        className="!mt-0"
                        value={testsValues}
                        onSubmiTreeSection={onSubmitTestSection}
                        onChange={(options, action) => {
                            if (action && action.action === "clear") {
                                setTestsValues([]);
                            }
                            if (action && action.action === "remove-value") {
                                setTestsValues((prevItems) =>
                                    prevItems.filter(({ id }) => id !== action.removedValue.id),
                                );
                            }
                            setShowReportTable(false);
                        }}
                        placeholder="Выберите один или несколько тестов"
                        resourceType={ResourceType.QUIZ}
                        fetchDataFilters={{ "courseId.in": [coursesValues.map(({ id }) => id)] }}
                        fetchDataDependencies={coursesValues}
                    />
                )}
            </div>
            {testsValues.length > 0 && (
                <>
                    <div className="mt-7 flex-wrap relative z-[70]">
                        <div className="grid items-center gap-6.5 grid-cols-2">
                            {/* Выбор участников */}
                            <FormGroup className="!mb-0">
                                <Label>Участники</Label>
                                <ReportUserSelect
                                    value={users}
                                    placeholder="Выберите одного или нескольких участников"
                                    onSubmit={(users) => {
                                        setUsers(
                                            users.map((user) => {
                                                const { firstName, lastName, avatarId, defaultAvatarColor } = user;
                                                return {
                                                    ...user,
                                                    value: {
                                                        avatarId,
                                                        firstName,
                                                        lastName,
                                                        defaultAvatarColor,
                                                    },
                                                    label: `${lastName} ${firstName}`,
                                                };
                                            }),
                                        );
                                        setShowReportTable(false);
                                    }}
                                    onChange={(options, action) => {
                                        if (action && action.action === "clear") {
                                            setUsers([]);
                                        }
                                        if (action && action.action === "remove-value") {
                                            setUsers(options as unknown as MembersListResponse[]);
                                        }
                                        setShowReportTable(false);
                                    }}
                                    components={
                                        {
                                            ValueContainer: StackedValueContainer,
                                            MultiValueLabel: SelectAvatarMultiValueLabel({}),
                                        } as unknown as SelectComponents
                                    }
                                    onDataLoaded={(users) => {
                                        if (!allUsers.length && !isUsersLoaded) {
                                            setUsers(users);
                                            setAllUsers(users);
                                            setAllUsersCount(users.length);
                                            setIsUsersLoaded(true);
                                        }
                                    }}
                                    isDataLoaded={isUsersLoaded}
                                    allPlaceholder={users.length > 0 && allUsersCount === users.length}
                                    selectedUsersDefault={users}
                                    showStatusFilter={true}
                                    isSingleSelect={false}
                                    isMulti={true}
                                />
                            </FormGroup>
                            <FormGroup className="!mb-0">
                                <Label>Доступ на текущий момент</Label>
                                <ComboBox
                                    onChange={(value) => {
                                        setAccess(value as { value: StatisticsReportAccess; label: string });
                                        setShowReportTable(false);
                                    }}
                                    value={access}
                                    options={accessOptions}
                                    isSearchable={false}
                                    isCreatable={false}
                                    isClearable={false}
                                />
                            </FormGroup>
                            <FormGroup className="!mb-0">
                                <Label>Статус прохождения теста</Label>
                                <ComboBox
                                    onChange={(value) => {
                                        setProgress(value as { value: ProgressStatus; label: string });
                                        setShowReportTable(false);
                                    }}
                                    value={progress}
                                    options={progressOptions}
                                    isSearchable={false}
                                    isCreatable={false}
                                    isClearable={false}
                                />
                            </FormGroup>
                            <FormGroup className="!mb-0">
                                <Label>Собрать статистику</Label>
                                <ComboBox
                                    onChange={(value) => {
                                        setAttempts(value as { value: StatisticsAttempts; label: string });
                                        setShowReportTable(false);
                                    }}
                                    value={attempts}
                                    options={attemptsOptions}
                                    isSearchable={false}
                                    isCreatable={false}
                                    isClearable={false}
                                />
                            </FormGroup>
                            <FormGroup className="!mb-0">
                                <Label>Статус участников</Label>
                                <ComboBox
                                    onChange={(value) => {
                                        setUsersStatus(value as { value: StatisticsUsersStatus; label: string });
                                        setShowReportTable(false);
                                    }}
                                    value={usersStatus}
                                    options={usersStatusOptions}
                                    isSearchable={false}
                                    isCreatable={false}
                                    isClearable={false}
                                />
                            </FormGroup>
                        </div>
                    </div>
                    <div className="mt-9">
                        {showReportTable ? (
                            <>
                                <ReportTable
                                    singleTestChoosed={testsValues.length === 1}
                                    onClickLoadReportFile={() => setShowReportSettings(true)}
                                    queryPayload={createPayloadParams()}
                                />
                                <ReportSettings
                                    isActive={showReportSettings}
                                    setIsActive={setShowReportSettings}
                                    settings={reportSettings}
                                    onSubmit={onSubmit}
                                    isReportFileFetching={false}
                                />
                            </>
                        ) : (
                            <Button
                                key={1}
                                onClick={() => createReport()}
                                variant="standard"
                                size={"medium"}
                                color={"primary"}
                                className="w-full"
                                disabled={!users.length}
                            >
                                {`Сформировать отчет по ${users.length} ${numWord(users.length, [
                                    "участнику",
                                    "участникам",
                                    "участникам",
                                ])}`}
                            </Button>
                        )}
                    </div>
                </>
            )}
        </>
    );
};
