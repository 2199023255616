export class BaseRequest {
    public objectValidator?: number;
}

export class BaseIdRequest1 extends BaseRequest {
    public id: string;

    constructor(id: string) {
        super();
        this.id = id;
    }
}
export class BaseIdRequest extends BaseRequest {
    public uuid: string;

    constructor(uuid: string) {
        super();
        this.uuid = uuid;
    }
}

export class BaseIdsRequest extends BaseRequest {
    public ids: string[];

    constructor(ids: string[]) {
        super();
        this.ids = ids;
    }
}

export class BasePaginationRequest extends BaseRequest {
    public page: number;
    public size: number;
    public sort?: string;
    public search?: string;
    public filters?: Record<string, string>;
}

export class BaseIdPaginationRequest extends BasePaginationRequest {
    public uuid: string;
}
