import { BaseRequest } from "../BaseRequest";
import dayjs from "dayjs";

export class UserRequest extends BaseRequest {
    public id?: string;
    public avatarId?: string;
    public jobTitleId?: string;
    public officeId?: string;
    public role: string;
    public roleSections: string[];
    public roleTeams: string[];
    public allTeams?: boolean | null;
    public teamIds: string[];
    public mentorTeamIds: string[];
    public contentManagerTeamIds: string[];
    public login: string;
    public password: string | null = null;
    public firstName: string;
    public lastName: string;
    public middleName: string | null = null;
    public email: string | null = null;
    public phone: string | null = null;
    public defaultAvatarColor?: string | null = null;
    public enabledNotifications: string[];
    public enabledCommenting: boolean;
    public lastModificationDate?: number;
    public companyId?: string;

    getLastModificationDate = () => {
        return this.lastModificationDate ? dayjs(this.lastModificationDate) : null;
    };
}

export class UserPublicPhoneValidationRequest {
    public phone: string;
}

export class UserPhoneValidationRequest extends UserPublicPhoneValidationRequest {
    public editedUserId?: string;
}

export interface IEditUserRequest {
    email?: string | null;
    phone?: string | null;
    allowPush?: boolean;
    allowEmail?: boolean;
    oldPassword?: string | null;
    newPassword?: string | null;
    avatarId?: string | null;
}

export class EditUserRequest extends BaseRequest implements IEditUserRequest {
    public email = null;
    public phone = null;
    public allowPush?: boolean;
    public allowEmail?: boolean;
    public oldPassword = null;
    public newPassword = null;
    //  email, phone, oldPassword, newPassword.
}

export class UserLoginValidationRequest {
    public login: string;

    constructor(login: string) {
        this.login = login;
    }
}

export class UserPublicEmailValidationRequest {
    public email: string;
}

export class UserEmailValidationRequest extends UserPublicEmailValidationRequest {
    public editedUserId?: string;
}
