import React, { ReactNode, Fragment } from "react";
import clsx from "clsx";
import { Transition } from "@headlessui/react";

import { TVoidFunction } from "types";
import { Icon, Icons, Button } from "Uikit";

import { Bar } from "./Bar";
import { Volume } from "./Volume";
import { TOpenedMenu, speedOptions } from "./VideoPlayer";

interface ControlsProps {
    className?: string;
    rewind?: boolean;
    status: string;
    watchedTime: number;
    currentTime: number;
    volume: number;
    getDuration: () => number | undefined;
    getCurrentTime: () => number | undefined;
    onPlayStatusChange: TVoidFunction;
    onFullscreenHandler: TVoidFunction;
    onTimeChange: (time: number) => void;
    onVolumeChange: (value: number) => void;
    children?: ReactNode;
    setIsSettingsOpen: () => void;
}

interface IVideoSettingsProps {
    open: TOpenedMenu;
    setIsSettingsOpen: (openedMenu: TOpenedMenu) => void;
    onSpeedOptionChecked: (speedOption: number) => void;
    speed?: number;
}

export const VideoSettings = ({ open, setIsSettingsOpen, onSpeedOptionChecked, speed = 1 }: IVideoSettingsProps) => {
    const speedOption = speedOptions.find(({ value }) => value === speed);

    return (
        <>
            <Transition
                as={Fragment}
                show={open === "main"}
                enter="transition ease-out duration-150"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="bg-white w-67.5 absolute right-0 bottom-[100%] -translate-y-4 rounded-md overflow-hidden video-settings">
                    <div
                        className="bg-white w-67.5 hover:bg-blue-lightest flex items-center py-2 px-3.5 cursor-pointer"
                        onClick={() => {
                            setIsSettingsOpen("speed");
                        }}
                    >
                        <span>Скорость</span>
                        <div className="inline-flex items-center text-blue-dark ml-auto gap-2">
                            <span>{speedOption?.label}</span>
                            <Icon icon={Icons.ChevronRight} width={16} height={13} color="fill-blue-dark" />
                        </div>
                    </div>
                </div>
            </Transition>
            <Transition
                as={Fragment}
                show={open === "speed"}
                enter="transition ease-out duration-150"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="bg-white w-[172px] absolute right-0 bottom-[100%] -translate-y-4 rounded-md overflow-hidden video-settings">
                    <div
                        className="bg-white w-43 flex items-center gap-2 py-2 px-3.5 cursor-pointer"
                        onClick={() => {
                            setIsSettingsOpen("main");
                        }}
                    >
                        <Icon icon={Icons.ChevronLeft} width={16} height={13} color="fill-blue-dark" />
                        <span className="text-md font-bold text-black">Скорость</span>
                    </div>
                    <div className="p-1 flex flex-col gap-1">
                        {speedOptions.map(({ value, label }) => {
                            return (
                                <div
                                    key={value}
                                    className="bg-white hover:bg-blue-lightest cursor-pointer rounded-md py-1 px-3.5"
                                    onClick={() => {
                                        onSpeedOptionChecked(value);
                                    }}
                                >
                                    <span>{label}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Transition>
        </>
    );
};

export const Controls = ({
    className,
    rewind = true,
    status = "PAUSE",
    watchedTime,
    currentTime,
    volume = 1,
    getDuration,
    getCurrentTime,
    onPlayStatusChange,
    onFullscreenHandler,
    onTimeChange = () => {},
    onVolumeChange = () => {},
    setIsSettingsOpen,
    children,
}: ControlsProps) => {
    return (
        <div
            className={clsx(
                "flex items-center justify-center w-full bg-black-70 transition-opacity duration-200 pr-3 hover:!opacity-100 z-20",
                className,
            )}
            id="video-controls"
        >
            <div className="flex items-center">
                <button
                    className="flex justify-center items-center shrink-0 w-10 h-10 p-0 border-0 m-0 cursor-pointer text-center"
                    onClick={onPlayStatusChange}
                >
                    {status === "PLAY" && <Icon icon={Icons.Pause} width={20} height={20} color="fill-white" />}
                    {status === "PAUSE" && <Icon icon={Icons.PlayFilled} width={20} height={20} color="fill-white" />}
                    {status !== "PLAY" && status !== "PAUSE" && (
                        <Icon icon={Icons.Refresh} width={20} height={20} color="fill-white" />
                    )}
                </button>
            </div>

            <Volume volume={volume} onVolumeUpdate={onVolumeChange} />
            <Bar
                rewind={rewind}
                watchedTime={watchedTime}
                currentTime={currentTime}
                getDuration={getDuration}
                getCurrentTime={getCurrentTime}
                setCurrentTime={onTimeChange}
            />
            <div className="flex items-center relative mr-2">
                <Button
                    onClick={() => {
                        setIsSettingsOpen();
                    }}
                    iconPlacement="center"
                    className="!w-5 !h-5 !bg-transparent !p-0 !rounded-none video-settings"
                    icon={<Icon icon={Icons.AdminMenuIconSettings} width={20} height={20} color="fill-white" />}
                ></Button>
                {/* video settings */}
                {children}
            </div>
            <Button
                onClick={onFullscreenHandler}
                iconPlacement="center"
                className="!w-5 !h-5 !bg-transparent !p-0 !rounded-none"
                icon={<Icon icon={Icons.Maximise} width={20} height={20} color="fill-white" />}
            ></Button>
        </div>
    );
};

Controls.VideoSettings = VideoSettings;
