import React, { useEffect, useState } from "react";
import SelectComponents from "react-select";

import { Button, ComboBox, FormGroup, Label, SelectAvatarMultiValueLabel } from "Uikit";
import { StackedValueContainer } from "Uikit/Forms/SelectCustomComponents/StackedValueContainer";
import Api from "Api";
import { MembersListResponse } from "Api/Responses/UserResponse";
import { StatisticRatingRequest } from "Api/Requests/StatisticRequest";
import { numWord } from "helpers/numWord";
import { IRequestSettingsItem, ReportSettings } from "Pages/Admin/Statistics/ReportSettings/ReportSettings";

import { ReportUserSelect } from "../../../ReportUserSelect/ReportUserSelect";
import { ReportTable } from "./ReportTable";

export enum ReportPeriod {
    LAST_MONTH = "LAST_MONTH",
    LAST_THREE_MONTHS = "LAST_THREE_MONTHS",
    LAST_YEAR = "LAST_YEAR",
    ALL = "ALL",
}

export const periodOptions = [
    {
        value: ReportPeriod.LAST_MONTH,
        label: "Последний месяц",
    },
    {
        value: ReportPeriod.LAST_THREE_MONTHS,
        label: "Последние 3 месяца",
    },
    {
        value: ReportPeriod.LAST_YEAR,
        label: "Последний год",
    },
    {
        value: ReportPeriod.ALL,
        label: "За все время",
    },
];

export const getReportPeriodStart = (periodValue: ReportPeriod) => {
    const date = new Date();

    date.setHours(0, 0, 0, 0);

    if (periodValue === ReportPeriod.LAST_MONTH) {
        date.setDate(date.getDate() - 30);
    }
    if (periodValue === ReportPeriod.LAST_THREE_MONTHS) {
        date.setDate(date.getDate() - 90);
    }
    if (periodValue === ReportPeriod.LAST_YEAR) {
        date.setDate(date.getDate() - 365);
    }

    return +date / 1000;
};

const reportSettings = [
    {
        title: "Участник",
        key: "user",
        items: [
            {
                title: "Все",
                value: "all",
                checked: true,
            },
            {
                title: "ФИО",
                value: "name",
                checked: true,
            },
            {
                title: "Логин",
                value: "login",
                checked: true,
            },
        ],
    },
    {
        title: "Ачивки",
        key: "achievement",
        items: [
            {
                title: "Все",
                value: "all",
                checked: true,
            },
            {
                title: "Всего получено",
                value: "total",
                checked: true,
            },
            {
                title: "Положительных",
                value: "positive",
                checked: true,
            },
            {
                title: "Отрицательных",
                value: "negative",
                checked: true,
            },
        ],
    },
    {
        title: "Рейтинг",
        key: "rating",
        items: [
            {
                title: "Все",
                value: "all",
                checked: true,
            },
            {
                title: "Всего баллов",
                value: "totalRating",
                checked: true,
            },
            {
                title: "Баллы за программы",
                value: "programRating",
                checked: true,
            },
            {
                title: "Баллы за курсы",
                value: "courseRating",
                checked: true,
            },
            {
                title: "Баллы за тесты",
                value: "quizRating",
                checked: true,
            },
            {
                title: "Баллы за задания",
                value: "exerciseRating",
                checked: true,
            },
            {
                title: "Баллы за ачивки",
                value: "achievementRating",
                checked: true,
            },
            {
                title: "Место в общем рейтинге",
                value: "totalRank",
                checked: true,
            },
        ],
    },
];

export const RatingComplexity = () => {
    const [users, setUsers] = useState<MembersListResponse[]>([]);
    const [allUsersCount, setAllUsersCount] = useState(0);
    const [showReportsTable, setShowReportsTable] = useState(false);
    const [showReportSettings, setShowReportSettings] = useState(false);
    const [period, setPeriod] = useState<{ value: ReportPeriod; label: string }>(periodOptions[2]);

    useEffect(() => {
        if (users.length === 0) {
            setPeriod(periodOptions[2]);
        }
    }, [users]);

    const createReport = () => {
        setShowReportsTable(true);
    };

    const createPayloadParams = () => {
        const payload: StatisticRatingRequest = {
            userIds: users.map(({ id }) => id),
        };

        if (period.value !== ReportPeriod.ALL) {
            payload.fromDate = getReportPeriodStart(period.value);
        }

        return payload;
    };

    const onSubmit = async (settingsChecked: IRequestSettingsItem[]) => {
        const request: any = {};

        request.params = createPayloadParams();

        settingsChecked.forEach(({ key, items }: IRequestSettingsItem) => {
            request[key] = items
                .filter((item) => item.value !== "all")
                .reduce((prev, cur) => {
                    return {
                        ...prev,
                        [cur.value]: cur.checked,
                    };
                }, {});
        });

        setShowReportSettings(false);

        try {
            await Api.Statistic.GenerateRatingStatisticReportFile(request);
        } catch (error: any) {
            console.log(error);
        }
    };

    return (
        <>
            <ReportUserSelect
                className="mt-5"
                value={users}
                onSubmit={(users) => {
                    setUsers(
                        users.map((user) => {
                            const { firstName, lastName, avatarId, defaultAvatarColor } = user;
                            return {
                                ...user,
                                value: {
                                    avatarId,
                                    firstName,
                                    lastName,
                                    defaultAvatarColor,
                                },
                                label: `${lastName} ${firstName}`,
                            };
                        }),
                    );
                    setShowReportsTable(false);
                }}
                onChange={(options, action) => {
                    if (action && action.action === "clear") {
                        setUsers([]);
                    }
                    if (action && action.action === "remove-value") {
                        setUsers(options as unknown as MembersListResponse[]);
                    }
                    setShowReportsTable(false);
                }}
                components={
                    {
                        ValueContainer: StackedValueContainer,
                        MultiValueLabel: SelectAvatarMultiValueLabel({}),
                    } as unknown as SelectComponents
                }
                onDataLoaded={(users) => {
                    setAllUsersCount(users.length);
                }}
                allPlaceholder={users.length > 0 && allUsersCount === users.length}
                selectedUsersDefault={users}
                showStatusFilter={true}
                isSingleSelect={false}
                isMulti={true}
            />
            {users.length > 0 && (
                <>
                    <div className="mt-7 flex-wrap relative z-[70]">
                        <div className="grid items-center gap-6.5 grid-cols-2">
                            <FormGroup className="!mb-0">
                                <Label>Период отчета</Label>
                                <ComboBox
                                    onChange={(value) => {
                                        setPeriod(value as { value: ReportPeriod; label: string });
                                        setShowReportsTable(false);
                                    }}
                                    value={period}
                                    options={periodOptions}
                                    isSearchable={false}
                                    isCreatable={false}
                                    isClearable={false}
                                />
                            </FormGroup>
                        </div>
                    </div>
                    <div className="mt-9">
                        {showReportsTable ? (
                            <>
                                <ReportTable
                                    onClickLoadReportFile={() => setShowReportSettings(true)}
                                    queryPayload={createPayloadParams()}
                                />
                                <ReportSettings
                                    isActive={showReportSettings}
                                    setIsActive={setShowReportSettings}
                                    settings={reportSettings}
                                    onSubmit={onSubmit}
                                    isReportFileFetching={false}
                                />
                            </>
                        ) : (
                            <Button
                                key={1}
                                onClick={() => createReport()}
                                variant="standard"
                                size={"medium"}
                                color={"primary"}
                                className="w-full"
                            >
                                {`Сформировать отчет по ${users.length} ${numWord(users.length, [
                                    "участнику",
                                    "участникам",
                                    "участникам",
                                ])}`}
                            </Button>
                        )}
                    </div>
                </>
            )}
        </>
    );
};
