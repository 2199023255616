import { Expose, Type } from "class-transformer";
import { ProgressStatus, ResourceType } from "Enums";
import { FILES_TYPE_SHORT_NAME } from "constants/attachments";

export class FavoriteListResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public resourceSolutionId: string;

    @Expose()
    @Type(() => String)
    public resourceId: string;

    @Expose()
    @Type(() => String)
    public componentId: string;

    @Expose()
    @Type(() => String)
    public courseId: string;

    @Expose()
    @Type(() => String)
    public title: string;

    @Expose()
    @Type(() => String)
    public logoId: string;

    @Expose()
    @Type(() => String)
    public state: string;

    @Expose()
    @Type(() => String)
    public type: ResourceType;

    @Expose()
    @Type(() => String)
    public materialType: ResourceType;

    @Expose()
    @Type(() => String)
    public materialFileType: keyof typeof FILES_TYPE_SHORT_NAME;

    @Expose()
    @Type(() => String)
    public progressStatus: ProgressStatus;

    @Expose()
    @Type(() => Number)
    public addedToFavoritesTime: number;

    @Expose()
    @Type(() => Number)
    public passingNumber: number;
}

export class FavoriteFilterInfoResponse {
    @Expose()
    @Type(() => String)
    public materialFileTypes: string[];
}
