import React, { useState, useEffect, useRef } from "react";
import { getCookie } from "typescript-cookie";
import { FileUpload, Icon, Icons, Tooltip, flash } from "Uikit";
import { Section } from "Uikit/Forms/Section";
import { VideoPlayer } from "Components/VideoPlayer";
import { BadRequestResponse, ErrorCode } from "Api/BaseResponse";
import { FileReadResponse } from "Api/Responses/FileResponse";
import { MaterialFile } from "Api/Responses/MaterialResponse";
import { useVideoPlayer } from "hooks/useVideoPlayer";
import { useUpload } from "hooks/useUpload";
import { FileUploadType, UIErrorMessages, WSEventVideoActionTypes, WssMessageType } from "Enums";
import NotificationConnect, { NotificationSubscribe } from "Api/Wss/Notifications";
import { fetchFileInfo } from "helpers/fetchFileInfo";
import { useDialog } from "hooks/useDialog";
import { Confirmation } from "Components/Confirmation/Confirmation";
import Api from "Api";
import { WssMessage } from "types/WssMessage";

interface MaterialVideoProps {
    attachment?: MaterialFile;
    videoUrl?: string;
    isAllowRewind?: boolean;
    onChange: (field: string, value: any) => void;
    onVideoDurationChange: (duration: number) => void;
    setIsSubmitDisabled: (isSubmitDisabled: boolean) => void;
    materialId?: string;
    addDiscardedVideoId: (fileId: string) => void;
}

export const MaterialVideo = ({
    attachment,
    videoUrl,
    onChange,
    onVideoDurationChange,
    setIsSubmitDisabled,
    materialId,
    addDiscardedVideoId,
}: MaterialVideoProps) => {
    // const playerRef = useRef<any>();
    const { upload } = useUpload();
    const [file, setFile] = useState<any>();
    const [fileUploadData, setFileUploadData] = useState<any>();
    const [videoProcessingActionType, setVideoProcessingActionType] = useState<WSEventVideoActionTypes | null>(null);
    const [isInitialized, setIsInitialized] = useState(false);
    const attachmentRef = useRef<MaterialFile | null | undefined>(attachment);
    const showPlayerDelay = attachmentRef.current ? 0 : 1000;
    const { duration, getPlayerProps } = useVideoPlayer({
        fluid: true,
        controls: true,
        rewind: true,
        src: videoUrl ?? null,
    });
    const { dialogState, openDialog, closeDialog } = useDialog();

    const hostname = window.location.hostname.includes("localhost")
        ? "https://lms-prod-api.gran-soft.ru"
        : window.location.protocol + "//" + window.location.hostname;

    const showOptimizationModal = (file: any, materialId?: string) => {
        openDialog({
            title: "Оптимизация файла",
            content:
                "Файл в процессе оптимизации. Это может занять много времени. Можно оптимизировать файл самостоятельно по инструкции и загрузить заново или продолжить оптимизировать в системе.",
            closeBtnText: "Отмена",
            submitBtnText: "Продолжить",
            onRequestClose: () => {
                onChange?.(
                    "fileId",
                    attachmentRef.current ? (attachmentRef?.current as unknown as FileReadResponse)?.id : null,
                );
                onChange?.("file", attachmentRef.current ? attachmentRef.current : null);
                setFileUploadData(null);
                onChange?.("fileId", null);
                onChange?.("file", null);
                setFile(null);

                // Удаляем файл из очереди на конвертацию только если он ещё не привязан ни к одной сущности (материалу)
                if (!materialId) {
                    Api.Upload.DiscardVideoFile(file.id);
                } else {
                    addDiscardedVideoId(file.id);
                }

                closeDialog();
            },
            onRequestSubmit: () => {
                closeDialog();
            },
        });
    };

    function uploadFile(file: any) {
        if (!file) {
            return;
        }

        onChange?.("fileId", null);
        onChange?.("file", null);
        setIsSubmitDisabled(true);

        const onUpdate = async (file: any) => setFileUploadData(file);

        const onError = ({ errorCode }: BadRequestResponse) => {
            if (errorCode === ErrorCode.FILE_CONTENT_TYPE_ERROR) {
                onChange?.(
                    "fileId",
                    attachmentRef.current ? (attachmentRef?.current as unknown as FileReadResponse)?.id : null,
                );
                onChange?.("file", attachmentRef.current ? attachmentRef.current : null);
                flash.error(UIErrorMessages.FILE_LOADING_ERROR);
                setFile(null);
                setFileUploadData(null);
                return;
            }
        };
        const onFinished = async (file: any) => {
            onChange?.("fileId", file.serverData.id);
            onChange?.("file", file.serverData);
            attachmentRef.current = file.serverData;
            setFile(file.serverData);
            setIsSubmitDisabled(false);
        };

        const extensionIdx = file.name.lastIndexOf(".");
        file["extension"] = file.name.slice(extensionIdx + 1);

        const u = upload({
            file: file,
            onUpdate,
            onFinished,
            onError,
            fileUploadType: FileUploadType.MATERIAL_VIDEO,
            socketId: getCookie("USER_SESSION"),
        });

        // u.start()
        // .then();
        const start = u.start();
        start.catch((error) => {
            const knownError = error as BadRequestResponse;
            if (
                [ErrorCode.CORRUPT_FILE_ERROR, ErrorCode.FILE_EXTENSION_ERROR].includes(
                    String(knownError.errorCode) as ErrorCode,
                )
            ) {
                flash.error(UIErrorMessages.FILE_LOADING_ERROR);
            }
            setFile(null);
            return;
        });

        setFile(u);
    }

    function cancelUpload() {
        file?.cancel();

        onChange?.("fileId", null);
        onChange?.("file", null);
        setFile(null);

        // Удаление файла с очереди на конвертацию
        if ((file?.file?.type || file?.contentType)?.includes("video")) {
            if (!materialId && file.disposableLink) {
                Api.Upload.DiscardVideoFile(file.id);
            } else {
                addDiscardedVideoId(file.id);
            }
        }
    }

    useEffect(() => {
        if (duration) {
            onVideoDurationChange(Math.ceil(duration / 60));
        }
    }, [duration, onVideoDurationChange]);

    // Флаг готовности устанавливается по таймауту на случай, если уведомление придёт раньше загрузки видео в lms-upload
    // Не смотря на то, что сейчас такая возможность на бэкенде уже исключена
    if (!isInitialized) {
        NotificationConnect({
            onWSCloseCallback: () => {
                if (attachment?.id) {
                    Api.Upload.DiscardVideoFile(attachment?.id);
                }
            },
        });
        NotificationSubscribe(async (message: WssMessage) => {
            if (message.messageType === WssMessageType.NOTIFICATION) {
                const { actionType } = message.body;
                if (actionType === WSEventVideoActionTypes.PROCESSING_COMPLETE) {
                    setVideoProcessingActionType(WSEventVideoActionTypes.PROCESSING_COMPLETE);
                    closeDialog();
                }
                if (actionType === WSEventVideoActionTypes.PROCESSING_IN_PROGRESS) {
                    setVideoProcessingActionType(WSEventVideoActionTypes.PROCESSING_IN_PROGRESS);
                    showOptimizationModal(attachment, materialId);
                }
            }
        });
        setIsInitialized(true);
    }

    useEffect(() => {
        if (attachment?.id && attachment?.disposableLink && videoUrl) {
            fetchFileInfo(attachment.id, (isProcessed) => {
                setTimeout(() => {
                    setVideoProcessingActionType(
                        isProcessed
                            ? WSEventVideoActionTypes.PROCESSING_COMPLETE
                            : WSEventVideoActionTypes.PROCESSING_IN_PROGRESS,
                    );
                }, showPlayerDelay);
            });
        }
    }, [attachment, videoUrl, showPlayerDelay]);

    return (
        <Section isRequired label="Видео">
            <Confirmation {...dialogState} />
            {(!videoUrl || (videoUrl && videoProcessingActionType !== WSEventVideoActionTypes.PROCESSING_COMPLETE)) && (
                <FileUpload
                    attachment={fileUploadData || attachment}
                    onChange={uploadFile}
                    onCancelUpload={cancelUpload}
                    accept={{ "video/mp4": [] }}
                    maxSize={2147483648}
                    acceptDescription="MP4 до 2 ГБ"
                    videoProcessingStatus={videoProcessingActionType}
                    showOptimizationModal={() => {
                        showOptimizationModal(attachment, materialId);
                    }}
                />
            )}
            {!!videoUrl &&
                attachment?.id &&
                attachment?.disposableLink &&
                videoProcessingActionType === WSEventVideoActionTypes.PROCESSING_COMPLETE && (
                    <div
                        className="relative w-full h-full group flex justify-center items-center ml-auto rounded-lg"
                        id="adminNewMaterialVideoContent"
                    >
                        <VideoPlayer
                            {...getPlayerProps()}
                            onError={() => {
                                onChange?.("fileId", null);
                                onChange?.("file", null);
                                setFile(null);
                            }}
                        >
                            <div className="group-hover:opacity-100 opacity-0 absolute z-10 flex top-5 right-5 space-x-1">
                                <Tooltip content="Скачать" interactive={false}>
                                    <a
                                        href={hostname + "/service/lms-upload/api/file/download/" + attachment?.id}
                                        download={attachment?.name ?? "video.mp4"}
                                        className="flex items-center justify-center w-8 h-8 rounded-lg bg-black cursor-pointer"
                                        id="adminNewMaterialVideoLinkDownload"
                                    >
                                        <Icon icon={Icons.Download} width="20px" height="20px" color="fill-white" />
                                    </a>
                                </Tooltip>
                                <Tooltip content="Загрузить" interactive={false}>
                                    <div className="relative flex items-center justify-center w-8 h-8 rounded-lg bg-black cursor-pointer">
                                        <label className="absolute top-0 left-0 right-0 bottom-0 opacity-0 cursor-pointer">
                                            <input
                                                className="hidden"
                                                type="file"
                                                accept="video/mp4"
                                                onChange={async (e) => {
                                                    uploadFile(e.target.files?.[0]);
                                                    e.target.files = null;
                                                    e.target.value = "";
                                                }}
                                                id="adminNewMaterialVideoInputDownload"
                                            />
                                        </label>
                                        <Icon
                                            icon={Icons.Upload}
                                            width="20px"
                                            height="20px"
                                            color="fill-white"
                                            className="cursor-pointer"
                                        />
                                    </div>
                                </Tooltip>
                            </div>
                        </VideoPlayer>
                    </div>
                )}
        </Section>
    );
};

// Сохранённый элемент вёрстки. Пока не используется.
// eslint-disable-next-line
// const _PlayerBackground = () => {
//     return (
//         <div className="group relative w-full rounded-lg bg-gray-dark">
//             <div className="group-hover:opacity-100 opacity-0 absolute z-10 flex top-5 right-5 space-x-1">
//                 {[Icons.Download, Icons.Upload, Icons.Delete].map((icon, idx) => {
//                     return (
//                         <div
//                             key={idx}
//                             className="flex items-center justify-center w-8 h-8 rounded-lg bg-black cursor-pointer"
//                         >
//                             <Icon icon={icon} width="20px" height="20px" color="fill-white" />
//                         </div>
//                     );
//                 })}
//             </div>

//             {/*    Плеер здесь */}
//         </div>
//     );
// };
