import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { ContentLayout } from "Containers/ContentLayout";
import { Button, Icon, Icons, Loader } from "Uikit";
import { ScormLMSApi } from "./ScormLMSApi";
import { ScormLMSApi2004 } from "./ScormLMSApi2004";
import Api from "Api/index";
import { ScormProgressRequest } from "Api/Requests/ScormProgressRequest";
import { CMIElement, CMIElement2004 } from "./ScormModel";

declare global {
    interface Window {
        API: ScormLMSApi;
        API_1484_11: ScormLMSApi2004;
    }
}

export const ScormPlayer = React.memo(() => {
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const [iframeLoading, setIframeLoading] = useState(true);

    const [initialScormProgress, setInitialScormProgress] = useState<any>("");

    const { fileId } = useParams<{ fileId: string }>();
    const storedMaterial = sessionStorage.getItem("currentMaterial");
    const material = storedMaterial ? JSON.parse(storedMaterial) : null;
    const scorm = material ? material.scorm : null;

    const courseId = sessionStorage.getItem("courseId");

    const storedCurrentCourseComponent = sessionStorage.getItem("currentCourseComponent");
    const currentCourseComponent = storedCurrentCourseComponent ? JSON.parse(storedCurrentCourseComponent) : null;

    const scormViewMode = sessionStorage.getItem("scormViewMode");

    const currentModule =
        scorm?.organizations[0]?.items[0]?.resources[0]?.href ||
        scorm?.organizations[0]?.items[0]?.children[0]?.resources[0]?.href;

    const saveProgress = useCallback(async () => {
        if (scormViewMode === "admin") {
            return;
        }

        const progressData = {
            scormData12: window.API.cmiElement,
            scormData2004: window.API_1484_11.cmiElement,
        };

        const request = new ScormProgressRequest();
        request.materialId = material.id;
        request.componentId = currentCourseComponent.id;
        request.courseId = courseId ?? "";
        request.scoreRewriteLimit = material.scoreRewriteLimit;
        request.version = scorm.schemaVersion;
        request.complexity = material.complexity;
        if (scorm.schemaVersion === "1" || scorm.schemaVersion === "1.1" || scorm.schemaVersion === "1.2") {
            request.completionStatus = progressData.scormData12.core.lesson_status;
            request.score = progressData.scormData12.core.score.raw;
            request.progressData = JSON.stringify(progressData.scormData12);
        } else {
            request.completionStatus = progressData.scormData2004.completion_status;
            request.successStatus = progressData.scormData2004.success_status;
            request.score = progressData.scormData2004.score.raw;
            request.progressData = JSON.stringify(progressData.scormData2004);
        }

        if (request.progressData === initialScormProgress) {
            return;
        }

        try {
            await Api.ScormProgress.SaveProgress(request);
        } catch (error) {
            console.log(error);
        }
    }, [scormViewMode, material, scorm.schemaVersion, currentCourseComponent?.id, courseId, initialScormProgress]);

    const getUserProgress = useCallback(async () => {
        if (scormViewMode === "admin") {
            return;
        }

        let userProgress;
        try {
            userProgress = await Api.ScormProgress.GetProgress(material.id);
        } catch (error) {
            console.log(error);
        }
        return userProgress;
    }, [scormViewMode, material.id]);

    const toggleFullscreen = () => {
        if (iframeRef.current) {
            if (document.fullscreenElement) {
                document.exitFullscreen().then();
            } else {
                iframeRef.current.requestFullscreen().catch((err) => {
                    console.error(`Ошибка при попытке входа в полноэкранный режим: ${err.message} (${err.name})`);
                });
            }
        }
    };

    const returnToInitialState = () => {
        window.API.isReloadInProgress = true;
        window.API_1484_11.isReloadInProgress = true;
        if (iframeRef.current) {
            iframeRef.current.setAttribute("src", `/service/lms-upload/api/scorm/${fileId}/content/${currentModule}`);
        }
        window.API.Reset(initialScormProgress);
        window.API_1484_11.Reset(initialScormProgress);
        window.API.isReloadInProgress = false;
        window.API_1484_11.isReloadInProgress = false;
    };

    const onIframeLoaded = () => {
        setIframeLoading(false);
    };

    const handleClose = () => {
        saveProgress().then(() => {
            window.close();
        });
    };

    useEffect(() => {
        const fetchAndInitialize = async () => {
            let initialProgressData = null;

            if (
                scormViewMode !== "admin" &&
                (currentCourseComponent.status === "IN_PROGRESS" || currentCourseComponent.status === "PASSED")
            ) {
                const initialDataResponse = await getUserProgress();
                const initialData = initialDataResponse ? initialDataResponse.progressData : null;

                if (initialData) {
                    window.API = new ScormLMSApi(JSON.parse(initialData) as CMIElement);
                    window.API_1484_11 = new ScormLMSApi2004(JSON.parse(initialData) as CMIElement2004);
                } else {
                    window.API = new ScormLMSApi();
                    window.API_1484_11 = new ScormLMSApi2004();
                }

                initialProgressData = initialData;
            } else {
                window.API = new ScormLMSApi();
                window.API_1484_11 = new ScormLMSApi2004();
            }

            window.API.onFinish = saveProgress;
            window.API.isReloadInProgress = false;

            window.API_1484_11.onFinish = saveProgress;
            window.API_1484_11.isReloadInProgress = false;

            setInitialScormProgress(initialProgressData);
        };

        if (iframeLoading) {
            fetchAndInitialize().then();
        }
    }, [getUserProgress, currentCourseComponent, saveProgress, iframeLoading, setInitialScormProgress, scormViewMode]);

    return (
        <ContentLayout className="!fixed top-0 left-0 flex flex-col items-center !pb-0 !px-0 z-[100] w-full h-full bg-white">
            <div className="flex flex-col w-full h-full">
                <div className="flex-grow overflow-hidden flex">
                    <div className="flex flex-col w-full !bg-[#F8F9FC]">
                        <div className="flex justify-between items-center px-6.5 py-4">
                            <h6 className="text-lg">{material?.settings?.title || material.title}</h6>
                            <Button
                                key={1}
                                onClick={handleClose}
                                variant="outline"
                                icon={<Icon icon={Icons.Close} color="fill-gray" width={30} height={30} />}
                                iconPlacement={"center"}
                                size={"medium"}
                                shape="square"
                                color="common"
                                className="border-[#EAEDF3]"
                            ></Button>
                        </div>
                        <div className="flex-grow flex flex-col relative rounded-lg">
                            {iframeLoading && (
                                <div className="top-1/2 left-1/2 absolute -translate-x-1/2 -translate-y-1/2">
                                    <Loader />
                                </div>
                            )}
                            {currentModule && (
                                <iframe
                                    ref={iframeRef}
                                    src={`/service/lms-upload/api/scorm/${fileId}/content/${currentModule}`}
                                    width="100%"
                                    onLoad={onIframeLoaded}
                                    className="border-0 bg-[#9EA6AC] px-3 flex-grow"
                                ></iframe>
                            )}
                            <div className="py-4 px-6.5 !bg-[#CACFD4]">
                                <div className="flex space-x-4">
                                    <div className="flex">
                                        <Button
                                            key={5}
                                            onClick={toggleFullscreen}
                                            variant="standard"
                                            icon={
                                                <Icon icon={Icons.Maximise2} color="fill-gray" width={17} height={17} />
                                            }
                                            iconPlacement={"center"}
                                            shape={"square"}
                                            size={"medium"}
                                            color={"secondary"}
                                            className="border-[#BCC2C8] !bg-white !text-white"
                                        ></Button>
                                    </div>
                                    <div className="flex">
                                        <Button
                                            key={5}
                                            onClick={returnToInitialState}
                                            variant="standard"
                                            icon={
                                                <Icon
                                                    icon={Icons.ArrowsExchangeAlt}
                                                    color="fill-gray"
                                                    width={17}
                                                    height={17}
                                                />
                                            }
                                            iconPlacement={"left"}
                                            shape={"default"}
                                            size={"medium"}
                                            color={"secondary"}
                                            className="border-[#BCC2C8] !bg-white !text-gray !px-6"
                                        >
                                            Начать заново
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ContentLayout>
    );
});
