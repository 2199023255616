import React from "react";
import { Button, Modal } from "Uikit";
import { TVoidFunction } from "types";

type TBtnColor = "common" | "primary" | "secondary" | "success" | "warning" | "caution" | "danger";
type TBtnVariant = "standard" | "outline" | "link";

interface ConfirmationProps {
    isOpen: boolean;
    title: string;
    content: any;
    description?: string;
    closeBtnText?: string;
    closeBtnColor?: TBtnColor;
    closeBtnVariant?: TBtnVariant;
    submitBtnText: string;
    submitBtnColor?: TBtnColor;
    cancelBtnText?: string;
    onRequestClose: TVoidFunction;
    onRequestSubmit?: TVoidFunction;
    onRequestCancel?: TVoidFunction;
    onCloseModal?: TVoidFunction;
    requestSubmitDisabled?: boolean;
    onRequestSubmitAdd?: TVoidFunction;
    submitBtnAddText?: string;
    submitBtnAddColor?: TBtnColor;
    requestSubmitAddDisabled?: boolean;
}

export const Confirmation = ({
    isOpen,
    title,
    content,
    description,
    closeBtnText,
    closeBtnColor = "secondary",
    closeBtnVariant = "outline",
    submitBtnText,
    submitBtnColor = "primary",
    cancelBtnText,
    onRequestClose,
    onRequestSubmit,
    onRequestCancel,
    onCloseModal,
    requestSubmitDisabled = false,
    onRequestSubmitAdd,
    submitBtnAddText,
    submitBtnAddColor = "primary",
    requestSubmitAddDisabled = false,
}: ConfirmationProps) => {
    return (
        <Modal
            isOpen={isOpen}
            setIsOpen={onCloseModal ?? onRequestClose}
            title={title}
            description={description}
            size="extraMedium"
        >
            <Modal.Body>{content}</Modal.Body>

            <Modal.Footer>
                {closeBtnText && (
                    <Button
                        className="border-[#E6E9ED]"
                        variant={closeBtnVariant}
                        color={closeBtnColor}
                        onClick={onRequestClose}
                        id="confirmationModalBtnClose"
                    >
                        {closeBtnText}
                    </Button>
                )}
                {onRequestSubmit && (
                    <Button
                        onClick={onRequestSubmit}
                        color={submitBtnColor}
                        disabled={requestSubmitDisabled}
                        id="confirmationModalBtnOk"
                    >
                        {submitBtnText}
                    </Button>
                )}
                {onRequestSubmitAdd && (
                    <Button
                        onClick={onRequestSubmitAdd}
                        color={submitBtnAddColor}
                        disabled={requestSubmitAddDisabled}
                        id="confirmationModalBtnAddOk"
                    >
                        {submitBtnAddText}
                    </Button>
                )}
                {onRequestCancel && (
                    <Button onClick={onRequestCancel} color="danger" id="confirmationModalBtnCancel">
                        {cancelBtnText}
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};
