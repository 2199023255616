import React, { ChangeEvent, useEffect, useState } from "react";
import { Icon, Icons } from "Uikit/Icon/Icon";
import { Input, numberRegexp } from "Uikit/Forms/Input";
import { Button } from "Uikit/Button/Button";

interface IApproxTime {
    canEdit: boolean;
    time: number;
    onChange: (time: number) => void;
}
export const ApproxTime = ({ canEdit, time, onChange }: IApproxTime) => {
    const [isEdit, setIsEdit] = useState<boolean>(false);

    const [hours, setHours] = useState(Math.floor(time / 60));
    const [minutes, setMinutes] = useState(Math.floor(time % 60));

    useEffect(() => {
        if (isEdit) {
            setHours(Math.floor(time / 60));
            setMinutes(Math.floor(time % 60));
        }
    }, [isEdit, time]);

    const onHoursChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (minutes === 0 && +e.target.value === 0) {
            setMinutes(1);
        }

        setHours(+e.target.value);
    };

    const onMinutesChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (hours === 0 && +e.target.value === 0) {
            setMinutes(1);
        } else {
            setMinutes(+e.target.value);
        }
    };

    return (
        <>
            {!isEdit && (
                <div className="flex items-center">
                    Ориентировочное время на прохождение {Math.floor(time / 60)} час. {Math.floor(time % 60)} мин.
                    {canEdit && (
                        <div onClick={() => setIsEdit(true)}>
                            <Icon
                                className="ml-1.5 cursor-pointer"
                                icon={Icons.Pencil}
                                width={16}
                                height={16}
                                color="fill-blue-drk hover:fill-blue-hover"
                            />
                        </div>
                    )}
                </div>
            )}
            {isEdit && (
                <div className="mb-1.5 flex items-center">
                    <div className="mr-2 max-w-17.5 2xl:max-w-22.5">
                        <Input min={0} max={1000} cutRegExp={numberRegexp} value={hours} onChange={onHoursChange} />
                    </div>
                    <span className="mr-3">часов</span>
                    <div className="mr-2 max-w-17.5">
                        <Input min={0} max={59} cutRegExp={numberRegexp} value={minutes} onChange={onMinutesChange} />
                    </div>
                    <span className="mr-5">минут</span>
                    <Button className="mr-4" variant="outline" color="secondary" onClick={() => setIsEdit(false)}>
                        Отменить
                    </Button>
                    <Button
                        onClick={() => {
                            if (time === hours * 60 + minutes) {
                                setIsEdit(false);
                                return;
                            }
                            onChange(hours * 60 + minutes);
                            setIsEdit(false);
                        }}
                    >
                        Применить
                    </Button>
                </div>
            )}
        </>
    );
};
