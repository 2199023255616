import React, { useCallback, useEffect, useState } from "react";
import { FormikErrors } from "formik";
import { getCookie } from "typescript-cookie";

import { Icon, Icons, Section, flash } from "Uikit";
import TextEditor from "Uikit/Editor/Editor";
import { FileViewDialog } from "Uikit";
import { FileReadResponse } from "Api/Responses/FileResponse";
import { useUpload } from "hooks/useUpload";
import { BadRequestResponse, ErrorCode } from "Api/BaseResponse";
import { FileUploadType, UIErrorMessages } from "Enums";
import { MaterialArticleAttachment } from "./MaterialArticleAttachment";
import { getFileSizeErrorMessage } from "helpers/file";
import Api from "Api";

interface MaterialArticleProps {
    content: string;
    // _attachments: FileReadResponse[];
    errors?: FormikErrors<{ content: string; attachments: any }>;
    isSubmitted: boolean;
    attachments?: any;
    onChange: (field: string, value: any) => void;
    onCharCountChange: (charCount: number) => void;
    setEditorInstance: (editor: any) => void;
    setIsSubmitDisabled: (isSubmitDisabled: boolean) => void;
    materialId?: string;
    addDiscardedVideoId: (fileId: string) => void;
}

const acceptedFileTypes = [
    { mimeType: "audio/mpeg", extension: "mp3" },
    { mimeType: "audio/wav", extension: "wav" },
    { mimeType: "application/pdf", extension: "pdf" },
    { mimeType: "text/plain", extension: "txt" },
    { mimeType: "application/msword", extension: "doc" },
    { mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document", extension: "docx" },
    { mimeType: "application/vnd.ms-excel", extension: "xls" },
    { mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", extension: "xlsx" },
    { mimeType: "application/vnd.ms-powerpoint", extension: "ppt" },
    { mimeType: "application/mspowerpoint", extension: "pps" },
    { mimeType: "application/vnd.openxmlformats-officedocument.presentationml.presentation", extension: "pptx" },
    { mimeType: "application/vnd.openxmlformats-officedocument.presentationml.slideshow", extension: "ppsx" },
    { mimeType: "image/jpeg", extension: "jpg" },
    { mimeType: "image/jpeg", extension: "jpeg" },
    { mimeType: "image/png", extension: "png" },
    { mimeType: "video/mp4", extension: "mp4" },
    // Прочие форматы видео ограничены требованиями документации
    // { mimeType: "video/x-msvideo", extension: "avi" },
    // { mimeType: "video/avi", extension: "avi" },
    // { mimeType: "video/x-ms-wmv", extension: "wmv" },
    // { mimeType: "video/quicktime", extension: "mov" },
    // { mimeType: "video/x-quicktime", extension: "mov" },
    { mimeType: "application/zip", extension: "zip" },
];

export const MaterialArticle = ({
    content,
    attachments = [],
    errors,
    isSubmitted,
    onChange,
    onCharCountChange,
    setEditorInstance,
    setIsSubmitDisabled,
    materialId,
    addDiscardedVideoId,
}: MaterialArticleProps) => {
    const { upload } = useUpload();
    // const { setIsFullScreenViewEnabled } = useContext(GlobalContext);

    const [isViewFileModal, setIsViewFileModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState<any>(null);
    const [files, setFiles] = useState<any[]>(attachments);

    const onContentChange = useCallback(
        (value: string) => {
            onChange("article.content", value);
        },
        [onChange],
    );

    const validateFileSize = (file: any) => {
        if (
            ["mp3", "wav", "pdf", "txt", "doc", "docx", "xls", "xlsx", "ppt", "pps", "pptx", "ppsx"].includes(
                file?.extension,
            ) &&
            file.size > 104857600
        ) {
            flash.error(getFileSizeErrorMessage("100 Мб"));
            return false;
        } else if (["jpg", "jpeg", "png"].includes(file?.extension) && file.size > 5242880) {
            flash.error(getFileSizeErrorMessage("5 Мб"));
            return false;
        } else if (["mp4", "zip"].includes(file?.extension) && file.size > 2147483648) {
            flash.error(getFileSizeErrorMessage("2 Гб"));
            return false;
        }

        return true;
    };

    const validateFileType = (type: string) => {
        const isValid = acceptedFileTypes.some((t) => t.extension === type);

        if (!isValid) {
            flash.error(UIErrorMessages.FILE_EXTENSION_ERROR);
        }

        return isValid;
    };

    useEffect(() => {
        if (files.every((file) => file.disposableLink)) {
            setIsSubmitDisabled(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files]);

    const onUpload = (e: any) => {
        const _onUpdate = async (file: any) => {
            const i = files.findIndex((f) => f.id === file.id);
            setFiles((prevState) => {
                const newState = [...prevState];
                newState.splice(i, 1, file);

                return newState;
            });
        };

        const _onFinished = async (file: any) => {
            const fileCopies = attachments.filter((a: FileReadResponse) => a.id === file.serverData.id);
            const _file = {
                ...file.serverData,
                name: fileCopies.length ? `Копия ${fileCopies.length} ` + file.serverData?.name : file.serverData.name,
            };
            let articleAttachments = [...attachments];

            // Проверка совпадения расширений файлов
            if (file.file.extension !== file.serverData.extension) {
                flash.error(UIErrorMessages.FILE_LOADING_ERROR);
            } else {
                articleAttachments = [...attachments, _file];
            }

            onChange("article.attachments", articleAttachments);
            setFiles(articleAttachments);
            e.target.value = "";
        };

        const newFiles = [];
        for (const f of e.target.files) {
            const extensionIdx = f.name.lastIndexOf(".");
            f["extension"] = f.name.slice(extensionIdx + 1);

            const isValidSize = validateFileSize(f);
            const isValidType = validateFileType(f["extension"]);

            if (!isValidSize || !isValidType) {
                return;
            }

            const u = upload({
                file: f,
                onUpdate: _onUpdate,
                onFinished: _onFinished,
                fileUploadType: FileUploadType.RESOURCE_ATTACHMENT,
                socketId: getCookie("USER_SESSION"),
            });
            newFiles.push(u);

            // u.start()
            // .then();
            const start = u.start();
            start.catch((error) => {
                const knownError = error as BadRequestResponse;
                console.log(error);
                if (
                    [ErrorCode.CORRUPT_FILE_ERROR, ErrorCode.FILE_EXTENSION_ERROR].includes(
                        String(knownError.errorCode) as ErrorCode,
                    )
                ) {
                    flash.error(UIErrorMessages.FILE_LOADING_ERROR);
                }
                setFiles([...files]);
                return;
            });
        }

        setFiles([...files, ...newFiles]);
        setIsSubmitDisabled(true);
    };

    const removeFile = async (e: any, file: any) => {
        e?.stopPropagation();
        e?.preventDefault();

        const _file = files.find((f) => f.id === file.id && f.name === file.name);
        const newState = files.filter((f) => f.id !== _file.id || f.name !== _file.name);

        setFiles(newState);
        onChange(
            "article.attachments",
            attachments.filter((f: any) => f.id !== _file.id || f.name !== _file.name),
        );

        // Отмена загрузки файла (если ещё загружается)
        const removedFile = files.find((item) => item.id === file.id);
        removedFile?.cancel?.();

        // Удаление файла с очереди на конвертацию
        if ((file?.file?.type || file?.contentType)?.includes("video")) {
            if (!materialId && file.disposableLink) {
                Api.Upload.DiscardVideoFile(file.id);
            } else {
                addDiscardedVideoId(file.id);
            }
        }
    };

    const onEditorChange = useCallback(
        (a: any) => {
            onCharCountChange(a);
        },
        [onCharCountChange],
    );

    useEffect(() => {
        setFiles(attachments);
    }, [attachments]);

    // Отмена оптимизации видеофайла
    const onDiscardOptimizationClick = (file: any) => {
        const _file = files.find((f) => f.id === file.id && f.name === file.name);
        const newState = files.filter((f) => f.id !== _file.id || f.name !== _file.name);

        setFiles(newState);
        onChange(
            "article.attachments",
            attachments.filter((f: any) => f.id !== _file.id || f.name !== _file.name),
        );

        // Удаляем файл из очереди на конвертацию только если он ещё не привязан ни к одной сущности (материалу)
        if (!materialId) {
            Api.Upload.DiscardVideoFile(file.id);
        } else {
            addDiscardedVideoId(file.id);
        }
    };

    return (
        <>
            <div className="pb-10">
                <Section label="Содержимое статьи" rightColumnClassName="max-w-185">
                    <TextEditor
                        initData={content}
                        error={isSubmitted ? errors?.content : ""}
                        onChange={onContentChange}
                        onWordCountChange={onEditorChange}
                        onInstanceReady={setEditorInstance}
                    />
                </Section>
                <Section label="Прикрепленные файлы" rightColumnClassName="max-w-185 space-y-4">
                    {files.length <= 9 && (
                        <div className="relative flex items-center w-full px-4 py-4.5 rounded-lg bg-background p1-accent text-blue">
                            <label
                                htmlFor="adminNewMaterialArticleInputAttachFile"
                                className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                            ></label>
                            <Icon icon={Icons.Clip} width="20px" height="20px" color="fill-blue" className="mr-2" />
                            Прикрепить файл
                            <input
                                type="file"
                                className="invisible hidden"
                                onChange={onUpload}
                                accept={acceptedFileTypes.map((t) => t.mimeType).join(", ")}
                                id="adminNewMaterialArticleInputAttachFile"
                            />
                        </div>
                    )}
                    {files.length > 0 && (
                        <div>
                            {files.map((attachment: any) => {
                                return (
                                    <MaterialArticleAttachment
                                        key={attachment?.serverData?.id ?? attachment?.file?.id ?? attachment?.id}
                                        attachment={attachment}
                                        onClick={(selectedFile) => {
                                            setSelectedFile(selectedFile);
                                            setIsViewFileModal(true);
                                            // setIsFullScreenViewEnabled(true);
                                        }}
                                        removeFile={removeFile}
                                        onDiscardOptimizationClick={onDiscardOptimizationClick}
                                    />
                                );
                            })}
                        </div>
                    )}
                </Section>
            </div>
            {isViewFileModal && (
                <FileViewDialog
                    file={selectedFile as FileReadResponse & { type: string; data: string }}
                    handleClose={() => {
                        setIsViewFileModal(false);
                        // setIsFullScreenViewEnabled(false);
                    }}
                />
            )}
        </>
    );
};
