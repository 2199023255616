import React, { useEffect, useMemo, useRef, useState } from "react";
import { ColumnDef, ColumnFiltersState, Table as TanstackTable, SortingState } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import { Checkbox, Table, Icon, Icons, PopoverList, Button } from "Uikit";
import { UserAvatar } from "Uikit/UserAvatar/UserAvatar";
import { ID } from "types/ID";
import { MembersListResponse } from "Api/Responses/UserResponse";
import { TeamMultiSelect } from "Components/Common/TeamMultiSelect";
import { OfficeMultiselect } from "Components/Common/OfficeMultiselect";
import { JobMultiSelect } from "Components/Common/JobMultiSelect";
import { useCurrentUser } from "hooks/useCurrentUser";
import { RoleName, StatisticsUsersStatus } from "Enums";
import clsx from "clsx";
import { MultiClumpTooltip } from "Components/MultiClumpTooltip/MultiClumpTooltip";

type UsersFilter = {
    value: StatisticsUsersStatus;
    label: string;
};

const userStatusFilterOptions: UsersFilter[] = [
    {
        value: StatisticsUsersStatus.ALL,
        label: "Все",
    },
    {
        value: StatisticsUsersStatus.ACTIVE,
        label: "Активен",
    },
    {
        value: StatisticsUsersStatus.BLOCKED,
        label: "Заблокирован",
    },
];

interface ReportUserTableProps {
    onChange?: (selectedUsers: MembersListResponse[]) => void;
    selectedUsers?: MembersListResponse[];
    data: MembersListResponse[];
    isSingleSelect?: boolean;
    showStatusFilter?: boolean;
    isFetching?: boolean;
}

const sortFunction = (
    { firstName: firstName1, lastName: lastName1 }: MembersListResponse,
    { firstName: firstName2, lastName: lastName2 }: MembersListResponse,
) => {
    const name1 = `${firstName1} ${lastName1}`;
    const name2 = `${firstName2} ${lastName2}`;
    return name1.localeCompare(name2);
};
const sortFunctionReverse = (
    { firstName: firstName1, lastName: lastName1 }: MembersListResponse,
    { firstName: firstName2, lastName: lastName2 }: MembersListResponse,
) => {
    const name1 = `${firstName1} ${lastName1}`;
    const name2 = `${firstName2} ${lastName2}`;
    return name2.localeCompare(name1);
};

export const ReportUserTable = ({
    onChange,
    selectedUsers = [],
    data = [],
    isSingleSelect = false,
    showStatusFilter = false,
    isFetching = false,
}: ReportUserTableProps) => {
    const navigate = useNavigate();
    const tableInstanceRef = useRef<TanstackTable<any> | null>(null);
    const currentUser = useCurrentUser();

    const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

    const [usersFiltered, setUsersFiltered] = useState<MembersListResponse[]>(data);
    const [teamsOnly, setTeamsOnly] = useState<ID[]>([]);
    const [jobsOnly, setJobsOnly] = useState<ID[]>([]);
    const [officesOnly, setOfficesOnly] = useState<ID[]>([]);
    const [usersStatus, setUsersStatus] = useState<UsersFilter>(userStatusFilterOptions[0]);
    const [sorting, setSorting] = useState<SortingState>([{ id: "firstName,lastName", desc: true }]);
    const sortingChangedRef = useRef(false);

    const columns = useMemo<ColumnDef<MembersListResponse>[]>(
        () => {
            const baseColumns: ColumnDef<MembersListResponse>[] = [
                !isSingleSelect
                    ? {
                          id: "select",
                          enableResizing: true,
                          size: 16,
                          header: ({ table }) => (
                              <Checkbox
                                  checked={table.getIsAllRowsSelected()}
                                  indeterminate={table.getIsSomeRowsSelected()}
                                  onChange={table.getToggleAllRowsSelectedHandler()}
                              />
                          ),
                          cell: ({ row }) => (
                              <div
                                  className={
                                      "flex items-center relative h-9 after:content-[''] after:hidden after:absolute after:top-0 after:-left-2 after:w-[calc(100%+8px)] after:h-full after:rounded-l-lg after:bg-blue-lightest group-hover:after:z-0 after:z-0 group-hover:after:block"
                                  }
                              >
                                  <div className="relative z-10">
                                      <Checkbox
                                          className="pl-2"
                                          checked={row.getIsSelected()}
                                          indeterminate={row.getIsSomeSelected()}
                                          onChange={row.getToggleSelectedHandler()}
                                      />
                                  </div>
                              </div>
                          ),
                      }
                    : {
                          // заглушка, чтобы избежать ошибок типа
                          id: "select",
                          enableResizing: true,
                          size: 16,
                          header: () => null,
                          cell: () => null,
                      },
                {
                    header: () => (
                        <div
                            className="flex h-9 gap-1 items-center"
                            onClick={() => {
                                setSorting((prevSorting) => {
                                    const prevSorting0 = structuredClone(prevSorting[0]);
                                    prevSorting0.desc = !prevSorting[0].desc;
                                    prevSorting[0] = prevSorting0;
                                    return [prevSorting0];
                                });
                                sortingChangedRef.current = true;
                            }}
                        >
                            <div className="">Участник</div>
                            <div className="">
                                <Icon color="fill-gray-text" icon={Icons.Sort} width={14} height={14} />
                            </div>
                        </div>
                    ),
                    footer: (props) => props.column.id,
                    accessorKey: "firstName,lastName",
                    cell: ({ row: { original: user } }) => {
                        const isSelected = selectedUsers?.some((selectedUser) => selectedUser.id === user.id);
                        return (
                            <div
                                className={clsx(
                                    "group flex items-center relative h-9 after:content-[''] after:hidden after:absolute after:top-0 after:-left-2 after:w-[calc(100%+8px)] after:h-full after:bg-blue-lightest group-hover:after:z-0 after:z-0 group-hover:after:block",
                                    isSelected && "!bg-blue-lightest",
                                )}
                                onClick={() => handleRowClick(user)}
                            >
                                <div className="flex items-center gap-2 relative z-10 group w-full pr-5">
                                    <UserAvatar
                                        avatarId={user.avatarId}
                                        color={user.defaultAvatarColor}
                                        userInitials={`${user.firstName?.slice(0, 1)}${user.lastName?.slice(0, 1)}`}
                                        size={28}
                                    />
                                    <MultiClumpTooltip
                                        clamp={1}
                                        label={String(user.lastName + " " + user.firstName)}
                                        textClassName={clsx(
                                            "cursor-pointer group-hover:text-blue",
                                            isSelected ? "!text-blue" : null,
                                        )}
                                    />
                                    <a
                                        href={`/admin/member/${user.id}`}
                                        target="_blank"
                                        className="text-current ml-auto hidden group-hover:inline-flex items-center no-underline hover:no-underline !text-blue gap-1.5"
                                        rel="noreferrer"
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <Icon
                                            color="fill-blue"
                                            className="translate-y-0.25"
                                            icon={Icons.ShareBox}
                                            width={16}
                                            height={16}
                                        />
                                        <span>Посмотреть</span>
                                    </a>
                                </div>
                            </div>
                        );
                    },
                    accessorFn: (row: any) => `${row.firstName} ${row.lastName}`,
                    enableSorting: false,
                },
            ];

            return baseColumns.filter(Boolean) as ColumnDef<MembersListResponse>[];
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isSingleSelect, navigate, selectedUsers],
    );

    useEffect(() => {
        if (data) {
            const rowSelectionObj: Record<string, boolean> = {};
            // tableUsersSelected.forEach((tableUsersItem) => {
            selectedUsers.forEach((tableUsersItem) => {
                rowSelectionObj[tableUsersItem.id] = true;
            });
            setRowSelection(rowSelectionObj);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        setUsersFiltered(() => {
            let usersFiltered = data;
            if (teamsOnly.length > 0) {
                usersFiltered = usersFiltered.filter((test) => test.teams.some((test) => teamsOnly.includes(test.id)));
            }
            if (officesOnly.length > 0) {
                usersFiltered = usersFiltered.filter((test) => officesOnly.includes(test.officeId));
            }
            if (jobsOnly.length > 0) {
                usersFiltered = usersFiltered.filter(({ jobTitle = {} }) => {
                    return jobTitle?.id && jobsOnly.includes(jobTitle?.id);
                });
            }
            if (usersStatus.value !== StatisticsUsersStatus.ALL) {
                usersFiltered = usersFiltered.filter((user) => user.state === usersStatus.value);
            }

            return usersFiltered;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, teamsOnly, officesOnly, jobsOnly, usersStatus.value]);

    useEffect(() => {
        if (sortingChangedRef.current) {
            const usersSorted = sorting[0].desc
                ? [...usersFiltered].sort(sortFunctionReverse)
                : [...usersFiltered].sort(sortFunction);
            setUsersFiltered(usersSorted);

            const selectedUsersRowsSelection: Record<string, boolean> = {};
            selectedUsers.forEach((item) => {
                selectedUsersRowsSelection[item.id] = true;
            });
            setRowSelection(selectedUsersRowsSelection);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sorting]);

    useEffect(() => {
        const selectedUsersArr: MembersListResponse[] = [];
        for (const id in rowSelection) {
            const rowUser = usersFiltered.find((i) => i.id === id);

            if (rowUser) {
                selectedUsersArr.push(rowUser);
            }
        }
        onChange?.(Array.from(new Set(selectedUsersArr)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowSelection]);

    const handleRowClick = (user: MembersListResponse) => {
        if (isSingleSelect) {
            onChange?.([user]);
        } else {
            setRowSelection((prev) => ({
                ...prev,
                [user.id]: !prev[user.id],
            }));
        }
    };

    const handleChangeUsersStatus = (option: UsersFilter) => {
        setUsersStatus(option);
    };

    const controlButtons = (
        <div className="flex w-full justify-start items-center space-x-2">
            <div className="max-w-42 w-full">
                <TeamMultiSelect
                    onChange={setTeamsOnly}
                    value={teamsOnly}
                    showTeams={false}
                    isClearable={true}
                    hideMembers={currentUser?.data?.role === RoleName.ADMIN}
                />
            </div>
            <div className="max-w-42 w-full">
                <OfficeMultiselect onChange={setOfficesOnly} />
            </div>
            <div className="max-w-42 w-full">
                <JobMultiSelect onChange={setJobsOnly} />
            </div>
        </div>
    );

    return (
        <div>
            <Table
                columns={columns}
                searchTitle="Поиск по участникам"
                data={usersFiltered}
                rowSelection={rowSelection}
                onRowSelectionChange={setRowSelection}
                onColumnFiltersChange={setColumnFilters}
                columnFilters={columnFilters}
                controlButtons={controlButtons}
                emptyMessage="По заданным параметрам результатов нет"
                rowClassName="!p-0 border-none group"
                searchAlwaysLeft={true}
                controlsWrapperClassNames={"mt-6 flex-col gap-4 grow"}
                setTableInstance={(tableInstance: TanstackTable<any>) => {
                    tableInstanceRef.current = tableInstance;
                }}
                searchClassName="!w-full"
                tableWrapperClassName="h-100 overflow-y-auto overflow-x-hidden -ml-2 custom-scrollbar js-scrollbar"
                columnClassName="sticky top-0 bg-white !opacity-100 first-of-type:pl-2 pl-0 py-0"
                theadClassName="sticky z-20 pb-4"
                sorting={sorting}
                onSortingChange={setSorting}
                afterSearchComponent={
                    showStatusFilter ? (
                        <UsersStatusFilter selectedOption={usersStatus} onChange={handleChangeUsersStatus} />
                    ) : null
                }
                searchInputWrapperClassName="!min-w-[250px]"
                isFetching={isFetching}
            />
        </div>
    );
};

interface UsersStatusFilterProps {
    selectedOption: UsersFilter;
    onChange: (option: UsersFilter) => void;
}

const UsersStatusFilter = ({ selectedOption, onChange }: UsersStatusFilterProps) => {
    const [popoverListOpen, setPopoverListOpen] = useState(false);

    const onPopoverListItemClick = (option: UsersFilter) => {
        onChange(option);
        setPopoverListOpen(false);
    };

    return (
        <PopoverList
            offset={[0, 0]}
            visible={popoverListOpen}
            onClickOutside={() => setPopoverListOpen(false)}
            className="!w-[8rem]"
        >
            <Button
                variant="outline"
                color="common"
                size="medium"
                className="border-[#E6E9ED] rounded-lg font-medium"
                icon={<Icon icon={Icons.Filter} width={20} height={20} color="stroke-blue" />}
                iconPlacement={"left"}
                onClick={() => setPopoverListOpen(true)}
            >
                {selectedOption.label}
            </Button>
            {userStatusFilterOptions.map(({ value, label }) => {
                return (
                    <PopoverList.Item
                        key={value}
                        name={value}
                        onClickItem={() => {
                            onPopoverListItemClick({ value, label });
                        }}
                        isActive={selectedOption.value === value}
                    >
                        {label}
                    </PopoverList.Item>
                );
            })}
        </PopoverList>
    );
};
