import { Expose, Type } from "class-transformer";
import { BaseResponse } from "../BaseResponse";

type TPreviewStatus = "GENERATION_IN_PROGRESS" | "SUCCESS";

export class GeneratePreviewResponse extends BaseResponse {
    @Expose()
    @Type(() => String)
    public taskId: string;

    @Expose()
    @Type(() => String)
    public status: TPreviewStatus;
}

export class GetPreviewResponse extends BaseResponse {
    @Expose()
    @Type(() => String)
    public fileId: string;

    @Expose()
    @Type(() => String)
    public status: TPreviewStatus;

    @Expose()
    @Type(() => String)
    public contentType: string;

    @Expose()
    @Type(() => String)
    public preview: string;

    // @Expose()
    // @Type(() => String)
    // public extension?: string;
}
