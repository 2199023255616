import React, { Fragment, ReactNode } from "react";
import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";
import { TModalSyzes, sizes } from "./Modal";

interface BaseModalProps {
    children: ReactNode;
    isOpen: boolean;
    size?: TModalSyzes;
    className?: string;
    id?: string;
}

export const BaseModal = ({ isOpen, children, size = "extraMedium", className, id }: BaseModalProps) => {
    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 z-[102]" onClose={() => {}}>
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-20 bg-black"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-20 bg-black"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0" />
                    </Transition.Child>

                    <span className="inline-block h-screen align-middle" aria-hidden="true">
                        &#8203;
                    </span>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div
                            className={clsx(
                                sizes[size],
                                "border-[1px] border-[#EAEDF3] inline-block w-full",
                                "text-left align-middle transition-all",
                                "transform bg-white shadow-xl rounded-lg 2xl:rounded-2lg",
                                className,
                            )}
                            id={id}
                        >
                            {children}
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
};
