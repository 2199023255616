import React, { useEffect } from "react";
import { SingleValue } from "react-select";

import { Button, FormGroup, Label, Tooltip, ComboBox } from "Uikit";
import { Loader } from "Uikit/Loader/Loader";
import { IOption } from "types";
import Api from "Api";

import { IAuthorizationInviteSettingsFormProps } from "./AuthorizationInviteForm";

interface IAuthorizationInviteFormJobProps extends IAuthorizationInviteSettingsFormProps {
    errors: Record<string, string | undefined>;
    inviteId: string;
    jobOptions: IOption[];
    setJobOptions: (options: IOption[]) => void;
    officeOptions: IOption[];
    setOfficeOptions: (options: IOption[]) => void;
    isFetched: boolean;
    setIsFetched: (isFetched: boolean) => void;
}

export const AuthorizationInviteFormJob = ({
    registrationRequest,
    onChange,
    onSubmit,
    errors,
    isValid,
    inviteId,
    jobOptions,
    setJobOptions,
    officeOptions,
    setOfficeOptions,
    isFetched,
    setIsFetched,
}: IAuthorizationInviteFormJobProps) => {
    useEffect(() => {
        const fetch = async () => {
            if (jobOptions.length === 0) {
                const jobs = await Api.Invites.JobsList(inviteId);
                const collator = new Intl.Collator("en");
                const specials = ["-", "–", "—", "(", ")", "#", "№", "&", "_", "+", "/"];

                setJobOptions(
                    jobs.Content.map((p) => ({ label: p.name, value: p.id! })).sort((a: any, b: any) => {
                        if (specials.includes(a.label[0])) {
                            return -1;
                        } else if (specials.includes(b.label[0])) {
                            return 1;
                        }

                        if (!isNaN(Number(a.label))) {
                            return -1;
                        } else if (!isNaN(Number(b.label))) {
                            return 1;
                        }

                        if (!isNaN(a.label) && !isNaN(b.label)) {
                            const aNum = Number(a.label);
                            const bNum = Number(b.label);
                            if (aNum < bNum) {
                                return -1;
                            } else if (aNum > bNum) {
                                return 1;
                            }
                        }

                        return collator.compare(a.label, b.label);
                    }),
                );
            }

            if (officeOptions.length === 0) {
                const offices = await Api.Invites.OfficeList(inviteId);
                setOfficeOptions([...offices.Content.map((p) => ({ label: p.name, value: p.id }))]);
            }

            setIsFetched(true);
        };
        fetch().then();
    }, [setJobOptions, inviteId, jobOptions.length, officeOptions.length, setOfficeOptions, setIsFetched]);

    return (
        <>
            {!isFetched ? (
                <div className="flex items-center justify-center py-10">
                    <Loader />
                </div>
            ) : (
                <>
                    <FormGroup>
                        <Label isRequired>Должность</Label>
                        <ComboBox
                            placeholder="Выберите должность"
                            options={jobOptions}
                            value={jobOptions[jobOptions.findIndex((p) => registrationRequest.jobTitleId === p.value)]}
                            onChange={(option: SingleValue<any>) =>
                                onChange({ ...registrationRequest, jobTitleId: option?.value || "" })
                            }
                            error={errors?.["jobTitleId"]}
                            id="adminNewMemberComboBoxJob"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Офис</Label>
                        <ComboBox
                            placeholder="Выберите офис"
                            options={officeOptions}
                            value={
                                registrationRequest.officeId
                                    ? officeOptions[
                                          officeOptions.findIndex((p) => registrationRequest.officeId === p.value)
                                      ]
                                    : null
                            }
                            onChange={(option: SingleValue<any>) => {
                                onChange({ ...registrationRequest, officeId: option?.value || undefined });
                            }}
                            error={errors?.["officeId"]}
                            id="adminNewMemberComboBoxOffice"
                        />
                    </FormGroup>
                </>
            )}
            <Tooltip
                className="w-full max-w-[700px] flex rounded-md cursor-pointer"
                content={"Заполните обязательные поля"}
                disabled={isValid}
            >
                <Button
                    className="w-full h-12 sm:!mt-1 sm:!mb-0 sm:h-10"
                    onClick={() => {
                        onSubmit();
                    }}
                    disabled={!isValid}
                >
                    Далее
                </Button>
            </Tooltip>
        </>
    );
};
