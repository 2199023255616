import React, { Fragment, useCallback, useEffect } from "react";

import dayjs from "dayjs";
import { useMutation, useQuery } from "react-query";
import { saveAs } from "file-saver";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Skeleton from "react-loading-skeleton";

import { CardsBlock } from "Components/CardsBlock";
import { Icon, Icons } from "Uikit/Icon/Icon";
import { Card } from "Components/Card";
import Api from "Api";
import { numWord } from "helpers/numWord";
import { MultiClumpTooltip } from "Components/MultiClumpTooltip/MultiClumpTooltip";
import { setSourcePage, SourcePage } from "slices/utilSlice";
import { Breadcrumbs } from "Uikit/Breadcrumbs/Breadcrumbs";
import { Empty } from "Uikit/Page/Empty";
import { useCurrentUser } from "hooks/useCurrentUser";
import { setIsBack, setIsFavorite, setIsHidden, setTitle } from "slices/headerSlice";
import "react-loading-skeleton/dist/skeleton.css";
import { Resource } from "Api/Responses/CommonsResponses";
import { CommonsAddFavoritesRequest, CommonsRemoveFavoritesRequest } from "Api/Requests/CommonsRequest";
import { ProgressStatus, ResourceType } from "Enums";
import { TVoidFunction } from "types";
import { LogoSize } from "Api/Services/UploadApi";

const elements = (count: number) => numWord(count, ["элемент", "элемента", "элементов"]);
const courses = (count: number) => numWord(count, ["курс", "курсы", "курсов"]);
const tasks = (count: number) => numWord(count, ["задание", "задания", "заданий"]);
const tests = (count: number) => numWord(count, ["тест", "тесты", "тестов"]);

// Скачивание файла
const saveFile = async (id: string) => {
    try {
        const resp = await Api.Upload.DownloadGeneratedFile(id);

        saveAs(resp);
    } catch (e) {
        console.error(`Ошибка при загрузке сгенерированного файла: `, e);
    }
};

export const Home = () => {
    const dispatch = useDispatch();
    const nativeNavigate = useNavigate();
    const { generatedFileId } = useParams();

    const currentUser = useCurrentUser();
    const { data: statistics } = useQuery(
        ["profile", "stats", currentUser.data],
        () => Api.Statistic.GetUserProgressActivity(""),
        {
            enabled: !!currentUser,
        },
    );

    const navigate = useCallback(
        (to: string) => {
            dispatch(setSourcePage(SourcePage.HOME));
            nativeNavigate(to);
        },
        [nativeNavigate, dispatch],
    );

    const {
        data: { exercises, myTraining, quizzes, favorites } = {},
        isLoading,
        refetch,
    } = useQuery(["ui/home-page"], () => Api.Commons.getHomePageData());

    const { mutate: onFavoriteChange } = useMutation(
        async function ({ resource, isFavorite }: { resource: Resource; isFavorite: boolean }) {
            if (isFavorite) {
                return await Api.Favorite.Add(
                    Object.assign(new CommonsAddFavoritesRequest(), {
                        resourceId: resource.resourceId,
                        resourceSolutionId: resource.progressId,
                        passingNumber: resource.passingNumber,
                        title: resource?.title,
                        logoId: resource?.logoId,
                        state: "ACTIVE",
                        type: resource.type,
                        progressStatus: resource.progressStatus,
                        ratingPoints: resource?.ratingPoints,
                        deadlineTimestamp: resource?.deadlineTimestamp ?? 0,
                    }),
                );
            } else {
                return await Api.Commons.removeFavorites(
                    Object.assign(new CommonsRemoveFavoritesRequest(), {
                        resourceId: resource.resourceId,
                        passingNumber: resource.passingNumber,
                        type: resource.type,
                    }),
                );
            }
        },
        {
            onSuccess: (_data) => {
                refetch().then();
            },
        },
    );

    const onNavigate = (resource: Resource) => {
        let url = "";
        const resourceId = resource.resourceId ?? resource.id;

        switch (resource.type) {
            case ResourceType.ARTICLE:
            case ResourceType.VIDEO:
            case ResourceType.DOCUMENT:
            case ResourceType.SCORM:
            case ResourceType.MATERIAL:
                url = `/training/course/${resource.courseId}/${resource.componentId}/${resourceId}?favorite=true`;
                break;
            case ResourceType.COURSE:
                url = `/training/course/${resourceId}`;
                break;
            case ResourceType.QUIZ:
                if (
                    resource.progressStatus === ProgressStatus.FAILED ||
                    resource.progressStatus === ProgressStatus.ON_REVIEW ||
                    resource.progressStatus === ProgressStatus.PASSED
                ) {
                    url = `/test/${resource.resourceId}/statistics`;
                } else {
                    url = `/test/${resource.resourceId}`;
                }

                break;
            case ResourceType.EXERCISE:
                url = `/task/${resource.resourceId}${
                    resource.progressStatus === ProgressStatus.FAILED ||
                    resource.progressStatus === ProgressStatus.ON_REVIEW ||
                    resource.progressStatus === ProgressStatus.PASSED
                        ? "/statistics"
                        : ""
                }`;
                break;
            case ResourceType.PROGRAM:
                url = `/training/program/${resourceId}`;
                break;
            default:
                break;
        }
        console.log(resource);
        console.log(url);

        navigate(url);
    };

    useEffect(() => {
        if (generatedFileId) {
            saveFile(generatedFileId);
        }
    }, [generatedFileId]);

    useEffect(() => {
        dispatch(setIsHidden(false));
        dispatch(setIsBack(false));
        dispatch(setTitle("Главная"));
        dispatch(setIsFavorite(false));
    }, [dispatch]);

    let statisticsTotal = 0;

    if (statistics?.courseStatistics) {
        statisticsTotal =
            statistics?.courseStatistics.totalCount +
            statistics?.courseStatistics.passedCount +
            statistics?.courseStatistics.inProgressCount;
    }

    const isGotFavorites = favorites && favorites.content.length > 0;
    const favoritesDataView = favorites && favorites.total > 8 ? favorites.content.slice(0, 7) : favorites?.content;

    const isGotMyTraining = myTraining && myTraining.content.length > 0;
    const trainingDataView = myTraining && myTraining.total > 8 ? myTraining.content.slice(0, 7) : myTraining?.content;

    const isGotExercises = exercises && exercises.content.length > 0;
    const exercisesDataView = exercises && exercises.total > 8 ? exercises.content.slice(0, 7) : exercises?.content;

    const isGotQuizzes = quizzes && quizzes.content.length > 0;
    const quizzesDataView = quizzes && quizzes.total > 8 ? quizzes.content.slice(0, 7) : quizzes?.content;

    const isEmpty =
        !isLoading &&
        (!favorites || favorites.content.length === 0) &&
        (!myTraining || myTraining.content.length === 0) &&
        (!quizzes || quizzes.content.length === 0) &&
        (!exercises || exercises.content.length === 0);

    return (
        <div className="h-full flex flex-wrap justify-center">
            <div className="sm:pl-4 sm:pr-6.5 sm:max-w-[1216px] 2xl:max-w-[1506px] w-full h-full mx-auto flex flex-col">
                {/*Mobile home block start*/}
                <div className="flex flex-col mt-3 px-3 sm:hidden pb-30">
                    <h2 className="mb-4 text-black">Привет, {currentUser.data?.firstName}!</h2>
                    {statistics?.courseStatistics && (
                        <div className="flex items-center justify-between mb-4 py-5 px-4 bg-background rounded-2xl">
                            <div className="flex flex-col mr-2 w-full">
                                <span className="mb-3 p1-accent text-black">Курсов</span>
                                <div className="flex items-center justify-between w-full">
                                    <div className="flex flex-col">
                                        <span className="mb-0.5 p4 text-gray">Пройдено</span>
                                        <div className="flex items-center">
                                            <span className="mr-2 w-1.5 h-1.5 bg-primary rounded-full"></span>
                                            <h4 className="text-black">{statistics?.courseStatistics.passedCount}</h4>
                                        </div>
                                    </div>
                                    <div className="flex flex-col">
                                        <span className="mb-0.5 p4 text-gray">В процессе</span>
                                        <div className="flex items-center">
                                            <span className="mr-2 w-1.5 h-1.5 bg-gray-light rounded-full"></span>
                                            <h4 className="text-black">
                                                {statistics?.courseStatistics.inProgressCount}
                                            </h4>
                                        </div>
                                    </div>
                                    <div className="flex flex-col">
                                        <span className="mb-0.5 p4 text-gray">Доступно</span>
                                        <div className="flex items-center">
                                            <span className="mr-2 w-1.5 h-1.5 bg-input-stroke rounded-full"></span>
                                            <h4 className="text-black">{statistics?.courseStatistics.totalCount}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="min-w-16 min-h-16">
                                <svg viewBox="0 0 36 36">
                                    <path
                                        className="that-circle"
                                        stroke="#E6E9ED"
                                        d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                                    />
                                    <path
                                        className="that-circle"
                                        stroke="#C1C6CA"
                                        strokeDasharray={`${
                                            100 - (statistics.courseStatistics.totalCount * 100) / statisticsTotal
                                        } 100`}
                                        d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                                    />
                                    <path
                                        className="that-circle"
                                        stroke="#0084E2"
                                        strokeDasharray={`${
                                            (statistics.courseStatistics.passedCount * 100) / statisticsTotal
                                        } 100`}
                                        d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                                    />
                                    <text className="percentage" x="9" y="22">
                                        {Math.round((statistics?.courseStatistics.passedCount * 100) / statisticsTotal)}
                                        %
                                    </text>
                                </svg>
                            </div>
                        </div>
                    )}
                    {myTraining && myTraining.total !== 0 && (
                        <div
                            className="hidden mb-4 p-3 bg-primary rounded-2xl"
                            onClick={() => navigate(`/training/course/${myTraining.content[0].id}`)}
                        >
                            <div className="flex items-center justify-between mb-3">
                                <span className="p1-accent text-white">Продолжить обучение</span>
                                <Icon icon={Icons.ChevronRight} width={14} height={14} color="fill-white" />
                            </div>
                            <div className="flex items-center cursor-pointer">
                                <img
                                    className="mr-2 rounded-lg w-15 h-10 object-cover"
                                    src={Api.Upload.GetLogo(myTraining.content[0].logoId ?? "", LogoSize.THUMB_MINI)}
                                    alt={myTraining.content[0].title}
                                />
                                <span className="p2 text-white">{myTraining.content[0].title}</span>
                            </div>
                        </div>
                    )}
                </div>
                {/*Mobile home block finish*/}
                <Breadcrumbs className="hidden sm:block w-full">
                    <Breadcrumbs.Link title="Главная страница" />
                </Breadcrumbs>
                {isEmpty && (
                    <div className="h-full flex justify-center">
                        <Empty
                            topElement={
                                <div className="flex items-center justify-center mb-4 2xl:mb-5 w-16.5 2xl:w-20.5 h-16.5 2xl:h-20.5 bg-blue-10 rounded-full">
                                    <Icon
                                        icon={Icons.UserMenuIconHome}
                                        width={36}
                                        height={36}
                                        color="fill-primary"
                                        className="2xl:!w-11.25 2xl:!h-11.25"
                                    />
                                </div>
                            }
                            title="Ничего нет :("
                            description={
                                <span>
                                    Пока тут ничего нет, но скоро обязательно
                                    <br />
                                    что-то появится!
                                </span>
                            }
                        />
                    </div>
                )}
                {!isEmpty && (
                    <div className="space-y-8 2xl:space-y-9 pb-30">
                        {((isGotFavorites && !isLoading) || (!isGotFavorites && isLoading)) && (
                            <CardsBlock
                                title="Избранное"
                                subtitle={isGotFavorites ? `Всего ${favorites?.total}` : ""}
                                flippable={false}
                                rows={window.screen.width <= 568 ? 1 : 2}
                                onSeeAll={window.screen.width <= 568 ? () => nativeNavigate("/favorite") : undefined}
                                total={favorites?.total ?? 0}
                                id="userHomeFavoriteBlock"
                            >
                                {!isGotFavorites &&
                                    Array.from(Array(4).keys()).map((p) => {
                                        return (
                                            <Fragment key={`card-skeleton__${p}`}>
                                                <div className="w-60 sm:w-[274px] sm:h-41 2xl:w-[342px] 2xl:h-51 rounded-2xl overflow-hidden leading-0">
                                                    <Skeleton className="rounded-2xl" width="100%" height="100%" />
                                                </div>
                                                <div className={"leading-5 line-clamp-2 pt-3"}>
                                                    <Skeleton className="rounded-2xl" width="100%" height="100%" />
                                                </div>
                                            </Fragment>
                                        );
                                    })}
                                {isGotFavorites &&
                                    favoritesDataView?.map((item) => {
                                        return (
                                            <div
                                                key={item.resourceId}
                                                className="w-60 sm:w-[274px] 2xl:w-[342px] cursor-pointer"
                                                onClick={() => onNavigate(item)}
                                            >
                                                <Card
                                                    className="w-60 h-36 sm:w-[274px] sm:h-41 2xl:w-[342px] 2xl:h-51"
                                                    points={item.ratingPoints}
                                                    deadline={
                                                        item.deadlineTimestamp
                                                            ? dayjs(item.deadlineTimestamp * 1000)
                                                            : undefined
                                                    }
                                                    required={item.isRequired}
                                                    logoId={item.logoId}
                                                    type={item.materialType || item.type}
                                                    isFavorite={true}
                                                    onFavoriteChange={(isFavorite) =>
                                                        onFavoriteChange({ resource: item, isFavorite })
                                                    }
                                                    score={item.averageReviewRating}
                                                />
                                                <MultiClumpTooltip
                                                    className="pt-3"
                                                    label={item.title}
                                                    textClassName="2xl:text-2md"
                                                />
                                            </div>
                                        );
                                    })}
                                {isGotFavorites && favorites.total > 8 && (
                                    <ReferenceItem
                                        onClick={() => {
                                            nativeNavigate("/favorite");
                                        }}
                                        subtitle={`${favorites.total} ${elements(favorites.total)}`}
                                    />
                                )}
                            </CardsBlock>
                        )}
                        {((isGotMyTraining && !isLoading) || (!isGotMyTraining && isLoading)) && (
                            <CardsBlock
                                title="Моё обучение"
                                subtitle={isGotMyTraining ? `Всего ${myTraining?.total}` : ""}
                                flippable={false}
                                rows={window.screen.width <= 568 ? 1 : 2}
                                onSeeAll={window.screen.width <= 568 ? () => nativeNavigate("/training") : undefined}
                                total={myTraining?.total ?? 0}
                                id="userHomeTrainingBlock"
                            >
                                {!isGotMyTraining &&
                                    Array.from(Array(8).keys()).map((p) => {
                                        return (
                                            <Fragment key={`card-skeleton__${p}`}>
                                                <div className="w-60 sm:w-[274px] sm:h-41 2xl:w-[342px] 2xl:h-51 rounded-2xl overflow-hidden leading-0">
                                                    <Skeleton className="rounded-2xl" width="100%" height="100%" />
                                                </div>
                                                <div className={"leading-5 line-clamp-2 pt-3"}>
                                                    <Skeleton className="rounded-2xl" width="100%" height="100%" />
                                                </div>
                                            </Fragment>
                                        );
                                    })}
                                {isGotMyTraining &&
                                    trainingDataView?.map((item) => {
                                        return (
                                            <div
                                                key={item.resourceId}
                                                className="w-60 sm:w-[274px] 2xl:w-[342px] cursor-pointer"
                                                onClick={() => onNavigate(item)}
                                            >
                                                <Card
                                                    className="w-60 h-36 sm:w-[274px] sm:h-41 2xl:w-[342px] 2xl:h-51"
                                                    type={item.type}
                                                    points={item.ratingPoints}
                                                    deadline={
                                                        item.deadlineTimestamp
                                                            ? dayjs(item.deadlineTimestamp * 1000)
                                                            : undefined
                                                    }
                                                    required={item.isRequired}
                                                    logoId={item.logoId}
                                                    isFavorite={item.isFavorite}
                                                    progress={(item.progressPercentage ?? 0) / 100}
                                                    onFavoriteChange={(isFavorite) =>
                                                        onFavoriteChange({ resource: item, isFavorite })
                                                    }
                                                    score={item.averageReviewRating}
                                                />
                                                <MultiClumpTooltip
                                                    className="pt-3"
                                                    label={item.title}
                                                    textClassName="2xl:text-2md"
                                                />
                                            </div>
                                        );
                                    })}
                                {isGotMyTraining && myTraining?.total > 8 && (
                                    <ReferenceItem
                                        onClick={() => {
                                            nativeNavigate("/training");
                                        }}
                                        subtitle={`${myTraining?.total} ${courses(Number(myTraining?.total))}`}
                                    />
                                )}
                            </CardsBlock>
                        )}
                        {((isGotQuizzes && !isLoading) || (!isGotQuizzes && isLoading)) && (
                            <CardsBlock
                                title="Тестирование"
                                subtitle={isGotQuizzes ? `Всего ${quizzes.total}` : ""}
                                onSeeAll={() => nativeNavigate("/tests")}
                                total={quizzes?.total ?? 0}
                                id="userHomeTestsBlock"
                            >
                                {!isGotQuizzes &&
                                    Array.from(Array(4).keys()).map((p) => {
                                        return (
                                            <Fragment key={`card-skeleton__${p}`}>
                                                <div className="w-60 sm:w-[274px] sm:h-41 2xl:w-[342px] 2xl:h-51 rounded-2xl overflow-hidden leading-0">
                                                    <Skeleton className="rounded-2xl" width="100%" height="100%" />
                                                </div>
                                                <div className={"leading-5 line-clamp-2 pt-3"}>
                                                    <Skeleton className="rounded-2xl" width="100%" height="100%" />
                                                </div>
                                            </Fragment>
                                        );
                                    })}
                                {isGotQuizzes &&
                                    quizzesDataView?.map((item) => {
                                        return (
                                            <div
                                                key={item.resourceId}
                                                className="w-60 sm:w-[274px] 2xl:w-[342px] cursor-pointer"
                                                onClick={() => onNavigate(item)}
                                            >
                                                <Card
                                                    className="w-60 h-36 sm:w-[274px] sm:h-41 2xl:w-[342px] 2xl:h-51"
                                                    points={item.ratingPoints}
                                                    deadline={
                                                        item.deadlineTimestamp
                                                            ? dayjs(item.deadlineTimestamp * 1000)
                                                            : undefined
                                                    }
                                                    required={item.isRequired}
                                                    logoId={item.logoId}
                                                    isFavorite={item.isFavorite}
                                                    onFavoriteChange={(isFavorite) =>
                                                        onFavoriteChange({ resource: item, isFavorite })
                                                    }
                                                    score={item.averageReviewRating}
                                                />
                                                <MultiClumpTooltip
                                                    className="pt-3"
                                                    label={item.title}
                                                    textClassName="2xl:text-2md"
                                                />
                                            </div>
                                        );
                                    })}
                                {isGotQuizzes && quizzes.total > 8 && (
                                    <ReferenceItem
                                        onClick={() => {
                                            nativeNavigate("/tests");
                                        }}
                                        subtitle={`${quizzes.total} ${tests(quizzes.total)}`}
                                    />
                                )}
                            </CardsBlock>
                        )}
                        {((isGotExercises && !isLoading) || (!isGotExercises && isLoading)) && (
                            <CardsBlock
                                title="Задания"
                                subtitle={isGotExercises ? `Всего ${exercises.total}` : ""}
                                onSeeAll={() => {
                                    nativeNavigate("/tasks");
                                }}
                                total={exercises?.total ?? 0}
                                id="userHomeTasksBlock"
                            >
                                {!isGotExercises &&
                                    Array.from(Array(4).keys()).map((p) => {
                                        return (
                                            <Fragment key={`card-skeleton__${p}`}>
                                                <div className="w-60 sm:w-[274px] sm:h-41 2xl:w-[342px] 2xl:h-51 rounded-2xl overflow-hidden leading-0">
                                                    <Skeleton className="rounded-2xl" width="100%" height="100%" />
                                                </div>
                                                <div className={"leading-5 line-clamp-2 pt-3"}>
                                                    <Skeleton className="rounded-2xl" width="100%" height="100%" />
                                                </div>
                                            </Fragment>
                                        );
                                    })}
                                {isGotExercises &&
                                    exercisesDataView?.map((item) => {
                                        return (
                                            <div
                                                key={item.resourceId}
                                                className="w-60 sm:w-[274px] 2xl:w-[342px] cursor-pointer"
                                                onClick={() => onNavigate(item)}
                                            >
                                                <Card
                                                    className="w-60 h-36 sm:w-[274px] sm:h-41 2xl:w-[342px] 2xl:h-51"
                                                    points={item.ratingPoints}
                                                    deadline={
                                                        item.deadlineTimestamp
                                                            ? dayjs(item.deadlineTimestamp * 1000)
                                                            : undefined
                                                    }
                                                    required={item.isRequired}
                                                    logoId={item.logoId}
                                                    isFavorite={item.isFavorite}
                                                    onFavoriteChange={(isFavorite) =>
                                                        onFavoriteChange({ resource: item, isFavorite })
                                                    }
                                                    score={item.averageReviewRating}
                                                />
                                                <MultiClumpTooltip
                                                    className="pt-3"
                                                    label={item.title}
                                                    textClassName="2xl:text-2md"
                                                />
                                            </div>
                                        );
                                    })}
                                {isGotExercises && exercises.total > 8 && (
                                    <ReferenceItem
                                        onClick={() => {
                                            nativeNavigate("/tasks");
                                        }}
                                        subtitle={`${exercises.total} ${tasks(exercises.total)}`}
                                    />
                                )}
                            </CardsBlock>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

interface ReferenceItemProps {
    __TYPE?: string;
    onClick: TVoidFunction;
    subtitle?: string;
}

const ReferenceItem = ({ onClick, subtitle }: ReferenceItemProps) => {
    return (
        <div
            className="flex flex-col w-60 h-36 sm:w-[274px] sm:h-41 2xl:w-[342px] 2xl:h-51 space-y-1 cursor-pointer rounded-2xl p-3 relative overflow-hidden group bg-blue-10 sm:bg-blue-lightest hover:!bg-blue-10 transition-all duration-300"
            onClick={onClick}
        >
            <div className="text-blue">Перейти к разделу</div>
            {subtitle && <div className="p4 text-gray">{subtitle}</div>}
            <Icon
                icon={Icons.ArrowLeft}
                width={240}
                height={240}
                className="hidden sm:block rotate-[135deg] absolute !m-0 !fill-blue-gray group-hover:!fill-blue transition-all duration-300 2xl:!w-75 2xl:!h-75 sm:top-[5px] sm:left-[88px] 2xl:top-0 2xl:left-[110px]"
            />
            <Icon
                icon={Icons.ArrowLeft}
                width={212}
                height={212}
                className="block sm:hidden rotate-[135deg] absolute !m-0 !fill-blue transition-all duration-300"
                style={{ left: 88, top: 5 }}
            />
        </div>
    );
};
