import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { components } from "react-select";
import { ColumnDef, PaginationState, SortingState } from "@tanstack/react-table";
import { Button, Checkbox, ComboBox, FormGroup, Input, Label, Modal, Table, Icon, Icons } from "Uikit";
import { UserListResponse } from "Api/Responses/UserResponse";
import { AchievementRequest } from "Api/Requests/AchievementRequest";
import { BaseArrayResponse, BasePaginationResponse } from "Api/BaseResponse";
import { ParentTeamChoiceModal } from "../../Teams/ParentTeamChoiceModal";
import { extendedRegexp } from "Uikit/Forms/Input";
import { UserAvatar } from "Uikit/UserAvatar/UserAvatar";
import { AchievementUserResponse } from "Api/Responses/AchievementResponse";
import { numCapEnd } from "helpers/numCapEnd";
import { ClearAllButton } from "Uikit/Forms/SelectCustomComponents/ClearAllButton";
import { IOption } from "types";
import Api from "Api/index";
import { LogoSize } from "Api/Services/UploadApi";

const ValueContainer = ({ children, ...props }: any) => {
    const {
        getValue,
        hasValue,
        isMulti,
        selectProps: { placeholder, inputValue },
    } = props;
    const itemsLength = getValue().length;

    const clearAll = () => {
        props.clearValue();
    };

    if (!hasValue || !isMulti) {
        return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
    }

    return (
        <components.ValueContainer {...props}>
            {!inputValue && (
                <components.Placeholder {...props}>
                    <div className="flex justify-between items-center w-full">
                        <span className="p1 2xl:text-md 2xl:!leading-[23px] text-black pl-3 2xl:pl-3">
                            {placeholder}
                        </span>
                        <ClearAllButton onClick={clearAll} itemsLength={itemsLength} />
                    </div>
                </components.Placeholder>
            )}
            {children[1]}
        </components.ValueContainer>
    );
};

const CheckboxOption = ({ children, ...props }: any) => {
    return (
        <components.Option {...props}>
            <Checkbox
                checked={props.isSelected}
                onChange={props.selectOption}
                label={children}
                className="pointer-events-none"
            />
        </components.Option>
    );
};

const DropdownIndicator = ({ ...props }: any) => {
    return (
        <components.DropdownIndicator {...props}>
            <Icon className="cursor-pointer sized" icon={Icons.List} width={20} height={20} color="fill-blue-drk" />
        </components.DropdownIndicator>
    );
};

interface IAddAchievementModal {
    id: string;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    achievement: AchievementRequest;
    offices: any;
    jobs: IOption[];
    achievements?: BasePaginationResponse<AchievementUserResponse>;
    fetch: (
        page: number,
        size: number,
        sort: SortingState,
        filters: any,
    ) => Promise<BaseArrayResponse<UserListResponse>>;
    onAdd: (users: string[], comment: string) => void;
}

export const AddAchievementModal = ({
    id,
    isOpen,
    setIsOpen,
    achievement,
    offices,
    jobs,
    achievements,
    fetch,
    onAdd,
}: IAddAchievementModal) => {
    const [{ pageIndex, pageSize }] = useState<PaginationState>({ pageIndex: 0, pageSize: 2000 });
    const [search, setSearch] = useState("");

    const [sorting, setSorting] = useState<SortingState>([{ id: "lastName,firstName", desc: false }]);
    const [rowSelection, setRowSelection] = useState({});

    const [nextStep, setNextStep] = useState(false);
    const [comment, setComment] = useState("");

    const [command, setCommand] = useState<any>([]);
    const [office, setOffice] = useState<any>([]);
    const [position, setPosition] = useState<any>([]);
    const [teamIds, setTeamIds] = useState<string[]>([]);

    const [isTeamModal, setIsTeamModal] = useState<boolean>(false);

    const pagination = useMemo(() => ({ pageIndex, pageSize }), [pageIndex, pageSize]);
    const dataQuery = useQuery(
        ["users", "list", pagination, sorting, search, command, office, position],
        () => {
            const filtersData: any = {};
            if (search.length > 0) {
                filtersData["searchQuery.contains"] = search;
            }
            if (command.length > 0) {
                filtersData["teamId.in"] = command.filter((item: IOption) => !!item).map((c: any) => c.value);
            }
            if (office.length > 0) {
                filtersData["office.in"] = office.map((c: any) => c.value);
            }
            if (position.length > 0) {
                filtersData["jobTitle.in"] = position.map((c: any) => c.value);
            }
            filtersData["state.equal"] = "ACTIVE";

            return fetch(pageIndex, pageSize, sorting, filtersData);
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );

    const columns = React.useMemo<ColumnDef<UserListResponse>[]>(
        () => [
            {
                id: "checkbox", // select
                enableResizing: true,
                size: 16,
                header: ({ table }) => (
                    <Checkbox
                        checked={table.getIsAllRowsSelected()}
                        indeterminate={table.getIsSomeRowsSelected()}
                        onChange={table.getToggleAllRowsSelectedHandler()}
                        disabled={achievement.maxQuantity != null}
                    />
                ),
                cell: ({ row }) => {
                    return (
                        <Checkbox
                            checked={row.getIsSelected()}
                            indeterminate={row.getIsSomeSelected()}
                            onChange={row.getToggleSelectedHandler()}
                            disabled={
                                achievement.maxQuantity != null && achievements
                                    ? achievements.Content.filter((p) => p.givenTo.id === row.original.id).length >=
                                      +achievement.maxQuantity
                                    : false
                            }
                        />
                    );
                },
            },
            {
                header: "Участник",
                accessorKey: "lastName,firstName",
                accessorFn: (row: any) => `${row.FirstName} ${row.LastName}`,
                cell: (info) => {
                    return (
                        <div className="group flex items-center space-x-3">
                            <UserAvatar
                                avatarId={info.row.original.avatarId}
                                color={info.row.original.defaultAvatarColor}
                                userInitials={`${info.row.original.firstName?.slice(
                                    0,
                                    1,
                                )}${info.row.original.lastName?.slice(0, 1)}`}
                                size={28}
                            />
                            <p className="p2 group-hover:text-blue">{`${info.row.original.lastName} ${info.row.original.firstName}`}</p>
                        </div>
                    );
                },
                footer: (props) => props.column.id,
            },
        ],
        [achievement, achievements],
    );

    const selectInputStyles = {
        position: "absolute",
        top: 0,
        left: 0,
        opacity: 0,
    };

    const controlButtons = (
        <div className="flex justify-between space-x-2 my-4">
            <ComboBox
                className="relative w-41 shrink-0"
                placeholder="Команды"
                isCreatable={false}
                isMulti={true}
                hideSelectedOptions={false}
                isClearable={false}
                components={{
                    ValueContainer,
                    DropdownIndicator,
                }}
                menuIsOpen={false}
                value={command}
                onChange={(option) => {
                    setCommand(option);
                    setTeamIds(
                        option
                            .filter((item) => {
                                return !!item;
                            })
                            .map(({ value }) => value),
                    );
                }}
                menuPortalTarget={null}
                onMenuOpen={() => setIsTeamModal(true)}
                styles={{
                    input: (baseStyles: any) => ({
                        ...baseStyles,
                        opacity: 0,
                    }),
                }}
                id="adminSettingsAddAchieveModalComboboxTeams"
                isSearchable={false}
                isModalOpen={isTeamModal}
                // select={{
                //     clear: () => {
                //         console.log('clear');
                //     }
                // }}
            />
            <ComboBox
                className="w-full"
                placeholder="Офисы"
                isCreatable={false}
                isMulti={true}
                hideSelectedOptions={false}
                isClearable={false}
                components={{
                    ValueContainer,
                    Option: CheckboxOption,
                }}
                options={offices}
                value={office}
                onChange={(option) => setOffice(option)}
                menuPortalTarget={null}
                closeMenuOnSelect={false}
                styles={{
                    container: (baseStyles: any) => ({
                        ...baseStyles,
                        position: "unset",
                    }),
                    menu: (baseStyles: any) => ({
                        ...baseStyles,
                        left: "0",
                        top: "15",
                    }),
                    menuList: (baseStyles: any) => ({
                        ...baseStyles,
                        maxHeight: 193,
                    }),
                    valueContainer: (baseStyles: any) => ({
                        ...baseStyles,
                        flexWrap: "nowrap",
                    }),
                    input: (baseStyles: any) => ({
                        ...baseStyles,
                        ...selectInputStyles,
                    }),
                }}
                id="adminSettingsAddAchieveModalComboboxOffices"
            />
            <ComboBox
                className="w-full"
                placeholder="Должности"
                isCreatable={false}
                isMulti={true}
                hideSelectedOptions={false}
                isClearable={false}
                components={{
                    ValueContainer,
                    Option: CheckboxOption,
                }}
                options={jobs}
                value={position}
                onChange={(option) => setPosition(option)}
                menuPortalTarget={null}
                closeMenuOnSelect={false}
                styles={{
                    container: (baseStyles: any) => ({
                        ...baseStyles,
                        position: "unset",
                    }),
                    menu: (baseStyles: any) => ({
                        ...baseStyles,
                        left: "0",
                        top: "15",
                    }),
                    menuList: (baseStyles: any) => ({
                        ...baseStyles,
                        maxHeight: 193,
                    }),
                    valueContainer: (baseStyles: any) => ({
                        ...baseStyles,
                        flexWrap: "nowrap",
                    }),
                    input: (baseStyles: any) => ({
                        ...baseStyles,
                        ...selectInputStyles,
                    }),
                }}
                id="adminSettingsAddAchieveModalComboboxPositions"
            />
        </div>
    );

    const resetSelected = () => {
        setTeamIds([]);
        setCommand([]);
        setPosition([]);
        setOffice([]);
    };

    return (
        <Modal
            id={id}
            className="!max-w-142 max-h-168 2xl:!max-h-[720px] border !border-blue-gray rounded-lg"
            title="Выдать ачивку"
            isOpen={isOpen}
            setIsOpen={(open) => {
                setIsOpen(open);

                setComment("");
                setNextStep(false);

                setRowSelection({});
                resetSelected();
            }}
        >
            <ParentTeamChoiceModal
                isOpen={isTeamModal}
                isMulti={true}
                title="Выбор команд"
                onSelect={(teamIds, _all, nodes) => {
                    setTeamIds(teamIds);
                    setCommand(
                        nodes?.filter((p) => p.id.indexOf("root:") === -1).map((p) => ({ label: p.name, value: p.id })),
                    );
                    setIsTeamModal(false);
                }}
                onClose={() => setIsTeamModal(false)}
                // checkedTeamIds={command.filter((item: {label: string, value: string}) => !!item).map((p: any) => p.value)}
                checkedTeamIds={teamIds}
            />
            {achievement.enabled && (
                <>
                    <Modal.Body
                        className="!min-h-[34rem] !max-h-[34rem] overflow-auto modalBody mt-6"
                        style={{ scrollbarGutter: "stable" }}
                    >
                        {!nextStep && (
                            <Table
                                columns={columns}
                                searchTitle="Поиск по участникам"
                                searchAlwaysLeft={true}
                                data={dataQuery.data?.Content ?? []}
                                rowSelection={rowSelection}
                                onRowSelectionChange={setRowSelection}
                                isFetching={dataQuery.isFetching}
                                pageCount={dataQuery ? dataQuery.data?.Content.length : 0}
                                controlsWrapperClassNames="flex-col w-full"
                                controlButtons={controlButtons}
                                rowClassName="!py-2 border-none after:bg-transparent"
                                rowTrClassName="rounded-md hover:bg-background"
                                columnClassName="!min-w-fit pb-4"
                                searchClassName="!w-full"
                                sorting={sorting}
                                onSortingChange={setSorting}
                                onSearch={(searchQuery: string) => setSearch(searchQuery)}
                                searchValue={search}
                            />
                        )}
                        {nextStep && (
                            <div className="mt-6">
                                <div className="flex justify-center">
                                    <div className="flex flex-grow space-x-4">
                                        {achievement.logoId && (
                                            <img
                                                className="rounded-full w-25 h-25 object-cover"
                                                src={Api.Upload.GetLogo(achievement.logoId, LogoSize.THUMB_MICRO)}
                                                alt={achievement.name}
                                            />
                                        )}
                                        {!achievement.logoId && <div className="rounded-full w-25 h-25 bg-gray-blue" />}
                                        <span className="my-auto p1 text-black">{achievement?.name}</span>
                                    </div>
                                    <span
                                        className="flex my-auto p1 text-primary"
                                        id="adminSettingsAddAchieveModalRatingLabel"
                                    >
                                        {achievement?.rating}{" "}
                                        {numCapEnd(
                                            { one: "балл", two: "балла", few: "баллов" },
                                            Number(achievement?.rating),
                                        )}
                                    </span>
                                </div>
                                <FormGroup className="mt-8">
                                    <Label>Комментарий</Label>
                                    <Input
                                        type="text"
                                        value={comment}
                                        onChange={(e) => setComment(e.target.value)}
                                        maxLength={200}
                                        cutRegExp={extendedRegexp}
                                        id="adminSettingsAddAchieveModalInputComment"
                                    />
                                </FormGroup>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer className="flex justify-between items-center py-4">
                        <p className="p2 text-gray-text" id="adminSettingsAddAchieveModalCounterLabel">
                            Выбрано {Object.keys(rowSelection).length}
                        </p>
                        <div className="space-x-4">
                            <Button
                                variant="outline"
                                size="medium"
                                color="secondary"
                                className="border-[#EAEDF3]"
                                onClick={() => {
                                    if (nextStep) {
                                        setNextStep(false);
                                    } else {
                                        setIsOpen(false);

                                        setRowSelection({});
                                        resetSelected();
                                    }
                                }}
                                id="adminSettingsAddAchieveModalBtnCancel"
                            >
                                {nextStep ? "Назад" : "Отмена"}
                            </Button>
                            <Button
                                disabled={Object.keys(rowSelection).length === 0}
                                size="medium"
                                color="primary"
                                onClick={() => {
                                    if (nextStep) {
                                        onAdd(Object.keys(rowSelection), comment);

                                        setRowSelection({});
                                        setIsOpen(false);
                                        resetSelected();

                                        setComment("");
                                        setNextStep(false);
                                    } else {
                                        setNextStep(true);
                                    }
                                }}
                                id="adminSettingsAddAchieveModalBtnOk"
                            >
                                {nextStep ? "Выдать" : "Далее"}
                            </Button>
                        </div>
                    </Modal.Footer>
                </>
            )}
            {!achievement.enabled && (
                <>
                    <Modal.Body>
                        Чтобы выдать ачивку, сначала необходимо изменить статус на «Выдача разрешена»
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={() => setIsOpen(false)}
                            variant="outline"
                            color="common"
                            className="border-[#EAEDF3]"
                            id="adminSettingsAddAchieveModalBtnClose"
                        >
                            Закрыть
                        </Button>
                    </Modal.Footer>
                </>
            )}
        </Modal>
    );
};
