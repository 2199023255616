import { CompleteMaterialRequest, StartMaterialRequest } from "Api/Requests/CourseProgressRequest";
import BaseApi from "../BaseApi";
import {
    CourseStartResponse,
    MaterialCompleteResponse,
    CourseProgressResponse,
} from "Api/Responses/CourseProgressResponse";

export default class CourseProgressApi extends BaseApi {
    StartCourse = async (courseId: string): Promise<CourseStartResponse> => {
        return await this.Get(new CourseStartResponse(), `/start/${courseId}`);
    };

    StartMaterial = async (data: StartMaterialRequest): Promise<MaterialCompleteResponse> => {
        return await this.Post(new MaterialCompleteResponse(), `/start-material`, data);
    };

    CompleteMaterial = async (data: CompleteMaterialRequest): Promise<MaterialCompleteResponse> => {
        return await this.Post(new MaterialCompleteResponse(), `/complete-material`, data);
    };

    Progress = async (courseId: string): Promise<CourseProgressResponse> => {
        return await this.Get(new CourseProgressResponse(), `/end-stats/${courseId}`);
    };
}
