import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ColumnDef, ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { useQuery } from "react-query";
import {
    Button,
    Checkbox,
    CustomFormatUserOptionsLabel,
    Icon,
    Icons,
    PopoverList,
    SelectMultiValueRemove,
    SelectValueContainer,
    Table,
} from "Uikit";
import { MultiClumpTooltip } from "Components/MultiClumpTooltip/MultiClumpTooltip";
import isEmpty from "lodash/isEmpty";
import { useNavigate } from "react-router-dom";
import { BasePaginationResponse } from "Api/BaseResponse";
import { TaskListResponse } from "Api/Responses/TaskResponse";
import { TasksRowActionPopover } from "./TasksRowActionPopover";
import { ResourceState, ResourceType, RoleName } from "Enums";
import { Filter, IFilterRow } from "Components/Filter/Filter";
import { TaskListRequest } from "Api/Requests/TaskRequest";
import { DateFormat, formatDate } from "helpers/dateHelper";
import { AccountableUser, UserListResponse } from "Api/Responses/UserResponse";
import { Confirmation } from "Components/Confirmation/Confirmation";
import { useDialog } from "hooks/useDialog";
import { useInvalidate } from "hooks/useInvalidate";
import Api from "Api";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useScreenSize } from "hooks/useMediaQuery";
import { TasksEmpty } from "./TasksEmpty";
import { TVoidFunction } from "types";
import { LogoSize } from "Api/Services/UploadApi";
import { useResponsibleList } from "Api/Hooks/useResponsibleList";

interface ITasksTable {
    categoryId?: string;
    selectedCategories: string[];
    onState: (ids: string[], state: string, resourceType: ResourceType) => Promise<void>;
    onCopy: (id: string) => void;
    fetchTasks: (props: TaskListRequest) => Promise<BasePaginationResponse<TaskListResponse>>;
    onCreateCategory?: TVoidFunction;
}

export const TasksTable = ({
    categoryId,
    selectedCategories,
    onState,
    onCopy,
    fetchTasks,
    onCreateCategory,
}: ITasksTable) => {
    const navigate = useNavigate();
    const invalidate = useInvalidate();
    const [rowSelection, setRowSelection] = useState({});
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [isFilterShow, setIsFilterShow] = useState(false);
    const [sorting, setSorting] = useState<SortingState>([{ id: "lastModified", desc: true }]);
    const [filters, setFilters] = useState<{ [id: string]: any }>({});
    const [isSelectedState, setIsSelectedState] = useState(false);
    const [isTasksArchived, setIsTasksArchived] = useState(false);
    const currentUser = useCurrentUser();
    const { size } = useScreenSize();

    const isFiltersEmpty = useMemo(() => {
        if (Object.keys(filters).indexOf("searchQuery.contains") !== -1) {
            return false;
        }

        return Object.keys(filters).every((f) => !filters[f]);
    }, [filters]);

    const { dialogState, openDialog, closeDialog } = useDialog();

    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 15,
    });

    const pagination = useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize],
    );

    const { data: ratingInfo } = useQuery(["tasks/filter-rating-info"], Api.Task.FilterRatingInfo);

    const dataQuery = useQuery(
        ["tasks", "collection", pagination, sorting, categoryId, selectedCategories, filters],
        async () => {
            const tasksRequest = new TaskListRequest();
            tasksRequest.size = pageSize;
            tasksRequest.page = pageIndex;
            tasksRequest.sort = sorting.map((s: any) => `${s.id},${s.desc ? "desc" : "asc"}`).join(";") || "desc";

            const filtersKeys = Object.keys(filters);
            const filtersData: any = {};

            for (const element of filtersKeys) {
                if (element === "changeDateTime") {
                    filtersData[element + ".greaterThanOrEqual"] = filters[element]["date"]["startDate"].toISOString();
                    filtersData[element + ".lessThanOrEqual"] = filters[element]["date"]["endDate"].toISOString();
                } else if (element === "questionsCount" || element === "ratingPoints") {
                    if (filters[element]["minValue"]) {
                        filtersData[element + ".greaterThanOrEqual"] = filters[element]["minValue"];
                    }
                    if (filters[element]["maxValue"]) {
                        filtersData[element + ".lessThanOrEqual"] = filters[element]["maxValue"];
                    }
                } else if (element === "complexity") {
                    filtersData[element + ".greaterThanOrEqual"] = filters[element]["minValue"];
                    filtersData[element + ".lessThanOrEqual"] = filters[element]["maxValue"];
                } else if (element === "assignedToTeam.in") {
                    filtersData[element] = filters[element].join(",");
                } else if (["accountableUser.in", "assignedToUser.in"].includes(element)) {
                    filtersData[element] = filters[element].map((p: any) => p.value.id).join(",");
                } else if (
                    ["hasDeadline", "isDeadlinePassed", "isHidden", "hasPublicAccess", "isNotAssigned"].includes(
                        element,
                    )
                ) {
                    filtersData[element + ".equal"] = filters[element];
                } else {
                    filtersData[element] = filters[element];
                }
            }

            if (categoryId) {
                filtersData["categoryId.equal"] = categoryId;
            }
            if (!filtersData["assignedToTeam.in"]?.length) {
                delete filtersData["assignedToTeam.in"];
            }

            if (selectedCategories.length !== 0) {
                filtersData["categoryId.in"] = selectedCategories.join(",");
            }

            tasksRequest.filters = filtersData;

            return await fetchTasks(tasksRequest);
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );

    const onSearch = (searchQuery: string) => {
        setFilters((prevState) => ({ ...prevState, "searchQuery.contains": searchQuery }));
    };

    const onCopyHandler = useCallback(
        async (task: TaskListResponse) => {
            openDialog({
                title: "Создание копии задания",
                description: "«" + task.title + "»",
                content: "Вы уверены, что хотите создать копию задания? Копируется всё задание со всеми настройками",
                closeBtnText: "Отмена",
                submitBtnText: "Создать копию",
                submitBtnColor: "primary",
                onRequestClose: () => closeDialog(),
                onRequestSubmit: () => {
                    onCopy(task.id);

                    invalidate("tasks");
                    closeDialog();
                },
            });
        },
        [invalidate, closeDialog, openDialog, onCopy],
    );
    const onArchiveHandler = useCallback(
        async (data: TaskListResponse | string[]) => {
            setIsSelectedState(false);
            const isChecked = Array.isArray(data);

            const onRequestSubmit = (ids: string[]) => {
                (async () => {
                    setIsTasksArchived(true);
                    setRowSelection({});

                    await onState(ids, ResourceState.ARCHIVED, ResourceType.EXERCISE);
                    invalidate("tasks");

                    setIsTasksArchived(false);
                    closeDialog();
                })();
            };

            const ids: string[] = !Array.isArray(data) ? [data.id] : data;
            const isMultiple = isChecked && data.length > 1;
            const available = await Api.Test.StateCheck(ids, ResourceState.ARCHIVED, ResourceType.EXERCISE);

            const { id, title } = isChecked ? dataQuery.data!.Content.find((i) => i.id === data[0])! : data;

            if (available.length !== ids.length) {
                openDialog({
                    title: "Архивирование невозможно",
                    description: isMultiple
                        ? `Выбрано заданий: ${ids.length}`
                        : `«${dataQuery.data?.Content.find((i) => i.id === ids[0])!.title}»`,
                    content: isMultiple
                        ? available.length > 0
                            ? "Некоторые выделенные задания нельзя архивировать, пока не оценено их выполнение в разделе «Проверка». Вы можете архивировать часть заданий или скрыть все выделенные задания, чтобы они перестали отображаться у пользователей"
                            : "Выделенные задания нельзя архивировать, пока не оценено их выполнение в разделе «Проверка». Вы можете скрыть выделенные задания, чтобы они перестали отображаться у пользователей"
                        : "Задание нельзя архивировать, так как задание находится в разделе «Проверка». Вы можете скрыть задание для пользователей или архивировать после проверки результатов задания",
                    closeBtnText: "Отмена",
                    submitBtnText: isMultiple ? "Скрыть все" : "Скрыть",
                    submitBtnColor: "danger",
                    onRequestClose: () => closeDialog(),
                    onRequestSubmit: async () => {
                        setIsTasksArchived(true);
                        setRowSelection({});

                        await onState(ids, ResourceState.HIDDEN, ResourceType.EXERCISE);
                        invalidate("tasks");

                        setIsTasksArchived(false);
                        closeDialog();
                    },
                    onRequestSubmitAdd:
                        isMultiple && available.length > 0
                            ? () => {
                                  setIsTasksArchived(true);
                                  onRequestSubmit(available);
                                  invalidate("tasks");
                                  setIsTasksArchived(false);
                              }
                            : undefined,
                    submitBtnAddText: `Архивировать ${available.length} из ${ids.length}`,
                });

                return;
            }

            openDialog({
                title: isMultiple ? "Архивирование заданий" : "Архивирование задания",
                description: isMultiple ? `Выбрано заданий: ${data.length}` : "«" + title + "»",
                content: isMultiple
                    ? "Вы действительно хотите архивировать задания? Задания переместятся в Архив и исчезнут у пользователей"
                    : "Вы действительно хотите архивировать задание? Задание переместится в Архив и исчезнет у пользователей",
                closeBtnText: "Отмена",
                submitBtnText: "Архивировать",
                submitBtnColor: "danger",
                onRequestClose: () => closeDialog(),
                onRequestSubmit: () => {
                    onRequestSubmit(isChecked ? data : [id]);
                },
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [invalidate, closeDialog, openDialog, onState, dataQuery.data],
    );
    const onStateHandler = useCallback(
        async (state: string) => {
            setIsSelectedState(false);
            const ids = Object.keys(rowSelection);
            const isMultiple = ids.length > 1;

            let content = `У всех пользователей ${!isMultiple ? "пропадет данное задание" : "пропадут данные задания"}`;

            if (state === ResourceState.ACTIVE) {
                content = `Все пользователи, у кого есть доступ, увидят ${
                    !isMultiple ? "данное задание" : "данные задания"
                }`;
            }

            openDialog({
                title: `Изменить статус на «${state === ResourceState.ACTIVE ? "Активен" : "Скрыт"}»`,
                description: isMultiple
                    ? `Выбрано заданий: ${ids.length}`
                    : `«${(dataQuery.data?.Content.find(({ id }) => id === ids[0]) as TaskListResponse).title}»`,
                content: content,
                closeBtnText: "Отмена",
                submitBtnText: "Изменить",
                submitBtnColor: "primary",
                onRequestClose: () => closeDialog(),
                onRequestSubmit: async () => {
                    setRowSelection({});
                    await onState(ids, state, ResourceType.EXERCISE);

                    invalidate("tasks");
                    closeDialog();
                },
            });
        },
        [invalidate, closeDialog, openDialog, rowSelection, onState, dataQuery.data],
    );

    const columns = useMemo<ColumnDef<TaskListResponse>[]>(
        () => [
            {
                id: "select",
                enableResizing: true,
                size: 16,
                header: ({ table }) => (
                    <Checkbox
                        checked={table.getIsAllRowsSelected()}
                        indeterminate={table.getIsSomeRowsSelected()}
                        onChange={table.getToggleAllRowsSelectedHandler()}
                    />
                ),
                cell: ({ row }) => (
                    <Checkbox
                        checked={row.getIsSelected()}
                        indeterminate={row.getIsSomeSelected()}
                        onChange={row.getToggleSelectedHandler()}
                    />
                ),
            },
            {
                header: "Название",
                footer: (props) => props.column.id,
                className: "w-1/2",
                cell: ({ row: { original: task }, ...info }) => {
                    const navToPage = () => {
                        navigate(`/admin/task/${task.id}`);
                    };
                    return (
                        <div className="group flex items-center space-x-3 cursor-pointer" onClick={navToPage}>
                            {task.logoId && (
                                <img
                                    className="rounded-md cursor-pointer object-cover h-[36px]"
                                    width={"54px"}
                                    height={"36px"}
                                    src={Api.Upload.GetLogo(task.logoId, LogoSize.THUMB_MICRO)}
                                />
                            )}
                            <MultiClumpTooltip
                                className="group-hover:text-blue"
                                clamp={1}
                                label={String(info.getValue())}
                            />
                        </div>
                    );
                },
                accessorKey: "title",
            },
            {
                header: "Статус",
                footer: (props) => props.column.id,
                enableResizing: true,
                size: 100,
                cell: (info) => {
                    return info.getValue() === ResourceState.HIDDEN ? (
                        <span className="text-[#939393]">Скрыт</span>
                    ) : (
                        "Активен"
                    );
                },
                accessorKey: "state",
            },
            {
                header: "Баллы",
                enableResizing: true,
                size: 100,
                accessorKey: "complexityRating",
                footer: (props) => props.column.id,
                cell: (info) => {
                    return <>{info.row.original.ratingPoints}</>;
                },
            },
            {
                header: "Обновлен",
                enableResizing: true,
                size: 200,
                accessorKey: "lastModified",
                cell: (info) => {
                    return formatDate(new Date(Number(info.getValue()) * 1000), DateFormat.DATE_TIME_LONG);
                },
                footer: (props) => props.column.id,
            },
            {
                header: "",
                enableResizing: true,
                size: 90,
                id: "buttons",
                accessor: "[row identifier to be passed to button]",
                cell: ({ row }) => {
                    return (
                        <div className="flex" id={"adminTasksTableGroupButton" + row.id}>
                            <Button
                                shape={"round"}
                                color={"common"}
                                icon={
                                    <Icon
                                        icon={Icons.Pencil}
                                        width="18px"
                                        height="18px"
                                        color="fill-blue-drk hover:fill-blue-hover"
                                    />
                                }
                                iconPlacement={"center"}
                                onClick={() => {
                                    navigate(`/admin/task/${row.original.id}/edit`);
                                }}
                            />

                            <TasksRowActionPopover
                                task={row.original}
                                onCopyHandler={onCopyHandler}
                                onArchiveHandler={onArchiveHandler}
                            />
                        </div>
                    );
                },
            },
        ],
        [navigate, onArchiveHandler, onCopyHandler],
    );

    const responsibleList = useResponsibleList();
    const loadResponsibleListOptions = useCallback(
        async (inputValue: string): Promise<any> => {
            if (inputValue === "") {
                return {
                    options: [],
                    hasMore: false,
                };
            }

            const selectOptions = responsibleList
                .filter((p) => p.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1)
                .map((p) => ({ label: p.label, value: p.payload }));

            return {
                options: selectOptions,
                hasMore: false,
            };
        },
        [responsibleList],
    );

    const loadActiveUserOptions = async (
        inputValue: string,
        prevOptions: unknown,
        { page }: { page: number },
    ): Promise<any> => {
        if (inputValue === "") {
            return {
                options: [],
                hasMore: false,
            };
        }

        const users = await Api.User.GetActiveListOptions(inputValue, page);
        const usersSelectOptions: { label: string; value: AccountableUser | UserListResponse | undefined | string }[] =
            users.Content.map((p) => ({ label: p.lastName + " " + p.firstName, value: p }));

        return {
            options: usersSelectOptions,
            hasMore: users.TotalPages > page,
            additional: {
                page: page + 1,
            },
        };
    };

    const controlButtons = (
        <div className="flex items-center space-x-4 w-full">
            <div className="flex flex-grow space-x-4">
                {!isEmpty(rowSelection) && (
                    <>
                        <Button
                            onClick={() => onArchiveHandler(Object.keys(rowSelection))}
                            color="danger"
                            size="medium"
                            id="adminTasksBtnArchive"
                        >
                            Архивировать
                        </Button>
                        <PopoverList
                            offset={[0, 10]}
                            visible={isSelectedState}
                            onClickOutside={() => setIsSelectedState(false)}
                        >
                            <Button
                                variant="outline"
                                size="medium"
                                color="common"
                                className="border-gray-blue"
                                icon={<Icon className="" icon={Icons.ChevronDown} width={10} height={6} />}
                                iconPlacement="right"
                                onClick={() => setIsSelectedState(true)}
                                id="adminTasksBtnChangeStatus"
                            >
                                Изменить статус
                            </Button>
                            <PopoverList.Item name="setStatusHide" onClickItem={() => onStateHandler("HIDDEN")}>
                                Скрыт
                            </PopoverList.Item>
                            <PopoverList.Item name="setStatusActive" onClickItem={() => onStateHandler("ACTIVE")}>
                                Активен
                            </PopoverList.Item>
                        </PopoverList>
                    </>
                )}
            </div>

            <Button
                variant="outline"
                color="secondary"
                size="medium"
                className="border-[#E6E9ED] rounded-lg font-medium"
                icon={<Icon icon={Icons.Filter} width={20} height={20} color="stroke-blue" />}
                iconPlacement={"left"}
                onClick={() => setIsFilterShow(true)}
                id="adminTasksBtnFilter"
            >
                Фильтры
            </Button>

            <Button
                size={"medium"}
                className="rounded-lg whitespace-nowrap font-medium"
                icon={<Icon icon={Icons.PlusFilled} color="fill-white" width={20} height={20} />}
                iconPlacement={"left"}
                onClick={() => navigate(`/admin/task${categoryId ? `?categoryId=${categoryId}` : ""}`)}
                id="adminTaskBtnCreateTask"
            >
                Создать задание
            </Button>
        </div>
    );

    const filtersConfig = useMemo(
        () => [
            {
                label: "Дата обновления",
                fields: [
                    {
                        accessor: "changeDateTime",
                        type: "date-range",
                    },
                ],
            },
            {
                label: "Кол-во вопросов, шт",
                fields: [
                    {
                        accessor: "questionsCount",
                        type: "range",
                        min: ratingInfo?.minQuestionsCount,
                        max: ratingInfo?.maxQuestionsCount,
                    },
                ],
            },
            {
                label: "Награда, баллы",
                fields: [
                    {
                        accessor: "ratingPoints",
                        type: "range",
                        min: ratingInfo?.minRatingPoints,
                        max: ratingInfo?.maxRatingPoints,
                    },
                ],
            },
            {
                label: "Ответственный",
                fields: [
                    {
                        accessor: "accountableUser.in",
                        type: "async-multi-select",
                        placeholder: "Выберите ответственного",
                        loadOptions: loadResponsibleListOptions,
                        formatOptionLabel: CustomFormatUserOptionsLabel({ size }),
                        components: {
                            MultiValueRemove: SelectMultiValueRemove,
                            ValueContainer: SelectValueContainer({}),
                        },
                        lazy: true,
                    },
                ],
            },
            {
                label: "Назначенные на команду",
                fields: [
                    {
                        accessor: "assignedToTeam.in",
                        type: "team-multi-select",
                    },
                ],
            },
            {
                label: "Назначенные на участника",
                fields: [
                    {
                        accessor: "assignedToUser.in",
                        type: "async-multi-select",
                        placeholder: "Выберите участника",
                        loadOptions: loadActiveUserOptions,
                        formatOptionLabel: CustomFormatUserOptionsLabel({ size }),
                        components: {
                            ValueContainer: SelectValueContainer({}),
                        },
                        lazy: true,
                    },
                ],
            },
            // {
            //     label: "Категория",
            //     fields: [
            //         {
            //             accessor: "categoryId",
            //             type: "select",
            //             default: "",
            //             placeholder: "Выберите категорию",
            //             options: categories,
            //         },
            //     ],
            // },
            {
                label: "Дополнительно",
                className: "!border-b-0 !p-0",
                resetButton: false,
                fields: [
                    {
                        accessor: "hasDeadline",
                        label: "С дедлайном",
                        type: "checkbox",
                        default: false,
                    },
                    {
                        accessor: "isDeadlinePassed",
                        label: "Дедлайн прошел",
                        type: "checkbox",
                        default: false,
                    },
                    {
                        accessor: "isHidden",
                        label: "Скрытые",
                        type: "checkbox",
                        default: false,
                    },
                    {
                        accessor: "hasPublicAccess",
                        label: "С общим доступом",
                        type: "checkbox",
                        default: false,
                    },
                    {
                        accessor: "isNotAssigned",
                        label: "Никому не назначены",
                        type: "checkbox",
                        default: false,
                    },
                ],
            },
        ],
        [loadResponsibleListOptions, ratingInfo, size],
    );

    useEffect(() => {
        setFilters({});
        setRowSelection({});
    }, [categoryId]);

    return (
        <>
            <Confirmation
                {...dialogState}
                requestSubmitDisabled={isTasksArchived}
                requestSubmitAddDisabled={isTasksArchived}
            />

            {isFiltersEmpty && dataQuery.data?.Content && dataQuery.data?.Content.length === 0 && (
                <TasksEmpty
                    categoryId={categoryId}
                    onCreateCategory={onCreateCategory}
                    onCreateTask={() => navigate(`/admin/task${categoryId ? `?categoryId=${categoryId}` : ""}`)}
                />
            )}
            {!isFiltersEmpty || (dataQuery.data?.Content && dataQuery.data?.Content.length > 0) ? (
                <>
                    <Filter
                        isActive={isFilterShow}
                        setIsActive={setIsFilterShow}
                        configuration={filtersConfig as IFilterRow[]}
                        filters={filters}
                        onChange={setFilters}
                        hideTeamMembers={currentUser?.data?.role === RoleName.ADMIN}
                    />
                    <Table
                        id="adminTasks"
                        columns={columns}
                        searchTitle="Поиск по названию"
                        pageCount={dataQuery.data?.TotalPages}
                        data={dataQuery.data?.Content}
                        pagination={pagination}
                        onPaginationChange={setPagination}
                        isFetching={dataQuery.isFetching}
                        rowSelection={rowSelection}
                        onRowSelectionChange={setRowSelection}
                        onColumnFiltersChange={setColumnFilters}
                        columnFilters={columnFilters}
                        controlButtons={controlButtons}
                        emptyMessage={"По заданным параметрам результатов нет"}
                        sorting={sorting}
                        onSortingChange={setSorting}
                        onSearch={onSearch}
                    />
                </>
            ) : null}
        </>
    );
};
