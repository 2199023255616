import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import SelectComponents from "react-select";

import Api from "Api";
import { StatisticTestTeamsRequest } from "Api/Requests/StatisticRequest";
import { TeamTreeAllBasicInfoResponse } from "Api/Responses/TeamResponse";
import { StatisticsAttempts, ResourceType } from "Enums";
import { TrainingMultiSelect } from "Components/Common/TrainingMultiSelect";
import { Button, ComboBox, FormGroup, Label } from "Uikit";
import { TeamMultiSelect } from "Components/Common/TeamMultiSelect";
import { numWord } from "helpers/numWord";
import { IRequestSettingsItem, ReportSettings } from "Pages/Admin/Statistics/ReportSettings/ReportSettings";
import { flattenTeams } from "models/Team";

import { IStatisticValue } from "../../utils";
import { SingleValueContainer } from "../../SingleValueContainer";
import { attemptsOptions } from "../TestWithoutCourse";
import { ReportTable } from "./ReportTable";

export const reportSettings = [
    {
        title: "Команда",
        key: "team",
        items: [
            {
                title: "Все",
                value: "all",
                checked: true,
            },
            {
                title: "Команда",
                value: "name",
                checked: true,
            },
            {
                title: "Путь к команде",
                value: "teamPath",
                checked: true,
            },
            {
                title: "Наставники",
                value: "mentors",
                checked: true,
            },
            {
                title: "Кол-во участников в команде",
                value: "memberCount",
                checked: true,
            },
        ],
    },
    {
        title: "Тест",
        key: "quiz",
        items: [
            {
                title: "Все",
                value: "all",
                checked: true,
            },
            {
                title: "Кол-во участников с результатом «Пройден»",
                value: "passedCount",
                checked: true,
            },
            {
                title: "Кол-во участников с результатом «Провален»",
                value: "failedCount",
                checked: true,
            },
            {
                title: "Кол-во участников с результатом «Не начат»",
                value: "notStartedCount",
                checked: true,
            },
            {
                title: "Кол-во участников с результатом «В процессе»",
                value: "inProgressCount",
                checked: true,
            },
            {
                title: "Кол-во участников с результатом «На проверке»",
                value: "onReviewCount",
                checked: true,
            },
            {
                title: "Кол-во участников с результатом «Без доступа»",
                value: "noAccessCount",
                checked: true,
            },
            {
                title: "% участников с результатом «Пройден»",
                value: "passedPercentage",
                checked: true,
            },
            {
                title: "% участников с результатом «Провален»",
                value: "failedPercentage",
                checked: true,
            },
            {
                title: "% участников с результатом «Не начат»",
                value: "notStartedPercentage",
                checked: true,
            },
            {
                title: "% участников с результатом «В процессе»",
                value: "inProgressPercentage",
                checked: true,
            },
            {
                title: "% участников с результатом «На проверке»",
                value: "onReviewPercentage",
                checked: true,
            },
            {
                title: "% участников с результатом «Без доступа»",
                value: "noAccessPercentage",
                checked: true,
            },
            {
                title: "Необходимый % правильных ответов",
                value: "requiredPassedAnswersPercentage",
                checked: true,
            },
            {
                title: "Средний % правильных ответов",
                value: "averagePassedAnswersPercentage",
                checked: true,
            },
            {
                title: "Среднее время прохождения теста",
                value: "averageCompletionTime",
                checked: true,
            },
            {
                title: "Среднее кол-во использованных попыток",
                value: "averageUsedAttempts",
                checked: true,
            },
        ],
    },
];

export const TestTeamsWithoutCourse = () => {
    const [showReportTable, setShowReportTable] = useState(false);
    const [testsValues, setTestsValues] = useState<IStatisticValue[]>([]);
    const [attempts, setAttempts] = useState(attemptsOptions[1]);
    const [showReportSettings, setShowReportSettings] = useState(false);
    const [selectedTeams, setSelectedTeams] = useState<string[]>([]);

    //запрашиваем список всех команд
    const { data: teams = [] as TeamTreeAllBasicInfoResponse[] } = useQuery(
        ["teams", "tree", "basic-info", "collection"],
        async () => await Api.Teams.TreeAllBasicInfo([]),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            staleTime: 5 * 60 * 1000,
        },
    );

    const onSubmitTestSection = (selectedItems: any[]) => {
        setTestsValues(
            selectedItems.map(({ id, name, logoId }) => {
                return {
                    label: name,
                    value: id,
                    name,
                    id,
                    logoId,
                };
            }),
        );

        setShowReportTable(false);
    };

    const createReport = () => {
        setShowReportTable(true);
    };

    const createPayloadParams = () => {
        const payload: StatisticTestTeamsRequest = {
            quizIds: testsValues.map(({ id }) => id),
            teamsIds: selectedTeams,
            attemptNumber: attempts.value,
        };

        return payload;
    };

    const onSubmit = async (settingsChecked: IRequestSettingsItem[]) => {
        const request: any = {};

        request.params = createPayloadParams();

        settingsChecked.forEach(({ key, items }: IRequestSettingsItem) => {
            request[key] = items
                .filter((item) => item.value !== "all")
                .reduce((prev, cur) => {
                    return {
                        ...prev,
                        [cur.value]: cur.checked,
                    };
                }, {});
        });

        setShowReportSettings(false);

        try {
            await Api.Statistic.GenerateTestTeamsStatisticReportFile(request);
        } catch (error: any) {
            console.log(error);
        }
    };

    //записываем id всеx команд в выбранные
    useEffect(() => {
        setSelectedTeams(flattenTeams(teams).map((team) => team.id));
    }, [teams]);

    return (
        <>
            {/* Выбор теста */}
            <TrainingMultiSelect
                className="!mt-0"
                value={testsValues[0] ?? null}
                onSubmiTreeSection={onSubmitTestSection}
                placeholder="Выберите тест"
                resourceType={ResourceType.QUIZ}
                components={
                    {
                        ValueContainer: SingleValueContainer,
                    } as unknown as SelectComponents
                }
                checkable={false}
                selectable={true}
                checkOnNameClick={true}
                showSelectedCounter={false}
                isMulti={false}
            />
            {testsValues.length > 0 && (
                <>
                    <div className="mt-7 flex-wrap relative z-[70]">
                        <div className="grid items-center gap-6.5 grid-cols-2">
                            <FormGroup className="!mb-0">
                                <Label>Команды</Label>
                                <TeamMultiSelect
                                    value={selectedTeams}
                                    onChange={(v) => {
                                        setSelectedTeams(v);
                                        setShowReportTable(false);
                                    }}
                                    placeholder="Выберите команды"
                                    checkable
                                    showTeams
                                    stacked={true}
                                    allPlaceholder={flattenTeams(teams).length === selectedTeams.length}
                                />
                            </FormGroup>
                            <FormGroup className="!mb-0">
                                <Label>Собрать статистику</Label>
                                <ComboBox
                                    onChange={(value) => {
                                        setAttempts(value as { value: StatisticsAttempts; label: string });
                                        setShowReportTable(false);
                                    }}
                                    value={attempts}
                                    options={attemptsOptions}
                                    isSearchable={false}
                                    isCreatable={false}
                                    isClearable={false}
                                />
                            </FormGroup>
                        </div>
                    </div>
                    <div className="mt-9">
                        {showReportTable ? (
                            <>
                                <ReportTable
                                    queryPayload={createPayloadParams()}
                                    onClickLoadReportFile={() => setShowReportSettings(true)}
                                />
                                <ReportSettings
                                    isActive={showReportSettings}
                                    setIsActive={setShowReportSettings}
                                    settings={reportSettings}
                                    onSubmit={onSubmit}
                                    isReportFileFetching={false}
                                />
                            </>
                        ) : (
                            <Button
                                key={1}
                                onClick={() => createReport()}
                                variant="standard"
                                size={"medium"}
                                color={"primary"}
                                className="w-full"
                                disabled={selectedTeams.length === 0}
                            >
                                {`Сформировать отчет по ${selectedTeams.length} ${numWord(selectedTeams.length, [
                                    "команде",
                                    "командам",
                                    "командам",
                                ])}`}
                            </Button>
                        )}
                    </div>
                </>
            )}
        </>
    );
};
