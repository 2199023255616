import React from "react";
import { Button, FormField, InputField, Modal } from "Uikit";
import { Office, TVoidFunction } from "types";
import { Except, SetOptional } from "type-fest";
import { Formik, FormikHelpers } from "formik";
import Api from "Api";
import { OfficeRequest } from "Api/Requests/OfficeRequest";
import { useInvalidate } from "hooks/useInvalidate";
import { object, string } from "yup";
import { testingRegexp } from "Uikit/Forms/Input";
import { BadRequestResponse, ErrorCode } from "Api/BaseResponse";

const modalTitle = {
    add: {
        text: "Создание офиса",
    },
    edit: {
        text: "Редактирование офиса",
    },
    remove: {
        text: "Удаление офиса",
    },
};

interface OfficeModalProps {
    isOpen: boolean;
    selectedOffice?: Office;
    type: "add" | "edit" | "remove";
    id: string;
    onClose: TVoidFunction;
}

export const OfficeModal = ({ isOpen, type, onClose, selectedOffice, ...rest }: OfficeModalProps) => {
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={modalTitle[type].text}
            description={type === "remove" ? selectedOffice?.name : ""}
        >
            <ModalContent type={type} onClose={onClose} selectedOffice={selectedOffice} {...rest} />
        </Modal>
    );
};

type OfficeFields = SetOptional<Office, "id">;

const regexp = new RegExp(/[^a-zA-Z0-9.,\-"'()«»!@#№$%^&*_+=?;:|/~><\][{}]?/g);

const validationSchema = object().shape({
    name: string()
        .required("Поле обязательно для заполнения")
        .max(256, "Невозможно ввести символов  больше разрешенной длины")
        .matches(regexp, "В поле нельзя ввести недопустимые символы"),
    country: string()
        .required("Поле обязательно для заполнения")
        .max(256, "Невозможно ввести символов  больше разрешенной длины")
        .matches(regexp, "В поле нельзя ввести недопустимые символы"),
    region: string()
        .required("Поле обязательно для заполнения")
        .max(256, "Невозможно ввести символов  больше разрешенной длины")
        .matches(regexp, "В поле нельзя ввести недопустимые символы"),
    address: string()
        .required("Поле обязательно для заполнения")
        .max(256, "Невозможно ввести символов  больше разрешенной длины")
        .matches(regexp, "В поле нельзя ввести недопустимые символы"),
});

const ModalContent = ({ selectedOffice, type, id, onClose }: Except<OfficeModalProps, "isOpen">) => {
    const invalidate = useInvalidate();

    const initialValues: OfficeFields = selectedOffice ?? {
        name: "",
        country: "",
        region: "",
        address: "",
        userIds: [],
    };

    const handleSubmit = async (values: OfficeFields, { setErrors }: FormikHelpers<OfficeFields>) => {
        try {
            if (type === "add") {
                const request = Object.assign(new OfficeRequest(), values);
                await Api.Office.Create(request);
            } else if (type === "edit") {
                const request = Object.assign(new OfficeRequest(), values);
                await Api.Office.Edit(request);
            } else if (type === "remove") {
                await Api.Office.Remove(selectedOffice!.id);
            }

            invalidate("offices");
            onClose();
        } catch (e) {
            if (e instanceof BadRequestResponse && e.errorCode === ErrorCode.OFFICE_ALREADY_EXISTS) {
                setErrors({ name: "Данное название уже используется" });
            } else {
                console.log(e);
            }
        }
    };

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
            {({ handleSubmit, values, dirty }) => {
                const doSubmit = () => {
                    if (dirty || type === "remove") {
                        handleSubmit();
                    } else {
                        onClose();
                    }
                };

                const submitDisabled =
                    values.name.length === 0 ||
                    values.country.length === 0 ||
                    values.region.length === 0 ||
                    values.address.length === 0;

                return (
                    <>
                        <Modal.Body>
                            {type === "remove" ? (
                                <div className="mb-6 mt-3">Вы действительно хотите удалить офис? </div>
                            ) : (
                                <div className="mb-6 mt-3">
                                    <FormField name="name" label="Название офиса" isRequired>
                                        <InputField
                                            placeholder="Центральный"
                                            id="createOfficeModalInput1"
                                            cutRegExp={testingRegexp}
                                        />
                                    </FormField>

                                    <FormField name="country" label="Страна" isRequired>
                                        <InputField
                                            placeholder="Россия"
                                            id="createOfficeModalInput2"
                                            cutRegExp={testingRegexp}
                                        />
                                    </FormField>

                                    <FormField name="region" label="Регион и город" isRequired>
                                        <InputField
                                            placeholder="Московская область, Коломна"
                                            id="createOfficeModalInput3"
                                            cutRegExp={testingRegexp}
                                        />
                                    </FormField>

                                    <FormField name="address" label="Адрес локации" isRequired>
                                        <InputField
                                            placeholder="Советская площадь, 8"
                                            id="createOfficeModalInput4"
                                            cutRegExp={testingRegexp}
                                        />
                                    </FormField>
                                </div>
                            )}
                        </Modal.Body>

                        <Modal.Footer className="space-x-4" id={id}>
                            <Button
                                onClick={onClose}
                                variant="outline"
                                size={"medium"}
                                color={"secondary"}
                                className={"border-[#EAEDF3] "}
                                id="createOfficeModalBtnCancel"
                            >
                                Отмена
                            </Button>

                            {selectedOffice && selectedOffice.userIds?.length === 0 && type === "remove" ? null : (
                                <Button
                                    onClick={doSubmit}
                                    size={"medium"}
                                    color={type === "remove" ? "danger" : "primary"}
                                    id="createOfficeModalBtnOk"
                                    disabled={submitDisabled}
                                >
                                    {type === "add" && "Создать"}
                                    {type === "edit" && "Сохранить"}
                                    {type !== "add" && type !== "edit" && "Удалить"}
                                </Button>
                            )}
                        </Modal.Footer>
                    </>
                );
            }}
        </Formik>
    );
};
