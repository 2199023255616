import BaseApi from "../BaseApi";
import { BaseResponse } from "../BaseResponse";
import { FileReadResponse } from "../Responses/FileResponse";
import { FileUploadType } from "Enums";

export default class ScormApi extends BaseApi {
    UploadFile = async (
        data: File,
        progressHandler?: any,
        token?: any,
        isDisposableLink = false,
        fileUploadType?: FileUploadType,
    ): Promise<FileReadResponse> => {
        const formData = new FormData();
        formData.append("file", data);

        const type = fileUploadType ? `type=${fileUploadType}` : "";
        const link = isDisposableLink ? "link=true" : "";

        const params = [type, link].filter((param) => param).join("&");

        return await this.Upload(new FileReadResponse(), `/upload?${params}`, formData, progressHandler, token);
    };

    Remove = async (fileId: string): Promise<BaseResponse> => {
        return await this.Delete(BaseResponse, "/delete/" + fileId);
    };
}
