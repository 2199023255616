export const userAvatarBgColors = [
    "#FF0361",
    "#FF4300",
    "#FF6200",
    "#FF8F00",
    "#FFA800",
    "#FFB800",
    "#FFCA00",
    "#C6DB10",
    "#8ACE1D",
    "#1AC65D",
    "#09CF79",
    "#04CEBA",
    "#31C7F6",
    "#16B9FF",
    "#2B91FF",
    "#2273FF",
    "#2B55EF",
    "#5F00FF",
    "#8338EC",
    "#C55DF6",
];
