import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import clsx from "clsx";
import Api from "Api";
import { CommonsAddFavoritesRequest, CommonsRemoveFavoritesRequest } from "Api/Requests/CommonsRequest";
import {
    TTaskPublicAttemptsChoiceContent,
    TTaskPublicAttemptsFileContent,
    TTaskPublicAttemptsImageContent,
    TTaskPublicAttemptsNumberContent,
    TTaskPublicAttemptsTextContent,
} from "Api/Responses/TaskPublicResponse";
import { ProgressStatus, ResourceState, ResourceType, TaskItemType } from "Enums";
import { Breadcrumbs, Checkbox, FormGroup, Icon, Icons, RadioGroup, Loader, UserAvatar, FavoriteButton } from "Uikit";
import { ContentLayout } from "Containers";
import { humanFileSize } from "helpers/humanFileSize";
import { FILE_INPUT_ACCEPTED_TYPES, FILES_TYPE_ICONS, FILES_TYPE_SHORT_NAME } from "constants/attachments";
import { formatLeadTime, formatTime } from "../Tasks/utils";
import { TaskQuestionText } from "./TaskQuestionText";
import { TaskQuestionNumber } from "./TaskQuestionNumber";
import { DateFormat, formatDate, formatStatusChangeTime } from "helpers/dateHelper";
import { setIsBack, setIsFavorite, setIsFavoriteSelected, setIsHidden, setTitle } from "../../../slices/headerSlice";
import { useDispatch, useSelector } from "react-redux";
import { IReducer } from "../../../store";
import { numCapEnd } from "helpers/numCapEnd";
import { LogoSize } from "Api/Services/UploadApi";

interface ITaskStatusBlockProps {
    status?: ProgressStatus;
    rating?: number;
}

const TaskStatusBlock = ({ status = ProgressStatus.PASSED, rating = 0 }: ITaskStatusBlockProps) => {
    const isPassed = status === ProgressStatus.PASSED;

    return (
        <div
            className={clsx(
                "py-4 sm:py-4 2xl:py-5 sm:px-11 2xl:px-16 rounded-2xl 2xl:rounded-3xl flex-col flex-center gap-2.5 2xl:gap-3 w-full sm:w-[274px] 2xl:w-[342px]",
                isPassed ? "bg-blue-5 text-primary" : "bg-red-10 text-red",
            )}
        >
            <div
                className={clsx(
                    "w-[54px] 2xl:w-[68px] h-[54px] 2xl:h-[68px] rounded-full flex-center shrink-0",
                    isPassed ? "bg-primary" : "bg-red",
                )}
            >
                {isPassed ? (
                    <Icon
                        icon={Icons.TickAll}
                        width={24}
                        height={24}
                        color={"fill-white"}
                        className="2xl:!w-7.5 2xl:!h-7.5"
                    />
                ) : (
                    <Icon
                        icon={Icons.Close}
                        width={24}
                        height={24}
                        color={"fill-white"}
                        className="2xl:!w-7.5 2xl:!h-7.5"
                    />
                )}
            </div>
            <h3 className="font-medium sm:p1 2xl:text-lg">
                {isPassed &&
                    `Принято${
                        rating
                            ? ` (+${rating} ${numCapEnd({ one: "балл", two: "балла", few: "баллов" }, Number(rating))})`
                            : ""
                    }`}
                {!isPassed && `Отклонено`}
            </h3>
        </div>
    );
};

export const TaskStatistics = () => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const isFavoriteSelected = useSelector((state: IReducer) => state.header.isFavoriteSelected);

    const [isAddFavoritePressed, setIsAddFavoritePressed] = useState(false);
    const [expandedSectionId, setExpandedSectionId] = useState<string | null>(null);

    const [coverLoading, setCoverLoading] = useState(true);

    const hostname = window.location.hostname.includes("localhost")
        ? "http://localhost:3000"
        : window.location.protocol + "//" + window.location.hostname;

    const dataQuery = useQuery(
        ["statistics", "collection", id],
        async () => {
            const res = await Api.TaskPublic.Statistics({ uuid: id as string });

            dispatch(setIsFavoriteSelected(res.isFavorite));
            setIsAddFavoritePressed(res.isFavorite);

            return res;
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );

    const { data, isLoading, isError } = dataQuery;

    // Добавление в избранное
    const { mutateAsync: addFavorites } = useMutation((payload: CommonsAddFavoritesRequest) => {
        return Api.Commons.addFavorites(payload);
    });
    const handleAddFavorites = useCallback(() => {
        addFavorites(
            Object.assign(new CommonsAddFavoritesRequest(), {
                resourceId: data?.resourceId,
                passingNumber: data?.passingNumber,
                title: data?.title,
                logoId: data?.logoId,
                deadlineTimestamp: 0,
                ratingPoints: data?.ratingPoints ?? 0,
                state: ResourceState.ACTIVE,
                type: ResourceType.EXERCISE,
                progressStatus: data?.progressStatus,
            }),
        ).then();
        setIsAddFavoritePressed(true);
        dispatch(setIsFavoriteSelected(true));
    }, [dispatch, data, addFavorites]);

    // Удаление из избранного
    const { mutateAsync: removeFavorites } = useMutation((payload: CommonsRemoveFavoritesRequest) => {
        return Api.Commons.removeFavorites(payload);
    });
    const handleRemoveFavorites = useCallback(() => {
        removeFavorites(
            Object.assign(new CommonsRemoveFavoritesRequest(), {
                resourceId: data?.resourceId,
                passingNumber: data?.passingNumber,
                type: ResourceType.EXERCISE,
            }),
        ).then();
        setIsAddFavoritePressed(false);
        dispatch(setIsFavoriteSelected(false));
    }, [dispatch, data, removeFavorites]);

    useEffect(() => {
        dispatch(setIsHidden(false));
        dispatch(setIsBack(true));
        dispatch(setTitle("Задание"));
        dispatch(setIsFavorite(true));
    }, [dispatch]);
    useEffect(() => {
        if (window.screen.width > 568 || !data || isFavoriteSelected === isAddFavoritePressed) {
            return;
        }

        isFavoriteSelected ? handleAddFavorites() : handleRemoveFavorites();
    }, [isFavoriteSelected, data, isAddFavoritePressed, handleAddFavorites, handleRemoveFavorites]);

    return (
        <ContentLayout className="mx-auto sm:pl-4 sm:pr-6.5 max-w-[1216px] 2xl:max-w-[1506px]">
            <Breadcrumbs className="hidden sm:block">
                <Breadcrumbs.Link title="Задание" url="/tasks" />
                {data?.title && <Breadcrumbs.Link title={data?.title} />}
            </Breadcrumbs>
            {isLoading && (
                <div className="w-full h-full flex-center px-10">
                    <Loader />
                </div>
            )}
            {isError && (
                <div className="w-full h-full flex-center px-10">
                    <p className="text-md text-center text-red">
                        Произошла ошибка запроса - невозможно загрузить страницу
                    </p>
                </div>
            )}
            {data && (
                <div className="w-full">
                    <div className="flex flex-col-reverse sm:flex-row justify-between sm:mb-8 2xl:mb-10 rounded-2xl 2xl:rounded-3xl sm:bg-background-light">
                        <div className="block sm:hidden mt-10 mb-10 w-full h-0.25 bg-gray-blue"></div>
                        <div className="sm:px-10 2xl:px-12.5 pt-4 sm:py-8 2xl:py-10 flex flex-col flex-grow">
                            <h1 className="hidden sm:block mb-1 2xl:mb-2 2xl:!text-4xl 2xl:!leading-[41px] break-anywhere">
                                {data.title}
                            </h1>
                            <h2 className="block sm:hidden mb-2 break-anywhere">{data.title}</h2>
                            <p className="mb-2 sm:mb-8 2xl:mb-11 w-full text-gray-dark 2xl:text-md break-anywhere">
                                {data.description}
                            </p>
                            <div className="mt-auto flex flex-col sm:flex-row sm:items-center gap-2 sm:gap-7.5 2xl:gap-9.5">
                                <div className="flex flex-row sm:flex-col items-center sm:items-start">
                                    <p className="text-gray-text mr-5 sm:mr-0 sm:mb-0.5 p4 2xl:p3 w-17.5 sm:w-auto">
                                        Статус
                                    </p>
                                    <p
                                        className={clsx(
                                            "rounded-md 2xl:rounded-lg p3 2xl:p2 py-0.5 2xl:py-0.75",
                                            [ProgressStatus.PASSED, ProgressStatus.RE_WORK].includes(
                                                data.progressStatus,
                                            ) && "px-1.5 2xl:px-2 text-white",
                                            data.progressStatus === ProgressStatus.PASSED && "bg-primary",
                                            data.progressStatus === ProgressStatus.RE_WORK && "bg-red",
                                        )}
                                    >
                                        {data.progressStatus === ProgressStatus.RE_WORK && "Отклонено"}
                                        {data.progressStatus === ProgressStatus.ON_REVIEW && "На проверке"}
                                        {data.progressStatus === ProgressStatus.PASSED && "Принято"}
                                        {data.progressStatus === ProgressStatus.IN_PROGRESS && "В процессе"}
                                    </p>
                                </div>
                                {!!data.passingNumber && (
                                    <div className="flex flex-row sm:flex-col">
                                        <p className="text-gray-text mr-5 sm:mr-0 sm:mb-0.5 p4 2xl:p3 w-17.5 sm:w-auto">
                                            Прохождение
                                        </p>
                                        <p className="py-0.5 p3 2xl:p2">{data.passingNumber}</p>
                                    </div>
                                )}
                                {data.progressStatus === ProgressStatus.PASSED && (
                                    <div className="flex flex-row sm:flex-col">
                                        <p className="text-gray-text mr-5 sm:mr-0 sm:mb-0.5 p4 2xl:p3 w-17.5 sm:w-auto">
                                            Получено
                                        </p>
                                        <p className="py-0.5 p3 2xl:p2">
                                            {data.ratingPoints}{" "}
                                            {numCapEnd(
                                                { one: "балл", two: "балла", few: "баллов" },
                                                Number(data.ratingPoints),
                                            )}
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="flex-shrink-0">
                            <div className="group relative">
                                {coverLoading && (
                                    <div className="top-1/2 left-1/2 absolute -translate-x-1/2 -translate-y-1/2">
                                        <Loader />
                                    </div>
                                )}
                                <img
                                    src={Api.Upload.GetLogo(data.logoId, LogoSize.ORIGINAL)}
                                    className={clsx(
                                        "w-full sm:w-100 2xl:w-125 h-[60vw] sm:h-61 2xl:h-[305px] object-cover rounded-2xl 2xl:rounded-3xl",
                                        coverLoading && "invisible",
                                    )}
                                    onLoad={() => {
                                        setCoverLoading(false);
                                    }}
                                    alt={data.title}
                                />
                                {/* Добавить в избранное */}
                                <FavoriteButton
                                    onChange={
                                        data?.isFavorite || isAddFavoritePressed
                                            ? handleRemoveFavorites
                                            : handleAddFavorites
                                    }
                                    isFavorite={(data?.isFavorite && !isAddFavoritePressed) || isAddFavoritePressed}
                                    className="absolute top-3 right-3 2xl:top-3.75 2xl:right-3.75"
                                />
                                <div className="absolute top-3 sm:top-4 2xl:top-5 left-3 sm:left-4 2xl:left-5 flex gap-1.5 2xl:gap-2 h-5.25 2xl:h-6.5">
                                    <div className="flex items-center rounded-md 2xl:rounded-lg bg-black-50 text-white p3 px-1.5 2xl:px-2 py-0.5 2xl:py-0.75 2xl:text-2sm">
                                        <span>Задание</span>
                                    </div>
                                    {data.attempts?.filter(({ progressStatus }) => {
                                        return progressStatus === ProgressStatus.PASSED;
                                    }).length > 0 && (
                                        <div className="flex items-center rounded-md 2xl:rounded-lg bg-black-50 text-white p3 px-1.5 2xl:px-2 py-0.5 2xl:py-0.75 2xl:text-2sm">
                                            <span>Пройдено {formatStatusChangeTime(data.finishTime)}</span>
                                        </div>
                                    )}
                                    {data.deadLinePassed && (
                                        <div className="flex items-center rounded-md 2xl:rounded-lg bg-red text-white p3 px-1.5 2xl:px-2 py-0.5 2xl:py-0.75 2xl:text-2sm">
                                            <span>Просрочено</span>
                                        </div>
                                    )}
                                    {!!data.onReviewTime && (
                                        <div className="flex items-center rounded-md 2xl:rounded-lg bg-black-50 text-white p3 px-1.5 2xl:px-2 py-0.5 2xl:py-0.75 2xl:text-2sm">
                                            <span>
                                                Отправлено{" "}
                                                {formatStatusChangeTime(data.onReviewTime, { showTime: true })}
                                            </span>
                                        </div>
                                    )}
                                </div>
                                <div className="absolute bottom-3 sm:bottom-4 2xl:bottom-5 left-3 sm:left-4 2xl:left-5 right-3 sm:right-4 2xl:right-5 flex justify-between h-5.25 2xl:h-6.5">
                                    <div className="rounded-md 2xl:rounded-lg bg-black-50 text-white flex items-center gap-1 2xl:gap-1.25 p3 px-1.5 2xl:px-2 py-0.5 2xl:py-0.75 2xl:text-2sm">
                                        <Icon
                                            icon={Icons.TrophyFilled}
                                            width={"14px"}
                                            height={"14px"}
                                            color={"fill-white"}
                                        />
                                        <span>{data.maxRatingPoints}</span>
                                    </div>
                                    {![ProgressStatus.ON_REVIEW, ProgressStatus.PASSED].includes(data.progressStatus) &&
                                        !!data.timeSpent && (
                                            <div className="flex items-center rounded-md 2xl:rounded-lg bg-black-50 text-white p3 px-1.5 2xl:px-2 py-0.5 2xl:py-0.75 2xl:text-2sm">
                                                <span>{formatTime(data.timeSpent)}</span>
                                            </div>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col sm:gap-5 2xl:gap-6.5 w-full">
                        {data?.attempts
                            ?.filter(({ progressStatus }) =>
                                [ProgressStatus.FAILED, ProgressStatus.PASSED].includes(progressStatus),
                            )
                            .sort((a, b) => b.finishTime - a.finishTime)
                            .map((attempt, index) => {
                                const isExpanded = attempt.id === expandedSectionId;

                                return (
                                    <Fragment key={attempt.id}>
                                        <div>
                                            <div
                                                className="flex justify-between gap-5 cursor-pointer mb-4 2xl:mb-5"
                                                onClick={() => {
                                                    setExpandedSectionId(isExpanded ? null : attempt.id);
                                                }}
                                            >
                                                <h2 className="hidden sm:block 2xl:!text-2xl 2xl:!leading-[35px]">
                                                    {data.attempts.filter(({ progressStatus }) =>
                                                        [ProgressStatus.FAILED, ProgressStatus.PASSED].includes(
                                                            progressStatus,
                                                        ),
                                                    ).length - index}{" "}
                                                    попытка
                                                </h2>
                                                <h3 className="block sm:hidden">
                                                    {data.attempts.filter(({ progressStatus }) =>
                                                        [ProgressStatus.FAILED, ProgressStatus.PASSED].includes(
                                                            progressStatus,
                                                        ),
                                                    ).length - index}{" "}
                                                    попытка
                                                </h3>
                                                <div
                                                    className={clsx(
                                                        "w-7.5 h-7.5 rounded-full flex flex-col items-center justify-center sm:bg-background-light 2xl:w-[35px] 2xl:h-[35px]",
                                                        !isExpanded && "rotate-180",
                                                    )}
                                                >
                                                    <Icon
                                                        icon={Icons.ChevronUp}
                                                        width={15}
                                                        height={15}
                                                        color={"fill-blue-drk"}
                                                        className="2xl:!w-4.5 2xl:!h-4.5"
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex flex-col-reverse sm:flex-row items-stretch mb-4 sm:mb-0 gap-3 sm:gap-6.5 2xl:gap-8">
                                                <div className="flex flex-col sm:flex-row flex-grow rounded-2xl 2xl:rounded-3xl sm:flex-center bg-background-light px-4 sm:px-9 2xl:px-[45px] py-6 2xl:py-8 gap-4 2xl:gap-5">
                                                    <div className="flex flex-row sm:flex-col flex-grow items-center sm:items-start">
                                                        <div className="mr-2 sm:mr-0 w-9 2xl:w-[45px] h-9 2xl:h-[45px] rounded-full flex-center shrink-0 bg-blue-gray sm:mb-2 2xl:mb-2.5">
                                                            <Icon
                                                                icon={Icons.TickAll}
                                                                width={20}
                                                                height={20}
                                                                color={"fill-blue-drk"}
                                                                className="2xl:!w-6.25 2xl:!h-6.25"
                                                            />
                                                        </div>
                                                        <div>
                                                            <p className="p2 2xl:text-md text-gray-text">
                                                                Ответил на вопросы
                                                            </p>
                                                            <h3 className="text-black font-medium sm:p1-accent 2xl:text-lg">
                                                                {attempt.questionAnsweredCount} из{" "}
                                                                {attempt.questionCount}
                                                            </h3>
                                                        </div>
                                                    </div>
                                                    {[ProgressStatus.PASSED, ProgressStatus.FAILED].includes(
                                                        attempt.progressStatus,
                                                    ) &&
                                                        !!attempt.timeSpent && (
                                                            <>
                                                                <div className="hidden sm:block border-l border-solid border-gray-stroke h-full"></div>
                                                                <div className="flex flex-row sm:flex-col flex-grow items-center sm:items-start">
                                                                    <div className="mr-2 sm:mr-0 w-9 2xl:w-[45px] h-9 2xl:h-[45px] rounded-full flex-center shrink-0 bg-blue-gray sm:mb-2 2xl:mb-2.5">
                                                                        <Icon
                                                                            icon={Icons.Time}
                                                                            width={20}
                                                                            height={20}
                                                                            color={"fill-blue-drk"}
                                                                            className="2xl:!w-6.25 2xl:!h-6.25"
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <p className="p2 2xl:text-md text-gray-text">
                                                                            Время выполнения
                                                                        </p>
                                                                        <h3 className="text-black font-medium sm:p1-accent 2xl:text-lg">
                                                                            {formatLeadTime(attempt.timeSpent)}
                                                                        </h3>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    <div className="hidden sm:block border-l border-solid border-gray-stroke h-full"></div>
                                                    <div className="flex flex-row sm:flex-col flex-grow items-center sm:items-start">
                                                        <div className="mr-2 sm:mr-0 w-9 2xl:w-[45px] h-9 2xl:h-[45px] rounded-full flex-center shrink-0 bg-blue-gray sm:mb-2 2xl:mb-2.5">
                                                            <Icon
                                                                icon={Icons.UserMenuIconCalendar}
                                                                width={20}
                                                                height={20}
                                                                color={"fill-blue-drk"}
                                                                className="2xl:!w-6.25 2xl:!h-6.25"
                                                            />
                                                        </div>
                                                        <div>
                                                            <p className="p2 2xl:text-md text-gray-text">Пройдено</p>
                                                            <h3 className="text-black font-medium sm:p1-accent 2xl:text-lg">
                                                                {formatStatusChangeTime(attempt.finishTime, {
                                                                    showTime: true,
                                                                    delimiter: "",
                                                                })}
                                                            </h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <TaskStatusBlock
                                                    status={attempt.progressStatus}
                                                    rating={attempt.ratingPoints}
                                                />
                                            </div>
                                        </div>
                                        {isExpanded && (
                                            <>
                                                {/* Уведомление */}
                                                {!!data.filesDeleteTime && (
                                                    <div className="mt-3 sm:mt-0 mb-5 sm:mb-0 flex sm:items-center rounded-xl 2xl:rounded-2xl border border-primary px-4 sm:px-4 2xl:px-5 py-2.5 sm:py-2.5 2xl:py-3 gap-1.5 2xl:gap-2 w-full">
                                                        <Icon
                                                            className="min-w-6 2xl:!w-7.5 2xl:!h-7.5"
                                                            icon={Icons.Info}
                                                            width={24}
                                                            height={24}
                                                            color="fill-primary"
                                                        />
                                                        <p className="p2 2xl:text-md">
                                                            Все загруженные файлы и изображения будут удалены{" "}
                                                            {formatStatusChangeTime(data.filesDeleteTime, {
                                                                showTime: true,
                                                                showYear: true,
                                                                formatTonight: false,
                                                                delimiter: ",",
                                                            })}
                                                        </p>
                                                    </div>
                                                )}
                                                {/* Коммент */}
                                                {attempt.comment && (
                                                    <div className="mb-5 sm:mb-0 w-full">
                                                        <div className="py-5 px-4 sm:py-7.5 2xl:py-9.5 sm:px-9 2xl:px-11 rounded-xl 2xl:rounded-2xl border border-gray-stroke relative">
                                                            <div className="flex gap-3.5 mb-4 2xl:mb-5">
                                                                <UserAvatar
                                                                    avatarId={attempt.comment.user.avatarId}
                                                                    color={attempt.comment.user.defaultAvatarColor}
                                                                    userInitials={`${attempt.comment.user.firstName?.slice(
                                                                        0,
                                                                        1,
                                                                    )}${attempt.comment.user.lastName?.slice(0, 1)}`}
                                                                    size={window.screen.width >= 1600 ? 45 : 36}
                                                                />
                                                                <div>
                                                                    <p className="p2 mb-0.5 2xl:mb-0.75 2xl:text-md">
                                                                        {attempt.comment.user.firstName}{" "}
                                                                        {attempt.comment.user.lastName}
                                                                    </p>
                                                                    {attempt.comment.user.jobTitle && (
                                                                        <p className="p3 2xl:p2 text-gray-text">
                                                                            {attempt.comment.user.jobTitle.name}
                                                                        </p>
                                                                    )}
                                                                    {!!attempt.comment.checkedTime && (
                                                                        <p className="p3 2xl:p2 text-gray-text">
                                                                            Проверил{" "}
                                                                            {formatDate(
                                                                                new Date(
                                                                                    Number(
                                                                                        attempt.comment.checkedTime,
                                                                                    ) * 1000,
                                                                                ),
                                                                                DateFormat.DATE_TIME_LONG,
                                                                            )}
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="flex gap-3.5 2xl:text-md">
                                                                <p>{attempt.comment.comment}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {[
                                                    ...(attempt.textContent ?? []),
                                                    ...(attempt.numericContent ?? []),
                                                    ...(attempt.imageContent ?? []),
                                                    ...(attempt.fileContent ?? []),
                                                    ...(attempt.singleChoiceContent ?? []),
                                                    ...(attempt.multipleChoiceContent ?? []),
                                                ]
                                                    .sort((a, b) => a.orderNumber - b.orderNumber)
                                                    .map((item) => {
                                                        const {
                                                            id: questionId,
                                                            question,
                                                            description: qustionDescription,
                                                            contentType,
                                                        } = item;

                                                        return (
                                                            <div
                                                                key={`${questionId}`}
                                                                className="mb-4 2xl:mb-0 w-full p-4 sm:p-6 2xl:p-7.5 rounded-xl 2xl:rounded-2xl bg-blue-lightest"
                                                            >
                                                                <div className="relative">
                                                                    <h4 className="2xl:text-md">{question}</h4>
                                                                </div>
                                                                {qustionDescription && (
                                                                    <p className="p2 2xl:p1 text-gray-text mt-1 2xl:mt-1.25">
                                                                        {qustionDescription}
                                                                    </p>
                                                                )}
                                                                <div className="mt-4 sm:mt-5 2xl:mt-6.25">
                                                                    {/* Текстовый вопрос */}
                                                                    {contentType === TaskItemType.Text && (
                                                                        <TaskQuestionText
                                                                            value={
                                                                                (item as TTaskPublicAttemptsTextContent)
                                                                                    .userText
                                                                            }
                                                                            available={false}
                                                                        />
                                                                    )}
                                                                    {/* Числовой вопрос */}
                                                                    {contentType === TaskItemType.Number && (
                                                                        <TaskQuestionNumber
                                                                            value={
                                                                                (
                                                                                    item as TTaskPublicAttemptsNumberContent
                                                                                ).userNumber
                                                                            }
                                                                            available={false}
                                                                        />
                                                                    )}
                                                                    {/* Вопрос типа "Один из списка" */}
                                                                    {contentType === TaskItemType.SingleChoice && (
                                                                        <RadioGroup
                                                                            value={
                                                                                (
                                                                                    item as TTaskPublicAttemptsChoiceContent
                                                                                ).answers.find(
                                                                                    ({ isUserChecked }) =>
                                                                                        isUserChecked,
                                                                                )?.id as string
                                                                            }
                                                                            listClassName="!bg-transparent"
                                                                        >
                                                                            {(
                                                                                item as TTaskPublicAttemptsChoiceContent
                                                                            ).answers.map((answer) => {
                                                                                return (
                                                                                    <RadioGroup.Button
                                                                                        key={`TaskItemChoiceItem_${answer.id}`}
                                                                                        label={answer.answer}
                                                                                        value={answer.id}
                                                                                        checked={answer.isUserChecked}
                                                                                        className={
                                                                                            "pointer-events-none"
                                                                                        }
                                                                                    />
                                                                                );
                                                                            })}
                                                                        </RadioGroup>
                                                                    )}
                                                                    {/* Вопрос типа "Несколько из списка" */}
                                                                    {contentType === TaskItemType.MultipleChoice && (
                                                                        <FormGroup
                                                                            value={(
                                                                                item as TTaskPublicAttemptsChoiceContent
                                                                            ).answers
                                                                                .filter(
                                                                                    ({ isUserChecked }) =>
                                                                                        isUserChecked,
                                                                                )
                                                                                ?.map(({ id }) => id)}
                                                                            className="flex flex-col gap-4 !bg-transparent sm:!mb-0 2xl:gap-5"
                                                                        >
                                                                            {(
                                                                                item as TTaskPublicAttemptsChoiceContent
                                                                            ).answers.map((answer) => {
                                                                                return (
                                                                                    <Checkbox
                                                                                        key={`TaskItemChoiceItem_${answer.id}`}
                                                                                        checked={answer.isUserChecked}
                                                                                        variant="large"
                                                                                        label={answer.answer}
                                                                                        value={answer.id}
                                                                                        className={
                                                                                            "pointer-events-none"
                                                                                        }
                                                                                    />
                                                                                );
                                                                            })}
                                                                        </FormGroup>
                                                                    )}
                                                                    {/* Вопрос типа "Загрузка изображения" */}
                                                                    {contentType === TaskItemType.Image && (
                                                                        <div className="flex flex-wrap sm:grid sm:grid-cols-[repeat(3,180px)] 2xl:grid-cols-[repeat(3,225px)] gap-1 sm:gap-3.5 2xl:gap-6.25">
                                                                            {(
                                                                                item as TTaskPublicAttemptsImageContent
                                                                            ).images.map(
                                                                                ({
                                                                                    id,
                                                                                    name: fileName,
                                                                                    type,
                                                                                    size,
                                                                                }) => {
                                                                                    return (
                                                                                        <div
                                                                                            key={id}
                                                                                            className="w-[calc(33vw-22px)] sm:w-45 h-[calc(33vw-22px)] sm:h-45 2xl:w-[225px] 2xl:h-[225px] relative group"
                                                                                        >
                                                                                            <div className="absolute flex z-10 bottom-1 sm:bottom-3 2xl:bottom-3.75 right-1 sm:right-3 2xl:right-3.75 h-5.25 2xl:h-6.5">
                                                                                                <p className="flex items-center p4 sm:p3 2xl:p2 px-1.5 py-0.5 2xl:px-2 2xl:py-0.75 rounded-md 2xl:rounded-lg bg-black-50 text-white">
                                                                                                    {type?.replace(
                                                                                                        "image/",
                                                                                                        "",
                                                                                                    )}{" "}
                                                                                                    ·{" "}
                                                                                                    {humanFileSize(
                                                                                                        size,
                                                                                                        true,
                                                                                                    )}
                                                                                                </p>
                                                                                            </div>
                                                                                            <img
                                                                                                className={
                                                                                                    "w-full h-full rounded-2lg 2xl:rounded-xl object-cover"
                                                                                                }
                                                                                                src={`/service/lms-upload/api/file/download/${id}`}
                                                                                                alt={fileName}
                                                                                            />
                                                                                        </div>
                                                                                    );
                                                                                },
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                    {/* Вопрос типа "Загрузка файлов" */}
                                                                    {item.contentType === TaskItemType.File && (
                                                                        <div className="flex flex-col gap-2 sm:grid sm:grid-cols-[repeat(2,555px)] sm:gap-4 2xl:grid-cols-[repeat(2,692px)] 2xl:gap-5">
                                                                            {(
                                                                                item as TTaskPublicAttemptsFileContent
                                                                            ).files.map(
                                                                                ({
                                                                                    id,
                                                                                    name: fileName,
                                                                                    size,
                                                                                    type,
                                                                                }) => {
                                                                                    return (
                                                                                        <div
                                                                                            key={id}
                                                                                            className="flex p-4 2xl:p-5 border border-gray-blue bg-white rounded-lg 2xl:rounded-2lg group"
                                                                                        >
                                                                                            <div className="relative flex items-center w-full">
                                                                                                <Icon
                                                                                                    className="min-w-8 mr-6 2xl:mr-7.5 2xl:!w-10 2xl:!h-10"
                                                                                                    icon={
                                                                                                        FILES_TYPE_ICONS[
                                                                                                            FILE_INPUT_ACCEPTED_TYPES[
                                                                                                                type
                                                                                                            ]
                                                                                                        ] ||
                                                                                                        Icons.FileOther
                                                                                                    }
                                                                                                    width="32px"
                                                                                                    height="32px"
                                                                                                />
                                                                                                <div>
                                                                                                    <div className="flex items-center pb-1 sm:pb-2 2xl:pb-2.5 group-hover:text-blue-light break-all 2xl:text-md">
                                                                                                        {fileName}
                                                                                                    </div>
                                                                                                    <div className="text-gray-text 2xl:text-md">
                                                                                                        {`${
                                                                                                            FILES_TYPE_SHORT_NAME[
                                                                                                                FILE_INPUT_ACCEPTED_TYPES[
                                                                                                                    type
                                                                                                                ]
                                                                                                            ]
                                                                                                        } — ${humanFileSize(
                                                                                                            size,
                                                                                                            true,
                                                                                                        )}`}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="absolute top-0 right-0 flex items-center gap-4 2xl:gap-5">
                                                                                                    <a
                                                                                                        href={`${hostname}/service/lms-upload/api/file/download/${id}`}
                                                                                                        download={
                                                                                                            fileName
                                                                                                        }
                                                                                                    >
                                                                                                        <Icon
                                                                                                            icon={
                                                                                                                Icons.Download
                                                                                                            }
                                                                                                            width={20}
                                                                                                            height={20}
                                                                                                            color="fill-blue-drk"
                                                                                                            className="2xl:!w-6.25 2xl:!h-6.25"
                                                                                                        />
                                                                                                    </a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    );
                                                                                },
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                            </>
                                        )}
                                    </Fragment>
                                );
                            })}
                    </div>
                </div>
            )}
        </ContentLayout>
    );
};
