import React from "react";
import { TeamTreeAllBasicInfoResponse } from "Api/Responses/TeamResponse";
import { Modal } from "Uikit/Modal/Modal";
import { SecondaryButton } from "Uikit/Button/SecondaryButton";
import { SubmitButton } from "Uikit/Button/SubmitButton";
import { useMutation } from "react-query";
import Api from "Api/index";
import { TVoidFunction } from "types";

interface RemoveEmptyChildTeamModalProps {
    isOpen: boolean;
    onClose: TVoidFunction;
    team?: Partial<TeamTreeAllBasicInfoResponse>;
    openLoadingModal: (operationType: string) => void;
    closeLoadingModal: TVoidFunction;
}

export const RemoveEmptyChildTeamModal = ({
    isOpen,
    onClose,
    team,
    openLoadingModal,
    closeLoadingModal,
}: RemoveEmptyChildTeamModalProps) => {
    const { mutateAsync: removeTeam, isLoading } = useMutation(() => {
        return Api.Teams.Remove(team!.id!);
    });

    const handleSubmit = async () => {
        onClose();
        openLoadingModal("remove");
        await removeTeam();
        closeLoadingModal();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} title="Удаление команды" description={team?.name}>
            <Modal.Body>
                Вы действительно хотите удалить команду? Также будут удалены все вложенные в нее команды (если они есть)
            </Modal.Body>
            <Modal.Footer>
                <SecondaryButton onClick={onClose} disabled={isLoading} id="removeEmptyChildTeamBtnCancel">
                    Отмена
                </SecondaryButton>
                <SubmitButton
                    onClick={handleSubmit}
                    isLoading={isLoading}
                    color="danger"
                    id="removeEmptyChildTeamBtnOk"
                >
                    Удалить
                </SubmitButton>
            </Modal.Footer>
        </Modal>
    );
};
