import { SortingState } from "@tanstack/react-table";
import BaseApi from "Api/BaseApi";
import { BaseRequest /* , BasePaginationRequest */ } from "Api/BaseRequest";
import { BaseResponse, BasePaginationResponse } from "Api/BaseResponse";
import {
    InviteRequest,
    InviteRegistrationRequest,
    ChangeInviteStateRequest,
    LoginValidationRequest,
    PhoneValidationRequest,
    EmailValidationRequest,
} from "Api/Requests/InviteRequest";
import {
    InviteResponse,
    InviteListResponse,
    InvitedUsersListResponse,
    InviteJobsListResponse,
    InviteOfficeResponse,
} from "Api/Responses/InviteResponse";
import { LMSFilterParams } from "models/LMSFilterParams";
import { ID } from "types/ID";

export default class InvitesApi extends BaseApi {
    // Получение (просмотр) регистрационного приглашения
    // https://lms-dev.ext.grancall.ru/webjars/swagger-ui/index.html?urls.primaryName=ums#/invite-resource/getById_1
    Read = async (id: ID): Promise<InviteResponse> => {
        return await this.Get(InviteResponse, `/v1/invites/${id}`);
    };

    // Создание регистрационного приглашения
    // https://lms-dev.ext.grancall.ru/webjars/swagger-ui/index.html?urls.primaryName=ums#/invite-resource/create
    Create = async (data: InviteRequest): Promise<InviteResponse> => {
        return await this.Post(InviteResponse, "/v1/invites", data);
    };

    // Редактирование регистрационного приглашения
    // https://lms-dev.ext.grancall.ru/webjars/swagger-ui/index.html?urls.primaryName=ums#/invite-resource/edit
    Edit = async (data: InviteRequest): Promise<InviteResponse> => {
        return await this.Put(InviteResponse, "/v1/invites", data);
    };

    // Список регистрационных приглашений в админке - вкладка "Ссылки для регистрации" в разделе "Настройки/Участники"
    // https://lms-dev.ext.grancall.ru/webjars/swagger-ui/index.html?urls.primaryName=ums#/invite-resource/getAll_4
    List = async (
        page: number,
        size: number,
        sort: SortingState,
        // filter: { [id: string]: any },
    ): Promise<BasePaginationResponse<InviteListResponse>> => {
        const params = LMSFilterParams({ sort, page, size });

        return this.Get(new BasePaginationResponse(InviteListResponse), `/v1/invites?${params.toString()}`);
    };

    // Изменение статуса приглашений
    // https://lms-dev.ext.grancall.ru/webjars/swagger-ui/index.html?urls.primaryName=ums#/invite-resource/changeStateByIds
    State = async (data: ChangeInviteStateRequest): Promise<BaseResponse> => {
        return await this.Put(BaseResponse, "/v1/invites/state", data);
    };

    // Удаление приглашений
    // https://lms-dev.ext.grancall.ru/webjars/swagger-ui/index.html?urls.primaryName=ums#/invite-resource/deleteByIds
    Remove = async (data: { inviteIds: string[] }): Promise<BaseResponse> => {
        return await this.Delete(new BaseResponse(), "/v1/invites", Object.assign(new BaseRequest(), data));
    };

    // Регистрация по приглашению
    // https://lms-dev.ext.grancall.ru/webjars/swagger-ui/index.html?urls.primaryName=ums#/signup-resource/register
    Registration = async (data: InviteRegistrationRequest): Promise<BaseResponse> => {
        return await this.Post(new BaseResponse(), "/v1/signup/register", data);
    };

    // Список участников, зарег-ых по приглашению - вкладка "Ссылки для регистрации" в разделе "Настройки/Участники", колонка "кол-во участников"
    // https://lms-dev.ext.grancall.ru/webjars/swagger-ui/index.html?urls.primaryName=ums#/invite-resource/getAll_4
    UsersList = async (
        page: number,
        size: number,
        sort: SortingState,
        filter: { [id: string]: any },
    ): Promise<BasePaginationResponse<InvitedUsersListResponse>> => {
        const params = LMSFilterParams({ sort, page, size, filter });

        return this.Get(new BasePaginationResponse(InvitedUsersListResponse), `/v1/users/invited?${params.toString()}`);
    };

    // Проверка существования приглашения по его inviteId
    // https://lms-dev.ext.grancall.ru/webjars/swagger-ui/index.html?urls.primaryName=ums#/signup-resource/inviteExists
    InviteExists = async (inviteId: string): Promise<string> => {
        const res = await this.Get("", `/v1/signup/invite-exists/${inviteId}`);
        return JSON.parse(res);
    };

    // Валидация логина
    // https://lms-dev.ext.grancall.ru/webjars/swagger-ui/index.html?urls.primaryName=ums#/signup-resource/validateLogin
    LoginValidation = async (data: LoginValidationRequest): Promise<string> => {
        return await this.Post("", "/v1/signup/validate-login", data as BaseRequest);
    };

    // Валидация номера телефона
    // https://lms-dev.ext.grancall.ru/webjars/swagger-ui/index.html?urls.primaryName=ums#/signup-resource/validatePhone_1
    PhoneValidation = async (data: PhoneValidationRequest): Promise<string> => {
        return await this.Post("", "/v1/signup/validate-phone", data as BaseRequest);
    };

    // Валидация эл. почты
    // https://lms-dev.ext.grancall.ru/webjars/swagger-ui/index.html?urls.primaryName=ums#/signup-resource/validateEmail_1
    EmailValidation = async (data: EmailValidationRequest): Promise<string> => {
        return await this.Post("", "/v1/signup/validate-email", data as BaseRequest);
    };

    // Список должностей
    // https://lms-dev.ext.grancall.ru/webjars/swagger-ui/index.html?urls.primaryName=ums#/signup-resource/getJobTitles
    JobsList = async (inviteId: string): Promise<BasePaginationResponse<InviteJobsListResponse>> => {
        return await this.Get(
            new BasePaginationResponse(InviteJobsListResponse),
            `/v1/signup/job-titles?inviteId=${inviteId}`,
        );
    };

    // Список оффисов
    // https://lms-dev.ext.grancall.ru/webjars/swagger-ui/index.html?urls.primaryName=ums#/signup-resource/getOffices
    OfficeList = async (inviteId: string): Promise<BasePaginationResponse<InviteOfficeResponse>> => {
        return await this.Get(
            new BasePaginationResponse(InviteOfficeResponse),
            `/v1/signup/offices?inviteId=${inviteId}`,
        );
    };
}
