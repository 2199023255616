import React, { ReactNode } from "react";
import { Dialog } from "@headlessui/react";
import { BaseModal } from "./BaseModal";
import { TModalSyzes } from "./Modal";

interface SimpleModalProps {
    title?: string;
    description?: string;
    children: ReactNode;
    dialogText?: [string, string];
    isOpen: boolean;
    size?: TModalSyzes;
    id?: string;
}

export const SimpleModal = ({
    title = "",
    description = "",
    isOpen,
    children,
    size = "extraMedium",
    id,
}: SimpleModalProps) => {
    return (
        <BaseModal isOpen={isOpen} size={size} className={"px-6 2xl:px-7.5 py-3 2xl:py-7.5 text-center"} id={id}>
            {children}
            <div className={"w-full flex items-start px-6 2xl:px-7.5 mt-6.5 2xl:mt-8"}>
                <div className="w-full">
                    <Dialog.Title
                        as="h3"
                        className="text-lg font-bold text-gray-900 2xl:!text-4xl 2xl:!leading-[41px]"
                        id="modalDialogTitle"
                    >
                        {title}
                    </Dialog.Title>
                    {description && (
                        <Dialog.Description className="text-gray-text pt-1 break-anywhere">
                            {description}
                        </Dialog.Description>
                    )}
                </div>
            </div>
        </BaseModal>
    );
};
