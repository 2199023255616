import BaseApi from "../BaseApi";
import { ProgramProgressResponse, ProgramStartResponse } from "Api/Responses/ProgramProgressResponse";

export default class ProgramProgressApi extends BaseApi {
    StartProgram = async (programId: string): Promise<ProgramStartResponse> => {
        return await this.Get(new ProgramStartResponse(), `/start/${programId}`);
    };

    ReadProgress = async (programId: string): Promise<ProgramProgressResponse> => {
        return await this.Get(new ProgramProgressResponse(), `/end-stats/${programId}`);
    };
}
