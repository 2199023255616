import React, { useState } from "react";
import { Button, flash, Modal, SubmitButton } from "Uikit";
import { AccessOpenTable } from "./AccessOpenTable";
import { Except } from "type-fest";
import { ID, TVoidFunction } from "types";
import { useMutation } from "react-query";
import Api from "Api/index";
import { UserAccessRequest } from "Api/Requests/AccessRequest";
import { ResourceState, ResourceType } from "Enums";

interface AccessOpenModalProps {
    isOpen: boolean;
    onClose: TVoidFunction;
    onSuccess: TVoidFunction;
    resourceId: ID;
    resourceType: ResourceType;
    resourceState: ResourceState;
    hideTeamMembers?: boolean;
}

export const AccessOpenModal = ({ isOpen, onClose, ...rest }: AccessOpenModalProps) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} title="Открытие доступа" className="!w-142 !max-w-142">
            <ModalContent onClose={onClose} {...rest} />
        </Modal>
    );
};

const ModalContent = ({
    onClose,
    resourceId,
    resourceType,
    resourceState,
    onSuccess,
    hideTeamMembers = false,
}: Except<AccessOpenModalProps, "isOpen">) => {
    const [selectedUsers, setSelectedUsers] = useState<ID[]>([]);
    const [oldSelected, setOldSelected] = useState<ID[]>([]);

    const { mutateAsync: setUserAccess } = useMutation((payload: UserAccessRequest) => {
        return Api.LMSRoles.setUserAccess(payload);
    });

    const handleSubmit = async () => {
        if (selectedUsers.length === 0) {
            flash.error("Чтобы открыть доступ, выберите участников", undefined, { toastId: "AccessOpenModal" });
            return;
        }

        await setUserAccess(
            Object.assign(new UserAccessRequest(), {
                resourceId,
                resourceType,
                resourceState,
                users: selectedUsers.concat(oldSelected),
            }),
        );

        onSuccess();
        onClose();
    };

    const handleAccessChanged = (selected: ID[], oldSelected: ID[]) => {
        setSelectedUsers(selected);
        setOldSelected(oldSelected);
    };

    return (
        <>
            <Modal.Body>
                <AccessOpenTable
                    resourceId={resourceId}
                    onChange={handleAccessChanged}
                    hideTeamMembers={hideTeamMembers}
                />
            </Modal.Body>

            <Modal.Footer>
                <div className="grow self-center text-gray">Выбрано {selectedUsers.length}</div>
                <Button
                    key={1}
                    onClick={onClose}
                    variant="outline"
                    size={"medium"}
                    color={"secondary"}
                    className="border-[#EAEDF3]"
                >
                    Отмена
                </Button>
                <SubmitButton key={2} onClick={handleSubmit} disabled={selectedUsers.length === 0}>
                    Открыть доступ
                </SubmitButton>
            </Modal.Footer>
        </>
    );
};
