import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import clsx from "clsx";
import Api from "Api";
import { CommonsAddFavoritesRequest, CommonsRemoveFavoritesRequest } from "Api/Requests/CommonsRequest";
import { ProgressStatus, ResourceState, ResourceType } from "Enums";
import { Breadcrumbs, Icon, Icons, Input, Loader, UserAvatar, FavoriteButton } from "Uikit";
import { ContentLayout } from "Containers";
import { formatStatusChangeTime } from "helpers/dateHelper";
import { setIsBack, setIsFavorite, setIsFavoriteSelected, setIsHidden, setTitle } from "slices/headerSlice";
import { useDispatch, useSelector } from "react-redux";
import { IReducer } from "store";
import { useGetUICourse } from "../Course/Course.hooks";
import { formatLeadTime, formatTime } from "../Tasks/utils";
import { numCapEnd } from "helpers/numCapEnd";
import { LogoSize } from "Api/Services/UploadApi";

export const TestStatistics = () => {
    const dispatch = useDispatch();
    const { courseId, testId } = useParams();

    const isFavoriteSelected = useSelector((state: IReducer) => state.header.isFavoriteSelected);

    const [isAddFavoritePressed, setIsAddFavoritePressed] = useState(false);
    const [coverLoading, setCoverLoading] = useState(true);

    const [expandedSectionId, setExpandedSectionId] = useState<string | null>(null);

    const dataQuery = useQuery(
        ["test", "statistics", "collection", testId],
        async () => {
            // const res = await Api.Test.Statistics(testId!);
            const res = await Api.Test.Stats(testId!);

            dispatch(setIsFavoriteSelected(res.isFavorite));
            setIsAddFavoritePressed(res.isFavorite);

            return res;
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );

    const { data, isLoading, isError } = dataQuery;
    const { data: course } = useGetUICourse(courseId ?? "");

    const { mutateAsync: addFavorites } = useMutation((payload: CommonsAddFavoritesRequest) => {
        return Api.Commons.addFavorites(payload);
    });
    const handleAddFavorites = useCallback(() => {
        addFavorites(
            Object.assign(new CommonsAddFavoritesRequest(), {
                resourceId: testId,
                title: data?.title,
                logoId: data?.logoId,
                state: ResourceState.ACTIVE,
                type: ResourceType.QUIZ,
                progressStatus: data?.progressStatus,
                ratingPoints: data?.ratingPoints,
                deadlineTimestamp: 0,
            }),
        ).then();
        setIsAddFavoritePressed(true);
        dispatch(setIsFavoriteSelected(true));
    }, [dispatch, testId, data, addFavorites]);

    const { mutateAsync: removeFavorites } = useMutation((payload: CommonsRemoveFavoritesRequest) => {
        return Api.Commons.removeFavorites(payload);
    });
    const handleRemoveFavorites = useCallback(() => {
        removeFavorites(
            Object.assign(new CommonsRemoveFavoritesRequest(), {
                resourceId: testId,
                type: ResourceType.QUIZ,
            }),
        ).then();
        setIsAddFavoritePressed(false);
        dispatch(setIsFavoriteSelected(false));
    }, [dispatch, testId, removeFavorites]);

    useEffect(() => {
        dispatch(setIsHidden(false));
        dispatch(setIsBack(true));
        dispatch(setTitle("Тест"));
        dispatch(setIsFavorite(true));
    }, [dispatch]);
    useEffect(() => {
        if (window.screen.width > 568 || !data || isFavoriteSelected === isAddFavoritePressed) {
            return;
        }

        isFavoriteSelected ? handleAddFavorites() : handleRemoveFavorites();
    }, [isFavoriteSelected, data, isAddFavoritePressed, handleAddFavorites, handleRemoveFavorites]);

    return (
        <ContentLayout className="mx-auto sm:pl-4 sm:pr-6.5 max-w-[1216px] 2xl:max-w-[1506px]">
            <Breadcrumbs className="hidden sm:block">
                {!courseId && <Breadcrumbs.Link title="Тест" url="/tests" />}
                {courseId && <Breadcrumbs.Link title="Моё обучение" url="/training" />}
                {courseId && course && (
                    <Breadcrumbs.Link title={course.title} url={`/training/course/${course.resourceId}`} />
                )}
                {data?.title && <Breadcrumbs.Link title={data?.title} />}
            </Breadcrumbs>
            {isLoading && (
                <div className="w-full h-full flex-center px-10">
                    <Loader />
                </div>
            )}
            {isError && (
                <div className="w-full h-full flex-center px-10">
                    <p className="text-md text-center text-red">
                        Произошла ошибка запроса - невозможно загрузить страницу
                    </p>
                </div>
            )}
            {data && (
                <div className="w-full">
                    <div className="flex flex-col-reverse sm:flex-row justify-between sm:mb-8 2xl:mb-10 rounded-2xl 2xl:rounded-3xl sm:bg-background-light">
                        <div className="block sm:hidden mt-10 mb-10 w-full h-0.25 bg-gray-blue"></div>
                        <div className="sm:px-10 2xl:px-12.5 pt-4 sm:py-8 2xl:py-10 flex flex-col flex-grow">
                            <h1 className="hidden sm:block mb-1 2xl:mb-2 2xl:!text-4xl 2xl:!leading-[41px] break-anywhere">
                                {data.title}
                            </h1>
                            <h2 className="block sm:hidden mb-2 break-anywhere">{data.title}</h2>
                            <p className="mb-2 sm:mb-8 2xl:mb-11 w-full text-gray-dark 2xl:text-md break-anywhere">
                                {data.description}
                            </p>
                            <div className="mt-auto flex flex-col sm:flex-row sm:items-center gap-2 sm:gap-7.5 2xl:gap-9.5">
                                <div className="flex flex-row sm:flex-col items-center sm:items-start">
                                    <p className="text-gray-text mr-5 sm:mr-0 sm:mb-0.5 p4 2xl:p3 w-17.5 sm:w-auto">
                                        Статус
                                    </p>
                                    <p
                                        className={clsx(
                                            "rounded-md 2xl:rounded-lg p3 2xl:p2 py-0.5 2xl:py-0.75",
                                            [
                                                ProgressStatus.PASSED,
                                                ProgressStatus.RE_WORK,
                                                ProgressStatus.FAILED,
                                            ].includes(data.progressStatus) && "px-1.5 2xl:px-2 text-white",
                                            data.progressStatus === ProgressStatus.PASSED && "bg-primary",
                                            [ProgressStatus.RE_WORK, ProgressStatus.FAILED].includes(
                                                data.progressStatus,
                                            ) && "bg-red",
                                        )}
                                    >
                                        {data.progressStatus === ProgressStatus.RE_WORK && "Отклонено"}
                                        {data.progressStatus === ProgressStatus.ON_REVIEW && "На проверке"}
                                        {data.progressStatus === ProgressStatus.PASSED && "Пройден"}
                                        {data.progressStatus === ProgressStatus.IN_PROGRESS && "В процессе"}
                                        {data.progressStatus === ProgressStatus.FAILED && "Провален"}
                                    </p>
                                </div>
                                {data.progressStatus === ProgressStatus.PASSED &&
                                    (!!data.ratingPoints || data.ratingPoints === 0) && (
                                        <div className="flex flex-row sm:flex-col">
                                            <p className="text-gray-text mr-5 sm:mr-0 sm:mb-0.5 p4 2xl:p3 w-17.5 sm:w-auto">
                                                Получено
                                            </p>
                                            <p className="sm:py-0.5 p3 2xl:p2">
                                                {data.ratingPoints}{" "}
                                                {numCapEnd(
                                                    { one: "балл", two: "балла", few: "баллов" },
                                                    Number(data.ratingPoints),
                                                )}
                                            </p>
                                        </div>
                                    )}
                                <div className="flex flex-row sm:flex-col items-center sm:items-start">
                                    <p className="text-gray-text mr-5 sm:mr-0 sm:mb-0.5 p4 2xl:p3 w-17.5 sm:w-auto">
                                        Порог прохождения
                                    </p>
                                    <p className="sm:py-0.5 p3 2xl:p2">{data.passPercentage}%</p>
                                </div>
                                {!!data.questionTimeLimit && (
                                    <div className="flex flex-row sm:flex-col items-center sm:items-start">
                                        <p className="text-gray-text mr-5 sm:mr-0 sm:mb-0.5 p4 2xl:p3 w-17.5 sm:w-auto">
                                            Время на вопрос
                                        </p>
                                        <p className="sm:py-0.5 p3 2xl:p2">
                                            {data.questionTimeLimit / 60 >= 1
                                                ? Math.floor(data.questionTimeLimit / 60) + " мин "
                                                : ""}
                                            {data.questionTimeLimit % 60} сек
                                        </p>
                                    </div>
                                )}
                                {!!data.quizTimeLimit && (
                                    <div className="flex flex-row sm:flex-col items-center sm:items-start">
                                        <p className="text-gray-text mr-5 sm:mr-0 sm:mb-0.5 p4 2xl:p3 w-17.5 sm:w-auto">
                                            Время на тест
                                        </p>
                                        <p className="sm:py-0.5 p3 2xl:p2">
                                            {data.quizTimeLimit / 60 >= 1
                                                ? Math.floor(data.quizTimeLimit / 60) + " мин "
                                                : ""}
                                            {data.quizTimeLimit % 60} сек
                                        </p>
                                    </div>
                                )}
                                <div className="flex flex-row sm:flex-col items-center sm:items-start">
                                    <p className="text-gray-text mr-5 sm:mr-0 sm:mb-0.5 p4 2xl:p3 w-17.5 sm:w-auto">
                                        Попыток доступно
                                    </p>
                                    <p className="sm:py-0.5 p3 2xl:p2">{data.attemptsAvailable || 0}</p>
                                </div>
                            </div>
                        </div>
                        <div className="flex-shrink-0">
                            <div className="group relative">
                                {coverLoading && (
                                    <div className="top-1/2 left-1/2 absolute -translate-x-1/2 -translate-y-1/2">
                                        <Loader />
                                    </div>
                                )}
                                <img
                                    className={clsx(
                                        "w-full sm:w-100 2xl:w-125 h-[60vw] sm:h-61 2xl:h-[305px] object-cover rounded-2xl 2xl:rounded-3xl",
                                        coverLoading && "invisible",
                                    )}
                                    src={Api.Upload.GetLogo(data.logoId, LogoSize.ORIGINAL)}
                                    alt={data.title}
                                    onLoad={() => setCoverLoading(false)}
                                />
                                <FavoriteButton
                                    onChange={isAddFavoritePressed ? handleRemoveFavorites : handleAddFavorites}
                                    isFavorite={isAddFavoritePressed}
                                    className="absolute top-3 right-3 2xl:top-3.75 2xl:right-3.75"
                                />
                                <div className="absolute top-3 sm:top-4 2xl:top-5 left-3 sm:left-4 2xl:left-5 flex gap-1.5 2xl:gap-2 h-5.25 2xl:h-6.5">
                                    <div className="flex items-center rounded-md 2xl:rounded-lg bg-black-50 text-white p3 px-1.5 2xl:px-2 py-0.5 2xl:py-0.75 2xl:text-2sm">
                                        Тест
                                    </div>
                                    {/* {data.attempts?.length > 0 && ( */}
                                    <div className="flex items-center rounded-md 2xl:rounded-lg bg-black-50 text-white p3 px-1.5 2xl:px-2 py-0.5 2xl:py-0.75 2xl:text-2sm">
                                        <span>
                                            {data.progressStatus === ProgressStatus.ON_REVIEW
                                                ? "Отправлено "
                                                : "Пройден "}
                                            {formatStatusChangeTime(
                                                data.progressStatus === ProgressStatus.ON_REVIEW
                                                    ? new Date(data.onReviewTime).getTime() / 1000
                                                    : data.attempts[data.attempts.length - 1].finishTime,
                                                {
                                                    showTime: true,
                                                    delimiter: "",
                                                    formatTonight: true,
                                                },
                                            )}
                                        </span>
                                    </div>
                                    {/* )} */}
                                    {data.deadLinePassed && (
                                        <div className="flex items-center rounded-md 2xl:rounded-lg bg-red text-white p3 px-1.5 2xl:px-2 py-0.5 2xl:py-0.75 2xl:text-2sm">
                                            Просрочено
                                        </div>
                                    )}
                                </div>
                                <div className="absolute bottom-3 sm:bottom-4 2xl:bottom-5 left-3 sm:left-4 2xl:left-5 right-3 sm:right-4 2xl:right-5 flex justify-between h-5.25 2xl:h-6.5">
                                    {(data.maxRatingPoints || data.maxRatingPoints === 0) && (
                                        <div className="rounded-md 2xl:rounded-lg bg-black-50 text-white flex items-center gap-1 2xl:gap-1.25 p3 px-1.5 2xl:px-2 py-0.5 2xl:py-0.75 2xl:text-2sm">
                                            <Icon icon={Icons.TrophyFilled} width={14} height={14} color="fill-white" />
                                            <span>{data.maxRatingPoints}</span>
                                        </div>
                                    )}
                                    {data.approxCompletionMinutes !== 0 && (
                                        <div className="flex items-center rounded-md 2xl:rounded-lg bg-black-50 text-white p3 px-1.5 2xl:px-2 py-0.5 2xl:py-0.75 2xl:text-2sm">
                                            {formatTime(Math.abs(data.approxCompletionMinutes * 60))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col sm:gap-5 2xl:gap-6.5 w-full">
                        {data?.attempts
                            ?.filter(({ progressStatus }) =>
                                [ProgressStatus.FAILED, ProgressStatus.PASSED].includes(progressStatus),
                            )
                            ?.sort((a, b) => b.finishTime - a.finishTime)
                            ?.map((attempt, index) => {
                                const isExpanded = attempt.id === expandedSectionId;

                                return (
                                    <Fragment key={attempt.id}>
                                        <div>
                                            <div
                                                className="flex justify-between gap-5 cursor-pointer mb-4 2xl:mb-5"
                                                onClick={() => setExpandedSectionId(isExpanded ? null : attempt.id)}
                                            >
                                                <h2 className="hidden sm:block 2xl:!text-2xl 2xl:!leading-[35px]">
                                                    {data.attempts.filter(({ progressStatus }) =>
                                                        [ProgressStatus.FAILED, ProgressStatus.PASSED].includes(
                                                            progressStatus,
                                                        ),
                                                    ).length - index}{" "}
                                                    попытка
                                                </h2>
                                                <h3 className="block sm:hidden">
                                                    {data.attempts.filter(({ progressStatus }) =>
                                                        [ProgressStatus.FAILED, ProgressStatus.PASSED].includes(
                                                            progressStatus,
                                                        ),
                                                    ).length - index}{" "}
                                                    попытка
                                                </h3>
                                                <div
                                                    className={clsx(
                                                        "w-7.5 h-7.5 rounded-full flex-center sm:bg-background-light 2xl:w-[35px] 2xl:h-[35px]",
                                                        !isExpanded && "rotate-180",
                                                    )}
                                                >
                                                    <Icon
                                                        icon={Icons.ChevronUp}
                                                        width={15}
                                                        height={15}
                                                        color="fill-blue-drk"
                                                        className="2xl:!w-4.5 2xl:!h-4.5"
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex flex-col-reverse sm:flex-row items-stretch gap-3 sm:gap-6.5 2xl:gap-8">
                                                <div className="flex flex-col sm:flex-row flex-grow rounded-2xl 2xl:rounded-3xl sm:flex-center bg-background-light px-4 sm:px-9 2xl:px-[45px] py-6 2xl:py-8 gap-4 2xl:gap-5">
                                                    <div className="flex flex-row sm:flex-col flex-grow items-center sm:items-start">
                                                        <div className="mr-2 sm:mr-0 w-9 2xl:w-[45px] h-9 2xl:h-[45px] rounded-full flex-center shrink-0 bg-blue-gray sm:mb-2 2xl:mb-2.5">
                                                            <Icon
                                                                icon={Icons.TickAll}
                                                                width={20}
                                                                height={20}
                                                                color="fill-blue-drk"
                                                                className="2xl:!w-6.25 2xl:!h-6.25"
                                                            />
                                                        </div>
                                                        <div>
                                                            <p className="p2 2xl:text-md text-gray-text">
                                                                Правильных ответов (
                                                                {Math.ceil(
                                                                    (attempt.correctAnswersCount * 100) /
                                                                        attempt.questions.length,
                                                                )}
                                                                %)
                                                            </p>
                                                            <h3 className="text-black font-medium sm:p1-accent 2xl:text-lg">
                                                                {attempt.correctAnswersCount} из{" "}
                                                                {attempt.questions.length}
                                                            </h3>
                                                        </div>
                                                    </div>
                                                    {[ProgressStatus.PASSED, ProgressStatus.FAILED].includes(
                                                        attempt.progressStatus,
                                                    ) &&
                                                        !!attempt.timeSpent && (
                                                            <>
                                                                <div className="hidden sm:block border-l border-solid border-gray-stroke h-full"></div>
                                                                <div className="flex flex-row sm:flex-col flex-grow items-center sm:items-start">
                                                                    <div className="mr-2 sm:mr-0 w-9 2xl:w-[45px] h-9 2xl:h-[45px] rounded-full flex-center shrink-0 bg-blue-gray sm:mb-2 2xl:mb-2.5">
                                                                        <Icon
                                                                            icon={Icons.Time}
                                                                            width={20}
                                                                            height={20}
                                                                            color="fill-blue-drk"
                                                                            className="2xl:!w-6.25 2xl:!h-6.25"
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <p className="p2 2xl:text-md text-gray-text">
                                                                            Время выполнения
                                                                        </p>
                                                                        <h3 className="text-black font-medium sm:p1-accent 2xl:text-lg">
                                                                            {formatLeadTime(attempt.timeSpent)}
                                                                        </h3>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    <div className="hidden sm:block border-l border-solid border-gray-stroke h-full"></div>
                                                    <div className="flex flex-row sm:flex-col flex-grow items-center sm:items-start">
                                                        <div className="mr-2 sm:mr-0 w-9 2xl:w-[45px] h-9 2xl:h-[45px] rounded-full flex-center shrink-0 bg-blue-gray sm:mb-2 2xl:mb-2.5">
                                                            <Icon
                                                                icon={Icons.UserMenuIconCalendar}
                                                                width={20}
                                                                height={20}
                                                                color="fill-blue-drk"
                                                                className="2xl:!w-6.25 2xl:!h-6.25"
                                                            />
                                                        </div>
                                                        <div>
                                                            <p className="p2 2xl:text-md text-gray-text">Пройден</p>
                                                            <h3 className="text-black font-medium sm:p1-accent 2xl:text-lg">
                                                                {formatStatusChangeTime(attempt.finishTime, {
                                                                    showTime: true,
                                                                    delimiter: "",
                                                                })}
                                                            </h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className={clsx(
                                                        "py-4 sm:py-4 2xl:py-5 sm:px-11 2xl:px-16 rounded-2xl 2xl:rounded-3xl flex-col flex-center gap-2.5 2xl:gap-3 w-full sm:w-[274px] 2xl:w-[342px]",
                                                        attempt.progressStatus === ProgressStatus.PASSED
                                                            ? "bg-blue-5 text-primary"
                                                            : "bg-red-10 text-red",
                                                    )}
                                                >
                                                    <div
                                                        className={clsx(
                                                            "w-[54px] 2xl:w-[68px] h-[54px] 2xl:h-[68px] rounded-full flex-center shrink-0",
                                                            attempt.progressStatus === ProgressStatus.PASSED
                                                                ? "bg-primary"
                                                                : "bg-red",
                                                        )}
                                                    >
                                                        {attempt.progressStatus === ProgressStatus.PASSED && (
                                                            <Icon
                                                                icon={Icons.TickAll}
                                                                width={24}
                                                                height={24}
                                                                color="fill-white"
                                                                className="2xl:!w-7.5 2xl:!h-7.5"
                                                            />
                                                        )}
                                                        {attempt.progressStatus !== ProgressStatus.PASSED && (
                                                            <Icon
                                                                icon={Icons.Close}
                                                                width={24}
                                                                height={24}
                                                                color="fill-white"
                                                                className="2xl:!w-7.5 2xl:!h-7.5"
                                                            />
                                                        )}
                                                    </div>
                                                    <h3 className="font-medium sm:p1 2xl:text-lg">
                                                        {attempt.progressStatus === ProgressStatus.PASSED &&
                                                            `Пройден${
                                                                attempt.ratingPoints
                                                                    ? ` (+${attempt.ratingPoints} ${numCapEnd(
                                                                          { one: "балл", two: "балла", few: "баллов" },
                                                                          Number(attempt.ratingPoints),
                                                                      )})`
                                                                    : ""
                                                            }`}
                                                        {attempt.progressStatus !== ProgressStatus.PASSED && `Провален`}
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                        {isExpanded &&
                                            attempt.questions
                                                .filter((p) => !p.isApproved)
                                                .map((question, questionIndex) => {
                                                    return (
                                                        <div
                                                            key={question.id}
                                                            className="mb-4 sm:mb-5 2xl:mb-0 p-4 sm:p-6 2xl:p-7.5 w-full bg-background rounded-xl 2xl:rounded-2xl"
                                                        >
                                                            <div className="flex items-center mb-4 sm:mb-5 2xl:mb-6.25">
                                                                <div
                                                                    className={clsx(
                                                                        "flex justify-center items-center mr-1.5 2xl:mr-2 w-6 2xl:w-7.5 h-6 2xl:h-7.5 border rounded-md 2xl:rounded-lg p3 leading-0 2xl:text-md",
                                                                        question.isApproved
                                                                            ? "bg-blue-10 text-blue border-blue"
                                                                            : "",
                                                                        !question.isApproved
                                                                            ? "bg-red-10 text-red border-red"
                                                                            : "",
                                                                    )}
                                                                >
                                                                    {questionIndex + 1}
                                                                </div>
                                                                <span className="p1-accent 2xl:text-md">
                                                                    {question.questionText}
                                                                </span>
                                                            </div>
                                                            {question.questionType === "NUMBER" && (
                                                                <div className="relative">
                                                                    <Input
                                                                        className={clsx(
                                                                            "!bg-white border-0",
                                                                            !question.isApproved
                                                                                ? "border-0 text-red"
                                                                                : "",
                                                                        )}
                                                                        type="number"
                                                                        value={question.numericAnswer}
                                                                        disabled={true}
                                                                        rootClassName="!bg-transparent"
                                                                    />
                                                                    {!question.isApproved && (
                                                                        <span className="absolute top-2 right-4.5 text-blue">
                                                                            Правильно: {question.correctNumericAnswer}
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            )}
                                                            {question.questionType === "TEXT" && (
                                                                <div className="relative">
                                                                    <Input
                                                                        className={clsx(
                                                                            "!bg-white border-0",
                                                                            !question.isApproved
                                                                                ? "border-0 text-red"
                                                                                : "",
                                                                        )}
                                                                        type="text"
                                                                        value={question.textAnswer}
                                                                        disabled={true}
                                                                        rootClassName="!bg-transparent"
                                                                    />
                                                                    {question?.comment.comment && (
                                                                        <div className="mt-5 2xl:mt-6.25">
                                                                            <div className="flex items-center mb-1.5 gap-2 2xl:gap-2.5">
                                                                                <UserAvatar
                                                                                    avatarId={
                                                                                        question.comment.user.avatarId
                                                                                    }
                                                                                    color={
                                                                                        question.comment.user
                                                                                            .defaultAvatarColor
                                                                                    }
                                                                                    userInitials={`${question.comment.user.firstName?.slice(
                                                                                        0,
                                                                                        1,
                                                                                    )}${question.comment.user.lastName?.slice(
                                                                                        0,
                                                                                        1,
                                                                                    )}`}
                                                                                    size={
                                                                                        window.screen.width >= 1600
                                                                                            ? 26
                                                                                            : 21
                                                                                    }
                                                                                />
                                                                                <span className="p1-accent text-black 2xl:text-md">
                                                                                    {question.comment.user.firstName +
                                                                                        " " +
                                                                                        question.comment.user.lastName}
                                                                                </span>
                                                                                <span className="p3 2xl:p2 text-gray">
                                                                                    Администратор
                                                                                </span>
                                                                            </div>
                                                                            <p className="p1 text-gray-dark 2xl:text-md">
                                                                                {question.comment.comment}
                                                                            </p>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )}
                                                            {(question.questionType === "ONE_OPTION" ||
                                                                question.questionType === "MULTIPLE_OPTIONS") && (
                                                                <div
                                                                    className={`flex flex-wrap ${
                                                                        question.optionsAnswers[0].pictureId
                                                                            ? "gap-x-4 2xl:gap-x-5"
                                                                            : ""
                                                                    }`}
                                                                >
                                                                    {question.optionsAnswers.map((answer) => {
                                                                        return (
                                                                            <div
                                                                                key={answer.id}
                                                                                className={clsx(
                                                                                    "flex mb-4 2xl:mb-5 cursor-pointer last:mb-0",
                                                                                    answer.pictureId
                                                                                        ? "flex-col"
                                                                                        : "items-center w-full",
                                                                                )}
                                                                            >
                                                                                <div
                                                                                    className={
                                                                                        answer.pictureId
                                                                                            ? "flex items-center"
                                                                                            : ""
                                                                                    }
                                                                                >
                                                                                    {question.questionType !==
                                                                                        "MULTIPLE_OPTIONS" &&
                                                                                        answer.isUserChecked &&
                                                                                        !answer.isCorrect && (
                                                                                            <div className="mr-2 2xl:mr-2.5 w-6 2xl:w-7.5 h-6 2xl:h-7.5 bg-red rounded-full">
                                                                                                <Icon
                                                                                                    icon={Icons.Close}
                                                                                                    width={24}
                                                                                                    height={24}
                                                                                                    color="fill-white"
                                                                                                    className="2xl:!w-7.5 2xl:!h-7.5"
                                                                                                />
                                                                                            </div>
                                                                                        )}
                                                                                    {question.questionType !==
                                                                                        "MULTIPLE_OPTIONS" &&
                                                                                        !answer.isUserChecked &&
                                                                                        answer.isCorrect && (
                                                                                            <div className="flex justify-center items-center mr-2 2xl:mr-2.5 w-6 2xl:w-7.5 h-6 2xl:h-7.5 bg-white border border-blue rounded-full">
                                                                                                <Icon
                                                                                                    icon={Icons.Minus}
                                                                                                    width={18}
                                                                                                    height={18}
                                                                                                    color="fill-blue"
                                                                                                    className="2xl:!w-6 2xl:!h-6"
                                                                                                />
                                                                                            </div>
                                                                                        )}
                                                                                    {question.questionType !==
                                                                                        "MULTIPLE_OPTIONS" &&
                                                                                        answer.isUserChecked &&
                                                                                        answer.isCorrect && (
                                                                                            <div className="flex justify-center items-center mr-2 2xl:mr-2.5 w-6 2xl:w-7.5 h-6 2xl:h-7.5 bg-blue rounded-full">
                                                                                                <Icon
                                                                                                    icon={Icons.Check}
                                                                                                    width={20}
                                                                                                    height={20}
                                                                                                    color="fill-white"
                                                                                                    className="2xl:!w-6.25 2xl:!h-6.25"
                                                                                                />
                                                                                            </div>
                                                                                        )}
                                                                                    {question.questionType !==
                                                                                        "MULTIPLE_OPTIONS" &&
                                                                                        !answer.isUserChecked &&
                                                                                        !answer.isCorrect && (
                                                                                            <div className="mr-2 2xl:mr-2.5 w-6 2xl:w-7.5 h-6 2xl:h-7.5 bg-white border border-gray-stroke rounded-full"></div>
                                                                                        )}
                                                                                    {question.questionType ===
                                                                                        "MULTIPLE_OPTIONS" &&
                                                                                        answer.isUserChecked &&
                                                                                        !answer.isCorrect && (
                                                                                            <div className="mr-2 2xl:mr-2.5 w-6 2xl:w-7.5 h-6 2xl:h-7.5 bg-red rounded-md">
                                                                                                <Icon
                                                                                                    icon={Icons.Close}
                                                                                                    width={24}
                                                                                                    height={24}
                                                                                                    color="fill-white"
                                                                                                    className="2xl:!w-7.5 2xl:!h-7.5"
                                                                                                />
                                                                                            </div>
                                                                                        )}
                                                                                    {question.questionType ===
                                                                                        "MULTIPLE_OPTIONS" &&
                                                                                        !answer.isUserChecked &&
                                                                                        answer.isCorrect && (
                                                                                            <div className="flex justify-center items-center mr-2 2xl:mr-2.5 w-6 2xl:w-7.5 h-6 2xl:h-7.5 bg-white border border-blue rounded-md">
                                                                                                <Icon
                                                                                                    icon={Icons.Minus}
                                                                                                    width={18}
                                                                                                    height={18}
                                                                                                    color="fill-blue"
                                                                                                    className="2xl:!w-6 2xl:!h-6"
                                                                                                />
                                                                                            </div>
                                                                                        )}
                                                                                    {question.questionType ===
                                                                                        "MULTIPLE_OPTIONS" &&
                                                                                        answer.isUserChecked &&
                                                                                        answer.isCorrect && (
                                                                                            <div className="flex justify-center items-center mr-2 2xl:mr-2.5 w-6 2xl:w-7.5 h-6 2xl:h-7.5 bg-blue rounded-md">
                                                                                                <Icon
                                                                                                    icon={Icons.Check}
                                                                                                    width={20}
                                                                                                    height={20}
                                                                                                    color="fill-white"
                                                                                                    className="2xl:!w-6.25 2xl:!h-6.25"
                                                                                                />
                                                                                            </div>
                                                                                        )}
                                                                                    {question.questionType ===
                                                                                        "MULTIPLE_OPTIONS" &&
                                                                                        !answer.isUserChecked &&
                                                                                        !answer.isCorrect && (
                                                                                            <div className="mr-2 2xl:mr-2.5 w-6 2xl:w-7.5 h-6 2xl:h-7.5 bg-white border border-gray-stroke rounded-md"></div>
                                                                                        )}
                                                                                    {answer.pictureId &&
                                                                                        answer.isCorrect && (
                                                                                            <span className="p1-accent text-blue 2xl:text-md">
                                                                                                Верный ответ
                                                                                            </span>
                                                                                        )}
                                                                                    {answer.pictureId &&
                                                                                        answer.isUserChecked &&
                                                                                        !answer.isCorrect && (
                                                                                            <span className="p1-accent text-red 2xl:text-md">
                                                                                                Неверно
                                                                                            </span>
                                                                                        )}
                                                                                </div>
                                                                                {answer.answerText && (
                                                                                    <span className="text-gray-dark 2xl:text-md">
                                                                                        {answer.answerText}
                                                                                    </span>
                                                                                )}
                                                                                {answer.pictureId && (
                                                                                    <img
                                                                                        className={clsx(
                                                                                            "mt-1 w-32 sm:w-50 h-32 sm:h-50 rounded-2lg object-cover",
                                                                                            answer.isUserChecked
                                                                                                ? "border-2 border-blue"
                                                                                                : "",
                                                                                            answer.isCorrect
                                                                                                ? "border-2 border-blue"
                                                                                                : "",
                                                                                            answer.isUserChecked &&
                                                                                                !answer.isCorrect
                                                                                                ? "border-2 border-red"
                                                                                                : "",
                                                                                        )}
                                                                                        src={`/service/lms-upload/api/file/download/${answer.pictureId}`}
                                                                                        alt={answer.id}
                                                                                    />
                                                                                )}
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                    </Fragment>
                                );
                            })}
                    </div>
                </div>
            )}
        </ContentLayout>
    );
};
