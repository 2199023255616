import React, { ElementType, forwardRef } from "react";
import { BaseInput, BaseInputProps } from "./BaseInput";
import { Except } from "type-fest";
import clsx from "clsx";
import { flash, Icon, Icons, Tooltip } from "..";
import { useScreenSize } from "hooks/useMediaQuery";

// eslint-disable-next-line no-useless-escape
const regexp = new RegExp(/[^а-яА-ЯёЁa-zA-Z0-9"'\(\)«»!@#№$%&*_ +=?,.\-;:\/]/gi);

// eslint-disable-next-line no-useless-escape
export const extendedRegexp = new RegExp(/[^а-яА-ЯёЁa-zA-Z0-9"'()«»!@#№$%&*_ +=?,.\-;:\/^|\\~><{}\[\]]/gi);

// eslint-disable-next-line no-useless-escape
export const numberRegexp = new RegExp(/[^0-9.,-]/gi);

// eslint-disable-next-line no-useless-escape
export const numberWithSpecSymbolsRegexp = new RegExp(/[^0-9eEeеЕ.,-/]/gi);

// eslint-disable-next-line no-useless-escape
export const wholeNumberRegexp = new RegExp(/[^0-9-]/gi);

// eslint-disable-next-line no-useless-escape
export const wholePosNumberRegexp = new RegExp(/[^0-9]/gi);

export const fioCutRegExps = [new RegExp(/[^а-яА-ЯёЁa-zA-Z'`\s-—–]/g)];

// eslint-disable-next-line no-useless-escape
export const testingRegexp = new RegExp(/[^а-яА-ЯёЁa-zA-Z0-9\s.,\-–—"'()«»!@#№$%^&*_+=?;:|\/~\\><\[\]}{]/gi);

// eslint-disable-next-line no-useless-escape
export const passwordRegexp = new RegExp(/[^a-zA-Z0-9"'()«»!@#№$%&*_ +=?,.\-;:\/^|\\~><{}\[\]]/g);

// TODO
export interface InputProps extends Except<BaseInputProps, "as"> {
    before?: React.ReactNode;
    type?: string;
    as?: ElementType;
    after?: React.ReactNode;
    error?: string | React.ReactNode;
    errorClassName?: string;
    value?: string | number | null;
    maxLength?: number;
    cutRegExp?: RegExp | RegExp[];
    tooltipIntercative?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    rootClassName?: string;
    doubleDashReplacement?: boolean;
    singleQuoteCheck?: boolean;
    singleDashCheck?: boolean;
    shiftOnBlur?: boolean;
}

export const Input = forwardRef(
    (
        {
            className,
            before,
            after,
            type = "text",
            as = "input",
            error,
            errorClassName,
            value = "",
            rootClassName = "",
            maxLength = 64,
            onChange,
            cutRegExp = regexp,
            tooltipIntercative = false,
            shiftOnBlur = false,
            ...rest
        }: InputProps,
        ref,
    ) => {
        const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            let str = String(e.target.value);

            if (cutRegExp instanceof RegExp && (cutRegExp === numberRegexp || cutRegExp === wholeNumberRegexp)) {
                str = str.replaceAll("–", "-").replaceAll("—", "-");

                str = str.startsWith("-") ? "-" + str.replaceAll("-", "") : str.replaceAll("-", "");

                str = str.startsWith(".") || str.startsWith(",") ? str.slice(1, str.length) : str;
                str = str
                    .replace(",", ".")
                    .split(".")
                    .reduce((prev, curr, i) => prev + (i == 1 ? "." : "") + curr);
            }

            str = str.replace(/(?=\s)[^\r\n\t]/g, " ");
            const re = new RegExp(`[${String.fromCharCode(160)}${String.fromCharCode(10240)}]`, "g");
            str = str.replace(re, " ");

            if (cutRegExp) {
                const exps = Array.isArray(cutRegExp) ? cutRegExp : [cutRegExp];
                exps.forEach((exp) => {
                    str = str.replaceAll(exp, "");
                });
            }

            if (maxLength && str.length > maxLength) {
                return value;
            }

            if (rest.min && +str < rest.min) {
                return value;
            }

            if (rest.max && +str > rest.max) {
                return value;
            }

            const caretPosition = e.target.selectionStart;
            const removedSymbols = e.target.value.length - str.length;

            e.target.value = str;
            onChange?.(e);

            if (caretPosition) {
                e.target.setSelectionRange(caretPosition - removedSymbols, caretPosition - removedSymbols);
            }
        };

        const onPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
            if (cutRegExp) {
                const exps = Array.isArray(cutRegExp) ? cutRegExp : [cutRegExp];
                if (exps.some((exp) => RegExp(exp).exec(e.clipboardData.getData("text/plain")))) {
                    console.log(RegExp(exps[0]).exec(e.clipboardData.getData("text/plain")));
                    flash.info("При вставке удалены запрещенные символы");
                }
            }
        };

        const onBlur = (event: any) => {
            if (shiftOnBlur) {
                return;
            }

            const newVal = event.target.value
                .toString()
                .trim()
                .replace(/^\s/g, "")
                .replace(/\s{2,}/g, " ");

            if (event.target.value.toString() !== newVal) {
                event.target.value = newVal;
                onInputChange(event);
            }

            if (rest.onBlur) {
                rest.onBlur(event as any);
            }
        };

        const { size } = useScreenSize();
        const isLarge = size === "large";
        const iconAlertSide = isLarge ? 28 : 20;
        const inputErrorClass = `!pr-10 2xl:!pr-[50px]`;

        return (
            <div className={clsx("relative w-full flex justify-between bg-white", rootClassName)}>
                {before && (
                    <div className="absolute inset-y-0 left-3 2xl:left-5 flex items-center z-10 2xl:text-md">
                        {before}
                    </div>
                )}
                <BaseInput
                    ref={ref}
                    as={as}
                    className={clsx(
                        className,
                        before && "pl-10",
                        after && "pr-10",
                        "bg-transparent",
                        error && inputErrorClass,
                    )}
                    type={type}
                    isError={!!error}
                    value={value}
                    onChange={onInputChange}
                    onPaste={onPaste}
                    maxLength={maxLength}
                    {...rest}
                    onBlur={onBlur}
                    title={rest.title}
                />
                {after && (
                    <div className="absolute inset-y-0 right-3 2xl:right-5 flex items-center opacity-70">{after}</div>
                )}
                {error && (
                    <div
                        className={`absolute inset-y-0 right-3 2xl:right-3 flex items-center pr-0 cursor-pointer ${errorClassName}`}
                        data-testid="error"
                    >
                        <Tooltip content={error} interactive={tooltipIntercative}>
                            <Icon
                                icon={Icons.AlertCircle}
                                color="fill-danger"
                                width={iconAlertSide}
                                height={iconAlertSide}
                            />
                        </Tooltip>
                    </div>
                )}
            </div>
        );
    },
);
