import React, { useMemo, useState } from "react";
import { StatisticUserComplexityRequest } from "Api/Requests/StatisticRequest";
import { TVoidFunction } from "types/TVoidFunction";
import { Button, Table, Loader, UserAvatar } from "Uikit";
import { ColumnDef, ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { useQuery } from "react-query";
import Api from "Api";
import { StatisticUserComplexityResponseData } from "Api/Responses/StatisticResponse";
import { MultiClumpTooltip } from "Components/MultiClumpTooltip/MultiClumpTooltip";

interface IReportTableProps {
    onClickLoadReportFile: TVoidFunction;
    queryPayload: StatisticUserComplexityRequest;
}

export const ReportTable = ({ onClickLoadReportFile, queryPayload }: IReportTableProps) => {
    const [sorting, setSorting] = useState<SortingState>([{ id: "userLastName,userFirstName", desc: false }]);
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 15 });

    const [selectedRows, setSelectedRows] = useState<any>();
    const [rowSelection, setRowSelection] = useState({});

    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
    const [search, setSearch] = useState<string>("");

    console.log(selectedRows);

    const pagination = useMemo(() => ({ pageIndex, pageSize }), [pageIndex, pageSize]);
    const dataQuery = useQuery(
        ["reportUserComplexity", "collection", pagination, sorting, search, queryPayload],
        async () => {
            const payload: StatisticUserComplexityRequest = { ...queryPayload, searchQuery: search };
            return await Api.Statistic.CreateUserComplexityReport(payload, pageIndex, pageSize, sorting);
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );

    const columns = React.useMemo<ColumnDef<StatisticUserComplexityResponseData>[]>(
        () => [
            {
                header: "Участник",
                accessorKey: "userLastName,userFirstName",
                className: "w-[30%]",
                footer: (props) => props.column.id,
                cell: ({
                    row: {
                        original: { user },
                    },
                }) => {
                    return (
                        <a
                            className="group inline-flex items-center space-x-3.5 cursor-pointer text-black hover:no-underline"
                            href={`/admin/member/${user.id}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <UserAvatar
                                avatarId={user.avatarId}
                                color={user.defaultAvatarColor}
                                userInitials={`${user.firstName?.slice(0, 1)}${user.lastName?.slice(0, 1)}`}
                                size={36}
                            />
                            <MultiClumpTooltip
                                key={user.id}
                                clamp={1}
                                label={`${user.lastName} ${user.firstName}`}
                                textClassName="group-hover:text-blue"
                            />
                        </a>
                    );
                },
            },
            {
                header: "Пройдено программ",
                accessorKey: "completedProgramCount",
                enableResizing: true,
                size: 200,
                footer: (props) => props.column.id,
                cell: ({
                    row: {
                        original: { completedProgramCount, totalProgramCount },
                    },
                }) => {
                    return <>{`${completedProgramCount} из ${totalProgramCount}`}</>;
                },
            },
            {
                header: "Пройдено курсов",
                accessorKey: "completedCourseCount",
                enableResizing: true,
                size: 200,
                footer: (props) => props.column.id,
                cell: ({
                    row: {
                        original: { completedCourseCount, totalCourseCount },
                    },
                }) => {
                    return <>{`${completedCourseCount} из ${totalCourseCount}`}</>;
                },
            },
            {
                header: "Пройдено тестов",
                accessorKey: "completedQuizCount",
                enableResizing: true,
                size: 200,
                footer: (props) => props.column.id,
                cell: ({
                    row: {
                        original: { completedQuizCount, totalQuizCount },
                    },
                }) => {
                    return <>{`${completedQuizCount} из ${totalQuizCount}`}</>;
                },
            },
            {
                header: "Выполнено заданий",
                accessorKey: "completedExerciseCount",
                enableResizing: true,
                size: 200,
                footer: (props) => props.column.id,
                cell: ({
                    row: {
                        original: { completedExerciseCount, totalExerciseCount },
                    },
                }) => {
                    return <>{`${completedExerciseCount} из ${totalExerciseCount}`}</>;
                },
            },
        ],

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const controlButtons = (
        <div className="flex justify-end items-center col-start-1 row-start-1">
            <Button
                className="rounded-lg whitespace-nowrap font-medium w-full"
                size="medium"
                onClick={() => {
                    onClickLoadReportFile();
                }}
            >
                Выгрузить отчет в файл
            </Button>
        </div>
    );

    const isEmpty = dataQuery.data?.data.Content.length === 0 && search === "";

    return (
        <>
            {dataQuery.isFetching && !dataQuery.data?.data.Content && (
                <div className="w-full flex justify-center">
                    <Loader />
                </div>
            )}
            {dataQuery.data?.data.Content && (
                <div>
                    <Table
                        columns={columns}
                        controlButtons={controlButtons}
                        columnFilters={columnFilters}
                        pageCount={dataQuery.data.data.TotalPages}
                        pagination={pagination}
                        emptyTitle="Нет данных"
                        emptyMessage={
                            isEmpty
                                ? "По заданным параметрам данных для выгрузки нет"
                                : "По заданным параметрам результатов нет"
                        }
                        searchTitle="Поиск по участнику"
                        data={dataQuery.data.data.Content}
                        isFetching={dataQuery.isFetching}
                        rowSelection={rowSelection}
                        onPaginationChange={setPagination}
                        onRowSelectionChange={setRowSelection}
                        onColumnFiltersChange={setColumnFilters}
                        setSelectedRows={setSelectedRows}
                        sorting={sorting}
                        onSortingChange={setSorting}
                        onSearch={setSearch}
                        searchRowClassName={`gap-6.5 !grid grid-cols-2 ${isEmpty && "!grid-cols-1"}`}
                        searchClassName={"!w-full"}
                        searchBlockClassName={`col-start-2 col-end-2 ${isEmpty && "hidden"}`}
                        defaultSortOrder={{
                            "userLastName,userFirstName": "asc",
                            completedProgramCount: "asc",
                            completedCourseCount: "asc",
                            completedQuizCount: "asc",
                            completedExerciseCount: "asc",
                        }}
                    />
                </div>
            )}
        </>
    );
};
