import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import { ColumnDef, ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { useSelector } from "react-redux";
import { ResourceState, ResourceType, RoleName } from "Enums";
import { Filter, IFilterRow } from "Components/Filter/Filter";
import {
    Button,
    Checkbox,
    Icon,
    Icons,
    PopoverList,
    SelectMultiValueRemove,
    SelectValueContainer,
    Table,
    SimpleModal,
    Loader,
} from "Uikit";
import { MultiClumpTooltip } from "Components/MultiClumpTooltip/MultiClumpTooltip";
import { BasePaginationResponse } from "Api/BaseResponse";
import { ProgramListResponse } from "Api/Responses/ProgramResponse";
import { AccountableUser, UserListResponse } from "Api/Responses/UserResponse";
import { ProgramListRequest } from "Api/Requests/ProgramRequest";
import Api from "Api";
import { Confirmation } from "Components/Confirmation/Confirmation";
import { useDialog } from "hooks/useDialog";
import { useInvalidate } from "hooks/useInvalidate";
import { DateFormat, formatDate } from "helpers/dateHelper";
import { useCurrentUser } from "hooks/useCurrentUser";
import { TVoidFunction, IAdministratorsOption, IOption } from "types";
import { LogoSize } from "Api/Services/UploadApi";
import { useResponsibleList } from "Api/Hooks/useResponsibleList";
import { IReducer } from "store";
import { urlProgram } from "./Programs";
import { ProgramsEmpty } from "./ProgramsEmpty";
import { ProgramsRowActionPopover } from "./ProgramsRowActionPopover";
import { ModalCopyToCompanyForm, TOpenCopyingToCompanyDialogArg } from "Components/ModalCopyToCompanyForm";

interface IProgramsTable {
    categoryId?: string;
    selectedCategories: string[];
    onState: (ids: string[], state: string, resourceType: ResourceType) => Promise<void>;
    onCopy: (id: string) => void;
    fetchPrograms: (props: ProgramListRequest) => Promise<BasePaginationResponse<ProgramListResponse>>;
    onCreateCategory: TVoidFunction;
}

export const ProgramsTable = ({
    categoryId,
    selectedCategories,
    onState,
    onCopy,
    fetchPrograms,
    onCreateCategory,
}: IProgramsTable) => {
    const navigate = useNavigate();
    const [rowSelection, setRowSelection] = useState({});
    const invalidate = useInvalidate();
    const currentUser = useCurrentUser();
    const isRoot = useSelector((state: IReducer) => state.auth.isRoot);

    const { dialogState, openDialog, closeDialog } = useDialog();

    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 15 });
    const [isSelectedState, setIsSelectedState] = useState(false);
    const [isProgramsArchived, setIsProgramsArchived] = useState(false);
    const [isProgramsCopying, setIsProgramsCopying] = useState(false);
    const [isFilterShow, setIsFilterShow] = useState(false);
    const [sorting, setSorting] = useState<SortingState>([{ id: "modifyTime", desc: true }]);
    const [filters, setFilters] = useState<{ [id: string]: any }>({});
    // Копирование программы в компанию
    const [copyingCompanyId, setCopyingCompanyId] = useState<string | null>(null);
    const copyingCompanyIdRef = useRef<string | null>(null);
    const [copyingCompanyResponsible, setCopyingCompanyResponsible] = useState<IAdministratorsOption | null>(null);
    const copyingCompanyResponsibleRef = useRef<IAdministratorsOption | null>(null);
    // TODO: Доработать после получения эндпоинта копирования в компанию
    const [isNotEnoughSpace, setIsNotEnoughSpace] = useState(false);
    const [companyOpt, setCompanyOpt] = useState<IOption[]>([]);

    const isFiltersEmpty = useMemo(() => {
        if (Object.keys(filters).indexOf("searchQuery.contains") !== -1) {
            return false;
        }

        return Object.keys(filters).every((f) => !filters[f]);
    }, [filters]);

    const { data: ratingInfo } = useQuery(["program/filter-rating-info"], Api.Program.FilterRatingInfo);

    const pagination = useMemo(() => ({ pageIndex, pageSize }), [pageIndex, pageSize]);
    const dataQuery = useQuery(
        ["programs", "collection", pagination, sorting, filters, categoryId, selectedCategories],
        async () => {
            const tasksRequest = new ProgramListRequest();
            tasksRequest.size = pageSize;
            tasksRequest.page = pageIndex;
            tasksRequest.sort = sorting.map((s: any) => `${s.id},${s.desc ? "desc" : "asc"}`).join(";") || "desc";

            const filtersKeys = Object.keys(filters);
            const filtersData: any = {};

            for (const element of filtersKeys) {
                if (element === "modifyTime") {
                    filtersData[element + ".greaterThanOrEqual"] = Math.round(
                        filters[element]["date"]["startDate"].getTime() / 1000,
                    );
                    filtersData[element + ".lessThanOrEqual"] = Math.round(
                        filters[element]["date"]["endDate"].getTime() / 1000,
                    );
                } else if (element === "ratingPoints" || element === "averageReviewRating") {
                    if (filters[element]["minValue"]) {
                        filtersData[element + ".greaterThanOrEqual"] = filters[element]["minValue"];
                    }
                    if (filters[element]["maxValue"]) {
                        filtersData[element + ".lessThanOrEqual"] = filters[element]["maxValue"];
                    }
                } else if (element === "assignedToTeamId.in" && filters[element].length > 0) {
                    filtersData[element] = filters[element].join(",");
                } else if (["managerUserId.in", "assignedToUserId.in"].includes(element)) {
                    filtersData[element] = filters[element].map((p: any) => p.value.id).join(",");
                } else if (element === "hasDeadline") {
                    filtersData["deadlineTime.isNull"] = "false";
                } else if (element === "deadlineTime.lessThanOrEqual") {
                    filtersData[element] = Math.round(new Date().getTime() / 1000);
                } else if (element === "isHidden") {
                    filtersData["state.equal"] = ResourceState.HIDDEN;
                } else if (element === "isNotAssigned") {
                    filtersData["assignedToTeamId.isNull"] = true;
                    filtersData["assignedToUserId.isNull"] = true;
                } else if (["isRequired", "publicAccess", "issueCertificate"].includes(element)) {
                    filtersData[`${element}.equal`] = filters[element];
                } else {
                    filtersData[element] = filters[element];
                }
            }

            if (categoryId) {
                filtersData["categoryId.equal"] = categoryId;
            }
            if (selectedCategories.length !== 0) {
                filtersData["categoryId.in"] = selectedCategories.join(",");
            }
            filtersData["state.notEqual"] = ResourceState.ARCHIVED;
            if (!filtersData["assignedToTeamId.in"]?.length) {
                delete filtersData["assignedToTeamId.in"];
            }

            tasksRequest.filters = filtersData;

            return await fetchPrograms(tasksRequest);
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );

    const onSearch = (searchQuery: string) => {
        setFilters((prevState) => ({ ...prevState, "searchQuery.contains": searchQuery }));
    };

    const responsibleList = useResponsibleList();
    const loadResponsibleListOptions = useCallback(
        async (inputValue: string): Promise<any> => {
            if (inputValue === "") {
                return {
                    options: [],
                    hasMore: false,
                };
            }

            const selectOptions = responsibleList
                .filter((p) => p.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1)
                .map((p) => ({ label: p.label, value: p.payload }));

            return {
                options: selectOptions,
                hasMore: false,
            };
        },
        [responsibleList],
    );

    const onCopyHandler = useCallback(
        async (program: ProgramListResponse) => {
            openDialog({
                title: "Создание копии программы обучения",
                description: "«" + program.title + "»",
                content:
                    "Вы уверены, что хотите создать копию программы? Копируется вся программа со всеми настройками",
                closeBtnText: "Отмена",
                submitBtnText: "Создать копию",
                submitBtnColor: "primary",
                onRequestClose: () => closeDialog(),
                onRequestSubmit: () => {
                    onCopy(program.id);
                    closeDialog();
                },
            });
        },
        [closeDialog, onCopy, openDialog],
    );

    const onRequestSubmit = async (
        ids: string[],
        copyingCompanyId: string,
        copyingCompanyResponsible: IAdministratorsOption,
        isMultiple: boolean,
        data: ProgramListResponse | string[],
    ) => {
        setRowSelection({});
        closeDialog();

        setIsProgramsCopying(true);
        // TODO: Переделать на эндпоинт отправки копирования сущностей в компанию
        console.log(ids, copyingCompanyId, copyingCompanyResponsible);
        // await Api.CompanyApi.CopyEntities(copyingCompanyId, ids, copyingCompanyResponsible.value)
        const promise = new Promise((resolve) => setTimeout(resolve, 3000));
        await promise.then();

        setIsProgramsCopying(false);

        setIsNotEnoughSpace(true);
        openCopyingToCompanyDialog({
            ids,
            isMultiple,
            data,
            isNotEnoughSpace: true,
            companyId: copyingCompanyIdRef.current,
            companyResponsible: copyingCompanyResponsibleRef.current,
            companies: companyOpt,
        });
    };

    const openCopyingToCompanyDialog = ({
        ids,
        isMultiple,
        data,
        isNotEnoughSpace,
        companyId,
        companyResponsible,
        companies,
    }: TOpenCopyingToCompanyDialogArg) => {
        openDialog({
            title: "Копирование в компанию",
            description: isMultiple ? `Выбрано ${(data as string[]).length} элементов` : "Программа обучения",
            content: (
                <>
                    <ModalCopyToCompanyForm
                        // companyId={copyingCompanyId}
                        companyId={companyId}
                        setCompanyId={(id) => {
                            copyingCompanyIdRef.current = id;
                            setCopyingCompanyId(id);
                        }}
                        companyResponsible={companyResponsible}
                        setCompanyResponsible={(copyingCompanyResponsible) => {
                            copyingCompanyResponsibleRef.current = copyingCompanyResponsible;
                            setCopyingCompanyResponsible(copyingCompanyResponsible);
                            setIsNotEnoughSpace(false);
                        }}
                        isNotEnoughSpace={isNotEnoughSpace}
                        companies={companies}
                    />
                </>
            ),
            closeBtnText: "Отмена",
            closeBtnColor: "danger",
            closeBtnVariant: "standard",
            submitBtnText: "Копировать",
            submitBtnColor: "primary",
            onRequestClose: () => {
                setIsNotEnoughSpace(false);
                setCopyingCompanyId(null);
                copyingCompanyIdRef.current = null;
                setCopyingCompanyResponsible(null);
                copyingCompanyResponsibleRef.current = null;
                closeDialog();
            },
            onRequestSubmit: () => {
                onRequestSubmit(
                    ids,
                    String(copyingCompanyIdRef.current),
                    copyingCompanyResponsibleRef.current as IAdministratorsOption,
                    isMultiple,
                    data as string[] | ProgramListResponse,
                );
            },
        });
    };
    // ProgramListResponse
    // description: isMultiple ? `Выбрано ${data.length} элементов` : "Программа обучения",
    const onCopyToCompanyHandler = async (data: ProgramListResponse | string[]) => {
        const isGotChecked = Array.isArray(data);
        const isMultiple = isGotChecked && data.length > 1;
        // const rows = isGotChecked ? data : [data.id];

        const ids: string[] = !isGotChecked ? [data.id] : data;

        openCopyingToCompanyDialog({
            ids,
            isMultiple,
            data,
            isNotEnoughSpace: false,
            companyId: copyingCompanyId,
            companyResponsible: copyingCompanyResponsible,
            companies: companyOpt,
        });
    };
    const onArchiveHandler = useCallback(
        async (data: ProgramListResponse | string[]) => {
            const isGotChecked = Array.isArray(data);
            const isMultiple = isGotChecked && data.length > 1;
            const rows = isGotChecked ? data : [data.id];

            const onRequestSubmit = async (ids: string[], state: ResourceState) => {
                setIsProgramsArchived(true);
                await onState(ids, state, ResourceType.PROGRAM);
                setRowSelection({});

                invalidate("programs");

                setIsProgramsArchived(false);
                closeDialog();
            };

            const ids: string[] = !isGotChecked ? [data.id] : data;

            setIsSelectedState(false);

            let programTitle = "";

            if (!isGotChecked) {
                programTitle = data.title;
            }

            if (isGotChecked && data.length === 1) {
                programTitle = dataQuery.data!.Content.find((course) => course.id === ids[0])!.title;
            }

            const available = await Api.Program.StateCheck(rows, ResourceState.ARCHIVED, ResourceType.PROGRAM);

            if (available.length !== ids.length) {
                let content = `Программу обучения нельзя архивировать, так как тест в ее составе находится 
                в разделе «Проверка». Вы можете скрыть программу обучения для пользователей и архивировать после 
                проверки результатов`;

                if (isMultiple) {
                    if (available.length > 0) {
                        content = `Некоторые выделенные программы обучения нельзя архивировать, так как тест из 
                        состава программы обучения находится в разделе «Проверка». Вы можете архивировать часть 
                        программ обучения или скрыть все выделенные программы обучения, чтобы они перестали 
                        отображаться у пользователей`;
                    } else {
                        content = `Выделенные программы обучения нельзя архивировать, так как тест из состава программы 
                        обучения находится в разделе «Проверка». Вы можете скрыть все выделенные программы обучения, 
                        чтобы они перестали отображаться у пользователей`;
                    }
                }

                openDialog({
                    title: "Архивирование невозможно",
                    description: isMultiple ? `Выделено программ обучения: ${data.length}` : `«${programTitle}»`,
                    content: content,
                    closeBtnText: "Отмена",
                    submitBtnText: isMultiple ? "Скрыть все" : "Скрыть",
                    submitBtnColor: "danger",
                    onRequestClose: () => closeDialog(),
                    onRequestSubmit: () => {
                        onRequestSubmit(ids, ResourceState.HIDDEN);
                    },
                    onRequestSubmitAdd:
                        isMultiple && available.length > 0
                            ? () => {
                                  onRequestSubmit(available, ResourceState.ARCHIVED);
                              }
                            : undefined,
                    submitBtnAddText: `Архивировать ${available.length} из ${ids.length}`,
                });

                return;
            }

            openDialog({
                title: isMultiple ? "Архивирование программ обучения" : "Архивирование программы обучения",
                description: isMultiple ? `Выбрано программ обучения: ${data.length}` : "«" + programTitle + "»",
                content: isMultiple
                    ? "Вы действительно хотите архивировать программы обучения? Программы обучения переместятся в Архив и исчезнут у пользователей"
                    : "Вы действительно хотите архивировать программу обучения? Программа обучения переместится в Архив и исчезнет у пользователей",
                closeBtnText: "Отмена",
                submitBtnText: "Архивировать",
                submitBtnColor: "danger",
                onRequestClose: () => closeDialog(),
                onRequestSubmit: () => {
                    onRequestSubmit(ids, ResourceState.ARCHIVED);
                },
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [invalidate, closeDialog, openDialog, onState, dataQuery.data],
    );
    const onStateHandler = useCallback(
        (state: string) => {
            setIsSelectedState(false);
            const isMultiple = Object.keys(rowSelection).length > 1;

            let content = `У всех пользователей 
            ${!isMultiple ? "пропадет данная программа обучения" : "пропадут данные программы обучения"}`;

            if (state === ResourceState.ACTIVE) {
                content = `Все пользователи, у кого есть доступ, увидят 
                ${!isMultiple ? "данную программу обучения" : "данные программы обучения"}`;
            }

            openDialog({
                title: `Изменить статус на «${state === ResourceState.ACTIVE ? "Активен" : "Скрыт"}»`,
                description: isMultiple
                    ? `Выбрано программ обучения: ${Object.keys(rowSelection).length}`
                    : `«${(dataQuery.data?.Content[0] as ProgramListResponse).title}»`,
                content: content,
                closeBtnText: "Отмена",
                submitBtnText: "Изменить",
                submitBtnColor: "primary",
                onRequestClose: () => closeDialog(),
                onRequestSubmit: () => {
                    onState(Object.keys(rowSelection), state, ResourceType.PROGRAM).then(() => {
                        setRowSelection({});

                        invalidate("programs");
                        closeDialog();
                    });
                },
            });
        },
        [rowSelection, openDialog, dataQuery.data, closeDialog, onState, invalidate],
    );

    const columns = useMemo<ColumnDef<ProgramListResponse>[]>(
        () => [
            {
                id: "select",
                enableResizing: true,
                size: 16,
                header: ({ table }) => (
                    <Checkbox
                        checked={table.getIsAllRowsSelected()}
                        indeterminate={table.getIsSomeRowsSelected()}
                        onChange={table.getToggleAllRowsSelectedHandler()}
                    />
                ),
                cell: ({ row }) => (
                    <Checkbox
                        checked={row.getIsSelected()}
                        indeterminate={row.getIsSomeSelected()}
                        onChange={row.getToggleSelectedHandler()}
                    />
                ),
            },
            {
                header: "Название",
                accessorKey: "title",
                footer: (props) => props.column.id,
                className: "w-1/2",
                cell: ({
                    row: {
                        original: { id, logoId, title },
                    },
                }) => {
                    return (
                        <div
                            className="group flex items-center space-x-3 cursor-pointer"
                            onClick={() => navigate(`${urlProgram}/${id}`)}
                        >
                            <div className="w-[54px] shrink-0">
                                <img
                                    className="rounded-md w-[54px] h-9 object-cover"
                                    src={Api.Upload.GetLogo(logoId, LogoSize.THUMB_MICRO)}
                                />
                            </div>
                            {/* <span className="group-hover:text-blue break-all">{String(title)}</span> */}
                            <MultiClumpTooltip className="group-hover:text-blue" clamp={1} label={String(title)} />
                        </div>
                    );
                },
            },
            {
                header: "Статус",
                accessorKey: "state",
                enableResizing: true,
                size: 100,
                footer: (props) => props.column.id,
                cell: ({
                    row: {
                        original: { state },
                    },
                }) => {
                    return state === ResourceState.HIDDEN ? <span className="text-[#939393]">Скрыт</span> : "Активен";
                },
            },
            {
                header: "Оценка",
                accessorKey: "averageReviewRating",
                enableResizing: true,
                size: 100,
                cell: (info) => {
                    return (
                        <div>
                            {info.row.original.averageReviewRating === 0
                                ? "-"
                                : info.row.original.averageReviewRating.toFixed(1)}
                        </div>
                    );
                },
                footer: (props) => props.column.id,
            },
            {
                header: "Баллы",
                accessorKey: "ratingPoints",
                enableResizing: true,
                size: 100,
                footer: (props) => props.column.id,
                cell: ({
                    row: {
                        original: { ratingPoints },
                    },
                }) => {
                    return <div>{ratingPoints}</div>;
                },
            },
            {
                header: "Обновлен",
                accessorKey: "modifyTime",
                enableResizing: true,
                size: 200,
                cell: ({
                    row: {
                        original: { modifyTime },
                    },
                }) => {
                    return formatDate(new Date(Number(modifyTime) * 1000), DateFormat.DATE_TIME_LONG);
                },
                footer: (props) => props.column.id,
            },
            {
                id: "buttons",
                header: "",
                accessor: "",
                enableResizing: true,
                size: 30,
                cell: ({ row }) => {
                    return (
                        <div className="flex">
                            <Button
                                shape="round"
                                color="common"
                                icon={
                                    <Icon
                                        icon={Icons.Pencil}
                                        width="18px"
                                        height="18px"
                                        color="fill-blue-drk hover:fill-blue-hover"
                                    />
                                }
                                iconPlacement="center"
                                onClick={() => {
                                    navigate(`${urlProgram}/${row.original.id}/edit`);
                                }}
                            />
                            <ProgramsRowActionPopover
                                program={row.original}
                                onCopyHandler={onCopyHandler}
                                onArchiveHandler={onArchiveHandler}
                                onCopyToCompanyHandler={onCopyToCompanyHandler}
                                isRoot={isRoot}
                            />
                        </div>
                    );
                },
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [onArchiveHandler, onCopyHandler],
    );

    const loadActiveUserOptions = async (
        inputValue: string,
        prevOptions: unknown,
        { page }: { page: number },
    ): Promise<any> => {
        if (inputValue === "") {
            return {
                options: [],
                hasMore: false,
            };
        }

        const users = await Api.User.GetActiveListOptions(inputValue, page);
        const usersSelectOptions: { label: string; value: AccountableUser | UserListResponse | undefined }[] =
            users.Content.map((p) => ({ label: p.lastName + " " + p.firstName, value: p }));
        return {
            options: usersSelectOptions,
            hasMore: users.TotalPages > page,
            additional: {
                page: page + 1,
            },
        };
    };

    const formatUserOptionsLabel = ({
        label,
        value,
    }: {
        label: string;
        value: AccountableUser | UserListResponse | undefined;
    }): React.ReactElement => {
        return (
            <div className="flex gap-2">
                {value?.avatarId ? (
                    <img
                        src={`/service/lms-upload/api/file/download/${value.avatarId}`}
                        className="w-5 h-5 rounded-full"
                    />
                ) : (
                    <div
                        className={`flex-center w-5 h-5 rounded-full`}
                        style={{ backgroundColor: value?.defaultAvatarColor }}
                    >
                        <span className="text-white text-xxs">
                            {value?.firstName[0]}
                            {value?.lastName[0]}
                        </span>
                    </div>
                )}
                <div>{label}</div>
            </div>
        );
    };

    const controlButtons = (
        <div className="flex items-center space-x-4 w-full">
            <div className="flex flex-grow space-x-4">
                {!isEmpty(rowSelection) && (
                    <>
                        <Button
                            onClick={() => onArchiveHandler(Object.keys(rowSelection))}
                            color="danger"
                            size="medium"
                            id="adminTasksBtnArchive"
                        >
                            Архивировать
                        </Button>
                        {isRoot && (
                            <Button
                                onClick={() => onCopyToCompanyHandler(Object.keys(rowSelection))}
                                color="primary"
                                size="medium"
                                id="adminTasksBtnArchive"
                            >
                                Копировать в компанию
                            </Button>
                        )}
                        <PopoverList
                            offset={[0, 10]}
                            visible={isSelectedState}
                            onClickOutside={() => setIsSelectedState(false)}
                        >
                            <Button
                                variant="outline"
                                size="medium"
                                color="common"
                                className="border-gray-blue"
                                icon={<Icon className="" icon={Icons.ChevronDown} width={10} height={6} />}
                                iconPlacement="right"
                                onClick={() => setIsSelectedState(true)}
                                id="adminTasksBtnChangeStatus"
                            >
                                Изменить статус
                            </Button>
                            <PopoverList.Item
                                name="setStatusHide"
                                onClickItem={() => onStateHandler(ResourceState.HIDDEN)}
                            >
                                Скрыт
                            </PopoverList.Item>
                            <PopoverList.Item
                                name="setStatusActive"
                                onClickItem={() => onStateHandler(ResourceState.ACTIVE)}
                            >
                                Активен
                            </PopoverList.Item>
                        </PopoverList>
                    </>
                )}
            </div>

            <Button
                variant="outline"
                color="secondary"
                size="medium"
                className="border-[#E6E9ED] rounded-lg font-medium"
                icon={<Icon icon={Icons.Filter} width={20} height={20} color="stroke-blue" />}
                iconPlacement={"left"}
                onClick={() => setIsFilterShow(true)}
                id="adminTasksBtnFilter"
            >
                Фильтры
            </Button>
            <Button
                className="rounded-lg whitespace-nowrap font-medium"
                size="medium"
                icon={<Icon icon={Icons.PlusFilled} color="fill-white" width={20} height={20} />}
                iconPlacement={"left"}
                onClick={() => navigate(`${urlProgram}${categoryId ? `?categoryId=${categoryId}` : ""}`)}
            >
                Создать программу
            </Button>
        </div>
    );

    const filtersConfig = useMemo(
        () => [
            {
                label: "Дата обновления",
                fields: [
                    {
                        accessor: "modifyTime",
                        type: "date-range",
                    },
                ],
            },
            {
                label: "Награда, баллы",
                fields: [
                    {
                        accessor: "ratingPoints",
                        type: "range",
                        min: ratingInfo?.minRatingPoints,
                        max: ratingInfo?.maxRatingPoints,
                    },
                ],
            },
            {
                label: "Оценка программы, баллы",
                fields: [
                    {
                        accessor: "averageReviewRating",
                        type: "range",
                        min: ratingInfo?.minAverageReviewRating,
                        max: ratingInfo?.maxAverageReviewRating,
                    },
                ],
            },
            {
                label: "Назначенные на команду",
                fields: [
                    {
                        accessor: "assignedToTeamId.in",
                        type: "team-multi-select",
                    },
                ],
            },
            {
                label: "Назначенные на участника",
                fields: [
                    {
                        accessor: "assignedToUserId.in",
                        type: "async-multi-select",
                        placeholder: "Выберите участника",
                        loadOptions: loadActiveUserOptions,
                        formatOptionLabel: formatUserOptionsLabel,
                        components: {
                            MultiValueRemove: SelectMultiValueRemove,
                            ValueContainer: SelectValueContainer({}),
                        },
                        lazy: true,
                    },
                ],
            },
            {
                label: "Ответственный",
                fields: [
                    {
                        accessor: "managerUserId.in",
                        type: "async-multi-select",
                        placeholder: "Выберите ответственного",
                        loadOptions: loadResponsibleListOptions,
                        formatOptionLabel: formatUserOptionsLabel,
                        components: {
                            MultiValueRemove: SelectMultiValueRemove,
                            ValueContainer: SelectValueContainer({}),
                        },
                        lazy: true,
                    },
                ],
            },
            {
                label: "Дополнительно",
                className: "!border-b-0 !p-0",
                resetButton: false,
                fields: [
                    {
                        accessor: "isRequired",
                        label: "Обязательные",
                        type: "checkbox",
                        default: false,
                    },
                    {
                        accessor: "hasDeadline",
                        label: "С дедлайном",
                        type: "checkbox",
                        default: false,
                    },
                    {
                        accessor: "deadlineTime.lessThanOrEqual",
                        label: "Дедлайн прошел",
                        type: "checkbox",
                        default: false,
                    },
                    {
                        accessor: "isHidden",
                        label: "Скрытые",
                        type: "checkbox",
                        default: false,
                    },
                    {
                        accessor: "publicAccess",
                        label: "С общим доступом",
                        type: "checkbox",
                        default: false,
                    },
                    {
                        accessor: "issueCertificate",
                        label: "Выдают сертификат",
                        type: "checkbox",
                        default: false,
                    },
                    {
                        accessor: "isNotAssigned",
                        label: "Никому не назначены",
                        type: "checkbox",
                        default: false,
                    },
                ],
            },
        ],
        [ratingInfo, loadResponsibleListOptions],
    );

    useEffect(() => {
        setFilters({});
        setRowSelection({});
    }, [categoryId]);

    useEffect(() => {
        const fetch = async () => {
            const companies = await Api.CompanyApi.List();
            setCompanyOpt(companies.Content.map((c) => ({ label: c.name, value: c.id })));
        };

        if (isRoot) {
            fetch().then();
        }
    }, [isRoot]);

    return (
        <>
            <Confirmation
                {...dialogState}
                requestSubmitDisabled={
                    isProgramsArchived ||
                    (isRoot && (!copyingCompanyId || !copyingCompanyResponsible || isNotEnoughSpace))
                }
                requestSubmitAddDisabled={isProgramsArchived}
            />

            {isRoot && (
                <SimpleModal
                    isOpen={isProgramsCopying}
                    title={"Идет копирование"}
                    description={"Пожалуйста, не закрывайте это окно "}
                    size="medium"
                >
                    <div className="pt-5">
                        <Loader size={70} />
                    </div>
                </SimpleModal>
            )}

            {isFiltersEmpty && dataQuery.data?.Content && dataQuery.data?.Content.length === 0 && (
                <ProgramsEmpty
                    isEmptyCategories={categoryId === undefined}
                    onCreateCategoryClick={onCreateCategory}
                    onCreateProgramClick={() =>
                        navigate(`${urlProgram}${categoryId ? `/?categoryId=${categoryId}` : ""}`)
                    }
                />
            )}

            {!isFiltersEmpty || (dataQuery.data?.Content && dataQuery.data?.Content.length > 0) ? (
                <>
                    <Filter
                        isActive={isFilterShow}
                        setIsActive={setIsFilterShow}
                        configuration={filtersConfig as IFilterRow[]}
                        filters={filters}
                        onChange={setFilters}
                        hideTeamMembers={currentUser?.data?.role === RoleName.ADMIN}
                    />
                    <Table
                        columns={columns}
                        controlButtons={controlButtons}
                        columnFilters={columnFilters}
                        pageCount={dataQuery.data?.TotalPages}
                        pagination={pagination}
                        emptyMessage="По заданным параметрам результатов нет"
                        searchTitle="Поиск по названию"
                        isFetching={dataQuery.isFetching}
                        data={dataQuery.data?.Content}
                        rowSelection={rowSelection}
                        onPaginationChange={setPagination}
                        onRowSelectionChange={setRowSelection}
                        onColumnFiltersChange={setColumnFilters}
                        sorting={sorting}
                        onSortingChange={setSorting}
                        onSearch={onSearch}
                    />
                </>
            ) : null}
        </>
    );
};
