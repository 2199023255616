import React, { ReactNode, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useBlocker, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { Breadcrumbs, Button, Content, flash, Icon, Icons, Tabs, TabsWrapper, Toggle } from "Uikit";
import { MultiClumpTooltip } from "Components/MultiClumpTooltip/MultiClumpTooltip";
import { AccessDummy as Access, AccessRef } from "Components/Access/AccessDummy";
import { ContentLayout, TreeWrapperContext } from "Containers";
import { ProgramSettings } from "./ProgramSettings";
import { ProgramContent } from "./ProgramContent";
import { FileUploadType, ResourceState, ResourceType, UIErrorMessages } from "Enums";
import Api from "Api";
import { BadRequestResponse, ErrorCode } from "Api/BaseResponse";
import { Confirmation } from "Components/Confirmation/Confirmation";
import { TeamAccessRequest } from "Api/Requests/AccessRequest";
import { ProgramReadResponse, ProgramResponse } from "Api/Responses/ProgramResponse";
import { ProgramRequest } from "Api/Requests/ProgramRequest";
import { CurrentUserResponse } from "Api/Responses/UserResponse";
import { formatStatusChangeTime } from "helpers/dateHelper";
import { ApproxTime } from "Components/ApproxTime/ApproxTime";
import { useInvalidate } from "hooks/useInvalidate";
import { useDialog } from "hooks/useDialog";
import { CancelModal } from "Components/CancelModal/CancelModal";
import { FeedbackTable } from "Components/FeedbackTable/FeedbackTable";
import { IOption } from "types";
import { useResponsibleList } from "Api/Hooks/useResponsibleList";

interface IProgramProps {
    isEdit?: boolean;
    isCopy?: boolean;
}

const urlPrograms = "/admin/programs";
const urlProgram = "/admin/program";

const responseToRequest = ({
    id,
    title,
    description,
    logoId,
    categoryId,
    publicAccess,
    isRequired,
    issueCertificate,
    hideAvgReviewScore,
    hideUserReviews,
    state,
    publicationTime,
    expirationTime,
    deadlineTime,
    managerUserId,
    sections,
    approxCompletionMinutes,
}: ProgramReadResponse) => {
    const request = new ProgramRequest();

    request.title = title;
    request.description = description;
    request.logoId = logoId;
    request.categoryId = categoryId;
    request.publicAccess = publicAccess;
    request.isRequired = isRequired;
    request.issueCertificate = issueCertificate;
    request.hideAvgReviewScore = hideAvgReviewScore;
    request.hideUserReviews = hideUserReviews;
    request.state = state;
    request.publicationTime = publicationTime;
    request.expirationTime = expirationTime;
    request.deadlineTime = deadlineTime;
    request.managerUserId = managerUserId;
    request.approxCompletionMinutes = approxCompletionMinutes;

    if (id) {
        request.id = id;
    }
    request.sections = sections.map(({ title, courses }) => {
        return {
            title,
            courseIds: courses.map(({ id }) => id),
        };
    });

    return request;
};

export const Program = ({ isEdit, isCopy }: IProgramProps) => {
    const queryClient = useQueryClient();
    const invalidate = useInvalidate();
    const navigate = useNavigate();
    const currentUser: CurrentUserResponse | undefined = queryClient.getQueryData(["users", "current"]);
    const [initCategoryId, setInitCategoryId] = useState("");
    const [categories, setCategories] = useState<IOption[]>([]);
    const [initTitle, setInitTitle] = useState("Новая программа");
    const [isChanged, setIsChanged] = useState(false);
    const [isToggleChanged, setIsToggleChanged] = useState(false);
    const params = new URLSearchParams(location.search);
    const categoryId = params.get("categoryId");

    const [isCancel, setIsCancel] = useState<boolean>(false);
    const [isEditCancel, setIsEditCancel] = useState<boolean>(false);

    const { setTreeProps } = useContext(TreeWrapperContext);
    const [currentTab, setCurrentTab] = useState(0);
    const { id } = useParams();

    const ref = useRef();
    const accessRef = useRef<AccessRef>();

    const blocker = useBlocker((params) => {
        if ((isChanged || isAccessFormDirty) && params?.historyAction !== "REPLACE") {
            setIsCancel(true);
        } else if (params?.historyAction === "REPLACE") {
            return false;
        }

        return isChanged || isAccessFormDirty;
    });

    const [program, setProgram] = useState<
        ProgramReadResponse & { isApproxCompletionMinutesManuallyChanged?: boolean }
    >({
        id: "",
        title: "",
        description: "",
        logoId: null,
        categoryId: initCategoryId,
        publicAccess: null,
        isRequired: false,
        issueCertificate: true,
        hideAvgReviewScore: false,
        hideUserReviews: false,
        state: ResourceState.ACTIVE,
        publicationTime: null,
        expirationTime: null,
        deadlineTime: null,
        managerUserId: "",
        sections: [],
        approxCompletionMinutes: 1,
        isApproxCompletionMinutesManuallyChanged: false,
    });
    const [initialProgram, setInitialProgram] = useState(program);
    const [defaultLogos, setDefaultLogos] = useState<any>([]);
    const [errors, setErrors] = useState<any>({});
    const { dialogState, openDialog, closeDialog } = useDialog();
    const responsibleList = useResponsibleList(program.managerUserId);
    const [cover, setCover] = useState<File | null>(null);
    const [isAccessFormDirty, setIsAccessFormDirty] = useState(false);
    const [addedTeamIds, setAddedTeamIds] = useState<string[]>([]);
    const [removedTeamIds, setRemovedTeamIds] = useState<string[]>([]);
    const [allTeamsAccess, setAllTeamsAccess] = useState<boolean | null>(null);

    useEffect(() => {
        (async () => {
            const defaultLogos = (await Api.Upload.GetDefaultLogos()).Content;

            setDefaultLogos(defaultLogos);
            setProgram((prevState) => {
                return { ...prevState, logoId: defaultLogos[Math.floor(Math.random() * defaultLogos.length)] };
            });

            const categories = (await Api.Program.CategoryGet()).Content.map((p) => {
                return { label: p.title, value: p.id };
            });
            setCategories(categories);

            if (categoryId) {
                setInitCategoryId(categoryId);
            }
        })();

        return () => {
            if (!setTreeProps) {
                return;
            }

            setTreeProps(undefined);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!id) {
            return;
        }

        const fetch = async () => {
            const response = await Api.Program.Read({ uuid: id });

            setProgram({
                ...response,
                state: isCopy ? ResourceState.HIDDEN : response.state,
                title: `${isCopy ? "Копия 1 " : ""}${response.title}`,
            });
            setInitCategoryId(response.categoryId);
            setInitialProgram(structuredClone(response));
            setInitTitle(`${isCopy ? "Копия 1 " : ""}${response.title}`);
        };

        fetch();
    }, [id, isCopy]);

    useEffect(() => {
        if (!setTreeProps) {
            return;
        }

        setTreeProps(undefined);
    }, [setTreeProps]);

    useEffect(() => {
        if (currentUser) {
            setProgram((prevState) => ({
                ...prevState,
                managerUserId: currentUser.id,
            }));
        }
    }, [currentUser]);

    useEffect(() => {
        setIsChanged(!!isEdit || !!isCopy);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChangeCover = (data: File | null, imgBase64?: string) => {
        setCover(data);
        setProgram({ ...program, logoId: imgBase64 });

        setIsChanged(true);
    };

    // Валидация полей перед отправкой
    const onValidate = (): boolean => {
        const errors: any = {};
        const errorMsgRequiredField = "Поле обязательно для заполнения";
        const errorMsgNoSections = "Для сохранения требуется хотя бы одна секция в содержимом программы";
        const errorMsgNoSectionComponents = "В секции должен быть указан хотя бы один курс";

        errors["title"] = !program.title ? errorMsgRequiredField : undefined;
        errors["category"] = !program.categoryId ? errorMsgRequiredField : undefined;
        errors["manager"] = !program.managerUserId ? errorMsgRequiredField : undefined;

        if (program.sections.length === 0) {
            errors["sections"] = errorMsgNoSections;
            flash.error(errorMsgNoSections);
        } else {
            errors["sections"] = {};

            for (const element of program.sections) {
                const questionErrors: any = {};

                if (element.courses.length === 0) {
                    questionErrors["courses"] = errorMsgNoSectionComponents;
                }

                if (Object.keys(questionErrors).length !== 0) {
                    errors["sections"][element.id] = questionErrors;
                }
            }

            if (Object.keys(errors["sections"]).length === 0) {
                errors["sections"] = undefined;
            }
        }

        if (errors["sections"] && typeof errors["sections"] === "object") {
            flash.error(errorMsgNoSectionComponents);
        }

        if (Object.keys(errors).filter((p) => errors[p]).length !== 0) {
            setErrors(errors);
            return false;
        }

        setErrors({});
        return true;
    };

    const getTime = () => {
        if (program.isApproxCompletionMinutesManuallyChanged) {
            return program.approxCompletionMinutes!;
        } else {
            let time = 0;

            for (const element of program.sections) {
                const items = element.courses;
                for (const element of items) {
                    time += element.approxCompletionMinutes;
                }
            }

            return Math.ceil(time) || 1;
        }
    };

    const tabsComponent = (): ReactNode => {
        if (currentTab !== 1 && program.modifyTime) {
            return `Обновлен ${formatStatusChangeTime(program.modifyTime, {
                formatTonight: false,
                showTime: true,
                showYear: true,
                delimiter: ",",
            })}`;
        } else if (currentTab === 1) {
            return (
                <ApproxTime
                    canEdit={true}
                    time={getTime()}
                    onChange={(time: number) => {
                        setProgram({
                            ...program,
                            approxCompletionMinutes: time,
                            isApproxCompletionMinutesManuallyChanged: true,
                        });
                        setIsChanged(true);
                    }}
                />
            );
        } else {
            return "";
        }
    };

    const { mutateAsync: setTeamAccess } = useMutation((payload: TeamAccessRequest) => {
        return Api.LMSRoles.setTeamAccess(payload);
    });

    const onSave = async () => {
        try {
            if (!onValidate()) {
                flash.error("Ошибка, не все поля формы заполнены правильно");
                return;
            }

            if (isAccessFormDirty) {
                let tree: { nodes: { find: () => unknown } };
                if (accessRef?.current) {
                    tree = (accessRef.current as unknown as { getTree: () => any }).getTree();
                }
                const teamIdsFiltered = addedTeamIds
                    .filter((id) => !id.startsWith("root:"))
                    .filter((id) => {
                        const treeNode = (tree.nodes as unknown as any[]).find(({ id: nodeId }) => {
                            return nodeId === id;
                        });
                        return treeNode && !treeNode?.isDisabled;
                    });
                await setTeamAccess(
                    Object.assign(new TeamAccessRequest(), {
                        resourceId: id,
                        resourceType: ResourceType.PROGRAM,
                        resourceState: program.state,
                        addedTeamIds: !allTeamsAccess ? teamIdsFiltered : [],
                        removedTeamIds: removedTeamIds.filter((id) => !id.startsWith("root:")),
                        allTeams: allTeamsAccess,
                    }),
                );

                invalidate("teamAccess", id);
                setIsAccessFormDirty(false);
            }

            program.publicAccess = allTeamsAccess;

            // Upload program cover;
            let response: ProgramResponse;

            try {
                if (cover) {
                    const uploadFileResponse = await Api.File.UploadFile(
                        cover,
                        undefined,
                        undefined,
                        false,
                        FileUploadType.RESOURCE_LOGO,
                    );
                    program.logoId = uploadFileResponse.id;
                }
            } catch (e: unknown) {
                const knownError = e as BadRequestResponse;
                let message = "Размер обложки слишком большой!";
                if (
                    [ErrorCode.CORRUPT_FILE_ERROR, ErrorCode.FILE_EXTENSION_ERROR].includes(
                        String(knownError.errorCode) as ErrorCode,
                    )
                ) {
                    message = UIErrorMessages.FILE_LOADING_ERROR;
                }
                flash.error(message);
                return;
            }

            if (program.isApproxCompletionMinutesManuallyChanged) {
                program.approxCompletionMinutes = getTime();
            } else {
                delete program.approxCompletionMinutes;
            }

            const request = responseToRequest(program);

            if (program.id && !isCopy) {
                response = await Api.Program.Edit(request);
            } else {
                response = await Api.Program.Create(request);
            }
            flash.success("Все изменения сохранены!");

            program.modifyTime = response.modifyTime;

            setInitialProgram(structuredClone(program));
            setInitCategoryId(program.categoryId);

            setIsChanged(false);
            setIsToggleChanged(false);

            setInitTitle(program.title);
            navigate(`${urlProgram}/${response.id}`, { replace: true });
        } catch (ex) {
            console.log(ex);
            flash.error("Программа обучения с таким именем уже существует!");
        }
    };

    const onSubmit = async () => {
        if (!isToggleChanged) {
            await onSave();
        } else {
            openDialog({
                title: `Изменить статус на ${program.state === ResourceState.ACTIVE ? "«Активен»" : "«Скрыт»"}`,
                content:
                    program.state === ResourceState.ACTIVE
                        ? "Все пользователи, у кого есть доступ, увидят данную программу обучения"
                        : "У всех пользователей пропадёт данная программа обучения",
                closeBtnText: "Отмена",
                submitBtnText: "Изменить",
                submitBtnColor: "primary",
                onRequestClose: () => closeDialog(),
                onRequestSubmit: () => {
                    onSave();

                    closeDialog();
                },
            });
        }
    };

    const onCancelChange = () => {
        program.id ? setIsEditCancel(true) : navigate(urlPrograms);
    };

    const onCancelModalSubmit = useCallback(async () => {
        setIsChanged(false);

        if (isCancel && blocker.state === "blocked") {
            blocker.proceed();
        } else {
            setIsEditCancel(false);
            setIsAccessFormDirty(false);
            setProgram(structuredClone(initialProgram));
            const initialAccess = await Api.LMSRoles.getTeamAccess(id!);
            if (ref?.current) {
                (
                    ref.current as { resetPublicationToggles: (initialProgram: ProgramReadResponse) => void }
                ).resetPublicationToggles(initialProgram);
            }
            if (accessRef?.current) {
                (accessRef.current as { resetAccess: (data: any) => void }).resetAccess(initialAccess);
            }
            navigate(`${urlProgram}/${initialProgram.id}`, { replace: true });
        }
    }, [blocker, initialProgram, isCancel, navigate, id]);

    return (
        <>
            <CancelModal
                id="programPageCancelModal"
                isEdit={isEditCancel}
                isOpen={isCancel || isEditCancel}
                setIsOpen={isEditCancel ? setIsEditCancel : setIsCancel}
                onSubmit={onCancelModalSubmit}
            />
            <ContentLayout className="h-full">
                <Breadcrumbs className="mb-2.5">
                    <Breadcrumbs.Link title="Администратор" />
                    <Breadcrumbs.Link title="Программы обучения" url={urlPrograms} />
                    {initCategoryId && (
                        <Breadcrumbs.Link
                            title={categories.find((p) => p.value === initCategoryId)?.label ?? ""}
                            url={`${urlPrograms}/${initCategoryId}`}
                        />
                    )}
                    <Breadcrumbs.Link
                        title={initTitle}
                        url={program.id ? `${urlProgram}/${program.id}` : "/admin/programs"}
                    />
                </Breadcrumbs>
                <div className="flex justify-between items-center mb-7.5 gap-4">
                    <h1>
                        <MultiClumpTooltip label={initTitle} clamp={1} textClassName="!leading-8"></MultiClumpTooltip>
                    </h1>
                    <div className="flex items-center">
                        <Toggle
                            className="mr-7.5 font-semibold"
                            label={program.state !== ResourceState.HIDDEN ? "Активен" : "Скрыт"}
                            enabled={program.state ? program.state === ResourceState.ACTIVE : true}
                            onChange={(p) => {
                                const updatedProgram = {
                                    ...program,
                                    state: p ? ResourceState.ACTIVE : ResourceState.HIDDEN,
                                };
                                if (p) {
                                    updatedProgram.publicationTime = undefined;
                                    updatedProgram.deadlineTime = undefined;
                                }
                                updatedProgram.expirationTime = undefined;
                                setProgram(updatedProgram);
                                setIsChanged(true);
                                setIsToggleChanged((isToggleChanged) => !isToggleChanged);
                            }}
                        />
                        {(isAccessFormDirty || isChanged || !program.id) && (
                            <Button
                                className="mr-4"
                                variant="outline"
                                color="secondary"
                                onClick={onCancelChange}
                                id="adminNewTestBtnCancel"
                            >
                                Отменить
                            </Button>
                        )}
                        {(isAccessFormDirty || isChanged || !program.id) && (
                            <Button onClick={onSubmit} id="adminNewProgramBtnOk">
                                Сохранить
                            </Button>
                        )}
                        {!isAccessFormDirty && !isChanged && program.id && (
                            <Button
                                className="w-10 h-10 !p-0"
                                variant="outline"
                                color="secondary"
                                onClick={() => navigate(urlPrograms)}
                                id="adminNewProgramBtnCrest"
                                shape="square"
                            >
                                <Icon icon={Icons.Close} width={24} height={24} color="fill-[#939393]" />
                            </Button>
                        )}
                    </div>
                </div>
                <TabsWrapper className="flex flex-col flex-grow-1 h-full">
                    <TabsWrapper.Tabs
                        classname="flex flex-grow justify-between items-center h-max"
                        label={tabsComponent()}
                    >
                        <Tabs.Tab
                            title="Настройка"
                            onClick={() => setCurrentTab(0)}
                            error={errors["title"] || errors["categoryId"] || errors["reviewerId"]}
                        />
                        <Tabs.Tab title="Содержимое" onClick={() => setCurrentTab(1)} error={errors["sections"]} />
                        {program.id && (
                            <Tabs.Tab
                                title={`Отзывы${
                                    program.averageReviewRating ? ` (${program.averageReviewRating})` : ""
                                }`}
                                disabled={isChanged || !program.id}
                                tooltip={isChanged || !program.id ? "Будет доступно после сохранения" : ""}
                                onClick={() => setCurrentTab(2)}
                            />
                        )}
                        <Tabs.Tab
                            title="Доступ"
                            onClick={() => setCurrentTab(3)}
                            disabled={isChanged || !program.id}
                            tooltip={isChanged || !program.id ? "Будет доступно после сохранения" : ""}
                        />
                    </TabsWrapper.Tabs>
                    <TabsWrapper.Content className="flex-grow-1" panelClassName="flex-grow-1">
                        <Content.Body>
                            <ProgramSettings
                                errors={errors}
                                defaultLogos={defaultLogos}
                                program={program}
                                onChange={(p) => {
                                    setProgram(p);
                                    setIsChanged(true);
                                }}
                                categories={categories}
                                onCategoriesChange={(category: any) => setCategories([...categories, category])}
                                users={responsibleList}
                                cover={cover}
                                onChangeCover={onChangeCover}
                            />
                        </Content.Body>
                        <Content.Body className="h-full">
                            <ProgramContent
                                program={program}
                                onChange={(p) => {
                                    setProgram(p);
                                    setIsChanged(true);
                                }}
                                errors={errors}
                            />
                        </Content.Body>
                        {program.id && (
                            <Content.Body className="h-full">
                                <FeedbackTable
                                    resourceId={program.id}
                                    resourceType={ResourceType.PROGRAM}
                                    showWarningHideUserReviews={program.hideUserReviews}
                                />
                            </Content.Body>
                        )}
                        <Content.Body>
                            <Access
                                resourceId={id}
                                resourceType={ResourceType.PROGRAM}
                                resourceState={program.state}
                                setIsAccessFormDirty={setIsAccessFormDirty}
                                setTeamIds={({ addedTeamIds, removedTeamIds }) => {
                                    setAddedTeamIds(addedTeamIds);
                                    setRemovedTeamIds(removedTeamIds);
                                }}
                                allTeamsAccess={allTeamsAccess}
                                setAllTeamsAccess={setAllTeamsAccess}
                                nodeAddComponentLink={`/admin/members?teamId.in=`}
                                ref={accessRef}
                            />
                        </Content.Body>
                    </TabsWrapper.Content>
                </TabsWrapper>
                <Confirmation {...dialogState} />
            </ContentLayout>
        </>
    );
};
