import React, { useCallback, useEffect, useState } from "react";
import { ImportRules } from "./ImportRules";
import { Button, FileUpload, flash, Icon, Icons } from "Uikit";
import { useUpload } from "hooks/useUpload";
import { FileUploadType } from "Enums";
import { FILES_TYPE_ICONS } from "constants/attachments";
import { humanFileSize } from "helpers/humanFileSize";
import { Empty } from "Uikit/Page/Empty";
import { UserImportTypes } from "Enums";
import Api from "Api";
import { BadRequestResponse, ErrorCode } from "Api/BaseResponse";
import clsx from "clsx";
import { numWord } from "helpers/numWord";

interface IMembersImportPage {
    isImportAvailable: boolean;
    isCurrentUserImporting: boolean;
    type: UserImportTypes;
}

export const MembersImportPage = ({ isImportAvailable, isCurrentUserImporting, type }: IMembersImportPage) => {
    const [file, setFile] = useState<any>(null);
    const [fileId, setFileId] = useState<any>(null);

    const [isUploading, setIsUploading] = useState(false);
    const [isImporting, setIsImporting] = useState(false);
    const [isFileValidating, setIsFileValidating] = useState(false);

    const [usersCount, setUsersCount] = useState(0);

    const [isFileValid, setIsFileValid] = useState(true);

    const { upload } = useUpload();

    const uploadFile = (file: any) => {
        if (!file) {
            return;
        }

        setIsUploading(true);
        clearFileState();

        const onUpdate = async (file: any) => setFile(file);

        const onFinished = async (file: any) => {
            setFileId(file.serverData.id);
            setFile(file.serverData);
        };

        const extensionIdx = file.name.lastIndexOf(".");
        file["extension"] = file.name.slice(extensionIdx + 1);

        const u = upload({
            file: file,
            type: "new",
            onUpdate,
            onFinished,
            fileUploadType: FileUploadType.USER_IMPORT_FILE,
        });

        u.start()
            .then(() => {
                setFile(u);
                setIsUploading(false);
            })
            .catch(() => {
                setFile(null);
                setIsUploading(false);
            });
    };

    const cancelUpload = () => {
        file?.cancel();
    };

    const removeFile = async (e: any) => {
        e.stopPropagation();
        e.preventDefault();

        clearFileState();
    };

    const handleImport = async () => {
        try {
            setIsImporting(true);
            await Api.User.UsersImport(fileId, type);
            // TODO: убрать задержку и переработать синхронизацию состояний
            await new Promise((resolve) => setTimeout(resolve, 100));
            clearFileState();
        } catch (e) {
            console.log(e);
        } finally {
            setIsImporting(false);
        }
    };

    const clearFileState = useCallback(() => {
        setFile(null);
        setFileId(null);
        setIsFileValid(true);
        setUsersCount(0);
    }, []);

    const getButtonTitle = () => {
        if (file && !isUploading && !isImporting && !isFileValidating && isFileValid) {
            return type === UserImportTypes.CREATE
                ? `Импортировать ${usersCount} ${numWord(usersCount, ["участника", "участников", "участников"])}`
                : `Обновить ${usersCount} ${numWord(usersCount, ["участника", "участников", "участников"])}`;
        } else if (isImporting) {
            return type === UserImportTypes.CREATE ? "Процесс импорта начался..." : "Процесс обновления начался...";
        } else {
            return type === UserImportTypes.CREATE ? "Импортировать" : "Обновить участников";
        }
    };

    useEffect(() => {
        const validate = async () => {
            if (fileId !== null) {
                try {
                    setIsFileValidating(true);
                    const response = await Api.User.UsersImportFileValidation(fileId, type);
                    setUsersCount(response.userCount);
                } catch (e) {
                    const knownError = e as BadRequestResponse;

                    if (knownError.errorCode === ErrorCode.USER_IMPORT_VALIDATION_ERROR) {
                        flash.error("Столбцы в файле не соответствуют шаблону");
                    }
                    setIsFileValid(false);
                } finally {
                    setIsFileValidating(false);
                }
            }
        };

        validate();
    }, [fileId, type]);

    useEffect(() => {
        if (!isImportAvailable && !isImporting) {
            clearFileState();
        }
    }, [isImportAvailable, isImporting, clearFileState]);

    return (
        <>
            <div className="mb-15">
                {!isImportAvailable && !isImporting && (
                    <Empty
                        title={isCurrentUserImporting ? "Импорт уже запущен" : "Загрузка файла невозможна"}
                        description={
                            isCurrentUserImporting
                                ? "Статус можно проверить в уведомлениях"
                                : "Импорт запущен другим пользователем компании"
                        }
                        topElement={
                            <div className="flex-center mb-4">
                                <div className="flex-center w-16.5 h-16.5 rounded-full bg-blue-10">
                                    <Icon icon={Icons.Time} width={"36px"} height={"36px"} color={"fill-primary"} />
                                </div>
                            </div>
                        }
                    />
                )}
                {(!file || isUploading) && isImportAvailable && (
                    <FileUpload
                        attachment={file}
                        onChange={uploadFile}
                        onCancelUpload={cancelUpload}
                        accept={{
                            "application/vnd.ms-excel": [],
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
                        }}
                        maxSize={104857600}
                        acceptDescription=".xls, .xlsx до 100 МБ"
                    />
                )}
                {file && !isUploading && (isImportAvailable || isImporting) && (
                    <div
                        className={clsx(
                            "w-full max-w-[700px] flex p-4 border border-gray-blue rounded-md cursor-pointer",
                            !isFileValid && "border-red",
                        )}
                    >
                        <div className="flex items-center mr-auto">
                            <Icon
                                className="mr-5"
                                icon={FILES_TYPE_ICONS[file?.file.extension]}
                                width="32px"
                                height="32px"
                            />
                            <div>
                                <div className="pb-2 max-w-[550px]">
                                    <span className="block truncate w-full">{file?.file.name}</span>
                                </div>
                                <div className="text-gray-text">{humanFileSize(file?.size, true)}</div>
                            </div>
                        </div>
                        {!isImporting && (
                            <div className="flex">
                                <div onClick={(e) => removeFile(e)} className=" px-1">
                                    <Icon
                                        icon={Icons.Delete}
                                        width={20}
                                        height={20}
                                        color="fill-blue-drk cursor-pointer"
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                )}
                <Button
                    className="rounded-lg whitespace-nowrap font-medium w-full mt-6.25"
                    size="medium"
                    onClick={handleImport}
                    disabled={
                        !isImportAvailable || !file || isUploading || isImporting || isFileValidating || !isFileValid
                    }
                >
                    {getButtonTitle()}
                </Button>
                {isImporting && (
                    <div className="mt-3 text-center text-gray text-sm">
                        {type === UserImportTypes.CREATE
                            ? "Проверить статус импорта можно в уведомлениях"
                            : "Проверить статус обновления можно в уведомлениях"}
                    </div>
                )}
            </div>
            <ImportRules type={type} />
        </>
    );
};
