import React, { useEffect, useState } from "react";

import { Breadcrumbs, Button, ComboBox, FormGroup, Label } from "Uikit";
import Api from "Api";
import { MembersListResponse } from "Api/Responses/UserResponse";
import { StatisticTaskUserRequest } from "Api/Requests/StatisticRequest";
import { ResourceType, StatisticsAttempts } from "Enums";
import { numWord } from "helpers/numWord";
import { IRequestSettingsItem, ReportSettings } from "Pages/Admin/Statistics/ReportSettings/ReportSettings";

import { ReportUserSelect } from "../../../ReportUserSelect/ReportUserSelect";
import { attemptsOptions } from "../TasksWithoutCourse";
import { ReportTable } from "./ReportTable";

export const reportSettings = [
    {
        title: "Задание",
        key: "exercise",
        items: [
            {
                title: "Все",
                value: "all",
                checked: true,
            },
            {
                title: "Категория задания",
                value: "category",
                checked: true,
            },
            {
                title: "Название задания",
                value: "name",
                checked: true,
            },
            {
                title: "Номер прохождения",
                value: "passingNumber",
                checked: true,
            },
            {
                title: "Статус прохождения задания",
                value: "status",
                checked: true,
            },
            {
                title: "Статус видимости задания",
                value: "visibility",
                checked: true,
            },
        ],
    },
    {
        title: "Прохождение",
        key: "passing",
        items: [
            {
                title: "Все",
                value: "all",
                checked: true,
            },
            {
                title: "Дата начала",
                value: "startDate",
                checked: true,
            },
            {
                title: "Дата завершения",
                value: "endDate",
                checked: true,
            },
            {
                title: "Дедлайн",
                value: "deadlineDate",
                checked: true,
            },
            {
                title: "Затрачено времени",
                value: "spendTime",
                checked: true,
            },
            {
                title: "Номер попытки",
                value: "attemptNumber",
                checked: true,
            },
            {
                title: "Начисленные баллы",
                value: "rating",
                checked: true,
            },
            {
                title: "Максимальное количество баллов",
                value: "maxRating",
                checked: true,
            },
        ],
    },
];

export const TaskUser = () => {
    const [users, setUsers] = useState<MembersListResponse[]>([]);
    const [showReportsTable, setShowReportsTable] = useState(false);
    const [attempts, setAttempts] = useState(attemptsOptions[1]);
    const [showReportSettings, setShowReportSettings] = useState(false);
    const [userTasksCount, setUserTasksCount] = useState(0);

    useEffect(() => {
        if (!users[0]) {
            return;
        }

        const fetchTasksCount = async () => {
            const tasksCount = await Api.Statistic.GetUserAvailableResourceCount(ResourceType.EXERCISE, users[0].id);
            setUserTasksCount(+tasksCount);
        };

        fetchTasksCount();
    }, [users]);

    const createReport = () => {
        setShowReportsTable(true);
    };

    const createPayloadParams = () => {
        const payload: StatisticTaskUserRequest = {
            userIds: users.map(({ id }) => id),
            attemptNumber: attempts.value,
        };

        return payload;
    };

    const onSubmit = async (settingsChecked: IRequestSettingsItem[]) => {
        const request: any = {};

        request.params = createPayloadParams();

        settingsChecked.forEach(({ key, items }: IRequestSettingsItem) => {
            request[key] = items
                .filter((item) => item.value !== "all")
                .reduce((prev, cur) => {
                    return {
                        ...prev,
                        [cur.value]: cur.checked,
                    };
                }, {});
        });

        setShowReportSettings(false);

        try {
            await Api.Statistic.GenerateTaskUserStatisticReportFile(request);
        } catch (error: any) {
            console.log(error);
        }
    };

    return (
        <div className="px-6 w-full h-full sm:overflow-y-scroll custom-scrollbar">
            <Breadcrumbs className="mb-1">
                <Breadcrumbs.Link title="Администратор" />
                <Breadcrumbs.Link title="Статистика" />
                <Breadcrumbs.Link title="Задания" />
                <Breadcrumbs.Link title="Отчет по участнику" />
            </Breadcrumbs>
            <div>
                <h1>Отчет по участнику</h1>
                <ReportUserSelect
                    value={users[0] ?? null}
                    onSubmit={(users) => {
                        setUsers(
                            users.map((user) => {
                                const { firstName, lastName, avatarId, defaultAvatarColor } = user;
                                return {
                                    ...user,
                                    value: {
                                        avatarId,
                                        firstName,
                                        lastName,
                                        defaultAvatarColor,
                                    },
                                    label: `${lastName} ${firstName}`,
                                };
                            }),
                        );
                        setShowReportsTable(false);
                    }}
                    selectedUsersDefault={users}
                    className="mt-5"
                />
                {users.length > 0 && (
                    <>
                        <div className="mt-7 flex-wrap relative z-[70]">
                            <div className="grid items-center gap-6.5 grid-cols-2">
                                <FormGroup className="!mb-0">
                                    <Label>Собрать статистику</Label>
                                    <ComboBox
                                        onChange={(value) => {
                                            setAttempts(value as { value: StatisticsAttempts; label: string });
                                            setShowReportsTable(false);
                                        }}
                                        value={attempts}
                                        options={attemptsOptions}
                                        isSearchable={false}
                                        isCreatable={false}
                                        isClearable={false}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <div className="mt-9">
                            {showReportsTable ? (
                                <>
                                    <ReportTable
                                        queryPayload={createPayloadParams()}
                                        onClickLoadReportFile={() => setShowReportSettings(true)}
                                    />
                                    <ReportSettings
                                        isActive={showReportSettings}
                                        setIsActive={setShowReportSettings}
                                        settings={reportSettings}
                                        onSubmit={onSubmit}
                                        isReportFileFetching={false}
                                    />
                                </>
                            ) : (
                                <Button
                                    key={1}
                                    onClick={() => createReport()}
                                    variant="standard"
                                    size={"medium"}
                                    color={"primary"}
                                    className="w-full"
                                >
                                    {`Сформировать отчет по ${userTasksCount} ${numWord(userTasksCount, [
                                        "заданию",
                                        "заданиям",
                                        "заданиям",
                                    ])}`}
                                </Button>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
