import React, { useEffect, useState, SyntheticEvent, useRef } from "react";
import SelectComponents, { Props, MultiValue, ActionMeta } from "react-select";
import { useQuery } from "react-query";
import { isEqual } from "lodash";
import { ComboBox, SelectImgMultiValueLabel, OptionsDropdownIndicator } from "Uikit";
import Api from "Api/index";
import { ProgramListResponse } from "Api/Responses/ProgramResponse";
import { CourseListResponse } from "Api/Responses/CourseResponse";
import { TestListResponse } from "Api/Responses/TestResponse";
import { MaterialListResponse } from "Api/Responses/MaterialResponse";
import { TaskListResponse } from "Api/Responses/TaskResponse";
import { StackedValueContainer } from "Uikit/Forms/SelectCustomComponents/StackedValueContainer";
import { TrainingMultiSelectModalTree } from "Components/Common/TrainingMultiSelectModalTree";
import { ResourceState, ResourceType } from "Enums";
import {
    IStatisticValue,
    structurizeProgramTreeData,
    structurizeCourseTreeData,
    structurizeTestTreeData,
    structurizeMaterialTreeData,
    structurizeTaskTreeData,
} from "Pages/Admin/Statistics/Group/utils";

export type TTrainingMultiSelectFilterValue = ResourceState | "ALL";

type ReactSelectArbitraryProps = Pick<Props, "optionsPlaceholder">;

export interface ITrainingMultiSelectValue extends IStatisticValue {
    logoId?: string;
    state?: {
        checked: boolean;
        depth?: number;
        open?: boolean;
        path?: string;
        prefixMask?: string;
        selected?: boolean;
        total?: number;
    };
}

interface TrainingMultiSelectProps extends ReactSelectArbitraryProps {
    onChange?: (
        newValue: MultiValue<ITrainingMultiSelectValue>,
        actionMeta: ActionMeta<ITrainingMultiSelectValue>,
    ) => void;
    value?: ITrainingMultiSelectValue[] | ITrainingMultiSelectValue;
    placeholder?: string;
    isMulti?: boolean;
    isSearchable?: boolean;
    isCreatable?: boolean;
    isClearable?: boolean;
    menuIsOpen?: boolean;
    onSubmiTreeSection?: (selectedItems: any[]) => void;
    components?: SelectComponents;
    resourceType?: ResourceType;
    checkable?: boolean;
    checkOnNameClick?: boolean;
    selectable?: boolean;
    onSelectNode?: (node: any) => void;
    showSelectedCounter?: boolean;
    className?: string;
    fetchDataFilters?: Record<string, string[][] | string[] | string>;
    fetchDataDependencies?: IStatisticValue[] | string;
}

type TTrainingListResponce = CourseListResponse &
    ProgramListResponse &
    TestListResponse &
    MaterialListResponse &
    TaskListResponse;

// Компонент мультиселекта для выбора программ/курсов
export const TrainingMultiSelect = ({
    onChange,
    value = [],
    placeholder,
    isMulti = true,
    isSearchable = false,
    isCreatable = false,
    isClearable = false,
    menuIsOpen = false,
    onSubmiTreeSection,
    components,
    resourceType = ResourceType.PROGRAM,
    checkable = true,
    checkOnNameClick = false,
    selectable = false,
    onSelectNode,
    showSelectedCounter = true,
    className = "",
    fetchDataFilters,
    fetchDataDependencies = [],
}: TrainingMultiSelectProps) => {
    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const [isFilterEmpty, setIsFilterEmpty] = useState(true);
    const [isTreeOpen, setIsTreeOpen] = useState(false);
    const [treeData, setTreeData] = useState<any[]>([]);
    const [selectedCount, setSelectedCount] = useState(0);
    const isProgram = resourceType === ResourceType.PROGRAM;
    const isCourse = resourceType === ResourceType.COURSE;
    const isTest = resourceType === ResourceType.QUIZ;
    const isMaterial = resourceType === ResourceType.MATERIAL;
    const isTask = resourceType === ResourceType.EXERCISE;
    const structurizeDataHandler = isProgram
        ? structurizeProgramTreeData
        : isCourse
        ? structurizeCourseTreeData
        : isTest
        ? structurizeTestTreeData
        : isMaterial
        ? structurizeMaterialTreeData
        : structurizeTaskTreeData;
    const valueRef = useRef<ITrainingMultiSelectValue[]>([]);

    // all programs request
    const programsData = useQuery(
        ["programsTree", fetchDataDependencies],
        async () => {
            return await Api.Program.List({
                page: 0,
                size: 999,
                sort: "modifyTime,desc",
                filters: {
                    "state.notEqual": ResourceState.ARCHIVED,
                    ...fetchDataFilters,
                },
            });
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            enabled: isProgram,
        },
    );
    // all courses request
    const coursesData = useQuery(
        ["coursesTree", fetchDataDependencies],
        async () => {
            return await Api.Course.List(0, 999, [{ id: "modifyTimestamp", desc: true }], {
                "state.notEqual": ResourceState.ARCHIVED,
                ...fetchDataFilters,
            });
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            enabled: isCourse,
        },
    );
    // all tests request
    const testsData = useQuery(
        ["testsTree", fetchDataDependencies],
        async () => {
            return await Api.Test.List(0, 999, [{ id: "modifyTime", desc: true }], {
                "state.notEqual": ResourceState.ARCHIVED,
                ...fetchDataFilters,
            });
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            enabled: isTest,
        },
    );
    // all materials request
    const materialsData = useQuery(
        ["materialsTree", fetchDataDependencies],
        async () => {
            return await Api.Material.List(0, 999, [{ id: "modifyTimestamp", desc: true }], {
                "state.notEqual": ResourceState.ARCHIVED,
                ...fetchDataFilters,
            });
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            enabled: isMaterial,
        },
    );
    // all materials request
    const tasksData = useQuery(
        ["materialsTree", fetchDataDependencies],
        async () => {
            return await Api.Task.List({
                page: 0,
                size: 999,
                sort: "lastModified,desc",
                filters: {
                    "state.notEqual": ResourceState.ARCHIVED,
                    ...fetchDataFilters,
                },
            });
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            enabled: isTask,
        },
    );

    const trainingData = isProgram
        ? programsData
        : isCourse
        ? coursesData
        : isTest
        ? testsData
        : isMaterial
        ? materialsData
        : tasksData;

    const { data: trainingTreeData } = trainingData;

    const { data: programsCategories } = useQuery(
        ["programCategories"],
        async () => {
            return await Api.Program.CategoryGet();
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            enabled: isProgram,
        },
    );

    // Setting tree data
    useEffect(() => {
        if (
            (programsCategories?.Content && trainingTreeData?.Content && !treeData.length) ||
            (!isProgram && trainingTreeData?.Content && !treeData.length) ||
            (treeData.length && !isEqual(valueRef.current, value))
        ) {
            const valueArray = value
                ? (Array.isArray(value) ? value : [value]).map(({ id, state }) => {
                      return {
                          id,
                          checked: Boolean(state?.checked),
                      };
                  })
                : [];
            valueRef.current = Array.isArray(value) ? value : [value];
            setTreeData(
                structurizeDataHandler(
                    (trainingTreeData?.Content as TTrainingListResponce[]) ?? [],
                    valueArray,
                    isProgram ? programsCategories : undefined,
                ),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [programsCategories?.Content, trainingTreeData?.Content, value]);

    useEffect(() => {
        setSelectedCount(selectedItems.length);
    }, [selectedItems]);

    return (
        <>
            <ComboBox
                className={`mt-4 ${className}`}
                placeholder={placeholder}
                onChange={(
                    newValue: MultiValue<ITrainingMultiSelectValue>,
                    actionMeta: ActionMeta<ITrainingMultiSelectValue>,
                ) => {
                    onChange?.(newValue, actionMeta);
                }}
                value={value}
                components={{
                    DropdownIndicator: OptionsDropdownIndicator({
                        onClick: (e) => {
                            if (!e.target.closest(".ui-combo-box__multi-value__remove")) {
                                setIsTreeOpen((prevIsOpen) => !prevIsOpen);
                            }
                        },
                    }),
                    ValueContainer: StackedValueContainer,
                    MultiValueLabel: SelectImgMultiValueLabel({}),
                    ...components,
                }}
                onClick={(e: SyntheticEvent) => {
                    if (!(e.target as HTMLElement).closest(".ui-combo-box__multi-value__remove")) {
                        setIsTreeOpen((prevIsOpen) => !prevIsOpen);
                    }
                }}
                allPlaceholder={
                    Array.isArray(value) &&
                    ((trainingTreeData?.Content as unknown as ProgramListResponse[]) ?? []).length > 0 &&
                    value.length === trainingTreeData?.Content.length
                }
                isSearchable={isSearchable}
                isCreatable={isCreatable}
                isClearable={isClearable}
                menuIsOpen={menuIsOpen}
                isMulti={isMulti as true}
                isModalOpen={isTreeOpen}
            />
            {/* Модальное окно */}
            <TrainingMultiSelectModalTree
                isOpen={isTreeOpen}
                title={`Выбор ${
                    isProgram
                        ? `${isMulti ? "программ" : "программы"}`
                        : isCourse
                        ? `${isMulti ? "курсов" : "курса"}`
                        : isTest
                        ? `${isMulti ? "тестов" : "теста"}`
                        : isMaterial
                        ? `${isMulti ? "материалов" : "материала"}`
                        : `${isMulti ? "заданий" : "задания"}`
                }`}
                setIsOpen={(open: boolean) => {
                    // DEV
                    // const valueArray = value
                    //     ? (Array.isArray(value) ? value : [value]).map(({ id, state }) => {
                    //         return {
                    //             id,
                    //             checked: Boolean(state?.checked),
                    //         };
                    //     })
                    //     : [];

                    // setSelectedItems(Array.isArray(value) ? value : [value]);
                    // setTreeData(
                    //     structurizeProgramTreeData(
                    //         trainingTreeData?.Content as unknown as ProgramListResponse[],
                    //         valueArray,
                    //         programsCategories,
                    //     ),
                    // );

                    setIsTreeOpen(open);
                    setIsFilterEmpty(true);
                }}
                treeData={treeData}
                checkedChange={(selectedNodes) => {
                    setSelectedItems(selectedNodes);
                }}
                onSubmit={() => {
                    onSubmiTreeSection?.(selectedItems);
                    setIsTreeOpen(false);
                }}
                submitButtonTitle="Выбрать"
                selectedCount={selectedCount}
                onSearchFilterChange={(value: TTrainingMultiSelectFilterValue, query?: string) => {
                    // DEV
                    // setTreeData(
                    //     structurizeProgramTreeData(
                    //         (trainingTreeData?.Content as unknown as ProgramListResponse[]).filter(
                    //             ({ state, title }) => {
                    //                 return (
                    //                     (!query ||
                    //                         title.toLowerCase().includes(query.trim().toLowerCase()) ||
                    //                         query.trim() === "") &&
                    //                     (state === value || value === "ALL")
                    //                 );
                    //             },
                    //         ),
                    //         selectedItems.map(({ id, state }) => {
                    //             return {
                    //                 id,
                    //                 checked: state.checked,
                    //             };
                    //         }),
                    //         programsCategories,
                    //     ),
                    // );
                    setIsFilterEmpty(value === "ALL" && (!query || query.trim() === ""));
                }}
                emptyMessage={isFilterEmpty ? "Нет элементов для выбора" : "По заданным параметрам результатов нет"}
                checkable={checkable}
                checkOnNameClick={checkOnNameClick}
                selectable={selectable}
                onSelectNode={(node: any) => {
                    setSelectedItems([node]);
                    onSelectNode?.(node);
                }}
                showSelectedCounter={showSelectedCounter}
            />
        </>
    );
};
