import React from "react";
import { Button, Modal } from "Uikit";
import { InviteRegistrationMembersTable } from "./InviteRegistrationMembersTable";
import { Except } from "type-fest";
import { TVoidFunction } from "types";

interface InviteRegistrationMembersModalProps {
    isOpen: boolean;
    onClose: TVoidFunction;
    hideTeamMembers?: boolean;
    membersCount?: number;
    inviteId: string;
}

export const InviteRegistrationMembersModal = ({
    isOpen,
    onClose,
    membersCount = 0,
    ...rest
}: InviteRegistrationMembersModalProps) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} title="Список участников" className="!w-142 !max-w-142">
            <ModalContent onClose={onClose} membersCount={membersCount} {...rest} />
        </Modal>
    );
};

const ModalContent = ({
    onClose,
    membersCount,
    inviteId,
    hideTeamMembers = false,
}: Except<InviteRegistrationMembersModalProps, "isOpen">) => {
    return (
        <>
            <Modal.Body>
                <InviteRegistrationMembersTable hideTeamMembers={hideTeamMembers} inviteId={inviteId} />
            </Modal.Body>

            <Modal.Footer className="flex items-center justify-between">
                {!!membersCount && <p className="text-gray">Участников: {membersCount}</p>}
                <Button
                    key={1}
                    onClick={onClose}
                    variant="outline"
                    size={"medium"}
                    color={"secondary"}
                    className="border-[#EAEDF3]"
                >
                    Закрыть
                </Button>
            </Modal.Footer>
        </>
    );
};
