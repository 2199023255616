import { Expose, Type } from "class-transformer";
import { Job } from "types/Job";
import { ID } from "types/ID";

export class JobResponse implements Job {
    @Expose()
    @Type(() => String)
    public id?: ID;

    @Expose()
    @Type(() => String)
    public name: string;

    @Expose()
    @Type(() => Number)
    public userCount: number;

    @Expose()
    @Type(() => String)
    public companyId?: string;
}
