import { Expose, Type } from "class-transformer";
import { BaseResponse } from "../BaseResponse";
import { ResourceState, ProgressStatus, ResourceType } from "Enums";

export class FeedbackUser {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public login: string;

    @Expose()
    @Type(() => String)
    public avatarId: string;

    @Expose()
    @Type(() => String)
    public firstName: string;

    @Expose()
    @Type(() => String)
    public lastName: string;

    @Expose()
    @Type(() => String)
    public middleName: string;

    @Expose()
    @Type(() => String)
    public state: ResourceState;

    @Expose()
    @Type(() => String)
    public defaultAvatarColor: string;
}

export class FeedbackUserResponse extends BaseResponse {
    @Expose()
    @Type(() => FeedbackUser)
    public user: FeedbackUser;

    @Expose()
    @Type(() => Number)
    public rating: number;

    @Expose()
    @Type(() => String)
    public comment: string;

    @Expose()
    @Type(() => String)
    public status: ProgressStatus;

    @Expose()
    @Type(() => Number)
    public publicationDate: number;
}

export class FeedbackResponse extends FeedbackUserResponse {
    @Expose()
    @Type(() => String)
    public resourceType: ResourceType;

    @Expose()
    @Type(() => String)
    public resourceTitle: string;
}

export class FeedbackListResponse extends FeedbackResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public resourceLogoId: string;

    @Expose()
    @Type(() => String)
    public resourceId: string;
}
