import Axios, { Method, ResponseType, AxiosProgressEvent, CancelToken, AxiosRequestConfig, isCancel } from "axios";
import { plainToClassFromExist } from "class-transformer";
import { BaseRequest } from "./BaseRequest";
import { BadRequestResponse, BaseResponse } from "./BaseResponse";
import { getCookie, removeCookie } from "typescript-cookie";

// Add a response interceptor
Axios.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    async function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        console.log(error);

        if (error instanceof BaseResponse) {
            throw error;
        }

        if (isCancel(error)) {
            throw Object.assign(new BaseResponse(), {});
        }

        if (getCookie("USER_SESSION")) {
            document.location.reload();
        }

        return Promise.reject(error);
    },
);

export default class BaseApi {
    private readonly Prefix: string;

    constructor(prefix: string) {
        this.Prefix = prefix;
    }

    private static GetHeaders() {
        return {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Timezone": new Date().getTimezoneOffset(),
        };
    }

    private Request<T extends BaseResponse>(
        responseClass: T | (new () => T),
        method: Method,
        url: string,
        query: BaseRequest = new BaseRequest(),
        data: BaseRequest | BaseRequest[] | string | FormData = new BaseRequest(),
        responseType: ResponseType | undefined = "json",
        headers: object = {},
        onUploadProgress: ((progressEvent: AxiosProgressEvent) => void) | undefined = undefined,
        cancelToken: CancelToken | undefined = undefined,
    ): Promise<T> {
        const config: AxiosRequestConfig = {
            url,
            method,
            baseURL: this.Prefix,
            headers: { ...BaseApi.GetHeaders(), ...headers },
            params: query,
            data,
            responseType,
            onUploadProgress,
            validateStatus: (status: number) => status <= 500,
            cancelToken,
            withCredentials: true,
        };

        return Axios(config)
            .then((response) => {
                if (response.status >= 400) {
                    throw Object.assign(new BadRequestResponse(), {
                        ...(response.data ?? {}),
                        status: response.status,
                    });
                }

                if (typeof responseClass === "string" && responseClass === "response") {
                    return response;
                }

                if (typeof responseClass === "string") {
                    return response.data;
                }

                let result = typeof responseClass === "function" ? new responseClass() : responseClass;
                const resultData = Object.assign(response.data, {});

                result = plainToClassFromExist(result, resultData, { excludeExtraneousValues: true });
                let property: keyof typeof result;

                for (property in result) {
                    if (result[property] === undefined) {
                        console.log("result", result);
                        console.log(`Error property undefined: ${property.toString()}`);
                        throw Object.assign(new BaseResponse(), {});
                    }
                }

                return result;
            })
            .catch((e) => {
                if (e instanceof BadRequestResponse && (e.status === 401 || e.status === 423)) {
                    removeCookie("USER_SESSION", { path: "/", domain: window.location.hostname });
                    localStorage.removeItem("companyId");

                    if (url !== "/service/lms-authorization/api/authorization/session") {
                        document.location.replace("/");
                    }
                }

                if (e instanceof BaseResponse) {
                    throw e;
                }

                if (isCancel(e)) {
                    throw Object.assign(new BaseResponse(), {});
                }

                throw Object.assign(new BaseResponse(), { Status: e.response?.status, Error: e.response?.data?.error });
            });
    }

    protected Get<T extends BaseResponse>(
        responseClass: T | (new () => T),
        url: string,
        query: BaseRequest = new BaseRequest(),
        responseType: ResponseType = "json",
        headers: object = {},
        cancelToken: CancelToken | undefined = undefined,
    ): Promise<T> {
        return this.Request<T>(
            responseClass,
            "GET",
            url,
            query,
            new BaseRequest(),
            responseType,
            headers,
            undefined,
            cancelToken,
        );
    }

    protected Post<T extends BaseResponse>(
        responseClass: T | (new () => T),
        url: string,
        data: BaseRequest | BaseRequest[] = new BaseRequest(),
        responseType: ResponseType = "json",
        headers: object = {},
        cancelToken: CancelToken | undefined = undefined,
    ): Promise<T> {
        return this.Request<T>(
            responseClass,
            "POST",
            url,
            new BaseRequest(),
            data,
            responseType,
            headers,
            undefined,
            cancelToken,
        );
    }

    protected Put<T extends BaseResponse>(
        responseClass: T | (new () => T),
        url: string,
        data: BaseRequest | BaseRequest[] = new BaseRequest(),
    ): Promise<T> {
        return this.Request<T>(responseClass, "PUT", url, new BaseRequest(), data);
    }

    // protected Patch<T extends BaseResponse>(
    //     responseClass: T | (new () => T),
    //     url: string,
    //     data: BaseRequest | BaseRequest[] = new BaseRequest(),
    // ): Promise<T> {
    //     return this.Request<T>(responseClass, "PATCH", url, new BaseRequest(), data);
    // }

    protected Upload<T extends BaseResponse>(
        responseClass: T | (new () => T),
        url: string,
        data: FormData,
        onUploadProgress: ((progressEvent: AxiosProgressEvent) => void) | undefined = undefined,
        cancelToken: CancelToken | undefined = undefined,
    ): Promise<T> {
        return this.Request<T>(
            responseClass,
            "POST",
            url,
            new BaseRequest(),
            data,
            undefined,
            {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress,
            cancelToken,
        );
    }

    protected Delete<T extends BaseResponse>(
        type: T | (new () => T),
        url: string,
        data: BaseRequest | BaseRequest[] | undefined = new BaseRequest(),
    ): Promise<T> {
        return this.Request<T>(type, "DELETE", url, new BaseRequest(), data);
    }
}
