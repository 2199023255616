import React, { useEffect, useState } from "react";
import SelectComponents from "react-select";

import { Button, ComboBox, FormGroup, Label, SelectAvatarMultiValueLabel } from "Uikit";
import Api from "Api";
import { ProgressStatus, StatisticsAttempts, StatisticsReportAccess, StatisticsUsersStatus, ResourceType } from "Enums";
import { MembersListResponse } from "Api/Responses/UserResponse";
import { StackedValueContainer } from "Uikit/Forms/SelectCustomComponents/StackedValueContainer";
import { numWord } from "helpers/numWord";
import { StatisticTestAnswersRequest } from "Api/Requests/StatisticRequest";
import { IRequestSettingsItem } from "Pages/Admin/Statistics/ReportSettings/ReportSettings";
import { TrainingMultiSelect } from "Components/Common/TrainingMultiSelect";

import { ReportUserSelect } from "../../../ReportUserSelect/ReportUserSelect";
import { IStatisticValue } from "../../utils";
import { SingleValueContainer } from "../../SingleValueContainer";
import { attemptsOptions } from "../TestWithoutCourse";
import { usersStatusOptions } from "../TestWithoutCourse";
import { accessOptions } from "../TestWithoutCourse";
import { ReportTable } from "./ReportTable";
import { TestAnswersReportSettings } from "./TestAnswersReportSettings";
import { progressOptions } from "./TestAnswersWithoutCourse";

const reportSettings = [
    {
        title: "Участник",
        key: "user",
        items: [
            {
                title: "Все",
                value: "all",
                checked: true,
            },
            {
                title: "Фамилия, Имя участника",
                value: "name",
                checked: true,
            },
            {
                title: "Логин",
                value: "login",
                checked: true,
            },
            {
                title: "Электронная почта",
                value: "email",
                checked: true,
            },
            {
                title: "Должность",
                value: "job",
                checked: true,
            },
            {
                title: "Офис",
                value: "office",
                checked: true,
            },
            {
                title: "Статус участника",
                value: "status",
                checked: true,
            },
            {
                title: "Команда",
                value: "command",
                checked: true,
            },
            {
                title: "Путь к команде",
                value: "commandPath",
                checked: true,
            },
            {
                title: "Наставник",
                value: "mentor",
                checked: true,
            },
            {
                title: "Доступ к курсу на текущий момент",
                value: "courseAccess",
                checked: true,
            },
        ],
    },
    {
        title: "Тест",
        key: "quiz",
        items: [
            {
                title: "Все",
                value: "all",
                checked: true,
            },
            {
                title: "Название теста",
                value: "name",
                checked: true,
            },
            {
                title: "Дата начала",
                value: "startDate",
                checked: true,
            },
            {
                title: "Дата завершения",
                value: "endDate",
                checked: true,
            },
            {
                title: "Дедлайн",
                value: "deadlineDate",
                checked: true,
            },
            {
                title: "Затрачено времени",
                value: "spendTime",
                checked: true,
            },
            {
                title: "Номер попытки",
                value: "attemptNumber",
                checked: true,
            },
            {
                title: "Начисленные баллы",
                value: "rating",
                checked: true,
            },
            {
                title: "Максимальное количество баллов",
                value: "maxRating",
                checked: true,
            },
            {
                title: "Набранный % правильных ответов",
                value: "percentageRating",
                checked: true,
            },
            {
                title: "Необходимый % правильных ответов",
                value: "requiredPercentage",
                checked: true,
            },
            {
                title: "Статус проходждения теста",
                value: "status",
                checked: true,
            },
            {
                title: "Правильных ответов",
                value: "correctAnswers",
                checked: true,
            },
            {
                title: "Всего вопросов",
                value: "totalQuestions",
                checked: true,
            },
        ],
    },
];

export const TestAnswersWithinCourse = () => {
    const [coursesValues, setCoursesValues] = useState<IStatisticValue[]>([]);
    const [showReportQuestionsTable, setShowReportTable] = useState(false);
    const [testsValues, setTestsValues] = useState<IStatisticValue[]>([]);
    const [allUsers, setAllUsers] = useState<MembersListResponse[]>([]);
    const [users, setUsers] = useState<MembersListResponse[]>([]);
    const [allUsersCount, setAllUsersCount] = useState(0);
    const [testQuestionsCount, setTestQuestionsCount] = useState(0);
    const [access, setAccess] = useState(accessOptions[0]);
    const [progress, setProgress] = useState<{ value: ProgressStatus; label: string }>(progressOptions[0]);
    const [attempts, setAttempts] = useState(attemptsOptions[1]);
    const [usersStatus, setUsersStatus] = useState(usersStatusOptions[0]);
    const [showReportSettings, setShowReportSettings] = useState(false);
    const [isUsersLoaded, setIsUsersLoaded] = useState(false);

    const onSubmitCourseSection = (selectedItems: any[]) => {
        setCoursesValues(
            selectedItems.map(({ id, name, logoId }) => {
                return {
                    label: name,
                    value: id,
                    name,
                    id,
                    logoId,
                };
            }),
        );

        setShowReportTable(false);
    };

    const onSubmitTestSection = (selectedItems: any[]) => {
        setTestsValues(
            selectedItems.map(({ id, name, logoId, questionsCount }) => {
                return {
                    label: name,
                    value: id,
                    name,
                    id,
                    logoId,
                    questionsCount,
                };
            }),
        );

        setShowReportTable(false);
    };

    const createReport = () => {
        setShowReportTable(true);
    };

    const createPayloadParams = () => {
        const payload: StatisticTestAnswersRequest = {
            courseIds: coursesValues.map(({ id }) => id),
            quizIds: testsValues.map(({ id }) => id),
            userIds: users.map(({ id }) => id),
            access: access.value,
            status: progress.value,
            attemptNumber: attempts.value,
            userStatus: usersStatus.value,
        };

        return payload;
    };

    const onSubmit = async (settingsChecked?: IRequestSettingsItem[]) => {
        setShowReportSettings(false);

        const request: any = {};

        request.params = createPayloadParams();

        if (settingsChecked) {
            settingsChecked.forEach(({ key, items }: IRequestSettingsItem) => {
                request[key] = items
                    .filter((item) => item.value !== "all")
                    .reduce((prev, cur) => {
                        return {
                            ...prev,
                            [cur.value]: cur.checked,
                        };
                    }, {});
            });

            await Api.Statistic.GenerateTestAnswersStatisticReportFile(request);
        } else {
            await Api.Statistic.GenerateTestAnswersStatisticSummaryReportFile(request);
        }
    };

    useEffect(() => {
        setTestsValues([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coursesValues]);

    useEffect(() => {
        if (testsValues.length !== 0) {
            setTestQuestionsCount(testsValues[0] ? Number(testsValues[0].questionsCount || 0) : 0);
        }

        if (testsValues.length === 0) {
            setUsers(allUsers);
            setAccess(accessOptions[0]);
            setProgress(progressOptions[0]);
            setAttempts(attemptsOptions[1]);
            setUsersStatus(usersStatusOptions[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [testsValues]);

    return (
        <>
            <div className="grid items-center gap-6.5 grid-cols-2">
                {/* Выбор курса */}
                <TrainingMultiSelect
                    className="!mt-0"
                    value={coursesValues[0] ?? null}
                    onSubmiTreeSection={onSubmitCourseSection}
                    placeholder="Выберите курс"
                    resourceType={ResourceType.COURSE}
                    components={
                        {
                            ValueContainer: SingleValueContainer,
                        } as unknown as SelectComponents
                    }
                    checkable={false}
                    selectable={true}
                    checkOnNameClick={true}
                    showSelectedCounter={false}
                    isMulti={false}
                />
                {/* Выбор теста */}
                {coursesValues.length > 0 && (
                    <TrainingMultiSelect
                        className="!mt-0"
                        value={testsValues[0] ?? null}
                        onSubmiTreeSection={onSubmitTestSection}
                        placeholder="Выберите тест"
                        resourceType={ResourceType.QUIZ}
                        components={
                            {
                                ValueContainer: SingleValueContainer,
                            } as unknown as SelectComponents
                        }
                        checkable={false}
                        selectable={true}
                        checkOnNameClick={true}
                        showSelectedCounter={false}
                        isMulti={false}
                        fetchDataFilters={{
                            "courseId.in": [coursesValues.map(({ id }) => id)],
                            "progressStatus.in": [ProgressStatus.PASSED, ProgressStatus.FAILED],
                        }}
                        fetchDataDependencies={coursesValues}
                    />
                )}
            </div>
            {testsValues.length > 0 && (
                <>
                    <div className="mt-7 flex-wrap relative z-[70]">
                        <div className="grid items-center gap-6.5 grid-cols-2">
                            {/* Выбор участников */}
                            <FormGroup className="!mb-0">
                                <Label>Участники</Label>
                                <ReportUserSelect
                                    value={users}
                                    placeholder="Выберите одного или нескольких участников"
                                    onSubmit={(users) => {
                                        setUsers(
                                            users.map((user) => {
                                                const { firstName, lastName, avatarId, defaultAvatarColor } = user;
                                                return {
                                                    ...user,
                                                    value: {
                                                        avatarId,
                                                        firstName,
                                                        lastName,
                                                        defaultAvatarColor,
                                                    },
                                                    label: `${lastName} ${firstName}`,
                                                };
                                            }),
                                        );
                                        setShowReportTable(false);
                                    }}
                                    onChange={(options, action) => {
                                        if (action && action.action === "clear") {
                                            setUsers([]);
                                        }
                                        if (action && action.action === "remove-value") {
                                            setUsers(options as unknown as MembersListResponse[]);
                                        }
                                        setShowReportTable(false);
                                    }}
                                    components={
                                        {
                                            ValueContainer: StackedValueContainer,
                                            MultiValueLabel: SelectAvatarMultiValueLabel({}),
                                        } as unknown as SelectComponents
                                    }
                                    onDataLoaded={(users) => {
                                        if (!allUsers.length && !isUsersLoaded) {
                                            setUsers(users);
                                            setAllUsers(users);
                                            setAllUsersCount(users.length);
                                            setIsUsersLoaded(true);
                                        }
                                    }}
                                    isDataLoaded={isUsersLoaded}
                                    allPlaceholder={users.length > 0 && allUsersCount === users.length}
                                    selectedUsersDefault={users}
                                    showStatusFilter={true}
                                    isSingleSelect={false}
                                    isMulti={true}
                                />
                            </FormGroup>
                            <FormGroup className="!mb-0">
                                <Label>Доступ к курсу на текущий момент</Label>
                                <ComboBox
                                    onChange={(value) => {
                                        setAccess(value as { value: StatisticsReportAccess; label: string });
                                        setShowReportTable(false);
                                    }}
                                    value={access}
                                    options={accessOptions}
                                    isSearchable={false}
                                    isCreatable={false}
                                    isClearable={false}
                                />
                            </FormGroup>
                            <FormGroup className="!mb-0">
                                <Label>Статус прохождения теста</Label>
                                <ComboBox
                                    onChange={(value) => {
                                        setProgress(value as { value: ProgressStatus; label: string });
                                        setShowReportTable(false);
                                    }}
                                    value={progress}
                                    options={progressOptions}
                                    isSearchable={false}
                                    isCreatable={false}
                                    isClearable={false}
                                />
                            </FormGroup>
                            <FormGroup className="!mb-0">
                                <Label>Собрать статистику</Label>
                                <ComboBox
                                    onChange={(value) => {
                                        setAttempts(value as { value: StatisticsAttempts; label: string });
                                        setShowReportTable(false);
                                    }}
                                    value={attempts}
                                    options={attemptsOptions}
                                    isSearchable={false}
                                    isCreatable={false}
                                    isClearable={false}
                                />
                            </FormGroup>
                            <FormGroup className="!mb-0">
                                <Label>Статус участников</Label>
                                <ComboBox
                                    onChange={(value) => {
                                        setUsersStatus(value as { value: StatisticsUsersStatus; label: string });
                                        setShowReportTable(false);
                                    }}
                                    value={usersStatus}
                                    options={usersStatusOptions}
                                    isSearchable={false}
                                    isCreatable={false}
                                    isClearable={false}
                                />
                            </FormGroup>
                        </div>
                    </div>
                    <div className="mt-9">
                        {showReportQuestionsTable ? (
                            <>
                                <ReportTable
                                    queryPayload={createPayloadParams()}
                                    onClickLoadReportFile={() => setShowReportSettings(true)}
                                />
                                <TestAnswersReportSettings
                                    isActive={showReportSettings}
                                    setIsActive={setShowReportSettings}
                                    settings={reportSettings}
                                    onSubmit={onSubmit}
                                    isReportFileFetching={false}
                                />
                            </>
                        ) : (
                            <Button
                                key={1}
                                onClick={() => createReport()}
                                variant="standard"
                                size={"medium"}
                                color={"primary"}
                                className="w-full"
                                disabled={!users.length}
                            >
                                {`Сформировать отчет по ${testQuestionsCount} ${numWord(testQuestionsCount, [
                                    "вопросу",
                                    "вопросам",
                                    "вопросам",
                                ])}`}
                            </Button>
                        )}
                    </div>
                </>
            )}
        </>
    );
};
