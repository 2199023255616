import React, { useState } from "react";
import SelectComponents from "react-select";
import { MembersListResponse } from "Api/Responses/UserResponse";
import { Breadcrumbs, Button, SelectAvatarMultiValueLabel } from "Uikit";
import { StackedValueContainer } from "Uikit/Forms/SelectCustomComponents/StackedValueContainer";
import Api from "Api";
import { IRequestSettingsItem, ReportSettings } from "Pages/Admin/Statistics/ReportSettings/ReportSettings";
import { numWord } from "helpers/numWord";
import { StatisticUserComplexityRequest } from "Api/Requests/StatisticRequest";
import { ReportTable } from "./ReportTable";
import { ReportUserSelect } from "../../../ReportUserSelect/ReportUserSelect";

const reportSettings = [
    {
        title: "Участник",
        key: "user",
        items: [
            {
                title: "Все",
                value: "all",
                checked: true,
            },
            {
                title: "ФИО",
                value: "name",
                checked: true,
            },
            {
                title: "Логин",
                value: "login",
                checked: true,
            },
            {
                title: "Команды участника",
                value: "team",
                checked: true,
            },
        ],
    },
    {
        title: "Общая статистика",
        key: "summary",
        items: [
            {
                title: "Все",
                value: "all",
                checked: true,
            },
            {
                title: "Всего назначено",
                value: "assigned",
                checked: true,
            },
            {
                title: "Всего изучено",
                value: "passed",
                checked: true,
            },
            {
                title: "В процессе изучения",
                value: "inProgress",
                checked: true,
            },
            {
                title: "Не начато",
                value: "notStarted",
                checked: true,
            },
            {
                title: "% изученного",
                value: "passedPercentage",
                checked: true,
            },
            {
                title: "% просроченного",
                value: "expiredDeadlinePercentage",
                checked: true,
            },
        ],
    },
    {
        title: "Программы обучения",
        key: "program",
        items: [
            {
                title: "Все",
                value: "all",
                checked: true,
            },
            {
                title: "Назначено",
                value: "assigned",
                checked: true,
            },
            {
                title: "Пройдено",
                value: "passed",
                checked: true,
            },
            {
                title: "В процессе",
                value: "inProgress",
                checked: true,
            },
            {
                title: "Не начато",
                value: "notStarted",
                checked: true,
            },
            {
                title: "% пройденных",
                value: "passedPercentage",
                checked: true,
            },
            {
                title: "% просроченных",
                value: "expiredDeadlinePercentage",
                checked: true,
            },
            {
                title: "Обязательных назначено",
                value: "requiredAssigned",
                checked: true,
            },
            {
                title: "Обязательных пройдено",
                value: "requiredPassed",
                checked: true,
            },
        ],
    },
    {
        title: "Курсы",
        key: "course",
        items: [
            {
                title: "Все",
                value: "all",
                checked: true,
            },
            {
                title: "Назначено",
                value: "assigned",
                checked: true,
            },
            {
                title: "Пройдено",
                value: "passed",
                checked: true,
            },
            {
                title: "В процессе",
                value: "inProgress",
                checked: true,
            },
            {
                title: "Не начато",
                value: "notStarted",
                checked: true,
            },
            {
                title: "% пройденных",
                value: "passedPercentage",
                checked: true,
            },
            {
                title: "% просроченных",
                value: "expiredDeadlinePercentage",
                checked: true,
            },
            {
                title: "Обязательных назначено",
                value: "requiredAssigned",
                checked: true,
            },
            {
                title: "Обязательных пройдено",
                value: "requiredPassed",
                checked: true,
            },
        ],
    },
    {
        title: "Тесты",
        key: "quiz",
        items: [
            {
                title: "Все",
                value: "all",
                checked: true,
            },
            {
                title: "Назначено",
                value: "assigned",
                checked: true,
            },
            {
                title: "Пройдено",
                value: "passed",
                checked: true,
            },
            {
                title: "Провалено",
                value: "failed",
                checked: true,
            },
            {
                title: "На проверке",
                value: "onReview",
                checked: true,
            },
            {
                title: "Не начато",
                value: "notStarted",
                checked: true,
            },
            {
                title: "% пройденных",
                value: "passedPercentage",
                checked: true,
            },
            {
                title: "% проваленных",
                value: "failedPercentage",
                checked: true,
            },
            {
                title: "% просроченны",
                value: "expiredDeadlinePercentage",
                checked: true,
            },
        ],
    },
    {
        title: "Задания",
        key: "exercise",
        items: [
            {
                title: "Все",
                value: "all",
                checked: true,
            },
            {
                title: "Назначено",
                value: "assigned",
                checked: true,
            },
            {
                title: "Принято",
                value: "passed",
                checked: true,
            },
            {
                title: "Отклонено",
                value: "failed",
                checked: true,
            },
            {
                title: "На проверке",
                value: "onReview",
                checked: true,
            },
            {
                title: "Не начато",
                value: "notStarted",
                checked: true,
            },
            {
                title: "% принятых",
                value: "passedPercentage",
                checked: true,
            },
            {
                title: "% просроченных",
                value: "expiredDeadlinePercentage",
                checked: true,
            },
        ],
    },
    {
        title: "Материалы",
        key: "material",
        items: [
            {
                title: "Все",
                value: "all",
                checked: true,
            },
            {
                title: "Назначено",
                value: "assigned",
                checked: true,
            },
            {
                title: "Изучено",
                value: "passed",
                checked: true,
            },
            {
                title: "В процессе",
                value: "inProgress",
                checked: true,
            },
            {
                title: "% изученных",
                value: "passedPercentage",
                checked: true,
            },
        ],
    },
];

export const UserComplexity = () => {
    const [users, setUsers] = useState<MembersListResponse[]>([]);
    const [allUsersCount, setAllUsersCount] = useState(0);
    const [showReportsTable, setShowReportsTable] = useState(false);
    const [showReportSettings, setShowReportSettings] = useState(false);

    const createReport = () => {
        setShowReportsTable(true);
    };

    const createPayloadParams = () => {
        const payload: StatisticUserComplexityRequest = {
            userIds: users.map(({ id }) => id),
        };

        return payload;
    };

    const onSubmit = async (settingsChecked: IRequestSettingsItem[]) => {
        const request: any = {};

        request.params = createPayloadParams();

        settingsChecked.forEach(({ key, items }: IRequestSettingsItem) => {
            request[key] = items
                .filter((item) => item.value !== "all")
                .reduce((prev, cur) => {
                    return {
                        ...prev,
                        [cur.value]: cur.checked,
                    };
                }, {});
        });

        setShowReportSettings(false);

        try {
            await Api.Statistic.GenerateUserComplexityStatisticReportFile(request);
        } catch (error: any) {
            console.log(error);
        }
    };

    return (
        <div className="px-6 w-full h-full sm:overflow-y-scroll custom-scrollbar">
            <Breadcrumbs className="mb-1">
                <Breadcrumbs.Link title="Администратор" />
                <Breadcrumbs.Link title="Статистика" />
                <Breadcrumbs.Link title="Участники" />
                <Breadcrumbs.Link title="Отчет по участнику" />
            </Breadcrumbs>
            <div>
                <h1>Отчет по участнику</h1>
                <ReportUserSelect
                    className="mt-5"
                    value={users}
                    onSubmit={(users) => {
                        setUsers(
                            users.map((user) => {
                                const { firstName, lastName, avatarId, defaultAvatarColor } = user;
                                return {
                                    ...user,
                                    value: {
                                        avatarId,
                                        firstName,
                                        lastName,
                                        defaultAvatarColor,
                                    },
                                    label: `${lastName} ${firstName}`,
                                };
                            }),
                        );
                        setShowReportsTable(false);
                    }}
                    onChange={(options, action) => {
                        if (action && action.action === "clear") {
                            setUsers([]);
                        }
                        if (action && action.action === "remove-value") {
                            setUsers(options as unknown as MembersListResponse[]);
                        }
                        setShowReportsTable(false);
                    }}
                    components={
                        {
                            ValueContainer: StackedValueContainer,
                            MultiValueLabel: SelectAvatarMultiValueLabel({}),
                        } as unknown as SelectComponents
                    }
                    onDataLoaded={(users) => {
                        setAllUsersCount(users.length);
                    }}
                    allPlaceholder={users.length > 0 && allUsersCount === users.length}
                    selectedUsersDefault={users}
                    showStatusFilter={true}
                    isSingleSelect={false}
                    isMulti={true}
                />
                {users.length > 0 && (
                    <div className="mt-9">
                        {showReportsTable ? (
                            <>
                                <ReportTable
                                    onClickLoadReportFile={() => setShowReportSettings(true)}
                                    queryPayload={createPayloadParams()}
                                />
                                <ReportSettings
                                    isActive={showReportSettings}
                                    setIsActive={setShowReportSettings}
                                    settings={reportSettings}
                                    onSubmit={onSubmit}
                                    isReportFileFetching={false}
                                />
                            </>
                        ) : (
                            <Button
                                key={1}
                                onClick={() => createReport()}
                                variant="standard"
                                size={"medium"}
                                color={"primary"}
                                className="w-full"
                            >
                                {`Сформировать отчет по ${users.length} ${numWord(users.length, [
                                    "участнику",
                                    "участникам",
                                    "участникам",
                                ])}`}
                            </Button>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
