import { BaseRequest } from "Api/BaseRequest";
import BaseApi from "../BaseApi";
import { BaseResponse, BaseObjectToArrayResponse } from "../BaseResponse";
import { FileReadResponse } from "Api/Responses/FileResponse";

export enum LogoSize {
    ORIGINAL = "ORIGINAL",
    THUMB_MINI = "THUMB_MINI",
    THUMB_MICRO = "THUMB_MICRO",
}

export default class UploadApi extends BaseApi {
    Download = async (id: string): Promise<""> => {
        return await this.Get("", `/file/download/${id}`, new BaseRequest(), "blob");
    };

    DownloadGeneratedFile = async (id: string): Promise<""> => {
        return await this.Get("", `/generated/download/${id}`, new BaseRequest(), "blob");
    };

    GetLogo = (id: string, size: LogoSize): string => {
        return `/service/lms-upload/api/logo/get/${id}/${size}`;
    };

    GetDefaultLogos = async (): Promise<BaseObjectToArrayResponse<string>> => {
        return await this.Get(new BaseObjectToArrayResponse(), `/default/logos`);
    };

    DiscardVideoFile = async (fileId: string): Promise<BaseResponse> => {
        return await this.Delete(BaseResponse, `/video/discard/${fileId}`);
    };

    // Загрузка аватара (открытый эндпоинт)
    // https://lms-dev.ext.grancall.ru/webjars/swagger-ui/index.html?urls.primaryName=lms-upload#/signup-resource/uploadAvatar
    InviteAvatar = async (
        data: File,
        progressHandler?: any,
        isDisposableLink = false,
        socketId?: string,
        inviteId?: string,
    ): Promise<FileReadResponse> => {
        const formData = new FormData();
        formData.append("file", data);

        const isDisposableLinkParam = isDisposableLink ? "link=true" : "";
        const socketIdParam = socketId ? `socketId=${socketId}` : "";
        const inviteIdParam = inviteId ? `inviteId=${inviteId}` : "";

        const params = [isDisposableLinkParam, socketIdParam, inviteIdParam].filter((param) => param).join("&");

        return await this.Upload(new FileReadResponse(), `/signup/avatar?${params}`, formData, progressHandler);
    };
}
