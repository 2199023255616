import React, { useMemo, useState, useEffect } from "react";
import { ColumnDef, SortingState } from "@tanstack/react-table";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { TeamUser } from "Api/Responses/TeamResponse";
import { Table } from "Uikit";
import { UserAvatar } from "Uikit/UserAvatar/UserAvatar";
import { useCurrentUser } from "hooks/useCurrentUser";

interface IModalUsersTableProps {
    data: TeamUser[];
}

const nameSortingFunction =
    (desc: boolean) =>
    (
        { firstName: firstName1, lastName: lastName1 }: TeamUser,
        { firstName: firstName2, lastName: lastName2 }: TeamUser,
    ) => {
        const name1 = `${firstName1} ${lastName1}`;
        const name2 = `${firstName2} ${lastName2}`;
        return desc ? name1.localeCompare(name2) : name2.localeCompare(name1);
    };

const jobTitleSortingFunction =
    (desc: boolean) =>
    ({ jobTitle: { name: jobTitle1 } }: TeamUser, { jobTitle: { name: jobTitle2 } }: TeamUser) => {
        return desc ? jobTitle2.localeCompare(jobTitle1) : jobTitle1.localeCompare(jobTitle2);
    };

export const ModalUsersTable = ({ data }: IModalUsersTableProps) => {
    const navigate = useNavigate();
    const [dataDisplayed, setDataDisplayed] = useState<TeamUser[]>(data);
    const currentUser = useCurrentUser();
    const [sorting, setSorting] = useState<SortingState>([]);

    const columns = useMemo<ColumnDef<TeamUser>[]>(
        () => [
            {
                header: "участник",
                accessorKey: "firstName,lastName",
                cell: ({ row: { original: user } }) => {
                    return (
                        <div className="flex items-center space-x-4 2xl:space-x-5 max-w-75 2xl:text-md">
                            <UserAvatar
                                avatarId={user.avatarId}
                                color={user.defaultAvatarColor}
                                userInitials={`${user.firstName?.slice(0, 1)}${user.lastName?.slice(0, 1)}`}
                                size={window.screen.width >= 1600 ? 56 : 45}
                            />
                            <div
                                className={clsx("line-clamp-1", "cursor-pointer")}
                                onClick={() => {
                                    if (currentUser?.data?.role === "USER") {
                                        navigate(`/personal/${user.id}`);
                                        return;
                                    }

                                    navigate(
                                        currentUser?.data?.id !== user.id ? `/admin/member/${user.id}` : `/personal`,
                                    );
                                }}
                            >
                                {user.firstName} {user.lastName}
                            </div>
                        </div>
                    );
                },
                footer: (props) => props.column.id,
            },
            {
                header: "Должность",
                cell: ({ row: { original: user } }) => {
                    const { jobTitle } = user;

                    return <div className="2xl:text-md">{jobTitle?.name ?? "—"}</div>;
                },
                accessorKey: "jobTitle",
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data],
    );

    useEffect(() => {
        if (sorting.length > 0) {
            if (sorting[0]?.id === "firstName,lastName") {
                const sortingFn = nameSortingFunction(sorting[0].desc);
                setDataDisplayed((prevData) => {
                    return [...prevData].sort(sortingFn);
                });
            }
            if (sorting[0]?.id === "jobTitle") {
                const sortingFn = jobTitleSortingFunction(sorting[0].desc);
                setDataDisplayed((prevData) => {
                    return [...prevData].sort(sortingFn);
                });
            }
        }
    }, [sorting]);

    const onSearch = (searchQuery: string) => {
        setDataDisplayed(() => {
            return data.filter(({ firstName, lastName, login, email, phone }) => {
                const params = [
                    {
                        label: "name",
                        value: `${firstName} ${lastName}`,
                    },
                    {
                        label: "login",
                        value: login,
                    },
                    {
                        label: "email",
                        value: email,
                    },
                    {
                        label: "phone",
                        value: phone,
                    },
                ];
                return params.some(({ label, value }) => {
                    if (label === "phone") {
                        value = value.replaceAll(/[\s-]/g, "");
                    }
                    return value.includes(searchQuery.toLowerCase());
                });
            });
        });
    };

    return (
        <Table
            id="modalTeamUsers"
            columns={columns}
            searchTitle="Поиск по наставникам"
            searchBlockClassName="w-full"
            searchClassName="!w-full"
            data={dataDisplayed}
            rowSelection={{}}
            emptyMessage={"По заданным параметрам результатов нет"}
            onSearch={onSearch}
            bodyCellClassName={"!py-2 2xl:!py-[9px]"}
            theadClassName="sticky z-40"
            tableWrapperClassName={"-ml-3 h-100 2xl:h-135 overflow-y-auto overflow-x-hidden"}
            columnClassName="sticky top-0 bg-white !opacity-100 first-of-type:pl-2 !pl-3.5 pb-2"
            withoutLines
            sorting={sorting}
            onSortingChange={setSorting}
        />
    );
};
