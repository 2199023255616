import React from "react";
import { ClearIndicatorProps, components } from "react-select";
import { TVoidFunction } from "types/TVoidFunction";
import { Icon, Icons } from "Uikit";

export interface ICustomClearIndicatorProps {
    readonly onChange?: TVoidFunction;
    readonly isMulti?: boolean;
}

// Кастомный компонент для react-select
// Кастомная кнопка очистки поля
export const CustomClearIndicator =
    ({ onChange, isMulti = true }: ICustomClearIndicatorProps) =>
    ({ children, ...props }: ClearIndicatorProps<any>) => {
        const itemsLength = props.getValue().length;

        const onClear = () => {
            props.clearValue();
            onChange?.();
        };

        if (!isMulti) {
            return null;
        }

        return (
            <components.ClearIndicator {...props} className={"gap-1 !pr-7 relative"}>
                {children}
                {itemsLength > 0 && (
                    <div className="absolute group right-0 flex-center rounded-full top-1/2 -translate-y-1/2 h-5.5 min-w-[22px] text-sm bg-primary text-white cursor-pointer">
                        <span className="group-hover:hidden">{itemsLength}</span>
                        <div onClick={onClear} className="hidden group-hover:block">
                            <Icon icon={Icons.Close} width={18} height={18} color="fill-white" />
                        </div>
                    </div>
                )}
            </components.ClearIndicator>
        );
    };
