import React, { useEffect, useState, useCallback } from "react";
import { generateAvatar } from "helpers/generateAvatar";
import clsx from "clsx";

interface UserAvatarProps {
    avatarId?: string | null;
    userInitials?: string;
    color?: string | null;
    size: number;
    className?: string;
}

export const UserAvatar = ({ avatarId, userInitials = "", color, size = 40, className }: UserAvatarProps) => {
    const [avatar, setAvatar] = useState("");
    const [isFontsLoaded, setIsFontsLoaded] = useState(false);

    const setAvatarHandler = useCallback(() => {
        if (avatarId) {
            setAvatar(avatarId.includes("base64") ? avatarId : "/service/lms-upload/api/file/download/" + avatarId);
        } else {
            const dataURI = generateAvatar(userInitials || "АБ", color, size);
            setAvatar(dataURI);
        }
    }, [avatarId, size, color, userInitials]);

    useEffect(() => {
        setIsFontsLoaded(document.fonts.check("16px Noto Sans"));
        // Noto Sans
    }, []);

    document.fonts.onloadingdone = function () {
        setIsFontsLoaded(document.fonts.check("16px Noto Sans"));
        setAvatarHandler();
    };

    useEffect(() => {
        setAvatarHandler();
    }, [avatarId, size, color, userInitials, setAvatarHandler]);

    return (
        <div
            className={clsx("flex flex-shrink-0", className)}
            style={{ width: size, height: size, opacity: avatar ? 1 : 0 }}
        >
            {isFontsLoaded && (
                <img
                    className="rounded-full object-cover group-hover:brightness-95"
                    style={{ width: size, height: size, opacity: avatar ? 1 : 0 }}
                    src={avatar}
                    alt="Аватарка юзера"
                />
            )}
        </div>
    );
};
