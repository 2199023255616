import React from "react";
import { useParams, Outlet } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { ContentContainer } from "Containers/ContentContainer";
import { Breadcrumbs } from "Uikit";
import { Preloader } from "Components/Preloader/Preloader";
import { useGetUIProgram } from "./Program.hooks";
import { IReducer } from "store";
import { NoAccess } from "Components/Stub/NoAccess";
import classNames from "classnames";
import { BadRequestResponse, ErrorCode } from "Api/BaseResponse";

export const Program = () => {
    const {
        programId = "",
        courseId = "",
        testId = "",
        resourceId = "",
    } = useParams<{
        programId: string;
        courseId: string;
        testId: string;
        resourceId: string;
    }>();

    const { data: program, isLoading: isProgramLoading, isFetched, error } = useGetUIProgram(programId);
    const { currentCourse, currentMaterial } = useSelector((state: IReducer) => state.program);

    const isNoAccessError =
        error && error instanceof BadRequestResponse && error.errorCode === ErrorCode.NO_RESOURCE_ACCESS;
    const isNoProgramError =
        error && error instanceof BadRequestResponse && error.errorCode === ErrorCode.PROGRAM_NOT_FOUND;

    const showNoAccess = isFetched && isNoAccessError;
    const showNoProgram = isFetched && isNoProgramError;

    return (
        <div className={classNames("relative min-h-full", (showNoAccess || showNoProgram) && "h-full")}>
            <div className="flex flex-col pt-3 sm:pt-0 h-full mx-auto sm:max-w-[1216px] 2xl:max-w-[1506px]">
                <Preloader className="pt-6 pl-4 pr-6 h-[calc(100vh-72px)]" isShow={isProgramLoading}>
                    <Skeleton className="mb-7" width="100%" height="244px" />
                    {Array.from(Array(16).keys()).map((p) => {
                        return (
                            <div key={`card-skeleton__${p}`} className="mb-1 h-20">
                                <Skeleton width="100%" height="100%" />
                            </div>
                        );
                    })}
                </Preloader>
                {(showNoAccess || showNoProgram) && <NoAccess className="flex-grow" />}
                {!showNoAccess && program && (
                    <>
                        <div className="hidden sm:block px-3 sm:pl-4 sm:pr-6.5 pb-3">
                            <Breadcrumbs id="userProgramBreadcrumbs">
                                <Breadcrumbs.Link title={"Моё обучение"} url="/training" />
                                {program && (
                                    <Breadcrumbs.Link
                                        url={courseId ? `/training/program/${programId}` : undefined}
                                        title={program.title}
                                    />
                                )}
                                {currentCourse?.id && (
                                    <Breadcrumbs.Link
                                        url={
                                            testId || resourceId
                                                ? `/training/program/${programId}/${courseId}`
                                                : undefined
                                        }
                                        title={currentCourse.title}
                                    />
                                )}
                                {currentMaterial?.id && <Breadcrumbs.Link title={currentMaterial.title} />}
                            </Breadcrumbs>
                        </div>
                        <ContentContainer>{<Outlet />}</ContentContainer>
                    </>
                )}
            </div>
        </div>
    );
};
