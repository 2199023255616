import React, { useEffect, useState } from "react";
import { ProfileBanner } from "Components/ProfileBanner";
import { Widget } from "Components/Widget";
import { PersonalTable } from "./PersonalTable";
import { useQuery } from "react-query";
import Api from "Api/index";
import { Breadcrumbs } from "Uikit/Breadcrumbs/Breadcrumbs";
import { TabsWrapper } from "Uikit/Tabs/TabsWrapper";
import { Tabs } from "Uikit/Tabs/Tabs";
import { Content, Icon, Icons } from "Uikit";
import { Empty } from "Uikit/Page/Empty";
import { setIsBack, setIsFavorite, setIsHidden, setTitle } from "../../../slices/headerSlice";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { Preloader } from "Components/Preloader/Preloader";
import { BadRequestResponse, ErrorCode } from "Api/BaseResponse";
import compact from "lodash/compact";
import { useCurrentUser } from "hooks/useCurrentUser";
import { PersonalCertificates } from "./PersonalCertificates";
import { PersonalWidget } from "./PersonalWidget";

export const Personal = () => {
    const [currentTab, setCurrentTab] = useState(0);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const { id: idParam } = useParams();
    const currentUser = useCurrentUser();

    const { data: user, error } = useQuery(compact(["users", "personal", idParam]), () =>
        idParam && currentUser.data?.id !== idParam ? Api.User.Read(idParam) : Api.User.GetCurrentUser(),
    );

    const id = user?.id;

    const { data: statistics } = useQuery(
        ["profile", "stats", user?.id],
        () => Api.Statistic.GetUserProgressActivity(user?.id ?? ""),
        {
            enabled: !!user,
        },
    );

    const { data: { Total: userProgressCount = 0 } = {} } = useQuery(
        ["user-progress", "collection", id, "size"],
        () => {
            return Api.Statistic.GetUserProgress(idParam ?? null, 0, 1, [], {});
        },
        { enabled: !!id },
    );

    useEffect(() => {
        dispatch(setIsHidden(false));
        dispatch(setIsBack(true));
        dispatch(setTitle("Профиль"));
        dispatch(setIsFavorite(false));
    }, [dispatch]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const showCertificates = queryParams.get("showCertificates");
        if (showCertificates) {
            setCurrentTab(1);
        }
    }, [location.search]);

    if (error && error instanceof BadRequestResponse) {
        if (error.errorCode === ErrorCode.NO_USER_ACCESS) {
            return <Empty title="Нет доступа к пользователю" />;
        }
    }

    return (
        <div className="relative sm:max-w-[1216px] 2xl:max-w-[1506px] w-full sm:mx-auto sm:pl-4 sm:pr-6.5">
            {!id && (
                <div
                    className="absolute top-2.25 right-10 block sm:hidden z-[100]"
                    onClick={() => navigate("/personal/edit")}
                >
                    <Icon icon={Icons.AdminMenuIconSettings} width={24} height={24} color="fill-blue-drk" />
                </div>
            )}
            <Breadcrumbs className="hidden sm:block">
                <Breadcrumbs.Link title="Личный кабинет" url="" />
            </Breadcrumbs>
            <Preloader className="px-4 h-[calc(100vh-101px)]" isShow={!user || !statistics}>
                <Skeleton className="mt-8 mb-5 rounded-2xl" width="100%" height="244px" />
                <div className="sm:grid sm:grid-cols-[repeat(4,274px)] 2xl:grid-cols-[repeat(4,342px)] sm:gap-6.5 2xl:gap-8 mb-5">
                    {Array.from(Array(4).keys()).map((p) => {
                        return (
                            <div key={p}>
                                <div className="w-60 h-36 sm:w-[274px] sm:h-[154px] 2xl:w-[342px] 2xl:h-[175px] rounded-2xl overflow-hidden leading-0">
                                    <Skeleton className="rounded-2xl" width="100%" height="100%" />
                                </div>
                                <div className="leading-5 line-clamp-2 pt-3">
                                    <Skeleton className="rounded-2xl" width="100%" height="100%" />
                                </div>
                            </div>
                        );
                    })}
                </div>
                <Skeleton className="mb-2.5" width="100%" height="50px" />
                <Skeleton width="100%" height="20px" />
                {Array.from(Array(16).keys()).map((p) => {
                    return <Skeleton key={p} width="100%" height="64px" />;
                })}
            </Preloader>
            {user && statistics && (
                <>
                    <ProfileBanner user={user} isCurrent={!idParam || idParam === currentUser.data?.id} />
                    <div className="hidden sm:flex sm:space-x-6.5 2xl:space-x-8 sm:mt-6 2xl:mt-7.5 sm:mb-6 2xl:mb-7.5">
                        {statistics.lastActivityStatistics && (
                            <Widget title="Активность за 30 дней">
                                <Widget.Line text="Набрано баллов" value={statistics.lastActivityStatistics.rating} />
                                <Widget.Line
                                    text="Материалов изучено"
                                    value={statistics.lastActivityStatistics.materialsPassedCount}
                                />
                                <Widget.Line
                                    text="Сертификатов получено"
                                    value={statistics.lastActivityStatistics.certificatesCount}
                                />
                            </Widget>
                        )}
                        {statistics.trainingProgramStatistics && (
                            <PersonalWidget
                                title="Программы обучения"
                                stats={[
                                    {
                                        size: statistics.trainingProgramStatistics.passedCount,
                                        color: "stroke-primary",
                                        zIndex: 30,
                                    },
                                    {
                                        size:
                                            statistics.trainingProgramStatistics.inProgressCount +
                                            statistics.trainingProgramStatistics.passedCount,
                                        color: "stroke-gray-light",
                                        zIndex: 10,
                                    },
                                ]}
                                count={statistics.trainingProgramStatistics.totalCount}
                                stubText="Здесь будет информация о доступных программах. Попросите назначить вам первую программу!"
                                legendLines={[
                                    {
                                        text: "Пройдено",
                                        value: statistics.trainingProgramStatistics.passedCount,
                                        color: "blue",
                                    },
                                    {
                                        text: "В процессе",
                                        value: statistics.trainingProgramStatistics.inProgressCount,
                                        color: "gray",
                                    },
                                    {
                                        text: "Доступно",
                                        value: statistics.trainingProgramStatistics.availableCount,
                                        color: "light-gray",
                                    },
                                ]}
                            />
                        )}
                        {statistics.courseStatistics && (
                            <PersonalWidget
                                title="Курсы"
                                stats={[
                                    {
                                        size: statistics.courseStatistics.passedCount,
                                        color: "stroke-primary",
                                        zIndex: 30,
                                    },
                                    {
                                        size:
                                            statistics.courseStatistics.inProgressCount +
                                            statistics.courseStatistics.passedCount,
                                        color: "stroke-gray-light",
                                        zIndex: 10,
                                    },
                                ]}
                                count={statistics.courseStatistics.totalCount}
                                stubText="Здесь будет информация о доступных курсах. Попросите назначить вам первый курс!"
                                legendLines={[
                                    {
                                        text: "Пройдено",
                                        value: statistics.courseStatistics.passedCount,
                                        color: "blue",
                                    },
                                    {
                                        text: "В процессе",
                                        value: statistics.courseStatistics.inProgressCount,
                                        color: "gray",
                                    },
                                    {
                                        text: "Доступно",
                                        value: statistics.courseStatistics.availableCount,
                                        color: "light-gray",
                                    },
                                ]}
                            />
                        )}
                        {statistics.quizStatistics && (
                            <PersonalWidget
                                title="Тесты"
                                stats={[
                                    {
                                        size: statistics.quizStatistics.passedCount,
                                        color: "stroke-primary",
                                        zIndex: 30,
                                    },
                                    {
                                        size:
                                            statistics.quizStatistics.failedCount +
                                            statistics.quizStatistics.passedCount,
                                        color: "stroke-red",
                                        zIndex: 10,
                                    },
                                ]}
                                count={statistics.quizStatistics.totalCount}
                                stubText="Здесь будет информация о доступных тестах. Попросите назначить вам первый тест!"
                                legendLines={[
                                    {
                                        text: "Пройдено",
                                        value: statistics.quizStatistics.passedCount,
                                        color: "blue",
                                    },
                                    {
                                        text: "Провалено",
                                        value: statistics.quizStatistics.failedCount,
                                        color: "red",
                                    },
                                    {
                                        text: "Доступно",
                                        value: statistics.quizStatistics.availableCount,
                                        color: "light-gray",
                                    },
                                ]}
                            />
                        )}
                    </div>
                    <div className="hidden sm:block">
                        {userProgressCount > 0 && (
                            <TabsWrapper selectedIndex={currentTab} onChange={setCurrentTab}>
                                <TabsWrapper.Tabs classname="hidden sm:block mb-5">
                                    <Tabs.Tab title="Прогресс" />
                                    {!idParam && <Tabs.Tab title="Сертификаты" />}
                                </TabsWrapper.Tabs>
                                <TabsWrapper.Content>
                                    <Content.Body>
                                        <div className="hidden sm:block">
                                            <PersonalTable user={user} />
                                        </div>
                                    </Content.Body>
                                    <Content.Body>
                                        <div className="hidden sm:block">
                                            <PersonalCertificates />
                                        </div>
                                    </Content.Body>
                                </TabsWrapper.Content>
                            </TabsWrapper>
                        )}
                        {userProgressCount === 0 && (
                            <Empty
                                title="Прогресс недоступен"
                                className="pt-12"
                                description={
                                    <span>
                                        Прогресс пользователя пока что недоступен, так как он
                                        <br />
                                        не начал ни одного прохождения.
                                    </span>
                                }
                                topElement={
                                    <div className="flex-center mb-4 2xl:mb-5">
                                        <div className="flex-center w-16.5 h-16.5 2xl:w-20.5 2xl:h-20.5 rounded-full bg-blue-10">
                                            <Icon
                                                icon={Icons.EmojiSad}
                                                width={36}
                                                height={36}
                                                color={"fill-primary"}
                                                className="2xl:!w-11.25 2xl:!h-11.25"
                                            />
                                        </div>
                                    </div>
                                }
                            />
                        )}
                    </div>
                </>
            )}
        </div>
    );
};
