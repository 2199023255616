import React, { useEffect, useState } from "react";

import Api from "Api";
import { MembersListResponse } from "Api/Responses/UserResponse";
import { StatisticCourseUserRequest } from "Api/Requests/StatisticRequest";
import { IRequestSettingsItem, ReportSettings } from "Pages/Admin/Statistics/ReportSettings/ReportSettings";
import { Breadcrumbs, Button } from "Uikit";
import { numWord } from "helpers/numWord";
import { ResourceType } from "Enums";

import { ReportUserSelect } from "../../../ReportUserSelect/ReportUserSelect";
import { ReportTable } from "./ReportTable";

const reportSettings = [
    {
        title: "Курс",
        key: "course",
        items: [
            {
                title: "Все",
                value: "all",
                checked: true,
            },
            {
                title: "Название программы",
                value: "programName",
                checked: true,
            },
            {
                title: "Статус видимости программы",
                value: "programVisibility",
                checked: true,
            },
            {
                title: "Категория курса",
                value: "category",
                checked: true,
            },
            {
                title: "Название курса",
                value: "name",
                checked: true,
            },
            {
                title: "Статус видимости курса",
                value: "visibility",
                checked: true,
            },
            {
                title: "Статус прохождения курса",
                value: "status",
                checked: true,
            },
            {
                title: "Доступ к курсу на текущий момент",
                value: "access",
                checked: true,
            },
        ],
    },
    {
        title: "Прохождение",
        key: "passing",
        items: [
            {
                title: "Все",
                value: "all",
                checked: true,
            },
            {
                title: "Дата начала",
                value: "startDate",
                checked: true,
            },
            {
                title: "Дата завершения",
                value: "endDate",
                checked: true,
            },
            {
                title: "Дедлайн",
                value: "deadlineDate",
                checked: true,
            },
            {
                title: "Дата последней активности участника",
                value: "activityDate",
                checked: true,
            },
            {
                title: "Набранные баллы",
                value: "rating",
                checked: true,
            },
            {
                title: "Максимальное количество баллов",
                value: "maxRating",
                checked: true,
            },
            {
                title: "Ориентировочное время",
                value: "requiredTime",
                checked: true,
            },
            {
                title: "Затрачено времени",
                value: "spendTime",
                checked: true,
            },
            {
                title: "Прогресс (%)",
                value: "progress",
                checked: true,
            },
        ],
    },
    {
        title: "Материалы",
        key: "material",
        items: [
            {
                title: "Все",
                value: "all",
                checked: true,
            },
            {
                title: "Пройдено материалов",
                value: "completedMaterials",
                checked: true,
            },
            {
                title: "Всего материалов",
                value: "totalMaterials",
                checked: true,
            },
            {
                title: "Статья",
                value: "article",
                checked: true,
            },
            {
                title: "Документ",
                value: "document",
                checked: true,
            },
            {
                title: "Видео",
                value: "video",
                checked: true,
            },
            {
                title: "SCORM",
                value: "scorm",
                checked: true,
            },
            {
                title: "Тест",
                value: "quiz",
                checked: true,
            },
        ],
    },
];

export const CourseUser = () => {
    const [users, setUsers] = useState<MembersListResponse[]>([]);
    const [showReportsTable, setShowReportsTable] = useState(false);
    const [showReportSettings, setShowReportSettings] = useState(false);
    const [userCoursesCount, setUserCoursesCount] = useState(0);
    const [isUsersLoaded, setIsUsersLoaded] = useState(false);

    useEffect(() => {
        if (!users[0]) {
            return;
        }

        const fetchCoursesCount = async () => {
            const coursesCount = await Api.Statistic.GetUserAvailableResourceCount(ResourceType.COURSE, users[0].id);
            setUserCoursesCount(+coursesCount);
        };

        fetchCoursesCount();
    }, [users]);

    const createReport = () => {
        setShowReportsTable(true);
    };

    const createPayloadParams = () => {
        const payload: StatisticCourseUserRequest = {
            userIds: users.map(({ id }) => id),
        };

        return payload;
    };

    const onSubmit = async (settingsChecked: IRequestSettingsItem[]) => {
        const request: any = {};

        request.params = createPayloadParams();

        settingsChecked.forEach(({ key, items }: IRequestSettingsItem) => {
            request[key] = items
                .filter((item) => item.value !== "all")
                .reduce((prev, cur) => {
                    return {
                        ...prev,
                        [cur.value]: cur.checked,
                    };
                }, {});
        });

        setShowReportSettings(false);

        try {
            await Api.Statistic.GenerateCourseUserStatisticReportFile(request);
        } catch (error: any) {
            console.log(error);
        }
    };

    return (
        <div className="px-6 w-full h-full sm:overflow-y-scroll custom-scrollbar">
            <Breadcrumbs className="mb-1">
                <Breadcrumbs.Link title="Администратор" />
                <Breadcrumbs.Link title="Статистика" />
                <Breadcrumbs.Link title="Курсы" />
                <Breadcrumbs.Link title="Отчет по участнику" />
            </Breadcrumbs>
            <div>
                <h1>Отчет по участнику</h1>
                <ReportUserSelect
                    value={users[0] ?? null}
                    onSubmit={(users) => {
                        setUsers(
                            users.map((user) => {
                                const { firstName, lastName, avatarId, defaultAvatarColor } = user;
                                return {
                                    ...user,
                                    value: {
                                        avatarId,
                                        firstName,
                                        lastName,
                                        defaultAvatarColor,
                                    },
                                    label: `${lastName} ${firstName}`,
                                };
                            }),
                        );
                        setShowReportsTable(false);
                    }}
                    onDataLoaded={() => {
                        if (!users.length && !isUsersLoaded) {
                            setIsUsersLoaded(true);
                        }
                    }}
                    isDataLoaded={isUsersLoaded}
                    selectedUsersDefault={users}
                    className="mt-5"
                    dataFetchingFilter={{ "state.in": "ACTIVE" }}
                />
                {users.length > 0 && (
                    <div className="mt-9">
                        {showReportsTable ? (
                            <>
                                <ReportTable
                                    onClickLoadReportFile={() => setShowReportSettings(true)}
                                    queryPayload={createPayloadParams()}
                                />
                                <ReportSettings
                                    isActive={showReportSettings}
                                    setIsActive={setShowReportSettings}
                                    settings={reportSettings}
                                    onSubmit={onSubmit}
                                    isReportFileFetching={false}
                                />
                            </>
                        ) : (
                            <Button
                                key={1}
                                onClick={() => createReport()}
                                variant="standard"
                                size={"medium"}
                                color={"primary"}
                                className="w-full"
                            >
                                {`Сформировать отчет по ${userCoursesCount} ${numWord(userCoursesCount, [
                                    "курсу",
                                    "курсам",
                                    "курсам",
                                ])}`}
                            </Button>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
