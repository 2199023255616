import React, { useState, ChangeEvent, useEffect } from "react";
import InputMask from "react-input-mask";

import { Button, Icon, Icons, Input, FormGroup, Label, Tooltip } from "Uikit";
import { passwordRegexp } from "Uikit/Forms/Input";
import { TVoidFunction } from "types";

import { IAuthorizationInviteSettingsFormProps } from "./AuthorizationInviteForm";

interface IAuthorizationInviteFormAccountProps extends IAuthorizationInviteSettingsFormProps {
    onLoginValidate: TVoidFunction;
    onPhoneValidate: TVoidFunction;
    onEmailValidate: TVoidFunction;
    setIsValid: (isValid: boolean) => void;
    setErrors: (errors: any) => void;
}

export const AuthorizationInviteFormAccount = ({
    registrationRequest,
    onChange,
    onSubmit,
    errors,
    isValid,
    onLoginValidate,
    onPhoneValidate,
    onEmailValidate,
    setIsValid,
    setErrors,
}: IAuthorizationInviteFormAccountProps) => {
    const [showPassword, setShowPassword] = useState(false);
    const [isPasswordChangedManually, setIsPasswordChangedManually] = useState(false);
    const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);
    const [passwordsDiffMessage, setPasswordsDiffMessage] = useState<string | null>("Пароли не совпадают");
    const onLoginBlur = () => {
        onLoginValidate();
    };
    const onPhoneBlur = () => {
        const phoneLength = registrationRequest.phone?.replace(/^[+7]+/g, "").replace(/[^\d]+/g, "").length;
        if (!phoneLength) {
            onChange({
                ...registrationRequest,
                phone: null,
            });
            return;
        }
        onPhoneValidate();
    };
    const onEmailBlur = () => {
        onEmailValidate();
    };

    const onLoginChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(/_{2,}/, "_").replace(/\.{2,}/, ".");
        onChange({ ...registrationRequest, login: value });
    };

    let errMsg = "";
    if ([registrationRequest.login, registrationRequest.password].some((item) => !item)) {
        errMsg = "Заполните обязательные поля";
    } else {
        if (errors?.login) {
            errMsg = `Логин: ${errors?.login}`;
        }
        if (errors?.password) {
            errMsg = `Пароль: ${errors?.password}`;
        }
        if (errors?.email) {
            errMsg = `Почта: ${errors?.email}`;
        }
        if (errors?.phone) {
            errMsg = `Телефон: ${errors?.phone}`;
        }
    }

    useEffect(() => {
        const isSame = registrationRequest.password === registrationRequest.passwordRepeat;
        setPasswordsDiffMessage(!isSame ? "Пароли не совпадают" : null);
    }, [registrationRequest.password, registrationRequest.passwordRepeat]);

    useEffect(() => {
        if (!isPasswordChangedManually && registrationRequest.password !== registrationRequest.passwordRepeat) {
            setIsValid(false);
        }
        if (passwordsDiffMessage && isPasswordChangedManually) {
            setErrors({
                "password-repeat": "Пароли не совпадают",
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [passwordsDiffMessage]);

    useEffect(() => {
        if (isValid) {
            setErrors({
                password: undefined,
                "password-repeat": undefined,
                email: undefined,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isValid]);

    return (
        <>
            <FormGroup>
                <Label isRequired={true}>Логин</Label>
                <Input
                    isRequired={true}
                    placeholder="Введите логин"
                    value={registrationRequest.login}
                    onChange={onLoginChange}
                    onBlur={onLoginBlur}
                    minLength={3}
                    maxLength={128}
                    error={errors?.["login"]}
                    cutRegExp={new RegExp(/[^a-zA-Z0-9_.]/g)}
                    id="adminNewMemberInputlogin"
                />
            </FormGroup>
            <FormGroup>
                <Label isRequired={/* !registrationRequest.inviteId */ true}>Пароль</Label>
                <div className="relative">
                    <Input
                        // isRequired={!registrationRequest.inviteId}
                        isRequired={true}
                        placeholder="Введите пароль"
                        value={registrationRequest.password}
                        onChange={(e) => {
                            onChange({ ...registrationRequest, password: e.target.value });
                            setIsPasswordChangedManually(true);
                        }}
                        minLength={8}
                        maxLength={16}
                        cutRegExp={passwordRegexp}
                        id="adminNewMemberInputPassword"
                        type={showPassword ? "text" : "password"}
                        error={errors?.["password"] && isPasswordChangedManually ? errors?.["password"] : undefined}
                        after={
                            <span
                                className={`flex items-center translate-x-2 ${
                                    (errors?.["password"] && isPasswordChangedManually
                                        ? errors?.["password"]
                                        : undefined) && "!-translate-x-6"
                                }`}
                            >
                                <button
                                    className="z-20 p-1 focus:outline-none focus:shadow-outline"
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    <Icon icon={showPassword ? Icons.Eye : Icons.EyeSlash} width="20px" height="20px" />
                                </button>
                            </span>
                        }
                    />
                </div>
            </FormGroup>
            <FormGroup>
                <Label>Повторите пароль</Label>
                <div className="relative">
                    <Input
                        placeholder="Введите пароль"
                        value={registrationRequest.passwordRepeat}
                        onChange={(e) => {
                            onChange({ ...registrationRequest, passwordRepeat: e.target.value });
                        }}
                        minLength={8}
                        maxLength={16}
                        cutRegExp={passwordRegexp}
                        id="adminNewMemberInputPasswordRepeat"
                        type={showPasswordRepeat ? "text" : "password"}
                        error={errors?.["password-repeat"]}
                        after={
                            <span
                                className={`flex items-center translate-x-2 ${
                                    errors?.["password-repeat"] && "!-translate-x-6"
                                }`}
                            >
                                <button
                                    className="z-20 p-1 focus:outline-none focus:shadow-outline"
                                    onClick={() => setShowPasswordRepeat(!showPasswordRepeat)}
                                >
                                    <Icon
                                        icon={showPasswordRepeat ? Icons.Eye : Icons.EyeSlash}
                                        width="20px"
                                        height="20px"
                                    />
                                </button>
                            </span>
                        }
                    />
                </div>
            </FormGroup>
            <FormGroup>
                <Label>Почта</Label>
                <Input
                    placeholder="Введите вашу почту"
                    value={registrationRequest.email}
                    onChange={(e) => onChange({ ...registrationRequest, email: e.target.value })}
                    minLength={5}
                    maxLength={256}
                    error={errors?.["email"]}
                    // eslint-disable-next-line no-useless-escape
                    cutRegExp={new RegExp(/[^a-zA-Z0-9!#$%&'*+\-\/=?^_`{|}~.@]/g)}
                    id="adminNewMemberInputEmail"
                    onBlur={onEmailBlur}
                />
            </FormGroup>
            <FormGroup>
                <Label>Номер телефона</Label>
                <InputMask
                    mask="+7 999 999-99-99"
                    type="text"
                    placeholder="+7 999 999-99-99"
                    value={registrationRequest.phone !== null ? registrationRequest.phone.toString() : ""}
                    onChange={(e) => {
                        if (e.type === "focus" || e.type === "blur") {
                            return;
                        }
                        onChange({ ...registrationRequest, phone: e.target.value });
                    }}
                    beforeMaskedStateChange={({ nextState, previousState }) => {
                        return nextState.value.includes("_") && nextState.selection?.end === 16
                            ? previousState ?? nextState
                            : nextState;
                    }}
                    onBlur={onPhoneBlur}
                >
                    <Input
                        id="adminNewMemberInputTel"
                        className="!text-black"
                        error={errors?.["phone"]}
                        tooltipIntercative={typeof errors?.["phone"] !== "string"}
                    />
                </InputMask>
            </FormGroup>
            <Tooltip
                className="w-full max-w-[700px] flex rounded-md cursor-pointer"
                content={errMsg || passwordsDiffMessage}
                disabled={isValid && !!registrationRequest.password && !passwordsDiffMessage}
            >
                <Button
                    className="w-full h-12 sm:!mt-1 sm:!mb-0 sm:h-10"
                    onClick={() => {
                        onSubmit();
                    }}
                    disabled={!isValid || !registrationRequest.password || !!passwordsDiffMessage}
                >
                    Далее
                </Button>
            </Tooltip>
        </>
    );
};
