import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ColumnDef, ColumnFiltersState, SortingState } from "@tanstack/react-table";
import { useInfiniteQuery, useQuery, useQueryClient } from "react-query";

import { ContentLayout } from "Containers/ContentLayout";
import { Breadcrumbs, Button, Checkbox, Icon, Icons, Table } from "Uikit/index";
import { MultiClumpTooltip } from "Components/MultiClumpTooltip/MultiClumpTooltip";
import { Filter, IFilterRow } from "Components/Filter/Filter";
import { FavoriteListResponse } from "Api/Responses/FavoriteResponse";
import { useDialog } from "hooks/useDialog";
import Api from "Api/index";
import { Confirmation } from "Components/Confirmation/Confirmation";
import { MaterialTypesTranslate, ProgressStatus, ProgressStatusTranslate, ResourceType } from "Enums";
import { DateFormat, formatDate } from "helpers/dateHelper";
import { FavoriteRemoveRequest } from "Api/Requests/FavoriteRequest";
import { Empty } from "Uikit/Page/Empty";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { Preloader } from "Components/Preloader/Preloader";
import { numWord } from "helpers/numWord";
import { LogoSize } from "Api/Services/UploadApi";

export const Favorite = () => {
    const queryClient = useQueryClient();
    const { dialogState, openDialog, closeDialog } = useDialog();
    const navigate = useNavigate();
    const observerElem = useRef<HTMLDivElement>(null);

    const [sorting, setSorting] = useState<SortingState>([{ id: "addedToFavoritesTime", desc: true }]);

    const [filters, setFilters] = useState<{ [id: string]: any }>({});
    const [isFilters, setIsFilters] = useState(false);
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

    const [rowSelection, setRowSelection] = useState({});

    const { data: filterInfo } = useQuery(["favorites/filter-info"], async () => {
        const info = await Api.Favorite.FilterInfo();

        const materialTypeOptions = [
            { value: "ARTICLE", label: "Статья", type: ResourceType.MATERIAL },
            { value: "VIDEO", label: "Видео", type: ResourceType.MATERIAL },
        ];

        for (const fileType of info.materialFileTypes) {
            materialTypeOptions.push({
                value: fileType,
                label: fileType,
                type: ResourceType.DOCUMENT,
            });
        }

        materialTypeOptions.push({ value: "SCORM", label: "SCORM", type: ResourceType.MATERIAL });

        return materialTypeOptions;
    });

    const {
        data: favorites,
        isFetching,
        refetch,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
    } = useInfiniteQuery(
        ["favorites", { sorting, filters }],
        async ({ pageParam = 0 }) => {
            const filtersKeys = Object.keys(filters);
            const filtersData: any = {};

            for (const element of filtersKeys) {
                if (element === "addedToFavoritesTime") {
                    filtersData[element + ".greaterThanOrEqual"] = Math.round(
                        filters[element].date["startDate"].getTime() / 1000,
                    );
                    filtersData[element + ".lessThanOrEqual"] = Math.round(
                        filters[element].date["endDate"].getTime() / 1000,
                    );
                } else if (element === "type.in") {
                    const isMaterials = filters[element].some((f: any) => f.type === ResourceType.MATERIAL);
                    const isDocuments = filters[element].some((f: any) => f.type === ResourceType.DOCUMENT);
                    if (isMaterials) {
                        filtersData["materialType.in"] = filters[element]
                            .filter((f: any) => f.type === ResourceType.MATERIAL)
                            .map((f: any) => f.value)
                            .join(",");
                    }
                    if (isDocuments) {
                        filtersData["materialFileType.in"] = filters[element]
                            .filter((f: any) => f.type === ResourceType.DOCUMENT)
                            .map((f: any) => f.value)
                            .join(",");
                    }

                    if (isMaterials && isDocuments) {
                        filtersData["materialType.in"] += ",DOCUMENT";
                        filtersData["materialFileType.in"] += ",NULL";
                    }
                    if (isMaterials && !isDocuments) {
                        filtersData["materialType.in"].replace(",DOCUMENT");
                    }
                } else if (element === "progressStatus.in" && Array.isArray(filters[element])) {
                    filtersData["progressStatus.in"] = filters[element].map((p: any) => p.value).join(",");
                } else {
                    filtersData[element] = filters[element];
                }
            }

            const res = await Api.Favorite.List(pageParam, 15, sorting, filtersData);
            return res.Content;
        },
        {
            getNextPageParam: (lastPage, allPages) => {
                const nextPage = allPages.length;

                return lastPage?.length !== 0 ? nextPage : undefined;
            },
            keepPreviousData: true,
        },
    );

    const favoritesList = useMemo(() => {
        return favorites?.pages.length ? ([] as FavoriteListResponse[]).concat(...favorites.pages.map((p) => p)) : [];
    }, [favorites]);

    const isFiltersEmpty = useMemo(() => {
        if (Object.keys(filters).indexOf("searchQuery.contains") !== -1) {
            return false;
        }

        return Object.keys(filters).every((f) => !filters[f]);
    }, [filters]);

    const onSearch = (searchQuery: string) => {
        setFilters((prevState) => ({ ...prevState, "searchQuery.contains": searchQuery }));
    };

    const navigateToResource = useCallback(
        (resource: FavoriteListResponse) => {
            let url = "";

            switch (resource.type) {
                case ResourceType.PROGRAM:
                    url = `/training/program/${resource.resourceId}`;
                    break;
                case ResourceType.MATERIAL:
                    url = `/training/course/${resource.courseId}/${resource.componentId}/${resource.resourceId}?favorite=true`;
                    break;
                case ResourceType.COURSE:
                    url = `/training/course/${resource.resourceId}`;
                    break;
                case ResourceType.QUIZ:
                    if (
                        resource.progressStatus === ProgressStatus.FAILED ||
                        resource.progressStatus === ProgressStatus.ON_REVIEW ||
                        resource.progressStatus === ProgressStatus.PASSED
                    ) {
                        url = `/test/${resource.resourceId}/statistics`;
                    } else {
                        url = `/test/${resource.resourceId}`;
                    }

                    break;
                case ResourceType.EXERCISE:
                    url = `/task/${resource.resourceId}${
                        resource.progressStatus === ProgressStatus.FAILED ||
                        resource.progressStatus === ProgressStatus.ON_REVIEW ||
                        resource.progressStatus === ProgressStatus.PASSED
                            ? "/statistics"
                            : ""
                    }`;
                    break;
                default:
                    break;
            }

            navigate(url);
        },
        [navigate],
    );

    const removeFromFavorite = useCallback(
        async (data: FavoriteListResponse | string[]) => {
            const isArray = Array.isArray(data);
            let rows: FavoriteRemoveRequest[] = [],
                title = "";

            if (isArray) {
                rows = data.reduce<FavoriteRemoveRequest[]>((acc, i) => {
                    const row = favoritesList.find((r) => r.id === i);

                    if (row) {
                        const req: any = {
                            resourceId: row?.resourceSolutionId || row?.resourceId,
                            type: row?.type,
                        };

                        if (row.type === ResourceType.MATERIAL) {
                            req["componentId"] = row.componentId;
                            req["courseId"] = row.courseId;
                        }

                        if (row.type === ResourceType.EXERCISE) {
                            req["passingNumber"] = row.passingNumber;
                        }

                        acc.push(req);
                    }

                    return acc;
                }, []);

                if (data.length === 1) {
                    title = favoritesList.find((i) => i.id === data[0])?.title ?? "";
                }
            } else if (!isArray) {
                const req: any = { resourceId: data.resourceSolutionId || data.resourceId, type: data.type };

                if (data.type === ResourceType.MATERIAL) {
                    req["componentId"] = data.componentId;
                    req["courseId"] = data.courseId;
                }
                rows = [req];
                title = data.title;
            }

            openDialog({
                title: "Удалить из избранного",
                description:
                    Array.isArray(data) && data.length > 1
                        ? `Выделено ${data.length} ${numWord(data.length, ["элемент", "элемента", "элементов"])}`
                        : `«${title}»`,
                content:
                    Array.isArray(data) && data.length > 1
                        ? "Вы действительно уверены, что хотите удалить материалы из избранного? Они пропадут из вкладки, но будут доступны в системе"
                        : "Вы действительно уверены, что хотите удалить материал из избранного? Он пропадет из вкладки, но будет доступен в системе",
                closeBtnText: "Отмена",
                submitBtnText: "Удалить",
                submitBtnColor: "danger",
                onRequestClose: () => closeDialog(),
                onRequestSubmit: () => {
                    let promise;

                    if (Array.isArray(data)) {
                        promise = Api.Favorite.RemoveList(rows);
                    } else {
                        promise = Api.Favorite.Remove(
                            Object.assign(new FavoriteRemoveRequest(), {
                                resourceId: data.resourceId,
                                passingNumber: data.passingNumber,
                                courseId: data.courseId,
                                componentId: data.componentId,
                                type: data.type,
                            }),
                        );
                    }

                    promise.then(() => {
                        queryClient.invalidateQueries(["ui/training"]);
                        queryClient.invalidateQueries(["ui/tests"]);

                        queryClient.invalidateQueries(["ui/tasks"]);
                        refetch();

                        setRowSelection({});
                        closeDialog();
                    });
                },
            });
        },
        [closeDialog, openDialog, favoritesList, refetch, queryClient],
    );

    const columns = useMemo<ColumnDef<FavoriteListResponse>[]>(
        () => [
            {
                id: "select",
                enableResizing: true,
                size: 16,
                header: ({ table }) => (
                    <Checkbox
                        checked={table.getIsAllRowsSelected()}
                        indeterminate={table.getIsSomeRowsSelected()}
                        onChange={table.getToggleAllRowsSelectedHandler()}
                    />
                ),
                cell: ({ row }) => (
                    <Checkbox
                        checked={row.getIsSelected()}
                        indeterminate={row.getIsSomeSelected()}
                        onChange={row.getToggleSelectedHandler()}
                    />
                ),
            },
            {
                header: "название",
                accessorKey: "title",
                enableResizing: true,
                size: 360,
                footer: (props) => props.column.id,
                cell: ({ row }) => (
                    <div
                        className="overflow-hidden max-w-100 cursor-pointer"
                        onClick={() => navigateToResource(row.original)}
                    >
                        <div className="flex items-center space-x-3 2xl:space-x-4.5">
                            <div
                                className="flex-shrink-0 bg-cover bg-center w-[54px] 2xl:w-[68px] h-9 2xl:h-[45px] rounded-md 2xl:rounded-lg"
                                style={{
                                    backgroundImage: `url('${
                                        row.original.logoId
                                            ? Api.Upload.GetLogo(row.original.logoId, LogoSize.THUMB_MICRO)
                                            : "/img/article-card-cover.jpg"
                                    }')`,
                                }}
                            ></div>
                            <MultiClumpTooltip clamp={1} label={String(row.original.title)} />
                        </div>
                    </div>
                ),
            },
            {
                header: "тип",
                accessorKey: "type, materialType, materialFileType",
                enableResizing: true,
                size: 200,
                footer: (props) => props.column.id,
                cell: ({ row }) => {
                    const isMaterial = row.original.type === ResourceType.MATERIAL;
                    const isDocument = row.original.materialType === ResourceType.DOCUMENT;
                    return (
                        <div className="overflow-hidden max-w-50">
                            {isDocument && String(row.original.materialFileType).toUpperCase()}
                            {isMaterial && !isDocument && MaterialTypesTranslate[row.original.materialType]}
                            {!isDocument && !isMaterial && MaterialTypesTranslate[row.original.type]}
                        </div>
                    );
                },
            },
            {
                header: "добавлен",
                accessorKey: "addedToFavoritesTime",
                enableResizing: true,
                size: 200,
                footer: (props) => props.column.id,
                cell: ({ row }) => (
                    <div className="overflow-hidden max-w-50">
                        {formatDate(
                            new Date(Number(row.original.addedToFavoritesTime) * 1000),
                            DateFormat.DATE_TIME_LONG,
                        )}
                    </div>
                ),
            },
            {
                header: "прогресс",
                accessorKey: "progressStatus",
                enableResizing: true,
                size: 200,
                footer: (props) => props.column.id,
                cell: ({ row: { original } }) => (
                    <div className="overflow-hidden max-w-50">{ProgressStatusTranslate[original.progressStatus]}</div>
                ),
            },
            {
                id: "buttons",
                header: "",
                accessor: "[row identifier to be passed to button]",
                enableResizing: true,
                size: 30,
                cell: ({ row }) => {
                    return (
                        <div className="flex justify-end" id={"adminCoursesTableGroupButton" + row.id}>
                            <Button
                                shape="round"
                                color="common"
                                icon={
                                    <Icon
                                        icon={Icons.Delete}
                                        width="16px"
                                        height="16px"
                                        color="fill-blue-drk hover:fill-blue-hover"
                                        className="2xl:!w-5 2xl:!h-5"
                                    />
                                }
                                iconPlacement="center"
                                onClick={() => removeFromFavorite(row.original)}
                            />
                        </div>
                    );
                },
            },
        ],
        [removeFromFavorite, navigateToResource],
    );

    const filtersConfig = [
        {
            label: "Дата добавления",
            fields: [
                {
                    accessor: "addedToFavoritesTime",
                    type: "date-range",
                },
            ],
        },
        {
            label: "Тип материала",
            fields: [
                {
                    accessor: "type.in",
                    type: "multi-select",
                    placeholder: "Выберите тип",
                    options: filterInfo ?? [],
                },
            ],
        },
        {
            label: "Прогресс",
            fields: [
                {
                    accessor: "progressStatus.in",
                    type: "multi-select",
                    placeholder: "Выберите прогресс",
                    options: [
                        { value: "NOT_STARTED", label: "Не начато" },
                        { value: "IN_PROGRESS", label: "В процессе" },
                        { value: "ON_REVIEW", label: "На проверке" },
                        { value: "PASSED", label: "Пройден" },
                        { value: "FAILED", label: "Провален" },
                    ],
                },
            ],
        },
    ];

    const controlButtons = (
        <div className="flex justify-end items-center space-x-4">
            <Button
                variant="outline"
                color="secondary"
                size="medium"
                className="border-[#E6E9ED] rounded-lg font-medium"
                icon={
                    <Icon
                        icon={Icons.Filter}
                        width={20}
                        height={20}
                        color="stroke-blue"
                        className="2xl:!w-6.25 2xl:!h-6.25"
                    />
                }
                iconPlacement="left"
                onClick={() => setIsFilters(true)}
                id="adminCoursesBtnFilter"
            >
                Фильтры
            </Button>
        </div>
    );

    const selectButtons = (
        <div className="flex items-center space-x-5">
            <Button
                onClick={() => removeFromFavorite(Object.keys(rowSelection))}
                color="danger"
                size="medium"
                id="adminCoursesBtnArchive"
            >
                Удалить из избранного
            </Button>
        </div>
    );

    const handleObserver = useCallback(
        (entries: any) => {
            const [target] = entries;
            if (target.isIntersecting && hasNextPage) {
                fetchNextPage().then();
            }
        },
        [fetchNextPage, hasNextPage],
    );

    useEffect(() => {
        const element = observerElem.current;
        const option = { threshold: 0 };

        const observer = new IntersectionObserver(handleObserver, option);
        if (element) {
            observer.observe(element);

            return () => observer.unobserve(element);
        }
    }, [fetchNextPage, hasNextPage, handleObserver]);

    return (
        <ContentLayout className="sm:pl-4 sm:pr-6.5 sm:max-w-[1216px] 2xl:max-w-[1506px] w-full h-full mx-auto">
            <Confirmation {...dialogState} />
            <Breadcrumbs>
                <Breadcrumbs.Link title="Избранное" />
            </Breadcrumbs>
            {!isFetching && isFiltersEmpty && favoritesList.length === 0 && (
                <Empty
                    title="Ничего нет"
                    description={
                        <span>
                            Добавьте понравившиеся учебные материалы в избранное,
                            <br />
                            нажимая на соответствующую кнопку
                        </span>
                    }
                    topElement={
                        <div className="flex-center mb-4 2xl:mb-5">
                            <div className="flex-center w-16.5 h-16.5 2xl:w-20.5 2xl:h-20.5 rounded-full bg-blue-10">
                                <Icon
                                    icon={Icons.EmojiSad}
                                    width={"36px"}
                                    height={"36px"}
                                    color={"fill-primary"}
                                    className="2xl:!w-11.25 2xl:!h-11.25"
                                />
                            </div>
                        </div>
                    }
                />
            )}
            {(!isFiltersEmpty || favoritesList.length > 0) && (
                <>
                    <Filter
                        isActive={isFilters}
                        setIsActive={setIsFilters}
                        configuration={filtersConfig as IFilterRow[]}
                        filters={filters}
                        onChange={setFilters}
                    />
                    <Table
                        id="adminTasks"
                        columns={columns}
                        controlButtons={controlButtons}
                        columnFilters={columnFilters}
                        emptyMessage="По заданным параметрам результатов нет"
                        emptyTitle="Ничего не найдено"
                        searchTitle="Поиск по названию"
                        // isFetching={isFetching}
                        data={favoritesList}
                        rowSelection={rowSelection}
                        onRowSelectionChange={setRowSelection}
                        onColumnFiltersChange={setColumnFilters}
                        selectButtons={selectButtons}
                        sorting={sorting}
                        withoutLines
                        onSortingChange={setSorting}
                        onSearch={onSearch}
                        defaultSortOrder={{
                            "type, materialType, materialFileType": "asc",
                        }}
                    />
                </>
            )}
            <div className="relative" ref={observerElem}>
                <Preloader
                    className={`flex flex-col ${isFetching ? "mt-14" : ""}`}
                    isShow={isFetching || (isFetchingNextPage && !!hasNextPage)}
                >
                    {isFetching && <Skeleton width="100%" height="18px" />}
                    {Array.from(Array(16).keys()).map((p) => {
                        return (
                            <div key={`card-skeleton__${p}`} className="mb-1 h-16">
                                <Skeleton width="100%" height="100%" />
                            </div>
                        );
                    })}
                </Preloader>
            </div>
        </ContentLayout>
    );
};
