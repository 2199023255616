import React, { useMemo, useState, useCallback } from "react";
import { ColumnDef, ColumnFiltersState, PaginationState, SortingState, Row } from "@tanstack/react-table";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import { Button, Icon, Icons, Table, PopoverList, Checkbox, Tooltip } from "Uikit";
import { flash } from "Uikit/Notification/flash";
import { Empty } from "Uikit/Page/Empty";
import { Confirmation } from "Components/Confirmation/Confirmation";
import Api from "Api";
import { InviteListResponse } from "Api/Responses/InviteResponse";
import { ResourceState } from "Enums";
import { numWord } from "helpers/numWord";
import { useDialog } from "hooks/useDialog";
import { useInvalidate } from "hooks/useInvalidate";
import { getInviteLink } from "helpers/urlUtils";

import { InviteRegistrationMembersModal } from "../Modal/InviteRegistrationMembersModal";
import clsx from "clsx";

export const InvitesTab = () => {
    const [rowSelection, setRowSelection] = useState({});
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [membersCount, setMembersCount] = useState(0);
    const [invitedUsersInviteId, setInvitedUsersInviteId] = useState<string | null>(null);

    const { dialogState, openDialog, closeDialog } = useDialog();
    const invalidate = useInvalidate();
    const navigate = useNavigate();

    const [sorting, setSorting] = useState<SortingState>([]);

    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 15 });
    const pagination = useMemo(() => ({ pageIndex, pageSize }), [pageIndex, pageSize]);
    const [showMembersModal, setShowMembersModal] = useState(false);

    const [isSelectedState, setIsSelectedState] = useState<boolean>(false);

    const goToInvitePage = useCallback(
        (id?: string, edit = false) => {
            navigate(`/admin/settings/invite${id ? `/${id}` : ""}${edit ? `/edit` : ""}`);
        },
        [navigate],
    );

    const data = useQuery(
        ["invites", "collection", pagination, sorting],
        () => {
            return Api.Invites.List(pageIndex, pageSize, sorting);
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );

    const copyLinkToClipBoard = (id: string) => {
        // В Authorization нужно будет отслеживать наличие пар-ра inviteId в URL страницы и делать соотв. обработку в UI
        // const inviteId = new URLSearchParams(window.location.search).get('inviteId')
        navigator.clipboard.writeText(getInviteLink(id)).then(
            function () {
                flash.success("Ссылка скопирована в буфер обмена");
            },
            function () {
                flash.success("Ошибка при копировании ссылки в буфер обмена");
            },
        );
    };

    const onActionHandler = useCallback(
        (
            action: "state" | "remove",
            values: {
                state?: ResourceState;
                ids?: string[];
            } = {
                state: ResourceState.ACTIVE,
            },
        ) => {
            setIsSelectedState(false);

            const isChangeState = action === "state";
            const isActive = values.state === ResourceState.ACTIVE;
            const ids: string[] = values.ids ?? Object.keys(rowSelection);
            const isMultiple = Array.isArray(ids) && ids.length > 1;
            const title = isChangeState
                ? "Изменение статуса"
                : `Удаление ${isMultiple ? "приглашений" : "приглашения"}`;
            const content = isChangeState
                ? `Вы действительно хотите изменить статус ${isMultiple ? "приглашений" : "приглашения"} на ${
                      isActive ? "активное" : "неактивное"
                  }?`
                : `Вы действительно хотите удалить ${isMultiple ? "приглашения" : "приглашение"}? Регистрация по ${
                      isMultiple ? "ним" : "нему"
                  } будет недоступна`;

            openDialog({
                title,
                description: isMultiple
                    ? `Выбрано приглашений: ${ids.length}`
                    : `«${data.data!.Content.find((i) => i.id === ids[0])?.title}»`,
                content,
                closeBtnText: "Отмена",
                submitBtnText: isChangeState ? "Изменить" : "Удалить",
                submitBtnColor: isChangeState ? "primary" : "danger",
                onRequestClose: () => closeDialog(),
                onRequestSubmit: async () => {
                    setRowSelection({});
                    if (isChangeState) {
                        await Api.Invites.State({ inviteIds: ids, state: values.state as ResourceState });
                    } else {
                        await Api.Invites.Remove({ inviteIds: ids });
                    }

                    invalidate("invites");
                    closeDialog();
                },
            });
        },
        [rowSelection, data.data, closeDialog, invalidate, openDialog],
    );

    const isRowSelectable = useCallback((row: Row<any>) => {
        return !row.original.requiresAction;
    }, []);

    const columns = React.useMemo<ColumnDef<InviteListResponse>[]>(
        () => [
            {
                id: "select",
                enableResizing: true,
                size: 16,
                header: ({ table }) => (
                    <Checkbox
                        checked={table.getIsAllRowsSelected()}
                        indeterminate={table.getIsSomeRowsSelected()}
                        onChange={table.getToggleAllRowsSelectedHandler()}
                    />
                ),
                cell: ({ row }) => {
                    return (
                        <div
                            className={`${
                                !isRowSelectable(row)
                                    ? "after:block after:absolute after:z-[1] after:bg-red-10 after:content-[''] after:top-3 after:bottom-3 after:-left-1 after:right-0 after:rounded-md"
                                    : ""
                            }`}
                        >
                            <Checkbox
                                checked={row.getIsSelected()}
                                indeterminate={row.getIsSomeSelected()}
                                onChange={row.getToggleSelectedHandler()}
                                className="z-[2]"
                                disabled={!isRowSelectable(row)}
                            />
                        </div>
                    );
                },
                getRowClassNameFn: (row: Row<any>) => {
                    return typeof row.original.requiresAction === "boolean" && row.original.requiresAction
                        ? "relative"
                        : "";
                },
            },
            {
                header: "название",
                accessorKey: "title",
                footer: (props) => props.column.id,
                enableResizing: true,
                size: 300,
                cell: ({
                    row: {
                        original: { title, requiresAction },
                    },
                }) => {
                    return (
                        <div className={`flex items-center relative`}>
                            <span className="text-ellipsis overflow-hidden line-clamp-1 z-[2]">{title}</span>
                            {requiresAction && (
                                <div className="ml-auto z-[2]">
                                    <Tooltip content={<span>Удалена команда, входящая в настройки приглашения</span>}>
                                        <Icon icon={Icons.Info} width={24} height={24} color="fill-red" />
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                    );
                },
            },
            {
                header: "Статус",
                accessorKey: "state",
                size: 200,
                cell: ({
                    row: {
                        original: { state },
                    },
                }) => {
                    const isActive = state === ResourceState.ACTIVE;
                    return (
                        <span className={`inline-block relative z-[2] ${!isActive ? "text-gray-text" : ""}`}>
                            {`${isActive ? "Активное" : "Неактивное"}`}
                        </span>
                    );
                },
                footer: (props) => props.column.id,
            },
            {
                header: "кол-во участников",
                // enableResizing: true,
                size: 200,
                cell: ({
                    row: {
                        original: { id, registeredUserCount },
                    },
                }) => {
                    return (
                        <div
                            className={clsx(
                                "inline-flex space-x-1 relative z-[2] group",
                                registeredUserCount === 0 ? "cursor-auto" : "cursor-pointer",
                            )}
                            onClick={() => {
                                if (registeredUserCount === 0) {
                                    return;
                                }
                                setShowMembersModal(true);
                                setMembersCount(registeredUserCount);
                                setInvitedUsersInviteId(id);
                            }}
                        >
                            <span className="flex align-middle items-center">
                                <Icon
                                    icon={Icons.ShareBox}
                                    width={18}
                                    height={18}
                                    color={registeredUserCount === 0 ? "fill-gray-light" : "fill-primary"}
                                />
                            </span>
                            <span
                                className={clsx(
                                    "flex !font-sans",
                                    registeredUserCount === 0
                                        ? "text-gray-light"
                                        : "text-primary group-hover:underline",
                                )}
                            >
                                {`${registeredUserCount} ${numWord(registeredUserCount, [
                                    "участник",
                                    "участника",
                                    "участников",
                                ])}`}
                            </span>
                        </div>
                    );
                },
                footer: (props) => props.column.id,
            },
            {
                header: "команды для регистрации",
                // enableResizing: true,
                size: 300,
                cell: ({
                    row: {
                        original: { teams },
                    },
                }) => {
                    let length = 31;
                    let counter = teams.length;

                    for (const element of teams) {
                        if (element.name.length + 2 > length) {
                            break;
                        }

                        length -= element.name.length + 2;
                        counter -= 1;
                    }

                    return (
                        <div className="flex items-center truncate">
                            <p className="relative z-[2] truncate w-56 font-normal grow">
                                {teams.length === 0 && "-"}
                                {teams.map((team) => team.name).join(", ")}
                            </p>
                            {!!counter && (
                                <Tooltip
                                    placement="top"
                                    arrow={true}
                                    content={teams.map((team) => team.name).join(", ")}
                                    className="relative z-[2] "
                                >
                                    <span className="ml-auto py-0.5 px-1 bg-gray-light rounded-full p4-accent text-white cursor-pointer">
                                        {counter}+
                                    </span>
                                </Tooltip>
                            )}
                        </div>
                    );
                },
                footer: (props) => props.column.id,
            },
            {
                header: "Действия",
                id: "buttons",
                enableResizing: true,
                size: 30,
                enableSorting: false,
                cell: ({
                    row: {
                        original: { id },
                    },
                }) => {
                    return (
                        <div className="flex relative z-[2]">
                            <Button
                                shape={"round"}
                                color={"common"}
                                icon={
                                    <Icon
                                        icon={Icons.Pencil}
                                        width="18px"
                                        height="18px"
                                        color="fill-blue-drk hover:fill-blue-hover"
                                    />
                                }
                                iconPlacement={"center"}
                                onClick={() => {
                                    goToInvitePage(id, true);
                                }}
                            />
                            <Button
                                shape={"round"}
                                color={"common"}
                                icon={
                                    <Icon
                                        icon={Icons.Copy}
                                        width="18px"
                                        height="18px"
                                        color="fill-blue-drk hover:fill-blue-hover"
                                    />
                                }
                                iconPlacement={"center"}
                                onClick={() => {
                                    copyLinkToClipBoard(id);
                                }}
                            />
                            <Button
                                shape={"round"}
                                color={"common"}
                                icon={
                                    <Icon
                                        icon={Icons.Delete}
                                        width="18px"
                                        height="18px"
                                        color="fill-blue-drk hover:fill-blue-hover"
                                    />
                                }
                                iconPlacement={"center"}
                                onClick={() => {
                                    onActionHandler("remove", {
                                        ids: [id],
                                    });
                                }}
                            />
                        </div>
                    );
                },
            },
        ],
        [onActionHandler, goToInvitePage, isRowSelectable],
    );

    const controlButtons = (
        <div
            className="flex justify-end items-center space-x-5 w-full"
            id="settingsMembersInviteTabTableControlButtons"
        >
            <Button
                size={"medium"}
                className="rounded-lg whitespace-nowrap font-medium"
                icon={<Icon className="mr-1.5" icon={Icons.PlusFilled} color="fill-white" width={17} height={17} />}
                iconPlacement={"left"}
                onClick={() => {
                    goToInvitePage();
                }}
                id="adminSettingsBtnCreateInvite"
            >
                Создать приглашение
            </Button>
        </div>
    );

    const selectButtons = (
        <div className="flex justify-end items-center space-x-5 grow">
            <PopoverList offset={[0, 10]} visible={isSelectedState} onClickOutside={() => setIsSelectedState(false)}>
                <Button
                    variant="outline"
                    size="medium"
                    color="common"
                    className="border-gray-blue"
                    icon={<Icon className="" icon={Icons.ChevronDown} width={20} height={12} />}
                    iconPlacement="right"
                    onClick={() => setIsSelectedState(true)}
                    id="adminTestsBtnChangeStatus"
                >
                    Изменить статус
                </Button>
                <PopoverList.Item
                    name="setStatusActive"
                    onClickItem={() => onActionHandler("state", { state: ResourceState.ACTIVE })}
                >
                    Активное
                </PopoverList.Item>
                <PopoverList.Item
                    name="setStatusHide"
                    onClickItem={() => onActionHandler("state", { state: ResourceState.INACTIVE })}
                >
                    Неактивное
                </PopoverList.Item>
            </PopoverList>
            <Button
                onClick={() => {
                    onActionHandler("remove");
                }}
                color="danger"
                size="medium"
                id="adminTestsBtnArchive"
            >
                Удалить
            </Button>
        </div>
    );

    const isGotNoData = data.isFetched && data.data?.Total === 0;

    return (
        <>
            <Confirmation {...dialogState} />
            <div
                id="settingsMembersInviteTab"
                className={`h-full ${isGotNoData ? "flex flex-col justify-center" : "pt-6"}`}
            >
                {isGotNoData && (
                    <Empty
                        title="Здесь пока нет приглашений"
                        description="Создайте приглашение, чтобы увидеть его в списке"
                        topElement={
                            <div className="flex-center mb-4">
                                <div className="flex-center w-16 h-16 rounded-full bg-blue-10">
                                    <Icon
                                        icon={Icons.Hyperlink2}
                                        width={"36px"}
                                        height={"36px"}
                                        color={"fill-primary"}
                                    />
                                </div>
                            </div>
                        }
                    >
                        <Button
                            size={"medium"}
                            className="rounded-lg whitespace-nowrap font-medium"
                            icon={
                                <Icon
                                    className="mr-1.5"
                                    icon={Icons.PlusFilled}
                                    color="fill-white"
                                    width={17}
                                    height={17}
                                />
                            }
                            iconPlacement={"left"}
                            onClick={() => {
                                goToInvitePage();
                            }}
                            id="adminSettingsBtnCreateInvite"
                        >
                            Создать приглашение
                        </Button>
                    </Empty>
                )}
                {data.isFetched && data.data?.Total !== 0 && (
                    <>
                        <Table
                            id="settingsMembersInviteTab"
                            columns={columns}
                            data={data.data?.Content}
                            isFetching={data.isFetching}
                            rowSelection={rowSelection}
                            onRowSelectionChange={setRowSelection}
                            onColumnFiltersChange={setColumnFilters}
                            columnFilters={columnFilters}
                            controlButtons={controlButtons}
                            emptyMessage={"По заданным параметрам результатов нет"}
                            sorting={sorting}
                            onSortingChange={setSorting}
                            pageCount={data.data?.TotalPages ?? 0}
                            pagination={pagination}
                            onPaginationChange={setPagination}
                            selectButtons={selectButtons}
                            searchAlwaysLeft={false}
                            defaultSortOrder={{
                                "user_firstName,user.lastName": "asc",
                                state: "asc",
                            }}
                            bodyCellClassName="!static"
                            gotIndividualRowClassName
                            enableRowSelection={isRowSelectable}
                        />
                        <InviteRegistrationMembersModal
                            isOpen={showMembersModal}
                            onClose={() => {
                                setShowMembersModal(false);
                                setMembersCount(0);
                            }}
                            membersCount={membersCount}
                            inviteId={String(invitedUsersInviteId)}
                        />
                    </>
                )}
            </div>
        </>
    );
};
