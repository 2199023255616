import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import InputMask from "react-input-mask";
import { components, MenuProps, SingleValue } from "react-select";
import { Popover } from "@headlessui/react";
import { Section } from "Uikit/Forms/Section";
import {
    Button,
    Checkbox,
    ComboBox,
    Divider,
    flash,
    FormGroup,
    Icon,
    Icons,
    Input,
    Label,
    Tooltip,
    AvatarEditor,
    UserAvatar,
} from "Uikit";
import { numWord } from "helpers/numWord";
import Api from "Api";
import clsx from "clsx";
import { UserRequest } from "Api/Requests/UserRequest";
import { useQuery } from "react-query";
import { RoleName, UIErrorMessages } from "Enums";
import { ParentTeamChoiceModal } from "../Settings/Group/Teams/ParentTeamChoiceModal";
import { TeamMultiSelect } from "Components/Common/TeamMultiSelect";
import { fioCutRegExps, passwordRegexp } from "Uikit/Forms/Input";
import { generateLogin } from "helpers/Login";
import { useCurrentUser } from "hooks/useCurrentUser";
import { ErrorCode } from "Api/BaseResponse";
import { Link } from "react-router-dom";
import { useScreenSize } from "hooks/useMediaQuery";
import { IOption, TVoidFunction } from "types";
import { getFileSizeErrorMessage } from "helpers/file";

const sectionsOptions = [
    {
        value: "programs",
        label: "Программы обучения",
        tip: "При активации доступа к разделу Программы обучения будет предоставлен доступ к зависимым сущностям",
    },
    {
        value: "courses",
        label: "Курсы",
        tip: "При активации доступа к разделу Курсы будет предоставлен доступ к зависимым сущностям",
    },
    {
        value: "tests",
        label: "Тесты",
        tip: null,
    },
    {
        value: "exercise",
        label: "Задания",
        tip: null,
    },
    {
        value: "materials",
        label: "Материалы",
        tip: null,
    },
    {
        value: "userControl",
        label: "Участники",
        tip: null,
    },
    {
        value: "statistic",
        label: "Статистика",
        tip: null,
    },
];
const useMemberRole = (userId?: string, currentUserRole?: RoleName) => {
    const [roleOptions, setRoleOptions] = useState<RoleOption[]>([]);

    const { data: allRoles } = useQuery(["role", "all", "collection"], () => Api.Role.List());

    const { data: permittedRoles } = useQuery(["role", "permitted", "collection"], () =>
        userId ? Api.LMSRoles.getPermittedRoles(userId) : Api.LMSRoles.getPermittedRolesForNewUser(),
    );

    useEffect(() => {
        if (!allRoles?.Content || !permittedRoles || !permittedRoles.Content) {
            return;
        }

        const options: RoleOption[] = [];

        for (const role of permittedRoles.Content) {
            options.push({ label: role.readableName, value: role.name, disabled: false });
        }

        const currentUserRoleIsNotPermitted =
            permittedRoles.Content.findIndex((role) => role.name === currentUserRole) < 0;

        if (currentUserRoleIsNotPermitted) {
            const disallowedRole = allRoles.Content.find((role) => role.name === currentUserRole);

            if (disallowedRole) {
                options.push({ label: disallowedRole.readableName, value: disallowedRole.name, disabled: false });
            }
        }

        setRoleOptions(options.reverse());
    }, [permittedRoles, allRoles, currentUserRole]);

    return useMemo(() => ({ options: roleOptions }), [roleOptions]);
};

interface RoleOption {
    label: string;
    value: string;
    disabled: boolean;
}
interface IMemberForm {
    errors: any;
    user: UserRequest;
    userInitials: string;
    onChangeAvatar: (data: File | null, imgBase64?: string) => void;
    onChange: (course: UserRequest) => void;
    onLoginValidate: TVoidFunction;
    onPhoneValidate: TVoidFunction;
}

export const MemberForm = ({
    errors,
    user,
    userInitials,
    onChangeAvatar,
    onChange,
    onLoginValidate,
    onPhoneValidate,
}: IMemberForm) => {
    const inputRef = useRef(null);
    const currentUser = useCurrentUser();
    const { size } = useScreenSize();
    const isLarge = size === "large";

    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [isSelectTeamModal, setIsSelectTeamModal] = useState<boolean>(false);

    const [avatarEditor, setAvatarEditor] = useState<string | undefined>(undefined);
    const [allTeams, setAllTeams] = useState<boolean | undefined>(false);

    const [jobOptions, setJobOptions] = useState<IOption[]>([]);
    const [officeOptions, setOfficeOptions] = useState<IOption[]>([]);

    const { options: roleOptions } = useMemberRole(user.id, user.role as RoleName);

    const isOptionDisabled = (value: string) => {
        if (value === "courses") {
            return user.roleSections.includes("programs");
        }
        if (value === "tests" || value === "materials") {
            return user.roleSections.includes("courses");
        }

        return false;
    };

    const renderSectionsSelector = () => (
        <Popover>
            {({ open }) => {
                const selectedSectionsCount = user.roleSections.filter(
                    (roleSection) => roleSection !== "admin" && roleSection !== "allTeams",
                ).length;
                const selectedSectionsCountWithoutAdmin = selectedSectionsCount;

                return (
                    <>
                        <Popover.Button
                            className={clsx(
                                `${open ? "" : "text-opacity-90 "}
                                2xl:pl-6 2xl:pr-[15px] 2xl:py-3
                                group inline-flex items-center justify-between gap-2 w-full px-3 py-2 bg-blue-lightest 
                                border-[1px] border-blue-lightest text-base focus:ring-none rounded-md cursor-default 
                                focus:border-primary transition-[border] duration-100 2xl:rounded-lg 2xl:text-md`,
                                errors["roleSections"] && "!border-danger",
                            )}
                            id="adminNewMemberComboBoxSections"
                        >
                            <span>
                                {selectedSectionsCount === 0 && "Выбрано 0 разделов"}
                                {selectedSectionsCount !== 0 && (
                                    <>
                                        {numWord(selectedSectionsCountWithoutAdmin, ["Выбран", "Выбраны", "Выбрано"])}
                                        &nbsp;{selectedSectionsCountWithoutAdmin}
                                        &nbsp;
                                        {numWord(selectedSectionsCountWithoutAdmin, ["раздел", "раздела", "разделов"])}
                                    </>
                                )}
                            </span>

                            <span className="inline-flex items-center space-x-4">
                                {open ? (
                                    <Icon
                                        className="!fill-blue-dark"
                                        icon={Icons.ChevronUp}
                                        width={isLarge ? 14 : 10}
                                        height={isLarge ? 14 : 6}
                                    />
                                ) : (
                                    <Icon
                                        className="!fill-blue-dark"
                                        icon={Icons.ChevronDown}
                                        width={isLarge ? 14 : 10}
                                        height={isLarge ? 14 : 6}
                                    />
                                )}
                                {errors["roleSections"] && (
                                    <span className="inset-y-0 right-1  items-center pr-2 cursor-pointer">
                                        <Tooltip content={errors["roleSections"]}>
                                            <Icon icon={Icons.Alert} color="fill-danger" width="20px" height="20px" />
                                        </Tooltip>
                                    </span>
                                )}
                            </span>
                        </Popover.Button>
                        <Popover.Panel className="popover-panel--command-open-tree absolute left-0 right-0 z-20 mt-3 transform px-4 sm:px-0 w-full bg-white">
                            <div className="min-h-[11rem] p-4 overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                <div className="-mx-1 -mt-1">
                                    {sectionsOptions.map(({ value, label, tip }, index) => {
                                        return (
                                            <div
                                                key={`${value}_${label}`}
                                                className="flex items-center justify-between py-2 px-3 gap-2 rounded-[4px] mb-[2px] last:mb-0 cursor-pointer  hover:bg-gray-background"
                                                id={"adminNewMemberComboBoxSectionsOption" + index}
                                            >
                                                <Checkbox
                                                    className="!w-full"
                                                    name="sections"
                                                    label={label}
                                                    disabled={isOptionDisabled(value)}
                                                    value={value}
                                                    checked={user.roleSections.includes(value)}
                                                    onChange={() => {
                                                        let newSections = user.roleSections;
                                                        let sections = [value];

                                                        if (value === "courses") {
                                                            sections = [
                                                                "courses",
                                                                "tests",
                                                                //"exercise",
                                                                "materials",
                                                            ];
                                                        }

                                                        if (value === "programs") {
                                                            sections = [
                                                                "programs",
                                                                "courses",
                                                                "tests",
                                                                //"exercise",
                                                                "materials",
                                                            ];
                                                        }

                                                        newSections = newSections.filter(
                                                            (item) => sections.indexOf(item) === -1,
                                                        );

                                                        if (!user.roleSections.includes(value)) {
                                                            newSections = newSections.concat(sections);
                                                        }

                                                        onChange({ ...user, roleSections: newSections });
                                                    }}
                                                />
                                                {tip && (
                                                    <Tooltip placement="top" content={<>{tip}</>}>
                                                        <Icon
                                                            icon={Icons.Question}
                                                            color="fill-gray-light"
                                                            width={14}
                                                            height={14}
                                                        />
                                                    </Tooltip>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </Popover.Panel>
                    </>
                );
            }}
        </Popover>
    );
    const onAvatarChange = (event: any) => {
        const files = (event?.target as HTMLInputElement)?.files;

        if (!files || files.length === 0) {
            return;
        }

        if (files.length > 1) {
            flash.error("Выбрано более 1 файла");
            return;
        }

        if (!RegExp(/^image\/(jpg|jpeg|png)/).exec(files[0].type)) {
            flash.error(UIErrorMessages.FILE_EXTENSION_ERROR);
            return;
        }

        if (files[0].size > 2097152) {
            flash.error(getFileSizeErrorMessage("2МБ"));
            return;
        }

        const reader = new FileReader();

        reader.readAsDataURL(files[0]);
        reader.onload = () => {
            // onChangeAvatar(files[0], reader.result!.toString());
            setAvatarEditor(reader.result!.toString());
            event.target.value = "";
        };
    };

    useEffect(() => {
        const fetch = async () => {
            const jobs = await Api.Job.GetJobs();
            const collator = new Intl.Collator("en");
            const specials = ["-", "–", "—", "(", ")", "#", "№", "&", "_", "+", "/"];

            setJobOptions(
                jobs.Content.map((p) => ({ label: p.name, value: p.id! })).sort((a: any, b: any) => {
                    if (specials.includes(a.label[0])) {
                        return -1;
                    } else if (specials.includes(b.label[0])) {
                        return 1;
                    }

                    if (!isNaN(Number(a.label))) {
                        return -1;
                    } else if (!isNaN(Number(b.label))) {
                        return 1;
                    }

                    if (!isNaN(a.label) && !isNaN(b.label)) {
                        const aNum = Number(a.label);
                        const bNum = Number(b.label);
                        if (aNum < bNum) {
                            return -1;
                        } else if (aNum > bNum) {
                            return 1;
                        }
                    }

                    return collator.compare(a.label, b.label);
                }),
            );

            const offices = await Api.Office.List();
            setOfficeOptions([
                // { label: "Выберите офис", value: "" },
                ...offices.Content.map((p) => ({ label: p.name, value: p.id })),
            ]);

            setIsFetched(true);
        };
        fetch().then();
    }, []);

    if (!isFetched) {
        return null;
    }

    const handleFioBlur = (event: any) => {
        const newVal = event.target.value
            .toString()
            .replaceAll(/(^[\s`'—–-]+)/gm, "")
            .replaceAll(/([\s`'—–-]+$)/gm, "")
            .replaceAll("`", "'")
            .replaceAll(/[–—]/gm, "-");

        if (event.target.value.toString() !== newVal) {
            onLoginValidate();
            if ((user as { [key: string]: any })[event.target.name] === newVal) {
                return;
            }

            onChange({ ...user, [event.target.name]: newVal });
        }
    };

    const onLoginBlur = () => {
        onLoginValidate();
    };

    const onLoginChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(/_{2,}/, "_").replace(/\.{2,}/, ".");
        onChange({ ...user, login: value });
    };

    const onFirstNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        const firstName = e.target.value.toLowerCase().replace(/(\s|^|-|')+\S/g, (x: any) => x.toUpperCase());

        onChange({
            ...user,
            firstName,
            login:
                !user.id && user.firstName !== firstName
                    ? generateLogin(firstName, user.lastName).toLowerCase()
                    : user.login ?? "",
        });
    };

    const onLastNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        const lastName = e.target.value.toLowerCase().replace(/(\s|^|-|')+\S/g, (x) => x.toUpperCase());

        onChange({
            ...user,
            lastName,
            login:
                !user.id && user.lastName !== lastName
                    ? generateLogin(user.firstName, lastName).toLowerCase()
                    : user.login ?? "",
        });
    };

    const emailError = (() => {
        const error = errors["email"];

        if (!error) {
            return null;
        }

        if (String(error).startsWith(ErrorCode.EMAIL_EXISTS_IN_COMPANY)) {
            const id = error.split("#")[1];
            return (
                <span>
                    Почта уже используется для <Link to={`/admin/member/${id}`}>пользователя</Link>
                </span>
            );
        }

        return error;
    })();

    return (
        <>
            <ParentTeamChoiceModal
                isOpen={isSelectTeamModal}
                title="Выбор команд"
                isMulti={true}
                onSelect={(selectedTeamIds, all) => {
                    setAllTeams(all);
                    onChange({ ...user, roleTeams: selectedTeamIds });
                    setIsSelectTeamModal(false);
                }}
                onClose={() => setIsSelectTeamModal(false)}
                enableAllToggle
                allValue={allTeams}
            />
            <AvatarEditor
                isOpen={avatarEditor !== undefined}
                type="circle"
                title="Загрузка аватара"
                img={avatarEditor ?? ""}
                onDismiss={() => setAvatarEditor(undefined)}
                onSubmit={(img: string, blob: Blob) => onChangeAvatar(new File([blob], "avatar.jpg"), img)}
            />

            <Section label="Личные данные">
                <div className="group flex space-x-7.5 mb-5.5">
                    <UserAvatar
                        avatarId={user.avatarId}
                        color={user.defaultAvatarColor}
                        userInitials={userInitials}
                        size={112}
                    />
                    <input
                        ref={inputRef}
                        id="logoInput"
                        className="hidden"
                        type="file"
                        accept="image/png, image/jpeg, image/jpg"
                        onChange={onAvatarChange}
                    />
                    <div className="flex">
                        <Button
                            className="my-auto"
                            onClick={() => (inputRef.current as unknown as HTMLInputElement).click()}
                            id="adminNewMemberBtnLoadImg"
                        >
                            Загрузить
                        </Button>
                        <Button
                            variant="outline"
                            color="common"
                            className="border-[#EAEDF3] ml-4 my-auto font-normal"
                            onClick={() => onChangeAvatar(null)}
                            disabled={!user.avatarId}
                            id="adminNewMemberBtnDeleteImg"
                        >
                            Удалить
                        </Button>
                    </div>
                </div>
                <FormGroup>
                    <Label isRequired={true}>Имя</Label>
                    <Input
                        isRequired={true}
                        placeholder="Введите имя"
                        value={user.firstName}
                        onChange={onFirstNameChange}
                        error={errors["firstName"]}
                        name="firstName"
                        maxLength={64}
                        cutRegExp={fioCutRegExps}
                        id="adminNewMemberInputName"
                        onBlur={handleFioBlur}
                    />
                </FormGroup>
                <FormGroup>
                    <Label isRequired={true}>Фамилия</Label>
                    <Input
                        isRequired={true}
                        placeholder="Введите фамилию"
                        value={user.lastName}
                        onChange={onLastNameChange}
                        maxLength={64}
                        error={errors["lastName"]}
                        name="lastName"
                        cutRegExp={fioCutRegExps}
                        id="adminNewMemberInputLastName"
                        onBlur={handleFioBlur}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Отчество</Label>
                    <Input
                        placeholder="Введите отчество"
                        value={user.middleName}
                        onChange={(e) =>
                            onChange({
                                ...user,
                                middleName: e.target.value
                                    ? e.target.value.toLowerCase().replace(/(\s|^|-|')+\S/g, (x) => x.toUpperCase())
                                    : null,
                            })
                        }
                        maxLength={64}
                        error={errors["middleName"]}
                        name="middleName"
                        cutRegExp={fioCutRegExps}
                        id="adminNewMemberInputMiddleName"
                        onBlur={handleFioBlur}
                    />
                </FormGroup>
            </Section>
            <Section label="Данные аккаунта">
                <FormGroup>
                    <Label isRequired={true}>Логин</Label>
                    <Input
                        isRequired={true}
                        placeholder="Введите логин"
                        value={user.login}
                        onChange={onLoginChange}
                        onBlur={onLoginBlur}
                        minLength={3}
                        maxLength={128}
                        error={errors["login"]}
                        disabled={!!user.id}
                        cutRegExp={new RegExp(/[^a-zA-Z0-9_.]/g)}
                        id="adminNewMemberInputlogin"
                    />
                </FormGroup>
                <FormGroup>
                    <Label isRequired={!user.id}>{user.id ? "Новый пароль" : "Пароль"}</Label>
                    <Input
                        isRequired={!user.id}
                        placeholder="Введите пароль"
                        value={user.password}
                        onChange={(e) => onChange({ ...user, password: e.target.value })}
                        minLength={8}
                        maxLength={16}
                        error={errors["password"]}
                        cutRegExp={passwordRegexp}
                        id="adminNewMemberInputPassword"
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Почта</Label>
                    <Input
                        placeholder="Введите вашу почту"
                        value={user.email}
                        onChange={(e) => onChange({ ...user, email: e.target.value ? e.target.value : null })}
                        minLength={5}
                        maxLength={256}
                        error={emailError}
                        // eslint-disable-next-line no-useless-escape
                        cutRegExp={new RegExp(/[^a-zA-Z0-9!#$%&'*+\-\/=?^_`{|}~.@]/g)}
                        id="adminNewMemberInputEmail"
                        tooltipIntercative={true}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Номер телефона</Label>
                    <InputMask
                        mask="+7 999 999-99-99"
                        type="text"
                        placeholder="+7 999 999-99-99"
                        value={user.phone !== null ? user.phone.toString() : ""}
                        onChange={(e) => {
                            if (e.type === "focus" || e.type === "blur") {
                                return;
                            }
                            onChange({ ...user, phone: e.target.value });
                        }}
                        beforeMaskedStateChange={({ nextState, previousState }) => {
                            return nextState.value.includes("_") && nextState.selection?.end === 16
                                ? previousState ?? nextState
                                : nextState;
                        }}
                        onBlur={() => {
                            const phoneLength = user.phone?.replace(/^[+7]+/g, "").replace(/[^\d]+/g, "").length;
                            if (!phoneLength) {
                                onChange({
                                    ...user,
                                    phone: null,
                                });
                                return;
                            }
                            onPhoneValidate();
                        }}
                    >
                        <Input
                            id="adminNewMemberInputTel"
                            className="!text-black"
                            isRequired={true}
                            error={errors["phone"]}
                            tooltipIntercative={typeof errors["phone"] !== "string"}
                        />
                    </InputMask>
                </FormGroup>
            </Section>
            <Section label="Рабочие данные">
                {/*<FormGroup>*/}
                {/*    <Label isRequired>Состоит в командах</Label>*/}
                {/*    <ComboBox*/}
                {/*        isMulti*/}
                {/*        isCreatable*/}
                {/*        menuPlacement="auto"*/}
                {/*        placeholder="Выберите команду"*/}
                {/*        options={teamOptions}*/}
                {/*        value={teamOptions.filter((p) => user.teamIds.includes(p.value))}*/}
                {/*        onChange={(options: MultiValue<any>) =>*/}
                {/*            onChange({ ...user, teamIds: options.map((p) => p.value) })*/}
                {/*        }*/}
                {/*        error={errors["teamIds"]}*/}
                {/*    />*/}
                {/*</FormGroup>*/}
                <FormGroup>
                    <Label isRequired>Должность</Label>
                    <ComboBox
                        placeholder="Выберите должность"
                        options={jobOptions}
                        value={jobOptions[jobOptions.findIndex((p) => user.jobTitleId === p.value)]}
                        onChange={(option: SingleValue<any>) => onChange({ ...user, jobTitleId: option?.value || "" })}
                        error={errors["jobTitleId"]}
                        id="adminNewMemberComboBoxJob"
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Офис</Label>
                    <ComboBox
                        placeholder="Выберите офис"
                        options={officeOptions}
                        isClearable
                        value={
                            user.officeId
                                ? officeOptions[officeOptions.findIndex((p) => user.officeId === p.value)]
                                : null
                        }
                        onChange={(option: SingleValue<any>) => {
                            onChange({ ...user, officeId: option?.value || undefined });
                        }}
                        id="adminNewMemberComboBoxOffice"
                    />
                </FormGroup>
            </Section>
            <Section label="Роли">
                <FormGroup>
                    <Label isRequired={true}>Роль в системе</Label>
                    <ComboBox
                        className="mb-4"
                        placeholder="Выберите роль"
                        options={roleOptions}
                        value={roleOptions.find((option) => user.role === option.value)}
                        onChange={(option: SingleValue<any>) => onChange({ ...user, role: option.value })}
                        isOptionDisabled={(option) => option.disabled}
                        error={errors["roleId"]}
                        components={{
                            Menu: (props: MenuProps<any, false>) => {
                                return (
                                    <components.Menu<any, false, { options: readonly any[] }> {...props}>
                                        <div className="flex flex-col gap-1 px-1">{props.children}</div>
                                    </components.Menu>
                                );
                            },
                            Option: ({ innerProps, innerRef, label, isSelected }: any) => {
                                return (
                                    <div
                                        ref={innerRef}
                                        className={clsx(
                                            "flex items-center justify-between py-2 px-3 gap-2 rounded-[4px] " +
                                                "mb-[2px] last:mb-0 cursor-pointer  hover:bg-gray-background",
                                            isSelected && "!bg-gray-blue",
                                        )}
                                        {...innerProps}
                                    >
                                        <span>{label}</span>
                                        {/* <Tooltip placement="top" content={<>Всплывающая подсказка</>}>
                                            <Icon
                                                icon={Icons.Question}
                                                color="fill-gray-light"
                                                width="14px"
                                                height="14px"
                                            />
                                        </Tooltip> */}
                                    </div>
                                );
                            },
                        }}
                        id="adminNewMemberComboBoxRole"
                        isSearchable={false}
                    />
                </FormGroup>
                {user.role === RoleName.ADMIN && (
                    <>
                        <Divider />
                        <FormGroup className="relative">
                            <Label>Видимость команд в системе</Label>
                            <TeamMultiSelect
                                value={user.roleTeams}
                                onChange={(teamIds: any, allTeams) => {
                                    onChange({
                                        ...user,
                                        allTeams,
                                        roleTeams: teamIds.filter((p: string) => p.indexOf("root:") === -1),
                                    });
                                }}
                                id="adminNewMemberComboBoxMentor"
                                enableAllToggle
                                requireEachTeamChoice
                                showTeams
                                stacked
                                styles={{ ValueContainer: { width: 664 } }}
                                allValue={user.allTeams ?? false}
                                allPlaceholder={user.allTeams ?? false}
                                optionsPlaceholder={(count) =>
                                    `${count === 1 ? "Выбрана" : "Выбрано"} ${count} ${numWord(count, [
                                        "команда",
                                        "команды",
                                        "команд",
                                    ])}`
                                }
                                hideMembers={currentUser?.data?.role === RoleName.ADMIN}
                            />
                        </FormGroup>
                        <FormGroup className="relative">
                            <Label isRequired>Доступ к разделам</Label>
                            {renderSectionsSelector()}
                        </FormGroup>
                    </>
                )}
            </Section>
            <Section label="Наставничество">
                <FormGroup>
                    <Label>Команды, наставником которых является пользователь</Label>
                    <TeamMultiSelect
                        value={user.mentorTeamIds}
                        onChange={(teamIds: any) => {
                            onChange({
                                ...user,
                                mentorTeamIds: teamIds.filter((p: string) => p.indexOf("root:") === -1),
                            });
                        }}
                        stacked
                        id="adminNewMemberComboBoxMentor"
                        hideMembers={currentUser?.data?.role === RoleName.ADMIN}
                        disabledTeamCheckDisabled={true}
                    />
                </FormGroup>
            </Section>
            {/*<Section label="Настройки уведомлений">*/}
            {/*    <FormGroup className="flex flex-col">*/}
            {/*        <Toggle*/}
            {/*            className="mr-auto mb-4"*/}
            {/*            enabled={user.enabledNotifications.length !== 0}*/}
            {/*            onChange={(p) => onChange({ ...user, enabledNotifications: p ? ["EMAIL"] : [] })}*/}
            {/*            label="Разрешить Email-уведомления"*/}
            {/*        />*/}
            {/*        <p className="text-gray-text">*/}
            {/*            Уведомления о действиях будут приходить на указанный в вашем профиле Email адрес {user.email}*/}
            {/*        </p>*/}
            {/*    </FormGroup>*/}
            {/*</Section>*/}
            {/*<Section label="Дополнительные настройки">*/}
            {/*    <FormGroup className="flex items-center">*/}
            {/*        <Toggle*/}
            {/*            className="mr-auto"*/}
            {/*            enabled={user.profileSettings.includes("DISABLE_COMMENTS")}*/}
            {/*            onChange={(enable) =>*/}
            {/*                onChange({*/}
            {/*                    ...user,*/}
            {/*                    profileSettings: enable*/}
            {/*                        ? [...user.profileSettings, "DISABLE_COMMENTS"]*/}
            {/*                        : user.profileSettings.filter((test) => test !== "DISABLE_COMMENTS"),*/}
            {/*                })*/}
            {/*            }*/}
            {/*            label="Запретить комментировать и оставлять отзывы"*/}
            {/*        />*/}
            {/*    </FormGroup>*/}
            {/*</Section>*/}
        </>
    );
};
