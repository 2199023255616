import React, { useEffect, useState } from "react";
import { ContentLayout } from "Containers/ContentLayout";
import { Breadcrumbs, Content, Icon, Tabs, TabsWrapper } from "Uikit";
import { FILES_TYPE_ICONS } from "constants/attachments";
import { MembersImportPage } from "./MembersImportPage";
import NotificationConnect, { NotificationSubscribe } from "Api/Wss/Notifications";
import { useQuery } from "react-query";
import Api from "Api";
import { NotificationActionType } from "Enums";
import { UserImportTypes } from "Enums";
import { WssMessage } from "types/WssMessage";

export const MembersImport = () => {
    const [isImportAvailable, setIsImportAvailable] = useState(true);
    const [userIdImporting, setUserIdImporting] = useState<string | null>(null);

    const [currentTab, setCurrentTab] = useState(0);

    const { data: currentUser } = useQuery(["users", "current"], () => Api.User.GetCurrentUser());

    useEffect(() => {
        const checkImportStatus = async () => {
            const response = await Api.User.UsersImportStatus();

            setIsImportAvailable(!response.isImportInProgress);
            setUserIdImporting(response.userId);
        };

        checkImportStatus();
    }, []);

    useEffect(() => {
        NotificationConnect();
        NotificationSubscribe(async (message: WssMessage) => {
            if (message.body.actionType === NotificationActionType.USER_IMPORT_STARTED) {
                setIsImportAvailable(false);
                setUserIdImporting(message.body.data.userid);
            }
            if (message.body.actionType === NotificationActionType.USER_IMPORT_FINISHED) {
                setIsImportAvailable(true);
                setUserIdImporting(null);
            }
        });
    }, []);

    return (
        <ContentLayout className="h-full">
            <Breadcrumbs id="adminMembersImportBreadcrumbs">
                <Breadcrumbs.Link title="Администратор" />
                <Breadcrumbs.Link title="Участники" url="/admin/members" />
                <Breadcrumbs.Link title="Импорт" />
            </Breadcrumbs>
            <div className="w-[700px] mx-auto mt-1">
                <div className="flex justify-between items-center">
                    <h1>Импорт участников</h1>
                    <a
                        className="flex items-center cursor-pointer text-xs"
                        href={`${process.env.PUBLIC_URL}/assets/${
                            currentTab === 0 ? "import_polzovateley.xlsx" : "obnovlenie_polzovateley.xlsx"
                        }`}
                        download={
                            currentTab === 0
                                ? "Импорт_добавление_пользователей.xlsx"
                                : "Импорт_обновление_пользователей.xlsx"
                        }
                    >
                        <Icon className="mr-2" icon={FILES_TYPE_ICONS.xlsx} width="18px" height="18px" />
                        {currentTab === 0
                            ? "Импорт_добавление_пользователей.xlsx"
                            : "Импорт_обновление_пользователей.xlsx"}
                    </a>
                </div>
                <TabsWrapper className="mt-7.5" selectedIndex={currentTab} onChange={setCurrentTab}>
                    <TabsWrapper.Tabs
                        classname="h-max space-x-4 flex-grow !border-0 rounded-lg p-1 bg-background-light"
                        childrenWrapperClassName="flex gap-1.5"
                    >
                        <Tabs.Tab
                            title="Добавить новых"
                            className={"rounded-md flex-initial basis-[50%] !py-2 !px-2.5 !m-0"}
                            selectedClassName={"!border-0 bg-gray-blue"}
                        />
                        <Tabs.Tab
                            title="Обновить существующих"
                            className={"rounded-md flex-initial basis-[50%] !py-2 !px-2.5 !m-0"}
                            selectedClassName={"!border-0 bg-gray-blue"}
                        />
                    </TabsWrapper.Tabs>
                    <TabsWrapper.Content>
                        <Content.Body className="mt-6.25">
                            <MembersImportPage
                                isImportAvailable={isImportAvailable}
                                isCurrentUserImporting={currentUser?.id === userIdImporting}
                                type={UserImportTypes.CREATE}
                            />
                        </Content.Body>
                        <Content.Body className="mt-6.25">
                            <MembersImportPage
                                isImportAvailable={isImportAvailable}
                                isCurrentUserImporting={currentUser?.id === userIdImporting}
                                type={UserImportTypes.UPDATE}
                            />
                        </Content.Body>
                    </TabsWrapper.Content>
                </TabsWrapper>
            </div>
        </ContentLayout>
    );
};
