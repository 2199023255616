import { UserImportTypes } from "Enums";
import React from "react";

interface IImportRules {
    type: UserImportTypes;
}

export const ImportRules = ({ type }: IImportRules) => {
    return (
        <div>
            <h2>Правила заполнения полей</h2>
            {type === UserImportTypes.CREATE && (
                <>
                    <div className="mt-6.5">
                        <p className="text-sm text-gray mb-1">1. Аватар</p>
                        <p className="text-sm">Необязательное для заполнения поле.</p>
                        <p className="text-sm">Доступные форматы: jpg, jpeg, png.</p>
                        <p className="text-sm">Размер: до 2 МБ.</p>
                    </div>
                    <div className="mt-6.5">
                        <p className="text-sm text-gray mb-1">
                            2. Имя, Фамилия<span className="text-red">*</span>
                        </p>
                        <p className="text-sm">Обязательное для заполнения поле.</p>
                        <p className="text-sm">{`Допустимые символы: русские и английские буквы, дефис ( - ), пробел, апостроф ( ' ).`}</p>
                        <p className="text-sm">Максимальная длина: до 64 символов в каждом поле.</p>
                    </div>
                    <div className="mt-6.5">
                        <p className="text-sm text-gray mb-1">3. Отчество</p>
                        <p className="text-sm">Необязательное для заполнения поле.</p>
                        <p className="text-sm">{`Допустимые символы: русские и английские буквы, дефис ( - ), пробел, апостроф ( ' ).`}</p>
                        <p className="text-sm">Максимальная длина: до 64 символов в каждом поле.</p>
                    </div>
                    <div className="mt-6.5">
                        <p className="text-sm text-gray mb-1">
                            4. Команда<span className="text-red">*</span>
                        </p>
                        <p className="text-sm">Обязательное для заполнения поле.</p>
                        <p className="text-sm">При заполнении:</p>
                        <p className="text-sm">a. Допустимо указывать только команды, уже добавленные в систему;</p>
                        <p className="text-sm">
                            b. Обязательно указывать по одной команде в рамках каждой корневой команды (указание
                            корневой допустимо). Например, если в системе две корневые команды, то указать надо всего
                            две команды, по одной в рамках каждой корневой команды;
                        </p>
                        <p className="text-sm">c. Разделитель команд – запятая.</p>
                    </div>
                    <div className="mt-6.5">
                        <p className="text-sm text-gray mb-1">5. Логин</p>
                        <p className="text-sm">
                            Необязательное для заполнения поле (если логин не указан, то он будет сгенерирован
                            автоматически).
                        </p>
                        <p className="text-sm">Допустимые символы: английские буквы, цифры, нижнее подчеркивание.</p>
                        <p className="text-sm">Допустимая длина: от 3 до 128 символов.</p>
                    </div>
                    <div className="mt-6.5">
                        <p className="text-sm text-gray mb-1">6. Пароль</p>
                        <p className="text-sm">
                            Необязательное для заполнения поле (если пароль не указан, то он будет сгенерирован
                            автоматически).
                        </p>
                        <p className="text-sm">{`Допустимые символы: английские буквы, верхний и нижний регистр, цифры, символы ., - " ' () «»!@#№$%^&*_+=?;: | \\ /~ > < [ ] { }`}</p>
                        <p className="text-sm">
                            Должен содержать как минимум одну прописную букву, одну заглавную букву и одну цифру.
                        </p>
                        <p className="text-sm">Допустимая длина: от 8 до 16 символов.</p>
                    </div>
                    <div className="mt-6.5">
                        <p className="text-sm text-gray mb-1">7. Почта</p>
                        <p className="text-sm">Необязательное для заполнения поле.</p>
                        <p className="text-sm">Обязательное использование символа @.</p>
                        <p className="text-sm">{`Допустимые символы и длина до @: английские буквы, цифры, символы ! # $ % & ' * + - / = ? ^ _ \` { | } ~ . длина от 2 до 63 символов.`}</p>
                        <p className="text-sm">
                            Допустимые символы после @: английские буквы, цифры, символы - . длина от 3 до 253 символов.
                        </p>
                    </div>
                    <div className="mt-6.5">
                        <p className="text-sm text-gray mb-1">8. Номер телефона</p>
                        <p className="text-sm">Необязательное для заполнения поле.</p>
                        <p className="text-sm">Допустимые символы: цифры</p>
                        <p className="text-sm">Ограничение длины: 11 символов.</p>
                    </div>
                    <div className="mt-6.5">
                        <p className="text-sm text-gray mb-1">
                            9. Должность<span className="text-red">*</span>
                        </p>
                        <p className="text-sm">Обязательное для заполнения поле.</p>
                        <p className="text-sm">Допустимо указывать только должность, уже добавленную в систему.</p>
                    </div>
                    <div className="mt-6.5">
                        <p className="text-sm text-gray mb-1">10. Офис</p>
                        <p className="text-sm">Необязательное для заполнения поле.</p>
                        <p className="text-sm">Допустимо указывать только офис, уже добавленный в систему.</p>
                    </div>
                    <div className="mt-6.5">
                        <p className="text-sm text-gray mb-1">11. Роль</p>
                        <p className="text-sm">Обязательное для заполнения поле.</p>
                        <p className="text-sm">
                            Допустимые значения: участник, администратор, суперадминистратор (роль загружающего файл
                            пользователя должна быть выше указанной в этом поле роли).
                        </p>
                    </div>
                    <div className="mt-6.5">
                        <p className="text-sm text-gray mb-1">12. Администратор – Видимость команд в системе</p>
                        <p className="text-sm">Необязательное для заполнения поле.</p>
                        <p className="text-sm">При заполнении:</p>
                        <p className="text-sm">a. допустимо указывать только команды, уже добавленные в систему;</p>
                        <p className="text-sm">
                            b. обязательно указывать как минимум по одной команде в каждой корневой команде (можно
                            больше);
                        </p>
                        <p className="text-sm">c. разделитель команд – запятая.</p>
                    </div>
                    <div className="mt-6.5">
                        <p className="text-sm text-gray mb-1">13. Администратор – Видимость разделов</p>
                        <p className="text-sm">{`Обязательное для заполнения поле, если в поле "Роль" указан "Администратор".`}</p>
                        <p className="text-sm">Разделы перечисляются через запятую.</p>
                        <p className="text-sm">
                            Внимание! Некоторые разделы автоматически открывают доступ к связанным разделам. Подробное
                            описание в Пользовательской инструкции.
                        </p>
                    </div>
                    <div className="mt-6.5 mb-6.5">
                        <p className="text-sm text-gray mb-1">14. Наставничество</p>
                        <p className="text-sm">Необязательное для заполнения поле.</p>
                        <p className="text-sm">Указываются команды, наставником которых будет пользователь.</p>
                        <p className="text-sm">Допустимо указывать только команды, уже добавленные в систему.</p>
                        <p className="text-sm">Разделитель команд – запятая.</p>
                    </div>
                </>
            )}
            {type === UserImportTypes.UPDATE && (
                <>
                    <div className="mt-6.5">
                        <p className="text-sm text-gray mb-1">1. Аватар</p>
                        <p className="text-sm">Необязательное для заполнения поле.</p>
                        <p className="text-sm">Доступные форматы: jpg, jpeg, png.</p>
                        <p className="text-sm">Размер: до 2 МБ.</p>
                    </div>
                    <div className="mt-6.5">
                        <p className="text-sm text-gray mb-1">2. Имя, Фамилия</p>
                        <p className="text-sm">Необязательное для заполнения поле.</p>
                        <p className="text-sm">{`Допустимые символы: русские и английские буквы, дефис ( - ), пробел, апостроф ( ' ).`}</p>
                        <p className="text-sm">Максимальная длина: до 64 символов в каждом поле.</p>
                    </div>
                    <div className="mt-6.5">
                        <p className="text-sm text-gray mb-1">3. Отчество</p>
                        <p className="text-sm">Необязательное для заполнения поле.</p>
                        <p className="text-sm">{`Допустимые символы: русские и английские буквы, дефис ( - ), пробел, апостроф ( ' ).`}</p>
                        <p className="text-sm">Максимальная длина: до 64 символов в каждом поле.</p>
                    </div>
                    <div className="mt-6.5">
                        <p className="text-sm text-gray mb-1">4. Команда</p>
                        <p className="text-sm">Необязательное для заполнения поле.</p>
                        <p className="text-sm">При заполнении:</p>
                        <p className="text-sm">a. Допустимо указывать только команды, уже добавленные в систему;</p>
                        <p className="text-sm">
                            b. Обязательно указывать по одной команде в рамках каждой корневой команды (указание
                            корневой допустимо). Например, если в системе две корневые команды, то указать надо всего
                            две команды, по одной в рамках каждой корневой команды;
                        </p>
                        <p className="text-sm">c. Разделитель команд – запятая.</p>
                    </div>
                    <div className="mt-6.5">
                        <p className="text-sm text-gray mb-1">
                            5. Логин<span className="text-red">*</span>
                        </p>
                        <p className="text-sm">
                            Обязательное для заполнения поле (если логин не указан, то он будет сгенерирован
                            автоматически).
                        </p>
                        <p className="text-sm">Допустимые символы: английские буквы, цифры, нижнее подчеркивание.</p>
                        <p className="text-sm">Допустимая длина: от 3 до 128 символов.</p>
                    </div>
                    <div className="mt-6.5">
                        <p className="text-sm text-gray mb-1">6. Пароль</p>
                        <p className="text-sm">
                            Необязательное для заполнения поле (если пароль не указан, то он будет сгенерирован
                            автоматически).
                        </p>
                        <p className="text-sm">{`Допустимые символы: английские буквы, верхний и нижний регистр, цифры, символы ., - " ' () «»!@#№$%^&*_+=?;: | \\ /~ > < [ ] { }`}</p>
                        <p className="text-sm">
                            Должен содержать как минимум одну прописную букву, одну заглавную букву и одну цифру.
                        </p>
                        <p className="text-sm">Допустимая длина: от 8 до 16 символов.</p>
                    </div>
                    <div className="mt-6.5">
                        <p className="text-sm text-gray mb-1">7. Почта</p>
                        <p className="text-sm">Необязательное для заполнения поле.</p>
                        <p className="text-sm">Обязательное использование символа @.</p>
                        <p className="text-sm">{`Допустимые символы и длина до @: английские буквы, цифры, символы ! # $ % & ' * + - / = ? ^ _ \` { | } ~ . длина от 2 до 63 символов.`}</p>
                        <p className="text-sm">
                            Допустимые символы после @: английские буквы, цифры, символы - . длина от 3 до 253 символов.
                        </p>
                    </div>
                    <div className="mt-6.5">
                        <p className="text-sm text-gray mb-1">8. Номер телефона</p>
                        <p className="text-sm">Необязательное для заполнения поле.</p>
                        <p className="text-sm">Допустимые символы: цифры</p>
                        <p className="text-sm">Ограничение длины: 11 символов.</p>
                    </div>
                    <div className="mt-6.5">
                        <p className="text-sm text-gray mb-1">9. Должность</p>
                        <p className="text-sm">Необязательное для заполнения поле.</p>
                        <p className="text-sm">Допустимо указывать только должность, уже добавленную в систему.</p>
                    </div>
                    <div className="mt-6.5">
                        <p className="text-sm text-gray mb-1">10. Офис</p>
                        <p className="text-sm">Необязательное для заполнения поле.</p>
                        <p className="text-sm">Допустимо указывать только офис, уже добавленный в систему.</p>
                    </div>
                    <div className="mt-6.5">
                        <p className="text-sm text-gray mb-1">11. Роль</p>
                        <p className="text-sm">Необязательное для заполнения поле.</p>
                        <p className="text-sm">
                            Допустимые значения: участник, администратор, суперадминистратор (роль загружающего файл
                            пользователя должна быть выше указанной в этом поле роли).
                        </p>
                    </div>
                    <div className="mt-6.5">
                        <p className="text-sm text-gray mb-1">12. Администратор – Видимость команд в системе</p>
                        <p className="text-sm">Необязательное для заполнения поле.</p>
                        <p className="text-sm">При заполнении:</p>
                        <p className="text-sm">a. допустимо указывать только команды, уже добавленные в систему;</p>
                        <p className="text-sm">
                            b. обязательно указывать как минимум по одной команде в каждой корневой команде (можно
                            больше);
                        </p>
                        <p className="text-sm">c. разделитель команд – запятая.</p>
                    </div>
                    <div className="mt-6.5">
                        <p className="text-sm text-gray mb-1">13. Администратор – Видимость разделов</p>
                        <p className="text-sm">{`Обязательное для заполнения поле, если в поле "Роль" указан "Администратор".`}</p>
                        <p className="text-sm">Разделы перечисляются через запятую.</p>
                        <p className="text-sm">
                            Внимание! Некоторые разделы автоматически открывают доступ к связанным разделам. Подробное
                            описание в Пользовательской инструкции.
                        </p>
                    </div>
                    <div className="mt-6.5 mb-6.5">
                        <p className="text-sm text-gray mb-1">14. Наставничество</p>
                        <p className="text-sm">Необязательное для заполнения поле.</p>
                        <p className="text-sm">Указываются команды, наставником которых будет пользователь.</p>
                        <p className="text-sm">Допустимо указывать только команды, уже добавленные в систему.</p>
                        <p className="text-sm">Разделитель команд – запятая.</p>
                    </div>
                </>
            )}
        </div>
    );
};
