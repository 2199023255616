import React from "react";
import { Breadcrumbs, Content, Tabs, TabsWrapper } from "Uikit";
import { TasksWithoutCourse } from "./TasksWithoutCourse";

export const Tasks = () => {
    return (
        <div className="px-6 w-full h-full sm:overflow-y-scroll custom-scrollbar">
            <Breadcrumbs className="mb-1">
                <Breadcrumbs.Link title="Администратор" />
                <Breadcrumbs.Link title="Статистика" />
                <Breadcrumbs.Link title="Задания" />
                <Breadcrumbs.Link title="Отчет по заданиям" />
            </Breadcrumbs>
            <div>
                <h1>Отчет по заданиям</h1>
                <TabsWrapper className="mt-5 h-fit">
                    <TabsWrapper.Tabs
                        classname="h-max space-x-4 flex-grow !border-0 rounded-lg p-1 bg-background-light"
                        childrenWrapperClassName="flex gap-1.5"
                    >
                        <Tabs.Tab
                            title="По заданиям вне курса"
                            className={"rounded-md flex-initial basis-[50%] !py-2 !px-2.5 !m-0"}
                            selectedClassName={"!border-0 bg-gray-blue"}
                        />
                        <Tabs.Tab
                            title="По заданиям в составе курса"
                            className={"rounded-md flex-initial basis-[50%] !py-2 !px-2.5 !m-0"}
                            selectedClassName={"!border-0 bg-gray-blue"}
                            disabled
                        />
                    </TabsWrapper.Tabs>
                    <TabsWrapper.Content>
                        <Content.Body className="mt-5 h-fit">
                            <TasksWithoutCourse />
                        </Content.Body>
                        <Content.Body className="mt-5 h-fit"></Content.Body>
                    </TabsWrapper.Content>
                </TabsWrapper>
            </div>
        </div>
    );
};
