import BaseApi from "../BaseApi";
import { BasePaginationRequest, BaseIdRequest, BaseRequest } from "../BaseRequest";
import { BasePaginationResponse, BaseArrayResponse, BaseResponse } from "../BaseResponse";
import {
    TaskPublicListResponse,
    TaskPublicNewCountResponse,
    TaskPublicPreviewResponse,
    TaskPublicPassingResponse,
    TaskPublicStatisticsResponse,
    TaskPublicVerificatedResponse,
    TaskPublicVerificatedReadResponse,
    TaskPublicReviewResponse,
} from "../Responses/TaskPublicResponse";
import {
    TaskPublicUserAnswersRequest,
    TaskReviewOperationMultipleRequest,
    TaskReviewOperationRequest,
    TaskReviewRequest,
} from "../Requests/TaskPublicRequest";
import { LMSFilterParams } from "models/LMSFilterParams";

const exerciseUser = "/exercise-user";
const exerciseUserAnswer = "/exercise-user-answer";

export default class TaskPublicApi extends BaseApi {
    List = (filter: { [id: string]: string }): Promise<BaseArrayResponse<TaskPublicListResponse>> => {
        const params = LMSFilterParams({ filter });
        const response = new BaseArrayResponse(TaskPublicListResponse);
        return this.Get(response, `${exerciseUser}/all?${params.toString()}`);
    };

    AvailableList = (): Promise<BaseArrayResponse<TaskPublicListResponse>> => {
        const params = LMSFilterParams();
        const response = new BaseArrayResponse(TaskPublicListResponse);
        return this.Get(response, `${exerciseUser}/all/available?${params.toString()}`);
    };

    ReviewList = async (): Promise<BaseArrayResponse<TaskPublicListResponse>> => {
        const params = LMSFilterParams();
        const response = new BaseArrayResponse(TaskPublicListResponse);
        return await this.Get(response, `${exerciseUser}/all/review?${params.toString()}`);
    };

    ResultsList = ({
        filters,
        page,
        size,
        sort,
    }: BasePaginationRequest): Promise<BasePaginationResponse<TaskPublicListResponse>> => {
        const params = LMSFilterParams({ filter: filters as any, page, size });
        params.append("sort", sort ?? "");

        return this.Get(
            new BasePaginationResponse(TaskPublicListResponse),
            `${exerciseUser}/all/approved?${params.toString()}`,
        );
    };

    NewCount = async (): Promise<TaskPublicNewCountResponse> => {
        return await this.Get(new TaskPublicNewCountResponse(), `${exerciseUser}/new/count/tabs`);
    };

    // Превью задания - /task/{id}
    Preview = async (data: BaseIdRequest & { progressId?: string | null }): Promise<TaskPublicPreviewResponse> => {
        const { uuid, progressId } = data;
        const progress = progressId ? `progressId=${progressId}` : "";
        const params = [progress].filter((param) => param).join("&");

        return await this.Get(TaskPublicPreviewResponse, `${exerciseUser}/${uuid}/info?${params}`);
    };

    // Прохождение задания - /task/{id}/passing
    Passing = async (data: BaseIdRequest): Promise<TaskPublicPassingResponse> => {
        return await this.Get(TaskPublicPassingResponse, `${exerciseUserAnswer}/${data.uuid}`);
    };

    // Запрос на старт задания - /admin - "Задания"
    StartExercise = async (payload: BaseRequest & { id: string }): Promise<BaseResponse> => {
        return await this.Post(BaseResponse, `${exerciseUserAnswer}/start`, payload);
    };

    // Отправка задания на проверку - /task/{id}/passing
    SendAnswers = async (data: TaskPublicUserAnswersRequest): Promise<BaseResponse> => {
        return await this.Post(BaseResponse, `${exerciseUserAnswer}`, data);
    };

    // Сохранение прогресса прохождения задания - /task/{id}/passing
    SaveAnswersProgress = async (data: TaskPublicUserAnswersRequest): Promise<BaseResponse> => {
        return await this.Put(BaseResponse, `${exerciseUserAnswer}`, data);
    };

    // Статистика задания - /task/{id}/statistics
    Statistics = async (data: BaseIdRequest): Promise<TaskPublicStatisticsResponse> => {
        return await this.Get(TaskPublicStatisticsResponse, `${exerciseUser}/${data.uuid}/statistics`);
    };

    // Проверка задания - /admin - "Задания"
    VerificatedList = ({
        filters,
        page,
        size,
        sort,
    }: BasePaginationRequest): Promise<BasePaginationResponse<TaskPublicVerificatedResponse>> => {
        const params = LMSFilterParams({ filter: filters as any, page, size });
        params.append("sort", sort ?? "");
        return this.Get(
            new BasePaginationResponse(TaskPublicVerificatedResponse),
            `${exerciseUserAnswer}/review/all?${params.toString()}`,
        );
    };

    // Проверка задания - /admin - "Задания"
    VerificatedRead = async (data: BaseIdRequest): Promise<TaskPublicVerificatedReadResponse> => {
        return await this.Get(TaskPublicVerificatedReadResponse, `${exerciseUserAnswer}/${data.uuid}`);
    };

    // Пропуск задания админом
    ReviewOperationMultiple = async (payload: TaskReviewOperationMultipleRequest): Promise<BaseResponse> => {
        return await this.Post(BaseResponse, `${exerciseUserAnswer}/review-multiple`, payload);
    };

    // Пропуск задания админом
    ReviewSkip = async (payload: TaskReviewRequest): Promise<TaskPublicReviewResponse> => {
        return await this.Post(TaskPublicReviewResponse, `${exerciseUserAnswer}/review/skip`, payload);
    };

    // Сохранение прогресса проверки задания админом
    ReviewSave = async (payload: TaskReviewRequest): Promise<BaseResponse> => {
        return await this.Put(BaseResponse, `${exerciseUserAnswer}/review`, payload);
    };

    // Принятие/отклонение задания админом
    ReviewOperation = async (payload: TaskReviewOperationRequest): Promise<TaskPublicReviewResponse> => {
        return await this.Post(TaskPublicReviewResponse, `${exerciseUserAnswer}/review`, payload);
    };
}
