import React, { useCallback, useEffect, useRef, useState, useContext, Fragment } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";

import { HtmlViewer } from "Uikit/HtmlViewer/HtmlViewer";
import { Button, FileViewDialog, Icon, Icons, Tooltip /* , flash */ } from "Uikit";
import { Loader } from "Uikit/Loader/Loader";
import { FileReadResponse } from "Api/Responses/FileResponse";
import Api from "Api";
import { setBackUrl, setIsBack, setIsFavorite, setIsHidden, setTitle } from "slices/headerSlice";
import { setCurrentCourse, setCurrentMaterial } from "slices/programSlice";
import { CourseReadResponseSectionItem } from "Api/Responses/CourseResponse";
import { GlobalContext } from "App";
import { ProgressStatus, ResourceType /* , WssMessageType */ } from "Enums";
// import { WssMessage } from "types/WssMessage";
import { IReducer } from "store";
import { useNavigateToSource } from "hooks/useNavigateToSouce";
// import NotificationConnect, { NotificationSubscribe } from "Api/Wss/Notifications";

import { formatLeadTime } from "../Tasks/utils";
import { CourseMaterialFile } from "./CourseMaterialFile";
import { CourseMaterialScorm } from "./CourseMaterialScorm";
import { CourseMaterialVideo } from "./CourseMaterialVideo";
import {
    useChangeMaterialFavoriteStatus,
    useCompleteMaterial,
    useGetUICourse,
    useGetUICourseMaterial,
    useGetUICourseProgress,
    useStartMaterial,
} from "./Course.hooks";

// const getFilePreview = async (fileId: string, successCallback: (res: string) => void) => {
//     const res = await Api.Preview.getFilePreview(fileId);

//     if (res.status === "SUCCESS" && res.contentType === "text/html") {
//         successCallback(res.preview);
//     }
// };

export const CourseMaterial = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const isFavoriteSelected = useSelector((state: IReducer) => state.header.isFavoriteSelected);

    const {
        programId = "",
        courseId = "",
        componentId = "",
        resourceId = "",
    } = useParams<{ programId: string; courseId: string; componentId: string; resourceId: string }>();
    const { goSourcePage } = useNavigateToSource(
        programId ? `/training/program/${programId}/${courseId}` : `/training/course/${courseId}`,
    );
    const { setIsFullScreenViewEnabled } = useContext(GlobalContext);

    const iframeRef = useRef<any>(null);
    const interval = useRef<any>();

    const [loaded, setLoaded] = useState(false);
    const [documentUrl, setDocumentUrl] = useState("");
    // const [preview, setPreview] = useState("");

    const [selectedFile, setSelectedFile] = useState<any>(null);
    const [isViewFileModal, setIsViewFileModal] = useState(false);

    const [isVideoViewed, setIsVideoViewed] = useState(false);
    const [isVideoProcessed, setIsVideoProcessed] = useState(false);

    const { data: course, refetch } = useGetUICourse(courseId, { enabled: false });
    const { data: courseProgress } = useGetUICourseProgress(courseId);
    const { data: material, isLoading } = useGetUICourseMaterial(resourceId);

    const { mutate: completeMaterial } = useCompleteMaterial();
    const { mutate: startMaterial } = useStartMaterial();
    const { mutate: changeFavoriteStatus } = useChangeMaterialFavoriteStatus();

    const currentComponent = findComponentById(componentId);
    const isLastComponent = course?.sections.at(-1)?.components.at(-1)?.id === currentComponent?.id;

    const findNextComponentById = useCallback(
        (componentId: string): CourseReadResponseSectionItem | null => {
            let nextComponent: CourseReadResponseSectionItem | null = null;
            let found = false;

            course?.sections.some((section) => {
                return section.components.some((component) => {
                    if (found) {
                        nextComponent = component;
                        return true;
                    }

                    if (component.id === componentId) {
                        found = true;
                    }

                    return false;
                });
            });

            return nextComponent;
        },
        [course],
    );

    function findComponentById(componentId: string): CourseReadResponseSectionItem | null {
        let foundComponent: CourseReadResponseSectionItem | null = null;

        course?.sections.some((section) => {
            const component = section.components.find((component) => component.id === componentId);
            if (component) {
                foundComponent = component;
                return true;
            }
            return false;
        });

        return foundComponent;
    }

    const clearCheckingInterval = () => {
        clearInterval(interval.current);
    };

    const onIframeLoaded = useCallback(() => {
        clearCheckingInterval();
        setLoaded(true);
    }, []);

    const fetchDisposableLink = async (fileId: string) => {
        // await getFilePreview(fileId, (result: string) => {
        //     setPreview(result);
        // });
        try {
            const res = await Api.File.DisposableLink(fileId);

            setDocumentUrl(`https://docs.google.com/gview?url=${res?.disposableLink}&embedded=true`);
        } catch (error) {
            console.log(error);
        }
    };

    const onFavoriteChange = useCallback(async () => {
        changeFavoriteStatus({
            courseId,
            componentId,
            resourceId,
            materialType: material!.type,
            fileType: material?.file?.extension?.toUpperCase(),
        });
    }, [courseId, componentId, resourceId, changeFavoriteStatus, material]);

    const onMaterialStart = useCallback(async () => {
        if (!course || !courseProgress) {
            return;
        }

        startMaterial({ courseId, componentId });
    }, [courseId, componentId, course, courseProgress, startMaterial]);

    const isFavorite = useCallback(() => {
        const queryParams = new URLSearchParams(location.search);
        return queryParams.get("favorite") === "true";
    }, [location.search]);

    const goToNextMaterial = useCallback(async () => {
        const nextComponent = findNextComponentById(componentId);
        navigate(
            programId
                ? `/training/program/${programId}/${course?.resourceId}/${nextComponent?.id}/${nextComponent?.resourceId}`
                : `/training/course/${course?.resourceId}/${nextComponent?.id}/${nextComponent?.resourceId}`,
            { replace: true },
        );
    }, [findNextComponentById, componentId, navigate, programId, course?.resourceId]);

    const onNextMaterial = useCallback(async () => {
        if (!course || !courseProgress) {
            return;
        }

        if (currentComponent?.progressStatus === ProgressStatus.PASSED && isFavorite()) {
            navigate("/favorite", { replace: true });
            return;
        }

        // Если завершается ранее пройденный курс и проходятся его материалы
        if (course?.progressStatus === ProgressStatus.PASSED && isLastComponent) {
            navigate(
                programId
                    ? `/training/program/${programId}/${course?.resourceId}`
                    : `/training/course/${course?.resourceId}`,
                { replace: true },
            );
            return;
        }

        if (course?.progressStatus === ProgressStatus.PASSED && !isLastComponent) {
            await goToNextMaterial();
        } else {
            const timeSpent = Math.round(
                Math.floor(Date.now() / 1000) -
                    course.startTime -
                    (courseProgress.completionTime - courseProgress.startTime),
            );

            completeMaterial({ programId, courseId, componentId, timeSpent });
        }
    }, [
        programId,
        courseId,
        componentId,
        course,
        courseProgress,
        completeMaterial,
        isLastComponent,
        goToNextMaterial,
        navigate,
        isFavorite,
        currentComponent?.progressStatus,
    ]);

    const onClose = () => {
        navigate(programId ? `/training/program/${programId}/${courseId}` : `/training/course/${courseId}`, {
            replace: true,
        });
    };
    const prepareContent = (content: string) => {
        return content
            .replaceAll("<table", "<div style='width: 100%; overflow: auto;'><table class='view-table'")
            .replaceAll("</table>", "</table></div>");
    };

    const onDurationVideoChange = useCallback((duration: number, currentTime: number) => {
        if (currentTime / duration > 0.95) {
            setIsVideoViewed(true);
        }
    }, []);

    const getButtonNextTitle = () => {
        if (currentComponent?.progressStatus === ProgressStatus.PASSED && isFavorite()) {
            return "Вернуться в избранное";
        }

        if (isLastComponent) {
            return "Завершить курс";
        } else {
            return "Следующий материал";
        }
    };

    useEffect(() => {
        // NotificationConnect();
        // NotificationSubscribe(async (message: WssMessage) => {
        //     const { messageType } = message;
        //     if (messageType === WssMessageType.FILE_PREVIEW_READY && material?.file?.id) {
        //         await getFilePreview(material.file.id, (result: string) => {
        //             setPreview(result);
        //         });
        //     }
        //     if (messageType === WssMessageType.FILE_PREVIEW_FAILED) {
        //         flash.error("Произошла ошибка при обработке файла");
        //     }
        // });
        if (material?.type !== ResourceType.DOCUMENT) {
            return;
        }

        interval.current = setInterval(() => {
            try {
                // google docs page is blank (204), hence we need to reload the iframe.
                if (iframeRef.current.contentWindow.document.body.innerHTML === "") {
                    iframeRef.current.src = documentUrl;
                }
            } catch (e) {
                // google docs page is being loaded, but will throw CORS error.
                // it mean that the page won't be blank and we can remove the checking interval.
                onIframeLoaded();
            }
        }, 4000); // 4000ms is reasonable time to load 2MB document
        // eslint-disable-next-line react-hooks/exhaustive-deps
        // }, []);
        return clearCheckingInterval;
    }, [documentUrl, onIframeLoaded, material?.type]);

    useEffect(() => {
        if (!material?.file?.id || material?.file.contentType.includes("image") || material?.type === "SCORM") {
            return;
        }

        const { id, contentType: type, size, name, extension } = material.file;

        fetchDisposableLink(id).then();

        setSelectedFile({
            type,
            id,
            size,
            name,
            extension,
            disposableLink: `/service/lms-upload/api/file/download/${id}`,
        });
    }, [material?.file, material?.type]);

    useEffect(() => {
        dispatch(setIsHidden(false));
        dispatch(setIsBack(true));
        dispatch(setBackUrl(programId ? `/training/program/${programId}/${courseId}` : `/training/course/${courseId}`));

        if (material?.type === ResourceType.ARTICLE) {
            dispatch(setTitle("Статья"));
        } else if (material?.type === ResourceType.DOCUMENT) {
            dispatch(setTitle("Документ"));
        } else if (material?.type === ResourceType.VIDEO) {
            dispatch(setTitle("Видео"));
        } else if (material?.type === ResourceType.SCORM) {
            dispatch(setTitle(ResourceType.SCORM));
        } else {
            dispatch(setTitle(" "));
        }

        dispatch(setIsFavorite(true));
        dispatch(
            setCurrentCourse({
                id: course?.resourceId ?? "",
                title: course?.title ?? "",
            }),
        );
        dispatch(
            setCurrentMaterial({
                id: material?.id ?? "",
                title: material?.title ?? "",
            }),
        );
    }, [dispatch, material, courseId, programId, course?.resourceId, course?.title]);
    useEffect(() => {
        if (window.screen.width > 568 || !material || isFavoriteSelected === material.isFavorite) {
            return;
        }

        onFavoriteChange().then();
    }, [isFavoriteSelected, material, onFavoriteChange]);

    // useEffect(() => {
    //     if (preview) {
    //         setLoaded(true);
    //         iframeRef.current.contentWindow.document.write(preview);
    //     }
    // }, [preview]);

    const isButtonNextDisabled =
        material?.type === ResourceType.VIDEO &&
        ((!material.allowRewind &&
            !isVideoViewed &&
            ((!isFavorite() && currentComponent?.progressStatus !== ProgressStatus.PASSED) ||
                (isFavorite() && currentComponent?.progressStatus !== ProgressStatus.PASSED))) ||
            !isVideoProcessed);

    return (
        <div className="flex flex-col min-h-full flex-grow items-center">
            <div
                className={clsx(
                    "w-full flex flex-col flex-grow items-center",
                    material && material.type !== ResourceType.SCORM && "pb-72 2xl:pb-[368px]",
                )}
            >
                {!isLoading && material && (
                    <>
                        {material.type !== ResourceType.SCORM && (
                            <>
                                {!isViewFileModal && (
                                    <div
                                        className="hidden sm:flex fixed top-[83px] right-[26px] w-10 h-10 group flex-center bg-white border border-gray-stroke rounded-lg cursor-pointer hover:bg-blue-lightest transition-all"
                                        onClick={onClose}
                                        id="userMaterialCloseBtn"
                                    >
                                        <Icon
                                            icon={Icons.Close}
                                            width={16}
                                            height={16}
                                            className="!fill-blue-drk group-hover:!fill-blue transition-all 2xl:!w-6.25 2xl:!h-6.25"
                                        />
                                    </div>
                                )}
                                <div
                                    className="flex w-full max-w-175 2xl:max-w-[875px] pb-1 2xl:pb-1.25 justify-between items-center"
                                    id="userMaterialHeader"
                                >
                                    <div className="text-gray p4 2xl:text-xs">
                                        {formatLeadTime(material.approxCompletionMinutes * 60)}
                                    </div>
                                    <div
                                        className={clsx(
                                            "sm:relative sm:top-0 sm:right-0 group hidden sm:flex justify-center items-center w-6 2xl:w-7.5 h-6 2xl:h-7.5 rounded-full transition-all",
                                            material.isFavorite ? "hover:bg-gray-blue" : "hover:bg-blue-60",
                                        )}
                                        onClick={onFavoriteChange}
                                    >
                                        {material.isFavorite ? (
                                            <Icon
                                                icon={Icons.SaveFilled}
                                                width={18}
                                                height={24}
                                                color="fill-gray-dark group-hover:fill-black transition-all"
                                                className="cursor-pointer 2xl:!w-6.25 2xl:!h-6.25"
                                            />
                                        ) : (
                                            <Icon
                                                icon={Icons.Save}
                                                width={18}
                                                height={24}
                                                color="fill-black-50 group-hover:fill-white transition-all"
                                                className="cursor-pointer 2xl:!w-6.25 2xl:!h-6.25"
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="w-full max-w-175 2xl:max-w-[875px] pb-6 2xl:pb-7.5">
                                    <h1
                                        className="2xl:!text-4xl 2xl:!leading-[41px] break-anywhere"
                                        id="userMaterialTitle"
                                    >
                                        {material.title}
                                    </h1>
                                    <div
                                        className="p2 mt-3 2xl:mt-3.75 text-gray 2xl:text-md break-anywhere"
                                        id="userMaterialDescription"
                                    >
                                        {material.description}
                                    </div>
                                </div>
                            </>
                        )}
                        {material.type !== ResourceType.SCORM && (
                            <div className="w-full border-t border-gray-blue pb-6 2xl:pb-7.5"></div>
                        )}
                        {material.type === "ARTICLE" && (
                            <>
                                {material && (
                                    <>
                                        {material.isTraining ? (
                                            <Button onClick={() => window.open("/trainer")}>Перейти в тренажер</Button>
                                        ) : (
                                            <HtmlViewer
                                                body={material.content ? prepareContent(material.content) : ""}
                                            />
                                        )}
                                    </>
                                )}

                                <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-4 2xl:gap-5 max-w-175 2xl:max-w-[875px] pt-6 sm:pt-7 2xl:pt-8.75 items-center">
                                    {material.attachedFiles.map((attachment: any) => {
                                        const id = attachment?.serverData?.id ?? attachment?.file?.id ?? attachment?.id;
                                        const name =
                                            attachment?.name ?? attachment?.file?.name ?? attachment?.serverData?.name;
                                        const disposableLink =
                                            attachment?.disposableLink ?? attachment?.serverData?.disposableLink;
                                        const fileType =
                                            attachment?.contentType ??
                                            attachment?.file?.type ??
                                            attachment?.serverData?.contentType;
                                        const extension =
                                            attachment?.extension ??
                                            attachment?.file?.extension ??
                                            attachment?.serverData?.extension;

                                        return (
                                            <Fragment key={id}>
                                                <CourseMaterialFile
                                                    id={id}
                                                    onClick={() => {
                                                        setSelectedFile({
                                                            type: fileType,
                                                            id,
                                                            size: attachment.size,
                                                            name,
                                                            extension,
                                                            disposableLink,
                                                        });
                                                        setIsViewFileModal(true);
                                                        setIsFullScreenViewEnabled(true);
                                                    }}
                                                    extension={extension}
                                                    fileName={name}
                                                    size={attachment.size}
                                                    status={attachment.status}
                                                    fileType={fileType}
                                                />
                                            </Fragment>
                                        );
                                    })}
                                </div>
                                {isViewFileModal && (
                                    <FileViewDialog
                                        file={selectedFile as FileReadResponse & { type: string; data: string }}
                                        handleClose={() => {
                                            setIsViewFileModal(false);
                                            setIsFullScreenViewEnabled(false);
                                        }}
                                    />
                                )}
                            </>
                        )}
                        {material.type === ResourceType.VIDEO && (
                            <div className="w-full max-w-250 2xl:max-w-[1250px]">
                                <CourseMaterialVideo
                                    watchedTime={material.playback.playbackTimeInSeconds}
                                    materialId={material.id}
                                    fileId={material.file.id}
                                    rewind={material.allowRewind}
                                    onDurationVideoChange={onDurationVideoChange}
                                    onVideoProcessed={setIsVideoProcessed}
                                />
                            </div>
                        )}
                        {material.type === ResourceType.SCORM && course && (
                            <div className="w-full">
                                <CourseMaterialScorm
                                    course={course}
                                    material={material}
                                    component={currentComponent}
                                    onMaterialStart={onMaterialStart}
                                    onNextMaterial={onNextMaterial}
                                    isLastComponent={isLastComponent}
                                    onClose={() => {
                                        refetch();
                                        goSourcePage();
                                    }}
                                />
                            </div>
                        )}
                        {material.type === ResourceType.DOCUMENT && (
                            <>
                                <div className="w-full max-w-250 2xl:max-w-[1250px]">
                                    <div className="relative group flex justify-center items-center w-full h-160 2xl:h-200 bg-gray-blue shadow-lg rounded-lg 2xl:rounded-2lg overflow-hidden">
                                        {!material.file.contentType.includes("image") ? (
                                            <div className="w-full h-full relative">
                                                {loaded && (
                                                    <div className="absolute right-3 top-3 bg-[#343434] flex-center 2xl:rounded-lg">
                                                        <Button
                                                            shape="round"
                                                            color="common"
                                                            icon={
                                                                <Icon
                                                                    icon={Icons.FullScreen}
                                                                    width="18px"
                                                                    height="18px"
                                                                    color="fill-white"
                                                                    className="2xl:!w-7.5 2xl:!h-7.5"
                                                                />
                                                            }
                                                            iconPlacement="center"
                                                            onClick={() => {
                                                                setIsViewFileModal(true);
                                                                setIsFullScreenViewEnabled(true);
                                                            }}
                                                            className="hover:bg-[#676767] !rounded-none 2xl:w-12.5 2xl:h-12.5 2xl:!rounded-lg"
                                                        />
                                                    </div>
                                                )}
                                                <iframe
                                                    ref={iframeRef}
                                                    // className={`w-full h-full ${loaded ? "bg-white" : ""}`}
                                                    className="w-full h-full"
                                                    src={documentUrl}
                                                    onLoad={onIframeLoaded}
                                                    onError={() => {}}
                                                />
                                            </div>
                                        ) : (
                                            <img
                                                className="rounded-lg object-cover max-w-200 2xl:max-w-[1068px]"
                                                src={"/service/lms-upload/api/file/download/" + material.file.id}
                                                onLoad={onIframeLoaded}
                                            />
                                        )}
                                        {!loaded && (
                                            <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
                                                <Loader />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {isViewFileModal && (
                                    <FileViewDialog
                                        file={selectedFile as FileReadResponse & { type: string; data: string }}
                                        handleClose={() => {
                                            setIsViewFileModal(false);
                                            setIsFullScreenViewEnabled(false);
                                        }}
                                        // closeButtonClassName={"!right-0"}
                                    />
                                )}
                            </>
                        )}
                    </>
                )}
                {isLoading && (
                    <div className="absolute inset-0 z-10 flex flex-center justify-center bg-white">
                        <Loader />
                    </div>
                )}
            </div>
            {!isLoading && material?.type !== ResourceType.SCORM && (
                <div className="absolute bottom-0 left-0 right-0 flex flex-center w-full bg-background py-16 sm:py-22.5 px-3 sm:px-0 2xl:h-[308px]">
                    <Tooltip
                        content={
                            !isVideoProcessed
                                ? "Чтобы пройти дальше, нужно дождаться завершения обработки видео"
                                : isLastComponent
                                ? "Чтобы завершить курс, нужно посмотреть видео"
                                : "Чтобы перейти к следующему материалу, нужно посмотреть видео"
                        }
                        disabled={!isButtonNextDisabled}
                    >
                        <Button
                            className={clsx(
                                "w-full text-center px-14 py-5 sm:!px-5.5 sm:!py-2.5 2xl:!px-7 2xl:!py-3 sm:text-md 2xl:!text-lg sm:w-[272px] 2xl:w-[340px] sm:!h-[66px] 2xl:!h-[82px] sm:!rounded-xxl 2xl:!rounded-2.5xl",
                                isButtonNextDisabled ? "!bg-gray-stroke" : "",
                            )}
                            size="xl"
                            onClick={onNextMaterial}
                            disabled={isButtonNextDisabled}
                            id="userMaterialFinishBtn"
                        >
                            {getButtonNextTitle()}
                        </Button>
                    </Tooltip>
                </div>
            )}
        </div>
    );
};
