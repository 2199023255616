import React, { useMemo, useState } from "react";
import { ColumnDef, ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { useQuery } from "react-query";
import Api from "Api";
import { Button, Icon, Icons, Table } from "Uikit";
import { RowActionPopover } from "../RowActionPopover";
import { Empty } from "Uikit/Page/Empty";
import { OfficeModal } from "../Modal/OfficeModal";
import { OfficeResponse } from "Api/Responses/OfficeResponse";
import { FilterLink } from "Components/Common/FilterLink";
import { numWord } from "helpers/numWord";

export const OfficesTab = () => {
    const [rowSelection, setRowSelection] = useState({});
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);

    const [controlOfficeModalOpen, setControlOfficeModalOpen] = useState(false);
    const [selectedOffice, setSelectedOffice] = useState<any>();
    const [controlOfficeModalType, setControlOfficeModalType] = useState<"add" | "edit" | "remove">("add");

    const [sorting, setSorting] = useState<SortingState>([{ id: "name", desc: false }]);
    const [search, setSearch] = useState<string>("");

    const [{ pageIndex, pageSize }] = React.useState<PaginationState>({
        pageIndex: 0,
        pageSize: 15,
    });
    const pagination = useMemo(() => ({ pageIndex, pageSize }), [pageIndex, pageSize]);

    const data = useQuery(
        ["offices", "collection", pagination, sorting, search],
        () => {
            const filtersData: any = {};

            if (search) {
                filtersData["searchQuery.contains"] = search;
            }

            return Api.Office.ListPaginated(pageIndex, pageSize, sorting, filtersData);
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );

    const officesColumns = React.useMemo<ColumnDef<OfficeResponse>[]>(
        () => [
            {
                header: "офис",
                footer: (props) => props.column.id,
                enableResizing: true,
                size: 200,
                cell: (office) => {
                    return (
                        <div className="group flex items-center space-x-3">
                            <span className="group-hover:text-blue">{office.row.original.name}</span>
                        </div>
                    );
                },
                accessorKey: "name",
            },
            {
                header: "адрес",
                accessorKey: "address",
                cell: (office) => {
                    const { country, region, address } = office.row.original;
                    return (
                        <div className="flex items-center space-x-3">
                            <span>{`${country}, ${region}, ${address}`}</span>
                        </div>
                    );
                },
                footer: (props) => props.column.id,
            },
            {
                header: "кол-во участников",
                accessorKey: "userCount",
                enableResizing: true,
                size: 200,
                cell: ({ row: { original: office } }) => {
                    return (
                        <div className="flex items-center space-x-3">
                            <FilterLink
                                href={`/admin/members?office.in=${office.id}`}
                                value={office.userList.length}
                                name={`${office.userList.length} ${numWord(office.userList.length, [
                                    "участник",
                                    "участника",
                                    "участников",
                                ])}`}
                            />
                        </div>
                    );
                },
                footer: (props) => props.column.id,
            },
            {
                header: "",
                id: "buttons",
                accessor: "[row identifier to be passed to button]",
                enableResizing: true,
                size: 90,
                cell: (info) => {
                    return (
                        <div className="flex" id={"memberSettingsOfficeTableGroupButton" + info.row.id}>
                            <Button
                                shape={"round"}
                                color={"common"}
                                icon={
                                    <Icon
                                        icon={Icons.Pencil}
                                        width="18px"
                                        height="18px"
                                        color="fill-blue-drk hover:fill-blue-hover"
                                    />
                                }
                                iconPlacement={"center"}
                                onClick={() => {
                                    setSelectedOffice(info.row.original);
                                    setControlOfficeModalType("edit");
                                    setControlOfficeModalOpen(true);
                                }}
                            />

                            <RowActionPopover
                                id="settingsMembersOfficeTabPopover"
                                onDelete={() => {
                                    setSelectedOffice(info.row.original);
                                    setControlOfficeModalType("remove");
                                    setControlOfficeModalOpen(true);
                                }}
                            />
                        </div>
                    );
                },
            },
        ],
        [],
    );

    const handleOfficeModalClose = () => {
        setControlOfficeModalOpen(false);
    };

    const officesControlButtons = (
        <div className="flex justify-end items-center space-x-5" id="settingsMembersOfficeTabTableControlButtons">
            <Button
                size={"medium"}
                className="rounded-lg whitespace-nowrap font-medium"
                icon={<Icon className="mr-1.5" icon={Icons.PlusFilled} color="fill-white" width={17} height={17} />}
                iconPlacement={"left"}
                onClick={() => {
                    setControlOfficeModalType("add");
                    setSelectedOffice(null);
                    setControlOfficeModalOpen(true);
                }}
                id="adminSettingsBtnCreateOffice"
            >
                Создать офис
            </Button>
        </div>
    );

    return (
        <div id="settingsMembersOfficeTab" className="pt-6 h-full">
            {data.isFetched && !search && data.data?.Total === 0 && (
                <div className="flex justify-center items-center h-full">
                    <Empty
                        title="Офисы не созданы"
                        description={
                            <span>
                                Создайте офисы и распределите в них участников. Это расширит
                                <br />
                                возможности поиска
                            </span>
                        }
                    >
                        <Button
                            size={"medium"}
                            className="rounded-lg whitespace-nowrap font-medium"
                            icon={
                                <Icon
                                    className="mr-1.5"
                                    icon={Icons.PlusFilled}
                                    color="fill-white"
                                    width={17}
                                    height={17}
                                />
                            }
                            iconPlacement={"left"}
                            onClick={() => {
                                setControlOfficeModalType("add");
                                setSelectedOffice(null);
                                setControlOfficeModalOpen(true);
                            }}
                            id="adminSettingsBtnCreateOffice"
                        >
                            Создать офис
                        </Button>
                    </Empty>
                </div>
            )}
            {(search || (data.isFetched && data.data?.Total !== 0)) && (
                <Table
                    id="settingsMembersOfficeTab"
                    columns={officesColumns}
                    searchTitle="Поиск по названию"
                    data={data.data?.Content}
                    isFetching={data.isFetching}
                    rowSelection={rowSelection}
                    onRowSelectionChange={setRowSelection}
                    onColumnFiltersChange={setColumnFilters}
                    columnFilters={columnFilters}
                    controlButtons={officesControlButtons}
                    emptyMessage={"По заданным параметрам результатов нет"}
                    sorting={sorting}
                    onSortingChange={setSorting}
                    onSearch={setSearch}
                />
            )}
            <OfficeModal
                isOpen={controlOfficeModalOpen}
                onClose={handleOfficeModalClose}
                selectedOffice={selectedOffice}
                type={controlOfficeModalType}
                id="settingsMembersOfficeTabModal"
            />
        </div>
    );
};
