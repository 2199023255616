import { Icon, Icons } from "Uikit/index";
import React, { useMemo, useState } from "react";

interface VolumeProps {
    volume: number;
    onVolumeUpdate: (value: number) => void;
}

export const Volume = ({ volume, onVolumeUpdate }: VolumeProps) => {
    const [isMute, setIsMute] = useState(false);
    const volumePercentage = useMemo(() => volume * 100, [volume]);

    const calcClickedVolume = (e: any): number => {
        const clickPositionInPage = e.pageX;
        const bar = document.getElementById("volume-bar");

        if (!bar) {
            return 0;
        }

        const barStart = bar.getBoundingClientRect().left + window.scrollX;
        const barWidth = bar.offsetWidth;

        let clickPositionInBar;

        if (clickPositionInPage - barStart > barWidth) {
            clickPositionInBar = barWidth;
        } else if (clickPositionInPage - barStart < 0) {
            clickPositionInBar = 0;
        } else {
            clickPositionInBar = clickPositionInPage - barStart;
        }

        const volumePerPixel = 100 / barWidth;
        return (volumePerPixel * clickPositionInBar) / 100;
    };

    const handleVolumeDrag = (e: any) => {
        if (isMute) {
            setIsMute(false);
        }

        onVolumeUpdate(calcClickedVolume(e.type === "touchstart" ? e.nativeEvent.touches[0] : e));

        const updateVolumeOnMove = (eMove: any) => {
            onVolumeUpdate(calcClickedVolume(eMove.type === "touchmove" ? eMove.touches[0] : eMove));
        };

        document.addEventListener("mousemove", updateVolumeOnMove);
        document.addEventListener("touchmove", updateVolumeOnMove);
        document.addEventListener("mouseup", () => {
            document.removeEventListener("mousemove", updateVolumeOnMove);
        });
        document.addEventListener("touchend", () => {
            document.removeEventListener("touchmove", updateVolumeOnMove);
        });
    };

    function mute() {
        if (navigator.maxTouchPoints !== 0) {
            return;
        }

        setIsMute((prevState) => {
            onVolumeUpdate(Number(prevState));

            return !prevState;
        });
    }

    return (
        <div className="select-none flex items-center">
            <div className="flex shrink-0 items-center cursor-pointer w-4.5 max-w-20 pr-1.5 overflow-hidden hover:w-20 transition-all">
                <div className="shrink-0" onClick={mute} onTouchStart={(e) => handleVolumeDrag(e)}>
                    <Icon
                        icon={isMute || volumePercentage === 0 ? Icons.VolumeOff : Icons.VolumeOn}
                        width={20}
                        height={20}
                        color="fill-white"
                    />
                </div>
                <div
                    id="volume-bar"
                    className="ml-2 flex-1 flex items-center rounded-md h-1 cursor-pointer"
                    style={{
                        background: `linear-gradient(to right, #1280CE ${volumePercentage}%, #1280CE 0, #EAEDF3  ${volumePercentage}%, #EAEDF3 100%)`,
                    }}
                    onMouseDown={(e) => handleVolumeDrag(e)}
                    onTouchStart={(e) => handleVolumeDrag(e)}
                >
                    <span
                        className="relative h-3 w-3 border border-gray-dark rounded-full bg-white shadow-md"
                        style={{ left: `calc(${volumePercentage}% - 6px)` }}
                    />
                </div>
            </div>
        </div>
    );
};
