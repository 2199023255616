import React, { useCallback, useEffect, useState, useLayoutEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ContentLayout } from "Containers";
import { Breadcrumbs, Button, flash, Icon, Icons } from "Uikit";
import Api from "Api";
import { useNavigateToSource } from "hooks/useNavigateToSouce";
import { TestsCardTime } from "../Tests/TestsCardTime";
import { formatCheckTime, formatDeadlineTime } from "../Tasks/utils";
import { UserAvatar } from "Uikit/UserAvatar/UserAvatar";
import clsx from "clsx";
import {
    getSourcePage,
    setBackUrl,
    setIsBack,
    setIsFavorite,
    setIsFavoriteSelected,
    setIsHidden,
    setTitle,
} from "slices/headerSlice";
import { setCurrentCourse, setCurrentMaterial } from "slices/programSlice";
import { useDispatch, useSelector } from "react-redux";
import { useGetUICourse } from "../Course/Course.hooks";
import Skeleton from "react-loading-skeleton";
import { Preloader } from "Components/Preloader/Preloader";
import { FavoriteButton } from "Uikit/FavoriteButton/FavoriteButton";
import { CommonsAddFavoritesRequest, CommonsRemoveFavoritesRequest } from "Api/Requests/CommonsRequest";
import { CourseReadResponseSectionItem } from "Api/Responses/CourseResponse";
import { ProgressStatus, ResourceState, ResourceType } from "Enums";
import { IReducer } from "store";
import { useQuery } from "react-query";
import { LogoSize } from "Api/Services/UploadApi";
import { NoAccess } from "Components/Stub/NoAccess";
import { BadRequestResponse, ErrorCode } from "Api/BaseResponse";

export const Test = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const backURL = useSelector(getSourcePage);
    const isFavoriteSelected = useSelector((state: IReducer) => state.header.isFavoriteSelected);

    const { goSourcePage, navigateWithCurrentSource } = useNavigateToSource("/tests");

    const {
        programId = "",
        courseId = "",
        testId = "",
    } = useParams<{ programId: string; courseId: string; testId: string }>();
    const [buttonTitle, setButtonTitle] = useState("Начать тест");
    const [isProgressStatusChecked, setIsProgressStatusChecked] = useState(false);
    const searchParams = new URL(window.location.href).searchParams;
    const componentId = searchParams?.get("componentId");

    const { data: course } = useGetUICourse(courseId || "");
    const {
        data: test,
        isFetched,
        error,
    } = useQuery(
        ["test", "collection", testId],
        async () => {
            const test = await Api.Test.UserRead(testId);
            dispatch(setIsFavoriteSelected(test.isFavorite));

            const isLastComponent = course?.sections.at(-1)?.components.at(-1)?.resourceId === test?.resourceId;
            setButtonTitle(
                course?.progressStatus === ProgressStatus.PASSED && test?.progressStatus === ProgressStatus.PASSED
                    ? isLastComponent
                        ? "Завершить курс"
                        : "Следующий материал"
                    : "Начать тест",
            );

            return test;
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );

    const onStart = async () => {
        try {
            if (course?.progressStatus === ProgressStatus.PASSED && test?.progressStatus === ProgressStatus.PASSED) {
                const nextComponent = findNextComponentById(test?.resourceId);
                if (nextComponent) {
                    navigate(`${backURL}/${nextComponent.id}/${nextComponent.resourceId}`);
                    return;
                } else {
                    navigate(backURL);
                }
            }
            await Api.Test.UserStart(test!.progressId);
            localStorage.removeItem("testingCourseId");
            localStorage.removeItem("testingCourseProgramId");

            if (courseId) {
                localStorage.setItem("testingCourseId", courseId);
            }
            if (programId) {
                localStorage.setItem("testingCourseProgramId", programId);
            }

            navigateWithCurrentSource("/testing");
        } catch {
            flash.error("Вы не можете начать проходить этот тест");
        }
    };
    const onFavoriteChange = useCallback(
        async (isFavorite: boolean) => {
            try {
                if (isFavorite) {
                    await Api.Commons.addFavorites(
                        Object.assign(new CommonsAddFavoritesRequest(), {
                            resourceId: test?.resourceId,
                            resourceSolutionId: test?.progressId,
                            title: test?.title,
                            logoId: test?.logoId,
                            state: ResourceState.ACTIVE,
                            type: ResourceType.QUIZ,
                            progressStatus: test?.progressStatus,
                            ratingPoints: test?.ratingPoints,
                            deadlineTimestamp: test?.deadlineTime ?? 0,
                            courseId,
                            componentId,
                        }),
                    );
                } else if (test?.isFavorite) {
                    await Api.Commons.removeFavorites(
                        Object.assign(new CommonsRemoveFavoritesRequest(), {
                            resourceId: test.resourceId,
                            type: ResourceType.QUIZ,
                        }),
                    );
                }

                dispatch(setIsFavoriteSelected(isFavorite));

                if (test) {
                    test.isFavorite = isFavorite;
                }
                // setTest((prevState) => {
                //     if (prevState) {
                //         return {
                //             ...prevState,
                //             isFavorite,
                //         };
                //     }
                //
                //     return null;
                // });
            } catch (error) {
                console.log(error);
            }
        },
        [dispatch, test, courseId, componentId],
    );
    const handleClose = () => {
        if (courseId) {
            dispatch(
                setCurrentMaterial({
                    id: "",
                    title: "",
                }),
            );
            navigate(backURL);
        } else {
            goSourcePage();
        }
    };
    const findNextComponentById = useCallback(
        (componentId: string): CourseReadResponseSectionItem | null => {
            let nextComponent: CourseReadResponseSectionItem | null = null;
            let found = false;
            course?.sections.some((section) => {
                return section.components.some((component, index, components) => {
                    if (found) {
                        nextComponent = component;
                        return true;
                    }

                    if (component.resourceId === componentId) {
                        if (index < components.length - 1) {
                            found = true;
                        } else {
                            return true;
                        }
                    }
                    return false;
                });
            });

            return nextComponent;
        },
        [course],
    );

    useEffect(() => {
        dispatch(setIsHidden(false));
        dispatch(setIsBack(true));
        dispatch(setTitle("Тест"));
        dispatch(setIsFavorite(true));
        dispatch(
            setBackUrl(
                programId
                    ? `/training/program/${programId}/${courseId}`
                    : courseId
                    ? `/training/course/${courseId}`
                    : "",
            ),
        );
        dispatch(
            setCurrentCourse({
                id: course?.resourceId ?? "",
                title: course?.title ?? "",
            }),
        );
        dispatch(
            setCurrentMaterial({
                id: test?.resourceId ?? "",
                title: test?.title ?? "",
            }),
        );
    }, [course, courseId, dispatch, programId, test]);

    useLayoutEffect(() => {
        if (
            test &&
            [ProgressStatus.FAILED, ProgressStatus.PASSED, ProgressStatus.ON_REVIEW].includes(
                test.progressStatus as ProgressStatus,
            )
        ) {
            navigate(`/test/${testId}/statistics`);
        } else {
            setIsProgressStatusChecked(true);
        }
    }, [test, testId, navigate]);

    useEffect(() => {
        if (window.screen.width > 568 || !test || isFavoriteSelected === test.isFavorite) {
            return;
        }

        onFavoriteChange(isFavoriteSelected).then();
    }, [isFavoriteSelected, test, onFavoriteChange]);

    const isNoPermissionError =
        error && error instanceof BadRequestResponse && error.errorCode === ErrorCode.NO_RESOURCE_ACCESS;
    const isNoTestError = error && error instanceof BadRequestResponse && error.errorCode === ErrorCode.QUIZ_NOT_FOUND;

    const showNoAccess = (isFetched && isNoPermissionError) as boolean;
    const showNoTest = (isFetched && isNoTestError) as boolean;

    if (!testId) {
        goSourcePage();
        return null;
    }

    return (
        <ContentLayout
            className={`mx-auto sm:pl-4 sm:pr-6.5 sm:max-w-[1216px] 2xl:max-w-[1506px] ${
                showNoAccess || showNoTest ? "h-full" : ""
            }`}
        >
            <Preloader className="flex justify-center h-[calc(100vh-72px)]" isShow={test === null}>
                <div className="w-175 2xl:w-[875px]">
                    <Skeleton className="mt-20 mb-5 rounded-3xl 2xl:!h-[492px]" width="100%" height="400px" />
                    <Skeleton className="mb-5 rounded-2xl 2xl:!h-[41px]" width="100%" height="33px" />
                    <Skeleton className="mb-5.5 rounded-2xl 2xl:!h-[48px]" width="100%" height="40px" />
                    <Skeleton className="mb-0.5 rounded-2xl 2xl:!h-[50px]" width="100%" height="40px" />
                    <Skeleton className="rounded-2xl 2xl:!h-[48px]" width="100%" height="40px" />
                </div>
            </Preloader>
            {(showNoAccess || showNoTest) && <NoAccess />}
            {!showNoAccess && test && isProgressStatusChecked && (
                <div className="pb-17.5 relative">
                    {!programId && (
                        <Breadcrumbs className="hidden sm:block mb-2.5" id="userTestPreviewBreadcrumbs">
                            {!courseId && <Breadcrumbs.Link title="Тестирование" url="/tests" />}
                            {courseId && <Breadcrumbs.Link title="Моё обучение" url="/training" />}
                            {courseId && course && (
                                <Breadcrumbs.Link title={course.title} url={`/training/course/${course.resourceId}`} />
                            )}
                            <Breadcrumbs.Link title={test.title} />
                        </Breadcrumbs>
                    )}
                    <div className="hidden sm:flex justify-end mb-1 right-0 absolute">
                        <Button
                            className={"w-auto h-auto p-1.5 focus:ring-0"}
                            shape={"square"}
                            size={"medium"}
                            icon={<Icon icon={Icons.Close} width={26} height={26} color="fill-blue-dark" />}
                            iconPlacement={"center"}
                            color={"secondary"}
                            variant={"outline"}
                            onClick={handleClose}
                            id="userTestPreviewCloseBtn"
                        />
                    </div>
                    <div className="flex flex-col items-center sm:border-b sm:border-gray-blue pt-8.5">
                        <div
                            id="userTestPreviewLogo"
                            className="relative mt-3 sm:mt-0 mb-4 2xl:mb-6.25 sm:mb-5 w-full h-[60vw] sm:w-175 sm:h-100 2xl:w-[875px] 2xl:h-[492px] bg-gray-blue rounded-3xl 2xl:rounded-3.5xl"
                        >
                            {test.logoId && (
                                <img
                                    className="w-full h-[60vw] sm:w-175 sm:h-100 2xl:w-[875px] 2xl:h-[492px] rounded-3xl 2xl:rounded-3.5xl object-cover"
                                    src={Api.Upload.GetLogo(test.logoId, LogoSize.ORIGINAL)}
                                    alt={test.title}
                                />
                            )}
                            <div className="hidden sm:block absolute top-5 right-5 2xl:top-6.25 2xl:right-6.25">
                                <FavoriteButton isFavorite={test.isFavorite} onChange={onFavoriteChange} />
                            </div>
                            <div className="absolute top-5 2xl:top-6.25 left-5 2xl:left-6.25 flex items-start gap-1.5">
                                {!!test.deadlineTime && (
                                    <TestsCardTime time={test.deadlineTime}>
                                        <>
                                            <Icon
                                                icon={Icons.Time}
                                                width={14}
                                                height={14}
                                                color="fill-white"
                                                className="2xl:!w-4.5 2xl:!h-4.5"
                                            />
                                            <span>{formatDeadlineTime(test.deadlineTime, true)}</span>
                                        </>
                                    </TestsCardTime>
                                )}
                            </div>
                        </div>
                        <h1
                            className="hidden sm:block sm:max-w-175 2xl:max-w-[875px] 2xl:!text-4xl 2xl:!leading-[41px] text-center text-black break-anywhere"
                            id="userTestPreviewTitle"
                        >
                            {test.title}
                        </h1>
                        <h2 className="block sm:hidden w-full text-black break-anywhere">{test.title}</h2>
                        {test.description && (
                            <p
                                className="mt-2 2xl:mt-2.5 w-full sm:max-w-175 2xl:max-w-[875px] sm:text-center p2 2xl:text-md text-gray-dark sm:text-blue-drk break-anywhere"
                                id="userTestPreviewDescription"
                            >
                                {test.description}
                            </p>
                        )}
                        {test.affectsCourseCertificate && (
                            <div className="flex items-center mt-2 sm:mt-5 2xl:mt-6.25 p-1 sm:p-3 2xl:p-4 rounded-lg sm:rounded-xl bg-background-light text-xs sm:text-sm 2xl:text-md text-gray">
                                <Icon
                                    icon={Icons.Warning}
                                    width={20}
                                    height={20}
                                    color="fill-red"
                                    className="shrink-0 mr-1 sm:!w-6 sm:!h-6 2xl:!w-8 2xl:!h-8"
                                />
                                Прохождение теста влияет на получение сертификата за курс
                            </div>
                        )}
                        <Button
                            className="hidden sm:flex mb-7 2xl:mb-9 mt-4 sm:mt-5 2xl:mt-6.25"
                            onClick={onStart}
                            id="userTestPreviewStartBtn"
                        >
                            <Icon
                                className="mr-1.5 2xl:mr-2 2xl:!w-6.25 2xl:!h-6.25"
                                icon={Icons.PlayFilled}
                                width={20}
                                height={20}
                                color="fill-white"
                            />
                            {test.progressStatus === "RE_WORK" ? "Пройти заново" : buttonTitle}
                        </Button>
                        <div className="fixed bottom-14 left-0 flex sm:hidden p-2.5 w-full z-10 bg-white">
                            <Button id="userTestPreviewStartBtn" className="w-full" size="large" onClick={onStart}>
                                <Icon
                                    className="mr-1.5"
                                    icon={Icons.PlayCircle}
                                    width={20}
                                    height={20}
                                    color="fill-white"
                                />
                                {test.progressStatus === "RE_WORK" ? "Пройти заново" : buttonTitle}
                            </Button>
                        </div>
                        <div
                            className="flex flex-col sm:flex-row flex-wrap justify-center mt-4 sm:mt-0 mb-2 sm:mb-12 w-full sm:w-125"
                            id="userTestPreviewInfo"
                        >
                            <p className="mb-2 2xl:mb-2.5 sm:px-3 2xl:px-3.75 sm:border-r sm:border-gray-stroke p4 sm:p3 2xl:p2 text-blue-drk">
                                <span className="inline-block w-30 sm:w-auto">Вопросов:&nbsp;</span>
                                <span className="p3 2xl:p2">{test.questionCount}</span>
                            </p>
                            {!!test.questionTimeLimit && (
                                <p className="mb-2 2xl:mb-2.5 sm:px-3 2xl:px-3.75 sm:border-r sm:border-gray-stroke p4 sm:p3 2xl:p2 text-blue-drk">
                                    <span className="inline-block w-30 sm:w-auto">Время на вопрос:&nbsp;</span>
                                    <span className="p3 2xl:p2 text-red">
                                        {Math.floor(test.questionTimeLimit / 60)} мин {test.questionTimeLimit % 60} сек
                                    </span>
                                </p>
                            )}
                            {!!test.quizTimeLimit && (
                                <p className="mb-2 2xl:mb-2.5 sm:px-3 2xl:px-3.75 sm:border-r sm:border-gray-stroke p4 sm:p3 2xl:p2 text-blue-drk">
                                    <span className="inline-block w-30 sm:w-auto">Время на тест:&nbsp;</span>
                                    <span className="p3 2xl:p2 text-red">
                                        {Math.floor(test.quizTimeLimit / 60)} мин {test.quizTimeLimit % 60} сек
                                    </span>
                                </p>
                            )}
                            {test.availableAttempts > 0 && (
                                <p className="mb-2 2xl:mb-2.5 sm:px-3 2xl:px-3.75 sm:border-r sm:border-gray-stroke p4 sm:p3 2xl:p2 text-blue-drk">
                                    <span className="inline-block w-30 sm:w-auto">Попыток доступно:&nbsp;</span>
                                    <span className="p3 2xl:p2 text-red">{test.availableAttempts}</span>
                                </p>
                            )}
                            <p className="mb-2 2xl:mb-2.5 sm:px-3 2xl:px-3.75 sm:border-r sm:border-gray-stroke p4 sm:p3 2xl:p2 text-blue-drk">
                                <span className="inline-block w-30 sm:w-auto">Порог прохождения:&nbsp;</span>
                                <span className="p3 2xl:p2">{test.passPercentage}%</span>
                            </p>
                            {(test.ratingPoints || test.ratingPoints === 0) && (
                                <p className="mb-2 2xl:mb-2.5 sm:px-3 2xl:px-3.75 sm:border-r sm:border-gray-stroke p4 sm:p3 2xl:p2 text-blue-drk">
                                    <span className="inline-block w-30 sm:w-auto">Макс. баллов:&nbsp;</span>
                                    <span className="p3 2xl:p2">{test.ratingPoints}</span>
                                </p>
                            )}
                            {!test.quizTimeLimit && (
                                <p className="mb-2 2xl:mb-2.5 sm:px-3 2xl:px-3.75 sm:border-gray-stroke p4 sm:p3 2xl:p2 text-blue-drk">
                                    <span className="inline-block w-30 sm:w-auto">Займет:&nbsp;</span>
                                    <span className="p3 2xl:p2">
                                        ~{Math.abs(test.approxCompletionMinutes).toString()} мин
                                    </span>
                                </p>
                            )}
                            {/*<p className="flex items-center mb-2 px-3 p3 text-blue cursor-pointer">*/}
                            {/*    <Icon className="mb-1" icon={Icons.ShareBox} width={18} height={18} />*/}
                            {/*    Статистика*/}
                            {/*</p>*/}
                        </div>
                    </div>
                    {test.questionChecks.length !== 0 && (
                        <div className="mt-10 sm:mt-12 w-full">
                            <h1 className="hidden sm:block mb-5 w-full text-black">Рекомендации</h1>
                            <h2 className="block sm:hidden mb-5 w-full text-black">Рекомендации</h2>
                            {test.questionChecks.map((question, index) => {
                                return (
                                    <div key={index} className="flex flex-col mb-9 w-full">
                                        <div className="flex items-center mb-4 py-3 px-3 sm:px-5 w-full bg-background rounded-2lg">
                                            <Icon icon={Icons.Question} width={24} height={24} color="fill-blue" />
                                            <h3 className="ml-0.75">{question.questionText}</h3>
                                        </div>
                                        <div className="flex flex-col sm:flex-row items-center w-full">
                                            <div className="flex flex-col sm:mr-3 py-3 sm:py-7.5 px-3 sm:px-9 w-full sm:w-[50%] border border-input-stroke rounded-2lg">
                                                <span className="mb-6 p1">{question.userAnswer}</span>
                                                <div className="relative flex flex-col">
                                                    <svg
                                                        className="absolute -top-3 left-0 sm:left-2"
                                                        width="14"
                                                        height="35"
                                                        viewBox="0 0 14 35"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M1 0C1 0 1 20.2985 1 25.3731C1 30.4478 4.25 34 7.5 34C10.75 34 14 34 14 34"
                                                            stroke="#C1C6CA"
                                                        />
                                                    </svg>
                                                    <div className="flex flex-col-reverse sm:flex-row justify-between items-start mb-2 sm:mb-0">
                                                        <div className="flex items-center mb-1 ml-5 sm:ml-7">
                                                            <UserAvatar
                                                                color={question.user.defaultAvatarColor}
                                                                avatarId={question.user.avatarId}
                                                                userInitials={`${question.user.firstName?.slice(
                                                                    0,
                                                                    1,
                                                                )}${question.user.lastName?.slice(0, 1)}`}
                                                                size={36}
                                                            />
                                                            <div className="flex flex-col ml-2">
                                                                <span className="mb-1 p1-accent text-black">
                                                                    {question.user.firstName} {question.user.lastName}
                                                                </span>
                                                                <span className="p4 text-gray">
                                                                    Проверил{" "}
                                                                    {formatCheckTime(question.checkedTime, {
                                                                        showTime: true,
                                                                    })}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={clsx(
                                                                "my-3 ml-5 sm:my-0 sm:ml-0",
                                                                question.isApproved
                                                                    ? "bg-primary py-0.5 px-1.5 rounded-md p3 text-white"
                                                                    : "bg-red py-0.5 px-1.5 rounded-md p3 text-white",
                                                            )}
                                                        >
                                                            {question.isApproved ? "Принят" : "Отклонен"}
                                                        </div>
                                                    </div>
                                                    <div className="ml-5 sm:ml-7 py-2 sm:py-2.5 px-3 sm:px-4 bg-background rounded-lg p1">
                                                        {question.comment}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="sm:ml-3 w-full sm:w-[50%]"></div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            )}
        </ContentLayout>
    );
};
