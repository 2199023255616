import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import "./Validations.scss";
import { Breadcrumbs, Content, Tabs, TabsWrapper, Loader } from "Uikit";
import { ContentLayout } from "Containers";
import { TaskTab } from "./Tabs/Tasks/TaskTab";
import { TestTab } from "./Tabs/Tests/TestTab";
import { ReviewTab } from "./Tabs/Reviews/ReviewTab";
import { useQuery } from "react-query";
import Api from "Api";
import { useCurrentUser } from "hooks/useCurrentUser";

export const Validations = () => {
    const [currentTab, setCurrentTab] = useState(0);
    const [isMounted, setIsMounted] = useState(false);

    const location = useLocation();
    const [queryParams] = useSearchParams();

    const { data: user } = useCurrentUser();

    const { data: role } = useQuery(["role", "get", user?.id], () => Api.Role.Id(user!.id), { enabled: !!user });

    const { data: reviewCount } = useQuery(["review", "collection"], () => Api.UtilsApi.GetReviewCount(), {
        refetchInterval: 30000,
    });

    const tabs = [
        {
            title: `Тесты`,
            url: "tests",
            content: <TestTab />,
            show: role?.tests,
            count: reviewCount ? reviewCount.quizTab : 0,
        },
        {
            title: "Задания",
            url: "tasks",
            content: <TaskTab queryParams={queryParams} />,
            show: role?.exercise,
            count: reviewCount ? reviewCount.exerciseTab : 0,
        },
        // {
        //     title: 'Замечания',
        //     url: 'remarks',
        //     content: <RemarkTab />,
        // },
        {
            title: "Отзывы",
            url: "reviews",
            content: <ReviewTab />,
            show: role?.exercise,
            count: reviewCount ? reviewCount.feedbackTab : 0,
        },
        // {
        //     title: 'Комментарии',
        //     url: 'comments',
        //     content: <CommentTab />,
        // },
        // {
        //     title: 'История',
        //     url: 'history',
        //     content: <HistoryTab />,
        // },
    ];

    const addTabUrl = (tabUrl: string) => {
        queryParams.delete("tab");
        queryParams.append("tab", tabUrl);
        window.history.pushState(null, "", `${location.pathname}?${queryParams.toString()}`);
        setCurrentTab(tabs.findIndex(({ url }) => url === tabUrl));
    };

    useEffect(() => {
        setTimeout(() => {
            if (!queryParams.has("tab")) {
                setTimeout(() => {
                    addTabUrl(tabs[0].url);
                }, 0);
            } else {
                const tab = queryParams.get("tab");
                console.log("Validations queryParams tab", tab);
                addTabUrl(tabs[tabs.findIndex(({ url }) => url === tab)].url);
            }
            setIsMounted(true);
        }, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ContentLayout className="h-full">
            <div className="pb-3">
                <Breadcrumbs id="adminValidationsBreadcrumbs">
                    <Breadcrumbs.Link title={"Администратор"} />
                    <Breadcrumbs.Link title={"Проверка"} url={"/admin/validations"} />
                    <Breadcrumbs.Link title={tabs[currentTab].title} url={"/admin/validations"} />
                </Breadcrumbs>
            </div>

            {!isMounted ? (
                <div className="flex justify-center h-2/4 items-end">
                    <Loader />
                </div>
            ) : (
                <TabsWrapper selectedIndex={currentTab} className="flex flex-col flex-grow grow">
                    <div className="flex">
                        <TabsWrapper.Tabs classname="h-max space-x-4 flex-grow" id="adminValidationsTabs">
                            {tabs
                                .filter((p) => p.show)
                                .map(({ title, url, count }) => {
                                    return (
                                        <Tabs.Tab
                                            key={title}
                                            title={`${title} (${count})`}
                                            onClick={() => {
                                                addTabUrl(url);
                                            }}
                                        />
                                    );
                                })}
                        </TabsWrapper.Tabs>
                    </div>

                    <TabsWrapper.Content className="!h-auto flex flex-col grow">
                        {tabs
                            .filter((p) => p.show)
                            .map(({ title, content }) => {
                                return (
                                    <Content.Body key={title} className="mt-6 h-full">
                                        {content}
                                    </Content.Body>
                                );
                            })}
                    </TabsWrapper.Content>
                </TabsWrapper>
            )}
        </ContentLayout>
    );
};
