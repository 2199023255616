import React, {
    ChangeEvent,
    ReactNode,
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { Icon, Icons, Input, Pagination } from "..";
import {
    ColumnDef,
    ColumnFiltersState,
    FilterFn,
    flexRender,
    getCoreRowModel,
    getFacetedMinMaxValues,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    OnChangeFn,
    PaginationState,
    Row,
    RowSelectionState,
    Table as TTable,
    useReactTable,
    Cell,
} from "@tanstack/react-table";
import { rankItem } from "@tanstack/match-sorter-utils";
import clsx from "clsx";
import { Loader, Empty } from "Uikit";
import { useInterval } from "hooks/useInterval";
import { SortingState } from "@tanstack/table-core/src/features/Sorting";
import { TVoidFunction } from "types";
import { InfiniteList } from "Components/InfiniteList/InfiniteList";

interface TableProps<T> {
    columns: ColumnDef<T>[];
    searchTitle?: string;
    columnFilters?: ColumnFiltersState;
    pageCount?: number;
    data?: T[];
    pagination?: PaginationState;
    enableRowSelection?: boolean | ((row: Row<any>) => boolean);
    rowSelection?: RowSelectionState;
    onPaginationChange?: OnChangeFn<PaginationState>;
    onRowSelectionChange?: OnChangeFn<RowSelectionState>;
    onColumnFiltersChange?: OnChangeFn<ColumnFiltersState>;
    onGlobalFiltersChange?: (value: string) => void;
    onRowClick?: (row: Row<any>) => void;
    isFetching?: boolean;
    controlButtons?: ReactNode;
    selectButtons?: ReactNode;
    setSelectedRows?: (rows: any) => void;
    allRowsSelected?: boolean;
    emptyBlock?: ReactNode;
    emptyMessage?: string;
    rowClassName?: string;
    preSearchComponent?: ReactNode;
    afterSearchComponent?: ReactNode;
    searchAlwaysLeft?: boolean;
    searchAlwaysRight?: boolean;
    title?: string;
    controlsWrapperClassNames?: string;
    setTableInstance?: (tableInstance: TTable<any>) => void;
    columnClassName?: string;
    searchClassName?: string;
    id?: string;
    noSearch?: boolean;
    bodyCellClassName?: string;
    rowTrClassName?: string;
    sorting?: any;
    onSortingChange?: OnChangeFn<SortingState>;
    tableWrapperClassName?: string;
    theadClassName?: string;
    onSearch?: (search: string) => void;
    searchRowClassName?: string;
    searchBlockClassName?: string;
    withoutLines?: boolean;
    emptyTitle?: string;
    highlightRowsIndex?: number[];
    hoverRowsIndex?: number[];
    searchValue?: string;
    titleClassName?: string;
    searchInputWrapperClassName?: string;
    defaultSortOrder?: Record<string, string>;
    gotIndividualRowClassName?: boolean;
    // lazyload params
    lazy?: boolean;
    isLazyLoading?: boolean;
    hasMore?: boolean;
    onLoadMore?: TVoidFunction;
    isSkeletonLoaderTable?: boolean;
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value);

    // Store the itemRank info
    addMeta({
        itemRank,
    });

    // Return if the item should be filtered in/out
    return itemRank.passed;
};

interface ISearchProps {
    value: string;
    preSearchComponent?: ReactNode;
    afterSearchComponent?: ReactNode;
    searchAlwaysRight?: boolean;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    className?: string;
    placeholder?: string;
    blockClassName?: string;
    disabled?: boolean;
    searchInputWrapperClassName?: string;
}

const Search = ({
    placeholder,
    preSearchComponent,
    afterSearchComponent,
    searchAlwaysRight = false,
    value = "",
    onChange,
    className,
    blockClassName,
    disabled = false,
    searchInputWrapperClassName,
}: ISearchProps) => (
    <div className={clsx("flex items-center gap-4", blockClassName)}>
        {preSearchComponent}
        <div className={clsx("w-full flex justify-between relative grow min-w-[350px]", searchInputWrapperClassName)}>
            <span className="z-20 absolute inset-y-0 left-1 flex items-center pl-2">
                <button className="z-20 p-1 focus:outline-none focus:shadow-outline" disabled={disabled}>
                    <Icon
                        icon={Icons.Search}
                        width={18}
                        height={18}
                        color="fill-[#939393]"
                        className="2xl:!w-5.5 2xl:!h-5.5"
                    />
                </button>
            </span>
            <Input
                className={clsx(
                    "z-10 block w-[350px] 2xl:w-105 border border-[#E6E9ED] placeholder:text-[#939393] pl-10 2xl:pl-11",
                    {
                        "!w-full 2xl:!w-105": searchAlwaysRight,
                    },
                    className,
                )}
                placeholder={placeholder ?? "Поиск..."}
                value={value}
                onChange={onChange}
                // disabled={disabled}
                id="tableSearchInput"
                shiftOnBlur={true}
            />
            {value && (
                <div
                    id="searchClearIcon"
                    className="absolute top-2/4 -translate-y-2/4 right-3 cursor-pointer z-20"
                    onClick={() => {
                        if (onChange) {
                            onChange({ target: { value: "" } } as ChangeEvent<HTMLInputElement>);
                        }
                    }}
                >
                    <Icon icon={Icons.Close} width={20} height={20} color="fill-blue-drk" />
                </div>
            )}
        </div>
        {afterSearchComponent}
    </div>
);

export const Table = <T,>({
    columns,
    searchTitle,
    data,
    pagination,
    pageCount,
    onPaginationChange,
    enableRowSelection,
    rowSelection,
    onRowSelectionChange,
    onColumnFiltersChange,
    onGlobalFiltersChange,
    onRowClick,
    isFetching: _isFetching = false,
    columnFilters = [],
    controlButtons,
    selectButtons,
    setSelectedRows,
    allRowsSelected,
    emptyBlock,
    emptyMessage = "По заданным параметрам результатов нет",
    emptyTitle = "Ничего не найдено",
    rowClassName,
    preSearchComponent,
    afterSearchComponent,
    searchAlwaysLeft,
    searchAlwaysRight,
    title,
    controlsWrapperClassNames,
    setTableInstance,
    columnClassName,
    searchClassName,
    id,
    noSearch = false,
    rowTrClassName,
    bodyCellClassName,
    sorting,
    onSortingChange = () => {},
    tableWrapperClassName,
    theadClassName,
    onSearch,
    searchRowClassName,
    searchBlockClassName,
    withoutLines,
    highlightRowsIndex,
    hoverRowsIndex,
    searchValue,
    titleClassName,
    searchInputWrapperClassName,
    defaultSortOrder,
    gotIndividualRowClassName = false,
    // lazyload params
    lazy = false,
    isLazyLoading = false,
    hasMore = false,
    onLoadMore = () => {},
    isSkeletonLoaderTable = false,
}: TableProps<T>) => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const defaultPagination = useCallback(() => {}, []);
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const defaultRowSelection = useCallback((_: any) => {}, []);
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const defaultColumnFilters = useCallback((_: any) => {}, []);

    const inputRef = useRef<HTMLInputElement | null>(null);
    const [globalFilter, setGlobalFilter] = useState("");

    const [isSearchInputChange, setIsSearchInputChange] = useState(false);
    const [searchCounter, setSearchCounter] = useState<number>(0);

    const defaultData = useMemo(() => [], []);

    const [tableCount, setTableCount] = useState(pagination?.pageSize ?? 15);
    const [tableOffset, setTableOffset] = useState(0);

    const table = useReactTable({
        data: data ?? defaultData,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        pageCount: pageCount ?? -1,
        state: {
            sorting,
            pagination,
            rowSelection,
            columnFilters,
            globalFilter,
        },
        onSortingChange: onSortingChange,
        manualSorting: true,
        enableSorting: true,
        enableSortingRemoval: false,
        onColumnFiltersChange: onColumnFiltersChange ?? defaultColumnFilters,
        onGlobalFilterChange: onGlobalFiltersChange,
        globalFilterFn: fuzzyFilter,
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        onPaginationChange: onPaginationChange ?? defaultPagination,
        enableRowSelection: enableRowSelection ?? true,
        onRowSelectionChange: onRowSelectionChange ?? defaultRowSelection,
        getCoreRowModel: getCoreRowModel(),
        manualPagination: true,
        debugTable: true,
        manualFiltering: !!onSearch,
        getRowId: (row) => {
            return row.id;
        },
    });

    const totalFound = table.getRowModel().rows.length;
    const totalFetched = pageCount ? pageCount * (pagination?.pageSize ?? 0) : 0;
    const selectedRows = table.getSelectedRowModel();

    const onTablePaginate = useCallback(
        (offset: number, count: number) => {
            setTableOffset(offset);
            setTableCount(count);

            table.setPageIndex(offset / count);
            table.setRowSelection({});
        },
        [table],
    );

    useInterval(() => {
        if (onSearch && isSearchInputChange && searchCounter === 0) {
            onSearch(globalFilter);
            setIsSearchInputChange(false);
        }

        if (searchCounter !== 0) {
            setSearchCounter(searchCounter - 1);
        }
    }, 500);

    useEffect(() => {
        setTableInstance?.(table);
        if (searchValue) {
            // setGlobalFilter(searchValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isSearchInputChange) {
            inputRef?.current?.focus();
        }
    }, [isSearchInputChange]);

    useEffect(() => {
        if (setSelectedRows) {
            setSelectedRows(selectedRows.flatRows.map((row) => row.original));
        }
    }, [setSelectedRows, selectedRows, table]);

    useLayoutEffect(() => {
        if (allRowsSelected) {
            table.toggleAllRowsSelected();
        }
    }, [allRowsSelected, table]);

    const TableContent = () => {
        return (
            <div className={clsx(!lazy && tableWrapperClassName, withoutLines && "-ml-3")}>
                <table className="w-full" id={id + "Table"}>
                    <thead className={clsx("text-gray", theadClassName)}>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id} className="">
                                {headerGroup.headers.map((header) => {
                                    const columnIndividualClassName =
                                        (header.column.columnDef as any).columnIndividualClassName ?? "";
                                    return (
                                        <th
                                            key={header.id}
                                            colSpan={header.colSpan}
                                            className={clsx(
                                                "px-3 p4-table 2xl:text-xs 2xl:leading-[15px] uppercase text-left min-w-4 cursor-pointer",
                                                columnClassName,
                                                header.id === "select" ? "pl-0 pr-1 opacity-100" : "opacity-70",
                                                withoutLines && "pl-3",
                                            )}
                                            onClick={() => {
                                                if (header.column.getCanSort()) {
                                                    let desc = undefined;
                                                    if (
                                                        !header.column.getIsSorted() &&
                                                        defaultSortOrder?.[header.column.id]
                                                    ) {
                                                        desc =
                                                            defaultSortOrder?.[header.column.id] === "desc"
                                                                ? true
                                                                : false;
                                                    }
                                                    header.column.toggleSorting(desc);
                                                }
                                            }}
                                        >
                                            {header.isPlaceholder ? null : (
                                                <div className={`group flex items-center ${columnIndividualClassName}`}>
                                                    <div className="group-hover:text-gray-dark">
                                                        {flexRender(
                                                            header.column.columnDef.header,
                                                            header.getContext(),
                                                        )}
                                                    </div>
                                                    {header.column.getCanSort() &&
                                                        ({
                                                            false: (
                                                                <Icon
                                                                    icon={Icons.Sort}
                                                                    width="12px"
                                                                    height="12px"
                                                                    className="ml-1 2xl:ml-1.25 2xl:!w-4.5 2xl:!h-4.5"
                                                                />
                                                            ),
                                                            asc: (
                                                                <Icon
                                                                    icon={Icons.ChevronUp}
                                                                    width="9px"
                                                                    height="9px"
                                                                    className="ml-1 2xl:ml-1.25 2xl:!w-3 2xl:!h-3"
                                                                    color="fill-[#B4B4B4]"
                                                                />
                                                            ),
                                                            desc: (
                                                                <Icon
                                                                    icon={Icons.ChevronDown}
                                                                    width="9px"
                                                                    height="9px"
                                                                    className="ml-1 2xl:ml-1.25 2xl:!w-3 2xl:!h-3"
                                                                    color="fill-[#B4B4B4]"
                                                                />
                                                            ),
                                                        }[header.column.getIsSorted() as string] ??
                                                            null)}
                                                </div>
                                            )}
                                        </th>
                                    );
                                })}
                            </tr>
                        ))}
                    </thead>

                    <tbody>
                        {table.getRowModel().rows.map((row, index) => {
                            let individualRowClassName = "";
                            if (gotIndividualRowClassName) {
                                const allCells = row.getAllCells();
                                allCells.forEach(({ column: { columnDef } }: Cell<any, unknown>) => {
                                    if ((columnDef as any).getRowClassNameFn) {
                                        individualRowClassName = (columnDef as any).getRowClassNameFn?.(row);
                                    }
                                });
                            }

                            return (
                                <tr
                                    key={row.id}
                                    // className={rowClassName rowTrClassName}
                                    className={clsx(
                                        rowClassName,
                                        rowTrClassName,
                                        index === table.getRowModel().rows.length - 1 ? "last" : "",
                                        withoutLines ? "hover:bg-background cursor-pointer" : "",
                                        hoverRowsIndex?.includes(index) ? "bg-background" : "",
                                        individualRowClassName,
                                    )}
                                    onClick={() => (onRowClick ? onRowClick(row) : null)}
                                >
                                    {row.getVisibleCells().map((cell, cellIndex) => {
                                        const visibleCells = row.getVisibleCells();
                                        return (
                                            <td
                                                key={cell.id}
                                                width={
                                                    cell.getContext().column.columnDef.enableResizing
                                                        ? cell.getContext().column.getSize()
                                                        : 0
                                                }
                                                className={clsx(
                                                    "px-3 py-3.5 2xl:py-4.5 border-gray-blue border-b 2xl:text-md",
                                                    cell.id.indexOf("select") !== -1
                                                        ? "relative !border-0 after:absolute after:content-[''] " +
                                                              "after:top-0 after:right-[-12px] after:w-3 after:h-full " +
                                                              "after:bg-white"
                                                        : null,
                                                    rowClassName,
                                                    bodyCellClassName,
                                                    withoutLines ? "pl-3 pr-1 after:!bg-transparent" : "",
                                                    !withoutLines && cell.id.indexOf("_select") !== -1
                                                        ? "pl-0 pr-1"
                                                        : "",
                                                    cell.id.indexOf("_buttons") !== -1 ? "pr-0" : "",
                                                    index === table.getRowModel().rows.length - 1 ? "border-b-0" : "",
                                                    withoutLines ? "!border-0" : "",
                                                    withoutLines && cellIndex === 0
                                                        ? "rounded-tl-xl rounded-bl-xl"
                                                        : "",
                                                    withoutLines && cellIndex === row.getVisibleCells().length - 1
                                                        ? "rounded-tr-xl rounded-br-xl"
                                                        : "",
                                                    highlightRowsIndex?.includes(index)
                                                        ? "bg-blue-10 !rounded-none"
                                                        : "",
                                                    (visibleCells[cellIndex]?.column.columnDef as any)?.className ?? "",
                                                )}
                                            >
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

                {totalFetched > (pagination?.pageSize ?? 0) && pagination && (
                    <div className="mt-4.5 2xl:mt-10 mb-2">
                        <Pagination
                            total={totalFetched}
                            count={pagination ? pagination.pageSize : tableCount}
                            offset={pagination ? pagination.pageIndex * pagination.pageSize : tableOffset}
                            onPaginate={onTablePaginate}
                        />
                    </div>
                )}
            </div>
        );
    };

    const searchProps = {
        value: globalFilter,
        preSearchComponent,
        afterSearchComponent,
        searchInputWrapperClassName,
        searchAlwaysRight,
        onChange: (e: ChangeEvent<HTMLInputElement>) => {
            if (onSearch) {
                setSearchCounter(1);
            }

            setGlobalFilter(e.target.value);
            setIsSearchInputChange(true);
        },
        className: searchClassName,
        blockClassName: searchBlockClassName,
        placeholder: searchTitle,
        disabled: _isFetching && !data?.length,
    };

    return (
        <div className="relative h-full w-full flex flex-col" id={id}>
            {!noSearch ? (
                <div className={clsx("relative flex justify-between mb-5 2xl:mb-6.25 z-50", searchRowClassName)}>
                    {!searchTitle && preSearchComponent}
                    {searchAlwaysLeft && (
                        <div className={clsx("flex", controlsWrapperClassNames)}>
                            <Search {...searchProps} />
                            {controlButtons}
                        </div>
                    )}
                    {searchTitle &&
                        !table.getIsAllRowsSelected() &&
                        !table.getIsSomeRowsSelected() &&
                        !searchAlwaysRight &&
                        !searchAlwaysLeft && <Search {...searchProps} />}
                    {searchTitle && !table.getIsAllRowsSelected() && !table.getIsSomeRowsSelected() && title && (
                        <h2 className={titleClassName}>{title}</h2>
                    )}

                    {(table.getIsAllRowsSelected() || table.getIsSomeRowsSelected()) && !searchAlwaysLeft && (
                        <div className="z-1 grow shrink-0">{selectButtons}</div>
                    )}

                    {searchAlwaysRight ? (
                        <div className="flex justify-end items-center space-x-5">
                            <Search {...searchProps} />
                            {controlButtons}
                        </div>
                    ) : (
                        <>{!searchAlwaysLeft ? controlButtons : null}</>
                    )}
                </div>
            ) : null}

            {totalFound > 0 && (
                <>
                    {lazy ? (
                        <InfiniteList
                            isLoading={isLazyLoading}
                            onLoadMore={onLoadMore}
                            hasMore={hasMore}
                            isSkeletonLoaderTable={isSkeletonLoaderTable}
                            className={tableWrapperClassName}
                        >
                            <TableContent />
                        </InfiniteList>
                    ) : (
                        <TableContent />
                    )}
                </>
            )}
            {totalFound === 0 && emptyBlock}
            {totalFound === 0 && !emptyBlock && !isLazyLoading && (
                <Empty
                    title={emptyTitle}
                    description={emptyMessage}
                    topElement={
                        <div className="flex-center mb-4 2xl:mb-5">
                            <div className="flex-center w-16.5 h-16.5 2xl:w-20.5 2xl:h-20.5 rounded-full bg-blue-10">
                                <Icon
                                    icon={Icons.EmojiSad}
                                    width={"36px"}
                                    height={"36px"}
                                    color={"fill-primary"}
                                    className="2xl:!w-11.25 2xl:!h-11.25"
                                />
                            </div>
                        </div>
                    }
                />
            )}
            {(_isFetching || isLazyLoading) && (
                <div className="absolute top-0 left-0 w-full h-full bg-white z-10">
                    <div className="flex justify-center items-center h-full">
                        <Loader />
                    </div>
                </div>
            )}
        </div>
    );
};
