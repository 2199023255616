import React, { useState } from "react";

import { Icon, Icons } from "Uikit";

import { AuthorizationForm } from "./AuthorizationForm";
import { AuthorizationInviteForm } from "./AuthorizationInviteForm";

interface IAuthorizationProps {
    signup?: boolean;
}

interface ILogoProps {
    signup?: boolean;
    isLoading: boolean;
}

const Logo = ({ isLoading }: ILogoProps) => {
    return (
        <>
            <div
                className={`rounded-xl bg-white w-15 h-15 flex items-center justify-center relative overflow-hidden ${
                    isLoading
                        ? `before:content-[""] before:block before:absolute before:h-full before:w-[200%] before:animate-gradientMoving`
                        : ""
                } logo`}
            >
                <Icon className="hidden sm:block relative z-[1]" icon={Icons.LogoBasic} width={60} height={60} />
            </div>
            <Icon className="block sm:hidden" icon={Icons.Logo} width={45} height={45} />
        </>
    );
};

export const Authorization = ({ signup = false }: IAuthorizationProps) => {
    const [inviteId, setInviteId] = useState(new URLSearchParams(window.location.search).get("inviteId") ?? "");
    const [isLoading, setIsLoading] = useState(true);

    return (
        <div className="sm:flex px-3 sm:px-0 h-full sm:bg-auth-background bg-cover bg-center">
            <div className="sm:flex sm:items-center sm:justify-end mb-13 pt-5 sm:mb-0 sm:pt-0 sm:pr-10 w-full sm:w-[50%]">
                <Logo isLoading={signup ? isLoading : false} />
                <span className="hidden sm:block my-auto ml-5 text-[30px] font-medium text-white">LMS GRAN</span>
            </div>
            <div className="flex items-center justify-center w-full sm:w-[50%]">
                {signup ? (
                    <AuthorizationInviteForm
                        inviteId={inviteId}
                        setInviteId={setInviteId}
                        setIsLoading={(loading: boolean) => {
                            setIsLoading(loading);
                        }}
                    />
                ) : (
                    <AuthorizationForm />
                )}
            </div>
        </div>
    );
};
