import React from "react";
import { OptionProps, SingleValueProps, components } from "react-select";
import { Icon, Icons } from "..";
import { UserListResponse } from "Api/Responses/UserResponse";
import { UserAvatar } from "Uikit/UserAvatar/UserAvatar";
import clsx from "clsx";
import { IAdministratorsOption } from "types";

export const CategoryMenuList = ({ children, ...rest }: any) => {
    return (
        <components.MenuList {...rest}>
            {children}

            <div
                className="flex items-center cursor-pointer px-3.5 py-2 group"
                onClick={rest.selectProps.addOptionClick ? rest.selectProps.addOptionClick : () => undefined}
            >
                <Icon color="fill-primary" icon={Icons.PlusFilled} className="mr-1.5 group-hover:fill-blue-hover" />{" "}
                Создать категорию
            </div>
        </components.MenuList>
    );
};

export const UsersSingleValue = ({ children, data, ...props }: SingleValueProps<IAdministratorsOption>) => {
    const firstName = data.payload ? data.payload.firstName : (data.value as UserListResponse).firstName;
    const lastName = data.payload ? data.payload.lastName : (data.value as UserListResponse).lastName;

    const avatarId = data.payload ? data.payload.avatarId : (data.value as UserListResponse).avatarId;
    const defaultAvatarColor = data.payload
        ? data.payload.defaultAvatarColor
        : (data.value as UserListResponse).defaultAvatarColor;

    return (
        <components.SingleValue className="!-my-1 !overflow-auto" data={data} {...props}>
            <div className="flex items-center gap-2 cursor-pointer hover:bg-background">
                <UserAvatar
                    avatarId={avatarId}
                    userInitials={`${firstName?.slice(0, 1)}${lastName?.slice(0, 1)}`}
                    color={defaultAvatarColor}
                    size={20}
                />
                <span className="overflow-hidden text-ellipsis">{children}</span>
            </div>
        </components.SingleValue>
    );
};

export const UsersOption = ({ innerProps, innerRef, label, data, isSelected }: OptionProps<IAdministratorsOption>) => {
    const {
        payload: { avatarId, firstName, lastName, defaultAvatarColor },
    } = data;
    return (
        <div
            ref={innerRef}
            {...innerProps}
            className={clsx(
                "flex items-center py-2 px-4.5 gap-2 cursor-pointer hover:bg-background",
                isSelected && "!bg-gray-blue text-black",
            )}
        >
            <UserAvatar
                avatarId={avatarId}
                userInitials={`${firstName?.slice(0, 1)}${lastName?.slice(0, 1)}`}
                color={defaultAvatarColor}
                size={28}
            />
            <span>{label}</span>
        </div>
    );
};
