import React, { ChangeEvent, ClipboardEvent, useCallback, useMemo, useRef, useState } from "react";
import {
    Button,
    ComboBox,
    flash,
    FormField,
    FormGroup,
    InputField,
    Label,
    Modal,
    OptionsDropdownIndicator,
    SecondaryButton,
    SubmitButton,
    Toggle,
} from "Uikit";
import { TeamTreeAllBasicInfoResponse } from "Api/Responses/TeamResponse";
import { Formik } from "formik";
import { Except, SetOptional } from "type-fest";
import { Team } from "types/Team";
import { object, string } from "yup";
import { TeamCreateRequest, TeamUpdateRequest } from "Api/Requests/TeamRequest";
import Api from "Api";
import { ParentTeamChoiceModal } from "./ParentTeamChoiceModal";
import { useQuery } from "react-query";
import { ID, IOption, TVoidFunction } from "types";
import { flattenTeams } from "models/Team";
import compact from "lodash/compact";
import clsx from "clsx";
import { BadRequestResponse, ErrorCode } from "Api/BaseResponse";
import { UIErrorMessages } from "Enums";

const modalTitle = {
    create: {
        text: "Создание команды",
    },
    edit: {
        text: "Редактирование команды",
    },
    remove: {
        text: "Удаление команды",
    },
};

interface TeamActionModalProps {
    isOpen: boolean;
    onClose: TVoidFunction;
    type: "create" | "edit" | "remove";
    selectedTeam?: Partial<TeamTreeAllBasicInfoResponse>;
    openLoadingModal: (operationType: string) => void;
    closeLoadingModal: TVoidFunction;
}

export const TeamActionModal = ({
    isOpen,
    onClose,
    type,
    selectedTeam,
    openLoadingModal,
    closeLoadingModal,
    ...rest
}: TeamActionModalProps) => {
    return (
        <Modal
            className="max-w-142"
            isOpen={isOpen}
            onClose={onClose}
            title={modalTitle[type].text}
            description={selectedTeam?.name}
        >
            <ModalContent
                onClose={onClose}
                type={type}
                selectedTeam={selectedTeam}
                openLoadingModal={openLoadingModal}
                closeLoadingModal={closeLoadingModal}
                {...rest}
            />
        </Modal>
    );
};

type TeamFields = { copyUsers: boolean } & SetOptional<Team, "id" | "name" | "userIds">;

const NAME_MAX_LENGTH = 256;
const teamNamePattern = /^[0-9a-zA-Zа-яА-ЯёЁ \-—–()#№&_+/]*$/;

const ModalContent = ({
    selectedTeam,
    onClose,
    type,
    openLoadingModal,
    closeLoadingModal,
}: Except<TeamActionModalProps, "isOpen">) => {
    const nameRef = useRef();
    const [copyAccess, setCopyAccess] = useState(false);
    const [parentTeamModalOpen, setParentTeamModalOpen] = useState(false);
    const [nestedMembersError, setNestedMembersError] = useState(false);
    const [currentParentTeamId, setCurrentParentTeamId] = useState<ID | null>(selectedTeam?.parentId ?? null);
    const [checkedTeamIds, setCheckedTeamIds] = useState<string[]>([]);
    const setFormErrors = useRef<(field: string, error: string) => void>();

    const { data: teams = [] as TeamTreeAllBasicInfoResponse[] } = useQuery(
        ["teams", "tree", "basic-info", "collection"],
        async () => await Api.Teams.TreeAllBasicInfo([]),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            staleTime: 5 * 60 * 1000,
        },
    );

    const getTeamOptions = useCallback(() => {
        const getTeams = (team: TeamTreeAllBasicInfoResponse) => {
            let response: IOption[] = [{ label: team.name, value: team.id }];

            for (let i = 0; i < team.subTeams?.length; i++) {
                response = response.concat(getTeams(team.subTeams[i]));
            }

            return response;
        };

        return getTeams({ subTeams: teams } as TeamTreeAllBasicInfoResponse);
    }, [teams]);

    const initialValues: TeamFields = (selectedTeam && {
        id: selectedTeam.id,
        name: selectedTeam.name,
        parentTeamId: selectedTeam.parentId ?? undefined,
        copyUsers: false,
    }) ?? {
        name: "",
        copyUsers: false,
        parentTeamId: null,
    };

    const toggleParentTeamModal = () => {
        setParentTeamModalOpen((open) => !open);
    };

    const handleSubmit = async (values: TeamFields) => {
        try {
            onClose();
            openLoadingModal(type);
            if (type === "create") {
                await Api.Teams.Create(
                    Object.assign(new TeamCreateRequest(), {
                        ...values,
                        copyUsers: copyAccess,
                    }),
                );
            } else if (type === "edit") {
                await Api.Teams.Update(Object.assign(new TeamUpdateRequest(), values));
            } else if (type === "remove") {
                if (flattenTeams([selectedTeam! as TeamTreeAllBasicInfoResponse]).some((team) => team.usersCount > 0)) {
                    setNestedMembersError(true);
                    return;
                }

                await Api.Teams.Remove(values.id!);
            }
        } catch (e) {
            if (e instanceof BadRequestResponse) {
                switch (e.errorCode) {
                    case ErrorCode.TOO_MANY_ROOT_TEAMS_ERROR:
                        flash.error("Слишком много корневых команд.");
                        break;
                    case ErrorCode.TEAM_ALREADY_EXISTS:
                        flash.error("Данное название уже используется");
                        setFormErrors.current?.("name", "Данное название уже используется");
                        break;
                    default:
                        flash.error(UIErrorMessages.UNKNOWN_ERROR);
                }
            }
        } finally {
            closeLoadingModal();
        }
    };

    const teamNames = useMemo(
        () => compact(flattenTeams(teams ?? []).map((team) => team.name.trim().toLowerCase().replaceAll("ё", "е"))),
        [teams],
    );

    const validationSchema = useMemo(
        () =>
            object({
                name: string()
                    .required("обязательно для ввода")
                    .max(NAME_MAX_LENGTH, "Невозможно ввести символов больше разрешенной длины")
                    .test("pattern", "В поле нельзя ввести недопустимые символы", (name, _form) => {
                        return !name || teamNamePattern.test(name);
                    })
                    .test("uniqueness", "Данное название уже используется", (name, form) => {
                        const notChanged = form.parent.name === name && (name?.length ?? 0) > 0;
                        const removing = type === "remove";
                        return (
                            removing ||
                            notChanged ||
                            !name ||
                            !teamNames.includes(name.trim().toLowerCase().replaceAll("ё", "е"))
                        );
                    }),
                parentTeamId: string().nullable(false),
            }),
        [teamNames, type],
    );

    const allTeamOptions = useMemo(() => getTeamOptions(), [getTeamOptions]);

    return (
        <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={handleSubmit}>
            {({ handleSubmit, setFieldValue, values, errors, submitCount, setFieldError }) => {
                const isRootTeam = values.parentTeamId === undefined;
                const isParentTeamError = !!errors.parentTeamId && submitCount > 0;
                setFormErrors.current = setFieldError;
                const parentTeamSelectedOption = values.parentTeamId
                    ? allTeamOptions.find((p) => p.value === values.parentTeamId)
                    : null;

                const handlePaste = (event: ClipboardEvent) => {
                    event.preventDefault();
                    const content = event.clipboardData.getData("text/plain");
                    const allowedContent = content
                        .trim()
                        .substring(0, NAME_MAX_LENGTH)
                        .split("")
                        .map((char) => (char === String.fromCharCode(160) ? " " : char))
                        .filter((char) => teamNamePattern.test(char))
                        .join("")
                        .trim();
                    if (allowedContent.length !== content.length) {
                        flash.success("При вставке удалены запрещенные символы");
                    }
                    setFieldValue("name", allowedContent).then();

                    setTimeout(() => {
                        (nameRef.current as any).value = allowedContent;
                    }, 100);
                };

                const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
                    event.preventDefault();
                    const content = event.target.value;
                    const allowedContent = content
                        .split("")
                        .filter((char) => teamNamePattern.test(char))
                        .join("");

                    setFieldValue("name", allowedContent).then();
                };

                const getParentRootTeam = (
                    team: Partial<TeamTreeAllBasicInfoResponse> | undefined,
                ): string[] | undefined => {
                    if (!teams || !team?.id) {
                        return undefined;
                    }

                    if (!team.parentId) {
                        return [team.id];
                    }

                    const getAllTeams = (team: TeamTreeAllBasicInfoResponse): TeamTreeAllBasicInfoResponse[] => {
                        let teams: TeamTreeAllBasicInfoResponse[] = [team];

                        if (!team.subTeams) {
                            return teams;
                        }

                        for (let i = 0; i < team.subTeams.length; i++) {
                            teams = teams.concat(getAllTeams(team.subTeams[i]));
                        }

                        return teams;
                    };

                    const allTeams = getAllTeams({ subTeams: teams } as TeamTreeAllBasicInfoResponse);
                    let currentTeam = team;

                    while (currentTeam?.parentId) {
                        currentTeam = allTeams.filter((p) => p.id === currentTeam.parentId)[0];
                    }

                    return currentTeam?.id ? [currentTeam.id] : undefined;
                };

                return (
                    <>
                        <Modal.Body id="settingsTeamsActionModalBody">
                            <div className="mb-6 mt-3">
                                {type === "remove" ? (
                                    <div>
                                        Вы действительно хотите удалить команду? Также будут удалены все вложенные в неё
                                        команды (если они есть).
                                    </div>
                                ) : (
                                    <div className="mb-6 mt-3">
                                        <FormField name="name" label="Название команды" isRequired>
                                            <InputField
                                                placeholder="Введите название команды"
                                                onPaste={handlePaste}
                                                onChange={handleChange}
                                                maxLength={256}
                                                ref={nameRef}
                                                id="createTeamModalInput1"
                                            />
                                        </FormField>

                                        {type !== "edit" && (
                                            <FormGroup>
                                                <Toggle
                                                    enabled={isRootTeam}
                                                    label="Корневая команда"
                                                    onChange={(flag) => {
                                                        setFieldValue("parentTeamId", flag ? undefined : null);
                                                        if (!flag) {
                                                            setFieldValue("parentTeamId", currentParentTeamId);
                                                        }
                                                    }}
                                                    id="createTeamModalToggle1"
                                                />
                                            </FormGroup>
                                        )}

                                        {!isRootTeam && (
                                            <>
                                                <FormGroup>
                                                    <Label
                                                        className={clsx(isParentTeamError && "text-danger")}
                                                        isRequired
                                                    >
                                                        Родительская команда
                                                    </Label>
                                                    <ComboBox
                                                        placeholder="Выберите команду"
                                                        value={parentTeamSelectedOption}
                                                        menuIsOpen={false}
                                                        onMenuOpen={toggleParentTeamModal}
                                                        isSearchable={false}
                                                        isCreatable={false}
                                                        isModalOpen={parentTeamModalOpen}
                                                        components={{
                                                            DropdownIndicator: OptionsDropdownIndicator({
                                                                onClick: (e) => {
                                                                    if (
                                                                        !e.target.closest(
                                                                            ".ui-combo-box__multi-value__remove",
                                                                        )
                                                                    ) {
                                                                        toggleParentTeamModal();
                                                                    }
                                                                },
                                                            }),
                                                        }}
                                                    />
                                                    <ParentTeamChoiceModal
                                                        isOpen={parentTeamModalOpen}
                                                        isMulti={false}
                                                        selectable={true}
                                                        onClose={toggleParentTeamModal}
                                                        onSelect={(parentTeamId) => {
                                                            const id = parentTeamId[0]
                                                                .split("root:")
                                                                .filter((id) => id.length > 0)[0];
                                                            setFieldValue("parentTeamId", id).then();
                                                            setCurrentParentTeamId(id);
                                                            toggleParentTeamModal();
                                                            setCheckedTeamIds(parentTeamId);
                                                        }}
                                                        checkedTeamIds={checkedTeamIds}
                                                        onlyIncludes={
                                                            type === "edit"
                                                                ? getParentRootTeam(selectedTeam)
                                                                : undefined
                                                        }
                                                    />
                                                </FormGroup>
                                                {type !== "edit" && (
                                                    <FormGroup>
                                                        <Toggle
                                                            enabled={copyAccess}
                                                            label="Скопировать доступы из родительской команды"
                                                            onChange={setCopyAccess}
                                                            id="createTeamModalToggle2"
                                                        />
                                                    </FormGroup>
                                                )}
                                                {/*<FormGroup>*/}
                                                {/*    <Toggle*/}
                                                {/*        enabled={values.copyUsers}*/}
                                                {/*        label="Скопировать участников из родительской команды"*/}
                                                {/*        onChange={(flag) => setFieldValue("copyUsers", flag)}*/}
                                                {/*        id="createTeamModalToggle2"*/}
                                                {/*    />*/}
                                                {/*</FormGroup>*/}
                                            </>
                                        )}
                                    </div>
                                )}
                            </div>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button
                                key={0}
                                onClick={onClose}
                                variant="outline"
                                size="medium"
                                color={"secondary"}
                                className={"border-[#EAEDF3] "}
                                id="createTeamModalBtnCancel"
                            >
                                Отмена
                            </Button>

                            <SubmitButton
                                key={1}
                                onClick={handleSubmit}
                                size="medium"
                                color={type === "remove" ? "danger" : "primary"}
                                disabled={(values.name ?? "").length === 0 || (!isRootTeam && !values.parentTeamId)}
                                id="createTeamModalBtnOk"
                            >
                                {type === "create" && "Создать"}
                                {type === "edit" && "Сохранить"}
                                {type !== "create" && type !== "edit" && "Удалить"}
                            </SubmitButton>
                            <NestedMembersErrorModal
                                isOpen={nestedMembersError}
                                team={selectedTeam}
                                onClose={() => {
                                    setNestedMembersError(false);
                                    onClose();
                                }}
                            />
                        </Modal.Footer>
                    </>
                );
            }}
        </Formik>
    );
};

interface NestedMembersErrorModalProps {
    isOpen: boolean;
    onClose: TVoidFunction;
    team?: Partial<TeamTreeAllBasicInfoResponse>;
}

const NestedMembersErrorModal = ({ isOpen, onClose, team }: NestedMembersErrorModalProps) => {
    return (
        <Modal
            className="max-w-142"
            isOpen={isOpen}
            onClose={onClose}
            title="Удаление команды невозможно"
            description={team?.name}
        >
            <Modal.Body>
                Для удаления команды нужно исключить из нее и вложенных в нее команд всех пользователей, так как
                возможно удаление только пустых команд
            </Modal.Body>

            <Modal.Footer>
                <SecondaryButton onClick={onClose}>Закрыть</SecondaryButton>
            </Modal.Footer>
        </Modal>
    );
};
