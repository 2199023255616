import React, { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ColumnDef, ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Api from "Api";
import { AccountableUser, UserListResponse } from "Api/Responses/UserResponse";
import { BasePaginationRequest } from "Api/BaseRequest";
import { TaskReviewOperationMultipleRequest } from "Api/Requests/TaskPublicRequest";
import { TaskPublicVerificatedResponse } from "Api/Responses/TaskPublicResponse";
import { TaskListResponse } from "Api/Responses/TaskResponse";
import {
    Button,
    Checkbox,
    flash,
    FormGroup,
    Icon,
    Icons,
    Input,
    Label,
    ModalTree,
    OptionsDropdownIndicator,
    SelectAvatarMultiValueLabel,
    SelectMultiValueRemove,
    SelectValueContainer,
} from "Uikit";
import { StackedValueContainer } from "Uikit/Forms/SelectCustomComponents/StackedValueContainer";
import { Table } from "Uikit/Table/Table";
import { UserAvatar } from "Uikit/UserAvatar/UserAvatar";
import { Empty } from "Uikit/Page/Empty";
import { Confirmation } from "Components/Confirmation/Confirmation";
import { Filter, IFilterRow } from "Components/Filter/Filter";
import { useDialog } from "hooks/useDialog";
import { useInvalidate } from "hooks/useInvalidate";
import { ResourceType } from "Enums";
import { LogoSize } from "Api/Services/UploadApi";
import { useResponsibleList } from "Api/Hooks/useResponsibleList";

interface ICommentFieldProps {
    onChange: (comment: string) => void;
}

const CommentField = ({ onChange }: ICommentFieldProps) => {
    const [value, setValue] = useState("");

    useEffect(() => {
        onChange(value);
    }, [value, onChange]);

    return (
        <FormGroup className="mt-4">
            <Label isRequired={true}>Ваш комментарий</Label>
            <Input
                value={value}
                onChange={({ target: { value: v } }) => setValue(v)}
                placeholder="Введите комментарий"
            />
        </FormGroup>
    );
};

const getTreeNode = ({ id, logoId, title }: TaskListResponse, checked = false) => {
    return {
        id: id,
        logoId: logoId,
        type: ResourceType.EXERCISE,
        name: title,
        nodeType: "SECTION",
        children: null,
        state: {
            checked,
        },
    };
};

const structurizeTaskTreeData = (
    treeDataItems: TaskListResponse[],
    checkedNodes: { id: string; checked: boolean }[] = [],
) => {
    const treeData: any[] = [];
    try {
        treeDataItems.forEach((p) => {
            let categoryIndex = treeData.findIndex((p1) => p1.id === p.category.id);

            if (categoryIndex === -1) {
                treeData.push({
                    id: p.category.id,
                    nodeType: "PROJECT",
                    name: p.category.title,
                    children: [],
                    state: {
                        open: false,
                        checked: false,
                    },
                });

                categoryIndex = treeData.length - 1;
            }

            treeData[categoryIndex].children.push(
                getTreeNode(p, checkedNodes.find(({ id }) => id === p.id)?.checked /* ?? false */),
            );
        });
    } catch (e) {
        /**/
    }

    if (treeData.length !== 0) {
        treeData[0].state.open = true;
    }

    return treeData;
};

interface ITaskTabProps {
    queryParams: URLSearchParams;
}

export const TaskTab = ({ queryParams }: ITaskTabProps) => {
    const [rowSelection, setRowSelection] = useState({});
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
    const navigate = useNavigate();
    const invalidate = useInvalidate();
    const [selectedTask, setSelectedTask] = useState<TaskPublicVerificatedResponse[]>([]);
    const [sorting, setSorting] = useState<SortingState>([{ id: "statusChangeTime", desc: false }]);
    const [filters, setFilters] = useState<{ [id: string]: any }>({});
    const [isAcceptConfirmation, setIsAcceptConfirmation] = useState(false);
    const [isModalTreeOpen, setIsModalTreeOpen] = useState(false);
    const [tasksTree, setTasksTree] = useState<any[]>([]);
    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const [selectedAcceptedItems, setSelectedAcceptedItems] = useState<any[]>([]);
    const [selectedCount, setSelectedCount] = useState(0);
    const onFilterChangeRef = useRef<((name: string, value: any) => void) | null>(null);
    const [comment, setComment] = useState("");
    const commentRef = useRef("");

    const { dialogState, openDialog, closeDialog } = useDialog();

    const [isFilterShow, setIsFilterShow] = useState(false);

    const isFiltersEmpty = useMemo(() => {
        if (Object.keys(filters).indexOf("searchQuery.contains") !== -1) {
            return false;
        }

        return Object.keys(filters).every((f) => !filters[f]);
    }, [filters]);

    const [{ pageIndex, pageSize }, setPagination] = React.useState<PaginationState>({
        pageIndex: 0,
        pageSize: 15,
    });

    const pagination = React.useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize],
    );

    const dataQuery = useQuery(
        ["tasks", "collection", pagination, sorting, filters],
        async () => {
            const request = new BasePaginationRequest();
            request.page = pageIndex;
            request.size = pageSize;
            request.sort =
                sorting.map((s: any) => `${s.id},${s.desc ? "desc" : "asc"}`).join(";") || "statusChangeTime,desc";

            const filtersKeys = Object.keys(filters);
            const filtersData: any = {};

            for (const element of filtersKeys) {
                if (element === "statusChangeTime") {
                    filtersData[element + ".greaterThanOrEqual"] = Math.round(
                        filters[element]["date"]["startDate"].getTime() / 1000,
                    );
                    filtersData[element + ".lessThanOrEqual"] = Math.round(
                        filters[element]["date"]["endDate"].getTime() / 1000,
                    );
                } else if (element === "accountableUser.in") {
                    filtersData[element] = filters[element].map((p: any) => p.value.id).join(",");
                } else if (element === "user") {
                    filtersData[element + ".in"] = filters[element].map((p: any) => p.value.id).join(",");
                } else if (element === "team") {
                    filtersData[element + ".in"] = filters[element].join(",");
                } else if (element === "exercise") {
                    filtersData[element + ".in"] = filters[element].map((p: any) => p.value).join(",");
                } else {
                    filtersData[element] = filters[element];
                }
            }

            request.filters = filtersData;

            return await Api.TaskPublic.VerificatedList(request);
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );

    const onSearch = (searchQuery: string) => {
        setFilters((prevState) => ({ ...prevState, "searchQuery.contains": searchQuery }));
    };

    // all tasks request
    const taskTreeDataQuery = useQuery(
        ["tasksTree"],
        async () => {
            return await Api.Task.List({
                filters: {},
                page: 0,
                size: 999,
                sort: "lastModified,desc",
            });
        },
        {
            keepPreviousData: true,
        },
    );

    const { data: taskTreeData } = taskTreeDataQuery;

    useEffect(() => {
        return () => {
            queryParams.delete("task");
            window.history.pushState(null, "", `${location.pathname}?${queryParams.toString()}`);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (queryParams.has("task") && taskTreeData?.Content) {
            console.log(queryParams.get("task"));
            const taskFiltered = taskTreeData.Content.find(({ id }) => id === queryParams.get("task"));
            console.log(taskFiltered);
            if (taskFiltered) {
                setFilters((prevState) => ({
                    ...prevState,
                    exercise: [
                        {
                            label: taskFiltered.title,
                            value: taskFiltered.id,
                        },
                    ],
                }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskTreeData]);

    // Setting taskTree data
    useEffect(() => {
        if (taskTreeData?.Content) {
            setTasksTree(structurizeTaskTreeData(taskTreeData?.Content));
        }
    }, [taskTreeData]);

    const onSubmitSection = () => {
        setSelectedAcceptedItems(selectedItems);
        if (onFilterChangeRef.current) {
            onFilterChangeRef.current(
                "exercise",
                selectedItems.map(({ name: itemName, id }) => {
                    return {
                        label: itemName,
                        value: id,
                    };
                }),
            );
        }

        setIsModalTreeOpen(false);
    };

    const loadActiveUserOptions = async (
        inputValue: string,
        prevOptions: unknown,
        { page }: { page: number },
    ): Promise<any> => {
        if (inputValue === "") {
            return {
                options: [],
                hasMore: false,
            };
        }

        const users = await Api.User.GetActiveListOptions(inputValue, page);
        const usersSelectOptions: { label: string; value: AccountableUser | UserListResponse | undefined }[] =
            users.Content.map((p) => ({ label: p.lastName + " " + p.firstName, value: p }));

        return {
            options: usersSelectOptions,
            hasMore: users.TotalPages > page,
            additional: {
                page: page + 1,
            },
        };
    };

    const responsibleList = useResponsibleList();
    const loadResponsibleListOptions = useCallback(
        async (inputValue: string): Promise<any> => {
            if (inputValue === "") {
                return {
                    options: [],
                    hasMore: false,
                };
            }

            const selectOptions = responsibleList
                .filter((p) => p.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1)
                .map((p) => ({ label: p.label, value: p.payload }));

            return {
                options: selectOptions,
                hasMore: false,
            };
        },
        [responsibleList],
    );

    const formatUserOptionsLabel = ({
        label,
        value,
    }: {
        label: string;
        value: AccountableUser | UserListResponse | undefined;
    }): React.ReactElement => {
        return (
            <div className="flex gap-2">
                {value?.avatarId ? (
                    <img
                        src={`/service/lms-upload/api/file/download/${value.avatarId}`}
                        className="w-5 h-5 rounded-full"
                    />
                ) : (
                    <div
                        className={`flex-center w-5 h-5 rounded-full`}
                        style={{ backgroundColor: value?.defaultAvatarColor }}
                    >
                        <span className="text-white text-xxs">
                            {value?.firstName[0]}
                            {value?.lastName[0]}
                        </span>
                    </div>
                )}
                <div>{label}</div>
            </div>
        );
    };

    useEffect(() => {
        setSelectedCount(selectedItems.length);
    }, [selectedItems]);

    useEffect(() => {
        if (selectedAcceptedItems) {
            setTasksTree(
                structurizeTaskTreeData(
                    taskTreeData?.Content as unknown as TaskListResponse[],
                    selectedAcceptedItems.map(({ id, state }) => {
                        return {
                            id,
                            checked: state.checked,
                        };
                    }),
                ),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAcceptedItems]);

    useEffect(() => {
        if (taskTreeData) {
            const selectedAcceptedItemsCur = filters.exercise
                ? (taskTreeData.Content as unknown as TaskListResponse[])
                      .filter(({ id: taskId }) =>
                          filters.exercise.find(({ value }: { value: string }) => value === taskId),
                      )
                      .map((item) => {
                          return getTreeNode(item, true);
                      })
                : [];

            setSelectedAcceptedItems(selectedAcceptedItemsCur);
            setSelectedItems(selectedAcceptedItemsCur);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    const { mutateAsync: reviewOperation } = useMutation((payload: TaskReviewOperationMultipleRequest) => {
        return Api.TaskPublic.ReviewOperationMultiple(payload);
    });

    const TasksColumns = React.useMemo<ColumnDef<TaskPublicVerificatedResponse>[]>(
        () => [
            {
                id: "select",
                enableResizing: true,
                size: 16,
                header: ({ table }) => (
                    <Checkbox
                        checked={table.getIsAllRowsSelected()}
                        indeterminate={table.getIsSomeRowsSelected()}
                        onChange={table.getToggleAllRowsSelectedHandler()}
                    />
                ),
                cell: ({ row }) => (
                    <Checkbox
                        checked={row.getIsSelected()}
                        indeterminate={row.getIsSomeSelected()}
                        onChange={row.getToggleSelectedHandler()}
                    />
                ),
            },
            {
                header: "название",
                footer: (props) => props.column.id,
                cell: (info) => {
                    const {
                        row: {
                            original: { logoId, title },
                        },
                    } = info;
                    return (
                        <div className="group flex items-center space-x-3">
                            <img
                                className="rounded-md w-[54px] h-9 object-cover"
                                src={Api.Upload.GetLogo(logoId, LogoSize.THUMB_MICRO)}
                            />
                            <span className="group-hover:text-blue">{title}</span>
                        </div>
                    );
                },
                accessorKey: "title",
            },
            {
                header: "участник",
                enableResizing: true,
                size: 250,
                cell: (info) => {
                    const {
                        row: {
                            original: {
                                user: { avatarId, firstName, lastName, defaultAvatarColor },
                            },
                        },
                    } = info;
                    return (
                        <div className="flex items-center space-x-3">
                            <UserAvatar
                                avatarId={avatarId}
                                color={defaultAvatarColor}
                                userInitials={`${firstName?.slice(0, 1)}${lastName?.slice(0, 1)}`}
                                size={36}
                                // className="absolute bottom-6 left-6 ring-white ring-4 rounded-full"
                            />
                            <span>{`${lastName} ${firstName}`}</span>
                        </div>
                    );
                },
                accessorKey: "user.firstName,user.lastName",
                footer: (props) => props.column.id,
                defaultSortOrder: "asc",
            },
            {
                header: "прохождение",
                enableResizing: true,
                size: 200,
                accessorKey: "passingNumber",
                footer: (props) => props.column.id,
            },
            {
                header: "отправлено",
                enableResizing: true,
                size: 200,
                cell: (info) => {
                    const {
                        row: {
                            original: { statusChangeTime },
                        },
                    } = info;
                    return (
                        <div className="flex items-center space-x-3 truncate">
                            <p className="font-normal">
                                {!!statusChangeTime &&
                                    new Date(statusChangeTime * 1000).toLocaleDateString("ru-ru", {
                                        year: "numeric",
                                        month: "long",
                                        day: "numeric",
                                        hour: "numeric",
                                        minute: "numeric",
                                    })}
                            </p>
                        </div>
                    );
                },
                accessorKey: "statusChangeTime",
                footer: (props) => props.column.id,
            },
        ],
        [],
    );

    const TasksControlButtons = (
        <div className="flex justify-end items-center space-x-4" id="validationsTaskControlButtons">
            <Button
                variant="outline"
                color="secondary"
                size="medium"
                className="border-[#E6E9ED] rounded-lg font-medium"
                icon={<Icon icon={Icons.Filter} width={20} height={20} color="stroke-blue" />}
                iconPlacement={"left"}
                onClick={() => setIsFilterShow(!isFilterShow)}
                id="adminValidationsTaskTabBtnFilter"
            >
                Фильтры
            </Button>
        </div>
    );

    const filtersConfig = [
        {
            label: "Дата отправки",
            fields: [
                {
                    accessor: "statusChangeTime",
                    type: "date-range",
                },
            ],
        },
        {
            label: "Команда",
            fields: [
                {
                    accessor: "team",
                    type: "team-multi-select",
                },
            ],
        },
        {
            label: "Участники",
            fields: [
                {
                    accessor: "user",
                    type: "async-multi-select",
                    placeholder: "Выберите участника",
                    loadOptions: loadActiveUserOptions,
                    formatOptionLabel: formatUserOptionsLabel,
                    components: {
                        MultiValueRemove: SelectMultiValueRemove,
                        ValueContainer: SelectValueContainer({}),
                    },
                    lazy: true,
                },
            ],
        },
        {
            label: "Задания",
            fields: [
                {
                    accessor: "exercise",
                    type: "multi-select",
                    default: "",
                    placeholder: "Выберите задание",
                    options: [],
                    components: {
                        MultiValueLabel: SelectAvatarMultiValueLabel({ withAvatar: false }),
                        MultiValueRemove: SelectMultiValueRemove,
                        // ValueContainer: SelectValueContainer({
                        //     onClick: (e) => {
                        //         if (!e.target.closest(".ui-combo-box__multi-value__remove")) {
                        //             setIsModalTreeOpen((prevIsOpen) => {
                        //                 return !prevIsOpen;
                        //             });
                        //         }
                        //     },
                        // }),
                        ValueContainer: StackedValueContainer,
                        DropdownIndicator: OptionsDropdownIndicator({
                            onClick: (e) => {
                                if (!e.target.closest(".ui-combo-box__multi-value__remove")) {
                                    setIsModalTreeOpen((prevIsOpen) => {
                                        return !prevIsOpen;
                                    });
                                }
                            },
                        }),
                    },
                    onClick: (e: SyntheticEvent) => {
                        if (!(e.target as HTMLElement).closest(".ui-combo-box__multi-value__remove")) {
                            setIsModalTreeOpen((prevIsOpen) => {
                                return !prevIsOpen;
                            });
                        }
                    },
                    menuIsOpen: false,
                    isModalOpen: isModalTreeOpen,
                },
            ],
        },
        {
            label: "Ответственный",
            fields: [
                {
                    accessor: "accountableUser.in",
                    type: "async-multi-select",
                    placeholder: "Выберите ответственного",
                    loadOptions: loadResponsibleListOptions,
                    formatOptionLabel: formatUserOptionsLabel,
                    components: {
                        MultiValueRemove: SelectMultiValueRemove,
                        ValueContainer: SelectValueContainer({}),
                    },
                    lazy: true,
                },
            ],
        },
    ];

    const onAcceptHandler = useCallback(
        async (data: TaskPublicVerificatedResponse[]) => {
            setIsAcceptConfirmation(true);
            openDialog({
                title: data.length > 1 ? "Принять задания" : "Принять задание",
                description: data.length > 1 ? `Выбрано элементов: ${data.length}` : "«" + data[0].title + "»",
                content:
                    data.length > 1
                        ? "Вы уверены, что хотите принять задания? Вы подтверждаете, что пользователи все выполнили верно"
                        : "Вы уверены, что хотите принять задание? Вы подтверждаете, что пользователи все выполнили верно",
                closeBtnText: "Отмена",
                submitBtnText: "Принять",
                submitBtnColor: "primary",
                onRequestClose: () => closeDialog(),
                onRequestSubmit: () => {
                    reviewOperation({
                        exerciseIds: selectedTask.map(({ id }) => id),
                        isApproved: true,
                        comment: "",
                    }).then(() => {
                        invalidate("tasks");
                        invalidate("review");

                        closeDialog();
                        setIsAcceptConfirmation(false);

                        flash.success(data.length > 1 ? "Задания приняты!" : "Задание принято!");
                        setRowSelection([]);
                    });
                },
            });
        },
        [invalidate, closeDialog, openDialog, selectedTask, reviewOperation],
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onDeclineSubmit = async (data: TaskPublicVerificatedResponse[]) => {
        await reviewOperation({
            exerciseIds: selectedTask.map(({ id }) => id),
            isApproved: false,
            comment: commentRef.current,
        });

        invalidate("tasks");
        invalidate("review");
        closeDialog();
        setIsAcceptConfirmation(false);
        flash.success(data.length > 1 ? "Задания отклонены!" : "Задание отклонено!");
        setRowSelection([]);
        setComment("");
    };

    const onDeclineHandler = useCallback(
        async (data: TaskPublicVerificatedResponse[]) => {
            openDialog({
                title: data.length > 1 ? "Отклонить задания" : "Отклонить задание",
                description: data.length > 1 ? `Выбрано элементов: ${data.length}` : "«" + data[0].title + "»",
                content: (
                    <>
                        {
                            <>
                                {data.length > 1
                                    ? "Вы уверены, что хотите отклонить задания? Они вернутся пользователю и ему придется их заново перепройти"
                                    : "Вы уверены, что хотите отклонить задание? Вы подтверждаете, что пользователи все выполнили верно"}
                                <CommentField
                                    onChange={(value: string) => {
                                        commentRef.current = value;
                                        setComment(value);
                                    }}
                                />
                            </>
                        }
                    </>
                ),
                closeBtnText: "Отмена",
                submitBtnText: "Отклонить",
                submitBtnColor: "danger",
                onRequestClose: () => {
                    setComment("");
                    closeDialog();
                },
                onRequestSubmit: () => {
                    onDeclineSubmit(data);
                },
            });
        },
        [openDialog, closeDialog, onDeclineSubmit, setComment],
    );

    const selectedRowButtons = (
        <div className="flex items-center space-x-5" id="validationsTaskSelectedRowButtons">
            <Button
                onClick={() => {
                    onAcceptHandler(selectedTask).then();
                }}
                color="primary"
                size="medium"
                className="border-[#E6E9ED] rounded-lg font-medium"
                id="adminValidationsTaskTabBtnOk"
            >
                Принять {selectedTask.length > 1 ? "задания" : "задание"}
            </Button>
            <Button
                onClick={() => {
                    onDeclineHandler(selectedTask);
                }}
                color="danger"
                size="medium"
                className="border-[#E6E9ED] rounded-lg font-medium"
                id="adminValidationsTaskTabBtnCancel"
            >
                Отклонить {selectedTask.length > 1 ? "задания" : "задание"}
            </Button>
        </div>
    );

    return (
        <div className="h-full flex flex-col grow" id="validationsTaskTab">
            <Confirmation {...dialogState} requestSubmitDisabled={!comment && !isAcceptConfirmation} />

            {isFiltersEmpty && dataQuery.data?.Content && dataQuery.data?.Content.length === 0 && (
                <Empty
                    title="Все задания проверены!"
                    description="Любые задания, отправленные на проверку участниками, отражаются на данной вкладке"
                    topElement={
                        <div className="flex-center mb-4">
                            <div className="flex-center w-16 h-16 rounded-full bg-blue-10">
                                <Icon icon={Icons.EmojiHappy} width={"36px"} height={"36px"} color={"fill-primary"} />
                            </div>
                        </div>
                    }
                ></Empty>
            )}

            {!isFiltersEmpty || (dataQuery.data?.Content && dataQuery.data?.Content.length > 0) ? (
                <>
                    <Filter
                        isActive={isFilterShow}
                        setIsActive={setIsFilterShow}
                        configuration={filtersConfig as IFilterRow[]}
                        filters={filters}
                        onChange={setFilters}
                        onFilterChangeRef={onFilterChangeRef}
                    />
                    <Table
                        id="adminValidationsTaskTab"
                        columns={TasksColumns}
                        searchTitle="Поиск по названию"
                        data={dataQuery.data?.Content}
                        isFetching={dataQuery.isFetching}
                        pagination={pagination}
                        onPaginationChange={setPagination}
                        pageCount={dataQuery.data?.TotalPages}
                        rowSelection={rowSelection}
                        onRowSelectionChange={setRowSelection}
                        onColumnFiltersChange={setColumnFilters}
                        onRowClick={(row) => {
                            navigate(`/admin/validations/task/${row.original.id}`, {
                                replace: true,
                                state: row.original,
                            });
                        }}
                        columnFilters={columnFilters}
                        controlButtons={TasksControlButtons}
                        selectButtons={selectedRowButtons}
                        setSelectedRows={setSelectedTask}
                        emptyMessage={"По заданным параметрам результатов нет"}
                        sorting={sorting}
                        onSortingChange={setSorting}
                        bodyCellClassName={"cursor-pointer"}
                        onSearch={onSearch}
                        defaultSortOrder={{
                            "user_firstName,user.lastName": "asc",
                        }}
                    />
                </>
            ) : null}

            <ModalTree
                isOpen={isModalTreeOpen}
                title={`Выбор задания`}
                setIsOpen={(open: boolean) => {
                    setSelectedItems(selectedAcceptedItems);
                    setTasksTree(
                        structurizeTaskTreeData(
                            taskTreeData?.Content as unknown as TaskListResponse[],
                            selectedAcceptedItems.map(({ id, state }) => {
                                return {
                                    id,
                                    checked: state.checked,
                                };
                            }),
                        ),
                    );

                    setIsModalTreeOpen(open);
                }}
                treeData={tasksTree}
                checkedChange={(selectedNodes) => {
                    setSelectedItems(selectedNodes);
                }}
                onSubmit={onSubmitSection}
                submitButtonTitle="Выбрать"
                selectedCount={selectedCount}
            />
        </div>
    );
};
