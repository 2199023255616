import React, { useCallback, useEffect, useState, useLayoutEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import clsx from "clsx";
import Api from "Api";
import { CommonsAddFavoritesRequest, CommonsRemoveFavoritesRequest } from "Api/Requests/CommonsRequest";
import { Breadcrumbs, Button, Icon, Icons, Loader, UserAvatar, FavoriteButton } from "Uikit";
import { ContentLayout } from "Containers";
import { ProgressStatus, ResourceState, ResourceType } from "Enums";
import { numCapEnd } from "helpers/numCapEnd";
import { useNavigateToSource } from "hooks/useNavigateToSouce";
import { TaskCardTime } from "../Tasks/TaskCardTime";
import { formatCheckTime, formatDeadlineTime } from "../Tasks/utils";
import { setIsBack, setIsFavorite, setIsFavoriteSelected, setIsHidden, setTitle } from "slices/headerSlice";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { Preloader } from "Components/Preloader/Preloader";
import { IReducer } from "store";
import { LogoSize } from "Api/Services/UploadApi";
import { NoAccess } from "Components/Stub/NoAccess";
import classNames from "classnames";
import { BadRequestResponse, ErrorCode } from "Api/BaseResponse";

export const Task = () => {
    const dispatch = useDispatch();
    const isFavoriteSelected = useSelector((state: IReducer) => state.header.isFavoriteSelected);
    const searchParams = new URL(window.location.href).searchParams;
    const progressId = searchParams?.get("progressId");

    const { id } = useParams();
    const navigate = useNavigate();

    const [coverLoading, setCoverLoading] = useState(true);
    const [isAddFavoritePressed, setIsAddFavoritePressed] = useState(false);
    const [isProgressStatusChecked, setIsProgressStatusChecked] = useState(false);

    const { goSourcePage } = useNavigateToSource("/tasks");

    const dataQuery = useQuery(
        ["task", "collection", id],
        async () => {
            const res = await Api.TaskPublic.Preview({ uuid: id as string, progressId });

            if (res.progressId !== progressId) {
                searchParams.delete("progressId");
                window.history.pushState(null, "", `${location.pathname}`);
            }

            dispatch(setIsFavoriteSelected(res.isFavorite));
            setIsAddFavoritePressed(res.isFavorite);

            return res;
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );

    const { data, isLoading, refetch, isFetched, error } = dataQuery;

    const goBack = () => goSourcePage();

    const goPassing = () => {
        if (data) {
            navigate(`/task/${data.progressId}/passing`);
        }
    };
    const availablePassingCountUnits = numCapEnd(
        { one: "раз", two: "раза", few: "раз" },
        data?.availablePassingCount ?? 0,
    );

    // Добавление в избранное
    const { mutateAsync: addFavorites } = useMutation(
        (payload: CommonsAddFavoritesRequest) => {
            return Api.Favorite.Add(payload);
        },
        {
            onSuccess: () => {
                refetch();
            },
        },
    );
    const handleAddFavorites = useCallback(() => {
        addFavorites(
            Object.assign(new CommonsAddFavoritesRequest(), {
                resourceId: data?.resourceId,
                resourceSolutionId: data?.progressId,
                title: data?.title,
                logoId: data?.logoId,
                deadlineTimestamp: data?.deadlineTime ?? 0,
                ratingPoints: data?.ratingPoints,
                state: ResourceState.ACTIVE,
                type: ResourceType.EXERCISE,
                progressStatus: data?.progressStatus,
                passingNumber: data?.passingNumber,
            }),
        ).then();
        setIsAddFavoritePressed(true);
        dispatch(setIsFavoriteSelected(true));
    }, [dispatch, data, addFavorites]);

    // Удаление из избранного
    const { mutateAsync: removeFavorites } = useMutation(
        (payload: CommonsRemoveFavoritesRequest) => {
            return Api.Commons.removeFavorites(payload);
        },
        {
            onSuccess: () => {
                refetch();
            },
        },
    );
    const handleRemoveFavorites = useCallback(() => {
        removeFavorites(
            Object.assign(new CommonsRemoveFavoritesRequest(), {
                resourceId: data?.resourceId,
                passingNumber: data?.passingNumber,
                type: ResourceType.EXERCISE,
            }),
        ).then();
        setIsAddFavoritePressed(false);
        dispatch(setIsFavoriteSelected(false));
    }, [dispatch, data, removeFavorites]);

    useEffect(() => {
        dispatch(setIsHidden(false));
        dispatch(setIsBack(true));
        dispatch(setTitle("Задание"));
        dispatch(setIsFavorite(true));
    }, [dispatch]);

    useLayoutEffect(() => {
        if (data && [ProgressStatus.PASSED, ProgressStatus.ON_REVIEW].includes(data.progressStatus as ProgressStatus)) {
            navigate(`/task/${data.progressId}/statistics`);
        } else {
            setIsProgressStatusChecked(true);
        }
    }, [data, id, navigate]);

    useEffect(() => {
        if (window.screen.width > 568 || !data || isFavoriteSelected === isAddFavoritePressed) {
            return;
        }

        isFavoriteSelected ? handleAddFavorites() : handleRemoveFavorites();
    }, [isFavoriteSelected, data, isAddFavoritePressed, handleAddFavorites, handleRemoveFavorites]);

    const isNoPermissionError =
        error && error instanceof BadRequestResponse && error.errorCode === ErrorCode.NO_RESOURCE_ACCESS;
    const isNoTaskError =
        error && error instanceof BadRequestResponse && error.errorCode === ErrorCode.EXERCISE_NOT_FOUND;

    const showNoAccess = (isFetched && isNoPermissionError) as boolean;
    const showNoTask = (isFetched && isNoTaskError) as boolean;

    return (
        <ContentLayout className="mx-auto sm:pl-4 sm:pr-6.5 sm:max-w-[1216px] 2xl:max-w-[1506px] h-full ">
            <Preloader className="flex justify-center h-[calc(100vh-72px)]" isShow={isLoading}>
                <div className="w-175 2xl:w-[875px]">
                    <Skeleton className="mt-14 mb-5 rounded-3xl 2xl:!h-[492px]" width="100%" height="400px" />
                    <Skeleton className="mb-5 rounded-2xl 2xl:!h-[41px]" width="100%" height="33px" />
                    <Skeleton className="mb-5.5 rounded-2xl 2xl:!h-[48px]" width="100%" height="40px" />
                    <Skeleton className="mb-0.5 rounded-2xl 2xl:!h-[50px]" width="100%" height="40px" />
                    <Skeleton className="rounded-2xl 2xl:!h-[48px]" width="100%" height="40px" />
                </div>
            </Preloader>
            <div className={classNames("relative", (showNoAccess || showNoTask) && "flex-grow")}>
                {(showNoAccess || showNoTask) && <NoAccess className="flex-grow" />}
                {!showNoAccess && data && isProgressStatusChecked && (
                    <>
                        <Breadcrumbs className="hidden sm:block">
                            <Breadcrumbs.Link title="Задание" url="/tasks" />
                            {data?.title && <Breadcrumbs.Link title={data?.title} />}
                        </Breadcrumbs>
                        <div className="pb-17.5 pt-6 relative">
                            <Button
                                className="hidden sm:block !p-0 absolute -top-3 right-0 !border-0 hover:!shadow-none active:!bg-transparent active:!ring-0 2xl:w-6.25 2xl:h-6.25"
                                variant="outline"
                                color="secondary"
                                onClick={goBack}
                            >
                                <Icon
                                    icon={Icons.Close}
                                    width={20}
                                    height={20}
                                    color="fill-[#939393]"
                                    className="2xl:!w-6.25 2xl:!h-6.25"
                                />
                            </Button>
                            <div className="flex flex-col items-center w-full sm:w-[700px] 2xl:w-[875px] mx-auto">
                                <div className="relative w-full h-[60vw] sm:h-[400px] 2xl:h-[492px] mb-5 2xl:mb-6.25">
                                    <div className="absolute top-3 2xl:top-6.25 left-3 2xl:left-6.25 right-3 2xl:right-6.25 flex gap-1.5 2xl:gap-2">
                                        {data?.deadlineTime && (
                                            <TaskCardTime time={data?.deadlineTime}>
                                                <>
                                                    <Icon
                                                        icon={Icons.Time}
                                                        width={"14px"}
                                                        height={"14px"}
                                                        color={"fill-white"}
                                                        className="2xl:!w-4.5 2xl:!h-4.5"
                                                    />
                                                    <span>{formatDeadlineTime(data?.deadlineTime)}</span>
                                                </>
                                            </TaskCardTime>
                                        )}
                                        {!!data?.comment?.checkedTime && (
                                            <div className="rounded-md bg-black-50 text-white flex items-center gap-1.5 p3 px-1.5 2xl:px-2 py-0.5 2xl:py-0.75 2xl:text-2sm">
                                                <span>
                                                    <span className="hidden sm:inline-block">Отправлено</span>{" "}
                                                    {formatCheckTime(data?.comment?.checkedTime, { showTime: true })}
                                                </span>
                                            </div>
                                        )}
                                        {(data?.availablePassingCount === null || data?.availablePassingCount > 1) && (
                                            <div className="rounded-md bg-black-50 text-white flex items-center gap-1.5 p3 px-1.5 2xl:px-2 py-0.5 2xl:py-0.75 2xl:text-2sm">
                                                <span>{data?.passingNumber} прохождение</span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="absolute top-5 2xl:top-6.25 right-5 2xl:right-6.25">
                                        <FavoriteButton
                                            className="hidden sm:flex"
                                            onChange={
                                                data?.isFavorite || isAddFavoritePressed
                                                    ? handleRemoveFavorites
                                                    : handleAddFavorites
                                            }
                                            isFavorite={
                                                (data?.isFavorite && !isAddFavoritePressed) || isAddFavoritePressed
                                            }
                                        />
                                    </div>
                                    {coverLoading && (
                                        <div className="top-1/2 left-1/2 absolute -translate-x-1/2 -translate-y-1/2">
                                            <Loader />
                                        </div>
                                    )}
                                    <img
                                        className={clsx(
                                            "rounded-3xl 2xl:rounded-3.5xl object-cover w-full h-full",
                                            coverLoading && "invisible",
                                        )}
                                        src={Api.Upload.GetLogo(data.logoId, LogoSize.ORIGINAL)}
                                        onLoad={() => {
                                            setCoverLoading(false);
                                        }}
                                    />
                                </div>
                                {[ProgressStatus.FAILED, ProgressStatus.ON_REVIEW, ProgressStatus.RE_WORK].includes(
                                    data.progressStatus,
                                ) && (
                                    <div className="w-full sm:text-center mb-1.5 2xl:mb-2">
                                        <span
                                            className={clsx(
                                                "inline-block py-0.5 2xl:py-0.75 px-1.5 2xl:px-2 rounded-md 2xl:rounded-lg text-white text-[12px] 2xl:text-2sm",
                                                data.progressStatus === ProgressStatus.ON_REVIEW && "bg-primary",
                                                [ProgressStatus.RE_WORK, ProgressStatus.FAILED].includes(
                                                    data.progressStatus,
                                                ) && "bg-red",
                                            )}
                                        >
                                            {data.progressStatus === ProgressStatus.ON_REVIEW && "На проверке"}
                                            {[ProgressStatus.RE_WORK, ProgressStatus.FAILED].includes(
                                                data.progressStatus,
                                            ) && (
                                                <>
                                                    Отклонено{" "}
                                                    {new Date(data.statusChangeTime * 1000).toLocaleDateString(
                                                        "ru-ru",
                                                        {
                                                            day: "numeric",
                                                            month: "short",
                                                        },
                                                    )}
                                                </>
                                            )}
                                        </span>
                                    </div>
                                )}
                                <div className="w-full sm:w-auto sm:text-center">
                                    <h1
                                        className="hidden sm:block mb-2 2xl:mb-2.5 sm:max-w-175 2xl:max-w-[875px] 2xl:!text-4xl 2xl:!leading-[41px] text-center text-black break-anywhere"
                                        id="userTaskTitle"
                                    >
                                        {data.title}
                                    </h1>
                                    <h2 className="block sm:hidden mb-2 w-full text-black break-anywhere">
                                        {data.title}
                                    </h2>
                                    <p
                                        className="mb-4 sm:mb-5 2xl:mb-6.25 w-full sm:max-w-175 2xl:max-w-[875px] sm:text-center p3 2xl:text-md text-gray-dark sm:text-blue-drk break-anywhere"
                                        id="userTaskDescription"
                                    >
                                        {data.description}
                                    </p>
                                </div>
                                {data.progressStatus !== ProgressStatus.ON_REVIEW && (
                                    <div className="hidden sm:block mb-7 2xl:mb-9 text-center">
                                        <Button
                                            size="medium"
                                            className="rounded-lg whitespace-nowrap font-medium"
                                            icon={
                                                <Icon
                                                    className="2xl:!w-6.25 2xl:!h-6.25"
                                                    icon={Icons.PlayFilled}
                                                    color="fill-white"
                                                    width={20}
                                                    height={20}
                                                />
                                            }
                                            iconPlacement={"left"}
                                            onClick={goPassing}
                                            id="userTaskingBtn"
                                        >
                                            {data.progressStatus === ProgressStatus.NOT_STARTED && "Пройти задание"}
                                            {data.progressStatus === ProgressStatus.IN_PROGRESS &&
                                                "Продолжить прохождение"}
                                            {[ProgressStatus.RE_WORK, ProgressStatus.FAILED].includes(
                                                data.progressStatus,
                                            ) && "Пройти заново"}
                                        </Button>
                                    </div>
                                )}
                                {data.progressStatus !== ProgressStatus.ON_REVIEW && (
                                    <div className="fixed bottom-14 left-0 flex sm:hidden p-2.5 w-full z-10 bg-white">
                                        <Button
                                            size="large"
                                            className="w-full"
                                            icon={
                                                <Icon
                                                    className="mr-1.5"
                                                    icon={Icons.PlayCircle}
                                                    color="fill-white"
                                                    width={17}
                                                    height={17}
                                                />
                                            }
                                            iconPlacement="left"
                                            onClick={goPassing}
                                            id="userTaskingBtn"
                                        >
                                            {data.progressStatus === ProgressStatus.NOT_STARTED && "Пройти задание"}
                                            {data.progressStatus === ProgressStatus.IN_PROGRESS &&
                                                "Продолжить прохождение"}
                                            {[ProgressStatus.RE_WORK, ProgressStatus.FAILED].includes(
                                                data.progressStatus,
                                            ) && "Пройти заново"}
                                        </Button>
                                    </div>
                                )}
                                <div
                                    className="flex flex-col sm:flex-row flex-wrap justify-center mb-2 sm:mb-12 w-full sm:w-125 2xl:w-[875px]"
                                    id="userTestingInfo"
                                >
                                    {!!data.availablePassingCount && data.availablePassingCount > 0 && (
                                        <p className="mb-2 2xl:mb-2.5 sm:px-3 2xl:px-3.75 sm:border-r sm:border-gray-stroke p4 sm:p3 2xl:p2 text-blue-drk">
                                            <span className="inline-block w-30 sm:w-auto">Можно выполнить:&nbsp;</span>
                                            <span className="p3 2xl:p2">
                                                {data.availablePassingCount} {availablePassingCountUnits}
                                            </span>
                                        </p>
                                    )}
                                    <p className="mb-2 2xl:mb-2.5 sm:px-3 2xl:px-3.75 sm:border-r sm:border-gray-stroke p4 sm:p3 2xl:p2 text-blue-drk">
                                        <span className="inline-block w-30 sm:w-auto">Вопросов:&nbsp;</span>
                                        <span className="p3 2xl:p2">{data.questionCount}</span>
                                    </p>
                                    <p className="mb-2 2xl:mb-2.5 sm:px-3 2xl:px-3.75 p4 sm:p3 2xl:p2 text-blue-drk">
                                        <span className="inline-block w-30 sm:w-auto">Макс. баллов:&nbsp;</span>
                                        <span className="p3 2xl:p2">{data.ratingPoints}</span>
                                    </p>
                                    {[
                                        ProgressStatus.FAILED,
                                        ProgressStatus.ON_REVIEW,
                                        ProgressStatus.IN_PROGRESS,
                                        ProgressStatus.RE_WORK,
                                    ].includes(data.progressStatus) && (
                                        <p className="mb-2 2xl:mb-2.5 sm:px-3 2xl:px-3.75 sm:border-l sm:border-gray-stroke p3 2xl:p2">
                                            <Link
                                                className="inline-flex items-center gap-1 2xl:gap-1.25 !text-primary hover:!no-underline"
                                                to={`/task/${data.progressId}/statistics`}
                                                target="_blank"
                                            >
                                                <Icon
                                                    icon={Icons.ShareBox}
                                                    color="fill-primary"
                                                    width={12}
                                                    height={12}
                                                    className="2xl:!w-3.75 2xl:!h-3.75"
                                                />{" "}
                                                Статистика
                                            </Link>
                                        </p>
                                    )}
                                </div>
                            </div>
                            {data.comment && (
                                <div className="mt-9 pt-9 border-t border-blue-gray">
                                    <div className="w-full sm:w-[700px] mx-auto">
                                        <div className="w-full">
                                            <h2 className="mb-5 text-left ">Комментарий проверяющего</h2>
                                            <div className="py-4 sm:py-5 px-3 sm:px-9 rounded-xl border border-gray-stroke relative">
                                                {!data.comment.isApproved && (
                                                    <span
                                                        className={
                                                            "absolute right-3 sm:right-9 top-4 sm:top-5 inline-block py-0.5 px-2 rounded-md text-white text-[12px] bg-red"
                                                        }
                                                    >
                                                        Отклонено
                                                    </span>
                                                )}
                                                <div className="flex gap-3.5 mb-4">
                                                    <UserAvatar
                                                        avatarId={data.comment.user.avatarId}
                                                        color={data.comment.user.defaultAvatarColor}
                                                        userInitials={`${data.comment.user.firstName?.slice(
                                                            0,
                                                            1,
                                                        )}${data.comment.user.lastName?.slice(0, 1)}`}
                                                        size={36}
                                                    />
                                                    <div>
                                                        <p className="p2 mb-0.5">
                                                            {data.comment.user.firstName} {data.comment.user.lastName}
                                                        </p>
                                                        {data.comment.user.jobTitle?.name && (
                                                            <p className="p3 text-gray-text">
                                                                {data.comment.user.jobTitle?.name}
                                                            </p>
                                                        )}
                                                        {!!data.comment.checkedTime && (
                                                            <p className="p3 text-gray-text">
                                                                Проверил{" "}
                                                                {formatCheckTime(Number(data.comment.checkedTime), {
                                                                    showTime: true,
                                                                })}
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="flex gap-3.5">
                                                    <p>{data.comment.comment}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
        </ContentLayout>
    );
};
