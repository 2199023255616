import React, { useState } from "react";
import { Button, PopoverList } from "Uikit";
import { ProgramListResponse } from "Api/Responses/ProgramResponse";

interface RowActionPopoverProps {
    program: ProgramListResponse;
    onCopyHandler: (program: ProgramListResponse) => void;
    onArchiveHandler: (program: ProgramListResponse) => void;
    onCopyToCompanyHandler: (program: ProgramListResponse) => void;
    isRoot: boolean;
}

export const ProgramsRowActionPopover = ({
    program,
    onCopyHandler,
    onArchiveHandler,
    onCopyToCompanyHandler,
    isRoot,
}: RowActionPopoverProps) => {
    const [visible, setVisible] = useState(false);

    const onCopy = () => {
        onCopyHandler(program);
        setVisible(false);
    };
    const onArchive = () => {
        onArchiveHandler(program);
        setVisible(false);
    };
    const onCopyToCompany = () => {
        onCopyToCompanyHandler(program);
        setVisible(false);
    };

    return (
        <div>
            <PopoverList
                offset={[0, 18]}
                visible={visible}
                onClickOutside={() => setVisible(false)}
                className={`${isRoot ? "!w-56" : ""}`}
            >
                <Button
                    shape="round"
                    color="common"
                    className="!text-blue-drk hover:!text-blue-hover"
                    onClick={() => setVisible(!visible)}
                >
                    ...
                </Button>
                <PopoverList.Item name="copy" onClickItem={onCopy}>
                    Создать копию
                </PopoverList.Item>
                <PopoverList.Item name="archive" onClickItem={onArchive}>
                    Архивировать
                </PopoverList.Item>
                {isRoot && (
                    <PopoverList.Item name="archive" onClickItem={onCopyToCompany}>
                        Копировать в компанию
                    </PopoverList.Item>
                )}
            </PopoverList>
        </div>
    );
};
