import React, { RefObject } from "react";
import clsx from "clsx";
import TextareaAutosize, { TextareaAutosizeProps } from "react-textarea-autosize";
import { Tooltip } from "Uikit/Tooltip";
import { Icon, Icons } from "Uikit/Icon/Icon";
import { useScreenSize } from "hooks/useMediaQuery";

const sizes = {
    small: "px-3 py-1.5 text-sm leading-sm",
    medium: "px-4.5 py-2.2 text-base leading-base 2xl:py-3 2xl:px-5.5 2xl:text-md 2xl:!rounded-2lg",
    large: "px-5 py-3.5 text-md leading-md",
};

// eslint-disable-next-line no-useless-escape
const regexp = new RegExp(/[^а-яА-ЯёЁa-zA-Z0-9"'\(\)«»!@#№$%&*_ +=?,.\-;:\/]/gi);

interface ITextareaAutoSizableProps extends TextareaAutosizeProps {
    minRows?: number;
    value?: string | number;
    maxLength?: number;
    cutRegExp?: RegExp;
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    error?: string;
    errorClassName?: string;
    inputRef?: RefObject<HTMLTextAreaElement>;
    className?: string;
    readOnly?: boolean;
    size?: keyof typeof sizes;
    tooltipInteractive?: boolean;
}

export const TextareaAutoSizable = ({
    minRows = 1,
    error,
    errorClassName,
    value = "",
    maxLength = 64,
    onChange,
    cutRegExp = regexp,
    inputRef,
    readOnly = false,
    size = "medium",
    className,
    tooltipInteractive = false,
    ...rest
}: ITextareaAutoSizableProps) => {
    const onInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const re = new RegExp(`[${String.fromCharCode(160)}${String.fromCharCode(10240)}]`, "g");
        const str = String(e.target.value)
            .replace(/(?=\s)[^\r\n\t]/g, " ")
            .replace(re, " ")
            .replaceAll(cutRegExp, "")
            .replace(/\n{3,}/g, "\n\n");

        if (maxLength && str.length > maxLength) {
            return value;
        }

        const caretPosition = e.target.selectionStart;
        const removedSymbols = e.target.value.length - str.length;

        e.target.value = str;
        onChange?.(e);

        if (caretPosition) {
            e.target.setSelectionRange(caretPosition - removedSymbols, caretPosition - removedSymbols);
        }
    };

    const onBlur = (event: any) => {
        const newVal = event.target.value
            .toString()
            .trim()
            .replace(/^\s/g, "")
            .replace(/\s{2,}/g, " ");

        if (event.target.value.toString() !== newVal) {
            event.target.value = newVal;
            onInputChange(event);
        }
    };

    const isError = !!error;

    const { size: screenSize } = useScreenSize();
    const isLarge = screenSize === "large";
    const iconAlertSide = isLarge ? 28 : 20;

    return (
        <div className="relative flex w-full items-center">
            <TextareaAutosize
                minRows={minRows}
                value={value}
                onChange={onInputChange}
                onBlur={onBlur}
                maxLength={maxLength}
                ref={inputRef}
                className={clsx(
                    "break-normal overflow-hidden w-full rounded-lg placeholder-gray-text disabled:text-gray leading-p150 font-normal border border-input-stroke disabled:bg-gray-blue min-h-[40px] 2xl:min-h-[50px]",
                    readOnly && "cursor-default",
                    sizes[size],
                    isError &&
                        "!border-danger !focus:border-danger focus:ring-0 focus:ring-danger !pr-10 2xl:!pr-[50px]",
                    !isError && !readOnly && "focus:border-primary focus:ring-0 focus:ring-primary",
                    readOnly && "focus:border-input-stroke focus:ring-0",
                    "border-1",
                    className,
                )}
                {...rest}
            />
            {error && (
                <div
                    className={`absolute inset-y-0 lex items-center pr-0 cursor-pointer top-2.5 2xl:top-3 right-3 2xl:right-3 ${errorClassName}`}
                >
                    <Tooltip content={error} interactive={tooltipInteractive}>
                        <Icon
                            icon={Icons.AlertCircle}
                            color="fill-danger"
                            width={iconAlertSide}
                            height={iconAlertSide}
                        />
                    </Tooltip>
                </div>
            )}
        </div>
    );
};
