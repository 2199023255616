import React, { useCallback, useEffect, useState } from "react";

import { UserRequest } from "Api/Requests/UserRequest";
import { Section } from "Uikit/Forms/Section";
import { TeamTreeAllBasicInfoResponse } from "Api/Responses/TeamResponse";
import { FormGroup } from "Uikit/Forms/FormGroup";
import { Label } from "Uikit/Forms/Label";
import { TeamMultiSelect } from "Components/Common/TeamMultiSelect";
import { useCurrentUser } from "hooks/useCurrentUser";
import { RoleName } from "Enums";
import { IOption } from "types";

interface IMemberCommands {
    errors: any;
    user: UserRequest;
    teams?: TeamTreeAllBasicInfoResponse[];
    onChange: (course: UserRequest) => void;
}
export const MemberCommands = ({ errors, user, teams, onChange }: IMemberCommands) => {
    const [teamId, setTeamId] = useState<string | undefined>(undefined);
    const [selectedTeams, setSelectedTeams] = useState<any>({});
    const currentUser = useCurrentUser();

    const getTeamOptions = useCallback(
        (teamId: string) => {
            const parentTeam = teams?.find((p) => p.id === teamId);

            const getTeams = (team: TeamTreeAllBasicInfoResponse) => {
                let response: IOption[] = [{ label: team.name, value: team.id }];

                for (const element of team.subTeams) {
                    response = response.concat(getTeams(element));
                }

                return response;
            };

            return getTeams(parentTeam!);
        },
        [teams],
    );

    useEffect(() => {
        const data = teams!.map((p: TeamTreeAllBasicInfoResponse) => {
            const option = getTeamOptions(p.id).find((p1) => user.teamIds.find((p2) => p2 === p1.value) !== undefined);

            return { id: p.id, option: option };
        });

        const obj: any = {};

        for (const element of data) {
            if (element.option === undefined) {
                continue;
            }

            obj[element.id] = element.option;
        }

        setSelectedTeams(obj);
    }, [teams, user, getTeamOptions]);

    if (!teams) {
        return null;
    }

    return (
        <>
            <Section label="Список корневых команд" id="adminNewMemberRootTeamsList">
                {teams.map((p, index) => {
                    const selectedItem = selectedTeams[p.id];
                    return (
                        <>
                            <FormGroup
                                key={p.id}
                                className="last:mb-0"
                                onClick={() => setTeamId(p.id)}
                                id={`adminNewMemberRootTeamGroup${index}`}
                            >
                                <Label isRequired={true} className="break-anywhere">
                                    {p.name}
                                </Label>
                                <TeamMultiSelect
                                    value={selectedItem ? [selectedItem.value] : []}
                                    onChange={(teamIds: any) => {
                                        if (!teamId) {
                                            return;
                                        }

                                        setSelectedTeams((prev: any) => {
                                            prev[teamId!] = getTeamOptions(teamId!).find(
                                                (p) => p.value === teamIds[0]?.replace("root:", ""),
                                            );

                                            return prev;
                                        });

                                        setTeamId(undefined);
                                        onChange({
                                            ...user,
                                            teamIds: Object.keys(selectedTeams).map((p) => selectedTeams[p]?.value),
                                        });
                                    }}
                                    checkable={false}
                                    selectable
                                    id={"adminSettingsCommandsTabComboBox" + p.id}
                                    hideMembers={currentUser?.data?.role === RoleName.ADMIN}
                                    disabledTeamCheckDisabled={true}
                                    error={errors["teamIds"] && !selectedTeams[p.id] ? errors["teamIds"] : ""}
                                    isMulti={false}
                                    mustExclude={teams.filter((p1) => p1.id !== p.id).map((p1) => p1.id)}
                                    showTeamsCounter={false}
                                />
                            </FormGroup>
                        </>
                    );
                })}
            </Section>
        </>
    );
};
