import React, { useState, useEffect, useContext, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import { Formik } from "formik";
import Api from "Api";
import { BadRequestResponse, ErrorCode } from "Api/BaseResponse";
import { TaskReadResponseItem } from "Api/Responses/TaskResponse";
import { CommonsAddFavoritesRequest, CommonsRemoveFavoritesRequest } from "Api/Requests/CommonsRequest";
import {
    TaskPublicUserAnswersRequestData,
    TUserAttachments,
    TQuestionsContent,
    TaskPublicUserAnswersRequest,
} from "Api/Requests/TaskPublicRequest";
import { FileReadResponse } from "Api/Responses/FileResponse";
import { Breadcrumbs, Button, Icon, Icons, Tooltip, flash, UserAvatar, Loader, FileViewDialog } from "Uikit";
import { ContentLayout } from "Containers";
import {
    ResourceState,
    ResourceType,
    TaskItemType,
    FileUploadType,
    UIErrorMessages,
    NotificationActionType,
    ProgressStatus,
} from "Enums";
import { numCapEnd } from "helpers/numCapEnd";
import { useUpload } from "hooks/useUpload";
import { TaskQuestionText } from "./TaskQuestionText";
import { TaskQuestionNumber } from "./TaskQuestionNumber";
import { TaskQuestionSingleChoice } from "./TaskQuestionSingleChoice";
import { TaskQuestionMultipleChoice } from "./TaskQuestionMultipleChoice";
import { TaskQuestionImage } from "./TaskQuestionImage";
import { TaskQuestionFile } from "./TaskQuestionFile";
import { validationSchema, normalizeRequestData } from "./helpers";
import { FILE_INPUT_ACCEPTED_TYPES } from "constants/attachments";
import { formatCheckTime } from "../Tasks/utils";
import { useDispatch, useSelector } from "react-redux";
import { setIsBack, setIsFavorite, setIsFavoriteSelected, setIsHidden, setTitle } from "slices/headerSlice";
import { GlobalContext } from "App";
import { IReducer } from "store";
import { getFileSizeErrorMessage } from "helpers/file";
import clsx from "clsx";
import isEqual from "lodash/isEqual";
import NotificationConnect, { NotificationSubscribe } from "Api/Wss/Notifications";
import { fetchFileInfo } from "helpers/fetchFileInfo";
import { WssMessage } from "types/WssMessage";

const imageInputAcceptedTypes = [
    "image/jpeg", // jpg/jpeg
    "image/png", // png
];

const getRequestContentItem = (
    items: TaskReadResponseItem[],
    paramsToAdd: { [id: string]: string | boolean | number | string[] | null },
) => {
    return items.map((item) => {
        const {
            id,
            question,
            description,
            orderNumber,
            mandatory,
            reviewerNote,
            answers,
            files = [],
            images = [],
        } = item;

        const paramsToAddKeys = Object.keys(paramsToAdd);
        const objectToAdd: any = {};
        paramsToAddKeys.forEach((key) => {
            objectToAdd[key] = item[key as keyof typeof item] ?? paramsToAdd[key];
            if (key === "answerIds" && answers?.filter(({ isUserChecked }) => isUserChecked).length > 0) {
                objectToAdd[key] = answers?.filter(({ isUserChecked }) => isUserChecked).map(({ id }) => id);
            }
            if (key === "answerId" && answers?.find(({ isUserChecked }) => isUserChecked)) {
                objectToAdd[key] = answers?.find(({ isUserChecked }) => isUserChecked)?.id;
            }
            if (key === "userAttachments" && files?.length > 0) {
                objectToAdd[key] = files.map(({ id, name, type, size }) => {
                    return { id, name, type, size };
                });
            }
            if (key === "userAttachments" && images?.length > 0) {
                objectToAdd[key] = images.map(({ id, name, type, size }) => {
                    return { id, name, type, size };
                });
            }
        });

        return {
            id,
            question,
            description,
            orderNumber,
            mandatory,
            reviewerNote,
            ...objectToAdd,
        };
    });
};

const getQuestionIndex = (questions: TQuestionsContent, questionId: string) => {
    return Number(questions?.findIndex(({ id }) => id === questionId));
};

export const TaskPassing = () => {
    const dispatch = useDispatch();
    const isFavoriteSelected = useSelector((state: IReducer) => state.header.isFavoriteSelected);
    // Массив Id файлов, удаляемых из очереди на конвертацию - в случае, если конвертируемый файл был прикреплён к созданной ранее сущности (материалу)
    const [discardedVideoIds, setDiscardedVideoIds] = useState<string[]>([]);

    const { id } = useParams();
    const navigate = useNavigate();
    const [isAddFavoritePressed, setIsAddFavoritePressed] = useState(false);
    const [initialValues, setInitialValues] = useState<TaskPublicUserAnswersRequestData>(
        new TaskPublicUserAnswersRequestData(),
    );
    const { upload } = useUpload();
    const [isViewFileModal, setIsViewFileModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState<{
        type: string;
        id: string;
        size: string;
        name: string;
        extension: string;
        disposableLink: string;
    } | null>(null);
    // Не в MVP
    // const [displayMobileNotification, setDisplayMobileNotification] = useState(false);
    const { setIsFullScreenViewEnabled } = useContext(GlobalContext);
    const [filesUploadProcessed, setFilesUploadProcessed] = useState<Array<File | null>>([]);

    const dataQuery = useQuery(
        ["data", "collection"],
        async () => {
            const res = await Api.TaskPublic.Passing({ uuid: id as string });

            dispatch(setIsFavoriteSelected(res.isFavorite));
            setIsAddFavoritePressed(res.isFavorite);

            return res;
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            cacheTime: 0,
        },
    );

    const { data: dataQueryData, isFetching, isError } = dataQuery;

    // Отправка задания на проверку
    const { mutateAsync: sendAnswers } = useMutation((payload: TaskPublicUserAnswersRequest) => {
        return Api.TaskPublic.SendAnswers(payload);
    });

    // Отправка задания на проверку
    const { mutateAsync: startExercise } = useMutation((payload: { id: string }) => {
        return Api.TaskPublic.StartExercise(payload);
    });

    // Сохранение прогресса прохождения задания
    const { mutateAsync: saveAnswersProgress } = useMutation((payload: TaskPublicUserAnswersRequest) => {
        return Api.TaskPublic.SaveAnswersProgress(payload);
    });

    const goBack = async (values: TaskPublicUserAnswersRequestData) => {
        const result = normalizeRequestData(values, id as string);

        try {
            await saveAnswersProgress(result);
            // При наличии файлов с отменённой очередью на оптимизацию, в цикле выполняем запрос на удаление их из очереди
            if (discardedVideoIds.length) {
                const fileIds = Array.from(new Set(discardedVideoIds));
                for (const fileId of fileIds) {
                    await Api.Upload.DiscardVideoFile(fileId);
                }
            }

            navigate(`/task/${dataQueryData?.resourceId}`);
        } catch (e) {
            flash.error("Произошла ошибка при сохранении прогресса задания");
        }
    };

    const ratingPointUnits = numCapEnd({ one: "балл", two: "балла", few: "баллов" }, dataQueryData?.ratingPoints ?? 0);

    // Добавление в избранное
    const { mutateAsync: addFavorites } = useMutation((payload: CommonsAddFavoritesRequest) => {
        return Api.Commons.addFavorites(payload);
    });
    const handleAddFavorites = useCallback(() => {
        addFavorites(
            Object.assign(new CommonsAddFavoritesRequest(), {
                resourceId: dataQueryData?.resourceId,
                passingNumber: dataQueryData?.passingNumber,
                title: dataQueryData?.title,
                logoId: dataQueryData?.logoId,
                deadlineTimestamp: 0,
                ratingPoints: dataQueryData?.ratingPoints,
                state: ResourceState.ACTIVE,
                type: ResourceType.EXERCISE,
                progressStatus: dataQueryData?.progressStatus,
            }),
        ).then();
        setIsAddFavoritePressed(true);
        dispatch(setIsFavoriteSelected(true));
    }, [dispatch, dataQueryData, addFavorites]);

    // Удаление из избранного
    const { mutateAsync: removeFavorites } = useMutation((payload: CommonsRemoveFavoritesRequest) => {
        return Api.Commons.removeFavorites(payload);
    });
    const handleRemoveFavorites = useCallback(() => {
        removeFavorites(
            Object.assign(new CommonsRemoveFavoritesRequest(), {
                resourceId: dataQueryData?.resourceId,
                passingNumber: dataQueryData?.passingNumber,
                type: ResourceType.EXERCISE,
            }),
        ).then();
        setIsAddFavoritePressed(false);
        dispatch(setIsFavoriteSelected(false));
    }, [dispatch, dataQueryData, removeFavorites]);

    const onSubmit = async (values: TaskPublicUserAnswersRequestData) => {
        const result = normalizeRequestData(values, id as string);

        try {
            await sendAnswers(result);
            // При наличии файлов с отменённой очередью на оптимизацию, в цикле выполняем запрос на удаление их из очереди
            if (discardedVideoIds.length) {
                const fileIds = Array.from(new Set(discardedVideoIds));
                for (const fileId of fileIds) {
                    await Api.Upload.DiscardVideoFile(fileId);
                }
            }
            navigate(`/task/${dataQueryData?.resourceId}/sended`);
        } catch (e) {
            flash.error("Произошла ошибка при отправке задания на проверку");
        }
    };

    useEffect(() => {
        NotificationConnect();
        NotificationSubscribe(async (message: WssMessage) => {
            if (
                message.body.objectType === ResourceType.EXERCISE &&
                message.body.actionType === NotificationActionType.EDIT &&
                message.body.objectId === dataQueryData?.resourceId
            ) {
                navigate(`/task/${dataQueryData?.resourceId}/changed`);
            }
        });
    }, [dataQueryData, navigate]);

    // Загрузка файлов (изображений)
    const handleUploadImages = (files: any[], onFinished: (file: any) => void, _onUpdate?: (file: any) => void) => {
        for (const f of files) {
            const u = upload({
                file: f,
                onUpdate: (file: any) => {
                    _onUpdate?.(file);
                },
                onFinished: onFinished,
                fileUploadType: FileUploadType.EXERCISE_IMAGE,
            });

            // u.start()
            // .then();
            const start = u.start();
            start.catch((error) => {
                const knownError = error as BadRequestResponse;
                if (
                    [ErrorCode.CORRUPT_FILE_ERROR, ErrorCode.FILE_EXTENSION_ERROR].includes(
                        String(knownError.errorCode) as ErrorCode,
                    )
                ) {
                    flash.error(UIErrorMessages.FILE_LOADING_ERROR);
                }
                return;
            });
        }
    };

    useEffect(() => {
        if (dataQueryData) {
            const {
                resourceId,
                title,
                logoId,
                ratingPoints,
                complexity,
                textContent,
                numericContent,
                imageContent,
                fileContent,
                singleChoiceContent,
                multipleChoiceContent,
            } = dataQueryData;

            setInitialValues({
                resourceId,
                title,
                logoId,
                ratingPoints: ratingPoints,
                complexity,
                textContent: textContent
                    ? getRequestContentItem(textContent, {
                          userText: "",
                          allowScan: false,
                      })
                    : [],
                numericContent: numericContent
                    ? getRequestContentItem(numericContent, {
                          userNumber: "",
                      })
                    : [],
                singleChoiceContent: singleChoiceContent
                    ? getRequestContentItem(singleChoiceContent, {
                          answerId: "",
                      })
                    : [],
                multipleChoiceContent: multipleChoiceContent
                    ? getRequestContentItem(multipleChoiceContent, {
                          answerIds: [],
                      })
                    : [],
                imageContent: imageContent
                    ? getRequestContentItem(imageContent, {
                          userAttachments: null,
                      })
                    : [],
                fileContent: fileContent
                    ? getRequestContentItem(fileContent, {
                          userAttachments: null,
                      })
                    : [],
            });
            // Не в MVP
            // setDisplayMobileNotification(dataQueryData.geoLocationRequired || dataQueryData.imageContent?.some(({ onlyCameraPhotos }) => onlyCameraPhotos));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataQueryData]);

    useEffect(() => {
        if (dataQueryData?.progressStatus && dataQueryData?.progressStatus !== ProgressStatus.IN_PROGRESS) {
            startExercise({ id: id as string }).then();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        dispatch(setIsHidden(false));
        dispatch(setIsBack(true));
        dispatch(setTitle("Задание"));
        dispatch(setIsFavorite(true));
    }, [dispatch]);
    useEffect(() => {
        if (window.screen.width > 568 || !dataQueryData || isFavoriteSelected === isAddFavoritePressed) {
            return;
        }

        isFavoriteSelected ? handleAddFavorites() : handleRemoveFavorites();
    }, [isFavoriteSelected, dataQueryData, isAddFavoritePressed, handleAddFavorites, handleRemoveFavorites]);

    return (
        <>
            <ContentLayout className="mx-auto sm:pl-4 sm:pr-6.5 max-w-[1216px] 2xl:max-w-[1506px] h-full">
                <Breadcrumbs className="hidden sm:block">
                    <Breadcrumbs.Link title="Задание" url="/tasks" />
                    {dataQueryData?.title && <Breadcrumbs.Link title={dataQueryData?.title} />}
                </Breadcrumbs>
                {isFetching && (
                    <div className="w-full h-full flex-center px-10">
                        <Loader />
                    </div>
                )}
                <div className="relative pt-6">
                    {isError && (
                        <p className="text-md text-center text-red">
                            Произошла ошибка запроса - невозможно загрузить задание
                        </p>
                    )}
                    {!isError && !isFetching && dataQueryData && initialValues.resourceId && (
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            enableReinitialize
                            onSubmit={onSubmit}
                            validateOnChange={false}
                        >
                            {({ handleSubmit, errors, handleChange, values, setFieldValue }) => {
                                const {
                                    textContent,
                                    numericContent,
                                    singleChoiceContent,
                                    multipleChoiceContent,
                                    imageContent,
                                    fileContent,
                                } = values;
                                const isValid = validationSchema.isValidSync(values);

                                return (
                                    <>
                                        <Button
                                            className="hidden sm:block !p-0 absolute -top-3 right-7 !border-0 hover:!shadow-none active:!bg-transparent active:!ring-0 2xl:w-6.25 2xl:h-6.25"
                                            variant="outline"
                                            color="secondary"
                                            onClick={() => {
                                                goBack(values).then();
                                            }}
                                        >
                                            <Icon
                                                icon={Icons.Close}
                                                width={20}
                                                height={20}
                                                color="fill-[#939393]"
                                                className="2xl:!w-6.25 2xl:!h-6.25"
                                            />
                                        </Button>
                                        <div className="flex flex-col items-center w-full sm:w-[700px] 2xl:w-[875px] mx-auto relative">
                                            <div className="w-full">
                                                <div className="flex items-center justify-between mb-1 2xl:mb-1.25">
                                                    <p className="p4 2xl:p2 text-gray-text">
                                                        {dataQueryData?.ratingPoints} {ratingPointUnits}
                                                    </p>
                                                    <div
                                                        className={clsx(
                                                            "sm:relative sm:top-0 sm:right-0 group hidden sm:flex justify-center items-center w-6 2xl:w-7.5 h-6 2xl:h-7.5 rounded-full transition-all",
                                                            (dataQueryData.isFavorite && !isAddFavoritePressed) ||
                                                                isAddFavoritePressed
                                                                ? "hover:bg-gray-blue"
                                                                : "hover:bg-blue-60",
                                                        )}
                                                        onClick={
                                                            dataQueryData.isFavorite || isAddFavoritePressed
                                                                ? handleRemoveFavorites
                                                                : handleAddFavorites
                                                        }
                                                    >
                                                        {(dataQueryData.isFavorite && !isAddFavoritePressed) ||
                                                        isAddFavoritePressed ? (
                                                            <Icon
                                                                icon={Icons.SaveFilled}
                                                                width={18}
                                                                height={24}
                                                                color="fill-gray-dark group-hover:fill-black transition-all"
                                                                className="cursor-pointer 2xl:!w-6.25 2xl:!h-6.25"
                                                            />
                                                        ) : (
                                                            <Icon
                                                                icon={Icons.Save}
                                                                width={18}
                                                                height={24}
                                                                color="fill-black-50 group-hover:fill-white transition-all"
                                                                className="cursor-pointer 2xl:!w-6.25 2xl:!h-6.25"
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                                <h1 className="2xl:!text-4xl 2xl:!leading-[41px] break-all">
                                                    {dataQueryData.title}
                                                </h1>
                                                {dataQueryData.description && (
                                                    <p className="text-gray-text mt-3 2xl:mt-3.75 p2 2xl:text-md break-all">
                                                        {dataQueryData.description}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="mt-5 2xl:mt-6.5 pt-5 2xl:pt-6.5 border-t pb-18 sm:pb-0 border-blue-gray">
                                            <div className="flex flex-col items-center w-full sm:w-[700px] 2xl:w-[875px] mx-auto gap-5 2xl:gap-6.5 2xl:pb-10">
                                                {/* Комментарий */}
                                                {dataQueryData.lastCheckedComment && (
                                                    <div className="w-full">
                                                        <div className="py-5 sm:py-7.5 2xl:py-9.5 px-4 sm:px-9 2xl:px-11 rounded-xl 2xl:rounded-2xl border border-gray-stroke relative">
                                                            <span
                                                                className={
                                                                    "sm:absolute right-9 top-7.5 2xl:right-11 2xl:top-9.5 inline-block mb-2 sm:mb-0 py-0.5 2xl:py-0.75 px-1.5 2xl:px-2 rounded-md 2xl:rounded-lg text-white text-[12px] bg-primary 2xl:h-6.5"
                                                                }
                                                            >
                                                                Комментарий
                                                            </span>
                                                            <div className="flex gap-3.5 mb-4 2xl:mb-5">
                                                                <UserAvatar
                                                                    avatarId={
                                                                        dataQueryData.lastCheckedComment.user.avatarId
                                                                    }
                                                                    color={
                                                                        dataQueryData.lastCheckedComment.user
                                                                            .defaultAvatarColor
                                                                    }
                                                                    userInitials={`${dataQueryData.lastCheckedComment.user.firstName?.slice(
                                                                        0,
                                                                        1,
                                                                    )}${dataQueryData.lastCheckedComment.user.lastName?.slice(
                                                                        0,
                                                                        1,
                                                                    )}`}
                                                                    size={window.screen.width >= 1600 ? 45 : 36}
                                                                />
                                                                <div>
                                                                    <p className="p2 mb-0.5 2xl:mb-0.75 2xl:text-md">
                                                                        {
                                                                            dataQueryData.lastCheckedComment.user
                                                                                .firstName
                                                                        }{" "}
                                                                        {dataQueryData.lastCheckedComment.user.lastName}
                                                                    </p>
                                                                    {!!dataQueryData.lastCheckedComment.checkedTime && (
                                                                        <p className="p3 2xl:p2 text-gray-text">
                                                                            Проверил{" "}
                                                                            {formatCheckTime(
                                                                                Number(
                                                                                    dataQueryData.lastCheckedComment
                                                                                        .checkedTime,
                                                                                ),
                                                                                { showTime: true },
                                                                            )}
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="flex gap-3.5 2xl:text-md">
                                                                <p>{dataQueryData.lastCheckedComment.comment}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {/* Вопросы */}
                                                {[
                                                    ...(dataQueryData.textContent ?? []),
                                                    ...(dataQueryData.numericContent ?? []),
                                                    ...(dataQueryData.imageContent ?? []),
                                                    ...(dataQueryData.fileContent ?? []),
                                                    ...(dataQueryData.singleChoiceContent ?? []),
                                                    ...(dataQueryData.multipleChoiceContent ?? []),
                                                ]
                                                    .sort((a, b) => a.orderNumber - b.orderNumber)
                                                    .map((item) => {
                                                        const {
                                                            question,
                                                            description,
                                                            mandatory,
                                                            orderNumber,
                                                            id,
                                                            answers,
                                                        } = item;

                                                        return (
                                                            <div
                                                                key={`${item.id}`}
                                                                className="w-full p-4 sm:p-6 2xl:p-7.5 rounded-xl 2xl:rounded-2xl bg-blue-lightest"
                                                            >
                                                                <div className="relative pr-2">
                                                                    {mandatory && (
                                                                        <div className="absolute top-0 right-0">
                                                                            <span className="text-red 2xl:text-md">
                                                                                *
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                    <pre className="font-sans p1-accent 2xl:text-md">
                                                                        {question}
                                                                    </pre>
                                                                </div>
                                                                {description && (
                                                                    <p className="p2 2xl:p1 text-gray-text mt-1 2xl:mt-1.25">
                                                                        {description}
                                                                    </p>
                                                                )}
                                                                <div className="mt-4 sm:mt-5 2xl:mt-6.25">
                                                                    {/* Текстовый вопрос */}
                                                                    {item.contentType === TaskItemType.Text && (
                                                                        <TaskQuestionText
                                                                            value={
                                                                                textContent?.find(
                                                                                    ({ orderNumber: on }) =>
                                                                                        on === orderNumber,
                                                                                )?.userText as string
                                                                            }
                                                                            onChange={handleChange}
                                                                            name={`textContent.${textContent?.findIndex(
                                                                                ({ id: itemId }) => itemId === id,
                                                                            )}.userText`}
                                                                            error={
                                                                                (
                                                                                    errors.textContent as unknown as Array<{
                                                                                        userText: string;
                                                                                    }>
                                                                                )?.[
                                                                                    textContent?.findIndex(
                                                                                        ({ id: itemId }) =>
                                                                                            itemId === id,
                                                                                    ) ?? 0
                                                                                ]?.userText
                                                                            }
                                                                        />
                                                                    )}
                                                                    {/* Числовой вопрос */}
                                                                    {item.contentType === TaskItemType.Number && (
                                                                        <TaskQuestionNumber
                                                                            mandatory={item.mandatory}
                                                                            value={
                                                                                numericContent?.find(
                                                                                    ({ orderNumber: on }) =>
                                                                                        on === orderNumber,
                                                                                )?.userNumber as string
                                                                            }
                                                                            onChange={handleChange}
                                                                            name={`numericContent.${numericContent?.findIndex(
                                                                                ({ id: itemId }) => itemId === id,
                                                                            )}.userNumber`}
                                                                            error={
                                                                                (
                                                                                    errors.numericContent as unknown as Array<{
                                                                                        userNumber: string;
                                                                                    }>
                                                                                )?.[
                                                                                    numericContent?.findIndex(
                                                                                        ({ id: itemId }) =>
                                                                                            itemId === id,
                                                                                    ) ?? 0
                                                                                ]?.userNumber
                                                                            }
                                                                        />
                                                                    )}
                                                                    {/* Вопрос типа "Один из списка" */}
                                                                    {item.contentType === TaskItemType.SingleChoice && (
                                                                        <TaskQuestionSingleChoice
                                                                            value={
                                                                                singleChoiceContent?.find(
                                                                                    ({ orderNumber: on }) =>
                                                                                        on === orderNumber,
                                                                                )?.answerId as string
                                                                            }
                                                                            onChange={(value) => {
                                                                                setFieldValue(
                                                                                    `singleChoiceContent.${singleChoiceContent?.findIndex(
                                                                                        ({ id: itemId }) =>
                                                                                            itemId === id,
                                                                                    )}.answerId`,
                                                                                    value,
                                                                                ).then();
                                                                            }}
                                                                            answers={answers}
                                                                        />
                                                                    )}
                                                                    {/* Вопрос типа "Несколько из списка" */}
                                                                    {item.contentType ===
                                                                        TaskItemType.MultipleChoice && (
                                                                        <TaskQuestionMultipleChoice
                                                                            value={
                                                                                multipleChoiceContent?.find(
                                                                                    ({ orderNumber: on }) =>
                                                                                        on === orderNumber,
                                                                                )?.answerIds as string[]
                                                                            }
                                                                            onChange={(e) => {
                                                                                const { value } =
                                                                                    e.target as HTMLInputElement;
                                                                                const values = [
                                                                                    ...(multipleChoiceContent?.find(
                                                                                        ({ id: itemId }) =>
                                                                                            itemId === id,
                                                                                    )?.answerIds as string[]),
                                                                                ];

                                                                                setFieldValue(
                                                                                    `multipleChoiceContent.${multipleChoiceContent?.findIndex(
                                                                                        ({ id: itemId }) =>
                                                                                            itemId === id,
                                                                                    )}.answerIds`,
                                                                                    values.includes(value)
                                                                                        ? values.filter(
                                                                                              (item) => item !== value,
                                                                                          )
                                                                                        : [...values, value],
                                                                                ).then();
                                                                            }}
                                                                            answers={answers}
                                                                        />
                                                                    )}
                                                                    {/* Вопрос типа "Загрузка изображения" */}
                                                                    {item.contentType === TaskItemType.Image && (
                                                                        <TaskQuestionImage
                                                                            value={
                                                                                imageContent?.[
                                                                                    getQuestionIndex(imageContent, id)
                                                                                ]?.userAttachments
                                                                            }
                                                                            onDelete={(index) => {
                                                                                const questionIndex = getQuestionIndex(
                                                                                    imageContent as TQuestionsContent,
                                                                                    id,
                                                                                );
                                                                                const questionFieldName = `imageContent.${questionIndex}.userAttachments`;
                                                                                const attachments =
                                                                                    imageContent?.[questionIndex]
                                                                                        ?.userAttachments ?? [];
                                                                                setFieldValue(questionFieldName, [
                                                                                    ...attachments.filter(
                                                                                        (item, itemIndex) =>
                                                                                            itemIndex !== index,
                                                                                    ),
                                                                                ]).then();
                                                                            }}
                                                                            onChange={(event) => {
                                                                                const files = (
                                                                                    event?.target as HTMLInputElement
                                                                                )?.files;
                                                                                const questionIndex = getQuestionIndex(
                                                                                    imageContent as TQuestionsContent,
                                                                                    id,
                                                                                );
                                                                                const questionFieldName = `imageContent.${questionIndex}.userAttachments`;

                                                                                if (!files || files.length === 0) {
                                                                                    return;
                                                                                }

                                                                                if (
                                                                                    !files[0].type.match(
                                                                                        /jpg|jpeg|png|webm/gi,
                                                                                    )
                                                                                ) {
                                                                                    flash.error(
                                                                                        UIErrorMessages.FILE_EXTENSION_ERROR,
                                                                                    );
                                                                                    return;
                                                                                }

                                                                                if (files[0].size > 10000000) {
                                                                                    flash.error(
                                                                                        getFileSizeErrorMessage(
                                                                                            "10 МБ",
                                                                                        ),
                                                                                    );
                                                                                    return;
                                                                                }

                                                                                setFilesUploadProcessed((prevFiles) => {
                                                                                    const prevFilesSet = new Set(
                                                                                        prevFiles,
                                                                                    );
                                                                                    prevFilesSet.add(files[0]);
                                                                                    return Array.from(prevFilesSet);
                                                                                });

                                                                                const attachments =
                                                                                    imageContent?.[questionIndex]
                                                                                        ?.userAttachments ?? [];
                                                                                const attachment: TUserAttachments = {};

                                                                                const _onFinished = ({
                                                                                    fileId,
                                                                                    size,
                                                                                    file,
                                                                                }: any) => {
                                                                                    const attachIndex =
                                                                                        attachments.length - 1;
                                                                                    attachments[attachIndex].id =
                                                                                        fileId;
                                                                                    attachments[attachIndex].size =
                                                                                        size;
                                                                                    attachments[attachIndex].type =
                                                                                        file.type;
                                                                                    setFieldValue(questionFieldName, [
                                                                                        ...attachments,
                                                                                    ]).then(() => {
                                                                                        (
                                                                                            event?.target as HTMLInputElement
                                                                                        ).value = "";
                                                                                    });

                                                                                    setFilesUploadProcessed(
                                                                                        (prevFiles) => {
                                                                                            const prevFilesSet =
                                                                                                new Set(prevFiles);
                                                                                            prevFilesSet.delete(file);
                                                                                            return Array.from(
                                                                                                prevFilesSet,
                                                                                            );
                                                                                        },
                                                                                    );
                                                                                };

                                                                                const reader = new FileReader();
                                                                                reader.readAsDataURL(files[0]);
                                                                                reader.onload = () => {
                                                                                    attachment.base64 =
                                                                                        reader.result!.toString();
                                                                                    attachments.push(attachment);
                                                                                    setFieldValue(questionFieldName, [
                                                                                        ...attachments,
                                                                                    ]).then();
                                                                                };

                                                                                handleUploadImages(
                                                                                    files as unknown as any[],
                                                                                    _onFinished,
                                                                                );
                                                                            }}
                                                                            accept={imageInputAcceptedTypes.join(",")}
                                                                            onAttachmentClick={(fileData) => {
                                                                                setSelectedFile(fileData);
                                                                                setIsViewFileModal(true);
                                                                                setIsFullScreenViewEnabled(true);
                                                                            }}
                                                                        />
                                                                    )}
                                                                    {/* Вопрос типа "Загрузка файлов" */}
                                                                    {item.contentType === TaskItemType.File && (
                                                                        <TaskQuestionFile
                                                                            onDeleteAttachment={(
                                                                                deletedAttchmentId,
                                                                                file,
                                                                            ) => {
                                                                                const questionIndex = getQuestionIndex(
                                                                                    fileContent as TQuestionsContent,
                                                                                    id,
                                                                                );
                                                                                const questionFieldName = `fileContent.${questionIndex}.userAttachments`;
                                                                                const attachments =
                                                                                    fileContent?.[questionIndex]
                                                                                        ?.userAttachments ?? [];
                                                                                setFieldValue(questionFieldName, [
                                                                                    ...attachments.filter((item) => {
                                                                                        return (
                                                                                            item.id !==
                                                                                            deletedAttchmentId
                                                                                        );
                                                                                    }),
                                                                                ]).then();
                                                                                setFilesUploadProcessed((prevFiles) => {
                                                                                    const prevFilesSet = new Set(
                                                                                        prevFiles,
                                                                                    );
                                                                                    prevFilesSet.delete(file);
                                                                                    return Array.from(prevFilesSet);
                                                                                });
                                                                            }}
                                                                            onLoadAttachment={(
                                                                                loadedAttchmentData,
                                                                                file,
                                                                            ) => {
                                                                                const questionIndex = getQuestionIndex(
                                                                                    fileContent as TQuestionsContent,
                                                                                    id,
                                                                                );
                                                                                const questionFieldName = `fileContent.${questionIndex}.userAttachments`;
                                                                                const attachments =
                                                                                    fileContent?.[questionIndex]
                                                                                        ?.userAttachments ?? [];
                                                                                const attachment: TUserAttachments =
                                                                                    loadedAttchmentData;
                                                                                const attachmentIndex =
                                                                                    attachments.findIndex(
                                                                                        ({
                                                                                            id,
                                                                                            file: attachmentsFile,
                                                                                        }) => {
                                                                                            return (
                                                                                                id ===
                                                                                                    loadedAttchmentData.id ||
                                                                                                isEqual(
                                                                                                    attachmentsFile,
                                                                                                    file,
                                                                                                )
                                                                                            );
                                                                                        },
                                                                                    );
                                                                                if (
                                                                                    typeof attachmentIndex ===
                                                                                        "number" &&
                                                                                    attachmentIndex >= 0
                                                                                ) {
                                                                                    attachments[attachmentIndex] =
                                                                                        attachment;
                                                                                } else {
                                                                                    attachments.push(attachment);
                                                                                }
                                                                                setFieldValue(questionFieldName, [
                                                                                    ...attachments,
                                                                                ]).then();

                                                                                setFilesUploadProcessed((prevFiles) => {
                                                                                    const prevFilesSet = new Set(
                                                                                        prevFiles,
                                                                                    );
                                                                                    prevFilesSet.delete(file);
                                                                                    return Array.from(prevFilesSet);
                                                                                });
                                                                            }}
                                                                            onChange={(event) => {
                                                                                const files = (
                                                                                    event?.target as HTMLInputElement
                                                                                )?.files;

                                                                                if (!files || files.length === 0) {
                                                                                    return null;
                                                                                }

                                                                                if (
                                                                                    Object.keys(
                                                                                        FILE_INPUT_ACCEPTED_TYPES,
                                                                                    ).every((item) => {
                                                                                        return !files[0].type.includes(
                                                                                            item,
                                                                                        );
                                                                                    })
                                                                                ) {
                                                                                    flash.error(
                                                                                        UIErrorMessages.FILE_EXTENSION_ERROR,
                                                                                    );
                                                                                    return null;
                                                                                }

                                                                                if (files[0].size > 200000000) {
                                                                                    flash.error(
                                                                                        getFileSizeErrorMessage(
                                                                                            "200 МБ",
                                                                                        ),
                                                                                    );
                                                                                    return null;
                                                                                }

                                                                                setFilesUploadProcessed((prevFiles) => {
                                                                                    const prevFilesSet = new Set(
                                                                                        prevFiles,
                                                                                    );
                                                                                    prevFilesSet.add(files[0]);
                                                                                    return Array.from(prevFilesSet);
                                                                                });

                                                                                return files[0];
                                                                            }}
                                                                            accept={Object.keys(
                                                                                FILE_INPUT_ACCEPTED_TYPES,
                                                                            ).join(",")}
                                                                            value={
                                                                                fileContent?.[
                                                                                    getQuestionIndex(
                                                                                        fileContent as TQuestionsContent,
                                                                                        id,
                                                                                    )
                                                                                ]?.userAttachments ?? []
                                                                            }
                                                                            onAttachmentClick={(fileData) => {
                                                                                setSelectedFile(fileData);
                                                                                setIsViewFileModal(true);
                                                                                setIsFullScreenViewEnabled(true);
                                                                            }}
                                                                            addDiscardedVideoId={(fileId) => {
                                                                                setDiscardedVideoIds(
                                                                                    (prevVideoIds: string[]) => {
                                                                                        prevVideoIds.push(fileId);
                                                                                        return prevVideoIds;
                                                                                    },
                                                                                );
                                                                            }}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                {/* Не в MVP */}
                                                {/* {!displayMobileNotification && ( */}
                                                <div className="w-full">
                                                    {isValid && filesUploadProcessed.length === 0 ? (
                                                        <Button
                                                            color={"primary"}
                                                            className="w-full text-center"
                                                            type="submit"
                                                            onClick={handleSubmit}
                                                        >
                                                            Отправить
                                                        </Button>
                                                    ) : (
                                                        <Tooltip
                                                            content={
                                                                filesUploadProcessed.length !== 0
                                                                    ? "Дождитесь завершения загрузки файла"
                                                                    : "Нужно ответить на все обязательные вопросы"
                                                            }
                                                        >
                                                            <Button
                                                                color={"primary"}
                                                                className="w-full text-center !bg-blue-lightest !text-gray-light"
                                                                onClick={() => {}}
                                                                disabled
                                                            >
                                                                Отправить
                                                            </Button>
                                                        </Tooltip>
                                                    )}
                                                </div>
                                                {/* )} */}
                                            </div>
                                        </div>
                                    </>
                                );
                            }}
                        </Formik>
                    )}
                </div>
            </ContentLayout>
            {isViewFileModal && (
                <FileViewDialog
                    file={selectedFile as unknown as FileReadResponse & { type: string; data: string }}
                    handleClose={() => {
                        setIsViewFileModal(false);
                        setIsFullScreenViewEnabled(false);
                    }}
                    onOpen={fetchFileInfo}
                />
            )}
        </>
    );
};
